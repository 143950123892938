import type { FC } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface Props {
    isUpdate?: boolean;
    onCancel?: any;
    onDelete?: any;
}

const FtCrud: FC<Props> = ({ isUpdate, onCancel, onDelete }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            {isUpdate != null && (
                <Button variant="contained" type="submit">
                    {(isUpdate && t('Update')) || t('Create')}
                </Button>
            )}
            {onCancel && (
                <Button type="button" onClick={onCancel}>
                    {t('Cancel')}
                </Button>
            )}
            <div className="align-right ">
                {onDelete && (
                    <Button type="button" variant="contained" color="error" onClick={onDelete}>
                        {t('Delete')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default FtCrud;
