import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        relatedSkill: {
            borderColor: colors.blue,
            color: colors.blue,
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        competencyImage: css({
            maxWidth: theme.spacing(8),
        }),
        competencyWrapper: css({
            marginTop: theme.spacing(2),
            textAlign: 'center',
        }),
    };
};
