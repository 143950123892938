import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const CHART_MARGIN = 128;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        title: {
            color: colors.grey,
            textTransform: 'uppercase',
        },
        legend: css({
            width: `calc(100% - ${CHART_MARGIN}px)`,
            margin: 'auto',

            '& .MuiGrid-item': {
                display: 'flex',

                '& svg': {
                    marginRight: theme.spacing(0.5),
                },
            },
        }),
        height100: {
            height: '100%',
        },
        chart: css({
            marginLeft: theme.spacing(CHART_MARGIN / 16),
            marginRight: theme.spacing(CHART_MARGIN / 16),
            marginTop: theme.spacing(CHART_MARGIN / 32),
            marginBottom: theme.spacing(CHART_MARGIN / 32),
        }),
        positive: {
            color: colors.peach,
        },
        negative: {
            color: colors.teal,
        },
        percentageValue: {
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
    };
};
