import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAnalyticsFilter } from 'hooks';
import type { AppReducerState } from 'store';
import { api, callService } from 'services';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useStyles } from './styles';
import { DonutChart } from '../../chart';
import { Loading } from '../../../Loading';

interface AnalyticsData {
    graphTitle: string;
    data: {
        competencySysId: string;
        totalQuestsCompetency: number;
    }[];
}

export const GrowthQuestBehaviors: FC = () => {
    const [data, setData] = useState<AnalyticsData>();
    const { filter } = useAnalyticsFilter();
    const classes = useStyles();
    const { allCompetencies } = useSelector(({ competencies }: AppReducerState) => competencies);

    const { dateFrom, dateTo, jobs, hierarchies, questTypes } = filter;

    const getAnalyticsData = async () => {
        const { error, payload } = await callService({
            api: api.analyticsController.getAnalyticsQuests,
            query: {
                graphId: 'Q2',
                jobs: jobs.join(','),
                teams: hierarchies.join(','),
                questTypes: questTypes.join(','),
                dateFrom: dateFrom.format('YYYY-MM-DD'),
                dateTo: dateTo.format('YYYY-MM-DD'),
            },
        });

        if (!error && payload) {
            setData(payload);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, [dateFrom, dateTo, jobs, hierarchies, questTypes]);

    if (!data) {
        return <Loading />;
    }

    return (
        <Card sx={classes.card}>
            <CardHeader
                title={
                    <Typography>
                        <strong className={classes.graphTitle}>{data.graphTitle}</strong>
                    </Typography>
                }
            />
            <CardContent sx={classes.cardContent}>
                <DonutChart
                    donut={false}
                    data={data.data.map((dataRow) => {
                        const competencyName =
                            allCompetencies.find(
                                (competency) => competency.compSysId === dataRow.competencySysId,
                            )?.descriptionShort || (Math.random() + 1).toString(36).substring(7);

                        return {
                            label: competencyName,
                            value: dataRow.totalQuestsCompetency,
                            valueLabel: `${dataRow.totalQuestsCompetency}`,
                        };
                    })}
                />
            </CardContent>
        </Card>
    );
};
