import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BulkImportStepper } from './BulkImportStepper';
import { BulkImportStep1 } from './BulkImportStep1';
import { BulkImportStep2 } from './BulkImportStep2';

interface BulkImportProps {
    step: number;
    setStep(s: number): any;
    setData(data: any): any;
    ImportedDataTable: any;
    ImportFileDefinition: any;
    handleImport(): any;
    dataValidated: any;
}

export const BulkImport: FC<BulkImportProps> = ({
    step,
    setStep,
    setData,
    ImportedDataTable,
    ImportFileDefinition,
    handleImport,
    dataValidated,
}) => {
    const { t: translator } = useTranslation();

    const prevStep = () => {
        setStep(step - 1);
    };

    return (
        <BulkImportStepper step={step}>
            {step === 1 && (
                <BulkImportStep1 setData={setData} ImportFileDefinition={ImportFileDefinition} />
            )}
            {step === 2 && (
                <BulkImportStep2
                    ImportedDataTable={ImportedDataTable}
                    data={dataValidated}
                    prevStep={prevStep}
                    handleImport={handleImport}
                />
            )}
        </BulkImportStepper>
    );
};

export default BulkImport;
