import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { AppReducerState } from 'store';
import { useNavigate } from 'react-router-dom';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { Favorite } from '@mui/icons-material';
import { useStyles } from './styles';

export const CardHelpfulness: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const { feedbackGivenHelpfulPercent, feedbackGivenHelpfulCompanyPercent } = useSelector(
        ({ user }: AppReducerState) => user.current,
    );

    const handleCardClick = () => navigate(`/feedback/my`);

    return (
        <Card sx={classes.card} onClick={handleCardClick}>
            <CardContent>
                <Grid container>
                    <Grid item xs={10} sx={classes.content}>
                        <div>
                            <Typography sx={classes.title}>
                                {t('home.card.helpfulness.title')}
                            </Typography>
                            <Typography variant="h6">{`${feedbackGivenHelpfulPercent}%`}</Typography>
                        </div>
                        <Typography
                            component="span"
                            variant="caption"
                            sx={classes.averageHelpfulnessWrapper}
                        >
                            {t('home.card.helpfulness.companyAverage')}
                            <span
                                className={classes.averageHelpfulness}
                            >{`${feedbackGivenHelpfulCompanyPercent}%`}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Avatar sx={classes.icon}>
                                    <Favorite />
                                </Avatar>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
