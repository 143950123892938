import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        container: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    };
};
