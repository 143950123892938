import { useEffect, useRef } from 'react';
import type { FC } from 'react';
import * as d3 from 'd3';
import type { ChartTuple } from 'types';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;
const BAR_GAP = 0.5;
const X_AXIS_HEIGHT = 20;
const CORNER_RADIUS = 2;
const EVEN = 2;
const MIN_Y = 0;
const FULL_OPACITY = 1;
const LOW_OPACITY = 0.3;
const ZERO_VALUE_MODIFIER = 1;

export interface Props {
    amount: ChartTuple<string>[];
    height: number;
    width: number;
    className?: string;
}

export const getValue = (value: number): number => value ?? MIN_Y;

export const CurrentMonthChart: FC<Props> = ({ amount, height, width, className = '' }) => {
    const chartRef = useRef(null);
    const xAxisRef = useRef(null);
    const { xDomain, yDomain } = amount.reduce(
        (acc, { key, value }) => {
            acc.xDomain.push(key);
            acc.yDomain.push(value);

            return acc;
        },
        {
            xDomain: [] as string[],
            yDomain: [] as number[],
        },
    );
    const yMax = d3.max<number, number>(yDomain, getValue);
    const xScale = d3
        .scaleBand<string>()
        .domain(xDomain)
        .rangeRound([0, width])
        .paddingInner(BAR_GAP);
    const yScale = d3
        .scaleLinear<number, number>()
        .domain([0, yMax || 1])
        .range([height, 0]);

    const renderXAxisTicks = () => {
        d3.select(xAxisRef.current).selectAll('*').remove();
        d3.select(xAxisRef.current)
            .selectAll('.xTicks')
            .data(xDomain)
            .enter()
            .append('text')
            .transition()
            .attr('y', yScale(0) || 0)
            .attr('x', (d) => (xScale(d) ?? 0) + xScale.bandwidth() / 2)
            .attr('text-anchor', 'middle')
            .attr('fill', colors.grey)
            .text((d, index) => index + 1);
    };

    const renderColumns = () => {
        d3.select(chartRef.current)
            .selectAll('.bar')
            .data(amount)
            .enter()
            .append('rect')
            .style('fill', colors.blue)
            .attr('opacity', (d, index) => (index % EVEN ? LOW_OPACITY : FULL_OPACITY))
            .attr('x', ({ key }) => xScale(key) ?? 0)
            .attr('width', xScale.bandwidth())
            .attr('y', ({ value }) => (value ? yScale(value) || 0 : height - ZERO_VALUE_MODIFIER))
            .attr('height', ({ value }) =>
                value ? height - (yScale(value) || 0) : ZERO_VALUE_MODIFIER,
            )
            .attr('rx', CORNER_RADIUS);
    };

    useEffect(() => {
        if (width && height) {
            d3.select(chartRef.current).selectAll('*').remove();
            renderXAxisTicks();
            renderColumns();
        }
    }, [amount, height, width, className]);

    return (
        <svg width={width} height={height + X_AXIS_HEIGHT} className={className}>
            <g ref={xAxisRef} transform={`translate(0,${X_AXIS_HEIGHT})`} />
            <g ref={chartRef} transform={`translate(0,0)`} />
        </svg>
    );
};
