import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import type { QuestFilterObject } from 'types';
import { PROFILE_TYPE } from 'types';
import type { AppReducerState } from 'store';
import { useBoundActions } from 'hooks';
import { setQuestLogFilterPreFillData } from 'store/slices/quest';

const shouldLoadSavedFilter = (date) => date && moment(date).isAfter(moment().subtract(2, 'hours'));

export const selectQuestFilter = createSelector(
    (state: AppReducerState) => state.user,
    (state: AppReducerState) => state.quest.questLogFilterPreFillData,
    (employee, questLogFilterPreFillData) => {
        const shouldLoad = shouldLoadSavedFilter(questLogFilterPreFillData?.timeStamp);
        let prefilledAssignedToId =
            questLogFilterPreFillData?.assignedToWithType?.id || shouldLoad
                ? undefined
                : employee.empSysId;
        const assignedToWithType = shouldLoad
            ? questLogFilterPreFillData?.assignedToWithType
            : { id: employee.empSysId, type: PROFILE_TYPE.PLAYER };

        if (assignedToWithType && !prefilledAssignedToId) {
            prefilledAssignedToId = assignedToWithType.id;
        }

        return {
            filter: {
                type: [],
                status: [],
                assignedTo: prefilledAssignedToId ? [prefilledAssignedToId] : [],
                query: '',
                ...(shouldLoad ? questLogFilterPreFillData?.filter || {} : {}),
            } as QuestFilterObject,
            assignedToWithType,
        };
    },
);

export const useQuestLogFilter = () => {
    const { filter, assignedToWithType } = useSelector(selectQuestFilter);

    const { setQuestLogFilterPreFillDataAction } = useBoundActions({
        setQuestLogFilterPreFillData,
    });

    return {
        assignedToWithType,
        filter,
        setQuestLogFilterPreFillDataAction,
    };
};
