import type { VFC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CardContent, Button, Typography } from '@mui/material';
import type { Leadership } from 'types';
import { FEEDBACK_STEP_TYPE } from 'types';
import type { AppReducerState } from 'store';
import { useBoundActions } from 'hooks';
import { setFeedbackPreFillData } from 'store/slices/feedback';
import { useStyles } from './styles';
import { Avatar } from '../../avatar';
import { RaisedCard } from '../../RaisedCard';

interface Props {
    leader: Leadership;
}

export const LeadershipCard: VFC<Props> = ({ leader }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();
    const { setFeedbackPreFillDataAction } = useBoundActions({
        setFeedbackPreFillData,
    });
    const { empSysId } = useSelector(({ user }: AppReducerState) => user);

    const handleOnQuestCardClick = () => navigate(`/profile/${leader.employeeSysId}`);

    const handleGiveFeedbackButtonClick = (evt) => {
        evt.stopPropagation();

        setFeedbackPreFillDataAction({
            step: FEEDBACK_STEP_TYPE.COMPETENCY,
            employeeId: leader.employeeSysId,
        });
        navigate('/feedback/new');
    };

    return (
        <RaisedCard onClick={handleOnQuestCardClick} className={classes.card}>
            <CardContent sx={classes.content}>
                <div className={classes.flex}>
                    <Avatar
                        alt={leader.firstName[0]}
                        src={leader.profilePhoto}
                        sx={classes.avatar}
                    />
                    <div>
                        <Typography>{`${leader.firstName} ${leader.lastName}`}</Typography>
                        <Typography variant="caption">{leader.job.title}</Typography>
                    </div>
                </div>
                {empSysId !== leader.employeeSysId && (
                    <div className={classes.actions}>
                        <Button onClick={handleGiveFeedbackButtonClick}>
                            {t('quest.log.tabs.tales.leadershipCard.actions.giveFeedback.label')}
                        </Button>
                    </div>
                )}
            </CardContent>
        </RaisedCard>
    );
};
