const SAVE_FILE_CLEANUP_TIMEOUT = 500;

const onSaveFileCleanup = (link: HTMLAnchorElement): void => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
};

const saveBlobWithLink = (blob: any, fileName: string): void => {
    const link = document.createElement('a');

    link.style.display = 'none';
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link); // this is necessary for firefox
    link.click();
    setTimeout(() => onSaveFileCleanup(link), SAVE_FILE_CLEANUP_TIMEOUT);
};

export function saveBlob(blob: any, fileName: string): void {
    if (typeof (window.navigator as any).msSaveOrOpenBlob === 'function') {
        (window.navigator as any).msSaveOrOpenBlob(blob, fileName);

        return;
    }

    saveBlobWithLink(blob, fileName);
}

export const createDownloadFile = async (response, onError?, onSuccess?): Promise<void> => {
    try {
        // const contentDisposition = response.headers.get('Content-Disposition');
        // const regExpMatch = contentDisposition.match(/filename\s*=\s*(.*?)(?:;|$)/);
        // const fileName = regExpMatch ? regExpMatch[1].replace(/"/g, '') : 'download';
        const fileName = 'download';
        const blob = await response.blob();

        saveBlob(blob, fileName);
        onSuccess?.();
    } catch (err) {
        onError?.(err);
    }
};
