import { useEffect } from 'react';
import type { AppReducerState } from 'store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useRedirectToHomeOrGDPR = () => {
    const navigate = useNavigate();
    const { loggedIn, policyAccepted } = useSelector(({ user }: AppReducerState) => user);

    useEffect(() => {
        if (loggedIn) {
            if (policyAccepted) {
                navigate('/dashboard');
            } else {
                navigate('/gdpr');
            }
        }
    }, [loggedIn]);
};
