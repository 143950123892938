import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Slide, Snackbar as MuiSnackbar, Typography } from '@mui/material';
import type { AlertColor } from '@mui/material';
import { CheckCircleOutline, ReportProblemOutlined } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiAutoFix } from '@mdi/js';
import { useLayout } from 'hooks';
import { useStyles } from './styles';

const SNACKBAR_AUTO_HIDE_DURATION = 5000;

interface Props {
    variant: AlertColor;
    onClose: () => void;
    message: string;
    open?: boolean;
}

const SlideTransition = (props) => {
    const { isXs, isSm, isMd } = useLayout();

    return <Slide {...props} direction={isXs || isSm || isMd ? 'down' : 'left'} />;
};

export const Snackbar: FC<Props> = ({ onClose, message, open = true, variant }) => {
    const { isXs, isSm, isMd } = useLayout();
    const classes = useStyles(variant);
    const { t } = useTranslation();

    return (
        <MuiSnackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: isXs || isSm || isMd ? 'center' : 'right',
            }}
            open={open}
            autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
            onClose={onClose}
            sx={classes.snackBar}
            TransitionComponent={SlideTransition}
        >
            <Alert
                severity={variant}
                iconMapping={{
                    success: <CheckCircleOutline />,
                    error: <ReportProblemOutlined />,
                    info: <Icon path={mdiAutoFix} size={1} />,
                }}
            >
                <Typography>{message}</Typography>
                <Button sx={classes.button} onClick={onClose}>
                    {t('Confirm')}
                </Button>
            </Alert>
        </MuiSnackbar>
    );
};
