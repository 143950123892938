/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Select,
    TextField,
    Typography,
    MenuItem,
} from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import { KeyResultAssignedTo, PROFILE_TYPE, QuestStatus, QuestType } from 'types';
import type { QuestAssignee } from 'types';
import { useLayout, useQuestLogFilter } from 'hooks';
import { api, callService } from 'services';
import { useStyles } from './styles';
import { Avatar } from '../../avatar';

const STATUS_TRANSLATION_KEYS = {
    [QuestStatus.DRAFT]: 'quest.questStatus.draft.label',
    [QuestStatus.ACTIVE]: 'quest.questStatus.active.label',
    [QuestStatus.EVALUATED]: 'quest.questStatus.evaluated.label',
    [QuestStatus.COMPLETED]: 'quest.questStatus.completed.label',
    [QuestStatus.ARCHIVED]: 'quest.questStatus.archived.label',
    [QuestStatus.ABANDONED]: 'quest.questStatus.abandoned.label',
};

const TYPE_TRANSLATION_KEYS = {
    [QuestType.TASK]: 'quest.questType.task.label',
    [QuestType.GROWTH]: 'quest.questType.growth.label',
    [QuestType.SKILL]: 'quest.questType.skill.label',
};

const MIN_LENGTH_FOR_SEARCH = 2;

const ASSIGNED_TO_TYPES = {
    employees: {
        groupKey: 'quest.addPlayerStep.form.assignTo.groups.employees.label',
        type: KeyResultAssignedTo.EMPLOYEE,
    },
    teams: {
        groupKey: 'quest.addPlayerStep.form.assignTo.groups.teams.label',
        type: KeyResultAssignedTo.TEAM,
    },
    jobs: {
        groupKey: 'quest.addPlayerStep.form.assignTo.groups.jobs.label',
        type: KeyResultAssignedTo.JOB,
    },
};

const ASSIGNED_TO_QUERIES = {
    [PROFILE_TYPE.PLAYER]: 'employeeId',
    [PROFILE_TYPE.JOB]: 'jobId',
    [PROFILE_TYPE.JOB_FAMILY]: 'jobFamilyId',
    [PROFILE_TYPE.TEAM]: 'teamId',
};

export const QuestFilter = () => {
    const { assignedToWithType, filter, setQuestLogFilterPreFillDataAction } = useQuestLogFilter();
    const { type, status, query, assignedTo } = filter;
    const { t } = useTranslation();
    const [users, setUsers] = useState<QuestAssignee[]>([]);
    const [assigneesFocused, setAssigneesFocused] = useState(false);
    const [assignees, setAssignees] = useState<QuestAssignee[]>(
        assignedToWithType ? [] : (assignedTo as []),
    );
    const [assigneesQuery, setAssigneesQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { isXs, isSm } = useLayout();
    const classes = useStyles();

    const handleOnQueryChange = (evt) =>
        setQuestLogFilterPreFillDataAction({
            filter: { ...filter, query: sanitize(evt.target.value, { USE_PROFILES: {} }) },
        });

    const handleOnClearQueryClick = () =>
        setQuestLogFilterPreFillDataAction({ filter: { ...filter, query: '' } });

    const handleCreateNewQuestClick = () => navigate('/quest/new');

    const handleQuestTypesChange = (evt) =>
        setQuestLogFilterPreFillDataAction({ filter: { ...filter, type: evt.target.value } });

    const handleOnStatusesChange = (evt) =>
        setQuestLogFilterPreFillDataAction({ filter: { ...filter, status: evt.target.value } });

    const handleOnAssigneesChange = (evt, selectedAssignees) => {
        setAssignees(selectedAssignees);
        setQuestLogFilterPreFillDataAction({
            filter: {
                ...filter,
                assignedTo: selectedAssignees,
            },
        });
    };

    const handleAssigneesDropdownClose = () => {
        setUsers([]);
        setAssigneesQuery('');
    };

    const handleAssigneesFocus = () => setAssigneesFocused(true);

    const handleAssigneesBlur = () => setAssigneesFocused(false);

    const getInputItem = (assigneeItem, key) => ({
        group: t(ASSIGNED_TO_TYPES[key].groupKey),
        type: ASSIGNED_TO_TYPES[key].type,
        name: assigneeItem.title || assigneeItem.displayName,
        id:
            assigneeItem.employeeSysId ||
            assigneeItem.employeeId ||
            assigneeItem.teamId ||
            assigneeItem.jobId,
        profilePhoto: assigneeItem.profilePhoto,
    });

    const parseSearchedUsers = (payload) =>
        Object.keys(payload).reduce(
            (acc, key) => [
                ...acc,
                ...payload[key].map((searchedUser) => getInputItem(searchedUser, key)),
            ],
            [] as any,
        );

    const preFillAssignedTo = async (assignedToPrefill) => {
        if (assignedToPrefill?.id.length) {
            setIsLoading(true);

            const { error, payload } = await callService({
                api: api.objectiveController.objectiveAssignees,
                params: { [ASSIGNED_TO_QUERIES[assignedToPrefill.type]]: assignedToPrefill.id },
            });

            if (!error && payload) {
                const parsedUsers = parseSearchedUsers(payload);

                setUsers(parsedUsers);
                setAssignees([
                    parsedUsers.find((parsedUser) => parsedUser.id === assignedToPrefill.id),
                ]);
                setIsLoading(false);
            }
        }
    };

    const handleUsersSearch = async (evt) => {
        const value = sanitize(evt?.target?.value, { USE_PROFILES: {} });
        setAssigneesQuery(value);

        if (value?.length >= MIN_LENGTH_FOR_SEARCH) {
            setIsLoading(true);

            const { error, payload } = await callService({
                api: api.objectiveController.objectiveAssignees,
                params: { query: value },
            });

            if (!error && payload) {
                setUsers(parseSearchedUsers(payload));
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (assignedToWithType) {
            preFillAssignedTo(assignedToWithType);
        }
    }, []);

    return (
        <>
            <TextField
                type="text"
                value={query}
                onChange={handleOnQueryChange}
                fullWidth
                size="small"
                className="searchInput"
                sx={classes.queryInput}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {!query && <Search />}
                            {query && (
                                <IconButton
                                    onClick={handleOnClearQueryClick}
                                    aria-label={t<string>('quest.log.filter.actions.clear.aria')}
                                    size="large"
                                >
                                    <Clear />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <div className={classes.filterWrapper}>
                <Grid container spacing={2} sx={classes.filters}>
                    <Grid item xs={12} md={4}>
                        <FormControl size="small" sx={classes.filter} fullWidth>
                            <Select
                                multiple
                                value={type}
                                onChange={handleQuestTypesChange}
                                displayEmpty
                                renderValue={() =>
                                    !type.length
                                        ? t('quest.questFilter.allTypes.label')
                                        : `${t('quest.questFilter.types.label', {
                                              count: type.length,
                                          })}`
                                }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                }}
                            >
                                {Object.keys(TYPE_TRANSLATION_KEYS).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        <Checkbox
                                            checked={type.includes(key as QuestType)}
                                            readOnly
                                        />
                                        {t(TYPE_TRANSLATION_KEYS[key])}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl size="small" sx={classes.filter} fullWidth>
                            <Select
                                multiple
                                value={status}
                                onChange={handleOnStatusesChange}
                                displayEmpty
                                renderValue={() =>
                                    !status.length
                                        ? t('quest.questFilter.allStatuses.label')
                                        : `${t('quest.questFilter.status.label', {
                                              count: status.length,
                                          })}`
                                }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                }}
                            >
                                {Object.keys(STATUS_TRANSLATION_KEYS).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        <Checkbox
                                            checked={status.includes(key as QuestStatus)}
                                            readOnly
                                        />
                                        {t(STATUS_TRANSLATION_KEYS[key])}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            value={assignees}
                            multiple
                            onChange={handleOnAssigneesChange}
                            getOptionLabel={(option) => option.name}
                            groupBy={(option) => option.group || ''}
                            filterOptions={(options) => options}
                            renderOption={(props, option) => (
                                <li className={classes.avatarWrapper} {...props}>
                                    <Checkbox
                                        checked={assignees.some(
                                            (assignee) => assignee.id === option.id,
                                        )}
                                        readOnly
                                    />
                                    <Avatar
                                        src={option.profilePhoto}
                                        alt={option.name[0]}
                                        sx={classes.avatar}
                                    />
                                    <Typography>{option.name}</Typography>
                                </li>
                            )}
                            options={assigneesQuery ? users : assignees}
                            onFocus={handleAssigneesFocus}
                            onBlur={handleAssigneesBlur}
                            open={
                                (assigneesFocused &&
                                    !!(assigneesQuery ? users : assignees).length) ||
                                !!assigneesQuery
                            }
                            popupIcon={null}
                            onInputChange={handleUsersSearch}
                            onClose={handleAssigneesDropdownClose}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <div className={classes.assigneesValueLabel}>
                                                {!assignees.length &&
                                                    t('quest.questFilter.allAssignees.label')}
                                                {!!assignees.length &&
                                                    t('quest.questFilter.assignees.label', {
                                                        count: assignees.length,
                                                    })}
                                            </div>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {isLoading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {!(isXs || isSm) && (
                    <div className={classes.createQuestButton}>
                        <Button variant="contained" onClick={handleCreateNewQuestClick}>
                            {t('quest.log.actions.createNewQuest.label')}
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};
