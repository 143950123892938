import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.userRoles;

const UserRoleLoader = Loadable({
    loader: () => import('views/admin/UserRole'),
    loading: Loading,
    render(loaded, props) {
        const { UserRole } = loaded;
        return <UserRole {...props} />;
    },
    delay: general.delay,
});

const UserRoleEditLoader = Loadable({
    loader: () => import('views/admin/UserRole/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const userRoleRoute = {
    path: `/${mainRoute}`,
    component: UserRoleLoader,
};

export const userRoleCreateRoute = {
    path: `/${mainRoute}/create`,
    component: UserRoleEditLoader,
};
