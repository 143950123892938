import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import { CardContent, Chip, Typography } from '@mui/material';
import type { Quest } from 'types';
import type { AppReducerState } from 'store';
import { QuestDuration } from '../QuestDuration';
import { KeyResultProgress } from '../KeyResultProgress';
import { Image } from '../../Image';
import { useStyles } from './styles';

interface Props {
    quest: Quest;
}

export const QuestDetailKeyResults: FC<Props> = ({ quest }) => {
    const { allCompetencies } = useSelector(({ competencies }: AppReducerState) => competencies);
    const { t } = useTranslation();
    const classes = useStyles();
    const { keyResults, relatedCompetency, relatedSkills } = quest;
    const competency = allCompetencies.find((comp) => comp.compSysId === relatedCompetency);

    return (
        <CardContent>
            <QuestDuration quest={quest} />
            {keyResults
                ?.sort((first, second) => first.order - second.order)
                .map((keyResult) => (
                    <KeyResultProgress keyResult={keyResult} />
                ))}
            {!!relatedSkills?.length && (
                <>
                    <Typography variant="h4">
                        {t('quest.questDetailKeyResults.relatedSkills.label')}
                    </Typography>
                    {relatedSkills.map((relatedSkill) => (
                        <Chip
                            label={sanitize(relatedSkill, { USE_PROFILES: {} })}
                            size="small"
                            variant="outlined"
                            sx={classes.relatedSkill}
                            key={relatedSkill}
                        />
                    ))}
                </>
            )}
            {competency && (
                <>
                    <Typography variant="h4">
                        {t('quest.questDetailKeyResults.relatedCompetency.label')}
                    </Typography>
                    <div className={classes.competencyWrapper}>
                        <Image
                            src={competency.photoPath}
                            alt={competency.descriptionShort}
                            className={classes.competencyImage}
                        />
                        <Typography>
                            {sanitize(competency.descriptionShort, { USE_PROFILES: {} })}
                        </Typography>
                    </div>
                </>
            )}
        </CardContent>
    );
};
