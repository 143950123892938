import { frankyTalesTheme } from './frankyTalesTheme';

const { colors, buttonColor, buttonColorHover, buttonTextColor } = frankyTalesTheme;

const root = {
    '& .MuiButtonBase-root.MuiButton-root': {
        borderColor: colors.skyBlue,
    },
};

export const MuiButtonGroup = {
    styleOverrides: {
        root,
    },
};
