import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Chip, TableCell, TableRow, Typography } from '@mui/material';
import type { Notification } from 'types';
import { useLayout } from 'hooks';

import { useStyles } from './styles';
import { EventRowAvatar } from '../EventRowAvatar';
import { EventRowMessage } from '../EventRowMessage';
import { RecipientsList } from '../RecipientsList';
import { Coins } from '../../Coins';
import { Experience } from '../../Experience';

interface Props {
    streamItem: Notification;
    showSecondaryPhoto?: boolean;
}

export const EventRow = ({ showSecondaryPhoto = true, streamItem }: Props) => {
    const { isXs } = useLayout();
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <TableRow key={streamItem.activityStreamId} sx={classes.row}>
            {!isXs && (
                <TableCell sx={classes.employeeAvatarWrapper} align="center">
                    <EventRowAvatar streamItem={streamItem} />
                </TableCell>
            )}
            <TableCell>
                <div>
                    {streamItem.isNew && (
                        <Chip label={t('eventRow.badgeNew.label')} size="small" sx={classes.chip} />
                    )}
                    <Typography variant="caption">
                        {moment(streamItem.date).format('DD.MM.YYYY')}
                        {` • ${streamItem.title}`}
                    </Typography>
                </div>
                <EventRowMessage streamItem={streamItem} />
                {streamItem.subtitle && (
                    <Typography variant="caption" component="p">
                        {streamItem.subtitle}
                    </Typography>
                )}
            </TableCell>
            <TableCell sx={classes.characterAvatarWrapper} align="right">
                {showSecondaryPhoto && (
                    <div className={classes.recipientsWrapper}>
                        <RecipientsList
                            entitiesTo={streamItem.entitiesTo}
                            className={classes.recipientsList}
                        />
                        <Coins value={streamItem.coins || 0} displaySign />
                        <Experience value={streamItem.xp} />
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
};
