import { useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FEEDBACK_TYPE } from 'types';
import type { Feedback } from 'types';
import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import { useBoundActions, useLayout, usePagination } from 'hooks';
import { api } from 'services';
import { resetUnseenFeedback } from 'store/slices/user';
import { Loading } from '../../Loading';
import { InfiniteScroll } from '../../InfiniteScroll';
import { FeedbackListDraftRow } from '../FeedbackListDraftRow';
import { FeedbackListSentRow } from '../FeedbackListSentRow';
import { FeedbackListReceivedRow } from '../FeedbackListReceivedRow';

const FEEDBACK_TYPE_TITLE_KEYS = {
    [FEEDBACK_TYPE.RECEIVED]: 'myFeedback.feedbackList.table.title.received.label',
    [FEEDBACK_TYPE.SENT]: 'myFeedback.feedbackList.table.title.sent.label',
    [FEEDBACK_TYPE.DRAFT]: 'myFeedback.feedbackList.table.title.drafts.label',
};

const FEEDBACK_ROW_COMPONENTS = {
    [FEEDBACK_TYPE.RECEIVED]: FeedbackListReceivedRow,
    [FEEDBACK_TYPE.SENT]: FeedbackListSentRow,
    [FEEDBACK_TYPE.DRAFT]: FeedbackListDraftRow,
};

interface Props {
    query?: string;
    type: FEEDBACK_TYPE;
}

export const FeedbackList: FC<Props> = ({ query = '', type }) => {
    const { t } = useTranslation();
    const title = t(FEEDBACK_TYPE_TITLE_KEYS[type]);
    const { isXs } = useLayout();
    const {
        isLoading,
        callService,
        cancelServiceCall,
        items,
        currentPage,
        nextPageNumber,
        resetPaging,
        setItems,
        wasReset,
    } = usePagination<Feedback>({
        api: api.getFeedbackAdvices,
        query: { direction: type, query },
    });
    const { resetUnseenFeedbackAction } = useBoundActions({ resetUnseenFeedback });

    useEffect(() => {
        if (type === FEEDBACK_TYPE.RECEIVED) {
            resetUnseenFeedbackAction();
        }

        return () => cancelServiceCall();
    }, []);

    useEffect(() => {
        resetPaging();
    }, [query]);

    useEffect(() => {
        if (wasReset) {
            callService();
        }
    }, [wasReset]);

    const handleFeedbackDelete = (feedbackId: string) =>
        setItems(items.filter(({ feedAdviceSysId }) => feedAdviceSysId !== feedbackId));

    const handleFeedbackRated = () => resetPaging();

    const FeedbackRowComponent = FEEDBACK_ROW_COMPONENTS[type];

    return (
        <TableContainer component={Paper}>
            <Toolbar>
                <Typography variant="h4" component="h2">
                    {title}
                </Typography>
            </Toolbar>
            <Divider />
            <InfiniteScroll
                active={!isLoading && !!items.length && currentPage < nextPageNumber}
                onEndOfScreen={callService}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            {!isXs && (
                                <TableCell>
                                    <Typography>
                                        {t('myFeedback.feedbackList.table.header.character.label')}
                                    </Typography>
                                </TableCell>
                            )}
                            <TableCell>
                                <Typography>
                                    {t('myFeedback.feedbackList.table.header.feedback.label')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {type === FEEDBACK_TYPE.DRAFT
                                        ? t('myFeedback.feedbackList.table.header.character.label')
                                        : t(
                                              'myFeedback.feedbackList.table.header.pointsCoins.label',
                                          )}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((feedback) => (
                            <FeedbackRowComponent
                                key={feedback.feedAdviceSysId}
                                feedback={feedback}
                                onFeedbackDelete={
                                    type === FEEDBACK_TYPE.DRAFT ? handleFeedbackDelete : undefined
                                }
                                onFeedbackRated={
                                    type === FEEDBACK_TYPE.RECEIVED
                                        ? handleFeedbackRated
                                        : undefined
                                }
                            />
                        ))}
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Loading transitionEnterDuration={0} transitionDelayIn={0} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </InfiniteScroll>
        </TableContainer>
    );
};
