import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = () => {
    const theme = useTheme();

    return {
        axisLine: css({
            strokeWidth: 1,
            stroke: colors.grey,
            fill: 'none',
        }),
        tick: css({
            strokeWidth: 1,
            stroke: colors.grey,
        }),
        tickText: css({
            color: colors.grey,
            fontSize: theme.spacing(1),
        }),
        rulerLine: {
            strokeWidth: 1,
            stroke: colors.grey,
            fill: 'none',
        },
    };
};
