import { useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useBoundActions, useService } from 'hooks';
import type { AppReducerState } from 'store';
import { Loading } from 'components';
import { api } from 'services';
import { logOutUser, acceptPolicy, rejectPolicy } from 'store/slices/user';
import type { Policy } from 'types';
import { useStyles as useCommonStyles } from '../common/styles';
import { useStyles } from './styles';

export const Gdpr: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { acceptPolicyAction, rejectPolicyAction, logOutUserAction } = useBoundActions({
        acceptPolicy,
        rejectPolicy,
        logOutUser,
    });
    const { policyAccepted, policyInformation } = useSelector(
        (state: AppReducerState) => state.user,
    );
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const { cancelServiceCall, data, error, isLoading } = useService<Policy>({
        api: api.policiesController.getPolicy,
        params: [policyInformation?.activePolicySysId],
    });

    useEffect(() => {
        if (policyAccepted) {
            navigate('/dashboard');
        }

        return () => cancelServiceCall();
    }, []);

    const gdprAgree = async () => {
        await acceptPolicyAction(data?.id);
        navigate('/dashboard');
    };

    const gdprReject = async () => {
        await rejectPolicyAction(data?.id);
        logOutUserAction();
        navigate('/login');
    };

    if (isLoading) {
        return <Loading />;
    }

    if (!data || error) {
        return null;
    }

    return (
        <div className={commonClasses.container}>
            <Dialog open className="bigPadding" sx={classes.gdprDialog}>
                <DialogTitle>
                    <Typography variant="h1" component="h1" sx={commonClasses.title}>
                        {t('gdpr.title.label')}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={classes.contentWrapper}>
                    <div dangerouslySetInnerHTML={{ __html: sanitize(data.policyText) }} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" type="submit" onClick={gdprAgree}>
                        {t('gdpr.actions.agree.label')}
                    </Button>
                    <Button onClick={gdprReject}>{t('gdpr.actions.reject.label')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
