import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        avatarTitle: {
            textAlign: 'center',
            fontWeight: theme.typography.fontWeightBold,
        },
        avatar: {
            width: theme.spacing(12),
            height: theme.spacing(12),
            margin: 'auto',
        },
        employeeAvatar: css({
            width: theme.spacing(12),
            height: theme.spacing(12),
            margin: 'auto',
        }),
        employeeAvatarImage: css({
            height: 'inherit',
            width: 'inherit',
        }),
        listItemLabel: {
            maxWidth: '33%',
        },
        listItemValue: {
            maxWidth: '66%',

            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        valuesList: {
            '& .MuiListItem-root': {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        valuesWrapper: {
            backgroundColor: '#f4f6f8',
        },
        switch: {
            '& .MuiSwitch-track': {
                backgroundColor: `${colors.grey} !important`,
            },

            '& .Mui-checked + .MuiSwitch-track': {
                backgroundColor: `${colors.skyBlue} !important`,
            },

            '& .MuiSwitch-switchBase.Mui-checked': {
                color: colors.blue,
            },

            '& .MuiSwitch-thumb': {
                boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.5)',
            },
        },
        anonymousWrapper: {
            display: 'flex',
            alignItems: 'baseline',
        },
    };
};
