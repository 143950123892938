import { useEffect, useRef } from 'react';
import type { FC } from 'react';
import * as d3 from 'd3';
import cx from 'classnames';
import { QuestProgressChartArc } from './QuestProgressChartArc';
import { useStyles } from './styles';

const ARC_WIDTH = 22;
const ARC_MARGIN = 4;
const AVERAGE_VALUE_RADIUS = 30;
const MINIMAL_RADIUS = 105;
const TRANSITION_DURATION = 500;

interface Props {
    className?: string;
    onHover: (idx: number | undefined) => void;
    values: number[];
    highlightedKeyResult: number | undefined;
}

export const QuestProgressChart: FC<Props> = ({
    className,
    onHover,
    values,
    highlightedKeyResult,
}) => {
    const textArcRef = useRef<SVGTextElement>(null);
    const classes = useStyles();

    const radius =
        values.length === 1
            ? MINIMAL_RADIUS
            : values.length * (ARC_WIDTH + ARC_MARGIN) + AVERAGE_VALUE_RADIUS;
    const averageValue = values.reduce((a, b) => a + b, 0) / values.length;

    const formatText = d3.format('.0d');

    const interpolate = d3.interpolate(0, averageValue);

    const tween = () => (val) => `${formatText(interpolate(val))}%`;

    useEffect(() => {
        if (!Number.isNaN(averageValue)) {
            d3.select(textArcRef.current)
                .transition()
                .duration(TRANSITION_DURATION)
                .textTween(tween);
        }
    }, [averageValue]);

    return (
        <svg width={radius * 2} height={radius * 2} className={cx(className, classes.root)}>
            <g transform={`translate(${radius},${radius})`}>
                {!Number.isNaN(averageValue) != null && (
                    <text ref={textArcRef} className={classes.valueText} dy=".5rem" />
                )}
                {values.map((value, idx) => (
                    <QuestProgressChartArc
                        index={idx}
                        value={value}
                        disabled={
                            highlightedKeyResult !== undefined && idx !== highlightedKeyResult
                        }
                        radius={radius - idx * (ARC_WIDTH + ARC_MARGIN)}
                        width={ARC_WIDTH}
                        arcMargin={ARC_MARGIN}
                        onHover={onHover}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${idx}${value}`}
                        transitionDuration={TRANSITION_DURATION}
                    />
                ))}
            </g>
        </svg>
    );
};
