import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        progressBar: {
            height: theme.spacing(1),
            borderRadius: theme.spacing(2),
            color: colors.blue,

            '& .MuiLinearProgress-bar': {
                backgroundColor: colors.blue,
            },

            '&.MuiLinearProgress-colorPrimary': {
                backgroundColor: colors.greyLighter2,
            },
        },
        label: css({
            marginTop: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',

            '& .MuiTypography-root': {
                color: colors.grey,
            },
        }),
    };
};
