export const MuiDialogActions = {
    styleOverrides: {
        root: {
            justifyContent: 'center',
        },
        spacing: {
            '& > :not(:first-child)': {
                marginLeft: '16px',
            },
        },
    },
};
