import type { FC } from 'react';
import { useEffect } from 'react';
import type { Avatar } from 'types';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { api } from 'services';
import { Image } from 'components';
import { TooltipColor } from 'types';
import { useImage } from 'hooks';
import { useStyles } from './styles';
import { AvatarPersonalityBar } from '../AvatarPersonalityBar';
import { InformationTooltip } from '../../InformationTooltip';

interface Props {
    avatar: Avatar;
    onSelectAvatarClick: (...args: any[]) => void;
    onSelectAvatarKeyUp: (...args: any[]) => void;
    raised?: boolean;
    tabIndex?: number;
}

export const FeedbackAvatarCard: FC<Props> = ({
    avatar,
    onSelectAvatarClick = () => {},
    onSelectAvatarKeyUp = () => {},
    raised = false,
    tabIndex,
}) => {
    const { cancelServiceCall, image } = useImage(avatar.photoPath);

    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        return () => {
            cancelServiceCall();
        };
    }, []);

    return (
        <Card
            tabIndex={tabIndex != null ? tabIndex : undefined}
            onClick={onSelectAvatarClick}
            onKeyUp={onSelectAvatarKeyUp}
            elevation={raised ? 2 : 0}
            sx={classes.card}
        >
            <CardMedia image={image} title={avatar.title} sx={classes.avatarImage} />
            <div className={classes.cardContentWrapper}>
                <CardContent>
                    <Typography variant="h3">{avatar.title}</Typography>
                    <AvatarPersonalityBar
                        value={avatar.howEmotional}
                        labelLeft={t('feedback.feedbackAvatarCard.personality.direct.label')}
                        labelRight={t('feedback.feedbackAvatarCard.personality.diplomatic.label')}
                    />
                    <AvatarPersonalityBar
                        value={avatar.howPolite}
                        labelLeft={t('feedback.feedbackAvatarCard.personality.brisk.label')}
                        labelRight={t('feedback.feedbackAvatarCard.personality.polite.label')}
                    />
                    <AvatarPersonalityBar
                        value={avatar.howSimple}
                        labelLeft={t('feedback.feedbackAvatarCard.personality.brief.label')}
                        labelRight={t(
                            'feedback.feedbackAvatarCard.personality.sophisticated.label',
                        )}
                    />
                    <AvatarPersonalityBar
                        value={avatar.howHumble}
                        labelLeft={t('feedback.feedbackAvatarCard.personality.humble.label')}
                        labelRight={t('feedback.feedbackAvatarCard.personality.proud.label')}
                    />
                    <InformationTooltip
                        color={TooltipColor.BLUE}
                        tooltip={`${avatar.description} ${avatar.helpfulHow}`}
                        text={t<string>('feedback.feedbackAvatarCard.moreAbout.label', {
                            avatarTitle: avatar.title,
                        })}
                        className={classes.moreAbout}
                    />
                </CardContent>
            </div>
        </Card>
    );
};
