import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { readString } from 'react-papaparse';
import { useStyles } from './styles';

export const CsvImport = ({ setResult }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const inputFile = useRef(null);

    const handleImportFileClick = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputFile?.current?.click();
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const handleUploadFile = (fileName) => {
        const reader = new FileReader();

        reader.onload = async (e) => {
            if (e.target?.result) {
                setResult(
                    readString(e.target.result as string, {
                        header: true,
                        skipEmptyLines: true,
                        dynamicTyping: true,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        encoding: 'UTF-8',
                    }),
                );
            }
        };

        reader.readAsText(fileName);
    };

    const dropHandler = (ev) => {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    const x = ev.dataTransfer.items[i].getAsFile();
                    handleUploadFile(x);
                }
            }
        }

        ev.target.value = '';
    };

    const showFile = async (e) => {
        const fileName = e.target.files[0];
        handleUploadFile(fileName);
        e.target.value = '';
    };

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                className={classes.csvImportContainer}
                onDrop={dropHandler}
                onDragOver={allowDrop}
                onClick={handleImportFileClick}
            >
                Click to browse or drag&drop the file
            </div>
            <input
                type="file"
                id="file"
                ref={inputFile}
                onChange={showFile}
                style={{ display: 'none' }}
            />
        </>
    );
};
