import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        cardContent: {
            height: theme.spacing(25),
        },
        graphTitle: css({
            textTransform: 'uppercase',
            color: colors.grey,
        }),
    };
};
