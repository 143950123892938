import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { Competency, EmployeeCompetency } from 'types';
import { TooltipColor } from 'types';
import {
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { api, callService } from 'services';
import { Image } from 'components';
import { useStyles } from './styles';
import { Loading } from '../../Loading';
import { InformationTooltip } from '../../InformationTooltip';

interface Props {
    employeeSysId: string;
}

export const ValuablePlayerTopicsSelector: FC<Props> = ({ employeeSysId }) => {
    const [competencies, setCompetencies] = useState<Competency[]>();
    const [employeeCompetencies, setEmployeeCompetencies] = useState<EmployeeCompetency[]>();
    const { t } = useTranslation();
    const classes = useStyles();

    const fetchCompetencies = async () => {
        const competenciesCall = callService({
            api: api.getCompetenciesByEmployeeId,
            params: [employeeSysId],
        });

        const employeeCompetenciesCall = callService({
            api: api.getEmployeeCompetency,
            params: [employeeSysId],
        });

        const [competenciesResult, employeeCompetenicesResult] = await Promise.all([
            competenciesCall,
            employeeCompetenciesCall,
        ]);

        if (!competenciesResult.error && competenciesResult.payload) {
            setCompetencies(competenciesResult.payload);
        }

        if (!employeeCompetenicesResult.error && employeeCompetenicesResult.payload) {
            setEmployeeCompetencies(employeeCompetenicesResult.payload);
        }
    };

    useEffect(() => {
        fetchCompetencies();
    }, []);

    const handleCompetencyCheck = (competencySysId) => async (evt) => {
        if (evt.target.checked) {
            const { error, payload } = await callService({
                api: api.createEmployeeCompetency,
                body: { competencySysId },
            });

            if (!error && payload) {
                setEmployeeCompetencies([
                    ...(employeeCompetencies as EmployeeCompetency[]),
                    payload,
                ]);
            }
        } else {
            const employeeCompetency = employeeCompetencies!.find(
                (competency) => competency.competencySysId === competencySysId,
            );

            const { error, payload } = await callService({
                api: api.deleteEmployeeCompetency,
                params: [employeeCompetency!.employeeCompetencySysId],
            });

            if (!error && payload) {
                setEmployeeCompetencies(
                    employeeCompetencies!.filter(
                        (competency) =>
                            competency.employeeCompetencySysId !== payload.employeeCompetencySysId,
                    ),
                );
            }
        }
    };

    if (!competencies || !employeeCompetencies) {
        return <Loading />;
    }

    return (
        <>
            <div className={classes.titleWrapper}>
                <Typography variant="h2" component="h4" sx={classes.title}>
                    {t('profile.valuablePlayerTopicsSelector.header.title')}
                </Typography>
                <InformationTooltip
                    color={TooltipColor.BLUE}
                    tooltip={t<string>(
                        'profile.valuablePlayerTopicsSelector.header.information.tooltip',
                    )}
                    text={t<string>(
                        'profile.valuablePlayerTopicsSelector.header.information.title',
                    )}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {competencies.map((competency) => (
                            <TableRow key={competency.compSysId}>
                                <TableCell component="th" sx={classes.actionsWrapper}>
                                    <Switch
                                        onChange={handleCompetencyCheck(competency.compSysId)}
                                        checked={employeeCompetencies.some(
                                            ({ competencySysId }) =>
                                                competencySysId === competency.compSysId,
                                        )}
                                        sx={classes.switch}
                                    />
                                </TableCell>
                                <TableCell component="th">
                                    <Typography variant="h5">
                                        {competency.descriptionShort}
                                    </Typography>
                                    <Typography>{competency.descriptionLong}</Typography>
                                </TableCell>
                                <TableCell component="th" sx={classes.competencyImage}>
                                    <Image
                                        src={competency.photoPath}
                                        alt={competency.descriptionShort}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
