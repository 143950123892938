import type { FC, ReactNode } from 'react';
import { useStyles } from './styles';

interface Props {
    children: ReactNode;
}

export const FtTitle: FC<Props> = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.titleContainer}>{children}</div>;
};
