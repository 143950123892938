import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { AppReducerState } from 'store';
import type { SideMenuItem as SideMenuItemType } from 'types';
import { Badge, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useStyles } from './styles';

interface Props {
    menuItem: SideMenuItemType;
    onMenuItemClick?: (...args: any[]) => void;
}

export const FeedbackMenuItem: FC<Props> = ({ menuItem, onMenuItemClick = () => {} }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { feedbackReceivedNotSeen } = useSelector(({ user }: AppReducerState) => user.current);
    const classes = useStyles();
    const { labelKey = '', url } = menuItem;

    return (
        <ListItemButton
            key={labelKey}
            onClick={onMenuItemClick}
            disabled={!url}
            selected={location.pathname === url}
            sx={classes.itemButton}
        >
            <ListItemText
                primary={
                    <>
                        <Typography variant="button">{t(labelKey)}</Typography>
                        {!!feedbackReceivedNotSeen && (
                            <Badge
                                badgeContent={feedbackReceivedNotSeen}
                                color="primary"
                                sx={classes.badge}
                            >
                                {''}
                            </Badge>
                        )}
                    </>
                }
            />
        </ListItemButton>
    );
};
