import type { FC } from 'react';
import { format, max, scaleLinear } from 'd3';
import type { ChartTuple } from 'types';
import { RadarChartArea } from './RadarChartArea';
import { RadarChartLabel } from './RadarChartLabel';
import { useStyles } from './RadarChart.styles';

const MIN_VALUE = 0;
const MAX_VALUE = 100;
const RULER_TICKS = [0.2, 0.4, 0.6, 0.8, 1];

interface Props {
    data: { color: string; data: ChartTuple<string>[] }[];
    radius: number;
}

export const RadarChart: FC<Props> = ({ radius, data }) => {
    const classes = useStyles();

    const maxDataValue = Math.max(
        MIN_VALUE,
        max(data, (valueSet) => max(valueSet.data.map((value) => value.value))) as number,
    );
    const maxValue = Math.max(maxDataValue, MAX_VALUE);
    const axisNames = data[0].data.map((value) => value.key);
    const total = axisNames.length;
    const formatPercentage = format('.0%');
    const sliceAngle = (Math.PI * 2) / total;

    const scale = scaleLinear().range([0, radius]).domain([MIN_VALUE, maxValue]);

    return (
        <div className={classes.chartWrapper}>
            <svg width={radius * 2} height={radius * 2} className={classes.root}>
                <g transform={`translate(${radius},${radius})`}>
                    {RULER_TICKS.map((value) => (
                        <g key={value}>
                            <circle
                                cx={0}
                                cy={0}
                                r={radius * value}
                                className={classes.rulerCircle}
                            />
                            <text
                                key={`ruler-circle-text-${value}`}
                                x={4}
                                y={-radius * value - 4}
                                className={classes.rulerCircleText}
                            >
                                {formatPercentage(value)}
                            </text>
                        </g>
                    ))}
                    {axisNames.map((name, index) => (
                        <g key={`ruler-${name}`}>
                            <line
                                x1={0}
                                y1={MIN_VALUE}
                                x2={
                                    scale(maxValue * 1.2) *
                                    Math.cos(sliceAngle * index - Math.PI / 2)
                                }
                                y2={
                                    scale(maxValue * 1.2) *
                                    Math.sin(sliceAngle * index - Math.PI / 2)
                                }
                                className={classes.rulerLine}
                            />
                            <RadarChartLabel
                                scale={scale}
                                index={index}
                                label={name}
                                sliceAngle={sliceAngle}
                            />
                        </g>
                    ))}
                    {data.map((lineData, index) => (
                        <RadarChartArea
                            scale={scale}
                            sliceAngle={sliceAngle}
                            lineData={lineData}
                            index={index}
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                        />
                    ))}
                </g>
            </svg>
        </div>
    );
};
