import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        stepLabelWrapper: css({
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        }),
        stepLabel: css({
            flex: '2',
        }),
        stepArrow: css({
            flex: '1',
        }),
        backButton: {
            marginLeft: theme.spacing(-1),
        },
        stepper: {
            backgroundColor: 'transparent',
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(1),

            '& .MuiStepIcon-active': {
                color: colors.blue,
            },

            '& .Mui-disabled': {
                '& svg': {
                    border: `1px solid ${colors.grey}`,
                    borderRadius: theme.spacing(3),
                    color: 'transparent',
                },
                '& .MuiStepIcon-text': {
                    fill: colors.grey,
                },
            },
        },
        progress: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            height: theme.spacing(1),
            borderRadius: theme.spacing(1 / 2),
        },
    };
};
