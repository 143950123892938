import { useContext } from 'react';
import type { FC } from 'react';
import { sanitize } from 'dompurify';
import { Paper, Typography } from '@mui/material';
import type { KeyResult } from 'types';
import { QuestDetailContext } from 'contexts';
import { useStyles } from './styles';

interface Props {
    className?: string;
    keyResult: KeyResult;
}

export const KeyResultProgress: FC<Props> = ({ className, keyResult }) => {
    const { order, progressPercent } = keyResult;
    const { highlightedKeyResult, setHighlightedKeyResult } = useContext(QuestDetailContext);
    const highlighted = order === highlightedKeyResult;
    const classes = useStyles(order, highlighted);

    const handleMouseEnter = () => setHighlightedKeyResult(order);

    const handleMouseLeave = () => setHighlightedKeyResult(undefined);

    return (
        <div
            className={classes.bounceWrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Paper
                className={className}
                sx={classes.keyResultProgress}
                elevation={highlighted ? 1 : 0}
            >
                <Typography>
                    {sanitize(keyResult.descriptionShort, { USE_PROFILES: {} })}
                </Typography>
                <Typography>
                    <strong>{`${progressPercent || 0}%`}</strong>
                </Typography>
            </Paper>
        </div>
    );
};
