import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        inputChip: {
            '& .MuiChip-root': {
                marginTop: 0,
                marginBottom: 0,
                backgroundColor: colors.blue,
                color: colors.white,

                '& .MuiSvgIcon-root': {
                    color: colors.white,
                },
            },
        },
        suggestedSkills: css({
            marginTop: theme.spacing(2),

            '& > .MuiTypography-root': {
                color: colors.grey,
            },
        }),
        suggestedSkill: {
            borderColor: colors.blue,
            color: colors.blue,
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    };
};
