import type { FC } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import type { IAutocompleteView } from 'types';

interface Props {
    value?: string;
    required?: boolean;
    title: string;
    name: string;
    disabled?: boolean;
    list: IAutocompleteView[];
    onChange: any;
}

const Dropdown: FC<Props> = ({
    value,
    title,
    name,
    required = false,
    list,
    disabled = false,
    onChange,
}) => (
    <FormControl fullWidth>
        <InputLabel id={`${name}-label`}>{`${title} ${(required && '*') || ''}`}</InputLabel>
        <Select
            disabled={disabled}
            value={value || ''}
            labelId={`${name}-label`}
            label={`${title} ${required && '*'}`}
            onChange={onChange}
        >
            {list &&
                list.map((x: IAutocompleteView) => {
                    return (
                        <MenuItem key={x.id} value={x.id}>
                            {x.displayName}
                        </MenuItem>
                    );
                })}
        </Select>
    </FormControl>
);

export default Dropdown;
