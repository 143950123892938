export enum COMPANY_SIZE {
    TWENTY = '20',
    FIFTY = '50',
    HUNDRED = '100',
    TWO_HUNDRED_FIFTY = '250',
    FIFE_HUNDRED = '500',
    ONE_THOUSAND_FIVE_HUNDRED = '1500',
    ONE_THOUSAND_FIVE_HUNDRED_PLUS = '1500+',
}

export enum TEAM_SIZE {
    FIVE = '5',
    TEN = '10',
    FIFTEEN = '15',
    TWENTY_FIVE = '25',
    FIFTY = '50',
    FIFTY_PLUS = '50+',
}

export enum ONBOARDING_ROLE {
    BUSINESS_OWNER = 'BUSINESS_OWNER',
    TEAM_LEADER = 'TEAM_LEADER',
    TEAM_MEMBER = 'TEAM_MEMBER',
}
