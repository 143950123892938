import { useTheme } from '@mui/material';

const CARD_IMAGE_HEIGHT = '50vh';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        feedbackImage: {
            backgroundSize: 'contain',
            height: CARD_IMAGE_HEIGHT,
            minHeight: CARD_IMAGE_HEIGHT,
            backgroundColor: theme.palette.grey.A100,
        },
        marginTop: {
            marginTop: theme.spacing(4),
        },
        header: {
            textAlign: 'center',
        },
    };
};
