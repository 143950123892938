import axios from 'axios';
import type {
    IAvatarAdviceView,
    IUpsertAvatarAdviceCommand,
    IApiResponse,
    ISearch,
    ISearchView,
} from 'types';
import { api } from '../constants';

const controllerApi = `${api.avatarAdvices}`;

interface AvatarAdvicesSearch extends ISearch {
    performanceCycleSysId: string;
}

export const getAvatarAdvices = async (
    queryParams: AvatarAdvicesSearch,
): Promise<ISearchView<IAvatarAdviceView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const getBySysId = async (avatarAdviceSysId: string): Promise<IAvatarAdviceView> => {
    return axios.get(`${controllerApi}/${avatarAdviceSysId}`).then((res) => res.data);
};

export const create = async (data: IUpsertAvatarAdviceCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (
    id: string,
    data: IUpsertAvatarAdviceCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const bulkImportValidation = async ({ bulkImportData }) => {
    return axios.put(`${controllerApi}/bulkImportValidate`, {
        importTheData: false,
        BulkImportFeedAvatarAdvices: bulkImportData,
    });
};

export const bulkImport = async ({ bulkImportData }) => {
    return axios.put(`${controllerApi}/bulkImportValidate`, {
        importTheData: true,
        BulkImportFeedAvatarAdvices: bulkImportData,
    });
};

export const deleteAvatarAdvices = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
