import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardContent, Grid, Typography } from '@mui/material';
import { USER_ROLES } from 'types';
import { CR } from '../CR';
import { RaisedCard } from '../../RaisedCard';
import { useStyles } from './styles';

interface Props {
    allowedRoles?: USER_ROLES[];
    label: string;
    path: string;
}

export const SectionCard: FC<Props> = ({
    allowedRoles = [USER_ROLES.ADMIN_HR, USER_ROLES.CONFIGURATION_MANAGER, USER_ROLES.MANAGER],
    label,
    path,
}) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const handleOnClick = () => navigate(path);

    return (
        <CR allowedRoles={allowedRoles}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <RaisedCard onClick={handleOnClick}>
                    <CardContent sx={classes.gridContent}>
                        <Typography variant="h4" align="center">
                            {label}
                        </Typography>
                    </CardContent>
                </RaisedCard>
            </Grid>
        </CR>
    );
};
