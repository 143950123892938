import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.rewardSystem;

const RewardSystemLoader = Loadable({
    loader: () => import('views/admin/RewardSystem'),
    loading: Loading,
    render(loaded, props) {
        const { RewardSystem } = loaded;
        return <RewardSystem {...props} />;
    },
    delay: general.delay,
});

export const rewardSystemRoute = {
    path: `/${mainRoute}`,
    component: RewardSystemLoader,
};
