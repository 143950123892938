import axios from 'axios';
import type {
    IApiResponse,
    ISearchView,
    IRelationEmployeeManagerView,
    ICreateRelationEmployeeManagerCommand,
} from 'types';
import { api } from '../constants';

const controllerApi = api.relationEmployeeManagers;

export const getManagers = async (
    searchEmployee: string,
    searchManager: string,
    pageIndex: number,
    pageSize: number,
): Promise<ISearchView<IRelationEmployeeManagerView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: {
                searchEmployee,
                searchManager,
                pageIndex,
                pageSize,
            },
        })
        .then((res) => res.data);
};

export const getBySysId = async (
    relationEmployeeManagerSysId: string,
): Promise<IRelationEmployeeManagerView> => {
    return axios.get(`${controllerApi}/${relationEmployeeManagerSysId}`).then((res) => res.data);
};

export const create = async (
    data: ICreateRelationEmployeeManagerCommand,
): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const deleteManager = async (id: string): Promise<IApiResponse> => {
    return axios.delete(`${controllerApi}/${id}`);
};

export const updateDateTo = async (id: string): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`);
};
