import axios from 'axios';
import type { IApiResponse, ISearchView, IStoreTransactionView } from 'types';
import { api } from '../constants';

const controllerApi = api.storeTransactions;

export const search = async (queryParams: any): Promise<ISearchView<IStoreTransactionView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const getBySysId = async (id: string): Promise<IStoreTransactionView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};

export const markAsDelivered = async (id: string): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/mark-as-delivered/${id}`);
};

export const markAsCanceled = async (id: string): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/mark-as-canceled/${id}`);
};
