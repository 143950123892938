// import { MuiAutocomplete } from './MuiAutocomplete';

const root = {
    '&.searchInput': {
        '& .MuiInputBase-root': {
            borderRadius: '20px',
        },
    },

    // '& .MuiInputBase-adornedStart': MuiAutocomplete.startAdornment,

    // '& .MuiAutocomplete-endAdornment': MuiAutocomplete.endAdornment,
};

export const MuiFormControl = {
    styleOverrides: {
        root,
    },
};
