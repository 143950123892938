import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs as MuiBreadCrumbs, Typography } from '@mui/material';
import { useStyles } from './styles';

const EDIT_PATH = 'edit';
const DETAIL_PATH = 'detail';

export const Breadcrumbs = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const classes = useStyles();

    const pathnames = pathname.split('/').filter((path) => path);
    const editIndex = pathnames.indexOf(EDIT_PATH);
    const detailIndex = pathnames.indexOf(DETAIL_PATH);
    const stopIndex = Math.max(editIndex, detailIndex);

    return (
        <MuiBreadCrumbs aria-label={t('admin.breadcrumb.label')} sx={classes.breadcrumbs}>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                if (stopIndex > 0 && index > stopIndex) {
                    return (
                        <Typography color="text.primary" variant="button">
                            {value}
                        </Typography>
                    );
                }

                if (last || value === EDIT_PATH || value === DETAIL_PATH) {
                    return (
                        <Typography color="text.primary" key={value} variant="button">
                            {t(`admin.breadcrumb.paths.${value}.label`)}
                        </Typography>
                    );
                }

                return (
                    <Link key={value} to={to}>
                        <Typography key={value} variant="button">
                            {t(`admin.breadcrumb.paths.${value}.label`)}
                        </Typography>
                    </Link>
                );
            })}
        </MuiBreadCrumbs>
    );
};
