import { useContext, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import {
    CardContent,
    Dialog,
    Divider,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
} from '@mui/material';
import type { Quest } from 'types';
import { QuestDetailContext } from 'contexts';
import { VisibilityOff, Close } from '@mui/icons-material';
import { Coins } from '../../Coins';
import { Experience } from '../../Experience';
import { QuestStatusChip } from '../QuestStatusChip';
import { QuestProgressChart } from '../QuestProgressChart';
import { QuestDetailQuestTypeIcon } from '../QuestDetailQuestTypeIcon';
import { useStyles } from './styles';

interface Props {
    quest: Quest;
    isEdit?: boolean;
}

export const QuestDetailHeader: FC<Props> = ({ quest, isEdit = false }) => {
    const [tooltipModalOpen, setTooltipModalOpen] = useState(false);
    const { highlightedKeyResult, setHighlightedKeyResult } = useContext(QuestDetailContext);
    const { t } = useTranslation();
    const {
        coins,
        status,
        descriptionShort,
        descriptionLong,
        confidential,
        keyResults = [],
        xp,
    } = quest;
    const classes = useStyles();

    const handleOnConfidentialClick = () => setTooltipModalOpen(true);

    const handleOnConfidentialKeyUp = (evt) => {
        if (evt.keyCode === 13) {
            setTooltipModalOpen(true);
        }
    };

    const onDialogCloseClick = () => setTooltipModalOpen(false);

    return (
        <CardContent sx={classes.headerContent}>
            <div className={classes.rewardsWrapper}>
                <Experience value={xp} />
                <Coins value={coins} displaySign />
            </div>
            <QuestDetailQuestTypeIcon questType={quest.type} className={classes.questTypeIcon} />
            {descriptionShort && (
                <div className={classes.confidential}>
                    {confidential && (
                        <VisibilityOff
                            sx={classes.confidentialIcon}
                            onClick={handleOnConfidentialClick}
                            onKeyUp={handleOnConfidentialKeyUp}
                            role="tooltip"
                        />
                    )}
                    <Typography variant="h2" sx={classes.descriptionShort} component="span">
                        {sanitize(descriptionShort, { USE_PROFILES: {} })}
                    </Typography>
                </div>
            )}
            {descriptionLong && (
                <Typography sx={classes.descriptionLong}>
                    {sanitize(descriptionLong, {
                        USE_PROFILES: {},
                    })}
                </Typography>
            )}
            <QuestStatusChip questStatus={status} />
            {!isEdit && (
                <QuestProgressChart
                    values={keyResults
                        .sort((first, second) => first.order - second.order)
                        .map((keyResult) => keyResult.progressPercent ?? 0)}
                    highlightedKeyResult={highlightedKeyResult}
                    onHover={setHighlightedKeyResult}
                    className={classes.questProgressChart}
                />
            )}
            <Dialog
                open={tooltipModalOpen}
                onClose={onDialogCloseClick}
                maxWidth="sm"
                className="bigPadding"
            >
                <DialogTitle sx={classes.confidentialDialogTitleWrapper}>
                    <Typography sx={classes.confidentialDialogTitle} component="span" variant="h4">
                        {t('quest.questDetailHeader.confidential.tooltipModal.title.label')}
                    </Typography>
                    <IconButton aria-label="close" onClick={onDialogCloseClick} size="small">
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>
                        {t('quest.questDetailHeader.confidential.tooltipModal.text.label')}
                    </Typography>
                </DialogContent>
            </Dialog>
        </CardContent>
    );
};
