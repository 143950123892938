import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { EvaluationType } from 'types';
import type {
    CompanyConfig,
    ConfidentialQuestsConfig,
    EmployeeEvaluationPeriod,
    FeedbackConfig,
    KeyResultsConfig,
    Quest,
    QuestPriorityConfig,
    QuestTypeConfig,
} from 'types';
import { api, callService } from 'services';

export interface ConfigurationState {
    failedToLoad: boolean;
    company: CompanyConfig;
    companyObjectives: Quest[];
    employeeEvaluationPeriods: EmployeeEvaluationPeriod[];
    questTypesEnabled: QuestTypeConfig[];
    parentQuestsEnabled: boolean;
    questPriorities: QuestPriorityConfig[];
    questPriorityDefaultValue?: string;
    keyResults: KeyResultsConfig;
    confidentialQuests: ConfidentialQuestsConfig;
    evaluationType: EvaluationType;
    enableOpenTopicFeedback: boolean;
    enablePersonalFeedback: boolean;
    feedbackConfiguration: FeedbackConfig;
    year: number;
}

export const initialState: ConfigurationState = {
    failedToLoad: false,
    company: { nameShort: '', noOfEmployeesCompany: 0 },
    companyObjectives: [],
    employeeEvaluationPeriods: [],
    questTypesEnabled: [],
    parentQuestsEnabled: false,
    questPriorities: [],
    questPriorityDefaultValue: undefined,
    keyResults: {
        enabled: false,
    },
    confidentialQuests: {
        enabled: false,
        questDefaultConfidential: false,
    },
    evaluationType: EvaluationType.PERCENTAGE,
    enableOpenTopicFeedback: true,
    enablePersonalFeedback: true,
    feedbackConfiguration: {
        enableAvatar: false,
        enableAdventurer: false,
        enableCompetencies: false,
        enableOpenTopic: false,
        isAnonymousDefault: true,
    },
    year: new Date().getFullYear(),
};

const configuration = createSlice({
    initialState,
    name: 'configuration',
    reducers: {
        setConfiguration: (state, { payload }: PayloadAction<ConfigurationState>) =>
            Object.keys(payload).forEach((key) => {
                state[key] = payload[key];
            }),
        setFailed: (state) => {
            state.failedToLoad = true;
        },
    },
});

export const {
    actions: { setConfiguration, setFailed },
    reducer: configurationReducer,
} = configuration;

export const loadConfiguration = () => async (dispatch) => {
    const { error, payload } = await callService({
        api: api.getConfiguration,
    });

    if (!error && payload) {
        dispatch(setConfiguration(payload));
    }

    if (error) {
        dispatch(setFailed());
    }
};
