import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useAnalyticsFilter, useChartColors } from 'hooks';
import { api, callService } from 'services';
import type { AppReducerState } from 'store';
import type { ChartTuple } from 'types';
import { ChartLegend, RadarChart } from '../../chart';
import { Loading } from '../../../Loading';
import { useStyles } from './styles';

interface AnalyticsData {
    graphTitle: string;
    data: {
        totalFeedbackTeam: number;
        totalFeedbackCompany: number;
        avgRateCompany: number;
        avgRateTeam: number;
        competencySysId: string;
    }[];
}

export const TeamMembersCompetencyFeedbackRate: FC = () => {
    const [data, setData] = useState<AnalyticsData>();
    const { filter } = useAnalyticsFilter();
    const { t } = useTranslation();
    const classes = useStyles();
    const { allCompetencies } = useSelector(({ competencies }: AppReducerState) => competencies);

    const { dateFrom, dateTo, hierarchies } = filter;

    const getAnalyticsData = async () => {
        const { error, payload } = await callService({
            api: api.analyticsController.getAnalyticsCulture,
            query: {
                graphId: 'T2',
                teams: hierarchies.join(','),
                dateFrom: dateFrom.format('YYYY-MM-DD'),
                dateTo: dateTo.format('YYYY-MM-DD'),
            },
        });

        if (!error && payload) {
            setData(payload);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, [dateFrom, dateTo, hierarchies]);

    if (!data) {
        return <Loading />;
    }

    const coloredData = useChartColors(
        data.data.reduce(
            (acc, item) => {
                const competencyName =
                    allCompetencies.find(
                        (competency) => competency.compSysId === item.competencySysId,
                    )?.descriptionShort || (Math.random() + 1).toString(36).substring(7);

                return [
                    {
                        data: [
                            ...acc[0].data,
                            {
                                key: competencyName,
                                value: item.avgRateTeam!,
                            },
                        ],
                        legend: t(
                            'analytics.graphs.teamMembersCompetencyFeedbackRate.averageRatingTeam.label',
                        ),
                    },
                    {
                        data: [...acc[1].data, { key: competencyName, value: item.avgRateCompany }],
                        legend: t(
                            'analytics.graphs.teamMembersCompetencyFeedbackRate.averageRatingCompany.label',
                        ),
                    },
                ];
            },
            [
                { data: [] as ChartTuple<string>[], legend: '' },
                { data: [] as ChartTuple<string>[], legend: '' },
            ],
        ),
    );

    return (
        <Card sx={classes.card}>
            <CardHeader
                title={
                    <Typography>
                        <strong className={classes.graphTitle}>{data.graphTitle}</strong>
                    </Typography>
                }
            />
            <CardContent sx={classes.cardContent}>
                <RadarChart radius={120} data={coloredData} />
                <ChartLegend
                    className={classes.legend}
                    data={coloredData.map((item) => ({
                        color: item.color,
                        label: item.legend,
                    }))}
                />
            </CardContent>
        </Card>
    );
};
