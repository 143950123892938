import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        root: css({
            overflow: 'visible',
        }),
        valueText: css({
            // ...theme.typography.body1,
            textAnchor: 'middle',
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '1.5rem',
            fill: theme.palette.text.primary,
        }),
    };
};
