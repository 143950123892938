export enum ADVICE_SENTIMENT {
    NEGATIVE = 0,
    POSITIVE = 1,
}

export interface Avatar {
    feedAvatarId: string;
    actSysId: string;
    description: string;
    helpfulHow: string;
    photoPath: string;
    photoPathSmall: string;
    title: string;
    howEmotional: number;
    howHumble: number;
    howPolite: number;
    howSimple: number;
}

export interface AvatarAdvice {
    id: string;
    advice: string;
    feedbackAvatarSysId: string;
    avatarTitle: string;
    competencyDescriptionShort: string;
    competencySysId: string;
    sentiment: ADVICE_SENTIMENT;
}
