import _ from 'lodash';
import type { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import type { USER_ROLES } from 'types';
import type { AppReducerState } from 'store';

interface Props {
    allowedRoles: USER_ROLES[];
    children: ReactNode;
}

export const CR: FC<Props> = ({ allowedRoles, children }) => {
    const { roles } = useSelector(({ user }: AppReducerState) => user);

    if (roles && !_.intersection(allowedRoles, roles).length) {
        return null;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};
