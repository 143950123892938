import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        card: {
            height: '100%',
        },
        content: {
            display: 'flex',
            paddingBottom: `${theme.spacing(2)} !important`,
            justifyContent: 'space-between',
        },
        competencyImage: css({
            paddingLeft: theme.spacing(1),
            '& img': {
                width: '56px',
            },
        }),
    };
};
