import cx from 'classnames';
import { AXIS_ORIENTATION } from 'types';
import type { Margin, Scale } from 'types';
import {
    getAxisMarginTranslate,
    getAxisPath,
    getAxisTicks,
    getTickAnchor,
    getTickTransform,
} from './AxisService';
import { useStyles } from './styles';

const TWO = 2;
const TICK_WIDTH = 6;
const TEXT_SPACING = 9;

interface Props<T> {
    className?: string;
    'data-cy'?: string;
    format?: (tick: T) => string;
    graphSize: { height: number; width: number };
    margin: Margin;
    numberOfTicks?: number;
    orientation?: AXIS_ORIENTATION;
    scale: Scale<T>;
    textSpacing?: number;
    ticks?: T[];
    tickWidth?: number;
    withoutLine?: boolean;
}

export const Axis = <T,>({
    className,
    'data-cy': dataCy = 'scale-axis',
    format,
    graphSize,
    margin,
    numberOfTicks,
    orientation = AXIS_ORIENTATION.LEFT,
    scale,
    textSpacing = TEXT_SPACING,
    ticks,
    tickWidth = TICK_WIDTH,
    withoutLine,
}: Props<T>) => {
    const classes = useStyles();

    const tickValues = getAxisTicks(scale, numberOfTicks, ticks);

    if (!tickValues.length) {
        return null;
    }

    const direction = [AXIS_ORIENTATION.TOP, AXIS_ORIENTATION.LEFT].includes(orientation) ? -1 : 1;
    const isVertical = [AXIS_ORIENTATION.LEFT, AXIS_ORIENTATION.RIGHT].includes(orientation);
    const textBottomDY = orientation === AXIS_ORIENTATION.BOTTOM ? textSpacing : TICK_WIDTH / TWO;

    return (
        <g
            data-cy={dataCy}
            className={cx(className)}
            transform={getAxisMarginTranslate(margin, orientation, graphSize)}
        >
            {!withoutLine && (
                <path
                    className={classes.axisLine}
                    d={getAxisPath(orientation, scale, tickWidth)}
                    data-cy="scale-axis"
                />
            )}
            {tickValues.map((tick) => (
                <g
                    key={String(tick)}
                    transform={getTickTransform(orientation, scale, tick)}
                    textAnchor={getTickAnchor(orientation)}
                >
                    {!withoutLine && (
                        <line
                            x2={isVertical ? direction * tickWidth : 0}
                            y2={isVertical ? 0 : direction * tickWidth}
                            x1={0}
                            y1={0}
                            className={classes.tick}
                            data-cy={`scale-axis-tick-${tick}`}
                        />
                    )}
                    <text
                        x={isVertical ? direction * textSpacing : 0}
                        y={isVertical ? 0 : direction * textSpacing}
                        dy={orientation === AXIS_ORIENTATION.TOP ? 0 : textBottomDY}
                        className={classes.tickText}
                        data-cy={`scale-axis-tick-text-${tick}`}
                    >
                        {format?.(tick) ?? String(tick)}
                    </text>
                </g>
            ))}
        </g>
    );
};
