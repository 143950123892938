import axios from 'axios';
import type {
    IApiResponse,
    ISearch,
    ISearchView,
    IStoreItemView,
    IUpsertStoreItemCommand,
} from 'types';
import { api, mainApiUrl } from '../constants';

const controllerApi = api.storeItems;

export const search = async (queryParams: ISearch): Promise<ISearchView<IStoreItemView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const getBySysId = async (id: string): Promise<IStoreItemView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};

export const create = async (data: IUpsertStoreItemCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (id: string, data: IUpsertStoreItemCommand): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const updatePhoto = async ({ id, base64Image }) => {
    return axios.put(`${mainApiUrl}/Store/photo`, {
        storeItemSysId: id,
        base64Image,
    });
};

export const deleteStoreItems = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
