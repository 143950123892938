import { hexToRgba } from 'theme';
import { css } from '@emotion/css';

export const useStyles = (color) => ({
    valueLine: css({
        stroke: color,
        strokeWidth: 1,
        fill: hexToRgba(color, 0.5),
    }),
    valueCircle: css({
        fill: color,
    }),
});
