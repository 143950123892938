import { Card, Typography } from '@mui/material';
import { EmployeeAvatar } from 'components';
import type { JobProfileEmployee } from 'types';
import { useStyles } from './styles';

interface Props {
    employee: JobProfileEmployee;
}

export const JobProfileEmployeeCard = ({ employee }: Props) => {
    const classes = useStyles();

    return (
        <Card sx={classes.wrapper}>
            <EmployeeAvatar
                alt={employee.displayName}
                employeeId={employee.id}
                src={employee.profilePhoto}
            />
            <div className={classes.dataWrapper}>
                <Typography component="span">
                    <strong>{employee.displayName}</strong>
                </Typography>
                <Typography component="span">{employee.jobName}</Typography>
            </div>
        </Card>
    );
};
