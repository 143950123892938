import axios from 'axios';
import type {
    IApiResponse,
    ISearch,
    ISearchView,
    ICreateRolesCommand as ICreateUserRolesCommand,
    IUserRoleView,
} from 'types';
import { api } from '../constants';

const controllerApi = `${api.userRoles}`;

export const getUserRoles = async (queryParams: ISearch): Promise<ISearchView<IUserRoleView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const createUserRoles = async (data: ICreateUserRolesCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const deleteUserRoles = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
