import { frankyTalesTheme } from 'theme';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (isDragActive) => ({
    avatarEdit: css({
        flex: '0 0 100%',

        '& > div > div': {
            borderColor: isDragActive ? `${colors.blue} !important` : 'transparent',
            border: '2px dashed',

            '& label:hover': {
                opacity: 0.7,
            },
        },
    }),
});
