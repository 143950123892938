import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsFilter } from 'hooks';
import { api, callService } from 'services';
import { QuestType } from 'types';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useStyles } from './styles';
import { DonutChart } from '../../chart';
import { Loading } from '../../../Loading';

const TYPE_TRANSLATION_KEYS = {
    [QuestType.TASK]: 'quest.questType.task.label',
    [QuestType.GROWTH]: 'quest.questType.growth.label',
    [QuestType.SKILL]: 'quest.questType.skill.label',
    [QuestType.COMPANY]: 'quest.questType.company.label',
};

interface AnalyticsData {
    graphTitle: string;
    data: {
        questType: QuestType;
        totalQuestsType: number;
    }[];
}

export const QuestsInType: FC = () => {
    const [data, setData] = useState<AnalyticsData>();
    const { filter } = useAnalyticsFilter();
    const { t } = useTranslation();
    const classes = useStyles();

    const { dateFrom, dateTo, jobs, hierarchies, questTypes } = filter;

    const getAnalyticsData = async () => {
        const { error, payload } = await callService({
            api: api.analyticsController.getAnalyticsQuests,
            query: {
                graphId: 'Q1',
                jobs: jobs.join(','),
                teams: hierarchies.join(','),
                questTypes: questTypes.join(','),
                dateFrom: dateFrom.format('YYYY-MM-DD'),
                dateTo: dateTo.format('YYYY-MM-DD'),
            },
        });

        if (!error && payload) {
            setData(payload);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, [dateFrom, dateTo, jobs, hierarchies, questTypes]);

    if (!data) {
        return <Loading />;
    }

    return (
        <Card sx={classes.card}>
            <CardHeader
                title={
                    <Typography>
                        <strong className={classes.graphTitle}>{data.graphTitle}</strong>
                    </Typography>
                }
            />
            <CardContent sx={classes.cardContent}>
                <DonutChart
                    donut={false}
                    data={data.data.map((dataRow) => ({
                        label: t(TYPE_TRANSLATION_KEYS[dataRow.questType]),
                        value: dataRow.totalQuestsType,
                        valueLabel: `${dataRow.totalQuestsType}`,
                    }))}
                />
            </CardContent>
        </Card>
    );
};
