import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        title: {
            marginBottom: theme.spacing(2),
            flex: '1 0 auto',
        },
        actionsWrapper: {
            textAlign: 'center',
        },
        competencyImage: {
            '& img': {
                width: '56px',
            },
        },
        titleWrapper: css({
            display: 'flex',
            alignItems: 'baseline',
        }),
        switch: {
            '& .MuiSwitch-track': {
                backgroundColor: `${colors.grey} !important`,
            },

            '& .Mui-checked + .MuiSwitch-track': {
                backgroundColor: `${colors.skyBlue} !important`,
            },

            '& .MuiSwitch-switchBase.Mui-checked': {
                color: colors.blue,
            },

            '& .MuiSwitch-thumb': {
                boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.5)',
            },
        },
    };
};
