export enum IMPORT_ITEM_STATUS {
    COMPLETE = 'Finished',
    PENDING = 'PENDING',
}

export interface ImportItem {
    id: string;
    createdBySysId?: string;
    createdBy: string;
    dateCreated: string;
    status: IMPORT_ITEM_STATUS;
    totalCount: number;
    successPercentage?: number;
}

export enum IMPORT_DATA_TYPE {
    EMPLOYEE = 'Employee',
    TEAM = 'Team',
    JOB = 'Job',
    RELATION_EMPLOYEE_TEAM = 'RelationEmployeeTeam',
    RELATION_EMPLOYEE_JOB = 'RelationEmployeeJob',
    RELATION_EMPLOYEE_MANAGER = 'RelationEmployeeManager',
    RELATION_USER_ROLE = 'RelationUserRole',
}

export interface ImportItemDetail {
    id: string;
    data: Record<string, any>;
    dataType: IMPORT_DATA_TYPE;
    errors?: string;
    title: string;
    success: boolean;
}
