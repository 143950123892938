import { useBackground } from 'hooks';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const background = useBackground();

    return {
        background: css({
            backgroundImage: `url(${background})`,
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'initial',
            backgroundSize: 'cover',
            minHeight: '100vh',
            minWidth: '100vw',
        }),
        appWrapper: {
            minHeight: '100vh',

            '& > .MuiBox-root': {
                minHeight: '100vh',
            },
        },
        appLoading: css({
            height: '100vh',
        }),
    };
};
