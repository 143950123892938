/* eslint-disable max-lines */

export const en = {
    translation: {
        general: {
            error: {
                label: 'An error occurred, please try again later.',
            },
        },
        userService: {
            email: {
                error: {
                    empty: {
                        label: 'Email is empty.',
                    },
                    invalid: {
                        label: 'Email is not a valid email.',
                    },
                },
            },
            username: {
                error: {
                    empty: {
                        label: 'Username is empty.',
                    },
                    invalid: {
                        label: 'The username is not an valid.',
                    },
                },
            },
            password: {
                error: {
                    empty: {
                        label: 'Password is empty.',
                    },
                    min: {
                        label: 'The password has to have at least 5 characters.',
                    },
                    max: {
                        label: 'The password has to have a maximum of 12 characters.',
                    },
                    match: {
                        label: 'The password and the password confirmation have to match.',
                    },
                },
            },
        },
        nodal: {
            header: {
                actions: {
                    cancel: 'Cancel',
                },
            },
            modal: {
                title: {
                    label: 'Do you really want to leave?',
                },
                description: {
                    label: 'Your feedback wont be saved.',
                },
                actions: {
                    exit: {
                        label: 'Exit without saving',
                    },
                },
            },
        },
        sideMenu: {
            menuOptions: {
                dashboard: {
                    label: 'Dashboard',
                },
                market: {
                    label: 'Market',
                },
                myFeedback: {
                    label: 'Feedback',
                },
                questLog: {
                    label: 'Quest log',
                },
                activityStream: {
                    label: 'Activity stream',
                },
                notifications: {
                    label: 'Notifications',
                },
                analytics: {
                    label: 'Analytics',
                },
                admin: {
                    label: 'Settings',
                },
                search: {
                    label: 'Search',
                },
            },
        },
        bottomMenu: {
            home: { label: 'Home' },
            feedback: { label: 'Feedback' },
            questLog: { label: 'Quest Log' },
            search: { label: 'Search' },
        },
        modal: {
            actions: {
                close: 'Close',
            },
        },
        profile: {
            profileDetailTabs: {
                general: { label: 'General' },
                quests: { label: 'Quests' },
                skills: { label: 'Skills' },
                analytics: { label: 'Analytics' },
                achievements: { label: 'Achievements' },
                yourStream: { label: 'Your stream' },
                activity: { title: { label: 'Activity' } },
                teamsAndRoles: { title: { label: 'Teams & Team roles' } },
            },
            achievements: {
                achievementCard: {
                    achievedOn: { tooltip: 'Achieved on {{date}}' },
                },
                achievementRate: {
                    globalRate: { tooltip: '{{globalRate}} of adventurers got this achievement' },
                },
            },
            teamsAndRoles: {
                header: { title: { label: 'Jobs & Teams' } },
                teamAndRoleCard: { actions: { goToTeam: { label: 'Go to team' } } },
            },
            valuablePlayerTopics: {
                header: {
                    title: 'Valuable adventurer topics',
                    information: {
                        title: 'What is this?',
                        tooltip:
                            'Using your Personal Profile will pay off\n' +
                            'On your profile, you can indicate specific behaviors that you would like to be working on (your development priorities). Publishing these will build trust as others see that you admit your imperfections. More importantly, it will invite others to help you by sending more focused feedback. While they still can give feedback on any other behavior, they will know that focusing on your highlighted ones will more likely be rated by you as useful (= more coins!).',
                    },
                },
                competency: {
                    actions: {
                        giveFeedback: {
                            title: 'Give feedback',
                        },
                    },
                },
            },
            valuablePlayerTopicsSelector: {
                header: {
                    title: 'Valuable topics for you',
                    information: {
                        title: 'What is this?',
                        tooltip:
                            'On your profile, you can indicate specific behaviors that you would like to be working on (your development priorities). Publishing these will build trust as others see that you admit your imperfections. More importantly, it will invite others to help you by sending more focused feedback. While they still can give feedback on any other behavior, they will know that focusing on your highlighted ones will more likely be rated by you as useful (= more coins!).',
                    },
                },
            },
            revokeGdpr: {
                title: 'Revoke Private Policy Consent',
                description:
                    ' You cliked to revoke our Private Policy, would you like to continue?',
                actions: {
                    goBack: 'No, go back',
                    continue: 'Yes, continue',
                },
            },
            passwordChange: {
                title: {
                    label: 'Password Change',
                },
                form: {
                    oldPassword: {
                        label: 'Old Password',
                    },
                    newPassword: {
                        label: 'New Password',
                    },
                    repeatPassword: {
                        label: 'Repeat New Password',
                    },
                },
                actions: {
                    changePassword: { label: 'Change the password' },
                    goBack: { label: 'Go Back' },
                },
                success: {
                    message: { label: 'Your user password has been successfully changed.' },
                },
                error: {
                    message: { label: 'An error happened. Please try again later.' },
                },
            },
            generateQRCode: {
                title: { label: 'Generate a code for your authenticator' },
                description: {
                    label: 'When logging in, you can also use an authenticator to generate a security code instead of receiving one by email. Create a QR code and scan it to register an authenticator. ',
                },
                qrCode: {
                    alt: 'QR code for scanning',
                    caption: 'Scan the QR in your authenticator',
                },
                error: {
                    label: 'An error occurred while generating a code. Please try again later.',
                },
                actions: {
                    generateCode: { label: 'Generate Code' },
                    goBack: { label: 'Go Back' },
                },
            },
            actions: {
                giveFeedback: { label: 'Give feedback' },
                evaluate: { label: 'Evaluate' },
                selfEvaluate: { label: 'Self-evaluate' },
                createQuest: { label: 'Create quest' },
                actionButton: { aria: 'Profile action button' },
                updateAvatar: {
                    update: 'Update',
                    saveChanges: 'Save changes',
                    cancel: 'Cancel',
                    imagePlaceholderLabel: 'Choose or drag a file here',
                },
            },
            settings: {
                changeLanguage: 'Change language',
                revokeConsent: 'Revoke Consent',
                changePassword: 'Change password',
                generateAuthenticatorCode: 'Generate authenticator code',
                logOut: { label: 'Log out' },
                language: {
                    english: { label: 'English' },
                    german: { label: 'German' },
                    slovak: { label: 'Slovak' },
                    czech: { label: 'Czech' },
                    croatian: { label: 'Croatian' },
                    slovenian: { label: 'Slovenian' },
                },
            },
            info: {
                level: 'LEVEL',
                experience: 'EXPERIENCE',
                inventory: 'INVENTORY',
            },
            tabs: {
                playerProfile: {
                    label: 'ADVENTURER PROFILE',
                },
                evaluation: {
                    label: 'PROGRESS REVIEWS',
                },
                reports: {
                    label: 'REPORTS',
                },
            },
        },
        greeting: {
            morning: 'Good morning!',
            afternoon: 'Good afternoon!',
            evening: 'Good evening!',
        },
        dashboard: {
            myQuests: {
                title: { label: 'My Quests' },
                noQuests: { label: 'No quests to show' },
                error: { label: 'Quest could not be loaded' },
                actions: {
                    createQuest: { label: 'Create Quest' },
                    viewMore: { label: 'View More' },
                },
            },
            playerSearch: {
                title: { label: 'Adventurers Search' },
                description: {
                    label: 'Find other players or teams or go on an adventure together!',
                },
                actions: {
                    viewMore: { label: 'View More' },
                },
            },
        },
        home: {
            card: {
                gameLevel: {
                    title: 'My Level',
                },
                helpfulness: {
                    title: 'Helpfulness Rate',
                    companyAverage: 'Keep it up! The company average is ',
                },
                score: {
                    title: 'Experience',
                    scoreCount: 'of {{count}} points',
                    nextLevel: 'XP until level up',
                },
                inventory: {
                    title: 'Inventory',
                    itemRedeemed: ' item redeemed',
                    itemRedeemed_plural: ' items redeemed',
                },
            },
            leaderBoard: {
                table: {
                    title: {
                        topExperience: 'Top experience',
                        topHelpful: 'Top helpful',
                    },
                    header: {
                        players: 'Adventurers',
                    },
                    row: {
                        experience: 'XP: ',
                        helpful: 'Helpful: ',
                    },
                },
            },
        },
        activityStream: {
            title: { label: 'Activity Stream' },
            table: {
                title: {
                    label: "What's happening in the world",
                    giveFeedback: { label: 'Give feedback' },
                },
                header: {
                    character: { label: 'Character' },
                    feedback: { label: 'FEEDBACK' },
                    pointsCoins: { label: 'XP / COINS' },
                },
                noItems: {
                    label: 'No activity stream items to show',
                },
                footer: {
                    actions: {
                        readMore: {
                            label: 'Read more',
                        },
                    },
                },
            },
            streamFilter: {
                title: { label: 'Activity Stream' },
                type: {
                    feedback: { label: 'Feedback' },
                    quest: { label: 'Quest' },
                    objectiveEvaluation: { label: 'Objective Evaluation' },
                    jobPromotion: { label: 'Job Promotion' },
                    anniversary: { label: 'Anniversary' },
                    processReminder: { label: 'Process Reminder' },
                },
                assignTo: {
                    groups: {
                        employees: { label: 'Adventurer' },
                        jobs: { label: 'Jobs' },
                        teams: { label: 'Teams' },
                    },
                },
                allTypes: { label: 'All types' },
                types: {
                    label: '{{count}} type',
                    label_plural: '{{count}} types',
                },
                allAssignees: { label: 'All assignees' },
                assignees: {
                    label: '{{count}} assignee',
                    label_plural: '{{count}} assignees',
                },
                actions: {
                    clear: {
                        aria: 'Clear',
                    },
                    giveFeedback: {
                        aria: 'Give feedback',
                        label: 'Give feedback',
                    },
                },
            },
        },
        analytics: {
            graphs: {
                feedbackJobVsCompany: {
                    totalFeedbackJob: { label: 'Total feedback job' },
                    totalFeedbackCompany: { label: 'Total feedback company' },
                },
                jobFeedbackScore: {
                    averageRatingJob: { label: 'Average rating for job' },
                    averageRatingCompany: { label: 'Average rating in company' },
                },
                feedbackTeamVsCompany: {
                    totalFeedbackTeam: { label: 'Total feedback team' },
                    totalFeedbackCompany: { label: 'Total feedback company' },
                },
                teamMembersCompetencyFeedbackRate: {
                    averageRatingTeam: { label: 'Average rating for team' },
                    averageRatingCompany: { label: 'Average rating in company' },
                },
                positiveNegativeFeedbackTeamVSCompany: {
                    feedbackNegativeTeam: { label: 'Total negative feedback for team' },
                    feedbackPositiveTeam: { label: 'Total positive feedback for team' },
                },
                positiveNegativeFeedbackJobVSCompany: {
                    feedbackNegativeJob: { label: 'Total negative feedback for job' },
                    feedbackPositiveJob: { label: 'Total positive feedback for job' },
                },
            },
            title: { label: 'Your company stats' },
            tabs: {
                culture: {
                    label: 'Culture',
                    filter: {
                        allJobs: { label: 'All jobs' },
                        jobs: {
                            label: '{{count}} job',
                            label_plural: '{{count}} jobs',
                        },
                        allHierarchies: { label: 'All teams' },
                        hierarchies: {
                            label: '{{count}} team',
                            label_plural: '{{count}} teams',
                        },
                    },
                },
                quests: {
                    label: 'Quests',
                    filter: {
                        allQuestTypes: { label: 'All quest types' },
                        questTypes: {
                            label: '{{count}} quest type',
                            label_plural: '{{count}} quest types',
                        },
                    },
                },
                reports: {
                    label: 'Reports',
                },
            },
            dateIntervalPicker: {
                dialog: {
                    dateFrom: {
                        label: 'Date from',
                        aria: 'Date from',
                    },
                    dateTo: {
                        label: 'Date to',
                        aria: 'Date to',
                    },
                    title: {
                        label: 'Select a date interval',
                    },
                    actions: {
                        confirm: {
                            label: 'Confirm',
                        },
                        close: {
                            label: 'Close',
                        },
                    },
                },
            },
            chartCard: {
                currentMonthCard: {
                    title: {
                        label: 'Feedback / Current Month',
                    },
                },
                feedbackComparisonCard: {
                    title: {
                        label: 'Feedback comparison',
                    },
                    legend: {
                        positiveFeedback: {
                            label: 'Positive Feedback',
                        },
                        negativeFeedback: {
                            label: 'Negative Feedback',
                        },
                    },
                },
                positiveNegativeCard: {
                    title: {
                        label: 'Positive vs. Negative',
                    },
                    legend: {
                        positiveFeedback: {
                            label: 'Positive Feedback',
                        },
                        negativeFeedback: {
                            label: 'Negative Feedback',
                        },
                    },
                },
            },
        },
        store: {
            title: { label: 'Welcome to the market!' },
            inventory: {
                table: {
                    title: 'Your transactions',
                    header: {
                        price: 'Price',
                        item: 'Item',
                        date: 'Date',
                    },
                    row: {
                        detail: {},
                    },
                },
            },
            storeItemCard: {
                actions: {
                    buy: 'Buy',
                    confirmPurchase: 'Confirm purchase',
                },
            },
            snackbar: {
                itemBought: 'Item bought!',
                errorOccurred: 'An error has occurred, please try again later.',
            },
            tabs: {
                rewards: {
                    label: 'All rewards',
                },
                history: {
                    label: 'Your history',
                },
                yourInventory: { label: 'Your inventory:' },
            },
            itemsOnNextLevels: {
                title: { label: 'Available on next levels' },
                helpText: {
                    title: { label: 'Increase your level to get access to these items' },
                    text: {
                        label: 'To gain more experience, be a valuable part of your work community, give helpful feedback, join quests…',
                    },
                },
            },
        },
        feedback: {
            feedbackTopicCard: {
                descriptionShort: {
                    label: 'Open topic',
                },
                descriptionLong: {
                    label: "Any other topic, a specific situation, an event, a quest or anything else what comes to your ming and it's not related to competencies",
                },
            },
            feedbackCompetencyCard: {
                information: {
                    text: 'Valuable for adventurer',
                    tooltip: 'Lorem ipsum',
                },
            },
            feedbackAvatarCard: {
                personality: {
                    direct: { label: 'Direct' },
                    diplomatic: { label: 'Diplomatic' },
                    brisk: { label: 'Brisk' },
                    polite: { label: 'Polite' },
                    brief: { label: 'Brief' },
                    sophisticated: { label: 'Sophisticated' },
                    humble: { label: 'Humble' },
                    proud: { label: 'Proud' },
                },
                moreAbout: { label: 'More about the {{avatarTitle}}' },
            },
            feedbackPlayerCard: {
                title: {
                    label: 'Yourself',
                },
                content: {
                    label: 'By choosing to give feedback as yourself, your feedback will not have the option to be anonymous. This means that the recipient will see your name as sender. The feedback will also be private and not displayed on the public activity stream.',
                },
            },
            new: {
                nodal: {
                    modal: {
                        description: {
                            label: 'Your feedback wont be saved.',
                        },
                    },
                },
                pageTitle: 'Give Feedback',
                userStep: {
                    stepTitle: 'Choose an adventurer',
                    form: {
                        employeeSearch: {
                            placeholder: 'Search for adventurer',
                        },
                    },
                    suggestedPlayers: {
                        label: 'SUGGESTED ADVENTURERS',
                    },
                    suggestedTeams: {
                        label: 'SUGGESTED TEAMS',
                    },
                    suggestedJobs: {
                        label: 'SUGGESTED JOBS',
                    },
                    suggestedQuests: {
                        label: 'SUGGESTED QUESTS',
                    },
                    dropdown: {
                        groups: {
                            adventurers: { label: 'Adventurers' },
                            teams: { label: 'Teams' },
                            jobs: { label: 'Jobs' },
                            quests: { label: 'Quests' },
                        },
                    },
                    actions: {
                        continue: { label: 'Continue' },
                    },
                },
                competencyStep: {
                    stepTitle: 'Choose a topic',
                    actions: {
                        goLeft: 'Go left in competencies',
                        goRight: 'Go right in competencies',
                    },
                },
                avatarStep: {
                    stepTitle: 'Choose a character',
                    actions: {
                        goLeft: { aria: 'Go left in avatars' },
                        goRight: { aria: 'Go right in avatars' },
                    },
                },
                successStep: {
                    title: {
                        label: 'Success',
                    },
                    feedbackSuccessfullySent: {
                        label: 'Feedback has been successfully sent',
                    },
                    actions: {
                        writeAnother: {
                            label: ' Write another',
                        },
                        done: {
                            label: 'Done',
                        },
                    },
                },
                draftSuccessStep: {
                    title: {
                        label: 'Success',
                    },
                    feedbackSuccessfullySent: {
                        label: 'Feedback draft has been successfully saved to your drafts',
                    },
                    actions: {
                        writeAnother: {
                            label: ' Write another',
                        },
                        done: {
                            label: 'Done',
                        },
                    },
                },
                feedbackStep: {
                    stepTitle: 'Speak your mind',
                    avatarTitle: { label: 'The {{avatarTitle}} says ...' },
                    playerTitle: { label: '{{playerTitle}} says ...' },
                    anonymousAdventurer: { label: 'Anonymous adventurer' },
                    anonymousTitle: { label: 'An adventurer says ...' },
                    errors: {
                        noAdviceSelected: 'Please pick one advice',
                    },
                    form: {
                        note: {
                            placeholder: 'Add feedback (optional)',
                        },
                        privateNote: {
                            placeholder: 'Add private note (optional)',
                        },
                        topic: {
                            placeholder: 'Add topic (mandatory)',
                        },
                        info: {
                            sendFeedbackTo: 'Send feedback to',
                            aboutTheTopic: 'About the topic',
                            feedbackIsAnonymousMessage: {
                                label: 'Your feedback is anonymous. If you wish to add a note, feel free to do so!',
                            },
                            feedbackIsNotAnonymousMessage: {
                                label: 'Your feedback is not anonymous. Please consider adding a personal note to provide more details to your feedback, so it can be as helpful as possible to your colleague!',
                            },
                            title: {
                                label: 'Anonymous',
                            },
                        },
                    },
                    actions: {
                        saveAndExit: 'Save & exit',
                        giveFeedback: 'Give feedback',
                    },
                },
                snackbar: {
                    draftSuccess: { label: 'Draft has been successfully saved' },
                    draftError: { label: 'Something went wrong' },
                },
            },
        },
        myFeedback: {
            title: { label: 'Feedback' },
            tabs: {
                received: {
                    label: 'RECEIVED',
                },
                sent: {
                    label: 'SENT',
                },
                drafts: {
                    label: 'DRAFTS',
                },
            },
            filter: {
                textInput: { placeholder: 'Search for a player, date or keyword' },
                actions: { clear: { aria: 'Clear search field' } },
            },
            feedbackList: {
                table: {
                    title: {
                        received: {
                            label: 'Feedback I have received',
                        },
                        sent: {
                            label: 'Feedback I gave others',
                        },
                        drafts: {
                            label: 'Feedback drafts',
                        },
                    },
                    header: {
                        character: {
                            label: 'Character',
                        },
                        feedback: {
                            label: 'FEEDBACK',
                        },
                        pointsCoins: {
                            label: 'XP / COINS',
                        },
                    },
                },
            },
            shareFeedbackButton: {
                label: {
                    label: 'Activity Stream',
                },
                actions: {
                    publish: {
                        aria: 'Publish or un-publish a feedback to activity stream',
                    },
                },
                sharedOn: {
                    label: 'Shared on {{dateTime}}',
                },
                modal: {
                    title: {
                        label: 'Share feedback',
                    },
                    titleUnShare: {
                        label: 'Un-share feedback',
                    },
                    description: {
                        label: 'Are you sure you want to share this feedbacks with all of your awesome colleagues? It will be displayed publicly on the company Activity Stream.',
                    },
                    descriptionUnShare: {
                        label: 'This feedback is currently shared on the company activity stream which helps create a culture of appreciation! Are you sure you want to un-share this feedback and remove it from the activity stream?',
                    },
                    info: {
                        label: 'Remember: Only positive feedback can be shared with everyone to encourage celebrating achievements together, instead of taking them as granted.',
                    },
                    actions: {
                        cancel: {
                            label: 'Not now',
                            aria: 'Not now',
                        },
                        share: {
                            label: 'Share',
                            aria: 'Share feedback',
                        },
                        unShare: {
                            label: 'Un-share',
                            aria: 'Un-share feedback',
                        },
                    },
                },
            },
            feedbackListReceivedRow: {
                helpfulQuestion: {
                    label: 'Helpful?',
                },
                ratedNow: {
                    label: 'Rated just now. Edit up until {{minutes}} minutes',
                },
                ratedOn: {
                    label: 'Rated on {{dateTime}}',
                },
                sharedOn: {
                    label: 'Shared on {{dateTime}}',
                },
                openTopic: {
                    label: 'Open topic',
                },
                badgeNew: { label: 'New' },
                actions: {
                    helpful: {
                        aria: 'Helpful',
                    },
                    notHelpful: {
                        aria: 'Not helpful',
                    },
                },
            },
            feedbackListSentRow: {
                helpful: 'helpful',
                notHelpful: 'not helpful',
                helpfulSentence: 'This feedback was considered ',
                openTopic: {
                    label: 'Open topic',
                },
            },
            feedbackListDraftRow: {
                openTopic: {
                    label: 'Open topic',
                },
                actions: {
                    edit: {
                        label: 'Continue',
                    },
                    delete: {
                        label: 'Delete',
                    },
                },
                notePlaceholder: 'Draft',
            },
            feedbackNote: {
                startingLabel: {
                    label: 'Personal Note:',
                },
            },
            actions: {
                giveFeedback: { label: 'Give feedback', ariaLabel: 'Give feedback' },
            },
        },
        user: {
            login: {
                actions: {
                    login: {
                        label: 'Login',
                    },
                    forgotPassword: { label: 'Forgot your password?' },
                    signUp: { label: 'Sign up!' },
                },
                genericError: { label: 'An error occurred. Please try again later.' },
            },
            passwordReset: {
                title: { label: 'Password reset' },
                subTitle: { label: 'Please enter a new password for your account' },
                form: {
                    passwordConfirmation: {
                        placeholder: 'Password Confirmation',
                    },
                },
                actions: {
                    resetPassword: { label: 'Reset password' },
                    backToLogin: {
                        label: 'Back to login',
                    },
                },
                message: {
                    success: {
                        label: 'The password has been successfully changed and can be used to log in',
                    },
                    error: {
                        label: 'An error occurred while trying to reset your password. Please try again later.',
                    },
                },
            },
            changePasswordForm: {
                subTitle: {
                    label: 'Your password needs to be changed. Please enter a new password for your account.',
                },
                form: {
                    oldPassword: {
                        placeholder: 'Current Password',
                    },
                    newPassword: {
                        placeholder: 'New Password',
                    },
                    passwordConfirmation: {
                        placeholder: 'Repeat New Password',
                    },
                },
                actions: {
                    changePassword: { label: 'Change password' },
                    backToLogin: {
                        label: 'Back to login',
                    },
                },
                message: {
                    success: {
                        label: 'The password has been successfully changed. Please return to the login form and use your new password to log in.',
                    },
                    error: {
                        label: 'An error occurred while trying to change your password. Please try again later.',
                    },
                },
            },
            requestPasswordReset: {
                title: { label: 'Reset password request' },
                subTitle: {
                    label: 'Please enter an email to which we send you information on how to reset your password',
                },
                actions: {
                    requestReset: { label: 'Request password reset' },
                    backToLogin: {
                        label: 'Back to login',
                    },
                },
                message: {
                    success: {
                        label: 'An url to has been sent to your email. Please use it to reset your password.',
                    },
                    error: {
                        label: 'An error occurred while trying to request the reset of you password. Please try again later.',
                    },
                },
            },
            twoFactorCodeForm: {
                title: { label: 'Type in access code' },
                subTitle: {
                    label: 'Please type in the access code you have received via email or enter the access code from your authenticator.',
                },
                actions: {
                    login: { label: 'Log In' },
                    sendCode: {
                        label: 'Send code by email',
                    },
                    backToLogin: {
                        label: 'Back to login',
                    },
                },
                message: {
                    genericError: { label: 'An error occurred. Please try again later.' },
                    codeError: { label: 'Please enter a code' },
                },
                code: {
                    label: 'Code',
                },
            },
            onboarding: {
                signUpForm: {
                    alreadyGotAccount: { label: 'Already got an account?' },
                    actions: {
                        backToLogin: { label: 'Back to login' },
                        continue: { label: 'Continue' },
                    },
                    title: { label: 'Sign up' },
                    info: {
                        label: 'Create an account and start collaborating with your team!',
                    },
                    firstName: { placeholder: 'First Name' },
                    lastName: { placeholder: 'Last Name' },
                },
                checkYourInbox: {
                    alreadyGotAccount: { label: 'ddd' },
                    title: { label: 'Check your email inbox.' },
                    info: {
                        label: 'Please confirm your account by clicking the link in the email you have received. This takes a few minutes. Be sure to check your spam folder!',
                    },
                    actions: {
                        backToLogin: { label: 'Go to login' },
                        getInTouch: {
                            label: 'If something went wrong, <1>get in touch</1> with us.',
                        },
                    },
                },
                basicInfo: {
                    title: { label: 'Hello, {{name}}!' },
                    info: {
                        label: 'In order to customize your account, tell us a few things about yourself.',
                    },
                    actions: {
                        back: { label: 'Back' },
                        createAccount: {
                            label: 'Create account',
                        },
                    },
                    role: {
                        label: 'Your role',
                        businessOwner: { label: 'Business owner / Director' },
                        teamLeader: { label: 'Team leader' },
                        teamMember: { label: 'Team member' },
                    },
                    companySize: {
                        label: 'Your company size',
                        twenty: { label: '1-20' },
                        fifty: { label: '21-50' },
                        hundred: { label: '51-100' },
                        twoHundredFifty: { label: '101-250' },
                        fifeHundred: { label: '251-500' },
                        oneThousandFiveHundred: { label: '501-1500' },
                        oneThousandFiveHundredPlus: { label: '1500+' },
                    },
                    teamSize: {
                        label: 'Your team size',
                        five: { label: '1-5' },
                        ten: { label: '6-10' },
                        fifteen: { label: '11-15' },
                        twentyFive: { label: '16-25' },
                        fifty: { label: '26-50' },
                        fiftyPlus: { label: '50+' },
                    },
                    password: { placeholder: 'Set a password' },
                    passwordConfirmation: { placeholder: 'Confirm password' },
                    nextStepInfo: {
                        label: 'In the next step you will get an email to confirm your account creation. After confirmation, you will be able to ',
                    },
                    nextStepInfoBold: {
                        label: 'invite your team to FrankyTales.',
                    },
                },
                inviteTeam: {
                    title: { label: 'Welcome! Invite your team.' },
                    info: {
                        label: 'With a free account you can invite up to 3 team members to experience FrankyTales with you.',
                    },
                    actions: {
                        continue: { label: 'Continue' },
                        doThisLater: {
                            label: 'Do this later',
                        },
                    },
                },
                twoFA: {
                    title: { label: 'Almost there ...' },
                    info: {
                        label: 'With 2 Factor Authentication your account will be optimally protected.',
                    },
                    info2: {
                        label: 'Install an authenticator app on your phone (for example by Google or Microsoft) and scan the QR code displayed below in your authenticator.',
                    },
                    // info2: {
                    //     label: 'Install an authenticator app on your phone (for example by <1>Google</1> or <1>Microsoft</1>) and scan the QR code displayed below in your authenticator.',
                    // },
                    actions: {
                        continue: { label: 'Confirm' },
                        doThisLater: {
                            label: 'Do this later',
                        },
                    },
                },
            },
        },
        quest: {
            createQuestStepper: {
                stepTitle: {
                    questType: { label: 'Choose your adventure!' },
                    skillStep: { label: 'Choose skill' },
                    competencyStep: { label: 'Select competency' },
                    objectiveStep: { label: 'Set objective' },
                    playerStep: { label: 'Add adventurer(s)' },
                },
            },
            questType: {
                task: { label: 'Task' },
                growth: { label: 'Growth' },
                skill: { label: 'Skill' },
                company: { label: 'Company' },
            },
            new: {
                nodal: {
                    modal: {
                        description: {
                            label: 'Your quest wont be saved.',
                        },
                    },
                },
                pageTitle: {
                    label: 'Create Quest',
                },
                snackbar: {
                    draftSuccess: { label: 'Draft has been successfully saved' },
                    draftError: { label: 'Something went wrong' },
                },
            },
            update: {
                pageTitle: { label: 'Update quest' },
            },
            setObjectiveStep: {
                form: {
                    objective: {
                        label: 'Objective',
                    },
                    description: {
                        label: 'Description',
                    },
                    dateFrom: {
                        label: 'Date from',
                        aria: 'Date from',
                    },
                    dateTo: {
                        label: 'Date to',
                        aria: 'Date to',
                    },
                    priority: {
                        label: 'Priority',
                    },
                    parentQuest: {
                        label: 'North star contribution',
                    },
                    validation: {
                        requiredMessage: {
                            label: 'This field is required',
                        },
                        dateRangeMessage: {
                            label: 'The date range is invalid',
                        },
                        dateAfterMinMessage: {
                            label: 'Date needs to be after the minimum date',
                        },
                        dateBeforeMaxMessage: {
                            label: 'Date needs to be before the maximum date',
                        },
                        invalidDate: { label: 'Invalid date' },
                    },
                },
                actions: {
                    next: { label: 'Next' },
                    saveAsDraft: {
                        label: 'Save as draft',
                    },
                },
            },
            keyResults: {
                form: {
                    keyResult: { label: 'Key Result' },
                },
                actions: {
                    addKeyResult: { label: 'Add key result' },
                },
            },
            skillStep: {
                form: {
                    skill: {
                        label: 'Choose or create new skill',
                    },
                    suggestedSkills: { label: 'Suggested Skills' },
                },
                actions: {
                    saveAsDraft: {
                        label: 'Save as draft',
                    },
                    next: { label: 'Next' },
                },
            },
            addPlayerStep: {
                form: {
                    assignTo: {
                        label: 'Assign to',
                        groups: {
                            employees: { label: 'Adventurers' },
                            jobs: { label: 'Jobs' },
                            teams: { label: 'Teams' },
                        },
                        selfAssignBadge: { label: 'Self-Assign' },
                    },
                    confidential: {
                        label: '<0>Confidential</0> This quest is not public but only visible to your manager and those assigned to it',
                    },
                },
                actions: {
                    saveAsDraft: { label: 'Save as draft' },
                    publishQuest: { label: 'Publish quest' },
                    publishChanges: { label: 'Publish changes' },
                },
            },
            successStep: {
                title: { label: 'Quest created!' },
                reward: { label: 'This quest will award' },
                actions: {
                    createNewQuest: { label: 'Create new quest' },
                    done: { label: 'Done' },
                },
            },
            log: {
                title: { label: 'Quest Log' },
                tabs: {
                    questLog: {
                        label: 'Quests',
                    },
                    tales: {
                        label: 'Company Tales',
                        companyValues: {
                            label: 'Values',
                        },
                        companyQuests: {
                            label: 'North Star Quests',
                        },
                        leadership: {
                            label: 'Leadership',
                        },
                        leadershipCard: { actions: { giveFeedback: { label: 'Give feedback' } } },
                    },
                },
                filter: {
                    title: { label: 'Quests' },
                    actions: { clear: { aria: 'Clear search field' } },
                },
                actions: {
                    createNewQuest: {
                        ariaLabel: 'Create quest',
                        label: 'Create quest',
                    },
                },
            },
            questListCard: {
                menu: { aria: 'Quest actions menu' },
                actions: {
                    join: { label: 'Join quest' },
                    abandon: { label: 'Abandon quest' },
                    updateQuest: { label: 'Update quest' },
                    updateProgress: { label: 'Update progress' },
                    evaluate: { label: 'Evaluate' },
                    selfEvaluate: { label: 'Self-evaluate' },
                    archive: { label: 'Archive' },
                },
            },
            questStatus: {
                draft: { label: 'Draft' },
                active: { label: 'Active' },
                evaluated: { label: 'Evaluated' },
                completed: { label: 'Completed' },
                abandoned: { label: 'Abandoned' },
                archived: { label: 'Archived' },
            },
            detail: {
                pageTitle: { label: 'Quest detail' },
                assigned: {
                    label: 'Assigned',
                },
                parentQuest: {
                    label: 'Parent Quest',
                },
                actions: {
                    join: { label: 'Join' },
                    edit: { label: 'Edit' },
                    updateProgress: { label: 'Update progress' },
                    abandon: { label: 'Abandon' },
                    evaluate: { label: 'Evaluate' },
                    selfEvaluate: { label: 'Self-evaluate' },
                    archive: { label: 'Archive' },
                    reopen: { label: 'Reopen' },
                },
                actionButton: { aria: 'Quest action button' },
            },
            questDetailKeyResults: {
                timeSpent: {
                    label: '{{percentage}}% time spent',
                },
                relatedSkills: {
                    label: 'Related skills',
                },
                relatedCompetency: {
                    label: 'Related competency',
                },
            },
            questDetailHeader: {
                confidential: {
                    tooltipModal: {
                        title: { label: 'Confidential quest' },
                        text: {
                            label: 'This quest is confidential. Only the assigned people and the creator can see it.',
                        },
                    },
                },
            },
            questFilter: {
                allTypes: { label: 'All types' },
                types: {
                    label: '{{count}} type',
                    label_plural: '{{count}} types',
                },
                allStatuses: { label: 'All statuses' },
                status: {
                    label: '{{count}} status',
                    label_plural: '{{count}} statuses',
                },
                allAssignees: { label: 'All assignees' },
                assignees: {
                    label: '{{count}} assignee',
                    label_plural: '{{count}} assignees',
                },
            },
            abandonQuestModal: {
                title: {
                    join: { label: 'Are you sure you want to join the quest?' },
                    abandon: { label: 'Are you sure you want to abandon the quest?' },
                },
                actions: {
                    abandon: { label: 'Abandon' },
                    cancel: { label: 'Cancel' },
                    join: { label: 'Join' },
                },
            },
            questProgressChartArc: {
                completed: { label: '{{percentage}} completed' },
            },
        },
        report: {
            detail: {
                pageTitle: { label: 'Report & Data export' },
                actions: {
                    export: { label: 'Export data' },
                },
                actionButton: { aria: 'Report action button' },
            },
            reportListCard: {
                actions: {
                    join: {
                        label: 'Create Report',
                    },
                },
                menu: {
                    aria: 'Report actions menu',
                },
            },
            reportName: {
                questProgress: {
                    label: 'Quest progress report',
                },
                questEvaluations: {
                    label: 'Quest evaluations',
                },
                peopleProgress: {
                    label: 'People progress report',
                },
            },
        },
        jobProfile: {
            pageTitle: {
                label: 'Job Profile',
            },
            jobHeaderSection: {
                title: {
                    JOB: { label: 'Job' },
                    JOB_FAMILY: { label: 'Job family' },
                    PLAYER: { label: 'Adventurer' },
                    TEAM: { label: 'Team' },
                },
                members: { label: '{{count}} Member', label_plural: '{{count}} Members' },
                jobFamily: { label: 'Category: {{title}}' },
                actions: {
                    createQuest: { label: 'Create quest' },
                },
            },
            jobProfileSkillsList: {
                title: { label: 'Related skills' },
                headerTitle: { label: 'Skills related to {{job}}' },
                actions: {
                    collapse: { label: 'Collapse' },
                    expand: { label: 'Expand to view' },
                },
            },
            jobProfilePeopleList: {
                title: { label: 'People on the job' },
                actions: {
                    seeAll: { label: 'See all {{count}}' },
                },
            },
            jobProfileQuestList: {
                title: { label: 'Related Quests' },
                emptyMessage: { label: 'There are no quests yet for this job title :)' },
                actions: {
                    seeAll: { label: 'See all {{count}}' },
                    goToQuestLog: { label: 'Go to quest log' },
                    createQuest: { label: 'Create quest' },
                },
            },
            jobDescription: {
                title: { label: 'Description' },
            },
            jobStream: {
                title: { label: 'Job Stream' },
            },
            jobProfileAnalytics: {
                actions: { goToAnalytics: { label: 'Go to analytics' } },
                legend: {
                    averageRatingJob: {
                        JOB: { label: 'Average rating for Job' },
                        JOB_FAMILY: { label: 'Average rating for Job family' },
                        PLAYER: { label: 'Average rating for Player' },
                        TEAM: { label: 'Average rating for Team' },
                    },
                    averageRatingCompany: { label: 'Average rating in company' },
                },
                title: {
                    JOB: { label: 'Job Statistics' },
                    JOB_FAMILY: { label: 'Job family Statistics' },
                    PLAYER: { label: 'Player Statistics' },
                    TEAM: { label: 'Team Statistics' },
                    feedback: { label: 'Feedback' },
                },
                feedbackChartEmployeeVsTeam: {
                    totalFeedbackEmployee: { label: 'Total feedback employee' },
                    totalFeedbackTeam: { label: 'Total feedback team' },
                },
                feedbackChartEmployeeVsJobFamily: {
                    totalFeedbackEmployee: { label: 'Total feedback employee' },
                    totalFeedbackJobFamily: { label: 'Total feedback job family' },
                },
            },
        },
        jobFamilyProfile: {
            pageTitle: { label: 'Job Family Profile' },
            jobs: {
                title: { label: 'Job descriptions' },
                actions: {
                    expand: { label: 'Read description' },
                    collapse: { label: 'Hide description' },
                },
            },
        },
        teamProfile: {
            pageTitle: {
                label: 'Team Profile',
            },
            teamProfilePeopleList: {
                title: { label: 'People on the team' },
            },
            teamProfileQuestList: {
                emptyMessage: { label: 'There are no quests yet for this team :)' },
            },
            teamStream: {
                title: { label: 'Team Stream' },
            },
        },
        playerSearch: {
            title: {
                label: 'Search',
            },
            resultType: {
                yourTeam: { label: 'Your Team' },
                yourLead: { label: 'Your Lead' },
                yourSubordinates: { label: 'Your Subordinates' },
                players: { label: 'Adventurer results for "{{searchFilter}}"' },
                jobs: { label: 'Job results for "{{searchFilter}}"' },
                teams: { label: 'Team results for "{{searchFilter}}"' },
            },
            playerSearchResultMenu: {
                actions: {
                    giveFeedback: { label: 'Give feedback' },
                    createQuest: { label: 'Create quest' },
                    evaluate: { label: 'Evaluate' },
                    selfEvaluate: { label: 'Self-evaluate' },
                },
            },
            playerSearchCard: {
                numberOfPlayers: '{{count}} Player',
                numberOfPlayers_plural: '{{count}} Players',
            },
            playerSearchPlayers: {
                actions: { loadMore: { label: 'Load more' } },
            },
            playerSearchJobs: {
                actions: { loadMore: { label: 'Load more' } },
            },
            playerSearchTeams: {
                actions: { loadMore: { label: 'Load more' } },
            },
        },
        employeeSection: {
            type: {
                employee: { label: 'Employee' },
                leadManager: { label: 'Lead / Manager' },
            },
        },
        evaluation: {
            new: {
                successStep: {
                    evaluationSuccessfullySent: {
                        label: 'Your evaluation has been successfully saved and can be found in the quest details',
                    },
                    title: { label: 'Evaluation created' },
                    actions: {
                        done: { label: 'Done' },
                    },
                },
            },
            performanceEvaluationSurvey: {
                title: {
                    employee: { label: 'Self-assessment' },
                    lead: { label: "Leader's assessment" },
                },
                actions: {
                    expand: { label: 'Expand to read' },
                    collapse: { label: 'Collapse' },
                },
            },
            evaluationQuestList: {
                title: {
                    task: { label: 'Task Quests' },
                    growth: { label: 'Growth Quests' },
                    skill: { label: 'Skill Quests' },
                    empty: { label: 'Related Quests' },
                },
                actions: {
                    goToQuestLog: { label: 'Go to quest log' },
                    createQuest: { label: 'Create quest' },
                },
                emptyMessage: { label: 'There are no quests yet :)' },
            },
            evaluationCard: {
                actions: {
                    evaluate: { label: 'Evaluate' },
                    selfEvaluate: { label: 'Self-evaluate' },
                },
            },
            performanceEvaluation: {
                pageTitle: { label: 'Progress Review' },
            },
            evaluationsFilter: {
                allYears: {
                    label: 'All years',
                },
            },
            evaluationStatus: {
                notStarted: { label: 'Not Started' },
                inProgress: { label: 'In Progress' },
                finished: { label: 'Finished' },
                expired: { label: 'Expired' },
            },
            questEvaluationHeaderSection: {
                quest: { label: 'Quest' },
            },
            employeeEvaluationHeaderSection: {
                employee: { label: 'Employee' },
            },
        },
        updateQuestProgress: {
            actions: {
                cancel: { label: 'Cancel' },
                save: { label: 'Save' },
                done: { label: 'Done' },
            },
            title: { label: 'UPDATE PROGRESS' },
            nodal: {
                modal: { description: { label: 'Your progress update wont be saved.' } },
            },
            snackbar: {
                saveError: { label: 'Something went wrong. Please try again later.' },
            },
            successStep: { title: { label: 'Success' } },
        },
        evaluateQuest: {
            actions: {
                saveAsDraft: { label: 'Save as draft' },
                send: { label: 'Send' },
                next: { label: 'Next' },
                viewSummary: { label: 'View summary' },
            },
            snackbar: {
                draftError: { label: 'Something went wrong' },
                draftSuccess: { label: 'Draft has been successfully saved' },
            },
            title: {
                selfEvaluate: {
                    label: 'Self-evaluate',
                },
                evaluate: {
                    label: 'Evaluate',
                },
            },
            summary: { title: { label: 'Summary' } },
            nodal: { modal: { description: { label: 'Your evaluation wont be saved.' } } },
        },
        evaluatePlayer: {
            title: {
                selfEvaluate: {
                    label: 'Self-evaluate adventurer',
                },
                evaluate: {
                    label: 'Evaluate adventurer',
                },
            },
            actions: {
                saveAsDraft: { label: 'Save as draft' },
                send: { label: 'Send' },
                next: { label: 'Next' },
                viewSummary: { label: 'View summary' },
            },
            snackbar: {
                draftError: { label: 'Something went wrong' },
                draftSuccess: { label: 'Draft has been successfully saved' },
            },
            summary: { title: { label: 'Summary' } },
            nodal: { modal: { description: { label: 'Your evaluation wont be saved.' } } },
        },
        form: {
            email: {
                placeholder: 'Email',
            },
            username: {
                placeholder: 'Username',
            },
            password: {
                placeholder: 'Password',
                endAdornment: {
                    aria: 'Toggle password visibility',
                },
            },
            textQuestion: {
                validation: {
                    valueMissing: {
                        label: 'This field is required, please enter a value.',
                    },
                },
            },
        },
        gdpr: {
            title: { label: 'Use policy' },
            actions: {
                agree: { label: 'Agree' },
                reject: { label: 'Reject' },
            },
        },
        flowStepper: {
            backButton: {
                label: 'BACK',
            },
        },
        notifications: {
            title: {
                label: 'Notifications',
            },
            filter: {
                actions: { clear: { aria: 'Clear search field' } },
                textInput: { placeholder: 'Search for a date or keyword' },
            },
        },
        notificationsTable: {
            title: { label: 'Notifications' },
        },
        eventRow: {
            badgeNew: { label: 'New' },
        },
        recipientsList: {
            modal: {
                title: { label: 'Multiple recipients' },
                description: { label: 'This feedback has been sent to the following adventurers:' },
                actions: {
                    close: { label: 'Got it' },
                },
            },
        },
        admin: {
            layout: {
                title: {
                    label: 'Settings',
                },
            },
            sectionCard: {
                systemInstances: { label: 'System Instances' },
                companies: { label: 'Companies' },
                companySettings: { label: 'Company Settings' },
                feedbackAvatars: { label: 'Feedback Avatars' },
                users: { label: 'Users' },
                managers: { label: 'Managers' },
                userRoles: { label: 'User Roles' },
                experienceLevels: { label: 'Experience Levels' },
                competencies: { label: 'Competencies' },
                avatarAdvices: { label: 'Avatar Advices' },
                jobs: { label: 'Jobs' },
                rewardSystem: { label: 'Reward System' },
                storeItems: { label: 'Store Items' },
                teams: { label: 'Teams' },
                jobTeamRoles: { label: 'Job Team Roles' },
                employeeTeams: { label: 'Employee Teams' },
                employeeJobs: { label: 'Employee Jobs' },
                storeOrders: { label: 'Store Orders' },
                perfCycles: { label: 'Perf Cycles' },
                perfCycleEmplEvals: { label: 'Perf Cycle Employee Evaluations' },
                priorities: { label: 'Priority' },
                surveys: { label: 'Survey' },
                companyQuests: { label: 'Company Quests' },
                scales: { label: 'Scales' },
                jobFamilies: { label: 'Job Families' },
                revokedConsents: { label: 'Revoked Consents' },
                imports: { label: 'Imports' },
                importItems: { label: 'Import Items' },
                questsSettings: { label: 'Quests Settings' },
            },
            breadcrumb: {
                label: 'Breadcrumb',
                paths: {
                    admin: { label: 'Home' },
                    edit: { label: 'Edit' },
                    detail: { label: 'Detail' },
                    create: { label: 'Create' },
                    bulkImport: { label: 'Bulk Import' },
                    companyQuests: { label: 'Company Quests' },
                    systemInstances: { label: 'System Instances' },
                    companies: { label: 'Companies' },
                    companySettings: { label: 'Company Settings' },
                    feedbackAvatars: { label: 'Feedback Avatars' },
                    users: { label: 'Users' },
                    managers: { label: 'Managers' },
                    userRoles: { label: 'User Roles' },
                    experienceLevels: { label: 'Experience Levels' },
                    competencies: { label: 'Competencies' },
                    avatarAdvices: { label: 'Avatar Advices' },
                    jobs: { label: 'Jobs' },
                    rewardsSystem: { label: 'Reward System' },
                    storeItems: { label: 'Store Items' },
                    teams: { label: 'Teams' },
                    jobTeamRoles: { label: 'Job Team Roles' },
                    employeeTeams: { label: 'Employee Teams' },
                    employeeJobs: { label: 'Employee Jobs' },
                    storeOrders: { label: 'Store Orders' },
                    scales: { label: 'Scales' },
                    rewardSystem: { label: 'Reward System' },
                    perfCycles: { label: 'Performance Cycles' },
                    perfCyclesEmplEvals: { label: 'Perf. Cycle Employee Evaluations' },
                    priorities: { label: 'Priorities' },
                    survey: { label: 'Surveys' },
                    jobFamilies: { label: 'Job Families' },
                    surveys: { label: 'Surveys' },
                    userImport: { label: 'Imports' },
                    revokedConsent: { label: 'Revoked Consents' },
                    zipImport: { label: 'Zip Import' },
                    securitySettings: { label: 'Security Settings' },
                    questsSettings: { label: 'Quests Settings' },
                    monthlyActiveUsers: { label: 'Monthly Active Users' },
                    questSettings: { label: 'Quests Settings' },
                },
            },
        },
        import: {
            filter: { actions: { clear: { aria: 'Clear search field' } } },
            importStatus: {
                error: { label: 'Error' },
                success: { label: 'Success' },
            },
            itemStatusFilter: {
                allStatuses: { label: 'All statuses' },
                statuses: { label: '{{count}} status', label_plural: '{{count}} statuses' },
            },
            itemTypeFilter: {
                allTypes: { label: 'All types' },
                types: { label: '{{count}} type', label_plural: '{{count}} types' },
            },
            importType: {
                employee: { label: 'Employee' },
                team: { label: 'Team' },
                job: { label: 'Job' },
                relationEmployeeTeam: { label: 'Relation Employee Team' },
                relationEmployeeJob: { label: 'Relation Employee Job' },
                relationEmployeeManager: { label: 'Relation Employee Manager' },
                relationUserRole: { label: 'Relation User Role' },
            },
        },
        revokedConsents: {
            policyStatus: {
                rejected: { label: 'Rejected' },
                accepted: { label: 'Accepted' },
            },
            policyAcceptedFilter: {
                allStatuses: { label: 'All statuses' },
                statuses: { label: '{{count}} status', label_plural: '{{count}} statuses' },
            },
            policyFilter: {
                allPolicies: { label: 'All policies' },
                policies: { label: '{{count}} policy', label_plural: '{{count}} policies' },
            },
            importType: {
                employee: { label: 'Employee' },
                team: { label: 'Team' },
                job: { label: 'Job' },
                relationEmployeeTeam: { label: 'Relation Employee Team' },
                relationEmployeeJob: { label: 'Relation Employee Job' },
                relationEmployeeManager: { label: 'Relation Employee Manager' },
                relationUserRole: { label: 'Relation User Role' },
            },
        },
        securitySettings: {
            passwordValidityInDays: {
                label: 'Password Validity In Days',
            },
        },
        systemInstances: {
            create: {
                success: {
                    label: 'System instance was successfully created',
                },
            },
            update: {
                success: {
                    label: 'System instance was successfully updated',
                },
            },
            detail: {
                name: {
                    label: 'Name',
                },
                domain: {
                    label: 'Domain',
                },
                year: {
                    label: 'Year',
                },
                language: {
                    label: 'Language',
                },
                users: {
                    label: 'Users',
                },
                limit: {
                    label: 'Limit',
                },
            },
        },
    },
};
