import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LEADERBOARD_TYPES } from 'types';
import type { LeaderboardUser } from 'types';
import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import { Avatar } from '../avatar';
import { Loading } from '../Loading';
import { useStyles } from './styles';

interface Props {
    data?: LeaderboardUser[];
    type?: LEADERBOARD_TYPES;
}

export const Leaderboard: FC<Props> = ({ data, type = LEADERBOARD_TYPES.HELPFUL }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const classes = useStyles();

    const handleAvatarClick = (employeeSysId) => () => navigate(`/profile/${employeeSysId}`);

    if (!data) {
        return <Loading />;
    }

    return (
        <TableContainer component={Paper}>
            <Toolbar>
                <Typography variant="h4" component="h2">
                    {type === LEADERBOARD_TYPES.POINTS
                        ? t('home.leaderBoard.table.title.topExperience')
                        : t('home.leaderBoard.table.title.topHelpful')}
                </Typography>
            </Toolbar>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography>{t('home.leaderBoard.table.header.players')}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((user) => (
                        <TableRow
                            key={user.employeeSysId}
                            onClick={handleAvatarClick(user.employeeSysId)}
                            sx={classes.row}
                        >
                            <TableCell sx={classes.avatarCell}>
                                <Avatar alt={user.employeeInitials} src={user.avatarPhotoPath} />
                            </TableCell>
                            <TableCell sx={classes.employeeCell}>
                                <Typography>{user.employeeName}</Typography>
                                <Typography variant="body2">
                                    <span>
                                        {type === LEADERBOARD_TYPES.POINTS
                                            ? t('home.leaderBoard.table.row.experience')
                                            : t('home.leaderBoard.table.row.helpful')}
                                    </span>
                                    {`${user.value}`}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
