import { Children, cloneElement, isValidElement, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBoundActions, useGoBackFromNodal } from 'hooks';
import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import type { Theme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { toggleNodal } from 'store/slices/layout';
import { setNodalBackPage } from 'store/slices/appState';
import { Logo } from '../Logo';
import { useStyles } from './styles';

interface Props {
    title?: string;
    modalTitle?: string;
    modalDescription?: string;
    askWhenLeaving?: boolean;
    closeHandle?(): void;
    children: ReactNode;
}

export const NodalPage: FC<Props> = ({
    title = '',
    modalTitle,
    modalDescription,
    children,
    askWhenLeaving = true,
    closeHandle,
    ...rest
}) => {
    const location = useLocation();
    const classes = useStyles();
    const [showGoBackModal, setShowGoBackModal] = useState(false);
    const { setNodalBackPageAction, toggleNodalAction } = useBoundActions({
        setNodalBackPage,
        toggleNodal,
    });
    const isSmAndLower: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isXs: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
    const goBackFromNodal = useGoBackFromNodal();
    const { t } = useTranslation();
    const cancelLabel = t('nodal.header.actions.cancel');

    useEffect(() => {
        // setNodalBackPageIndexAction(history.length - 1);
        setNodalBackPageAction(location);
        toggleNodalAction();
        return () => toggleNodalAction();
    }, []);

    const handleGoBack = () => {
        if (closeHandle) {
            closeHandle();

            return;
        }

        if (!askWhenLeaving) {
            goBackFromNodal();
        } else {
            setShowGoBackModal(true);
        }
    };

    const handleModalClose = () => setShowGoBackModal(false);

    return (
        <>
            <AppBar sx={classes.nodalHeader}>
                <Container maxWidth="lg">
                    <Grid container sx={classes.nodalHeaderGrid}>
                        <Grid item xs={2} md={4} sx={classes.appName}>
                            {!isSmAndLower && <Logo />}
                        </Grid>
                        <Grid item xs={8} md={4} sx={classes.pageTitle}>
                            <Typography variant="h2" component="h1">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={4} sx={classes.closeButton}>
                            <Button onClick={handleGoBack} endIcon={<Close>{cancelLabel}</Close>}>
                                {!isXs && cancelLabel}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </AppBar>
            {Children.map(children, (child) =>
                isValidElement(child) ? cloneElement(child, { ...rest }) : child,
            )}
            <Dialog
                open={showGoBackModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="bigPadding"
            >
                <Card>
                    <CardHeader
                        action={
                            <IconButton
                                aria-label="settings"
                                onClick={handleModalClose}
                                size="large"
                            >
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent sx={classes.modalContent}>
                        <Typography variant="h4" component="h2">
                            {modalTitle || t('nodal.modal.title.label')}
                        </Typography>
                        <Typography>
                            {modalDescription || t('nodal.modal.description.label')}
                        </Typography>
                    </CardContent>{' '}
                    <Divider />
                    <CardActions sx={classes.modalActions}>
                        <Button variant="contained" onClick={goBackFromNodal}>
                            {t('nodal.modal.actions.exit.label')}
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
        </>
    );
};
