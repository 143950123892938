import type { NumberValue } from 'd3';
import type { Moment } from 'moment';

import type { COLOR } from './Color';
import type { QuestType } from './Quest';

// export type ChartTupleKey = number | string;
//
// export type ChartTupleValue = number;

export type ChartTupleKey = string | NumberValue;

export type ChartTupleValue = string | NumberValue | undefined | null;

export type NumberOrString<T extends ChartTupleKey | ChartTupleValue> = T extends number
    ? number
    : string;

export interface ChartTuple<T = ChartTupleKey, K = ChartTupleValue> {
    key: T;
    value: K;
}

export interface ChartTupleNew<T = ChartTupleKey, K = ChartTupleValue> {
    x: T;
    y: K;
}

export enum CHART_LINE_STYLE {
    FULL = 'FULL',
    DASHED = 'DASHED',
    DOTTED = 'DOTTED',
    DASH_DOTTED = 'DASH_DOTTED',
}

export interface ChartData<T, K> {
    label?: string;
    color?: COLOR;
    style?: CHART_LINE_STYLE;
    data: ChartTupleNew<T, K>[];
}

export enum CHART_TYPE {
    LINE = 'line',
    MULTI_LINE = 'multiLine',
    COLUMN = 'column',
    PIE = 'pie',
    DONUT = 'donut',
    SPIDER = 'spider',
    COMFORT = 'comfort',
    PURPOSE = 'purpose',
}

export interface ChartSetupData {
    type: CHART_TYPE;
    title?: string;
    legend?: boolean;
    xMin?: ChartTupleKey;
    xMax?: ChartTupleKey;
    yMin?: ChartTupleValue;
    yMax?: ChartTupleValue;
    background?: string;
}

export interface Chart<T = ChartTupleKey, K = ChartTupleValue> {
    setupData: ChartSetupData;
    dataSet: ChartData<T, K>[];
}

export interface AnalyticsHierarchy {
    hierarchyType: string;
    hierarchySysId: string;
    hierarchyTitle: string;
}

export interface AnalyticsCompetency {
    compSysId: string;
    descriptionShort: string;
    favorite: boolean;
}

export interface AnalyticsJob {
    id: string;
    title: string;
}

export interface LegendItem {
    color: string;
    label: string;
    valueLabel?: string;
    selected?: boolean;
}

export interface ColoredChartData<T, K> extends ChartData<T, K> {
    color: COLOR;
}

export enum ANALYTICS_TAB_TYPE {
    CULTURE = 0,
    PERFORMANCE = 1,
    REPORTS = 2,
}

export interface AnalyticsFilterObject {
    dateFrom?: Moment;
    dateTo?: Moment;
    hierarchies: string[];
    jobs: string[];
    questTypes?: QuestType[];
}

export enum RULER_ORIENTATION {
    HORIZONTAL = 'HORIZONTAL',
    VERTICAL = 'VERTICAL',
}
