import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';

interface Props {
    name: string;
    title: string;
    control: any;
}

const FtCheckbox: FC<Props> = ({ name, title, control }) => (
    <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
            <FormControlLabel
                control={<Checkbox checked={value} onChange={onChange} color="primary" />}
                label={title}
            />
        )}
    />
);

export default FtCheckbox;
