import axios from 'axios';
import type {
    IApiResponse,
    IApiResponseData,
    IAutocompleteView,
    ICreateSurveyCommand,
    ICreateSurveyQuestionCommand,
    ICreateSurveyQuestionGroupCommand,
    ICreateSurveyStepCommand,
    ISearch,
    ISearchView,
    ISurveyDetailsView,
    ISurveyView,
    IUpdateSurveyCommand,
    IUpdateSurveyQuestionCommand,
    IUpdateSurveyQuestionGroupCommand,
    IUpdateSurveyStepCommand,
} from 'types';
import { api } from '../constants';

const controllerApi = api.surveys;

const getAutocomplete = async (): Promise<IAutocompleteView[]> => {
    return axios.get(`${controllerApi}/autocomplete`).then((res) => res.data);
};

const searchAsync = async (queryParams: ISearch): Promise<ISearchView<ISurveyView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

const getById = async (id: string): Promise<ISurveyDetailsView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};
const createSurvey = (data: ICreateSurveyCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};
const updateSurvey = (id: string, data: IUpdateSurveyCommand): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};
const deleteSurvey = (id: string): Promise<IApiResponse> => {
    return axios.delete(`${controllerApi}/${id}`);
};
const deleteSurveyBulk = (ids): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, { ids });
};

const createSurveyStep = (data: ICreateSurveyStepCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/steps`, data);
};
const updateSurveyStep = (id: string, data: IUpdateSurveyStepCommand): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/steps/${id}`, data);
};
const deleteSurveyStep = (id: string): Promise<IApiResponse> => {
    return axios.delete(`${controllerApi}/steps/${id}`);
};

const createSurveyQuestionGroup = (
    data: ICreateSurveyQuestionGroupCommand,
): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/groups`, data);
};
const updateSurveyQuestionGroup = (
    id: string,
    data: IUpdateSurveyQuestionGroupCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/groups/${id}`, data);
};
const deleteSurveyQuestionGroup = (id: string): Promise<IApiResponse> => {
    return axios.delete(`${controllerApi}/groups/${id}`);
};

const createSurveyQuestion = (data: ICreateSurveyQuestionCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/questions`, data);
};
const updateSurveyQuestion = (
    id: string,
    data: IUpdateSurveyQuestionCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/questions/${id}`, data);
};
const deleteSurveyQuestion = (id: string): Promise<IApiResponse> => {
    return axios.delete(`${controllerApi}/questions/${id}`);
};

export const surveyService = {
    getAutocomplete,
    searchAsync,
    getById,
    createSurvey,
    updateSurvey,
    deleteSurvey,
    deleteSurveyBulk,
    createSurveyStep,
    updateSurveyStep,
    deleteSurveyStep,
    createSurveyQuestionGroup,
    updateSurveyQuestionGroup,
    deleteSurveyQuestionGroup,
    createSurveyQuestion,
    updateSurveyQuestion,
    deleteSurveyQuestion,
};
