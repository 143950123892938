export interface ExperienceLevelValue {
    id?: string;
    levelId: number;
    descriptionShort: string;
    descriptionLong?: string;
    pointsFrom: number;
    pointsTo: number;
}

export enum EXPERIENCE_LEVEL_STATUS {
    INACTIVE = 'Inactive',
    ACTIVE = 'Active',
}

export interface IExperienceLevelView {
    id: string;
    title: string;
    status: EXPERIENCE_LEVEL_STATUS;
    values: ExperienceLevelValue[];
}

export interface IUpsertExperienceLevelCommand {
    title: string;
    status: EXPERIENCE_LEVEL_STATUS;
    values: ExperienceLevelValue;
}
