import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.userImport;

const ImportsLoader = Loadable({
    loader: () => import('views/admin/Imports'),
    loading: Loading,
    render(loaded, props) {
        const { Imports } = loaded;

        return <Imports {...props} />;
    },
    delay: general.delay,
});

const ImportDetailLoader = Loadable({
    loader: () => import('views/admin/Imports'),
    loading: Loading,
    render(loaded, props) {
        const { ImportItemDetail } = loaded;

        return <ImportItemDetail {...props} />;
    },
    delay: general.delay,
});

const ZipImportLoader = Loadable({
    loader: () => import('views/admin/Imports'),
    loading: Loading,
    render(loaded, props) {
        const { ZipImport } = loaded;

        return <ZipImport {...props} />;
    },
    delay: general.delay,
});

export const importsRoute = {
    path: `/${mainRoute}`,
    component: ImportsLoader,
};

export const importDetailRoute = {
    path: `/${mainRoute}/detail/:importId`,
    component: ImportDetailLoader,
};

export const zipImportRoute = {
    path: `/${mainRoute}/zipImport`,
    component: ZipImportLoader,
};
