import { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import type { ChartTuple } from 'types';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;
const LINE_WIDTH = 4;
const MARGIN = 10;
const Y_AXIS_WIDTH = 50;
const Y_AXIS_GUIDE_WIDTH = 1;

export interface Props<T> {
    positive: ChartTuple<T>[];
    negative: ChartTuple<T>[];
    height: number;
    width: number;
    className?: string;
}

export const FeedbackComparisonChart = <T extends {}>({
    positive,
    negative,
    height,
    width,
    className = '',
}: Props<T>) => {
    const chartRef = useRef(null);
    const yAxisRef = useRef(null);
    const xDomain = [...positive, ...negative].reduce((acc, { key, value }) => {
        if (value != null && acc.indexOf(key) === -1) {
            acc.push(key);
        }

        return acc;
    }, [] as T[]);
    const xDomainStepping = (width - 2 * LINE_WIDTH - Y_AXIS_WIDTH) / (xDomain.length - 1);
    const xRange = xDomain.map((item, index) => index * xDomainStepping);
    const yAxisTicks = [0, 25, 50, 75, 100];

    const xScale = d3.scaleOrdinal<T, number>().domain(xDomain).range(xRange);

    const yScale = d3
        .scaleLinear<number, number>()
        .domain([0, 100])
        .range([height - LINE_WIDTH - MARGIN, LINE_WIDTH + MARGIN])
        .nice();

    const line = d3
        .line<ChartTuple<T>>()
        .x(({ key }) => xScale(key))
        .y(({ value }) => yScale(value) || 0);

    const yGuideLine = d3
        .line<number>()
        .x((value, idx) => {
            return idx === 0 ? 0 : width - 2 * LINE_WIDTH - Y_AXIS_WIDTH;
        })
        .y((value) => yScale(value) || 0);

    const renderYAxisTicks = () => {
        if (!positive.length && !negative.length) {
            return;
        }

        const selection = d3.select(yAxisRef.current).selectAll('.yTicks').data(yAxisTicks).enter();

        selection
            .append('path')
            .transition()
            .attr('fill', 'none')
            .attr('stroke', colors.grey)
            .attr('stroke-width', Y_AXIS_GUIDE_WIDTH)
            .attr('stroke-linecap', 'round')
            .attr('stroke-opacity', '50%')
            .attr('d', (d) => yGuideLine([d, d]));
        selection
            .append('text')
            .transition()
            .attr('y', (d) => (yScale(d) || 0) + 5)
            .attr('x', xScale(xDomain[0]) - Y_AXIS_WIDTH / 2)
            .attr('text-anchor', 'middle')
            .attr('fill', colors.grey)
            .text((d) => `${d}%`);
    };

    const renderLine = (data: ChartTuple<T>[], color: string) => {
        d3.select(chartRef.current)
            .append('path')
            .transition()
            .attr('fill', 'none')
            .attr('stroke', color)
            .attr('stroke-width', LINE_WIDTH)
            .attr('stroke-linecap', 'round')
            .attr('d', line(data) || '');
    };

    useEffect(() => {
        if (width && height) {
            d3.select(chartRef.current).selectAll('*').remove();
            d3.select(yAxisRef.current).selectAll('*').remove();
            renderYAxisTicks();
            renderLine(positive, colors.peach);
            renderLine(negative, colors.teal);
        }
    }, [positive, negative, height, width, className]);

    return (
        <svg width="100%" height={height} className={className}>
            <g ref={yAxisRef} transform={`translate(${Y_AXIS_WIDTH},0)`} />
            <g ref={chartRef} transform={`translate(${Y_AXIS_WIDTH},0)`} />
        </svg>
    );
};
