import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => ({
    cardContent: {
        height: '100%',
    },
    card: {
        height: '100%',
    },
    graphTitle: css({
        textTransform: 'uppercase',
        color: colors.grey,
    }),
});
