import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import type { Job } from 'types';
import { JobPositionCard } from '../JobPositionCard';

interface Props {
    jobs: Job[];
}

export const JobPositionsList = ({ jobs }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="h2" component="h2" gutterBottom>
                {t('jobFamilyProfile.jobs.title.label')}
            </Typography>
            <Grid container spacing={2}>
                {!!jobs.length &&
                    jobs.map((job) => (
                        <Grid item key={job.id} xs={12}>
                            <JobPositionCard job={job} />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
};
