import { createContext, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';
import type { AdminTileTab } from 'types';

interface AdminContextType {
    tileTabs: AdminTileTab[];
    selectedPerformanceCycleId: string;
    preSelectedTab: number;
    setPreSelectedTab: (tab: number) => void;
}

export const AdminContext = createContext<AdminContextType>({
    tileTabs: [],
    selectedPerformanceCycleId: '',
    preSelectedTab: 0,
    setPreSelectedTab: () => {},
});

interface Props {
    children: ReactNode;
    selectedPerformanceCycleId: string;
    tileTabs: AdminTileTab[];
}

export const AdminProvider: FC<Props> = ({ children, selectedPerformanceCycleId, tileTabs }) => {
    const [preSelectedTab, setPreSelectedTab] = useState(0);

    const contextValue = useMemo(
        () => ({ selectedPerformanceCycleId, tileTabs, preSelectedTab, setPreSelectedTab }),
        [tileTabs, selectedPerformanceCycleId, preSelectedTab],
    );

    return <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>;
};
