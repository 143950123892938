import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import type { SystemInstanceUser } from 'types';
import { useStyles } from './styles';
import { FtUserItem } from './FtUserItem';

interface Props {
    disabled?: boolean;
    items: SystemInstanceUser[];
    onChange: (items: SystemInstanceUser[]) => void;
}

export const FtUsersList: FC<Props> = ({ disabled, items, onChange }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleItemChange = (item: SystemInstanceUser) =>
        onChange([...items.slice(0, item.order ?? 0), item, ...items.slice((item.order ?? 0) + 1)]);

    const handleDeleteUserClick = (index) => () =>
        onChange(
            [...items.slice(0, index), ...items.slice(index + 1)].map((keyResult, order) => ({
                ...keyResult,
                order,
            })),
        );

    const handleAddUserClick = () =>
        onChange([
            ...items,
            { order: items.length, email: '', firstName: '', lastName: '', roles: [] },
        ]);

    return (
        <Grid container spacing={2}>
            {items?.map((item, index) => (
                <Grid item key={item.order} xs={12}>
                    <div className={classes.keyResultInputWrapper}>
                        <FtUserItem item={item} onChange={handleItemChange} disabled={disabled} />
                        <Button
                            onClick={handleDeleteUserClick(index)}
                            variant="outlined"
                            size="large"
                            sx={classes.keyResultDeleteButton}
                        >
                            <Icon path={mdiTrashCanOutline} size={1} />
                        </Button>
                    </div>
                </Grid>
            ))}
            <Button onClick={handleAddUserClick} sx={classes.keyResultAddButton}>
                {t('Add user')}
            </Button>
        </Grid>
    );
};
