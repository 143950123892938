export const cs = {
    translation: {
        profile: {
            actions: {
                giveFeedback: 'Dát zpětnou vazbu',
                updateAvatar: {
                    update: 'Aktualizovat',
                    saveChanges: 'Uložit změny',
                    cancel: 'Zrušit',
                    imagePlaceholderLabel: 'Vyberte soubor',
                },
            },
            settings: {
                changeLanguage: 'Změnit jazyk',
                revokeConsent: 'Zrušit souhlas s GDPR',
                changePassword: 'Změnit heslo',
                language: {
                    english: { label: 'Anglicky' },
                    german: { label: 'Německy' },
                    slovak: { label: 'Slovensky' },
                    czech: { label: 'Česky' },
                    croatian: { label: 'Chorvatsky' },
                    slovenian: { label: 'Slovinsky' },
                },
            },
            info: {
                level: 'LEVEL',
                experience: 'ZKUŠENOSTI',
                inventory: 'INVENTÁŘ',
            },
        },
    },
};
