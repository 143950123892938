import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.avatarAdvices;

const AvatarAdviceLoader = Loadable({
    loader: () => import('views/admin/AvatarAdvice'),
    loading: Loading,
    render(loaded, props) {
        const { AvatarAdvice } = loaded;
        return <AvatarAdvice {...props} />;
    },
    delay: general.delay,
});

const AvatarAdviceEditLoader = Loadable({
    loader: () => import('views/admin/AvatarAdvice/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});
const AvatarAdviceBulkImportLoader = Loadable({
    loader: () => import('views/admin/AvatarAdviceBulkImport'),
    loading: Loading,
    render(loaded, props) {
        const { AvatarAdviceBulkImport } = loaded;
        return <AvatarAdviceBulkImport {...props} />;
    },
    delay: general.delay,
});

export const avatarAdviceRoute = {
    path: `/${mainRoute}`,
    component: AvatarAdviceLoader,
};

export const avatarAdviceCreateRoute = {
    path: `/${mainRoute}/create`,
    component: AvatarAdviceEditLoader,
};

export const avatarAdviceEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: AvatarAdviceEditLoader,
};

export const avatarAdviceBulkImportRoute = {
    path: `/${mainRoute}/bulkImport`,
    component: AvatarAdviceBulkImportLoader,
};
