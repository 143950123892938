import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useBoundActions, useQuestLogFilter } from 'hooks';
import type { JobProfileQuest, JobProfileHeader } from 'types';
import { KeyResultAssignedTo, PROFILE_TYPE } from 'types';
import { setQuestPreFillData } from 'store/slices/quest';

import { EvaluationQuestCard } from '../../evaluation/EvaluationQuestCard';
import { useStyles } from './styles';

interface Props {
    emptyMessageKey?: string;
    job: JobProfileHeader;
    quests: JobProfileQuest[];
    type: PROFILE_TYPE;
}

const NUM_QUESTS_TO_SHOW = 3;

const ASSIGNED_TO_TYPE_MAP = {
    [PROFILE_TYPE.JOB]: KeyResultAssignedTo.JOB,
    [PROFILE_TYPE.JOB_FAMILY]: KeyResultAssignedTo.JOB,
    [PROFILE_TYPE.PLAYER]: KeyResultAssignedTo.EMPLOYEE,
    [PROFILE_TYPE.TEAM]: KeyResultAssignedTo.TEAM,
};

export const JobProfileQuestList = ({ emptyMessageKey, job, quests, type }: Props) => {
    const { setQuestLogFilterPreFillDataAction } = useQuestLogFilter();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setQuestPreFillDataAction } = useBoundActions({
        setQuestPreFillData,
    });
    const classes = useStyles();

    const handleQuestLogButtonClick = () => {
        if (type !== PROFILE_TYPE.JOB_FAMILY) {
            setQuestLogFilterPreFillDataAction({
                assignedToWithType: {
                    id: job.id,
                    type,
                },
            });
        }

        navigate('/quest/log');
    };

    const handleCreateQuestButtonClick = () => {
        if (type !== PROFILE_TYPE.JOB_FAMILY) {
            setQuestPreFillDataAction({
                assignedTo: [
                    {
                        group: t('quest.addPlayerStep.form.assignTo.groups.jobs.label'),
                        type: ASSIGNED_TO_TYPE_MAP[type],
                        name: job.title,
                        id: job.id,
                        profilePhoto: job.profilePhoto,
                    },
                ],
            });
        }

        navigate('/quest/new');
    };

    return (
        <>
            <div className={classes.titleWrapper}>
                <Typography variant="h2" component="h4" sx={classes.title}>
                    {t('jobProfile.jobProfileQuestList.title.label')}
                </Typography>
                {quests.length > NUM_QUESTS_TO_SHOW && (
                    <Button onClick={handleQuestLogButtonClick}>
                        {t('jobProfile.jobProfileQuestList.actions.seeAll.label', {
                            count: quests.length,
                        })}
                    </Button>
                )}
                {!quests.length && (
                    <Button onClick={handleQuestLogButtonClick}>
                        {t('jobProfile.jobProfileQuestList.actions.goToQuestLog.label')}
                    </Button>
                )}
            </div>
            <Grid container spacing={2}>
                {!quests.length && (
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={classes.emptyContent}>
                                <Typography variant="h4" sx={classes.emptyQuestsTitle}>
                                    {t(
                                        emptyMessageKey ||
                                            'jobProfile.jobProfileQuestList.emptyMessage.label',
                                    )}
                                </Typography>
                                <Button onClick={handleCreateQuestButtonClick} variant="contained">
                                    {t('jobProfile.jobProfileQuestList.actions.createQuest.label')}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {!!quests.length &&
                    quests.slice(0, NUM_QUESTS_TO_SHOW).map((quest) => (
                        <Grid item key={quest.id} xs={12}>
                            <EvaluationQuestCard quest={quest} />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
};
