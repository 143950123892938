import { hexToRgba } from './themeService';
import { frankyTalesTheme } from './frankyTalesTheme';

const { colors } = frankyTalesTheme;

const button = {
    textTransform: 'initial' as const,
    fontSize: '1rem',
};

const caption = {
    color: hexToRgba(colors.grey),
};

export const MuiTypography = {
    styleOverrides: {
        button,
        caption,
    },
};
