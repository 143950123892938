import { useTheme } from '@mui/material';
import { frankyTalesTheme } from 'theme';
import { useLayout } from 'hooks';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (useDarkText, hideGreeting) => {
    const { isXs, isSm, isMd } = useLayout();
    const isMdAndLower = isXs || isSm || isMd;

    const theme = useTheme();

    return {
        headerBar: {
            background: 'transparent',
            boxShadow: 'none',
            display: 'flex',
            marginTop: theme.spacing(isMdAndLower ? 1 : 5),
            marginBottom: theme.spacing(1),
        },
        toolBar: {
            height: theme.spacing(6),
        },
        greetingsWrapper: css(
            hideGreeting
                ? {}
                : {
                      margin: 'auto',
                      marginTop: theme.spacing(6),
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  },
        ),
        logo: css({
            ...(hideGreeting
                ? {}
                : {
                      marginBottom: theme.spacing(3),
                  }),
        }),
        buttonWidth: css({
            width: theme.spacing(6),
        }),
        menuButton: {
            ...(hideGreeting
                ? {}
                : {
                      position: 'absolute',
                      right: '-12px',
                  }),
            color: useDarkText ? colors.darkBlue : colors.white,
        },
        logoWrapper: css({
            ...(hideGreeting
                ? {
                      justifyContent: 'space-between',
                  }
                : {}),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        }),
    };
};
