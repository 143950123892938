import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        graphTitle: css({
            textTransform: 'uppercase',
            color: colors.grey,
        }),
        card: {
            height: '100%',
        },
        cardContent: {
            height: '100%',
        },
        legend: css({
            marginTop: theme.spacing(3),
        }),
    };
};
