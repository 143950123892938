import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { useLayout } from 'hooks';

export const useStyles = (): any => {
    const theme = useTheme();
    const { isXs, isSm } = useLayout();

    let select = {};

    if (!isXs && !isSm) {
        select = { position: 'absolute', right: 0, top: theme.spacing(1.5) };
    } else {
        select = {
            marginBottom: theme.spacing(2),
        };
    }

    return {
        select: css(select),
    };
};
