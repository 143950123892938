import { useEffect } from 'react';
import type { VFC } from 'react';
import type { Quest } from 'types';
import { useService } from 'hooks';
import { Loading } from 'components';
import { api } from 'services';
import { Grid } from '@mui/material';
import { QuestListCard } from '../QuestListCard';

interface Props {
    onQuestClick(questId: string): void;
}

export const CompanyQuestList: VFC<Props> = ({ onQuestClick }) => {
    const {
        data = [],
        error,
        isLoading,
        cancelServiceCall,
    } = useService<Quest[]>({
        api: api.objectiveController.getCompanyObjectives,
    });

    useEffect(() => {
        return () => cancelServiceCall();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            {data.map((quest) => (
                <Grid item key={quest.objectiveId} xs={12}>
                    <QuestListCard quest={quest} onQuestClick={onQuestClick} />
                </Grid>
            ))}
            {isLoading && (
                <Grid item xs={12}>
                    <Loading transitionEnterDuration={0} transitionDelayIn={0} />
                </Grid>
            )}
        </Grid>
    );
};
