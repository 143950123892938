import type { FeedbackToEntity } from './Feedback';

export enum NOTIFICATION_STATUS {
    CREATED = 'Created',
    SENT = 'Sent',
    SEEN = 'Seen',
    ARCHIVED = 'Archived',
}

export enum NOTIFICATION_SOURCE {
    FEEDBACK = 'Feedback',
    HELPFUL = 'Helpful',
    NOT_HELPFUL = 'HelpfulNot',
    STORE = 'Store',
    QUEST = 'Quest',
}

export enum NOTIFICATION_FEATURE {
    VIEW_FEEDBACK = 'ViewFeedback',
    VIEW_QUEST = 'ViewQuest',
    VIEW_QUEST_EVALUATION = 'ViewQuestEvaluation',
    STAR_QUEST_SELF_EVALUATION = 'StarQuestSelfEvaluation',
    STAR_QUEST_MNGR_EVALUATION = 'StarQuestMngrEvaluation',
    START_PEOPLE_SELF_EVALUATION = 'StartPeopleSelfEvaluation',
    START_PEOPLE_MNGR_EVALUATION = 'StartPeopleMngrEvaluation',
    VIEW_PEOPLE_SELF_EVALUATION = 'ViewPeopleSelfEvaluation',
    VIEW_PEOPLE_MNGR_EVALUATION = 'ViewPeopleMngrEvaluation',
}

export enum NOTIFICATION_DIRECTION {
    FEEDBACK_PROVIDER_TO_RECEIVER = 'FeedbackProviderToReceiver',
    FEEDBACK_RECEIVER_TO_PROVIDER = 'FeedbackReceiverToProvider',
    BALANCE_CHANGED_FEEDBACK_PROVIDER = 'BalanceChangedFeedbackProvider',
    BALANCE_CHANGED_FEEDBACK_RECEIVER = 'BalanceChangedFeedbackReceiver',
    BALANCE_CHANGED_STORE = 'BalanceChangedStore',
    QUEST_CREATOR_TO_ASSINGED_PEOPLE = 'QuestCreatorToAssingedPeople',
    QUEST_CREATOR_TO_MANAGER = 'QuestCreatorToManager',
    SYSTEM_TO_ACTIVITY_STREAM = 'SystemToActivityStream',
}

export enum NOTIFICATION_TYPE {
    FEEDBACK = 'Feedback',
    QUEST = 'Quest',
    OBJECTIVE_EVALUATION = 'ObjectiveEvaluation',
    JOB_PROMOTION = 'JobPromotion',
    ANNIVERSARY = 'Anniversary',
    PROCESS_REMINDER = 'ProcessReminder',
}

export enum NOTIFICATION_DATA_LINE_TYPE {
    // EMPLOYEE = 'employee',
    // COMPETENCY = 'competency',
    // POINTS = 'points',
    // COINS = 'coins',
    // QUEST = 'quest',
    // TEAM = 'team',
    // JOB = 'job',
    // JOB_FAMILY = 'jobFamily',
    EMPLOYEE = 'Employee',
    COMPETENCY = 'Competency',
    POINTS = 'Points',
    COINS = 'Coins',
    QUEST = 'Quest',
    TEAM = 'Team',
    JOB = 'Job',
    JOB_FAMILY = 'JobFamily',
    TEXT = 'Text',
}

export interface NotificationLink {
    id: string;
    linkType: string;
    name: string;
}

export interface NotificationDataLineValue {
    type: NOTIFICATION_DATA_LINE_TYPE;
    id?: string;
    displayName: string;
    coins?: number;
    points?: number;
}

export enum NOTIFICATION_QUEST_TYPE {
    TASK = 'questTask',
    SKILL = 'questSkill',
    GROWTH = 'questGrowth',
}

export interface Notification {
    title: string;
    subtitle: string;
    data: Record<string, NotificationDataLineValue>;
    date: string;
    primaryPhotoPath?: string;
    secondaryPhotoPath?: string;
    secondaryPhotoInitials?: string;
    type: NOTIFICATION_TYPE;
    xp?: number;
    coins?: number;
    isNew?: boolean;
    lines: string[];
    link?: NotificationLink;
    activityStreamId: string;
    id: string;
    secondaryPhotoPathId?: string;
    entitiesTo: FeedbackToEntity[];
}
