import type { FC } from 'react';
import { TextField } from '@mui/material';

interface Props {
    name: string;
    title: string;
    type?: string;
    required?: boolean;
    register: any;
    multiline?: boolean;
    errors: any;
    size?: string;
    rows?: number;
    validation?: object;
}

export const FtTextbox: FC<Props> = ({
    name,
    title,
    type,
    required = false,
    multiline,
    register,
    size = 'normal',
    errors,
    rows = 1,
    validation,
}) => (
    <TextField
        fullWidth
        type={type}
        size={size}
        multiline={multiline || rows > 1}
        minRows={rows}
        label={`${title} ${(required && '*') || ''}`}
        {...register(name, {
            required,
            ...validation,
        })}
        error={errors[name]}
    />
);
