import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import type { ActivityStreamFilterObject, AssignedToWithType } from 'types';

export type ActivityStreamFilterPreFillData = {
    timeStamp: string;
    filter: ActivityStreamFilterObject;
    assignedToWithType?: AssignedToWithType;
};

export interface ActivityStreamState {
    activityStreamFilterPreFillData?: ActivityStreamFilterPreFillData;
}

interface PrefillDataPayload {
    filter: ActivityStreamFilterObject;
    assignedToWithType?: AssignedToWithType;
}

export const initialState: ActivityStreamState = {
    activityStreamFilterPreFillData: undefined,
};

const activityStream = createSlice({
    initialState,
    name: 'activityStream',
    reducers: {
        setActivityStreamFilterPreFillData: (
            state,
            { payload }: PayloadAction<PrefillDataPayload>,
        ) => {
            state.activityStreamFilterPreFillData = {
                timeStamp: moment().format(),
                filter: payload.filter,
                assignedToWithType: payload.assignedToWithType,
            };
        },
    },
});

export const {
    actions: { setActivityStreamFilterPreFillData },
    reducer: activityStreamReducer,
} = activityStream;
