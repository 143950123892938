export enum COLOR {
    ORANGE = 'orange',
    ORANGE_DARKER = 'orangeDarker',
    BACKPACK_ORANGE = 'backpackOrange',
    BLUE = 'blue',
    BLUE_LIGHTER_1 = 'blueLighter1',
    SKY_BLUE = 'skyBlue',
    LIGHT_SKY_BLUE = 'lightSkyBlue',
    BLUE_DARKER = 'blueDarker',
    DARK_BLUE = 'darkBlue',
    DARK_BLUE_LIGHTER_1 = 'darkBlueLighter1',
    GREY = 'grey',
    GREY_LIGHTER_1 = 'greyLighter1',
    GREY_LIGHTER_2 = 'greyLighter2',
    LIGHT_BLUE = 'lightBlue',
    TEAL = 'teal',
    TEAL_DARKER_1 = 'tealDarker1',
    TEAL_LIGHTER_1 = 'tealLighter1',
    LIGHT_GREEN = 'lightGreen',
    LIGHT_TEAL = 'lightTeal',
    PEACH = 'peach',
    LIGHT_PEACH = 'felightPeache4bc',
    PINK_RED = 'pinkRed',
    WHITE = 'white',
    LOGO_LIGHT_BLUE = 'logoLightBlue',
}
