import type { FC } from 'react';
import { FEEDBACK_STEP_TYPE } from 'types';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    IconButton,
    LinearProgress,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
} from '@mui/material';
import type { Theme } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useStyles } from './styles';

interface Props {
    step: FEEDBACK_STEP_TYPE;
    steps: FEEDBACK_STEP_TYPE[];
    onStepClick: (step: FEEDBACK_STEP_TYPE) => void;
}

const STEP_TITLES = {
    [FEEDBACK_STEP_TYPE.TARGET]: 'feedback.new.userStep.stepTitle',
    [FEEDBACK_STEP_TYPE.COMPETENCY]: 'feedback.new.competencyStep.stepTitle',
    [FEEDBACK_STEP_TYPE.AVATAR]: 'feedback.new.avatarStep.stepTitle',
    [FEEDBACK_STEP_TYPE.FEEDBACK]: 'feedback.new.feedbackStep.stepTitle',
};

const STEP_VALUES = {
    [FEEDBACK_STEP_TYPE.TARGET]: 25,
    [FEEDBACK_STEP_TYPE.COMPETENCY]: 50,
    [FEEDBACK_STEP_TYPE.AVATAR]: 75,
    [FEEDBACK_STEP_TYPE.FEEDBACK]: 100,
};

export const FeedbackStepper: FC<Props> = ({ step, steps, onStepClick }) => {
    const classes = useStyles();
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
    const { t } = useTranslation();

    const handleStepClick = (goToStep: FEEDBACK_STEP_TYPE) => () => {
        if (goToStep < step) {
            onStepClick(goToStep);
        }
    };

    if (step === FEEDBACK_STEP_TYPE.SUCCESS) {
        return null;
    }

    return (
        <div className={classes.maxWidthXs}>
            {!isXs && (
                <Stepper activeStep={steps.indexOf(step)} sx={classes.stepper} alternativeLabel>
                    {steps.map((possibleStep) => (
                        <Step
                            completed={possibleStep < step}
                            onClick={handleStepClick(possibleStep)}
                        >
                            <StepLabel>{''}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}
            {isXs && (
                <LinearProgress
                    variant="determinate"
                    value={STEP_VALUES[step] || 0}
                    sx={classes.progress}
                />
            )}
            <Grid container sx={classes.stepTitleContainer}>
                <Grid item xs={1} sx={classes.backArrow}>
                    {isXs && step !== FEEDBACK_STEP_TYPE.TARGET && (
                        <IconButton
                            onClick={handleStepClick(steps.indexOf(step) - 1)}
                            color="primary"
                            size="large"
                        >
                            <ChevronLeft />
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={10} sx={classes.stepTitle}>
                    <Typography align="center" component="h2" variant="body1">
                        {t(STEP_TITLES[step] || '')}
                    </Typography>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        </div>
    );
};
