import type { ChangeEvent, FC, ReactNode } from 'react';
import {
    Divider,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { TooltipColor } from 'types';
import { TRow } from './TRow';
import { useStyles } from './styles';

interface Props {
    columns: {
        propName: string;
        title: string;
        type?: string;
        align?: 'right' | 'center' | undefined;
        badgeText?: (value: string, row: any) => string;
        color?: (value: string, row: any) => any;
        tooltip?: (value: string, row: any) => string;
        tooltipColor?: (value: string, row: any) => TooltipColor;
        tooltipText?: (value: string, row: any) => string;
        onClick?: (item: any) => void;
    }[];
    idProp?: string;
    rows: any[];
    selectedItems?: any[];
    selectItem?: (e: any, id: any) => any;
    selectAllItems?: (e: any) => any;
    handleEdit?: (id: any) => any;
    handleAddNew?: (id: any) => any;
    mandatory?: boolean;
    totalPages?: number;
    onPageChange?: (evt: ChangeEvent<unknown>, page: number) => void;
    page?: number;
    title?: string | null;
    renderCollapsible?: (row: any) => ReactNode;
}

export const FtTable: FC<Props> = ({
    columns,
    idProp = 'id',
    rows,
    selectedItems,
    selectItem,
    selectAllItems,
    handleEdit,
    handleAddNew,
    mandatory = false,
    totalPages,
    onPageChange,
    page,
    title,
    renderCollapsible,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const numOfColumns = columns.length + 1;

    return (
        <>
            {rows && (
                <TableContainer component={Paper}>
                    {title && (
                        <>
                            <Toolbar>
                                <Typography variant="h4" component="h2">
                                    {title}
                                </Typography>
                            </Toolbar>
                            <Divider />
                        </>
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                {selectAllItems && selectItem && selectedItems && (
                                    <TableCell>
                                        <input type="checkbox" onClick={selectAllItems} />
                                    </TableCell>
                                )}
                                {columns.map((c) => (
                                    <TableCell key={c.propName} align={c.align}>
                                        <Typography>{c.title}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TRow
                                    key={row[idProp]}
                                    columns={columns}
                                    idProp={idProp}
                                    row={row}
                                    selectedItems={selectedItems}
                                    selectItem={selectItem}
                                    selectAllItems={selectAllItems}
                                    handleEdit={handleEdit}
                                    renderCollapsible={renderCollapsible}
                                />
                            ))}
                            {(!rows || rows.length === 0) && (
                                <TableRow>
                                    {mandatory ? (
                                        <TableCell sx={classes.mandatory}>
                                            {t('Add at least one entry')}
                                        </TableCell>
                                    ) : (
                                        <TableCell colSpan={numOfColumns} align="center">
                                            {t('No data found')}
                                        </TableCell>
                                    )}
                                </TableRow>
                            )}
                        </TableBody>
                        {!!totalPages && totalPages > 1 && (
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={numOfColumns}>
                                        <Pagination
                                            sx={classes.pagination}
                                            count={totalPages}
                                            color="primary"
                                            page={page}
                                            onChange={onPageChange}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                </TableContainer>
            )}
            {handleAddNew && (
                <div className={classes.addNewContainer}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <span onClick={handleAddNew}>{t('Add new')}</span>
                </div>
            )}
        </>
    );
};
