import { useMemo, useRef } from 'react';
import { line, max, min, scaleLinear } from 'd3';
import { useChartDimensions } from 'hooks';
import type { ChartData, ChartTupleKey, ChartTupleNew, ChartTupleValue, Margin } from 'types';
import { AXIS_ORIENTATION } from 'types';
import { Axis } from '../Axis';
import { ChartRuler } from '../ChartRuler';
import { useStyles } from './styles';

const NEUTRAL_NUMERIC = 0;
const DEFAULT_MARGIN = { left: 40, right: 40, top: 20, bottom: 20 };

interface Props<T, K> {
    chartData: ChartData<T, K>[];
    margin?: Margin;
    tickFormatX?: (tick: T) => string;
    tickFormatY?: (tick: K) => string;
    ticksX?: T[];
    ticksY?: K[];
    tickWidth?: number;
    xAxisOrientation?: AXIS_ORIENTATION;
    yAxisOrientation?: AXIS_ORIENTATION;
    xMin?: ChartTupleKey;
    xMax?: ChartTupleKey;
    yMin?: ChartTupleValue;
    yMax?: ChartTupleValue;
}

const getNeutral = <T,>(value: T) => {
    if (typeof value === 'number') {
        return NEUTRAL_NUMERIC;
    }

    return null;
};

const getValue = <T,>(value: T) => value ?? NEUTRAL_NUMERIC;

const getMin = <T extends ChartTupleKey>(domain: T[], domainMin?: T): T => {
    if (domainMin) {
        return domainMin;
    }

    return min<number, number>(domain as number[], getValue) ?? 0;
};

const getMax = <T extends ChartTupleKey>(domain: T[], domainMax?: T): T => {
    if (domainMax) {
        return domainMax;
    }

    return max<number, number>(domain as number[], getValue) ?? 0;
};

export const LineChart = <T extends ChartTupleKey, K extends ChartTupleValue>({
    chartData,
    margin = DEFAULT_MARGIN,
    tickFormatX,
    tickFormatY,
    ticksX,
    ticksY,
    tickWidth,
    xAxisOrientation = AXIS_ORIENTATION.BOTTOM,
    yAxisOrientation = AXIS_ORIENTATION.LEFT,
    xMin,
    xMax,
    yMin,
    yMax,
}: Props<T, K>) => {
    const gRef = useRef(null);
    const { width, height } = useChartDimensions(gRef);
    const classes = useStyles();

    const { left, right, top, bottom } = margin;

    const { xDomain, yDomain } = chartData.reduce(
        (acc, { data }) => {
            return {
                xDomain: [...acc.xDomain, ...data.map(({ x }) => x)],
                yDomain: [...acc.yDomain, ...data.map(({ y }) => y)],
            };
        },
        { xDomain: [] as T[], yDomain: [] as K[] },
    );

    const xDomainMin = getMin(xDomain, xMin);
    const xDomainMax = getMax(xDomain, xMax);
    const yDomainMin = getMin(yDomain, yMin);
    const yDomainMax = getMax(yDomain, yMax);
    const [xRangeMin, xRangeMax] = [0 + left, width - right];
    const [yRangeMin, yRangeMax] = [height - bottom, 0 + top];

    const xScale = useMemo(
        () =>
            scaleLinear()
                .domain([xDomainMin as number, xDomainMax as number])
                .range([xRangeMin, xRangeMax])
                .nice(),
        [xDomainMin, xDomainMax, width],
    );
    const yScale = useMemo(
        () => scaleLinear().domain([yDomainMin, yDomainMax]).range([yRangeMin, yRangeMax]).nice(),
        [height, yDomainMin, yDomainMax],
    );

    const dLine = line<ChartTupleNew<T>>()
        .defined(({ y }) => y != null)
        .x(({ x }) => xScale(x as number))
        .y(({ y }) => yScale(y) || 0);

    return (
        <svg className={classes.svg} ref={gRef}>
            <ChartRuler xScale={xScale} yScale={yScale} ticks={ticksY} />
            <Axis
                format={tickFormatX}
                graphSize={{ height, width }}
                margin={margin}
                // numberOfTicks={numberOfTicksX}
                orientation={xAxisOrientation}
                scale={xScale}
                // textSpacing={textSpacing}
                ticks={ticksX}
                tickWidth={tickWidth}
                // withoutLine
            />
            <Axis
                format={tickFormatY}
                graphSize={{ height, width }}
                margin={margin}
                // numberOfTicks={numberOfTicksY}
                orientation={yAxisOrientation}
                scale={yScale}
                // textSpacing={textSpacing}
                ticks={ticksY}
                tickWidth={tickWidth}
                // withoutLine
            />
            {chartData.map(({ color, data }) => {
                return <path d={dLine(data) || ''} className={classes.line} stroke={color} />;
            })}
        </svg>
    );
};
