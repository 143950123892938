import _ from 'lodash';
import type { FC } from 'react';
import { sanitize } from 'dompurify';
import { InputAdornment, Slider, TextField, Typography } from '@mui/material';
import type { KeyResult } from 'types';
import { useField } from 'formik';
import {
    SentimentVeryDissatisfied,
    SentimentDissatisfied,
    SentimentSatisfied,
    SentimentVerySatisfied,
} from '@mui/icons-material';
import { useStyles } from './styles';

interface Props {
    keyResult: KeyResult;
}

const THROTTLE_WAIT = 50;

const valueLabelFormat = (value: number) => {
    if (value < 25) {
        return <SentimentVeryDissatisfied />;
    }

    if (value < 50) {
        return <SentimentDissatisfied />;
    }

    if (value < 75) {
        return <SentimentSatisfied />;
    }

    return <SentimentVerySatisfied />;
};

const valuetext = (value: number) => `${value}%`;

export const KeyResultInput: FC<Props> = ({ keyResult }) => {
    const classes = useStyles();
    const { id } = keyResult;
    const [{ value = 0 }, , { setValue, setTouched }] = useField({
        type: 'text',
        name: id!,
    });

    const handleChange = _.throttle(
        (evt, val?) => {
            const newValue = Number(sanitize(val ?? evt.target.value, { USE_PROFILES: {} }));

            setTouched(newValue !== 0);

            if (newValue < 0) {
                setValue(0);
                setTouched(false);

                return;
            }

            if (newValue > 100) {
                const stringValue = newValue.toString();
                const trimmedValue = Number(stringValue.substring(0, stringValue.length - 1));

                setValue(trimmedValue);

                return;
            }

            setValue(newValue);
        },
        THROTTLE_WAIT,
        {
            leading: true,
            trailing: true,
        },
    );

    return (
        <div className={classes.keyResult} key={keyResult.id}>
            <div className={classes.descriptionWrapper}>
                <Typography id={`key-result-${keyResult.id}`} sx={classes.description}>
                    {sanitize(keyResult.descriptionShort, { USE_PROFILES: {} })}
                </Typography>
                <TextField
                    type="number"
                    value={value}
                    size="small"
                    onChange={handleChange}
                    InputProps={{
                        inputProps: {
                            min: 0,
                            max: 100,
                        },
                        endAdornment: (
                            <InputAdornment position="end" sx={classes.percentageAdornment}>
                                %
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Slider
                min={0}
                max={100}
                getAriaValueText={valuetext}
                aria-labelledby={`key-result-${keyResult.id}`}
                step={1}
                valueLabelDisplay="on"
                valueLabelFormat={valueLabelFormat}
                value={value}
                onChange={handleChange}
            />
        </div>
    );
};
