import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { AppReducerState } from 'store';

const DEFAULT_WIDTH = 0;
const MENU_TRANSITION_DURATION = 226;

export const useChartDimensions = (ref) => {
    const [dimensions, setDimensions] = useState({
        height: ref?.current?.clientHeight || DEFAULT_WIDTH,
        width: ref?.current?.clientWidth || DEFAULT_WIDTH,
    });
    const { menuOpen } = useSelector(({ layout }: AppReducerState) => layout);
    let timer;

    const resize = useCallback(
        () =>
            setDimensions({
                height: ref?.current?.clientHeight || DEFAULT_WIDTH,
                width: ref?.current?.clientWidth || DEFAULT_WIDTH,
            }),
        [],
    );

    useEffect(() => {
        resize();
        window.addEventListener('resize', resize);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        timer = setTimeout(() => resize(), MENU_TRANSITION_DURATION);
    }, [menuOpen]);

    return { height: dimensions.height, width: dimensions.width, setDimensions, resize };
};
