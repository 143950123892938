import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'store';
import type { CommonError } from 'types';
import { getAllCompetencies } from './competencies';
import { getEmployeeStats, logOut } from './user';
import { loadConfiguration } from './configuration';

export interface AppState {
    errors: CommonError[];
    isLoading: boolean;
    nodalBackPage: string;
}

export const initialState: AppState = {
    errors: [],
    isLoading: true,
    nodalBackPage: 'default',
};

const appState = createSlice({
    initialState,
    name: 'appState',
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        endLoading: (state) => {
            state.isLoading = false;
        },
        setErrors: (state, { payload }: PayloadAction<CommonError[]>) => {
            state.errors = payload;
        },
        setNodalBackPage: (state, { payload }) => {
            state.nodalBackPage = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logOut, (state) => {
            state.errors = initialState.errors;
            state.isLoading = initialState.isLoading;
        });
    },
});

export const {
    actions: { startLoading, endLoading, setErrors, setNodalBackPage },
    reducer: appStateReducer,
} = appState;

export const loadAppData = (): AppThunk => async (dispatch) => {
    dispatch(startLoading());

    await Promise.all([
        dispatch(getAllCompetencies()),
        dispatch(getEmployeeStats()),
        dispatch(loadConfiguration()),
    ]);

    dispatch(endLoading());
};
