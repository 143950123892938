import { useCallback, useMemo, useRef, useState } from 'react';
import type { VFC } from 'react';
import { arc, pie, scaleOrdinal } from 'd3';
import type { PieArcDatum } from 'd3';
import { Paper } from '@mui/material';
import { frankyTalesTheme } from 'theme';
import { useChartDimensions } from 'hooks';

import { ChartLegend } from '../ChartLegend';
import { useStyles } from './styles';

const { colors } = frankyTalesTheme;

const COLORS = [
    colors.peach,
    colors.teal,
    colors.blue,
    colors.tealDarker1,
    colors.darkBlue,
    colors.tealLighter1,
];
const FULL_OPACITY = 1;
const HOVERED_OPACITY = 0.5;

interface DataItem {
    label: string;
    value: number;
    valueLabel?: string;
}

interface Props {
    data: DataItem[];
    donut?: boolean;
}

export const DonutChart: VFC<Props> = ({ data, donut = true }) => {
    const gRef = useRef(null);
    const [hoveredPathItem, setHoveredPathItem] = useState<any>();
    const [hoveredPosition, setHoveredPosition] = useState({ x: 0, y: 0 });
    const { width, height } = useChartDimensions(gRef);
    const classes = useStyles(hoveredPosition);
    const radius = height / 2;

    const colorScale = useMemo(
        () =>
            scaleOrdinal<number, string>()
                .domain(Array.from(data.map((item) => item.label).keys()))
                .range(COLORS),
        [data],
    );

    const pieTransform = useMemo(() => pie<DataItem>().value((d) => d.value), []);

    const pieData = useMemo(() => pieTransform(data), [data, pieTransform]);

    const dArc = useMemo(
        () =>
            arc<PieArcDatum<DataItem>>()
                .innerRadius(radius - height / 15)
                .innerRadius(donut ? radius - height / 15 : 0)
                .outerRadius(radius),
        [radius, height],
    );

    const handlePathMouseEnter = useCallback((item) => () => setHoveredPathItem(item), []);

    const handlePathMouseLeave = useCallback(() => setHoveredPathItem(undefined), []);

    const handlePathMouseMove = useCallback(
        (evt) => {
            setHoveredPosition({ x: evt.clientX, y: evt.clientY });
        },
        [setHoveredPosition],
    );

    const paths = useMemo(
        () =>
            pieData.map((item, index) => (
                <path
                    key={item.index}
                    d={dArc(item) || ''}
                    fill={colorScale(index)}
                    fillOpacity={hoveredPathItem === item ? HOVERED_OPACITY : FULL_OPACITY}
                    style={{ cursor: 'pointer' }} //styled here because classes cannot be memoized
                    onMouseEnter={handlePathMouseEnter(item)}
                    onMouseLeave={handlePathMouseLeave}
                    onMouseMove={handlePathMouseMove}
                />
            )),
        [
            colorScale,
            dArc,
            handlePathMouseEnter,
            handlePathMouseLeave,
            handlePathMouseMove,
            pieData,
            hoveredPathItem,
        ],
    );

    return (
        <div className={classes.wrapper}>
            <svg className={classes.svg} ref={gRef}>
                <g transform={`translate(${width / 2},${height / 2})`}>{paths}</g>
            </svg>
            {hoveredPathItem && (
                <Paper sx={classes.tooltip}>{hoveredPathItem.data.valueLabel}</Paper>
            )}
            <ChartLegend
                className={classes.legend}
                data={data.map((item, index) => ({
                    color: colorScale(index),
                    label: item.label,
                    valueLabel: item.valueLabel,
                    selected: item.label === hoveredPathItem?.data.label,
                }))}
            />
        </div>
    );
};
