import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.storeItems;

const StoreItemLoader = Loadable({
    loader: () => import('views/admin/StoreItem'),
    loading: Loading,
    render(loaded, props) {
        const { StoreItem } = loaded;
        return <StoreItem {...props} />;
    },
    delay: general.delay,
});

const StoreItemEditLoader = Loadable({
    loader: () => import('views/admin/StoreItem/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const storeItemRoute = {
    path: `/${mainRoute}`,
    component: StoreItemLoader,
};

export const storeItemCreateRoute = {
    path: `/${mainRoute}/create`,
    component: StoreItemEditLoader,
};

export const storeItemEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: StoreItemEditLoader,
};
