import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { SideMenuItem as SideMenuItemType } from 'types';
import { useBoundActions } from 'hooks';
import { closeMenu } from 'store/slices/layout';
import { ListItemButton, ListItemText, Typography, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';

interface Props {
    menuItem: SideMenuItemType;
}

export const SideMenuItem: FC<Props> = ({ menuItem }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { component: Component, labelKey = '', url } = menuItem;
    const isMdAndLower = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const { closeMenuAction } = useBoundActions({ closeMenu });

    const handleItemClick = () => {
        if (isMdAndLower) {
            closeMenuAction();
        }

        if (url) {
            navigate(url);
        }
    };

    return (
        <>
            {Component && <Component menuItem={menuItem} onMenuItemClick={handleItemClick} />}
            {!Component && (
                <ListItemButton
                    key={labelKey}
                    onClick={handleItemClick}
                    disabled={!url && !Component}
                    selected={location.pathname === url}
                >
                    <ListItemText
                        primary={<Typography variant="button">{t(labelKey)}</Typography>}
                    />
                </ListItemButton>
            )}
        </>
    );
};
