import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        headerTitle: {
            color: colors.grey,
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        relatedSkill: {
            color: colors.blue,
            borderColor: colors.blue,
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    };
};
