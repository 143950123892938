// import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

const ARC_COLORS = [
    colors.tealDarker1,
    colors.blue,
    colors.darkBlue,
    colors.teal,
    colors.tealLighter1,
];

export const useStyles = (index, disabled) => {
    const opacity = disabled ? 0.2 : 1;

    // const theme = useTheme();

    return {
        backgroundArcPath: css({
            fill: colors.greyLighter2,
        }),
        valueArcPath: css({
            fill: ARC_COLORS[index % ARC_COLORS.length],
            opacity,
        }),
        valueText: css({
            // ...theme.typography.body1,
            textAnchor: 'end',
            fill: ARC_COLORS[index % ARC_COLORS.length],
        }),
    };
};
