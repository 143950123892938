import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import type { AnalyticsFilterObject, ANALYTICS_TAB_TYPE } from 'types';

export type AnalyticsPreFillData = {
    tab?: ANALYTICS_TAB_TYPE;
    // timeStamp: string;
    filter?: AnalyticsFilterObject;
};

export interface AnalyticsState {
    analyticsPreFillData?: AnalyticsPreFillData;
}

interface PrefillDataPayload {
    tab?: ANALYTICS_TAB_TYPE;
    filter?: AnalyticsFilterObject;
}

export const initialState: AnalyticsState = {
    analyticsPreFillData: undefined,
};

const analytics = createSlice({
    initialState,
    name: 'analytics',
    reducers: {
        setAnalyticsFilterPreFillData: (state, { payload }: PayloadAction<PrefillDataPayload>) => {
            state.analyticsPreFillData = {
                // timeStamp: moment().format(),
                tab: payload.tab || state.analyticsPreFillData?.tab,
                filter: payload.filter || state.analyticsPreFillData?.filter,
            };
        },
    },
});

export const {
    actions: { setAnalyticsFilterPreFillData },
    reducer: analyticsReducer,
} = analytics;
