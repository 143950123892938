import { css } from '@emotion/css';

export const useStyles = (): any => ({
    bottomMenu: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
    },
    icon: css({
        height: '2em',
        width: '2em',
    }),
});
