import _ from 'lodash';
import { useCallback, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FEEDBACK_SHARE_TYPE } from 'types';
import type { Feedback } from 'types';
import moment from 'moment';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    IconButton,
    Typography,
} from '@mui/material';
import { CheckCircle, Close, Mood, Share } from '@mui/icons-material';
import { api, callService } from 'services';
import { useStyles } from './styles';

const BUTTON_DEBOUNCE = 500;

interface Props {
    feedback: Feedback;
    onFeedbackShared: () => void;
}

export const FeedbackShareButton: FC<Props> = ({ feedback, onFeedbackShared }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const dateTimeShared = moment(feedback.dateShared);
    const isUnShareEnabled = feedback.features.includes(FEEDBACK_SHARE_TYPE.UN_SHARE);

    if (
        !_.intersection(feedback.features, [
            FEEDBACK_SHARE_TYPE.SHARE,
            FEEDBACK_SHARE_TYPE.UN_SHARE,
        ]).length
    ) {
        return null;
    }

    const handleShareFeedback = useCallback(
        _.debounce(
            async () => {
                const { error } = await callService({
                    api: feedback.features.includes(FEEDBACK_SHARE_TYPE.SHARE)
                        ? api.feedback.share
                        : api.feedback.unShare,
                    params: [feedback.feedAdviceSysId],
                });

                setIsModalOpen(false);

                if (!error) {
                    onFeedbackShared();
                }
            },
            BUTTON_DEBOUNCE,
            {
                leading: true,
                trailing: false,
            },
        ),
        [feedback, onFeedbackShared, setIsModalOpen],
    );

    const handleFeedbackClick = () => setIsModalOpen(true);

    const handleOnModalClose = () => setIsModalOpen(false);

    return (
        <div className={classes.action}>
            <Typography
                component="span"
                variant="caption"
                sx={{ ...classes.activityStream, ...classes.teal }}
            >
                {t('myFeedback.shareFeedbackButton.label.label')}
            </Typography>
            <IconButton
                aria-label={t<string>('myFeedback.shareFeedbackButton.actions.publish.aria')}
                onClick={handleFeedbackClick}
                size="small"
                sx={{
                    ...classes.icon,
                    ...(!isUnShareEnabled ? classes.grey : {}),
                    ...(isUnShareEnabled ? classes.teal : {}),
                }}
            >
                {!isUnShareEnabled && <Share />}
                {isUnShareEnabled && <CheckCircle />}
            </IconButton>
            {feedback.dateShared && (
                <Typography variant="caption" component="span" sx={classes.sharedOn}>
                    {t('myFeedback.shareFeedbackButton.sharedOn.label', {
                        dateTime: dateTimeShared.format('DD.MM.YYYY HH:mm'),
                    })}
                </Typography>
            )}
            <Dialog
                open={isModalOpen}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="bigPadding"
            >
                <Card>
                    <CardHeader
                        action={
                            <IconButton
                                aria-label="settings"
                                onClick={handleOnModalClose}
                                size="large"
                            >
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent sx={classes.modalContent}>
                        <Typography variant="h4">
                            {!isUnShareEnabled &&
                                t('myFeedback.shareFeedbackButton.modal.title.label')}
                            {isUnShareEnabled &&
                                t('myFeedback.shareFeedbackButton.modal.titleUnShare.label')}
                        </Typography>
                        <Typography>
                            {!isUnShareEnabled &&
                                t('myFeedback.shareFeedbackButton.modal.description.label')}
                            {isUnShareEnabled &&
                                t('myFeedback.shareFeedbackButton.modal.descriptionUnShare.label')}
                        </Typography>
                        {!isUnShareEnabled && (
                            <>
                                <br />
                                <Typography variant="caption" sx={classes.caption}>
                                    {t('myFeedback.shareFeedbackButton.modal.info.label')}
                                </Typography>
                                <Mood sx={classes.good} />
                            </>
                        )}
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Button variant="outlined" onClick={handleOnModalClose}>
                            {t('myFeedback.shareFeedbackButton.modal.actions.cancel.label')}
                        </Button>
                        {!isUnShareEnabled && (
                            <Button variant="contained" onClick={handleShareFeedback}>
                                {t('myFeedback.shareFeedbackButton.modal.actions.share.label')}
                            </Button>
                        )}
                        {isUnShareEnabled && (
                            <Button variant="contained" onClick={handleShareFeedback}>
                                {t('myFeedback.shareFeedbackButton.modal.actions.unShare.label')}
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </Dialog>
        </div>
    );
};
