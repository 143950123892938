import type { LegendItem } from 'types';
import cx from 'classnames';
import { ChartLegendItem } from './ChartLegendItem';
import { useStyles } from './styles';

interface Props {
    className?: string;
    data: LegendItem[];
}

export const ChartLegend = ({ className, data }: Props) => {
    const classes = useStyles();

    return (
        <div className={cx(classes.legend, className)}>
            {data.map((item) => (
                <ChartLegendItem legendItem={item} key={item.label || item.color} />
            ))}
        </div>
    );
};
