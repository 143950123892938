import { useTheme } from '@mui/material';
import { frankyTalesTheme } from 'theme';
import { useLayout } from 'hooks';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const { isXs, isSm, isMd } = useLayout();
    const isMdAndLower = isXs || isSm || isMd;

    const theme = useTheme();

    return {
        greeting: {
            color: colors.white,
            marginBottom: theme.spacing(2),
            textAlign: isMdAndLower ? 'center' : 'initial',
        },
    };
};
