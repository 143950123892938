import type { FC } from 'react';
import cx from 'classnames';
import { CircularProgress, Fade } from '@mui/material';
import { useStyles } from './styles';

interface Props {
    loading?: boolean;
    className?: string;
    transitionEnterDuration?: number;
    transitionExitDuration?: number;
    transitionDelayOut?: number;
    transitionDelayIn?: number;
}

export const Loading: FC<Props> = ({
    className,
    loading = true,
    transitionEnterDuration = 225,
    transitionDelayIn = 800,
    transitionDelayOut = 0,
    transitionExitDuration = 0,
}) => {
    const classes = useStyles();

    return (
        <Fade
            in={loading}
            unmountOnExit
            style={{
                transitionDelay: loading ? `${transitionDelayIn}ms` : `${transitionDelayOut}ms`,
            }}
            timeout={{
                enter: transitionEnterDuration,
                exit: transitionExitDuration,
            }}
        >
            <div className={cx(classes.loader, className)}>
                <CircularProgress />
            </div>
        </Fade>
    );
};
