import axios from 'axios';
import type {
    IApiResponse,
    IEmployeeJobView,
    ISearch,
    ISearchView,
    IUpsertRelationEmployeeJobCommand,
} from 'types';
import { api } from '../constants';

const controllerApi = api.relationEmployeeJobs;

export const search = async (data: ISearch): Promise<ISearchView<IEmployeeJobView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: data,
        })
        .then((res) => res.data);
};

export const getBySysId = async (id: string): Promise<IEmployeeJobView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};

export const create = async (data: IUpsertRelationEmployeeJobCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (
    id: string,
    data: IUpsertRelationEmployeeJobCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const deleteEmployeeJobs = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
