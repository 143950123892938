import { useCallback } from 'react';
import type { FC } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { FormControl, FormHelperText, Select, MenuItem, Typography } from '@mui/material';
import type { Question } from 'types';
import { useStyles } from '../common/question.styles';

interface Props {
    className?: string;
    question: Question;
    showAsAnswer?: boolean;
    globalValidation?: boolean;
}

export const SelectQuestion: FC<Props> = ({
    className,
    question,
    showAsAnswer = false,
    globalValidation = false,
}) => {
    const { id, mandatory, questionText, options } = question;
    const classes = useStyles();
    const { t } = useTranslation();
    const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
        type: 'text',
        name: id,
        validate: (text) =>
            mandatory && !text
                ? t<string>('form.textQuestion.validation.valueMissing.label')
                : undefined,
    });

    const shouldShowError = (touched || globalValidation) && !!error;

    const onQuestionChange = useCallback((evt) => setValue(evt.target.value), [setValue]);

    const onQuestionBlur = useCallback(() => setTouched(true), [setTouched]);

    return (
        <div className={cx(classes.question, className)}>
            <Typography sx={classes.title}>
                <strong>{questionText}</strong>
            </Typography>
            {!showAsAnswer && (
                <FormControl error={shouldShowError} size="small" fullWidth>
                    <Select
                        labelId="quest-priority-input-label"
                        id="quest-priority-input"
                        value={value}
                        onChange={onQuestionChange}
                        onBlur={onQuestionBlur}
                        fullWidth
                    >
                        {options?.map((option) => (
                            <MenuItem key={`${question.id}${option.id}`} value={option.id}>
                                {option.descriptionShort}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{shouldShowError ? error : ''}</FormHelperText>
                </FormControl>
            )}
            {showAsAnswer && (
                <Typography>
                    {options?.find((option) => option.id === value)?.descriptionShort}
                </Typography>
            )}
        </div>
    );
};
