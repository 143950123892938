import type { FC } from 'react';
import cx from 'classnames';
import { Logo } from 'components';
import { SignUpProvider } from 'contexts';
import { useNavigate, useParams } from 'react-router-dom';

import { TwoFA } from './TwoFA';
import { LanguagePicker } from '../LanguagePicker';
import { useStyles as useCommonStyles } from '../common/styles';
import { useStyles } from './signUpStyles';

export const SignUpFinishing: FC = () => {
    const { signUpId } = useParams();
    const navigate = useNavigate();
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    if (!signUpId) {
        navigate('/login');

        return null;
    }

    return (
        <SignUpProvider>
            <div className={cx(commonClasses.container, classes.containerAlign)}>
                <div className={classes.contentWrapper}>
                    <Logo disableNavigation className={classes.logo} />
                    <LanguagePicker />
                    <TwoFA />
                </div>
            </div>
        </SignUpProvider>
    );
};
