import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.companyQuests;

const CompanyQuestLoader = Loadable({
    loader: () => import('views/admin/CompanyQuest'),
    loading: Loading,
    render(loaded, props) {
        const { CompanyQuest } = loaded;
        return <CompanyQuest {...props} />;
    },
    delay: general.delay,
});

const CompanyQuestEditLoader = Loadable({
    loader: () => import('views/admin/CompanyQuest/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const companyQuestRoute = {
    path: `/${mainRoute}`,
    component: CompanyQuestLoader,
};

export const companyQuestCreateRoute = {
    path: `/${mainRoute}/create/:id`,
    component: CompanyQuestEditLoader,
};

export const companyQuestEditRoute = {
    path: `/${mainRoute}/edit/:id/:objectiveId`,
    component: CompanyQuestEditLoader,
};
