import axios from 'axios';
import type { IApiResponse, ISignUpCompleteData, ISignUpUpsertCommand } from 'types';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const signUp = (data: ISignUpUpsertCommand): Promise<IApiResponse> =>
    axios.post(`${API_BASE_URL}/api/signup`, data);

export const getSignUpQRCode = (token: string): Promise<ISignUpCompleteData> =>
    axios.post(`${API_BASE_URL}/api/signup/complete`, { signUpToken: token });
