import { useCallback, useEffect, useState } from 'react';
import { useCancellableServiceCall } from './useCancellableServiceCall';
import type {
    CancellableResponse,
    CancellableServiceCallParams,
} from './useCancellableServiceCall';

export interface ServiceCallParams extends CancellableServiceCallParams {
    fetchOnMount?: boolean;
}

export interface ServiceCallResponse<T> extends CancellableResponse {
    data?: T;
    error?: Error;
}

export const useService = <T>({
    api,
    params,
    onSuccess,
    onError,
    body,
    query,
    headers,
    useToken,
    fetchOnMount = true,
}: ServiceCallParams): ServiceCallResponse<T> => {
    const [data, setData] = useState<T>();
    const [error, setError] = useState<Error>();

    const handleSuccess = useCallback(
        ({ payload }) => {
            setData(payload);
            onSuccess?.(payload);
        },
        [onSuccess],
    );

    const handleError = useCallback(
        ({ error: callError }) => {
            setError(callError);
            onError?.(callError);
        },
        [onError],
    );

    const { callService, cancelServiceCall, isLoading } = useCancellableServiceCall({
        api,
        params,
        query,
        onSuccess: handleSuccess,
        onError: handleError,
        body,
        headers,
        useToken,
    });

    useEffect(() => {
        if (fetchOnMount) {
            callService();
        }
    }, []);

    return {
        callService,
        cancelServiceCall,
        data,
        error,
        isLoading,
    };
};
