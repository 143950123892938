import { Avatar } from '@mui/material';
import { NotificationsActiveOutlined } from '@mui/icons-material';
import type { Notification } from 'types';
import { NOTIFICATION_QUEST_TYPE, QuestType } from 'types';
import { api } from 'services';

import { useStyles } from './styles';
import { QuestDetailQuestTypeIcon } from '../../quest/QuestDetailQuestTypeIcon';
import { EmployeeAvatar } from '../../avatar';

const QUEST_ICON_TYPES_MAP = {
    [NOTIFICATION_QUEST_TYPE.TASK]: QuestType.TASK,
    [NOTIFICATION_QUEST_TYPE.SKILL]: QuestType.SKILL,
    [NOTIFICATION_QUEST_TYPE.GROWTH]: QuestType.GROWTH,
};

interface Props {
    streamItem: Notification;
}

export const EventRowAvatar = ({ streamItem }: Props) => {
    const classes = useStyles();
    const isQuest = [
        NOTIFICATION_QUEST_TYPE.TASK,
        NOTIFICATION_QUEST_TYPE.SKILL,
        NOTIFICATION_QUEST_TYPE.GROWTH,
    ].includes(streamItem.primaryPhotoPath as NOTIFICATION_QUEST_TYPE);

    return (
        <div className={classes.avatarWrapper}>
            {!streamItem.primaryPhotoPath && (
                <Avatar sx={classes.characterAvatar} alt={streamItem.subtitle?.[0]}>
                    <NotificationsActiveOutlined sx={classes.bellIcon} />
                </Avatar>
            )}
            {isQuest && streamItem.primaryPhotoPath && (
                <QuestDetailQuestTypeIcon
                    questType={QUEST_ICON_TYPES_MAP[streamItem.primaryPhotoPath]}
                />
            )}
            {!isQuest && streamItem.primaryPhotoPath && (
                <EmployeeAvatar
                    sx={classes.characterAvatar}
                    alt={streamItem.subtitle?.[0]}
                    src={streamItem.primaryPhotoPath}
                />
            )}
        </div>
    );
};
