import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.priorities;

const PriorityLoader = Loadable({
    loader: () => import('views/admin/Priority'),
    loading: Loading,
    render(loaded, props) {
        const { Priority } = loaded;
        return <Priority {...props} />;
    },
    delay: general.delay,
});

const PriorityEditLoader = Loadable({
    loader: () => import('views/admin/Priority/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const priorityRoute = {
    path: `/${mainRoute}`,
    component: PriorityLoader,
};

export const priorityCreateRoute = {
    path: `/${mainRoute}/create`,
    component: PriorityEditLoader,
};

export const priorityEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: PriorityEditLoader,
};
