import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const CARD_IMAGE_HEIGHT = 300;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        avatarImage: {
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: `${CARD_IMAGE_HEIGHT}px`,
            minHeight: `${CARD_IMAGE_HEIGHT}px`,
        },
        cardContentWrapper: css({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
        }),
        card: {
            width: '100%',
            cursor: 'pointer',
        },
        moreAbout: css({
            marginTop: theme.spacing(3),
            width: '100%',
            justifyContent: 'center',
        }),
    };
};
