import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        card: {
            height: '100%',

            '& .MuiCardContent-root': {
                padding: theme.spacing(3),
            },

            cursor: 'pointer',
        },
        icon: {
            backgroundColor: colors.orange,
        },
        title: {
            textTransform: 'capitalize',
            color: colors.grey,
            fontSize: '0.75rem',
            fontWeight: 600,
            whiteSpace: 'nowrap',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
        },
        averageHelpfulnessWrapper: {
            marginTop: theme.spacing(2),
        },
        averageHelpfulness: css({
            color: colors.darkBlue,
            fontWeight: theme.typography.fontWeightMedium,
        }),
    };
};
