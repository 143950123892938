import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        marginTop: {
            marginTop: theme.spacing(4),
        },
        marginBottom: {
            marginBottom: theme.spacing(4),
        },
        noQuestsTitleWrapper: css({
            display: 'flex',
            alignItems: 'baseline',
        }),
        noQuestsTitle: {
            marginBottom: theme.spacing(2),
            flex: '1 0 auto',
        },
        centered: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        emptyQuestsTitle: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    };
};
