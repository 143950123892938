import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        titleContainer: css({
            backgroundColor: '#f3f3f3',
            paddingLeft: theme.spacing(3),
            padding: theme.spacing(1),
            fontSize: '1.3rem',
            marginBottom: theme.spacing(4),
        }),
    };
};
