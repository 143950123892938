import { useRef } from 'react';
import type { ScaleLinear } from 'd3';
import { useStyles } from './RadarChartLabel.styles';

const MAX_CHARS_IN_ROW = 15;
const RADIUS_MODIFIER = 1.5;

interface Props {
    index: number;
    scale: ScaleLinear<number, number>;
    sliceAngle: number;
    label: string;
}

export const RadarChartLabel = ({ index, label, scale, sliceAngle }: Props) => {
    const labelRef = useRef(null);
    const classes = useStyles();
    const maxValue = scale.domain()[1];
    const words = label.split(/\s+/);
    const lines = words.reduce((acc, item) => {
        if (!acc.length) {
            return [item];
        }

        const [last, ...rest] = acc.slice().reverse();
        const newLine = [last].concat(item).join(' ');

        if (newLine.length > MAX_CHARS_IN_ROW) {
            return [...acc, item];
        }

        return [...rest.reverse(), newLine];
    }, [] as string[]);

    return (
        <g
            transform={`translate(${
                scale(maxValue * RADIUS_MODIFIER) * Math.cos(sliceAngle * index - Math.PI / 2)
            } ${scale(maxValue * RADIUS_MODIFIER) * Math.sin(sliceAngle * index - Math.PI / 2)})`}
        >
            <text x={0} y={0} className={classes.valueText} ref={labelRef}>
                {lines.map((line, idx) => (
                    <tspan x={0} dy={`${idx ? 1 : 0}rem`} key={line}>
                        {line}
                    </tspan>
                ))}
            </text>
        </g>
    );
};
