import type { LANGUAGE } from './App';
import type { Quest } from './Quest';
import type { Job } from './Job';

export interface CurrentUser {
    coins: number;
    competencyDescShort: string;
    email: string;
    employeeSysId: string;
    feedbackGiven: number;
    feedbackReceived: number;
    feedbackReceivedNotSeen: number;
    firstName: string;
    language: LANGUAGE;
    lastName: string;
    nickName: string;
    photoPath: string;
    points: number;
    storeTransactions: number;
    feedbackGivenCompany: number;
    feedbackGivenCompanyPercent: number;
    feedbackGivenHelpfulCompanyPercent: number;
    feedbackGivenHelpfulPercent: number;
    feedbackGivenPercent: number;
    pointsUntilLevelUp: number;
    expLevelMaxPoints: number;
}

export interface LeaderboardUser {
    value: number;
    avatarPhotoPath: string;
    employeeName: string;
    employeeSysId: string;
    employeeInitials: string;
}

export interface Leadership {
    firstName: string;
    lastName: string;
    job: Job;
    profilePhoto: string;
    coins: number;
    points: number;
    experienceLevelName: string;
    features: ProfileFeature[];
    email: string;
    employeeSysId: string;
}

export interface Employee {
    displayName: string;
    employeeSysId?: string;
    employeeId?: string;
    profilePhoto?: string;
    initials?: string;
}

export enum ProfileFeature {
    GIVE_FEEDBACK = 'Feedback',
    CREATE_QUEST = 'CreateQuest',
    EVALUATE = 'EvaluateButton',
    EVALUATION_TAB = 'EvaluationTab',
    SELF_EVALUATE = 'SelfevaluateButton',
}

export interface ProfileTeam {
    id: string;
    name: string;
    teamRoleName: string;
    noOfEmployees: number;
}

export interface ProfileData {
    coins: number;
    experienceLevelName: string;
    email: string;
    employeeSysId: string;
    firstName: string;
    lastName: string;
    points: number;
    profilePhoto: string;
    features: ProfileFeature[];
    perfCycleEmployeeEvaluationId?: string;
    job?: Job;
    teams: ProfileTeam[];
    objectives: Quest[];
    initials?: string;
}

export interface EmployeeProfile {
    coins: number;
    email: string;
    empSysId: string;
    firstName: string;
    lastName: string;
    points: number;
    profilePhoto: string;
}

export interface EmployeeEvaluationPeriod {
    no: number;
    dateFrom: string;
    dateTo: string;
    evaluationOffSetDays: number;
}

export enum UserType {
    EMPLOYEE = 'Employee',
    LEAD = 'Lead',
}

export interface EvaluationUser {
    type: UserType;
    employeeId: string;
    displayName: string;
    jobTitle: string;
    profilePhoto: string;
}

export enum USER_ROLES {
    EMPLOYEE = 'Employee',
    MANAGER = 'Manager',
    ADMIN_HR = 'AdminHR',
    SUBSCRIPTION_MANAGER = 'SubscriptionManager',
    CONFIGURATION_MANAGER = 'ConfigurationManager',
}
