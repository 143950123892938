import { useEffect, useRef } from 'react';
import type { FC } from 'react';
import * as d3 from 'd3';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

const COLORS = [colors.peach, colors.teal];

interface Props {
    positive: number;
    negative: number;
    width: number;
    className?: string;
}

export const PositiveNegativeChart: FC<Props> = ({ positive, negative, width, className = '' }) => {
    const gRef = useRef(null);
    const radius = width / 2;
    const pie = d3.pie<number>();
    const pieData = pie([positive, negative]);
    const colorScale = d3.scaleOrdinal<number, string>().domain([0, 1]).range(COLORS);
    const arc = d3
        .arc<d3.PieArcDatum<number>>()
        .innerRadius(radius - width / 15)
        .outerRadius(radius);

    useEffect(() => {
        if (width) {
            d3.select(gRef.current).selectAll('*').remove();
            d3.select(gRef.current)
                .selectAll('arc')
                .data(pieData)
                .enter()
                .append('path')
                .attr('d', arc)
                .attr('fill', (d, index) => colorScale(index));
        }
    }, [positive, negative, width, className]);

    return (
        <svg width={width} height={width} className={className}>
            <g transform={`translate(${width / 2},${width / 2})`} ref={gRef} />
        </svg>
    );
};
