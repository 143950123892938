import { useRef, useEffect, useState } from 'react';
import type { FC } from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import type { TypographyVariant } from '@mui/material';
import { useStyles, DEFAULT_HEIGHT } from './styles';

interface Props {
    className?: string;
    displaySign?: boolean;
    value?: number;
    variant?: TypographyVariant;
}

const getSign = (displaySign, value) => {
    if (!displaySign) {
        return '';
    }

    if (value >= 0) {
        return '+';
    }

    return '-';
};

export const Coins: FC<Props> = ({ className, displaySign = false, value, variant = 'body1' }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(DEFAULT_HEIGHT);
    const classes = useStyles(height);

    useEffect(() => {
        setHeight(ref.current?.clientHeight || DEFAULT_HEIGHT);
    }, []);

    return (
        <div className={cx(classes.coins, className)} ref={ref}>
            {value != null && (
                <Typography component="span" variant={variant}>{`${getSign(
                    displaySign,
                    value,
                )}${value}`}</Typography>
            )}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={DEFAULT_HEIGHT}
                height={DEFAULT_HEIGHT}
                version="1.1"
            >
                <circle cx="9" cy="11.5" r="6" stroke="orange" strokeWidth="1.5" fill="gold" />
                <circle cx="15" cy="11.5" r="6" stroke="orange" strokeWidth="1.5" fill="gold" />
            </svg>
        </div>
    );
};
