/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Autocomplete,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    TextField,
    Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { api, callService } from 'services';
import { CreateQuestContext } from 'contexts';
import { KeyResultAssignedTo, QuestStatus } from 'types';
import type { QuestAssignee } from 'types';
import type { AppReducerState } from 'store';
import { Avatar } from '../../avatar';
import { useStyles } from './styles';

const MIN_LENGTH_FOR_SEARCH = 2;

const ASSIGNED_TO_TYPES = {
    employees: {
        groupKey: 'quest.addPlayerStep.form.assignTo.groups.employees.label',
        type: KeyResultAssignedTo.EMPLOYEE,
    },
    teams: {
        groupKey: 'quest.addPlayerStep.form.assignTo.groups.teams.label',
        type: KeyResultAssignedTo.TEAM,
    },
    jobs: {
        groupKey: 'quest.addPlayerStep.form.assignTo.groups.jobs.label',
        type: KeyResultAssignedTo.JOB,
    },
};

export const AddPlayersStep: FC = () => {
    const [suggestedEmployees, setSuggestedEmployees] = useState<any[]>([]);
    const [users, setUsers] = useState<QuestAssignee[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userQuery, setUserQuery] = useState('');
    const [assigneesFocused, setAssigneesFocused] = useState(false);
    const {
        assignedTo,
        confidential,
        objectiveId,
        publishQuest,
        saveAsDraft,
        setConfidential,
        setAssignedTo,
        status,
    } = useContext(CreateQuestContext);
    const { confidentialQuests } = useSelector(
        ({ configuration }: AppReducerState) => configuration,
    );
    const { empSysId } = useSelector(({ user }: AppReducerState) => user);

    const classes = useStyles();
    const { t } = useTranslation();

    const getInputItem = (assigneeItem, key) => ({
        group: t(ASSIGNED_TO_TYPES[key].groupKey),
        type: ASSIGNED_TO_TYPES[key].type,
        name: assigneeItem.title || assigneeItem.displayName,
        id:
            assigneeItem.employeeSysId ||
            assigneeItem.employeeId ||
            assigneeItem.teamId ||
            assigneeItem.jobId,
        profilePhoto: assigneeItem.profilePhoto,
    });

    const parseSearchedUsers = (payload) =>
        Object.keys(payload).reduce(
            (acc, key) => [
                ...acc,
                ...payload[key].map((searchedUser) => getInputItem(searchedUser, key)),
            ],
            [] as any,
        );

    const handleUsersSearch = async (evt) => {
        const query = evt.target?.value;

        if (query?.length >= MIN_LENGTH_FOR_SEARCH) {
            setIsLoading(true);
            setUserQuery(query);

            const { error, payload } = await callService({
                api: api.objectiveController.objectiveAssignees,
                params: { query },
            });

            if (!error && payload) {
                setUsers(parseSearchedUsers(payload));
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        (async () => {
            const { error, payload } = await callService({
                api: api.employeeController.getObjectiveSuggestedEmployees,
            });

            if (!error && payload) {
                setSuggestedEmployees(payload);
            }
        })();
    }, []);

    const handleConfidentialChange = () => setConfidential(!confidential);

    const handleAssignedToChange = (evt, selectedAssignees) => setAssignedTo(selectedAssignees);

    const handleSuggestedUserSelected = (employee) => () => {
        const existingAssignee = assignedTo.find(({ id }) => id === employee.employeeId);

        if (!existingAssignee) {
            setAssignedTo([...assignedTo, getInputItem(employee, 'employees')]);
        }
    };

    const handleAssigneesDropdownClose = () => {
        setUsers([]);
        setUserQuery('');
    };

    const handleAssigneesFocus = () => setAssigneesFocused(true);

    const handleAssigneesBlur = () => setAssigneesFocused(false);

    const handlePublishQuestClick = () => publishQuest();

    const handlePublishChangesClick = () => publishQuest();

    const handleSaveAsDraftClick = () => saveAsDraft();

    return (
        <Card className="bigPadding">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="quest-assign-to-input">
                            <Typography>{t('quest.addPlayerStep.form.assignTo.label')}</Typography>
                        </InputLabel>
                        <Autocomplete
                            id="quest-assign-to-input"
                            sx={classes.inputChip}
                            value={assignedTo}
                            multiple
                            onChange={handleAssignedToChange}
                            renderTags={(val, getTagProps) =>
                                val.map((option, index: number) => (
                                    <Chip
                                        size="small"
                                        avatar={
                                            <Avatar
                                                alt={option.name[0]}
                                                src={option.profilePhoto}
                                            />
                                        }
                                        label={option.name}
                                        {...getTagProps({ index })}
                                        key={option.id}
                                    />
                                ))
                            }
                            isOptionEqualToValue={(option, val) => option.id === val.id}
                            getOptionLabel={(option) => option.name}
                            groupBy={(option) => option.group || ''}
                            renderOption={(props, option) => (
                                <li className={classes.avatarWrapper} {...props}>
                                    <Avatar
                                        src={option.profilePhoto}
                                        alt={option.name[0]}
                                        sx={classes.avatar}
                                    />
                                    <Typography>{option.name}</Typography>
                                    {empSysId === option.id && (
                                        <Chip
                                            label={t(
                                                'quest.addPlayerStep.form.assignTo.selfAssignBadge.label',
                                            )}
                                            size="small"
                                            variant="outlined"
                                            sx={classes.selfAssignBadge}
                                        />
                                    )}
                                </li>
                            )}
                            options={users}
                            popupIcon={null}
                            onInputChange={handleUsersSearch}
                            onClose={handleAssigneesDropdownClose}
                            onFocus={handleAssigneesFocus}
                            onBlur={handleAssigneesBlur}
                            open={(assigneesFocused && !!users.length) || !!userQuery}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: [
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>,
                                                params.InputProps.startAdornment,
                                            ],
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {confidentialQuests.enabled && (
                            <FormControl>
                                <FormControlLabel
                                    sx={classes.confidentialCheckbox}
                                    control={
                                        <Checkbox
                                            checked={confidential}
                                            onChange={handleConfidentialChange}
                                            name="Confidential"
                                        />
                                    }
                                    label={
                                        <Typography>
                                            <Trans
                                                t={t}
                                                i18nKey="quest.addPlayerStep.form.confidential.label"
                                            >
                                                <strong>Confidential</strong> This quest is not
                                                public but only visible to your manager and those
                                                assigned to it
                                            </Trans>
                                        </Typography>
                                    }
                                />
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {!!suggestedEmployees.length && (
                            <div className={classes.suggestedEmployees}>
                                <Typography variant="button">
                                    {t('feedback.new.userStep.suggestedPlayers.label')}
                                </Typography>
                                <List>
                                    {suggestedEmployees.map((employee) => (
                                        <ListItem
                                            key={employee.employeeId}
                                            button
                                            sx={classes.suggestedEmployee}
                                            onClick={handleSuggestedUserSelected(employee)}
                                        >
                                            <Avatar
                                                src={employee.profilePhoto}
                                                alt={employee.displayName[0]}
                                                sx={classes.avatar}
                                            />
                                            <Typography>{employee.displayName}</Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardActions>
                {(!objectiveId || status === QuestStatus.DRAFT) && (
                    <Button variant="outlined" onClick={handleSaveAsDraftClick}>
                        {t('quest.addPlayerStep.actions.saveAsDraft.label')}
                    </Button>
                )}
                {(!objectiveId || status === QuestStatus.DRAFT) && (
                    <Button
                        variant="contained"
                        onClick={handlePublishQuestClick}
                        disabled={!assignedTo.length}
                    >
                        {t('quest.addPlayerStep.actions.publishQuest.label')}
                    </Button>
                )}
                {objectiveId && status !== QuestStatus.DRAFT && (
                    <Button
                        variant="contained"
                        onClick={handlePublishChangesClick}
                        disabled={!assignedTo.length}
                    >
                        {t('quest.addPlayerStep.actions.publishChanges.label')}
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};
