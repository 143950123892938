import axios from 'axios';
import type {
    IApiResponse,
    IPerformanceCycleDetailsView,
    IPerformanceCycleListView,
    IUpsertPerformanceCycleCommand,
} from 'types';
import { api } from '../constants';

const controllerApi = api.performanceCycles;

const getPerfCycles = async (year?: number): Promise<IPerformanceCycleListView[]> => {
    return axios.get(`${controllerApi}${year ? `?year=${year}` : ''}`).then((res) => res.data);
};

const getPerfCycleBySysId = async (id: string): Promise<IPerformanceCycleDetailsView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};

const create = async (data: IUpsertPerformanceCycleCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

const update = async (id: string, data: IUpsertPerformanceCycleCommand): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const perfCycleService = {
    create,
    update,
    getPerfCycles,
    getPerfCycleBySysId,
};
