import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (hoveredPosition) => {
    const theme = useTheme();

    return {
        legend: css({
            marginTop: theme.spacing(2),
        }),
        svg: css({
            width: '100%',
        }),
        wrapper: css({
            width: '100%',
            height: '100%',
        }),
        tooltip: {
            padding: theme.spacing(1),
            top: `${hoveredPosition.y - 8 * 5}px`,
            left: `${hoveredPosition.x}px`,
            position: 'fixed',
            zIndex: 1000,
        },
    };
};
