export const sl = {
    translation: {
        profile: {
            actions: {
                giveFeedback: 'Dajte povratne informacije',
                updateAvatar: {
                    update: 'Posodobite',
                    saveChanges: 'Shrani spremembe',
                    cancel: 'Prekliči',
                    imagePlaceholderLabel: 'Izberite datoteko',
                },
            },
            settings: {
                changeLanguage: 'Spremeni jezik',
                revokeConsent: 'Preklic sporazuma z GDPR',
                changePassword: 'Spremeni geslo',
                language: {
                    english: { label: 'Angleščina' },
                    german: { label: 'Nemško' },
                    slovak: { label: 'Slovenski' },
                    czech: { label: 'Češko' },
                    croatian: { label: 'Hrvaški' },
                    slovenian: { label: 'Slovenščina' },
                },
            },
            info: {
                level: 'NIVO',
                experience: 'IZKUŠNJE',
                inventory: 'INVENTAR',
            },
        },
    },
};
