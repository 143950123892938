import type { FC } from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

interface Props {
    value?: number;
    className?: string;
}

export const Experience: FC<Props> = ({ value, className }) => {
    const classes = useStyles();

    if (value == null) {
        return null;
    }

    return (
        <div className={cx(classes.experience, className)}>
            <Typography component="span">
                {`${value >= 0 ? '+' : '-'}${value} `}
                <span>XP</span>
            </Typography>
        </div>
    );
};
