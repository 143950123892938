import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { api, callService, getPasswordConfirmationErrors, getPasswordErrors } from 'services';
import { Logo, PasswordInput } from 'components';
import type { FormValidationError } from 'types';
import { useStyles } from './styles';
import { useStyles as useCommonStyles } from '../common/styles';

interface Props {
    onBackToLoginClick: () => void;
}

export const ChangePasswordForm: FC<Props> = ({ onBackToLoginClick }) => {
    const [serviceError, setServiceError] = useState('');
    const [serviceSuccess, setServiceSuccess] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmation, setConfirmation] = useState('');
    const [oldPasswordErrors, setOldPasswordErrors] = useState<FormValidationError[]>([]);
    const [passwordErrors, setPasswordErrors] = useState<FormValidationError[]>([]);
    const [confirmationErrors, setConfirmationErrors] = useState<FormValidationError[]>([]);
    const { t } = useTranslation();
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const changePassword = async () => {
        const { error } = await callService({
            api: api.authentication.changePassword,
            body: { oldPassword, newPassword: password, newPasswordCopy: confirmation },
            useToken: false,
        });

        if (error) {
            setServiceError(t<string>('user.changePasswordForm.message.error.label'));
            return;
        }

        setServiceError('');
        setServiceSuccess(true);
    };

    const handleFormSubmit = (evt) => {
        evt.preventDefault();
        const oldPasswordValidationErrors = getPasswordErrors(oldPassword, t);
        const passwordValidationErrors = getPasswordErrors(password, t);
        const confirmationValidationErrors = getPasswordConfirmationErrors(
            password,
            confirmation,
            t,
        );

        setOldPasswordErrors(oldPasswordValidationErrors);
        setPasswordErrors(passwordValidationErrors);
        setConfirmationErrors(confirmationValidationErrors);
        setServiceError('');

        if (
            !oldPasswordValidationErrors.length &&
            !passwordValidationErrors.length &&
            !confirmationValidationErrors.length
        ) {
            changePassword();
        }
    };

    const handleOldPasswordChange = (value) => {
        setOldPassword(value);
        setOldPasswordErrors([]);
    };

    const handlePasswordChange = (value) => {
        setPassword(value);
        setPasswordErrors([]);
    };

    const handleConfirmationChange = (value) => {
        setConfirmation(value);
        setConfirmationErrors([]);
    };

    return (
        <>
            <Logo className={classes.logo} disableNavigation />
            {serviceSuccess && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="success">
                            {t('user.changePasswordForm.message.success.label')}
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="text"
                            sx={commonClasses.secondaryAction}
                            onClick={onBackToLoginClick}
                        >
                            {t('user.changePasswordForm.actions.backToLogin.label')}
                        </Button>
                    </Grid>
                </Grid>
            )}
            {!serviceSuccess && (
                <form onSubmit={handleFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{t('user.changePasswordForm.subTitle.label')}</Typography>
                        </Grid>
                        {serviceSuccess && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Alert severity="success">
                                        {t('user.changePasswordForm.message.success.label')}
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="text"
                                        sx={commonClasses.secondaryAction}
                                        onClick={onBackToLoginClick}
                                    >
                                        {t('user.changePasswordForm.actions.backToLogin.label')}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <PasswordInput
                                password={oldPassword}
                                onPasswordChange={handleOldPasswordChange}
                                passwordError={oldPasswordErrors[0]}
                                placeholder={t<string>(
                                    'user.changePasswordForm.form.oldPassword.placeholder',
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInput
                                password={password}
                                onPasswordChange={handlePasswordChange}
                                passwordError={passwordErrors[0]}
                                placeholder={t<string>(
                                    'user.changePasswordForm.form.newPassword.placeholder',
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInput
                                password={confirmation}
                                onPasswordChange={handleConfirmationChange}
                                passwordError={confirmationErrors[0]}
                                placeholder={t<string>(
                                    'user.changePasswordForm.form.passwordConfirmation.placeholder',
                                )}
                            />
                        </Grid>
                        {serviceError && (
                            <Grid item xs={12}>
                                <Alert severity="error">{serviceError}</Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={commonClasses.primaryAction}
                            >
                                {t('user.changePasswordForm.actions.changePassword.label')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={commonClasses.actions}>
                            <Button
                                variant="text"
                                sx={commonClasses.secondaryAction}
                                onClick={onBackToLoginClick}
                            >
                                {t('user.changePasswordForm.actions.backToLogin.label')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </>
    );
};
