import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        badge: {
            '& .MuiBadge-badge': {
                right: 'initial',
                left: theme.spacing(1),
                transform: 'translate(0%, -50%)',
                backgroundColor: colors.blue,
            },
        },
        itemButton: {
            '&.MuiListItemButton-root.Mui-selected': {
                '& .MuiTypography-root': { color: colors.blue },
            },
        },
    };
};
