import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface Props {
    step: number;
    children: ReactNode;
}

export const BulkImportStepper: FC<Props> = ({ step, children }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.progressPoint} ${step >= 1 ? 'activated' : ''}`}>
                1. Select the file
            </div>
            {step === 1 && children}
            <div className={`${classes.progressPoint} ${step >= 2 ? 'activated' : ''}`}>
                2. Data validation
            </div>
            {step === 2 && children}
        </>
    );
};
