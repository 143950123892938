import type { FC } from 'react';
import { CsvImport } from './CsvImport';

interface BulkImportStep1Interface {
    setData(data: any): any;
    ImportFileDefinition: any;
}

export const BulkImportStep1: FC<BulkImportStep1Interface> = ({
    setData,
    ImportFileDefinition,
}) => (
    <div>
        <ImportFileDefinition />
        <CsvImport setResult={setData} />
    </div>
);
