import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        title: {
            marginBottom: theme.spacing(2),
            flex: '1 0 auto',
        },
        titleWrapper: css({
            display: 'flex',
            alignItems: 'baseline',
        }),
        cardContent: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
        },
        graphTitle: css({
            textTransform: 'uppercase',
            color: colors.grey,
        }),
        legend: css({
            marginTop: theme.spacing(3),
        }),
    };
};
