import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'store';
import type { Competency } from 'types';
import { api, callService } from 'services';

export interface CompetenciesState {
    allCompetencies: Competency[];
}

const initialState: CompetenciesState = {
    allCompetencies: [],
};

const competencies = createSlice({
    initialState,
    name: 'competencies',
    reducers: {
        setAllCompetencies: (state, { payload }: PayloadAction<Competency[]>) => {
            state.allCompetencies = payload;
        },
    },
});

export const {
    actions: { setAllCompetencies },
    reducer: competenciesReducer,
} = competencies;

export const getAllCompetencies = (): AppThunk => async (dispatch, getState) => {
    const { empSysId } = getState().user;
    const { error, payload } = await callService({
        api: api.getCompetenciesByEmployeeId,
        params: [empSysId],
    });

    if (!error && payload) {
        dispatch(setAllCompetencies(payload));
    }
};
