import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { QuestDetailQuestTypeIcon } from 'components';
import type { EvaluationQuestData } from 'types';
import moment from 'moment';

import { useStyles } from './styles';

const DATE_FORMAT = 'DD.MM.YYYY';

interface Props {
    className?: string;
    quest?: EvaluationQuestData;
}

export const QuestEvaluationHeaderSection: FC<Props> = ({ className, quest }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    if (!quest) {
        return null;
    }

    return (
        <div className={cx(classes.wrapper, className)}>
            <QuestDetailQuestTypeIcon questType={quest.type} transparentBackground />
            <div className={classes.dataWrapper}>
                <Typography component="span">
                    <strong>{t('evaluation.questEvaluationHeaderSection.quest.label')}</strong>
                </Typography>

                <Typography component="span">
                    <strong>{quest.title}</strong>
                </Typography>
                <Typography component="span" variant="caption">
                    {`${moment(quest.dateFrom).format(DATE_FORMAT)} - ${moment(quest.dateTo).format(
                        DATE_FORMAT,
                    )}`}
                </Typography>
            </div>
        </div>
    );
};
