import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        avatarGroup: css({
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row-reverse',
            marginRight: theme.spacing(1.5),

            '& .MuiAvatar-root': {
                width: theme.spacing(3.5),
                height: theme.spacing(3.5),
                marginRight: theme.spacing(-1.5),
                border: '1px solid',
                borderColor: colors.white,
                fontSize: '0.75rem',

                '&.dialogButton': {
                    fontSize: '1rem',
                },
            },
        }),
        employeeAvatar: {
            width: theme.spacing(5),
            height: theme.spacing(5),
            marginBottom: theme.spacing(1),
        },
        employeeWrapper: css({
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),

            '& .MuiAvatar-root': {
                width: theme.spacing(5),
                height: theme.spacing(5),
                cursor: 'pointer',
                marginRight: theme.spacing(1),
            },
        }),
        helpfulWrapper: css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
        }),
        dialogTitle: {
            padding: `${theme.spacing(2)} !important`,
        },
        dialogContent: {
            paddingTop: '0px !important',
            textAlign: 'center',
        },
        dialogDescription: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(3),
        },
        dialogCloseButton: {
            float: 'right',
        },
        orange: css({
            color: colors.orange,

            '&.Mui-disabled': {
                color: colors.orange,
            },
        }),
        teal: css({
            color: colors.lightGreen,

            '&.Mui-disabled': {
                color: colors.lightGreen,
            },
        }),
    };
};
