import type { NumberValue } from 'd3';
import type { ChartTupleKey, ChartTupleValue } from './Analytics';

export enum AXIS_ORIENTATION {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export interface Margin {
    left: number;
    right: number;
    top: number;
    bottom: number;
}

export interface Scale<D = ChartTupleKey | ChartTupleValue, O = number> {
    (x: D): O;
    bandwidth?: () => NumberValue;
    domain: () => D[];
    range: () => O[];
    invert?: (y: O) => D;
    copy: () => Scale<D, O>;
    ticks?: (n?: number) => D[];
    nice?: () => this;
}
