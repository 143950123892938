import _ from 'lodash';
import { useEffect, useCallback } from 'react';
import { usePagination } from 'hooks';

const SEARCH_DEBOUNCE = 500;

export const useQueriedPaginatedItems = <T>({ api, query }) => {
    const {
        isLoading,
        callService,
        cancelServiceCall,
        items,
        currentPage,
        nextPageNumber,
        resetPaging,
        STARTING_PAGE,
    } = usePagination<T>({
        api,
        query,
    });

    const resetSearch = useCallback(
        _.debounce(() => {
            resetPaging();
        }, SEARCH_DEBOUNCE),
        [resetPaging],
    );

    useEffect(() => {
        if (query.query !== '' || currentPage !== STARTING_PAGE) {
            resetSearch();
        }
    }, [query]);

    useEffect(() => {
        if (currentPage === STARTING_PAGE) {
            callService();
        }
    }, [currentPage]);

    useEffect(() => {
        return () => cancelServiceCall();
    }, []);

    return {
        isLoading,
        items,
        pagingActive: !isLoading && currentPage < nextPageNumber,
        queryItems: callService,
    };
};
