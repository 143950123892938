import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { QuestStatus } from 'types';
import { useStyles } from './styles';

const STATUS_TRANSLATION_KEYS = {
    [QuestStatus.DRAFT]: 'quest.questStatus.draft.label',
    [QuestStatus.ACTIVE]: 'quest.questStatus.active.label',
    [QuestStatus.EVALUATED]: 'quest.questStatus.evaluated.label',
    [QuestStatus.COMPLETED]: 'quest.questStatus.completed.label',
    [QuestStatus.ABANDONED]: 'quest.questStatus.abandoned.label',
    [QuestStatus.ARCHIVED]: 'quest.questStatus.archived.label',
};

interface Props {
    questStatus: QuestStatus;
}

export const QuestStatusChip: FC<Props> = ({ questStatus }) => {
    const classes = useStyles(questStatus);
    const { t } = useTranslation();

    return (
        <Chip
            label={t(STATUS_TRANSLATION_KEYS[questStatus])}
            size="small"
            variant="outlined"
            sx={classes.questStatus}
        />
    );
};
