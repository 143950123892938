import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        actions: css({
            minWidth: theme.spacing(16),
            paddingLeft: theme.spacing(1),
        }),
        avatar: {
            marginRight: theme.spacing(2),
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
        card: css({
            height: '100%',
            paddingBottom: 0,
        }),
        content: {
            display: 'flex',
            paddingBottom: `${theme.spacing(2)} !important`,
            justifyContent: 'space-between',
        },
        flex: css({
            display: 'flex',
        }),
    };
};
