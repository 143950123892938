import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { Competency } from 'types';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import { Image } from 'components';
import { TooltipColor } from 'types';
import { InformationTooltip } from '../../InformationTooltip';
import { useStyles } from './styles';

interface Props {
    className?: string;
    competency: Competency;
    onSelectCompetencyClick: (...args: any[]) => void;
    onSelectCompetencyKeyUp: (...args: any[]) => void;
    raised?: boolean;
    tabIndex?: number;
}

export const FeedbackCompetencyCard: FC<Props> = ({
    className,
    competency,
    onSelectCompetencyClick = () => {},
    onSelectCompetencyKeyUp = () => {},
    raised = false,
    tabIndex,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Card
            tabIndex={tabIndex != null ? tabIndex : undefined}
            className={className}
            sx={classes.card}
            onClick={onSelectCompetencyClick}
            onKeyUp={onSelectCompetencyKeyUp}
            elevation={raised ? 2 : 0}
        >
            <CardContent sx={classes.content}>
                <div className={classes.competencyImage}>
                    <Image src={competency.photoPath} alt={competency.descriptionShort} />
                </div>
                <Typography variant="h6">{competency.descriptionShort}</Typography>
                <Typography>{competency.descriptionLong}</Typography>
            </CardContent>
            {competency.favorite && (
                <CardActions>
                    <InformationTooltip
                        text={t<string>('feedback.feedbackCompetencyCard.information.text')}
                        tooltip={t<string>('feedback.feedbackCompetencyCard.information.tooltip')}
                        color={TooltipColor.ORANGE}
                    />
                </CardActions>
            )}
        </Card>
    );
};
