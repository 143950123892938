export enum PROFILE_TYPE {
    PLAYER = 'PLAYER',
    JOB = 'JOB',
    JOB_FAMILY = 'JOB_FAMILY',
    TEAM = 'TEAM',
}

export enum PROFILE_DETAIL_TAB_TYPE {
    GENERAL = 0,
    // ACHIEVEMENTS = 1,
    QUESTS = 1,
    SKILLS = 2,
    ANALYTICS = 3,
}

export enum PROFILE_TAB_TYPE {
    PLAYER_PROFILE = 0,
    EVALUATION = 1,
}

export interface ProfilePrefillData {
    tab?: PROFILE_TAB_TYPE;
}
