import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Card, CardContent, Grid, LinearProgress, Typography } from '@mui/material';
import { ShowChart } from '@mui/icons-material';
import type { AppReducerState } from 'store';
import { useStyles } from './styles';

export const CardTotalScore: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const { expLevelMaxPoints, points, pointsUntilLevelUp } = useSelector(
        ({ user }: AppReducerState) => user.current,
    );

    const getPercentage = (val) =>
        pointsUntilLevelUp ? Math.ceil((val / pointsUntilLevelUp) * 100) : 0;

    const handleCardClick = () => navigate(`/profile`);

    return (
        <Card sx={classes.card} onClick={handleCardClick}>
            <CardContent>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography sx={classes.title}>{t('home.card.score.title')}</Typography>
                        <Typography sx={classes.levelText} variant="h6">
                            <span className={classes.currentLevel}>{points}</span>
                            <span className={classes.level}>
                                {t('home.card.score.scoreCount', {
                                    count: expLevelMaxPoints,
                                })}
                            </span>
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={getPercentage(points)}
                            sx={classes.progressBar}
                        />
                        <Typography variant="caption" sx={classes.expUntilLevel}>
                            {pointsUntilLevelUp}{' '}
                        </Typography>
                        <Typography variant="caption">{t('home.card.score.nextLevel')}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Avatar sx={classes.icon}>
                                    <ShowChart />
                                </Avatar>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
