import type { VFC } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import type { ProfileData, PROFILE_TAB_TYPE } from 'types';
import { Image, Loading } from 'components';
import type { AppReducerState } from 'store';
import defaultUser from 'assets/img/user_icon.png';
import { Button, Card, CardContent, CardHeader, Link, Typography } from '@mui/material';
import { PlayerStats } from '../PlayerStats';
import { ChangeProfilePictureModal } from '../ChangeProfilePictureModal';
import { ProfileDetailTabs } from '../ProfileDetailTabs';
import { ProfileDetailMenu } from '../ProfileDetailMenu';
import { ProfileDetailActions } from '../ProfileDetailActions';
import { useStyles } from './styles';

interface Props {
    className?: string;
    onProfileDataChanged: () => void;
    profileData: ProfileData;
    setSelectedTab: (tab: PROFILE_TAB_TYPE) => void;
    userId: string;
}

export const ProfileDetail: VFC<Props> = ({
    onProfileDataChanged,
    profileData,
    setSelectedTab,
    userId,
    className,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { empSysId, current } = useSelector(({ user }: AppReducerState) => user);
    const [isEditProfilePhotoModalOpen, setIsEditProfilePhotoModalOpen] = useState(false);
    const enableEdit = empSysId === userId;
    const classes = useStyles(enableEdit);

    const profileImage = enableEdit ? current.photoPath : profileData.profilePhoto;

    const changeProfilePhotoClick = () => setIsEditProfilePhotoModalOpen(true);

    const handleOnBackpackClick = () => {
        if (enableEdit) {
            navigate('store');
        }
    };

    const handleJobClick = (evt) => {
        evt.preventDefault();
        navigate(`/jobProfile/${profileData.job?.id}`);
    };

    const handleOnProfilePhotoModalClose = () => setIsEditProfilePhotoModalOpen(false);

    const handleProfileDataChanged = () => {
        onProfileDataChanged();
    };

    if (!profileData.employeeSysId) {
        return <Loading />;
    }

    return (
        <div className={cx(className, classes.container)}>
            <div className={classes.imageContainer}>
                <div className={classes.imageWrapper}>
                    {profileImage && <Image src={profileImage} className={classes.profileImage} />}
                    {!profileImage && (
                        <img className={classes.profileImage} alt="Profile" src={defaultUser} />
                    )}
                    {enableEdit && (
                        <div className={classes.imageUpdateButtonContainer}>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={changeProfilePhotoClick}
                            >
                                {t('profile.actions.updateAvatar.update')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <Card sx={classes.profileCard}>
                <CardHeader action={<ProfileDetailMenu hide={!enableEdit} />} />
                <CardContent sx={classes.cardContent}>
                    <Typography variant="h2" sx={classes.contentName}>
                        {profileData.firstName} {profileData.lastName}
                    </Typography>
                    {profileData.job && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link href="#" onClick={handleJobClick} sx={classes.marginBottom}>
                            {profileData.job.description}
                        </Link>
                    )}
                    <Typography sx={classes.marginBottom}>{profileData.email}</Typography>
                    <PlayerStats
                        player={{
                            coins: profileData.coins,
                            id: profileData.employeeSysId,
                            displayName: profileData.firstName,
                            profilePhoto: profileData.profilePhoto,
                            experienceLevel: Number(profileData.experienceLevelName),
                            points: profileData.points,
                            initials: profileData.initials,
                        }}
                        onBackpackClick={handleOnBackpackClick}
                        className={classes.playerStats}
                    />
                    <ProfileDetailActions
                        profileData={profileData}
                        setSelectedTab={setSelectedTab}
                    />
                </CardContent>
            </Card>
            <ProfileDetailTabs enableEdit={enableEdit} profileData={profileData} />
            <ChangeProfilePictureModal
                open={isEditProfilePhotoModalOpen}
                onClose={handleOnProfilePhotoModalClose}
                profileData={profileData}
                onProfileDataChanged={handleProfileDataChanged}
            />
        </div>
    );
};
