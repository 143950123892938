import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = () => {
    const theme = useTheme();

    return {
        svg: css({
            width: '100%',
            height: '100%',
        }),
        line: css({
            strokeWidth: theme.spacing(0.5),
            strokeLinecap: 'round',
            fill: 'none',
        }),
    };
};
