import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        question: css({
            marginBottom: theme.spacing(2),
        }),
        questionGroupTitle: {
            marginBottom: theme.spacing(2),
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        title: {
            marginBottom: theme.spacing(2),
        },
    };
};
