import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme, hexToRgba } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const grey = hexToRgba(colors.grey, 0.4);

    const theme = useTheme();

    return {
        root: css({
            overflow: 'visible',
            marginTop: '5rem',
            marginBottom: '5rem',
        }),
        rulerCircle: css({
            strokeWidth: 1,
            stroke: grey,
            fill: 'none',
        }),
        rulerCircleText: css({
            // ...theme.typography.body1,
            textAnchor: 'start',
            fill: grey,
        }),
        rulerLine: css({
            stroke: grey,
            strokeWidth: 1,
        }),
        chartWrapper: css({
            width: '100%',
            textAlign: 'center',
        }),
    };
};
