import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        avatar: {
            backgroundColor: colors.teal,
        },
        content: {
            display: 'flex',
            paddingBottom: `${theme.spacing(2)} !important`,
        },
        detail: css({
            flex: '1 0 auto',
        }),
        iconWrapper: css({
            marginRight: theme.spacing(2),
        }),
    };
};
