import type { VFC } from 'react';
import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { ACTIVITY_STREAM_ITEM_TYPE, FEEDBACK_STEP_TYPE, FEEDBACK_TARGET_TYPE } from 'types';
import type { JobProfileHeader, PROFILE_TYPE } from 'types';

import { useStyles } from './styles';
import { ActivityStream } from '../../activityStream/ActivityStream';

interface Props {
    job: JobProfileHeader;
    title: string;
    type: PROFILE_TYPE;
}

export const JobProfileStream: VFC<Props> = ({ job, title, type }) => {
    const classes = useStyles();

    const filter = useMemo(
        () => ({
            eventTypes: [ACTIVITY_STREAM_ITEM_TYPE.FEEDBACK],
            query: '',
            assignedTo: [job.id],
        }),
        [job.id],
    );

    const assignedToWithType = useMemo(
        () => ({
            id: job.id,
            type,
        }),
        [job.id, type],
    );

    return (
        <>
            <Typography variant="h2" component="h4" sx={classes.title}>
                {title}
            </Typography>
            <ActivityStream
                preview
                showGiveFeedback
                filter={filter}
                assignedToWithType={assignedToWithType}
                giveFeedbackData={{
                    step: FEEDBACK_STEP_TYPE.COMPETENCY,
                    profileData: {
                        id: job.id,
                        displayName: job.title,
                        type: FEEDBACK_TARGET_TYPE.JOB,
                        initials: job.initials,
                        profilePhoto: job.profilePhoto,
                    },
                }}
            />
        </>
    );
};
