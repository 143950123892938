import { frankyTalesTheme } from 'theme';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    return {
        iconWrapper: {
            textAlign: 'center',
        },
        icon: css({
            color: colors.skyBlue,
        }),
    };
};
