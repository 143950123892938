export const useStyles = (showCursor): any => {
    const rowStyle = showCursor ? { cursor: 'pointer' } : {};

    return {
        collapsibleCell: {
            '&.MuiTableCell-root.MuiTableCell-body': {
                padding: `0px !important`,
                width: '100%',
            },
        },
        row: rowStyle,
    };
};
