import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        actionsMarginRight: {
            marginRight: theme.spacing(2),
        },
    };
};
