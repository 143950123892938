export const sk = {
    translation: {
        profile: {
            actions: {
                giveFeedback: 'Dať spätnú väzbu',
                updateAvatar: {
                    update: 'Aktualizovať',
                    saveChanges: 'Uložiť zmeny',
                    cancel: 'Zrušiť',
                    imagePlaceholderLabel: 'Vyberte súbor',
                },
            },
            settings: {
                changeLanguage: 'Zmeniť jazyk',
                revokeConsent: 'Zrušiť súhlas s GDPR',
                changePassword: 'Zmeniť heslo',
                language: {
                    english: { label: 'Anglicky' },
                    german: { label: 'Nemecky' },
                    slovak: { label: 'Slovensky' },
                    czech: { label: 'Česky' },
                    croatian: { label: 'Chorvátsky' },
                    slovenian: { label: 'Slovinsky' },
                },
            },
            info: {
                level: 'LEVEL',
                experience: 'SKÚSENOSTI',
                inventory: 'INVENTÁR',
            },
        },
    },
};
