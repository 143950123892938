import type { FC } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import type { TypographyVariant } from '@mui/material';
import { useStyles } from './styles';

interface Props {
    variant?: TypographyVariant;
}

export const Greeting: FC<Props> = ({ variant = 'h1' }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const hour = moment().hour();
    let greetingKey = '';

    if (hour >= 5 && hour < 12) {
        greetingKey = 'greeting.morning';
    }

    if (hour >= 12 && hour < 17) {
        greetingKey = 'greeting.afternoon';
    }

    if (hour >= 17 || hour < 5) {
        greetingKey = 'greeting.evening';
    }

    return (
        <Typography variant={variant} sx={classes.greeting}>
            {t(greetingKey)}
        </Typography>
    );
};
