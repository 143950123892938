import { createContext, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';

interface SignUpContextType {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    companySize: string;
    password: string;
    passwordConfirmation: string;
    setFirstName: (value: string) => void;
    setLastName: (value: string) => void;
    setEmail: (value: string) => void;
    setRole: (value: string) => void;
    setCompanySize: (value: string) => void;
    setPassword: (value: string) => void;
    setPasswordConfirmation: (value: string) => void;
    setTeamSize: (value: string) => void;
    teamSize: string;
}

export const SignUpContext = createContext<SignUpContextType>({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    companySize: '',
    password: '',
    passwordConfirmation: '',
    setFirstName: () => {},
    setLastName: () => {},
    setEmail: () => {},
    setRole: () => {},
    setCompanySize: () => {},
    setPassword: () => {},
    setPasswordConfirmation: () => {},
    setTeamSize: () => {},
    teamSize: '',
});

interface Props {
    children: ReactNode;
}

export const SignUpProvider: FC<Props> = ({ children }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [teamSize, setTeamSize] = useState('');

    const contextValue = useMemo(
        () => ({
            firstName,
            lastName,
            email,
            role,
            companySize,
            password,
            passwordConfirmation,
            setFirstName,
            setLastName,
            setEmail,
            setRole,
            setCompanySize,
            setPassword,
            setPasswordConfirmation,
            setTeamSize,
            teamSize,
        }),
        [firstName, lastName, email, role, companySize, password, passwordConfirmation, teamSize],
    );

    return <SignUpContext.Provider value={contextValue}>{children}</SignUpContext.Provider>;
};
