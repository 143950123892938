import { useTheme } from '@mui/material';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        avatarCell: {
            width: theme.spacing(5),
        },
        employeeCell: {
            paddingLeft: 0,

            '& .MuiTypography-body2': {
                '& span': {
                    color: colors.grey,
                },
            },
        },
        row: {
            cursor: 'pointer',
        },
    };
};
