import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Button, Fade, Modal } from '@mui/material';
import ReactCrop from 'react-image-crop';
import type { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useStyles } from './styles';

export const ImageUpload = ({ image, setImage, disabled, aspectRatio }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [uploadedImage, setUploadedImage] = useState<string | null>();
    const [croppedImage, setCroppedImage] = useState<string | null>();
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<Crop>();
    const refInputFile = useRef<HTMLInputElement>(null);

    const handleUploadFile = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = function (upload) {
            // setImage(upload.target.result);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setUploadedImage(upload.target.result);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setCroppedImage(upload.target.result);
            setOpen(true);
        };

        reader.readAsDataURL(file);
        e.target.value = '';
    };

    useEffect(() => {
        if (!completedCrop || !uploadedImage || !crop) {
            return;
        }

        const img = new Image();
        img.src = uploadedImage;
        const canvas = document.createElement('canvas');
        const cropScaleX = crop.width / 100;
        const cropScaleY = crop.height / 100;
        canvas.width = img.width * cropScaleX;
        canvas.height = img.height * cropScaleY;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return;
        }

        // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            img,
            img.width * (crop.x / 100),
            img.height * (crop.y / 100),
            img.width * cropScaleX,
            img.height * cropScaleY,
            0,
            0,
            img.width * cropScaleX,
            img.height * cropScaleY,
        );

        const base64Image = canvas.toDataURL('image/jpeg');
        setCroppedImage(base64Image);
    }, [completedCrop]);

    const handleCancel = () => {
        setUploadedImage(null);
        setOpen(false);
    };

    const handleSubmit = () => {
        setImage(croppedImage);
        setOpen(false);
    };

    const onCropChange = (cropChange) => {
        setCrop(cropChange);
    };

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img
                src={image || '/images/no-image.png'}
                className={`item-img img-fluid ${!disabled && 'clickable'}`}
                onClick={() => !disabled && refInputFile?.current?.click()}
                alt="img"
            />
            <input
                accept=".jpg, .jpeg, .png"
                ref={refInputFile}
                onChange={handleUploadFile}
                type="file"
                style={{ display: 'none' }}
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="container">
                            {uploadedImage && (
                                <ReactCrop
                                    aspect={aspectRatio}
                                    crop={crop}
                                    circularCrop
                                    onChange={onCropChange}
                                    onComplete={setCompletedCrop}
                                >
                                    <img src={uploadedImage} alt={t('') as string} />
                                </ReactCrop>
                            )}
                        </div>
                        <p>
                            <Button type="submit" onClick={handleSubmit}>
                                Submit
                            </Button>
                            <Button type="button" onClick={handleCancel}>
                                {t('Cancel')}
                            </Button>
                        </p>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};
