import type { NotificationDataLineValue } from 'types';
import { NOTIFICATION_DATA_LINE_TYPE } from 'types';

import { EventRowMessageItemCoins } from '../EventRowMessageItemCoins';
import { EventRowMessageItemXP } from '../EventRowMessageItemXP';
import { EventRowMessageItemEmployee } from '../EventRowMessageItemEmployee';
import { EventRowMessageItemBold } from '../EventRowMessageItemBold';

const EventRowMessageItemTypes = {
    [NOTIFICATION_DATA_LINE_TYPE.COINS]: EventRowMessageItemCoins,
    [NOTIFICATION_DATA_LINE_TYPE.POINTS]: EventRowMessageItemXP,
    [NOTIFICATION_DATA_LINE_TYPE.EMPLOYEE]: EventRowMessageItemEmployee,
    [NOTIFICATION_DATA_LINE_TYPE.JOB]: EventRowMessageItemEmployee,
    [NOTIFICATION_DATA_LINE_TYPE.JOB_FAMILY]: EventRowMessageItemEmployee,
    [NOTIFICATION_DATA_LINE_TYPE.TEAM]: EventRowMessageItemEmployee,
    [NOTIFICATION_DATA_LINE_TYPE.COMPETENCY]: EventRowMessageItemBold,
    [NOTIFICATION_DATA_LINE_TYPE.QUEST]: EventRowMessageItemEmployee,
    [NOTIFICATION_DATA_LINE_TYPE.TEXT]: EventRowMessageItemBold,
};

interface Props {
    item?: NotificationDataLineValue;
}

export const EventRowMessageItem = ({ item }: Props) => {
    if (!item) {
        return null;
    }

    const Component = EventRowMessageItemTypes[item?.type];

    if (!Component) {
        return null;
    }

    return <Component item={item} />;
};
