import { Controller } from 'react-hook-form';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import type { IAutocompleteView } from 'types';

interface FtAutcompleteInterface {
    label: string;
    name: string;
    control: any;
    itemList: IAutocompleteView[];
    errors: any;
    open: boolean;
    loading: boolean;
    setOpen: any;
    setSelected: any;
    handleInputChange: any;
    defaultValue?: { id: string; displayName: string };
    required?: boolean;
    disabled?: boolean;
    multiple?: boolean;
}

const FtAutocomplete = ({
    label,
    name,
    control,
    itemList,
    errors,
    open,
    loading,
    setOpen,
    setSelected,
    handleInputChange,
    defaultValue,
    required,
    disabled,
    multiple,
}: FtAutcompleteInterface) => (
    <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required: required || false }}
        render={({ field }) => (
            <Autocomplete
                {...field}
                open={open}
                onChange={(e, value) => setSelected(name, value)}
                onClose={() => {
                    setOpen(false);
                }}
                multiple={multiple || false}
                disabled={disabled || false}
                options={itemList}
                loading={loading}
                getOptionLabel={(option: IAutocompleteView) => option.displayName}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={errors[name]}
                        label={`${label} ${(required && '*') || ''}`}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        )}
    />
);

export default FtAutocomplete;
