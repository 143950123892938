import type { VFC } from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { ProfileData } from 'types';
import {
    FEEDBACK_STEP_TYPE,
    FEEDBACK_TARGET_TYPE,
    KeyResultAssignedTo,
    PROFILE_TAB_TYPE,
    ProfileFeature,
} from 'types';
import { useBoundActions } from 'hooks';
import { setFeedbackPreFillData } from 'store/slices/feedback';
import { setQuestPreFillData } from 'store/slices/quest';
import { Button, ButtonGroup, Fade, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { useStyles } from './styles';

const PROFILE_FEATURE_TRANSLATIONS = {
    [ProfileFeature.GIVE_FEEDBACK]: 'profile.actions.giveFeedback.label',
    [ProfileFeature.EVALUATE]: 'profile.actions.evaluate.label',
    [ProfileFeature.SELF_EVALUATE]: 'profile.actions.selfEvaluate.label',
    [ProfileFeature.CREATE_QUEST]: 'profile.actions.createQuest.label',
};

const NUM_FEATURES_TO_RENDER_IN_DROPDOWN = 4;

interface Props {
    profileData: ProfileData;
    setSelectedTab: (tab: PROFILE_TAB_TYPE) => void;
}

export const ProfileDetailActions: VFC<Props> = ({ profileData, setSelectedTab }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setFeedbackPreFillDataAction, setQuestPreFillDataAction } = useBoundActions({
        setFeedbackPreFillData,
        setQuestPreFillData,
    });
    const classes = useStyles();
    const filteredFeatures = profileData.features.filter(
        (feature) => feature !== ProfileFeature.EVALUATION_TAB,
    );
    const lastFeature = filteredFeatures[filteredFeatures.length - 1];

    const handleMenuClick = (evt) => evt.stopPropagation();

    const handleMenuButtonClick = (evt) => {
        evt.stopPropagation();
        setMenuOpen(true);
    };

    const handleMenuClose = () => setMenuOpen(false);

    const handleProfileFeatureClick = (feature) => () => {
        if (feature === ProfileFeature.GIVE_FEEDBACK) {
            setFeedbackPreFillDataAction({
                step: FEEDBACK_STEP_TYPE.COMPETENCY,
                profileData: {
                    id: profileData.employeeSysId,
                    displayName: `${profileData.firstName} ${profileData.lastName}`,
                    type: FEEDBACK_TARGET_TYPE.EMPLOYEE,
                    initials: profileData.initials,
                    profilePhoto: profileData.profilePhoto,
                },
            });
            navigate('/feedback/new');
        }

        if (feature === ProfileFeature.CREATE_QUEST) {
            setQuestPreFillDataAction({
                assignedTo: [
                    {
                        group: t('quest.addPlayerStep.form.assignTo.groups.employees.label'),
                        type: KeyResultAssignedTo.EMPLOYEE,
                        name: `${profileData.firstName} ${profileData.lastName}`,
                        id: profileData.employeeSysId,
                        profilePhoto: profileData.profilePhoto,
                    },
                ],
            });
            navigate('/quest/new');
        }

        if (feature === ProfileFeature.SELF_EVALUATE) {
            if (!profileData.perfCycleEmployeeEvaluationId) {
                setSelectedTab(PROFILE_TAB_TYPE.EVALUATION);

                return;
            }

            navigate(
                `/player/assessment/self/${profileData.employeeSysId}/${profileData.perfCycleEmployeeEvaluationId}`,
            );
        }

        if (feature === ProfileFeature.EVALUATE) {
            if (!profileData.perfCycleEmployeeEvaluationId) {
                setSelectedTab(PROFILE_TAB_TYPE.EVALUATION);

                return;
            }

            navigate(
                `/player/assessment/${profileData.employeeSysId}/${profileData.perfCycleEmployeeEvaluationId}`,
            );
        }
    };

    if (filteredFeatures.length < NUM_FEATURES_TO_RENDER_IN_DROPDOWN) {
        return (
            <div className={classes.profileActions}>
                {filteredFeatures.map((feature, index) => (
                    <Button
                        key={feature}
                        variant={index === filteredFeatures.length - 1 ? 'contained' : 'outlined'}
                        onClick={handleProfileFeatureClick(feature)}
                    >
                        {t(PROFILE_FEATURE_TRANSLATIONS[feature])}
                    </Button>
                ))}
            </div>
        );
    }

    return (
        <div>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label={t('profile.actionButton.aria')}
            >
                <Button onClick={handleProfileFeatureClick(lastFeature)}>
                    {t(PROFILE_FEATURE_TRANSLATIONS[lastFeature])}
                </Button>
                <Button
                    size="small"
                    aria-controls={menuOpen ? 'action-button-menu' : undefined}
                    aria-expanded={menuOpen ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleMenuButtonClick}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Menu
                id="action-button-menu"
                anchorEl={anchorRef.current}
                keepMounted
                elevation={0}
                open={menuOpen}
                onClick={handleMenuClick}
                onClose={handleMenuClose}
                TransitionComponent={Fade}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {filteredFeatures
                    .filter((feature) => feature !== lastFeature)
                    .map((feature) => (
                        <MenuItem onClick={handleProfileFeatureClick(feature)} key={feature}>
                            {t(PROFILE_FEATURE_TRANSLATIONS[feature])}
                        </MenuItem>
                    ))}
            </Menu>
        </div>
    );
};
