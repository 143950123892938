import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        container: css({
            marginBottom: theme.spacing(3),
        }),
        textInput: {
            flex: 1,
        },
    };
};
