/* eslint-disable no-unused-expressions */
import { useState, useRef } from 'react';
import type { ServiceCallResponse } from 'types';
import { callService as serviceFetch } from 'services';

const ABORT_ERROR = 'AbortError';

export interface CancellableServiceCallParams {
    api: string;
    body?: Record<string, any>;
    headers?: Record<string, any>;
    params?: any[] | Record<string, any>;
    query?: any[] | Record<string, any>;
    useToken?: boolean;
    onSuccess?: (...args: any[]) => void;
    onError?: (...args: any[]) => void;
}

export interface CancellableResponse {
    callService: () => Promise<ServiceCallResponse<any>>;
    cancelServiceCall: () => void;
    isLoading: boolean;
}

export const useCancellableServiceCall = ({
    api,
    params,
    onSuccess,
    onError,
    body,
    query,
    headers,
    useToken,
}: CancellableServiceCallParams): CancellableResponse => {
    const [isLoading, setIsLoading] = useState(false);
    const controller = useRef<AbortController>();

    const callService = async () => {
        controller.current = new AbortController();
        setIsLoading(true);

        try {
            const { error: serviceError, payload } = await serviceFetch({
                api,
                params,
                body,
                query,
                headers,
                useToken,
                signal: controller.current.signal,
            });

            setIsLoading(false);

            if (!serviceError) {
                onSuccess?.({ payload });
            }

            if (serviceError) {
                onError?.({ error: serviceError, payload });
            }

            return { error: serviceError, payload };
        } catch (e: any) {
            if (e.name !== ABORT_ERROR) {
                setIsLoading(false);
                onError?.({ error: e, payload: {} });
            }

            return { error: undefined, payload: {} };
        }
    };

    const cancelServiceCall = () => controller.current?.abort();

    return {
        callService,
        cancelServiceCall,
        isLoading,
    };
};
