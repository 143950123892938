import { frankyTalesTheme } from './frankyTalesTheme';

const { colors } = frankyTalesTheme;

const root = {
    '& .MuiSvgIcon-root': {
        fill: colors.blue,
    },

    '&.greyInputAdornment .MuiSvgIcon-root': {
        fill: colors.grey,
    },
};

export const MuiInputAdornment = {
    styleOverrides: {
        root,
    },
};
