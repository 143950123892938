import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        logo: css({
            width: '100%',
            textAlign: 'center',
            marginBottom: theme.spacing(1),
        }),
        languagePicker: {
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
            padding: `${theme.spacing(1)} !important`,
            minWidth: theme.spacing(5),
            boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.3)',
            color: colors.darkBlue,
            backgroundColor: colors.white,
            textTransform: 'uppercase',

            '&:hover': {
                backgroundColor: colors.greyLighter2,
                boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.5)',
                color: colors.darkBlue,
            },
        },
    };
};
