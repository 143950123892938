import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import { InputLabel } from '@mui/material';

import { FtExperienceLevelsList } from './FtExperienceLevelsList';
import { useStyles } from './styles';

interface Props {
    title: string;
    name: string;
    control: any;
    disabled?: boolean;
    errors: any;
    required?: boolean;
    className?: string;
}

export const FtExperienceLevels: FC<Props> = ({
    className,
    title,
    name,
    control,
    disabled = false,
    errors,
    required = false,
}) => {
    const classes = useStyles();

    return (
        <div className={className}>
            <InputLabel
                id={`${name}-label`}
                error={errors[name]}
                sx={classes.marginBottom}
            >{`${title} ${required && '*'}`}</InputLabel>
            <Controller
                control={control}
                name={name}
                rules={{ required }}
                render={({ field: { value, onChange } }) => (
                    <FtExperienceLevelsList
                        levels={value}
                        onChange={onChange}
                        disabled={disabled}
                    />
                )}
            />
        </div>
    );
};
