import { useCallback, useState, useEffect } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, Modal, Typography } from '@mui/material';
import { api, callService } from 'services';
import type { PerformanceCycle } from 'types';
import type { AppReducerState } from 'store';
import { EvaluationsFilter } from '../EvaluationsFilter';
import { EvaluationCard } from '../EvaluationCard';
import { PerformanceEvaluation } from '../PerformanceEvaluation';
import { useStyles } from './styles';

interface Props {
    employeeId: string;
}

export const EvaluationsList: FC<Props> = ({ employeeId }) => {
    const [filter, setFilter] = useState({ year: '' });
    const [evaluationDetailId, setEvaluationDetailId] = useState('');
    const [performanceCycles, setPerformanceCycles] = useState<PerformanceCycle[]>();
    const { employeeEvaluationPeriods } = useSelector(
        ({ configuration }: AppReducerState) => configuration,
    );
    const classes = useStyles();

    const years = employeeEvaluationPeriods.reduce((acc, period) => {
        const dateToYear = moment(period.dateTo).year();
        const dateFromYear = moment(period.dateFrom).year();
        const arr = acc;

        if (!acc.includes(dateToYear)) {
            arr.push(dateToYear);
        }

        if (!acc.includes(dateFromYear)) {
            arr.push(dateFromYear);
        }

        return arr;
    }, [] as number[]);

    const getPerformanceCycles = async () => {
        const { error, payload } = await callService({
            api: api.employeeController.getPerformanceCycles,
            params: [employeeId],
            query: filter,
        });

        if (!error && payload) {
            setPerformanceCycles(payload);
        }
    };

    const handleOnEvaluationClick = (evaluationId) => setEvaluationDetailId(evaluationId);

    const handleEvaluationDetailClose = () => setEvaluationDetailId('');

    const handleFilterChange = useCallback((newFilter) => setFilter(newFilter), [setFilter]);

    useEffect(() => {
        getPerformanceCycles();
    }, [filter]);

    if (!performanceCycles) {
        return null;
    }

    return (
        <div className={classes.wrapper}>
            <EvaluationsFilter onFilterChange={handleFilterChange} years={years} filter={filter} />
            <Grid container spacing={3}>
                {performanceCycles.map((performanceCycle) => (
                    <Grid item key={performanceCycle.performanceCycleId} xs={12}>
                        <Typography variant="h2" component="h3" sx={classes.year}>
                            {performanceCycle.year}
                        </Typography>
                        <Grid container spacing={2}>
                            {performanceCycle.employeeEvaluations.map((evaluation) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={evaluation.performanceCycleEmployeeEvaluationId}
                                >
                                    <EvaluationCard
                                        employeeId={employeeId}
                                        evaluation={evaluation}
                                        onEvaluationClick={handleOnEvaluationClick}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Modal open={!!evaluationDetailId} sx={classes.modal}>
                <PerformanceEvaluation
                    employeeId={employeeId}
                    evaluationId={evaluationDetailId}
                    closeHandle={handleEvaluationDetailClose}
                />
            </Modal>
        </div>
    );
};
