import { useEffect, useState } from 'react';
import type { Context } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import type { Moment } from 'moment';
import { CreateQuestStep, QuestStatus, QuestType } from 'types';
import type { QuestAssignee, KeyResult } from 'types';
import { CreateQuestContext } from 'contexts';
import type { CreateQuestContextType } from 'contexts';
import type { AppReducerState } from 'store';
import { api, callService } from 'services';
import { useBoundActions } from 'hooks';
import { setQuestPreFillData } from 'store/slices/quest';

const DATE_FORMAT = 'YYYY-MM-DD';

const QUEST_TYPE_STEPS = {
    [QuestType.TASK]: CreateQuestStep.OBJECTIVE_STEP,
    [QuestType.GROWTH]: CreateQuestStep.COMPETENCY_STEP,
    [QuestType.SKILL]: CreateQuestStep.SKILL_STEP,
};

const getDefaultKeyResults = (minNumber) =>
    Array(minNumber || 0)
        .fill('')
        .map((item, index) => {
            return {
                order: index,
                descriptionShort: '',
                descriptionLong: '',
            };
        });

export const useProvideCreateQuestContext = (
    objectiveId,
): [Context<CreateQuestContextType>, CreateQuestContextType] => {
    const {
        questPriorityDefaultValue,
        confidentialQuests,
        questTypesEnabled,
        keyResults: keyResultsConfig,
        questPreFillData = {},
    } = useSelector(({ configuration, quest }: AppReducerState) => ({
        ...configuration,
        ...quest,
    }));
    const [step, setStep] = useState(CreateQuestStep.TYPE_STEP);
    const [type, setType] = useState<QuestType>();
    const [loading, setLoading] = useState(!!objectiveId);
    const [status, setStatus] = useState<QuestStatus>();
    const [previousQuestType, setPreviousQuestType] = useState<QuestType>();
    const [descriptionShort, setDescriptionShort] = useState<string>();
    const [assignedTo, setAssignedTo] = useState<QuestAssignee[]>([]);
    const [confidential, setConfidential] = useState(confidentialQuests.questDefaultConfidential);
    const [descriptionLong, setDescriptionLong] = useState<string>();
    const [dateTo, setDateTo] = useState<Moment | null>();
    const [dateFrom, setDateFrom] = useState<Moment | null>();
    const [keyResults, setKeyResults] = useState<KeyResult[]>(
        getDefaultKeyResults(keyResultsConfig.minNumber),
    );
    const [priorityId, setPriorityId] = useState(questPriorityDefaultValue);
    const [parentQuestId, setParentQuestId] = useState<string>();
    const [relatedCompetency, setRelatedCompetency] = useState<string>();
    const [relatedSkills, setRelatedSkills] = useState<string[]>();
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarError, setSnackBarError] = useState('');
    const { t } = useTranslation();
    const { setQuestPreFillDataAction } = useBoundActions({ setQuestPreFillData });

    useEffect(() => {
        if (type !== undefined && !objectiveId) {
            if (type === QuestType.SKILL && !relatedSkills) {
                setRelatedSkills([]);
            }

            if (previousQuestType !== type) {
                const questType = questTypesEnabled.find(
                    (enabledQuestTypes) => enabledQuestTypes.type === type,
                );
                const { suggestedDateFrom, suggestedDateTo } = questType || {};

                setDateFrom(suggestedDateFrom ? moment(suggestedDateFrom) : null);
                setDateTo(suggestedDateTo ? moment(suggestedDateTo) : null);
            }

            setStep(QUEST_TYPE_STEPS[type]);
            setPreviousQuestType(type);
        }
    }, [type]);

    useEffect(() => {
        if (step === CreateQuestStep.TYPE_STEP) {
            setType(undefined);
        }
    }, [step]);

    useEffect(() => {
        if (relatedCompetency !== undefined) {
            setStep(CreateQuestStep.OBJECTIVE_STEP);
        }
    }, [relatedCompetency]);

    const publishQuest = async (questStatus = QuestStatus.ACTIVE) => {
        const { error } = await callService({
            api: objectiveId
                ? api.objectiveController.updateObjective
                : api.objectiveController.createObjective,
            body: {
                objectiveId,
                type,
                status: questStatus,
                descriptionShort,
                descriptionLong,
                priorityId,
                dateFrom: dateFrom?.format(DATE_FORMAT),
                dateTo: dateTo?.format(DATE_FORMAT),
                confidential,
                parentQuestId,
                relatedCompetency,
                relatedSkills,
                assignedTo: assignedTo.map((assignee) => ({
                    id: assignee.id,
                    type: assignee.type,
                })),
                keyResults: keyResults.map((keyResult) => ({
                    ...keyResult,
                    descriptionLong: keyResult.descriptionShort,
                })),
            },
        });

        if (error) {
            setSnackBarError(t<string>('quest.new.snackbar.draftError.label'));
            setSnackBarOpen(true);
        } else {
            setSnackBarError('');

            if (questStatus === QuestStatus.DRAFT) {
                setSnackBarOpen(true);
            } else {
                setStep(CreateQuestStep.SUCCESS_STEP);
            }
        }
    };

    const saveAsDraft = () => publishQuest(QuestStatus.DRAFT);

    const getDefaultData = () => ({
        type: undefined,
        descriptionShort: undefined,
        assignedTo: [],
        confidential: confidentialQuests.questDefaultConfidential,
        descriptionLong: undefined,
        relatedCompetency: undefined,
        relatedSkills: undefined,
        keyResults: getDefaultKeyResults(keyResultsConfig.minNumber),
        priorityId: questPriorityDefaultValue,
        step: CreateQuestStep.TYPE_STEP,
    });

    const preFillData = (data) => {
        const {
            type: questTypeValue,
            descriptionShort: objectiveValue,
            assignedTo: assigneesValue,
            confidential: confidentialValue,
            descriptionLong: descriptionValue,
            dateFrom: startDateValue,
            dateTo: endDateValue,
            relatedCompetency: competencyValue,
            relatedSkills: skillsValue,
            keyResults: keyResultsValue,
            priorityId: priorityValue,
            step: stepValue,
            status: statusValue,
        } = data;

        if (startDateValue) {
            setDateFrom(startDateValue);
        }

        if (endDateValue) {
            setDateTo(endDateValue);
        }

        setType(questTypeValue);
        setDescriptionShort(objectiveValue);
        setAssignedTo(assigneesValue);
        setConfidential(confidentialValue);
        setDescriptionLong(descriptionValue);
        setRelatedCompetency(competencyValue);
        setRelatedSkills(skillsValue);
        setKeyResults(keyResultsValue);
        setPriorityId(priorityValue);
        setStep(stepValue);
        setStatus(statusValue);
    };

    useEffect(() => {
        const { assignedTo: prefilledAssignedTo } = questPreFillData;

        if (prefilledAssignedTo) {
            preFillData({
                ...getDefaultData(),
                assignedTo: prefilledAssignedTo,
            });
        }

        if (objectiveId) {
            (async () => {
                const { error, payload } = await callService({
                    api: api.objectiveController.getObjective,
                    params: [objectiveId],
                });

                if (!error && payload) {
                    preFillData({
                        ...payload,
                        keyResults: payload.keyResults?.sort(
                            (first, second) => first.order - second.order,
                        ),
                        dateFrom: moment(payload.dateFrom),
                        dateTo: moment(payload.dateTo),
                        step: CreateQuestStep.OBJECTIVE_STEP,
                    });
                }

                setLoading(false);
            })();
        }

        return () => {
            setQuestPreFillDataAction(undefined);
        };
    }, []);

    const createNewQuest = () => preFillData(getDefaultData());

    return [
        CreateQuestContext,
        {
            assignedTo,
            relatedCompetency,
            confidential,
            descriptionLong,
            dateTo,
            keyResults,
            loading,
            descriptionShort,
            priorityId,
            parentQuestId,
            relatedSkills,
            dateFrom,
            objectiveId,
            step,
            status,
            type,
            createNewQuest,
            preFillData,
            publishQuest,
            saveAsDraft,
            setAssignedTo,
            setRelatedCompetency,
            setConfidential,
            setDescriptionLong,
            setDateTo,
            setKeyResults,
            setDescriptionShort,
            setPriorityId,
            setParentQuestId,
            setType,
            setRelatedSkills,
            setDateFrom,
            setStep,
            snackBarError,
            snackBarOpen,
            setSnackBarOpen,
            setSnackBarError,
        },
    ];
};
