import { api as adminApi } from '../constants';

/* eslint-disable max-lines */
const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_ADMIN_URL = process.env.REACT_APP_SERVICE_URI;
const API_RESOURCE_URL = process.env.REACT_APP_API_RESOURCE_URL;

export const api = {
    baseUrl: API_BASE_URL,
    baseResourceUrl: process.env.REACT_APP_API_RESOURCE_URL,
    controller: {
        activityStreamController: 'api/activity-streams',
        employeeController: 'api/employees',
        competencyController: 'api/competencies',
        employeeCompetenciesController: 'api/employee-competencies',
        feedbackAdviceController: 'api/feedback-advices',
        feedbackAvatarAdviceController: 'api/feedback-advices',
        feedbackAvatarController: 'api/feedback-avatars',
        feedbackController: 'api/feedbacks',
        notificationsController: 'api/notifications',
        objectiveController: 'api/objectives',
        orgUnitController: 'api/org-units',
        referenceDataController: 'api/reference-data',
        storeController: 'api/stores',
        userController: 'api/users',
        analyticsController: 'api/analytics',
        configurationController: 'api/configurations',
        skillController: 'api/skills',
        searchController: 'api/search',
        jobController: 'api/jobs',
        jobFamilyController: 'api/job-families',
        teamController: 'api/teams',
        keyResultsController: 'api/key-results',
        storagesController: 'api/storages',
        authenticationController: 'api/authentication',
        dashboardsController: 'api/dashboards',
        reportsController: 'api/reports',
        tilesController: 'tiles',
        policiesController: 'api/policies',
        adminPoliciesController: 'policies',
        importsController: 'api/imports',
    },
    employeeController: {
        getSuggestedEmployeeForFeedback: 'GetSuggestedEmployeeForFeedback',
        getEmployeeProfile: 'getEmployeeProfile',
        getEmployeeAssessmentEvaluationSurvey: 'getEmployeeAssessmentEvaluationSurvey',
        createEmployeeAssessmentEvaluationSurvey: 'createEmployeeAssessmentEvaluationSurvey',
        getPerformanceCycles: 'getPerformanceCycles',
        getEmployeePerformanceReview: 'getEmployeePerformanceReview',
        myTeam: 'myTeam',
        autoSuggest: 'autoSuggest',
        searchEmployees: 'searchEmployees',
        searchJobs: 'searchJobs',
        searchTeams: 'searchTeams',
        getFeatures: 'getEmployeeFeatures',
        getObjectiveSuggestedEmployees: 'getObjectiveSuggestedEmployees',
        getLeadership: 'getLeadership',
    },
    adminController: {
        getLanguages: 'getLanguages',
        getCompanySettings: 'getCompanySettings',
        updateCompanySettings: 'updateCompanySettings',
    },
    keyResultsController: {
        updateKeyResults: 'updateKeyResults',
    },
    analyticsController: {
        getJobAnalytics: 'getJobAnalytics',
        getJobFamilyAnalytics: 'getJobFamilyAnalytics',
        getPlayerAnalytics: 'getPlayerAnalytics',
        getTeamAnalytics: 'getTeamAnalytics',
        getAnalyticsHierarchies: 'getAnalyticsHierarchies',
        getAnalyticsJobs: 'getAnalyticsJobs',
        getAnalyticsCulture: 'getAnalyticsCulture',
        getAnalyticsQuests: 'getAnalyticsQuests',
    },
    jobController: {
        getJobProfile: 'getJobProfile',
        getFeatures: 'getJobFeatures',
    },
    jobFamilyController: {
        getJobFamilyProfile: 'getJobFamilyProfile',
    },
    teamController: {
        getTeamProfile: 'getTeamProfile',
        getFeatures: 'getTeamFeatures',
    },
    activityStreamController: {
        getActivityStream: 'getActivityStream',
        getActivityStreamAssignees: 'getActivityStreamAssignees',
    },
    storeController: {
        getStoreItems: 'GetStoreItems',
        getStoreTransactions: 'GetStoreTransactions',
    },
    userController: {
        changePassword: 'ChangePassword',
        resetPassword: 'resetPassword',
        requestPasswordReset: 'requestPasswordReset',
    },
    notificationsController: {
        getNotifications: 'getNotifications',
    },
    objectiveController: {
        getObjectives: 'getObjectives',
        getCompanyObjectives: 'getCompanyObjectives',
        getObjective: 'getObjective',
        createObjective: 'createObjective',
        updateObjective: 'updateObjective',
        assignObjective: 'assignObjective',
        getQuestEmployeeEvaluationSurvey: 'getQuestEmployeeEvaluationSurvey',
        createQuestEmployeeEvaluationSurvey: 'createQuestEmployeeEvaluationSurvey',
        objectiveAssignees: 'objectiveAssignees',
        getFeatures: 'getObjectiveFeatures',
        archiveQuest: 'archiveQuest',
        reopenQuest: 'reopenQuest',
    },
    reportsController: {
        createReport: 'createReport',
    },
    policiesController: {
        getPolicies: 'getPolicies',
        getPolicy: 'getPolicy',
        updatePolicy: 'updatePolicy',
    },
    skillsController: {
        getSkills: 'getSkills',
        getSkillsSuggestions: 'getSkillsSuggestions',
    },
    authentication: {
        logIn: 'LogIn',
        getToken: 'GetToken',
        refreshToken: 'RefreshToken',
        deleteToken: 'DeleteToken',
        generateCode: 'GenerateCode',
        changePassword: 'ChangePassword',
        generateAuthenticatorCode: 'GenerateAuthenticatorCode',
    },
    feedback: {
        share: 'share',
        unShare: 'unShare',
    },
    getUser: 'getUser',
    getEmployees: 'getEmployees',
    getAvatar: 'getAvatar',
    getAvatarPhoto: 'getAvatarPhoto',
    getEmployeeStats: 'getEmployeeStats',
    getLoggedInEmployeeStats: 'getLoggedInEmployeeStats',
    updateAvatarPhoto: 'updateAvatarPhoto',
    getCompetencyStatsByCompanyIdYear: 'getCompetencyStatsByCompanyIdYear',
    getFeedbackAvatarAdvice: 'getFeedbackAvatarAdvice',
    getCompetenciesByEmployeeId: 'getCompetenciesByEmployeeId',
    getFeedbackAdvice: 'getFeedbackAdvice',
    getFeedbackAdviceGif: 'getFeedbackAdviceGif',
    getLeaderBoards: 'getLeaderBoards',
    updateFeedbackHelpfull: 'updateFeedbackHelpfull',
    updateFeedbackAdvice: 'updateFeedbackAdvice',
    createNewFeedbackAdvice: 'createNewFeedbackAdvice',
    deleteFeedbackAdvice: 'deleteFeedbackAdvice',
    getFeedbackAdvices: 'getFeedbackAdvices',
    getLastFeedbacks: 'getLastFeedbacks',
    getNotifications: 'getNotifications',
    putNotificationStatus: 'putNotificationStatus',
    getStoreItems: 'getStoreItems',
    getStoreTransactions: 'getStoreTransactions',
    buyStoreItem: 'buyStoreItem',
    getEmployeeCompetency: 'employeeCompetency',
    createEmployeeCompetency: 'createEmployeeCompetency',
    deleteEmployeeCompetency: 'deleteEmployeeCompetency',
    getAnalyticsCompetencies: 'getAnalyticsCompetencies',
    getAnalyticsGraphData: 'getAnalyticsGraphData',
    getConfiguration: 'getConfiguration',
    storagesController: {
        getImage: 'getImage',
    },
    tilesController: {
        getTileTabs: 'getTileTabs',
    },
    importsController: {
        getImports: 'getImports',
        getImportDetail: 'getImportDetail',
    },
    revokedConsentsController: {
        getUsersConsents: 'getUsersConsents',
        deleteUser: 'deleteUser',
    },
};

export const calls = [
    {
        call: api.authentication.logIn,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.authenticationController}`,
    },
    {
        call: api.authentication.getToken,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.authenticationController}/validation/{param0}`,
    },
    {
        call: api.authentication.refreshToken,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.authenticationController}/refresh-token`,
    },
    {
        call: api.authentication.deleteToken,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.authenticationController}/logout`,
    },
    {
        call: api.authentication.generateCode,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.authenticationController}/generate-email-code`,
    },
    {
        call: api.authentication.generateAuthenticatorCode,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.authenticationController}/generate-authenticator-secret`,
    },
    {
        call: api.authentication.changePassword,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.authenticationController}/change-password`,
    },
    {
        call: api.getUser,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/{param0}`,
    },
    {
        call: api.getEmployees,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/{param0}/{param1}`,
    },
    {
        call: 'GetEmployeesForManager',
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/{param0}/subordinates`,
    },
    {
        call: 'GetEmployeesForManager',
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/{param0}/subordinates`,
    },
    {
        call: api.employeeController.getLeadership,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/leadership`,
    },
    {
        call: api.employeeController.getEmployeeProfile,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/profile/{param0}`,
    },
    {
        call: api.employeeController.getEmployeeAssessmentEvaluationSurvey,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/{param0}/evaluation-survey/{param1}`,
    },
    {
        call: api.employeeController.createEmployeeAssessmentEvaluationSurvey,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.employeeController}/{param0}/evaluation-survey/{param1}`,
    },
    {
        call: api.employeeController.getPerformanceCycles,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/{param0}/performance-cycle`,
    },
    {
        call: 'GetOkrStatus',
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.referenceDataController}/okrstatus`,
    },
    {
        call: 'GetObjectiveType',
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.referenceDataController}/objectivetype/{param0}`,
    },
    {
        call: api.objectiveController.getObjectives,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.objectiveController}`,
    },
    {
        call: api.objectiveController.getObjective,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.objectiveController}/{param0}`,
    },
    {
        call: api.objectiveController.getCompanyObjectives,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.objectiveController}/company`,
    },
    {
        call: 'GetMySubordinates',
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/subordinates`,
    },
    {
        call: 'GetOrgUnits',
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.orgUnitController}`,
    },
    {
        call: 'GetOrgUnitById',
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.orgUnitController}/{param0}`,
    },
    {
        call: 'UpdateOrgUnit',
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.orgUnitController}`,
    },
    {
        call: api.getAvatar,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.feedbackAvatarController}`,
    },
    {
        call: api.updateFeedbackHelpfull,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.feedbackController}/{param0}/helpful`,
    },
    {
        call: api.getFeedbackAvatarAdvice,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.feedbackAvatarAdviceController}`,
    },
    {
        call: api.createNewFeedbackAdvice,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.feedbackController}`,
    },
    {
        call: api.updateFeedbackAdvice,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.feedbackController}`,
    },
    {
        call: api.deleteFeedbackAdvice,
        method: 'DELETE',
        url: `${API_BASE_URL}/${api.controller.feedbackController}/{param0}`,
    },
    {
        call: api.getFeedbackAdvices,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.feedbackController}`,
    },
    {
        call: api.getLastFeedbacks,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.feedbackAdviceController}/lastfeedbacks`,
    },
    {
        call: api.getFeedbackAdvice,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.feedbackAdviceController}/{param0}`,
    },
    {
        call: api.getFeedbackAdviceGif,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.feedbackAdviceController}/gif/{param0}`,
    },
    {
        call: api.getAvatarPhoto,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.feedbackAvatarController}/{param0}/photo`,
    },
    {
        call: api.getStoreItems,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.storeController}/items/{param0}`,
    },
    {
        call: api.buyStoreItem,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.storeController}`,
    },
    {
        call: api.getEmployeeStats,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/stats/{param0}`,
    },
    {
        call: api.getLoggedInEmployeeStats,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/stats`,
    },
    {
        call: api.updateAvatarPhoto,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.employeeController}/updateAvatarPhoto`,
    },
    {
        call: api.notificationsController.getNotifications,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.notificationsController}`,
    },
    {
        call: api.putNotificationStatus,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.notificationsController}`,
    },
    {
        call: api.getCompetenciesByEmployeeId,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.competencyController}/{param0}`,
    },
    {
        call: api.getLeaderBoards,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.dashboardsController}`,
    },
    {
        call: api.employeeController.getSuggestedEmployeeForFeedback,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.feedbackController}/suggestions`,
    },
    {
        call: api.employeeController.getObjectiveSuggestedEmployees,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/objective/suggestions`,
    },
    {
        call: api.getStoreTransactions,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.storeController}/transactions?{param0}`,
    },
    {
        call: api.getCompetencyStatsByCompanyIdYear,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.competencyController}/{param0}/stats/{param1}`,
    },
    {
        call: api.userController.requestPasswordReset,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.authenticationController}/password-recovery`,
    },
    {
        call: api.userController.resetPassword,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.authenticationController}/reset-password`,
    },
    {
        call: api.userController.changePassword,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.authenticationController}/change-password`,
    },
    {
        call: api.getEmployeeCompetency,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeCompetenciesController}/{param0}`,
    },
    {
        call: api.objectiveController.objectiveAssignees,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.objectiveController}/assign`,
    },
    {
        call: api.createEmployeeCompetency,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.employeeCompetenciesController}`,
    },
    {
        call: api.deleteEmployeeCompetency,
        method: 'DELETE',
        url: `${API_BASE_URL}/${api.controller.employeeCompetenciesController}/{param0}`,
    },
    {
        call: api.getAnalyticsCompetencies,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.competencyController}/analytics`,
    },
    {
        call: api.analyticsController.getAnalyticsHierarchies,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.analyticsController}/filters/teams`,
    },
    {
        call: api.getAnalyticsGraphData,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.analyticsController}`,
    },
    {
        call: api.getConfiguration,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.configurationController}`,
    },
    {
        call: api.skillsController.getSkills,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.skillController}`,
    },
    {
        call: api.skillsController.getSkillsSuggestions,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.skillController}/suggestions`,
    },
    {
        call: api.objectiveController.createObjective,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.objectiveController}`,
    },
    {
        call: api.objectiveController.updateObjective,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.objectiveController}`,
    },
    {
        call: api.keyResultsController.updateKeyResults,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.keyResultsController}`,
    },
    {
        call: api.objectiveController.assignObjective,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.objectiveController}/{param0}/assignment`,
    },
    {
        call: api.objectiveController.getQuestEmployeeEvaluationSurvey,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.objectiveController}/{param0}/evaluation-survey`,
    },
    {
        call: api.objectiveController.createQuestEmployeeEvaluationSurvey,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.objectiveController}/{param0}/evaluation-survey`,
    },
    {
        call: api.employeeController.getEmployeePerformanceReview,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/{param0}/performance-cycle/{param1}`,
    },
    {
        call: api.employeeController.myTeam,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.searchController}/my-team`,
    },
    {
        call: api.employeeController.autoSuggest,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.searchController}/autosuggest`,
    },
    {
        call: api.employeeController.searchEmployees,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.searchController}/employees`,
    },
    {
        call: api.employeeController.searchJobs,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.searchController}/jobs`,
    },
    {
        call: api.employeeController.searchTeams,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.searchController}/org-units`,
    },
    {
        call: api.jobController.getJobProfile,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.jobController}/profile/{param0}`,
    },
    {
        call: api.analyticsController.getAnalyticsJobs,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.analyticsController}/filters/jobs`,
    },
    {
        call: api.jobFamilyController.getJobFamilyProfile,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.jobFamilyController}/profile/{param0}`,
    },
    {
        call: api.teamController.getTeamProfile,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.teamController}/profile/{param0}`,
    },
    {
        call: api.activityStreamController.getActivityStream,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.activityStreamController}`,
    },
    {
        call: api.activityStreamController.getActivityStreamAssignees,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.activityStreamController}/assign`,
    },
    {
        call: api.analyticsController.getJobAnalytics,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.analyticsController}/job/{param0}`,
    },
    {
        call: api.analyticsController.getTeamAnalytics,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.analyticsController}/team/{param0}`,
    },
    {
        call: api.analyticsController.getPlayerAnalytics,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.analyticsController}/employee/{param0}`,
    },
    {
        call: api.analyticsController.getAnalyticsCulture,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.analyticsController}/culture`,
    },
    {
        call: api.analyticsController.getAnalyticsQuests,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.analyticsController}/quests`,
    },
    {
        call: api.employeeController.getFeatures,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.employeeController}/features`,
    },
    {
        call: api.jobController.getFeatures,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.jobController}/features`,
    },
    {
        call: api.teamController.getFeatures,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.teamController}/features`,
    },
    {
        call: api.objectiveController.getFeatures,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.objectiveController}/{param0}/features`,
    },
    {
        call: api.objectiveController.archiveQuest,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.objectiveController}/{param0}/archive`,
    },
    {
        call: api.objectiveController.reopenQuest,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.objectiveController}/{param0}/reopen`,
    },
    {
        call: api.storagesController.getImage,
        method: 'GET',
        url: `${API_RESOURCE_URL}/${api.controller.storagesController}/{param0}`,
    },
    {
        call: api.feedback.share,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.feedbackController}/{param0}/share`,
    },
    {
        call: api.feedback.unShare,
        method: 'POST',
        url: `${API_BASE_URL}/${api.controller.feedbackController}/{param0}/unShare`,
    },
    {
        call: api.reportsController.createReport,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.reportsController}/{param0}`,
    },
    {
        call: api.tilesController.getTileTabs,
        method: 'GET',
        url: `${API_ADMIN_URL}/${api.controller.tilesController}/{param0}`,
    },
    {
        call: api.policiesController.getPolicies,
        method: 'GET',
        url: `${API_ADMIN_URL}/${api.controller.adminPoliciesController}`,
        // url: `${API_BASE_URL}/admin-api/policies`,
    },
    {
        call: api.policiesController.getPolicy,
        method: 'GET',
        url: `${API_BASE_URL}/${api.controller.policiesController}/{param0}`,
    },
    {
        call: api.policiesController.updatePolicy,
        method: 'PUT',
        url: `${API_BASE_URL}/${api.controller.employeeController}/policy`,
    },
    {
        call: api.adminController.getLanguages,
        method: 'GET',
        url: `${adminApi.appCodes}/languages`,
    },
    {
        call: api.adminController.getCompanySettings,
        method: 'GET',
        // url: adminApi.companies,
        url: `${adminApi.companies}/settings`,
    },
    {
        call: api.adminController.updateCompanySettings,
        method: 'PUT',
        url: `${adminApi.companies}/settings`,
        // url: adminApi.companies,
    },
    {
        call: api.importsController.getImports,
        method: 'GET',
        url: adminApi.import,
    },
    {
        call: api.importsController.getImportDetail,
        method: 'GET',
        url: `${adminApi.import}/{param0}`,
    },
    {
        call: api.revokedConsentsController.getUsersConsents,
        method: 'GET',
        url: `${adminApi.reports}/user-policies`,
    },
];
