import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = () => {
    return {
        rulerLine: css({
            strokeWidth: 1,
            strokeDasharray: 4,
            stroke: colors.grey,
        }),
    };
};
