import type { FC } from 'react';
import type { Report } from 'types';
import { QuestType, REPORT_TYPE } from 'types';
import { Avatar, CardContent, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiAccountHeart, mdiFeather, mdiStarSettings, mdiSwordCross } from '@mdi/js';
import { useStyles } from './styles';
import { ReportActionsMenu } from '../ReportActionsMenu';
import { RaisedCard } from '../../../RaisedCard';

interface Props {
    report: Report;
    onReportClick?(reportId: string): void;
}

const REPORT_ICONS = {
    [REPORT_TYPE.COMPANY]: mdiStarSettings,
    [REPORT_TYPE.GROWTH]: mdiAccountHeart,
    [REPORT_TYPE.SKILL]: mdiFeather,
    [REPORT_TYPE.TASK]: mdiSwordCross,
};

export const ReportsListCard: FC<Props> = ({ onReportClick, report }) => {
    const classes = useStyles(report.type);

    const handleOnReportCardClick = () => onReportClick?.(report.id);

    return (
        <RaisedCard onClick={handleOnReportCardClick}>
            <CardContent sx={classes.content}>
                <div className={classes.nameAndIcon}>
                    <div className={classes.typeIconWrapper}>
                        <Avatar variant="rounded" sx={classes.typeIconAvatar}>
                            <Icon path={REPORT_ICONS[report.type]} className={classes.typeIcon} />
                        </Avatar>
                    </div>
                    <Typography>
                        <strong>{report.name}</strong>
                    </Typography>
                </div>
                <div className={classes.actions}>
                    <ReportActionsMenu report={report} />
                </div>
            </CardContent>
        </RaisedCard>
    );
};
