import { frankyTalesTheme } from 'theme';
import { useBackground } from 'hooks';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const background = useBackground();
    const theme = useTheme();

    return {
        containerAlign: css({
            flexDirection: 'column',
            alignItems: 'end',
        }),
        content: css({
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '33%',

            '& p': {
                textAlign: 'center',
            },

            '& h1': {
                textAlign: 'center',
            },
        }),
        contentWrapper: css({
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '60%',
            backgroundColor: colors.white,
        }),
        logo: css({
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(2),
        }),
        title: {
            fontSize: theme.spacing(8),
        },
        grayText: {
            fontSize: '22px',
            color: colors.grey,
        },
        link: css({ textDecoration: 'none', color: colors.skyBlue }),
        darkBlueText: {
            color: colors.darkBlue,
        },
    };
};
