import { createContext, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import type { AlertColor } from '@mui/material';
import { Snackbar } from 'components';

export const SnackbarContext = createContext<any>({
    showSuccessMsg: () => {},
    showErrorMsg: () => {},
});

interface Props {
    children: ReactNode;
}

export const SnackbarProvider: FC<Props> = ({ children }) => {
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarVariant, setSnackBarVariant] = useState<AlertColor>('success');

    const showErrorMsg = (message: string) => {
        setSnackBarVariant('error');
        setSnackBarMessage(message);
        setSnackBarOpen(true);
    };

    const showSuccessMsg = (message: string) => {
        setSnackBarVariant('success');
        setSnackBarMessage(message);
        setSnackBarOpen(true);
    };

    const handleApiResponse = (response: any, handleRedirect: NavigateFunction, url: string) => {
        if (response.data?.success) {
            showSuccessMsg(response.data.message);

            if (handleRedirect && url) handleRedirect(url);
        } else {
            showErrorMsg(response.data.message);
        }
    };

    const handleOnSnackBarClose = () => setSnackBarOpen(false);

    const contextValue = useMemo(
        () => ({
            showSuccessMsg,
            showErrorMsg,
            handleApiResponse,
        }),
        [showSuccessMsg, showErrorMsg, handleApiResponse],
    );

    return (
        <SnackbarContext.Provider value={contextValue}>
            {children}
            <Snackbar
                open={snackBarOpen}
                onClose={handleOnSnackBarClose}
                message={snackBarMessage}
                variant={snackBarVariant}
            />
        </SnackbarContext.Provider>
    );
};
