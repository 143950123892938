import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        grid: {
            marginTop: theme.spacing(1),
        },
        gridItem: {
            display: 'flex',
            transition: 'margin 70ms, padding 70ms',

            paddingTop: `${theme.spacing(4)} !important`,
            paddingBottom: `${theme.spacing(4)} !important`,

            '&:hover &:focus': {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(6),
            },
        },
        focused: {
            paddingTop: `${theme.spacing(2)} !important`,
            paddingBottom: `${theme.spacing(6)} !important`,
        },
        itemWrapper: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        spinnerWrapper: css({
            position: 'relative',
        }),
        arrowButton: {
            position: 'absolute',
            top: '45%',
            backgroundColor: 'white',
            zIndex: 6,

            '&:hover, &:focus': {
                backgroundColor: '#ececec',
            },

            '& .MuiSvgIcon-root': {
                fontSize: '3rem',
                color: colors.blue,
            },

            '&.left': {
                left: theme.spacing(10),
            },

            '&.right': {
                right: theme.spacing(10),
            },
        },
        slideWrapper: css({
            position: 'relative',
            paddingLeft: theme.spacing(16),
            paddingRight: theme.spacing(16),
            paddingTop: theme.spacing(2),

            '&::before': {
                content: "''",
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: -1,
                width: theme.spacing(16),
                zIndex: 5,
                background:
                    'linear-gradient(to left, rgba(246, 249, 252, 0), rgba(246, 249, 252, 0.9) 60%, #f6f9fc)',
            },

            '&::after': {
                content: "''",
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: -1,
                width: theme.spacing(16),
                zIndex: 5,
                background:
                    'linear-gradient(to right, rgba(246, 249, 252, 0), rgba(246, 249, 252, 0.9) 60%, #f6f9fc)',
            },
        }),
    };
};
