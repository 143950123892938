import { useContext } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Typography,
} from '@mui/material';
import { Coins, Experience } from 'components';
import { useGoBackFromNodal } from 'hooks';
import { CreateQuestContext } from 'contexts';
import type { AppReducerState } from 'store';
import { useStyles } from './styles';

const MIN = 1;
const MAX = 21;

export const QuestSuccessStep: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const goBackFromNodal = useGoBackFromNodal();
    const random = Math.floor(Math.random() * (MAX - MIN + 1)) + MIN;
    const image = `/gifs/positive_${`000${random}`.slice(-3)}.gif`;
    const { priorityId, createNewQuest, objectiveId } = useContext(CreateQuestContext);
    const { questPriorities } = useSelector(({ configuration }: AppReducerState) => configuration);
    const { rewardXp = 1, rewardCoins = 2 } =
        questPriorities.find(({ id }) => id === priorityId) || {};

    const handleCreateNewQuestClick = () => createNewQuest();

    return (
        <Card sx={classes.marginTop}>
            <CardHeader
                title={<Typography variant="h2">{t('quest.successStep.title.label')}</Typography>}
                sx={classes.header}
            />
            <CardContent sx={classes.reward}>
                <Typography>{t('quest.successStep.reward.label')}</Typography>
                <Experience value={rewardXp} className={classes.xp} />
                <Coins value={rewardCoins} displaySign />
            </CardContent>
            <CardMedia
                image={image}
                title={t('feedback.new.successStep.title.label')}
                sx={classes.feedbackImage}
            />
            <Divider />
            <CardActions>
                {!objectiveId && (
                    <Button variant="outlined" onClick={handleCreateNewQuestClick}>
                        {t('quest.successStep.actions.createNewQuest.label')}
                    </Button>
                )}
                <Button variant="contained" onClick={goBackFromNodal}>
                    {t('quest.successStep.actions.done.label')}
                </Button>
            </CardActions>
        </Card>
    );
};
