import { Typography } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import type { LegendItem } from 'types';
import { useStyles } from './ChartLegendItem.styles';

interface Props {
    legendItem: LegendItem;
}

export const ChartLegendItem = ({ legendItem }: Props) => {
    const classes = useStyles(legendItem);

    return (
        <div className={classes.chartLegendItem}>
            <FiberManualRecord sx={classes.icon} />
            <Typography component="span">
                <strong>{legendItem.label}</strong> {legendItem.valueLabel}
            </Typography>
        </div>
    );
};
