import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { useLayout } from 'hooks';

export const useStyles = (): any => {
    const { isXs, isSm, isMd, width, sidePadding, menuPadding } = useLayout();
    const isMdAndLower = isXs || isSm || isMd;

    const theme = useTheme();

    return {
        pageWrapper: css({
            paddingLeft: sidePadding + menuPadding,
            paddingRight: sidePadding,
            overflow: 'hidden',
        }),
        pageContent: css({
            marginLeft: 'auto',
            marginRight: 'auto',
            width,
            marginBottom: isMdAndLower ? theme.spacing(11) : theme.spacing(4),
        }),
    };
};
