import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Report } from 'types';
import { ReportFeature } from 'types';
import { api, callService, createDownloadFile } from 'services';
import { useAnalyticsFilter } from 'hooks';
import { Loading } from 'components';
import { Fade, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { useStyles } from './styles';

const FEATURE_TRANSLATION_KEYS = {
    [ReportFeature.CREATE]: 'report.reportListCard.actions.join.label',
};

interface Features {
    features: ReportFeature[];
}

interface Props {
    report: Report;
}

export const ReportActionsMenu: FC<Props> = ({ report }) => {
    const [actionsMenuOpen, setActionsMenuOpen] = useState(false);
    const [isActionRunning, setIsActionRunning] = useState(false);
    const [reportFeatures, setReportFeatures] = useState<Features>();
    const actionsMenuButtonRef = useRef(null);
    const { filter } = useAnalyticsFilter();
    const { t } = useTranslation();
    const classes = useStyles();

    const handleActionsMenuClose = () => setActionsMenuOpen(false);

    const handleOnMenuButtonClick = async () => {
        setReportFeatures({
            features: [ReportFeature.CREATE],
        });
        // setReportFeatures(undefined);
        setActionsMenuOpen(true);

        // const { error, payload } = await callService({
        //     api: api.objectiveController.getFeatures,
        //     params: [quest.objectiveId],
        // });
        //
        // if (!error && payload) {
        //     setReportFeatures(payload);
        // }
    };

    const handleCreateReport = async () => {
        setIsActionRunning(true);
        const { dateFrom, dateTo, jobs, hierarchies } = filter;
        const { error, payload } = await callService({
            api: api.reportsController.createReport,
            params: [report.id],
            query: {
                jobs: jobs.join(','),
                dateFrom: dateFrom.format('YYYY-MM-DD'),
                dateTo: dateTo.format('YYYY-MM-DD'),
                teams: hierarchies.join(','),
            },
        });

        if (!error && payload) {
            createDownloadFile(payload);
        }

        setIsActionRunning(false);
    };

    const handleActionMenuItemClick = (feature) => () => {
        setActionsMenuOpen(false);

        if (feature === ReportFeature.CREATE) {
            handleCreateReport();
        }
    };

    if (isActionRunning) {
        return <Loading />;
    }

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            onClick={(evt) => {
                evt.stopPropagation();
            }}
            onKeyUp={(evt) => {
                evt.stopPropagation();
            }}
        >
            <IconButton
                aria-label={t<string>('report.reportListCard.menu.aria')}
                aria-controls={`actions-menu-${report.id}`}
                aria-haspopup="true"
                onClick={handleOnMenuButtonClick}
                ref={actionsMenuButtonRef}
                size="large"
            >
                <MoreHoriz />
            </IconButton>
            <Menu
                id={`actions-menu-${report.id}`}
                anchorEl={actionsMenuButtonRef.current}
                open={actionsMenuOpen}
                onClose={handleActionsMenuClose}
                TransitionComponent={Fade}
            >
                {!reportFeatures && (
                    <>
                        <Skeleton variant="rectangular" sx={classes.skeleton} />
                        <Skeleton variant="rectangular" sx={classes.skeleton} />
                    </>
                )}
                {reportFeatures?.features?.map((feature) => (
                    <MenuItem
                        key={`actions-menu-${report.id}-${feature}`}
                        onClick={handleActionMenuItemClick(feature)}
                    >
                        {t(FEATURE_TRANSLATION_KEYS[feature])}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
