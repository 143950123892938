/* eslint-disable max-lines */
import { useCallback, useState, useEffect } from 'react';
import type { VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Select,
    TextField,
    Typography,
    MenuItem,
} from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import { KeyResultAssignedTo, PROFILE_TYPE, ACTIVITY_STREAM_ITEM_TYPE } from 'types';
import type { QuestAssignee } from 'types';
import { useLayout, useActivityStreamFilter } from 'hooks';
import { Avatar } from 'components';
import { api, callService } from 'services';
import { CustomPopper } from './CustomPopper';
import { useStyles } from './styles';

const TYPE_TRANSLATION_KEYS = {
    [ACTIVITY_STREAM_ITEM_TYPE.FEEDBACK]: 'activityStream.streamFilter.type.feedback.label',
    [ACTIVITY_STREAM_ITEM_TYPE.QUEST]: 'activityStream.streamFilter.type.quest.label',
    [ACTIVITY_STREAM_ITEM_TYPE.OBJECTIVE_EVALUATION]:
        'activityStream.streamFilter.type.objectiveEvaluation.label',
    [ACTIVITY_STREAM_ITEM_TYPE.JOB_PROMOTION]:
        'activityStream.streamFilter.type.jobPromotion.label',
    [ACTIVITY_STREAM_ITEM_TYPE.ANNIVERSARY]: 'activityStream.streamFilter.type.anniversary.label',
    [ACTIVITY_STREAM_ITEM_TYPE.PROCESS_REMINDER]:
        'activityStream.streamFilter.type.processReminder.label',
};

const MIN_LENGTH_FOR_SEARCH = 2;

const ASSIGNED_TO_TYPES = {
    employees: {
        groupKey: 'activityStream.streamFilter.assignTo.groups.employees.label',
        type: KeyResultAssignedTo.EMPLOYEE,
    },
    teams: {
        groupKey: 'activityStream.streamFilter.assignTo.groups.teams.label',
        type: KeyResultAssignedTo.TEAM,
    },
    jobs: {
        groupKey: 'activityStream.streamFilter.assignTo.groups.jobs.label',
        type: KeyResultAssignedTo.JOB,
    },
};

const ASSIGNED_TO_QUERIES = {
    [PROFILE_TYPE.PLAYER]: 'employeeId',
    [PROFILE_TYPE.JOB]: 'jobId',
    [PROFILE_TYPE.JOB_FAMILY]: 'jobFamilyId',
    [PROFILE_TYPE.TEAM]: 'teamId',
};

interface Props {
    className?: string;
}

export const StreamFilter: VFC<Props> = ({ className }) => {
    const { assignedToWithType, filter, setActivityStreamFilterPreFillDataAction } =
        useActivityStreamFilter();
    const { eventTypes, query, assignedTo } = filter;
    const { t } = useTranslation();
    const [users, setUsers] = useState<QuestAssignee[]>([]);
    const [assignees, setAssignees] = useState<QuestAssignee[]>(
        assignedToWithType ? [] : (assignedTo as []),
    );
    const [assigneesQuery, setAssigneesQuery] = useState('');
    const [assigneesFocused, setAssigneesFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { isXs, isSm } = useLayout();
    const classes = useStyles();

    const handleOnQueryChange = (evt) =>
        setActivityStreamFilterPreFillDataAction({
            filter: { ...filter, query: evt.target.value },
        });

    const handleOnClearQueryClick = () =>
        setActivityStreamFilterPreFillDataAction({ filter: { ...filter, query: '' } });

    const handleOnGiveFeedbackClick = () => navigate('./feedback/new');

    const handleStreamTypesChange = (evt) =>
        setActivityStreamFilterPreFillDataAction({
            filter: { ...filter, eventTypes: evt.target.value },
        });

    const handleOnAssigneesChange = useCallback(
        (evt, selectedAssignees) => {
            setAssignees(selectedAssignees);
            setActivityStreamFilterPreFillDataAction({
                filter: {
                    ...filter,
                    assignedTo: selectedAssignees,
                },
            });
        },
        [filter],
    );

    const handleAssigneesDropdownClose = () => {
        setUsers([]);
        setAssigneesQuery('');
    };

    const getInputItem = (assigneeItem, key) => ({
        group: t(ASSIGNED_TO_TYPES[key].groupKey),
        type: ASSIGNED_TO_TYPES[key].type,
        name: assigneeItem.title || assigneeItem.displayName,
        id:
            assigneeItem.employeeSysId ||
            assigneeItem.employeeId ||
            assigneeItem.teamId ||
            assigneeItem.jobId,
        profilePhoto: assigneeItem.profilePhoto,
    });

    const parseSearchedUsers = (payload) =>
        Object.keys(payload).reduce(
            (acc, key) => [
                ...acc,
                ...payload[key].map((searchedUser) => getInputItem(searchedUser, key)),
            ],
            [] as any,
        );

    const preFillAssignedTo = async (assignedToPrefill) => {
        if (assignedToPrefill?.id.length) {
            setIsLoading(true);

            const { error, payload } = await callService({
                api: api.activityStreamController.getActivityStreamAssignees,
                params: { [ASSIGNED_TO_QUERIES[assignedToPrefill.type]]: assignedToPrefill.id },
            });

            if (!error && payload) {
                const parsedUsers = parseSearchedUsers(payload);

                setUsers(parsedUsers);
                setAssignees([
                    parsedUsers.find((parsedUser) => parsedUser.id === assignedToPrefill.id),
                ]);
                setIsLoading(false);
            }
        }
    };

    const handleUsersSearch = async (evt) => {
        setAssigneesQuery(evt.target.value);

        if (evt.target.value?.length >= MIN_LENGTH_FOR_SEARCH) {
            setIsLoading(true);

            const { error, payload } = await callService({
                api: api.objectiveController.objectiveAssignees,
                params: { query: evt?.target?.value },
            });

            if (!error && payload) {
                setUsers(parseSearchedUsers(payload));
                setIsLoading(false);
            }
        }
    };

    const handleAssigneesFocus = () => setAssigneesFocused(true);

    const handleAssigneesBlur = () => setAssigneesFocused(false);

    useEffect(() => {
        if (assignedToWithType) {
            preFillAssignedTo(assignedToWithType);
        }
    }, []);

    return (
        <div className={cx(className)}>
            <TextField
                type="text"
                value={query}
                onChange={handleOnQueryChange}
                fullWidth
                size="small"
                className="searchInput"
                sx={classes.queryInput}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {!query && <Search />}
                            {query && (
                                <IconButton
                                    onClick={handleOnClearQueryClick}
                                    aria-label={t<string>(
                                        'activityStream.streamFilter.actions.clear.aria',
                                    )}
                                    size="large"
                                >
                                    <Clear />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <div className={classes.filterInputsWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl size="small" fullWidth>
                            <Select
                                multiple
                                value={eventTypes}
                                onChange={handleStreamTypesChange}
                                displayEmpty
                                renderValue={() =>
                                    !eventTypes.length
                                        ? t('activityStream.streamFilter.allTypes.label')
                                        : `${t('activityStream.streamFilter.types.label', {
                                              count: eventTypes.length,
                                          })}`
                                }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                }}
                            >
                                {Object.keys(TYPE_TRANSLATION_KEYS).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        <Checkbox
                                            checked={eventTypes.includes(
                                                key as ACTIVITY_STREAM_ITEM_TYPE,
                                            )}
                                            readOnly
                                        />
                                        {t(TYPE_TRANSLATION_KEYS[key])}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>{' '}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            value={assignees}
                            multiple
                            onChange={handleOnAssigneesChange}
                            getOptionLabel={(option) => option.name}
                            groupBy={(option) => option.group || ''}
                            filterOptions={(options) => options}
                            renderOption={(props, option) => (
                                <li className={classes.avatarWrapper} {...props}>
                                    <Checkbox
                                        checked={assignees.some(
                                            (assignee) => assignee.id === option.id,
                                        )}
                                        readOnly
                                    />
                                    <Avatar
                                        src={option.profilePhoto}
                                        alt={option.name[0]}
                                        sx={classes.avatar}
                                    />
                                    <Typography>{option.name}</Typography>
                                </li>
                            )}
                            options={assigneesQuery ? users : assignees}
                            popupIcon={null}
                            onInputChange={handleUsersSearch}
                            onClose={handleAssigneesDropdownClose}
                            onFocus={handleAssigneesFocus}
                            onBlur={handleAssigneesBlur}
                            open={
                                (assigneesFocused &&
                                    !!(assigneesQuery ? users : assignees).length) ||
                                !!assigneesQuery
                            }
                            PopperComponent={CustomPopper}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <div className={classes.assigneesValueLabel}>
                                                {!assignees.length &&
                                                    t(
                                                        'activityStream.streamFilter.allAssignees.label',
                                                    )}
                                                {!!assignees.length &&
                                                    t(
                                                        'activityStream.streamFilter.assignees.label',
                                                        {
                                                            count: assignees.length,
                                                        },
                                                    )}
                                            </div>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {isLoading ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                        key="asd"
                                                    />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {!(isXs || isSm) && (
                    <div className={classes.giveFeedbackButton}>
                        <Button variant="contained" onClick={handleOnGiveFeedbackClick}>
                            {t('activityStream.streamFilter.actions.giveFeedback.label')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
