import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRedirectToHomeOrGDPR } from 'hooks';
import { api, callService, getPasswordErrors, getPasswordConfirmationErrors } from 'services';
import type { FormValidationError } from 'types';
import { Alert, Button, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import { PasswordInput } from 'components';
import { useStyles } from '../common/styles';

export const PasswordReset: FC = () => {
    const [serviceError, setServiceError] = useState('');
    const [serviceSuccess, setServiceSuccess] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmation, setConfirmation] = useState('');
    const [passwordErrors, setPasswordErrors] = useState<FormValidationError[]>([]);
    const [confirmationErrors, setConfirmationErrors] = useState<FormValidationError[]>([]);
    const { t } = useTranslation();
    const classes = useStyles();
    const { resetId } = useParams();

    useRedirectToHomeOrGDPR();

    const resetPassword = async () => {
        const { error } = await callService({
            api: api.userController.resetPassword,
            body: {
                password,
                resetId,
            },
            useToken: false,
        });

        if (error) {
            setServiceError(t<string>('user.passwordReset.message.error.label'));
            return;
        }

        setServiceError('');
        setServiceSuccess(true);
    };

    const handleFormSubmit = (evt) => {
        evt.preventDefault();
        const passwordValidationErrors = getPasswordErrors(password, t);
        const confirmationValidationErrors = getPasswordConfirmationErrors(
            password,
            confirmation,
            t,
        );

        setPasswordErrors(passwordValidationErrors);
        setConfirmationErrors(confirmationValidationErrors);
        setServiceError('');

        if (!passwordValidationErrors.length && !confirmationValidationErrors.length) {
            resetPassword();
        }
    };

    const handlePasswordChange = (value) => {
        setPassword(value);
        setPasswordErrors([]);
    };

    const handleConfirmationChange = (value) => {
        setConfirmation(value);
        setConfirmationErrors([]);
    };

    return (
        <div className={classes.container}>
            <Card variant="outlined" sx={classes.dialog}>
                <CardContent>
                    <Typography variant="h4" component="h1" sx={classes.title}>
                        {t('user.passwordReset.title.label')}
                    </Typography>
                    {serviceSuccess && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Alert severity="success">
                                    {t('user.passwordReset.message.success.label')}
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <Link href="/login" sx={classes.secondaryAction}>
                                    {t('user.passwordReset.actions.backToLogin.label')}
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                    {!serviceSuccess && (
                        <form onSubmit={handleFormSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="caption" component="p">
                                        {t('user.passwordReset.subTitle.label')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordInput
                                        password={password}
                                        onPasswordChange={handlePasswordChange}
                                        passwordError={passwordErrors[0]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordInput
                                        password={confirmation}
                                        onPasswordChange={handleConfirmationChange}
                                        passwordError={confirmationErrors[0]}
                                        placeholder={t<string>(
                                            'user.passwordReset.form.passwordConfirmation.placeholder',
                                        )}
                                    />
                                </Grid>
                                {serviceError && (
                                    <Grid item xs={12}>
                                        <Alert severity="error">{serviceError}</Alert>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={classes.primaryAction}
                                    >
                                        {t('user.passwordReset.actions.resetPassword.label')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link href="/login" sx={classes.secondaryAction}>
                                        {t('user.passwordReset.actions.backToLogin.label')}
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};
