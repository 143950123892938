import { frankyTalesTheme } from 'theme';
import { QuestType } from 'types';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

const QUEST_TYPE_COLORS = {
    [QuestType.TASK]: colors.blueDarker,
    [QuestType.GROWTH]: colors.pinkRed,
    [QuestType.SKILL]: colors.lightGreen,
    [QuestType.COMPANY]: colors.orange,
};

export const useStyles = (questType) => ({
    icon: css({
        height: '2em',
        width: '2em',
        color: QUEST_TYPE_COLORS[questType],
    }),
});
