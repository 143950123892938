import type { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { PlayerSearchResultMode } from 'types';
import type { PlayerSearchResult, PlayerSearchResultType } from 'types';
import { useLayout } from 'hooks';
import { useStyles } from './styles';
import { PlayerSearchCard } from '../PlayerSearchCard';

interface Props {
    mode: PlayerSearchResultMode;
    players: PlayerSearchResult[];
    type: PlayerSearchResultType;
    title: string;
}

const getItemSize = (layout, mode) => {
    const { isXs, isSm } = layout;

    if (isXs || mode === PlayerSearchResultMode.TABLE) {
        return 12;
    }

    if (isSm) {
        return 6;
    }

    return 4;
};

export const PlayerSearchResults: FC<Props> = ({ mode, players = [], title, type }) => {
    const layout = useLayout();
    const classes = useStyles(mode);
    const itemSize = getItemSize(layout, mode);

    if (!players.length) {
        return null;
    }

    return (
        <>
            <Typography component="h2" variant="h2">
                {title}
            </Typography>
            <Grid
                container
                spacing={mode === PlayerSearchResultMode.GRID ? 3 : 2}
                sx={classes.title}
            >
                {players.map((player) => (
                    <Grid item xs={itemSize} key={player.id}>
                        <PlayerSearchCard player={player} mode={mode} type={type} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
