import axios from 'axios';
import type {
    ISearch,
    IJobTeamRoleView,
    IUpsertJobTeamRoleCommand,
    ISearchView,
    IApiResponse,
    IAutocompleteView,
} from 'types';
import { api } from '../constants';

const controllerApi = api.jobTeamRoles;

export const search = async (queryParams: ISearch): Promise<ISearchView<IJobTeamRoleView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const autocomplete = async (searchText: string): Promise<IAutocompleteView[]> => {
    return axios
        .get(`${controllerApi}/Autocomplete?searchText=${searchText}`)
        .then((res) => res.data);
};

export const getBySysId = async (id: string): Promise<IJobTeamRoleView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};

export const create = async (data: IUpsertJobTeamRoleCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (
    id: string,
    data: IUpsertJobTeamRoleCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const deleteTeams = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};

export const jobTeamRoleService = {
    search,
    create,
    update,
    getBySysId,
    deleteTeams,
    autocomplete,
};
