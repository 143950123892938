import type { FC } from 'react';
import { useBoundActions, useLayout } from 'hooks';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { MenuRounded } from '@mui/icons-material';
import { Logo } from 'components';
import { openMenu } from 'store/slices/layout';
import { useStyles } from './styles';

interface Props {
    useDarkText?: boolean;
    hideGreeting?: boolean;
    hideOnBigScreen?: boolean;
}

export const HeaderBar: FC<Props> = ({ hideGreeting, hideOnBigScreen, useDarkText }) => {
    const { isXs, isSm, isMd } = useLayout();
    const isMdAndLower = isXs || isSm || isMd;
    const classes = useStyles(useDarkText, hideGreeting);
    const { openMenuAction } = useBoundActions({ openMenu });

    if (hideOnBigScreen && !isMdAndLower) {
        return null;
    }

    return (
        <AppBar position="relative" sx={classes.headerBar}>
            {isMdAndLower && (
                <Toolbar disableGutters sx={classes.toolBar}>
                    <div className={classes.logoWrapper}>
                        <div className={classes.buttonWidth} />
                        <div className={classes.greetingsWrapper}>
                            <Logo
                                className={classes.logo}
                                darkMode={!useDarkText}
                                smaller={isMdAndLower}
                            />
                        </div>
                        <IconButton
                            aria-label="open drawer"
                            onClick={() => openMenuAction()}
                            edge="start"
                            sx={classes.menuButton}
                            size="large"
                        >
                            <MenuRounded />
                        </IconButton>
                    </div>
                </Toolbar>
            )}
        </AppBar>
    );
};
