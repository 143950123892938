import type { SideMenuItem } from 'types';
import { USER_ROLES } from 'types';
import { StoreMenuItem } from './StoreMenuItem';
import { ProfileMenuItem } from './ProfileMenuItem';
import { FeedbackMenuItem } from './FeedbackMenuItem';

export const sideMenuItems: SideMenuItem[][] = [
    [
        {
            labelKey: 'profile',
            component: ProfileMenuItem,
            url: '/profile',
        },
        {
            labelKey: 'sideMenu.menuOptions.dashboard.label',
            url: '/dashboard',
        },
        {
            labelKey: 'sideMenu.menuOptions.myFeedback.label',
            component: FeedbackMenuItem,
            url: '/feedback/my',
        },
        {
            labelKey: 'sideMenu.menuOptions.questLog.label',
            url: '/quest/log',
        },
        {
            labelKey: 'sideMenu.menuOptions.notifications.label',
            url: '/notifications',
        },
    ],
    [
        {
            labelKey: 'sideMenu.menuOptions.activityStream.label',
            url: '/activityStream',
        },
        {
            labelKey: 'sideMenu.menuOptions.search.label',
            url: '/search',
        },
        {
            labelKey: 'sideMenu.menuOptions.analytics.label',
            url: '/analytics',
        },
        {
            roles: [
                USER_ROLES.ADMIN_HR,
                USER_ROLES.CONFIGURATION_MANAGER,
                USER_ROLES.SUBSCRIPTION_MANAGER,
            ],
            labelKey: 'sideMenu.menuOptions.admin.label',
            url: '/admin',
        },
    ],
    [
        {
            labelKey: 'sideMenu.menuOptions.market.label',
            component: StoreMenuItem,
            url: '/marketplace',
        },
    ],
];
