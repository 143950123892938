import type { NotificationDataLineValue } from 'types';

import { useStyles } from './styles';
import { Experience } from '../../Experience';

interface Props {
    item: NotificationDataLineValue;
}

export const EventRowMessageItemXP = ({ item }: Props) => {
    const classes = useStyles();

    return <Experience value={Number(item.points)} className={classes.experience} />;
};
