import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components';
import { signUpService } from 'services';

import { Alert, Button, Grid, Typography } from '@mui/material';
import { useStyles as useSignUpStyles } from './signUpStyles';
import { useStyles as useCommonStyles } from '../common/styles';
import { useStyles } from './twoFa.styles';

export const TwoFA: FC = () => {
    const { signUpId } = useParams();
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(true);
    const [serviceError, setServiceError] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const signUpClasses = useSignUpStyles();
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const handleContinueClick = () => navigate('/login');

    const handleDoThisLater = () => navigate('/login');

    useEffect(() => {
        setLoading(true);
        signUpService
            .getSignUpQRCode(signUpId!)
            .then((payload) => {
                setCode(payload?.data);
            })
            .catch((err) => {
                setServiceError(t<string>('profile.generateQRCode.error.label'));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div className={signUpClasses.content}>
            <Typography variant="h1">{t('user.onboarding.twoFA.title.label')}</Typography>
            <br />
            <Typography variant="body1" sx={signUpClasses.grayText}>
                {t('user.onboarding.twoFA.info.label')}
            </Typography>
            <br />
            <Typography>{t('user.onboarding.twoFA.info2.label')}</Typography>
            {/*<Trans t={t} i18nKey="user.onboarding.twoFA.info2.label">*/}
            {/*    Install an authenticator app on your phone (for example by*/}
            {/*    <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app">*/}
            {/*        Google*/}
            {/*    </a>*/}
            {/*    or*/}
            {/*    <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app">*/}
            {/*        Microsoft*/}
            {/*    </a>*/}
            {/*    ) and scan the QR code displayed below in your authenticator.*/}
            {/*</Trans>*/}
            <div className={classes.code}>
                {loading && <Loading transitionDelayIn={0} />}
                {code && !loading && (
                    <div className={classes.codeContent}>
                        <img
                            src={code}
                            alt={t<string>('profile.generateQRCode.qrCode.alt')}
                            className={classes.qrCode}
                        />
                    </div>
                )}
                {serviceError && <Alert severity="error">{serviceError}</Alert>}
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={commonClasses.actions}>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={commonClasses.primaryAction}
                        onClick={handleContinueClick}
                    >
                        {t('user.onboarding.twoFA.actions.continue.label')}
                    </Button>
                </Grid>
                <Grid item xs={12} sx={commonClasses.actions}>
                    <Button
                        variant="outlined"
                        sx={commonClasses.secondaryAction}
                        onClick={handleDoThisLater}
                    >
                        {t('user.onboarding.twoFA.actions.doThisLater.label')}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};
