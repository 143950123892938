import { useEffect } from 'react';
import type { VFC } from 'react';
import type { Leadership } from 'types';
import { useService } from 'hooks';
import { Loading } from 'components';
import { api } from 'services';
import { Grid } from '@mui/material';
import { LeadershipCard } from './LeadershipCard';

const NUM_LEADERS_PER_ROW = 2;

export const CompanyLeadership: VFC = () => {
    const {
        data = [],
        error,
        isLoading,
        cancelServiceCall,
    } = useService<Leadership[]>({
        api: api.employeeController.getLeadership,
    });

    useEffect(() => {
        return () => cancelServiceCall();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return null;
    }

    const leadershipPairs = data.reduce((acc, employee, index, array) => {
        if (index % NUM_LEADERS_PER_ROW === 0) {
            return [...acc, [array[index], array[index + 1]]];
        }

        return acc;
    }, [] as Leadership[][]);

    return (
        <Grid container spacing={2}>
            {leadershipPairs.map(([first, second]) => (
                <Grid item key={`${first?.employeeSysId}${second?.employeeSysId}`} xs={12}>
                    <Grid container spacing={2}>
                        {first && (
                            <Grid
                                item
                                key={first.employeeSysId}
                                xs={12}
                                md={data.length > 1 ? 6 : 12}
                            >
                                <LeadershipCard leader={first} />
                            </Grid>
                        )}
                        {second && (
                            <Grid item key={second.employeeSysId} xs={12} md={6}>
                                <LeadershipCard leader={second} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            ))}
            {isLoading && (
                <Grid item xs={12}>
                    <Loading transitionEnterDuration={0} transitionDelayIn={0} />
                </Grid>
            )}
        </Grid>
    );
};
