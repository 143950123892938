import { css } from '@emotion/css';
import { useTheme } from '@mui/material';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

const DEFAULT_HEIGHT = 33;
const DEFAULT_WIDTH = 170;
const SMALLER_HEIGHT = 23;
const SMALLER_WIDTH = 120;

export const useStyles = (dark, smaller) => {
    const theme = useTheme();
    const frankyColor = dark ? colors.white : colors.darkBlue;
    const talesColor = dark ? colors.logoLightBlue : colors.blueDarker;

    return {
        logo: css({
            '& svg': {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),

                height: smaller ? SMALLER_HEIGHT : DEFAULT_HEIGHT,
                width: smaller ? SMALLER_WIDTH : DEFAULT_WIDTH,

                '& path.franky': {
                    fill: frankyColor,
                },

                '& path.tales': {
                    fill: talesColor,
                },
            },
        }),
    };
};
