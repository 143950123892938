import { useState, useCallback } from 'react';
import { useCancellableServiceCall } from './useCancellableServiceCall';
import type {
    CancellableResponse,
    CancellableServiceCallParams,
} from './useCancellableServiceCall';

export const STARTING_PAGE = -1;

export const PAGE_SIZE = 10;

export interface InfinityScrollParams extends CancellableServiceCallParams {
    pageSize?: number;
}

export interface InfinityScrollResponse<T> extends CancellableResponse {
    nextPageNumber: number;
    currentPage: number;
    items: T[];
    resetPaging: (...args: any[]) => void;
    STARTING_PAGE: number;
    setItems: (items: T[]) => void;
    wasReset: boolean;
}

export const usePagination = <T>({
    api,
    params,
    onSuccess,
    onError,
    body,
    query,
    headers,
    useToken,
    pageSize = PAGE_SIZE,
}: InfinityScrollParams): InfinityScrollResponse<T> => {
    const [fetchedItems, setFetchedItems] = useState<T[]>([]);
    const [wasReset, setWasReset] = useState(false);
    const [currentPage, setCurrentPage] = useState(STARTING_PAGE);
    const [nextPageNumber, setNextPageNumber] = useState(STARTING_PAGE + 1);

    const successCall = useCallback(
        ({ payload, error: serviceError }) => {
            const { page, nextPage, items } = payload;

            setCurrentPage(page);
            setNextPageNumber(nextPage);
            setFetchedItems([...fetchedItems, ...items]);
            setWasReset(false);
            onSuccess?.({ payload, error: serviceError });
        },
        [fetchedItems, onSuccess],
    );

    const errorCall = useCallback(
        (response) => {
            setCurrentPage(STARTING_PAGE);
            setWasReset(false);
            onError?.(response);
        },
        [onError],
    );

    const { callService, cancelServiceCall, isLoading } = useCancellableServiceCall({
        api,
        params: {
            ...params,
            pageSize,
            page: currentPage + 1,
        },
        query: {
            ...query,
            pageSize,
            page: currentPage + 1,
        },
        onSuccess: successCall,
        onError: errorCall,
        body,
        headers,
        useToken,
    });

    const resetPaging = useCallback(() => {
        setFetchedItems([]);
        setNextPageNumber(STARTING_PAGE + 1);
        setCurrentPage(STARTING_PAGE);
        setWasReset(true);
    }, []);

    return {
        callService,
        cancelServiceCall,
        isLoading,
        items: fetchedItems,
        currentPage,
        nextPageNumber,
        resetPaging,
        STARTING_PAGE,
        setItems: setFetchedItems,
        wasReset,
    };
};
