import type { FC } from 'react';
import { Grid, CardContent, Typography } from '@mui/material';
import { Mood } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiHeartBroken } from '@mdi/js';
import { RaisedCard } from 'components';
import cx from 'classnames';
import type { AvatarAdvice } from 'types';
import { ADVICE_SENTIMENT } from 'types';
import { useStyles } from './styles';

interface Props {
    advices: AvatarAdvice[];
    onAdviceSelected: (advice: AvatarAdvice) => void;
    selectedAdviceId?: string;
}

export const YayNayAdvices: FC<Props> = ({ advices, onAdviceSelected, selectedAdviceId }) => {
    const classes = useStyles();

    const handleOnAdviceClick = (advice: AvatarAdvice) => () => onAdviceSelected(advice);

    return (
        <Grid container spacing={4}>
            {advices.map((advice) => (
                <Grid item xs={12} sm={6} key={advice.id}>
                    <RaisedCard
                        onClick={handleOnAdviceClick(advice)}
                        className={cx(classes.advice, {
                            [classes.selectedAdvice]: advice.id === selectedAdviceId,
                        })}
                    >
                        <CardContent sx={classes.cardContent}>
                            {advice.sentiment === ADVICE_SENTIMENT.POSITIVE && (
                                <Mood sx={classes.good} />
                            )}
                            {advice.sentiment === ADVICE_SENTIMENT.NEGATIVE && (
                                <Icon size="3em" path={mdiHeartBroken} className={classes.bad} />
                            )}
                            <Typography sx={classes.typeName}>{advice.advice}</Typography>
                        </CardContent>
                    </RaisedCard>
                </Grid>
            ))}
        </Grid>
    );
};
