import type { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'i18n';
import { store } from 'src/store';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'contexts';
import { theme } from 'theme';
import { DefaultLayout } from 'views/DefaultLayout';
import { Login } from 'views/user/Login';
import { Gdpr } from 'views/user/GDPR';
import { SignUp, SignUpFinishing } from 'views/user/Onboarding';
import { PasswordReset } from 'views/user/PasswordReset';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const App: FC = () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/passwordReset/:resetId" element={<PasswordReset />} />
                            <Route path="/gdpr" element={<Gdpr />} />
                            <Route path="/signUp" element={<SignUp />} />
                            <Route
                                path="/signUpFinishing/:signUpId"
                                element={<SignUpFinishing />}
                            />
                            <Route path="/*" element={<DefaultLayout />} />
                        </Routes>
                    </BrowserRouter>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    </Provider>
);
