import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import { useBoundActions } from 'hooks';
import type { JobProfileEmployee } from 'types';
import { setSearchFilterPreFillData } from 'store/slices/search';
import type { SearchFilterPreFillData } from 'store/slices/search';
import { useStyles } from './styles';
import { JobProfileEmployeeCard } from '../JobProfileEmployeeCard';

interface Props {
    employees: JobProfileEmployee[];
    preFillQuery: SearchFilterPreFillData;
    titleKey?: string;
}

const NUM_EMPLOYEES_TO_SHOW = 6;
const NUM_EMPLOYEES_PER_ROW = 2;

export const JobProfilePeopleList = ({ employees, titleKey, preFillQuery }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setSearchFilterPreFillDataAction } = useBoundActions({
        setSearchFilterPreFillData,
    });
    const classes = useStyles();

    const handleButtonClick = () => {
        setSearchFilterPreFillDataAction({ preFillQuery });
        navigate('/search');
    };

    if (!employees.length) {
        return null;
    }

    const employeePairs = employees
        .slice(0, NUM_EMPLOYEES_TO_SHOW)
        .reduce((acc, employee, index, array) => {
            if (index % NUM_EMPLOYEES_PER_ROW === 0) {
                return [...acc, [array[index], array[index + 1]]];
            }

            return acc;
        }, [] as JobProfileEmployee[][]);

    return (
        <>
            <div className={classes.titleWrapper}>
                <Typography variant="h2" component="h4" sx={classes.title}>
                    {t(titleKey || 'jobProfile.jobProfilePeopleList.title.label')}
                </Typography>
                {/*{employees.length > NUM_EMPLOYEES_TO_SHOW && (*/}
                <Button onClick={handleButtonClick}>
                    {t('jobProfile.jobProfilePeopleList.actions.seeAll.label', {
                        count: employees.length,
                    })}
                </Button>
                {/*)}*/}
            </div>
            <Grid container spacing={1}>
                {employeePairs.map(([first, second]) => (
                    <Grid item key={`${first?.id}${second?.id}`} xs={12}>
                        <Grid container spacing={1}>
                            {first && (
                                <Grid
                                    item
                                    key={first.id}
                                    xs={12}
                                    md={employees.length > 1 ? 6 : 12}
                                >
                                    <JobProfileEmployeeCard employee={first} />
                                </Grid>
                            )}
                            {second && (
                                <Grid item key={second.id} xs={12} md={6}>
                                    <JobProfileEmployeeCard employee={second} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
