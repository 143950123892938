import _ from 'lodash';
import { useEffect } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useBoundActions, useLayout } from 'hooks';
import { closeMenu, openMenu } from 'store/slices/layout';

import { Divider, Drawer, IconButton, List, SwipeableDrawer } from '@mui/material';
import type { SwipeableDrawerProps } from '@mui/material';
import { Close } from '@mui/icons-material';
import type { AppReducerState } from 'store';
import { SideMenuItem } from '../SideMenuItem';
import { Logo } from '../../Logo';
import { sideMenuItems } from '../config';
import { useStyles } from './styles';

interface Props {
    alignWithContent?: boolean;
}

export const SideMenu: FC<Props> = ({ alignWithContent = false }) => {
    const { menuOpen, userRoles } = useSelector(({ layout, user }: AppReducerState) => ({
        menuOpen: layout.menuOpen,
        userRoles: user.roles,
    }));
    const { isXs, isSm, isMd, isXxl } = useLayout();
    const classes = useStyles(alignWithContent);
    const { closeMenuAction, openMenuAction } = useBoundActions({
        closeMenu,
        openMenu,
    });
    const isMdAndLower = isXs || isSm || isMd;
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
    const drawerProps: Partial<SwipeableDrawerProps> = {
        open: menuOpen,
        sx: {
            ...classes.drawer,
            ...(menuOpen ? classes.drawerOpen : {}),
            ...(!menuOpen ? classes.drawerClosed : {}),
            ...(isXxl ? classes.wideScreenPaper : {}),
        },
    };

    useEffect(() => {
        if (isMdAndLower) {
            closeMenuAction();
        } else {
            openMenuAction();
        }
    }, [isMdAndLower]);

    const menuItems = (
        <>
            <div className={classes.toolbar}>
                <Logo />
                {isMdAndLower && (
                    <IconButton onClick={closeMenuAction} size="large">
                        <Close />
                    </IconButton>
                )}
            </div>
            {sideMenuItems.map((itemGroup, index) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>
                        <List component="nav">
                            {itemGroup.map((menuItem) => {
                                const { labelKey, roles } = menuItem;

                                if (roles && !_.intersection(userRoles, roles).length) {
                                    return null;
                                }

                                return <SideMenuItem key={labelKey} menuItem={menuItem} />;
                            })}
                        </List>
                        {sideMenuItems.length - 1 > index && <Divider variant="middle" />}
                    </div>
                );
            })}
        </>
    );

    if (isMdAndLower) {
        return (
            <SwipeableDrawer
                onClose={() => closeMenuAction()}
                onOpen={() => openMenuAction()}
                disableDiscovery={isIOS}
                anchor="right"
                {...drawerProps}
            >
                {menuItems}
            </SwipeableDrawer>
        );
    }

    return (
        <Drawer variant="permanent" {...drawerProps}>
            {menuItems}
        </Drawer>
    );
};
