import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.perfCycles;

const PerfCycleLoader = Loadable({
    loader: () => import('views/admin/PerfCycle'),
    loading: Loading,
    render(loaded, props) {
        const { PerfCycle } = loaded;
        return <PerfCycle {...props} />;
    },
    delay: general.delay,
});

const PerfCycleEditLoader = Loadable({
    loader: () => import('views/admin/PerfCycle/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const perfCycleRoute = {
    path: `/${mainRoute}`,
    component: PerfCycleLoader,
};

export const perfCycleCreateRoute = {
    path: `/${mainRoute}/create`,
    component: PerfCycleEditLoader,
};

export const perfCycleEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: PerfCycleEditLoader,
};
