import type { QuestAssignee } from 'types/Quest';

export enum ACTIVITY_STREAM_ITEM_TYPE {
    FEEDBACK = 'FEEDBACK',
    QUEST = 'QUEST',
    OBJECTIVE_EVALUATION = 'OBJECTIVE_EVALUATION',
    JOB_PROMOTION = 'JOB_PROMOTION',
    ANNIVERSARY = 'ANNIVERSARY',
    PROCESS_REMINDER = 'PROCESS_REMINDER',
}

export enum ACTIVITY_STREAM_ITEM_FEATURE_TYPE {
    VIEW_FEEDBACK = 'VIEW_FEEDBACK',
    VIEW_QUEST = 'VIEW_QUEST',
    VIEW_QUEST_LOG = 'VIEW_QUEST_LOG',
}

export interface ActivityStreamItem {
    // adviceSentence: string;
    // adviceToEmployeeSysId: string;
    // adviceToEmployeeAvatarPhoto: string;
    // avatarPhotoPath: string;
    // avatarTitle: string;
    // competencyTitle: string;

    id: string;
    type: ACTIVITY_STREAM_ITEM_TYPE;
    photoPath: string;
    subject: string;
    message: string;
    signature?: string;
    feature: ACTIVITY_STREAM_ITEM_FEATURE_TYPE;
    toEmployeeName?: string;
    employeeId?: string;
    feedbackId?: string;
    objectiveId?: string;
    objectiveEvaluationId?: string;
    teamId?: string;
    rewardPoints?: number;
    rewardCoins?: number;
    dateCreated: string;
}

export interface ActivityStreamFilterObject {
    eventTypes: ACTIVITY_STREAM_ITEM_TYPE[];
    assignedTo: QuestAssignee[] | string[];
    query: string;
}
