import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.feedbackAvatars;

const FeedbackAvatarLoader = Loadable({
    loader: () => import('views/admin/FeedbackAvatar'),
    loading: Loading,
    render(loaded, props) {
        const { FeedbackAvatar } = loaded;
        return <FeedbackAvatar {...props} />;
    },
    delay: general.delay,
});

const FeedbackAvatarEditLoader = Loadable({
    loader: () => import('views/admin/FeedbackAvatar/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

const FeedbackAvatarBulkLoader = Loadable({
    loader: () => import('views/admin/FeedbackAvatarBulkImport'),
    loading: Loading,
    render(loaded, props) {
        const { FeedbackAvatarBulkImport } = loaded;
        return <FeedbackAvatarBulkImport {...props} />;
    },
    delay: general.delay,
});

export const feedbackAvatarRoute = {
    path: `/${mainRoute}`,
    component: FeedbackAvatarLoader,
};

export const feedbackAvatarEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: FeedbackAvatarEditLoader,
};

export const feedbackAvatarCreateRoute = {
    path: `/${mainRoute}/create`,
    component: FeedbackAvatarEditLoader,
};

export const feedbackAvatarBulkImportRoute = {
    path: `/${mainRoute}/bulkImport`,
    component: FeedbackAvatarBulkLoader,
};
