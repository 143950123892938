import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        title: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        tabs: {
            marginTop: theme.spacing(2),
        },
        divider: {
            marginBottom: theme.spacing(2),
        },
        wrapper: css({
            marginBottom: theme.spacing(4),
        }),
    };
};
