import type { VFC } from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { Quest } from 'types';
import { QuestFeature } from 'types';
import { AsignementQuestModal } from 'components';
import { Button, ButtonGroup, Fade, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { api, callService } from 'services';
import { useStyles } from './styles';

const FEATURE_TRANSLATION_KEYS = {
    [QuestFeature.JOIN]: 'quest.detail.actions.join.label',
    [QuestFeature.ABANDON]: 'quest.detail.actions.abandon.label',
    [QuestFeature.UPDATE_QUEST]: 'quest.detail.actions.edit.label',
    [QuestFeature.UPDATE_PROGRESS]: 'quest.detail.actions.updateProgress.label',
    [QuestFeature.EVALUATE]: 'quest.detail.actions.evaluate.label',
    [QuestFeature.SELF_EVALUATE]: 'quest.detail.actions.selfEvaluate.label',
    [QuestFeature.ARCHIVE]: 'quest.detail.actions.archive.label',
    [QuestFeature.REOPEN]: 'quest.detail.actions.reopen.label',
};

const NUM_FEATURES_TO_RENDER_IN_DROPDOWN = 3;

interface Props {
    questDetails: Quest;
    getQuestDetails: () => {};
}

export const QuestDetailActions: VFC<Props> = ({ getQuestDetails, questDetails }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [assignmentFeature, setAssignmentFeature] = useState<QuestFeature>();
    const anchorRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();
    const features = questDetails.features || [];
    const lastFeature = features[features.length - 1];

    const handleMenuClick = (evt) => evt.stopPropagation();

    const handleMenuButtonClick = (evt) => {
        evt.stopPropagation();
        setMenuOpen(true);
    };

    const handleMenuClose = () => setMenuOpen(false);

    const handleOnModalFeatureCancel = () => setAssignmentFeature(undefined);

    const handleOnModalFeatureSuccess = () => {
        setAssignmentFeature(undefined);
        getQuestDetails();
    };

    const handleQuestArchive = async () => {
        const { error } = await callService({
            api: api.objectiveController.archiveQuest,
            params: [questDetails.objectiveId],
        });

        if (!error) {
            getQuestDetails();
        }
    };

    const handleQuestReopen = async () => {
        const { error } = await callService({
            api: api.objectiveController.reopenQuest,
            params: [questDetails.objectiveId],
        });

        if (!error) {
            getQuestDetails();
        }
    };

    const handleActionMenuItemClick = (feature) => () => {
        if (feature === QuestFeature.UPDATE_QUEST) {
            navigate(`/quest/edit/${questDetails.objectiveId}`);
        }

        if (feature === QuestFeature.ABANDON) {
            setAssignmentFeature(QuestFeature.ABANDON);
        }

        if (feature === QuestFeature.ARCHIVE) {
            handleQuestArchive();
        }

        if (feature === QuestFeature.REOPEN) {
            handleQuestReopen();
        }

        if (feature === QuestFeature.JOIN) {
            setAssignmentFeature(QuestFeature.JOIN);
        }

        if (feature === QuestFeature.SELF_EVALUATE) {
            navigate(`/quest/evaluate/self/${questDetails.objectiveId}`);
        }

        if (feature === QuestFeature.EVALUATE) {
            navigate(`/quest/evaluate/${questDetails.objectiveId}`);
        }

        if (feature === QuestFeature.UPDATE_PROGRESS) {
            navigate(`/quest/updateProgress/${questDetails.objectiveId}`);
        }
    };

    if (features.length < NUM_FEATURES_TO_RENDER_IN_DROPDOWN) {
        return (
            <div>
                <div className={classes.questActions}>
                    {features.map((feature, index) => (
                        <Button
                            key={`actions-menu-${questDetails.objectiveId}-${feature}`}
                            variant={index === features.length - 1 ? 'contained' : 'outlined'}
                            onClick={handleActionMenuItemClick(feature)}
                        >
                            {t(FEATURE_TRANSLATION_KEYS[feature])}
                        </Button>
                    ))}
                </div>
                {assignmentFeature !== undefined && (
                    <AsignementQuestModal
                        action={assignmentFeature}
                        questId={questDetails.objectiveId}
                        onCancel={handleOnModalFeatureCancel}
                        onSuccess={handleOnModalFeatureSuccess}
                    />
                )}
            </div>
        );
    }

    return (
        <div>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label={t('quest.detail.actionButton.aria')}
            >
                <Button onClick={handleActionMenuItemClick(lastFeature)}>
                    {t(FEATURE_TRANSLATION_KEYS[lastFeature])}
                </Button>
                <Button
                    size="small"
                    aria-controls={menuOpen ? 'action-button-menu' : undefined}
                    aria-expanded={menuOpen ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleMenuButtonClick}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Menu
                id="action-button-menu"
                anchorEl={anchorRef.current}
                keepMounted
                elevation={0}
                open={menuOpen}
                onClick={handleMenuClick}
                onClose={handleMenuClose}
                TransitionComponent={Fade}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {features
                    .filter((feature) => feature !== lastFeature)
                    .map((feature) => (
                        <MenuItem
                            onClick={handleActionMenuItemClick(feature)}
                            key={`actions-menu-${questDetails.objectiveId}-${feature}`}
                        >
                            {t(FEATURE_TRANSLATION_KEYS[feature])}
                        </MenuItem>
                    ))}
            </Menu>
            {assignmentFeature !== undefined && (
                <AsignementQuestModal
                    action={assignmentFeature}
                    questId={questDetails.objectiveId}
                    onCancel={handleOnModalFeatureCancel}
                    onSuccess={handleOnModalFeatureSuccess}
                />
            )}
        </div>
    );
};
