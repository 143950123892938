import { useState } from 'react';
import type { FC } from 'react';
import { Grid } from '@mui/material';
import { FeedbackJobVSCompany } from './FeedbackJobVSCompany';
import { JobFeedbackScore } from './JobFeedbackScore';
import { FeedbackTeamVSCompany } from './FeedbackTeamVSCompany';
import { AnalyticsCultureTabFilter } from './AnalyticsCultureTabFilter';
import { TeamMembersCompetencyFeedbackRate } from './TeamMembersCompetencyFeedbackRate';
import { PositiveNegativeFeedbackTeamVSCompany } from './PositiveNegativeFeedbackTeamVSCompany';
import { PositiveNegativeFeedbackJobVSCompany } from './PositiveNegativeFeedbackJobVSCompany';
import { useStyles } from './styles';

export const AnalyticsCultureTab: FC = () => {
    const [filtersLoaded, setFiltersLoaded] = useState<boolean>();
    const classes = useStyles();

    const handleFiltersLoaded = (err) => setFiltersLoaded(!err);

    return (
        <>
            <AnalyticsCultureTabFilter onFiltersLoaded={handleFiltersLoaded} />
            {filtersLoaded && (
                <Grid container spacing={2} sx={classes.graphsWrapper}>
                    <Grid item xs={12}>
                        <JobFeedbackScore />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FeedbackJobVSCompany />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PositiveNegativeFeedbackJobVSCompany />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TeamMembersCompetencyFeedbackRate />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <PositiveNegativeFeedbackTeamVSCompany />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FeedbackTeamVSCompany />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
