import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import type { StoreTransaction } from 'types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Toolbar,
    Typography,
    Divider,
} from '@mui/material';
import { PurchasedItem } from '../PurchasedItem';

interface Props {
    storeTransactions: StoreTransaction[];
    className?: string;
}

export const PurchasedItems: FC<Props> = ({ storeTransactions, className }) => {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper} className={cx(className)}>
            <Toolbar>
                <Typography variant="h4" component="h2">
                    {t('store.inventory.table.title')}
                </Typography>
            </Toolbar>
            <Divider />
            <Table aria-label={t('store.inventory.table.title')}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography>{t('store.inventory.table.header.item')}</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography>{t('store.inventory.table.header.price')}</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography>{t('store.inventory.table.header.date')}</Typography>
                        </TableCell>
                        <TableCell>{''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {storeTransactions.map((storeTransaction) => (
                        <PurchasedItem storeTransaction={storeTransaction} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
