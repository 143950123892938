const root = {
    '& .MuiTableCell-root:first-child': {
        paddingLeft: '24px',
    },

    '& .MuiTableCell-root:last-child': {
        paddingRight: '24px',
    },
};

export const MuiTableRow = {
    styleOverrides: {
        root,
    },
};
