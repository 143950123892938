import type { VFC } from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LANGUAGE } from 'types';
import { useBoundActions } from 'hooks';
import type { AppReducerState } from 'store';
import { logOutUser, rejectPolicy } from 'store/slices/user';
import { Divider, Fade, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { GdprRevokeConfirmationDialog } from '../GdprRevokeConfirmationDialog';
import { useStyles } from './styles';

const LANGUAGES = [
    { lng: LANGUAGE.EN, label: 'profile.settings.language.english.label' },
    { lng: LANGUAGE.DE, label: 'profile.settings.language.german.label' },
    { lng: LANGUAGE.SK, label: 'profile.settings.language.slovak.label' },
    { lng: LANGUAGE.SL, label: 'profile.settings.language.slovenian.label' },
    { lng: LANGUAGE.CS, label: 'profile.settings.language.czech.label' },
    { lng: LANGUAGE.HR, label: 'profile.settings.language.croatian.label' },
];

interface Props {
    hide?: boolean;
    sideMenuMode?: boolean;
}

export const ProfileDetailMenu: VFC<Props> = ({ hide = false, sideMenuMode = false }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [gdprRevokeConfirmationDialogOpen, setGdprRevokeConfirmationDialogOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [languagesMenuOpen, setLanguagesMenuOpen] = useState(false);
    const { policyInformation } = useSelector((state: AppReducerState) => state.user);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const classes = useStyles();
    const { logOutUserAction, rejectPolicyAction } = useBoundActions({
        logOutUser,
        rejectPolicy,
    });

    const handleMenuClick = (evt) => evt.stopPropagation();

    const handleMenuButtonClick = (evt) => {
        evt.stopPropagation();
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
        setLanguagesMenuOpen(false);
    };

    const handleChangeLanguage = (language) => () => {
        handleMenuClose();
        i18n.changeLanguage(language);
    };

    const revokeGdprConsent = async () => {
        await rejectPolicyAction(policyInformation?.activePolicySysId);
        await logOutUserAction();
        navigate('/login');
    };

    const handleLogOut = () => logOutUserAction();

    const handleGdprModalOnClose = () => setGdprRevokeConfirmationDialogOpen(false);

    const handleChangePassword = () => navigate('/password-change');

    const handleGenerateAuthenticatorCode = () => navigate('/generate-authenticator-code');

    if (hide) {
        return null;
    }

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuButtonClick}
                ref={menuButtonRef}
                size="large"
            >
                <MoreHoriz />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={menuButtonRef.current}
                keepMounted
                elevation={0}
                open={menuOpen}
                onClick={handleMenuClick}
                onClose={handleMenuClose}
                TransitionComponent={Fade}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {!sideMenuMode && (
                    <MenuItem onClick={handleChangePassword}>
                        {t('profile.settings.changePassword')}
                    </MenuItem>
                )}
                {!sideMenuMode && (
                    <MenuItem onClick={() => setGdprRevokeConfirmationDialogOpen(true)}>
                        {t('profile.settings.revokeConsent')}
                    </MenuItem>
                )}
                {!sideMenuMode && (
                    <MenuItem onClick={handleGenerateAuthenticatorCode}>
                        {t('profile.settings.generateAuthenticatorCode')}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        setLanguagesMenuOpen(true);
                    }}
                >
                    {t('profile.settings.changeLanguage')}
                </MenuItem>
                {languagesMenuOpen && (
                    <>
                        <Divider />
                        {LANGUAGES.map(({ lng, label }) => (
                            <MenuItem
                                key={lng}
                                sx={classes.languageOption}
                                onClick={handleChangeLanguage(lng)}
                            >
                                {t(label)}
                            </MenuItem>
                        ))}
                        <Divider />
                    </>
                )}
                <MenuItem onClick={handleLogOut}>{t('profile.settings.logOut.label')}</MenuItem>
            </Menu>
            {!sideMenuMode && (
                <GdprRevokeConfirmationDialog
                    open={gdprRevokeConfirmationDialogOpen}
                    onClose={handleGdprModalOnClose}
                    revokeGdprConsent={revokeGdprConsent}
                />
            )}
        </>
    );
};
