// import moment from 'moment';
import { mainApiResourceUrl } from '../constants';

export function getImagePath(imagePath) {
    if (imagePath) {
        return `${mainApiResourceUrl}/${imagePath}`;
    }

    return '/images/no-image.png';
}

export function getImageFromStoragePath(fileName) {
    const x = `${mainApiResourceUrl}/api/storages/${fileName}`;

    if (fileName) {
        return x;
    }

    return '/images/no-image.png';
}

export const toFtDatePickerDate = (date) => {
    // let x = moment().utc(date).format('DATE');
    // console.log('toFtDatePickerDate', x);
    return date;
};
