import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        datePicker: {
            width: '100%',
        },
        dateIntervalDialog: {
            '& .MuiFormControl-root': {
                marginBottom: theme.spacing(2),
            },
        },
        dateTo: {
            marginTop: theme.spacing(2),
        },
    };
};
