import { AXIS_ORIENTATION } from 'types';
import type { Margin, Scale } from 'types';

const TWO = 2;

export const getAxisMarginTranslate = (
    margin: Margin,
    orientation: AXIS_ORIENTATION,
    graphSize: { height: number; width: number },
): string => {
    if (orientation === AXIS_ORIENTATION.LEFT) {
        return `translate(${margin.left},0)`;
    }

    if (orientation === AXIS_ORIENTATION.RIGHT) {
        return `translate(${graphSize.width - margin.right},0)`;
    }

    if (orientation === AXIS_ORIENTATION.TOP) {
        return `translate(0,${margin.top})`;
    }

    return `translate(0,${graphSize.height - margin.bottom})`;
};

export const getTickTransform = <T,>(
    orientation: AXIS_ORIENTATION,
    scale: Scale<T>,
    tick: T,
): string => {
    let value;

    if (scale.bandwidth) {
        const offset = Number(scale.bandwidth()) / TWO;

        value = Number(scale(tick) || 0) + offset;
    } else {
        value = scale(tick);
    }

    if ([AXIS_ORIENTATION.TOP, AXIS_ORIENTATION.BOTTOM].includes(orientation)) {
        return `translate(${value},0)`;
    }

    return `translate(0,${value})`;
};

export const getAxisPath = <T,>(
    orientation: AXIS_ORIENTATION,
    scale: Scale<T>,
    tickWidth: number,
) => {
    const [min, max] = scale.range();
    const k = [AXIS_ORIENTATION.TOP, AXIS_ORIENTATION.LEFT].includes(orientation) ? -1 : 1;
    const isVertical = [AXIS_ORIENTATION.LEFT, AXIS_ORIENTATION.RIGHT].includes(orientation);

    if (isVertical) {
        return `M${k * tickWidth},${min}H${0}V${max}H${k * tickWidth}`;
    }

    return `M${min},${k * tickWidth}V${0}H${max}V${k * tickWidth}`;
};

export const getTickAnchor = (orientation: AXIS_ORIENTATION): string => {
    if (orientation === AXIS_ORIENTATION.RIGHT) {
        return 'start';
    }

    if (orientation === AXIS_ORIENTATION.LEFT) {
        return 'end';
    }

    return 'middle';
};

export const getAxisTicks = <T,>(scale: Scale<T>, numberOfTicks?: number, ticks?: T[]): T[] => {
    if (ticks) {
        return ticks;
    }

    if (scale.bandwidth) {
        return scale.domain();
    }

    if (!scale.ticks) {
        return [];
    }

    return scale.ticks(numberOfTicks);
};
