import type { FC } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { useStyles } from './styles';

const MIN = 0;
const MAX = 5;

interface Props {
    value: number;
    labelLeft: string;
    labelRight: string;
}

const normalize = (value) => ((value - MIN) * 100) / (MAX - MIN);

export const AvatarPersonalityBar: FC<Props> = ({ labelLeft, labelRight, value }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.label}>
                <Typography>{labelLeft}</Typography>
                <Typography>{labelRight}</Typography>
            </div>
            <LinearProgress
                variant="determinate"
                value={normalize(value)}
                sx={classes.progressBar}
            />
        </>
    );
};
