export enum ACHIEVEMENT_RARITY {
    BRONZE = 0,
    SILVER = 1,
    GOLD = 2,
    PLATINUM = 4,
    GROUP_GOLD = 5,
}

export interface Achievement {
    dateAchieved?: string;
    description: string;
    id: string;
    rarity: ACHIEVEMENT_RARITY;
    title: string;
    globalRate: number;
}

export interface AchievementGroup {
    title: string;
    achievements: Achievement[];
}
