import type { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import type { FormValidationError } from 'types';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';

interface Props {
    onEmailChange?: (...args: any[]) => void;
    email: string;
    emailError?: FormValidationError;
    hideAdornment?: boolean;
    useLabel?: boolean;
}

export const EmailInput: FC<Props> = ({
    onEmailChange = () => {},
    email,
    emailError,
    hideAdornment = false,
    useLabel = false,
}) => {
    const { t } = useTranslation();

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
        onEmailChange(sanitize(event.target.value, { USE_PROFILES: {} }));

    const startAdornment = hideAdornment ? null : (
        <InputAdornment position="start">
            <PersonOutlined />
        </InputAdornment>
    );

    const label = useLabel ? t<string>('form.email.placeholder') : undefined;
    const placeholder = useLabel ? undefined : t<string>('form.email.placeholder');

    return (
        <TextField
            type="text"
            placeholder={placeholder}
            label={label}
            value={email}
            autoComplete="email"
            onChange={handleEmailChange}
            InputProps={{
                startAdornment,
            }}
            size="small"
            error={!!emailError}
            helperText={emailError?.message}
            fullWidth
            variant="outlined"
        />
    );
};
