import { useSelector } from 'react-redux';
import type { AppReducerState } from 'store';
import type { GridSize } from '@mui/material';

interface ItemSizes {
    xs?: number | 'auto';
    sm?: number | 'auto';
    md?: number | 'auto';
    lg?: number | 'auto';
    xl?: number | 'auto';
}

interface GridItemSizesMap {
    open?: ItemSizes;
    closed?: ItemSizes;
}

interface GridItemSizes {
    xs: GridSize;
    sm: GridSize;
    md: GridSize;
    lg: GridSize;
    xl: GridSize;
}

const DEFAULT_ITEM_SIZES: GridItemSizesMap = {
    open: {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
    },
    closed: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
    },
};

export const useGridItemSizes = (
    _sizesMap: GridItemSizesMap = DEFAULT_ITEM_SIZES,
): GridItemSizes => {
    const { menuOpen } = useSelector(({ layout }: AppReducerState) => layout);
    const sizesMap = {
        open: {
            ...DEFAULT_ITEM_SIZES.open,
            ..._sizesMap.open,
        },
        closed: {
            ...DEFAULT_ITEM_SIZES.closed,
            ..._sizesMap.closed,
        },
    };

    return sizesMap[menuOpen ? 'open' : 'closed'] as GridItemSizes;
};
