import type { FC } from 'react';
import { useLayout } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Button, LinearProgress, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useStyles } from './styles';

const MAX_VALUE = 100;
const MINIMUM_STEPS = 3;

interface FlowStepperStep {
    label?: string;
    onClick?(evt?): void;
}

interface Props {
    activeStep: number;
    steps: FlowStepperStep[];
    onStepClick(step: number): void;
}

export const FlowStepper: FC<Props> = ({ activeStep, steps, onStepClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { isXs } = useLayout();

    const handleOnStepClick = (step, index) => () => {
        if (step.onClick) {
            step.onClick(step);
        }

        if (index < activeStep) {
            onStepClick(index);
        }
    };

    const handleGoBackClick = () => {
        if (activeStep > 0) {
            handleOnStepClick(steps[activeStep - 1], activeStep - 1)();
        }
    };

    const getStepValue = () => Math.ceil((MAX_VALUE / steps.length) * (activeStep + 1));

    if (steps.length < MINIMUM_STEPS) {
        return null;
    }

    return (
        <div>
            {!isXs && (
                <Stepper activeStep={activeStep} sx={classes.stepper} alternativeLabel>
                    {steps.map((step, index) => (
                        <Step
                            completed={activeStep > index}
                            onClick={handleOnStepClick(step, index)}
                        >
                            <StepLabel />
                        </Step>
                    ))}
                </Stepper>
            )}
            {isXs && (
                <LinearProgress
                    variant="determinate"
                    value={getStepValue()}
                    sx={classes.progress}
                />
            )}
            <div className={classes.stepLabelWrapper}>
                <div className={classes.stepArrow}>
                    {activeStep !== 0 && (
                        <Button
                            startIcon={<ChevronLeft />}
                            onClick={handleGoBackClick}
                            sx={classes.backButton}
                        >
                            {t('flowStepper.backButton.label')}
                        </Button>
                    )}
                </div>
                <div className={classes.stepLabel}>
                    <Typography align="center" component="h2" variant="h2">
                        {steps[activeStep].label || ''}
                    </Typography>
                </div>
                <div className={classes.stepArrow} />
            </div>
        </div>
    );
};
