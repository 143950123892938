import { memo } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions, CardHeader, CardMedia, Typography } from '@mui/material';
import { useGoBackFromNodal } from 'hooks';
import { useStyles } from './styles';

const MIN = 1;
const MAX = 21;

export const EvaluationSuccessCard: FC = memo(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const goBackFromNodal = useGoBackFromNodal();
    const random = Math.floor(Math.random() * (MAX - MIN + 1)) + MIN;
    const image = `/gifs/positive_${`000${random}`.slice(-3)}.gif`;

    return (
        <Card sx={classes.marginTop}>
            <CardHeader
                title={
                    <Typography variant="h6">
                        {t('evaluation.new.successStep.evaluationSuccessfullySent.label')}
                    </Typography>
                }
                sx={classes.header}
            />
            <CardMedia
                image={image}
                title={t('evaluation.new.successStep.title.label')}
                sx={classes.feedbackImage}
            />
            <CardActions>
                <Button variant="contained" onClick={goBackFromNodal}>
                    {t('evaluation.new.successStep.actions.done.label')}
                </Button>
            </CardActions>
        </Card>
    );
});
