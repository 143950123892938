import moment from 'moment';
import { LANGUAGE } from 'types';

export const general = {
    delay: 200,
};

export const apiUrl = process.env.REACT_APP_SERVICE_URI;
export const mainApiResourceUrl = process.env.REACT_APP_MAIN_API_RESOURCE_URI;
export const mainApiUrl = process.env.REACT_APP_MAIN_API_URI;

export const api = {
    appCodes: `${apiUrl}/app-codes`,
    authentications: `${apiUrl}/authentications`,
    avatars: `${apiUrl}/avatars`,
    avatarAdvices: `${apiUrl}/avatar-advices`,
    companies: `${apiUrl}/companies`,
    competencies: `${apiUrl}/competencies`,
    employees: `${apiUrl}/employees`,
    experienceLevels: `${apiUrl}/exp-levels`,
    identities: `${apiUrl}/identities`,
    jobs: `${apiUrl}/jobs`,
    jobFamilies: `${apiUrl}/job-families`,
    jobTeamRoles: `${apiUrl}/job-team-roles`,
    notifications: `${apiUrl}/notifications`,
    objectives: `${apiUrl}/objectives`,
    performanceCycles: `${apiUrl}/perf-cycles`,
    performanceCycleEmplEvaluations: `${apiUrl}/perf-cycle-empl-evals`,
    priorities: `${apiUrl}/priorities`,
    relationEmployeeJobs: `${apiUrl}/rel-employee-jobs`,
    relationEmployeeManagers: `${apiUrl}/rel-employee-managers`,
    relationEmployeeTeams: `${apiUrl}/rel-employee-teams`,
    resxs: `${apiUrl}/resxs`,
    rewardSystems: `${apiUrl}/reward-systems`,
    scales: `${apiUrl}/scales`,
    storeItems: `${apiUrl}/store-items`,
    storeTransactions: `${apiUrl}/store-transactions`,
    surveys: `${apiUrl}/surveys`,
    systemInstances: `${apiUrl}/system-instances`,
    teams: `${apiUrl}/teams`,
    userRoles: `${apiUrl}/user-roles`,
    import: `${apiUrl}/imports`,
    reports: `${apiUrl}/reports`,
    questSettings: `${apiUrl}/quest-settings`,
};

export const paths = {
    avatarAdvices: 'avatarAdvices',
    companyQuests: 'companyQuests',
    systemInstances: 'systemInstances',
    companies: 'companies',
    companySettings: 'companySettings',
    feedbackAvatars: 'feedbackAvatars',
    users: 'users',
    userRoles: 'userRoles',
    managers: 'managers',
    experienceLevels: 'experienceLevels',
    competencies: 'competencies',
    jobs: 'jobs',
    rewardSystem: 'rewardSystem',
    storeItems: 'storeItems',
    teams: 'teams',
    jobTeamRoles: 'jobTeamRoles',
    employeeTeams: 'employeeTeams',
    employeeJobs: 'employeeJobs',
    storeOrders: 'storeOrders',
    perfCycles: 'perfCycles',
    perfCycleEmplEvals: 'perfCyclesEmplEvals',
    priorities: 'priorities',
    surveys: 'surveys',
    scales: 'scales',
    jobFamilies: 'jobFamilies',
    userImport: 'userImport',
    revokedConsent: 'revokedConsent',
    securitySettings: 'securitySettings',
    questSettings: 'questSettings',
    monthlyActiveUsers: 'monthlyActiveUsers',
};

export const languages = [
    {
        id: 'en',
        displayNameKey: 'profile.settings.language.english.label',
        flagPath: 'images/flags/kingdom_united.png',
    },
    {
        id: 'de',
        displayNameKey: 'profile.settings.language.german.label',
        flagPath: 'images/flags/germany.png',
    },
    {
        id: 'sk',
        displayNameKey: 'profile.settings.language.slovak.label',
        flagPath: 'images/flags/slovakia.png',
    },
    {
        id: 'cz',
        displayNameKey: 'profile.settings.language.czech.label',
        flagPath: 'images/flags/czech_republic.png',
    },
    {
        id: 'hr',
        displayNameKey: 'profile.settings.language.croatian.label',
        flagPath: 'images/flags/croatia.png',
    },
];

export const maxDate = moment({ year: 9999, month: 11, day: 31 });

const C = {
    general,
    api,
    paths,
    languages,
    maxDate,
};

export default C;
