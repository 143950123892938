import type { VFC } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import type { AppReducerState } from 'store';
import type { Competency } from 'types';
import { CompanyValueCard } from './CompanyValueCard';

const NUM_VALUES_PER_ROW = 2;

export const CompanyValues: VFC = () => {
    const { allCompetencies } = useSelector(({ competencies }: AppReducerState) => competencies);

    if (!allCompetencies) {
        return null;
    }

    const competenciesPairs = allCompetencies.reduce((acc, employee, index, array) => {
        if (index % NUM_VALUES_PER_ROW === 0) {
            return [...acc, [array[index], array[index + 1]]];
        }

        return acc;
    }, [] as Competency[][]);

    return (
        <Grid container spacing={2}>
            {competenciesPairs.map(([first, second]) => (
                <Grid item key={`${first?.compSysId}${second?.compSysId}`} xs={12}>
                    <Grid container spacing={2}>
                        {first && (
                            <Grid
                                item
                                key={first.compSysId}
                                xs={12}
                                md={allCompetencies.length > 1 ? 6 : 12}
                            >
                                <CompanyValueCard competency={first} />
                            </Grid>
                        )}
                        {second && (
                            <Grid item key={second.compSysId} xs={12} md={6}>
                                <CompanyValueCard competency={second} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};
