import axios from 'axios';
import type {
    IApiResponse,
    ICompetencyView,
    ISearch,
    ISearchView,
    IUpsertCompetencyCommand,
} from 'types';
import { api, mainApiUrl } from '../constants';

const controllerApi = api.competencies;

interface CompetencySearch extends ISearch {
    performanceCycleSysId: string;
}

export const getCompetencies = async (
    queryParams: CompetencySearch,
): Promise<ISearchView<ICompetencyView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const getBySysId = async (competencySysId: string): Promise<ICompetencyView> => {
    return axios.get(`${controllerApi}/${competencySysId}`).then((res) => res.data);
};

export const create = async (data: IUpsertCompetencyCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (id: string, data: IUpsertCompetencyCommand): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const bulkImportValidation = async ({ bulkImportData }) => {
    return axios.put(`${controllerApi}/bulkImportValidate`, {
        bulkImportCompetencies: bulkImportData,
    });
};

export const bulkImport = async ({ bulkImportData }) => {
    return axios.put(`${controllerApi}/bulkImportValidate`, {
        importTheData: true,
        bulkImportCompetencies: bulkImportData,
    });
};

export const updatePhoto = async ({ id, base64Image }) => {
    return axios.put(`${mainApiUrl}/Competency/photo`, {
        competencySysId: id,
        base64Image,
    });
};

export const deleteCompetencies = async ({ ids }) => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
