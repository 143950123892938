import { memo } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions, CardHeader, CardMedia, Typography } from '@mui/material';
import { ADVICE_SENTIMENT } from 'types';
import type { AvatarAdvice } from 'types';
import { useGoBackFromNodal } from 'hooks';
import { useStyles } from './styles';

const MIN = 1;
const MAX = 21;

interface Props {
    advice: AvatarAdvice;
    createNewFeedback: () => void;
}

export const FeedbackSuccessCard: FC<Props> = memo(({ advice, createNewFeedback }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const goBackFromNodal = useGoBackFromNodal();
    const random = Math.floor(Math.random() * (MAX - MIN + 1)) + MIN;
    const feedbackType = advice.sentiment === ADVICE_SENTIMENT.POSITIVE ? 'positive' : 'negative';
    const image = `/gifs/${feedbackType}_${`000${random}`.slice(-3)}.gif`;

    return (
        <Card sx={classes.marginTop}>
            <CardHeader
                title={
                    <Typography variant="h6">
                        {t('feedback.new.successStep.feedbackSuccessfullySent.label')}
                    </Typography>
                }
                sx={classes.header}
            />
            <CardMedia
                image={image}
                title={t('feedback.new.successStep.title.label')}
                sx={classes.feedbackImage}
            />
            <CardActions>
                <Button variant="outlined" onClick={createNewFeedback}>
                    {t('feedback.new.successStep.actions.writeAnother.label')}
                </Button>
                <Button variant="contained" onClick={goBackFromNodal}>
                    {t('feedback.new.successStep.actions.done.label')}
                </Button>
            </CardActions>
        </Card>
    );
});
