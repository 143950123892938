import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.managers;

const ManagerLoader = Loadable({
    loader: () => import('views/admin/EmployeeManager'),
    loading: Loading,
    render(loaded, props) {
        const { EmployeeManager } = loaded;
        return <EmployeeManager {...props} />;
    },
    delay: general.delay,
});

const ManagerEditLoader = Loadable({
    loader: () => import('views/admin/EmployeeManager/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const managerRoute = {
    path: `/${mainRoute}`,
    component: ManagerLoader,
};

export const managerCreateRoute = {
    path: `/${mainRoute}/create`,
    component: ManagerEditLoader,
};

export const managerEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: ManagerEditLoader,
};
