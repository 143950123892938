import { useCallback } from 'react';
import type { FC } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { TextField, Typography } from '@mui/material';
import { sanitize } from 'dompurify';
import type { Question } from 'types';
import { useStyles } from '../common/question.styles';

interface Props {
    className?: string;
    question: Question;
    showAsAnswer?: boolean;
    globalValidation?: boolean;
}

export const TextQuestion: FC<Props> = ({
    className,
    question,
    showAsAnswer = false,
    globalValidation = false,
}) => {
    const { id, mandatory, questionText } = question;
    const classes = useStyles();
    const { t } = useTranslation();
    const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
        type: 'text',
        name: id,
        validate: (text) => {
            return mandatory && !text
                ? t<string>('form.textQuestion.validation.valueMissing.label')
                : undefined;
        },
    });
    const shouldShowError = (touched || globalValidation) && !!error;

    const onQuestionChange = useCallback(
        (evt) => setValue(sanitize(evt.target.value, { USE_PROFILES: {} })),
        [setValue],
    );

    const onQuestionBlur = useCallback(() => setTouched(true), [setTouched]);

    return (
        <div className={cx(classes.question, className)}>
            <Typography sx={classes.title}>
                <strong>{questionText}</strong>
            </Typography>
            {!showAsAnswer && (
                <TextField
                    type="text"
                    fullWidth
                    value={sanitize(value, { USE_PROFILES: {} })}
                    size="small"
                    onChange={onQuestionChange}
                    onBlur={onQuestionBlur}
                    error={shouldShowError}
                    helperText={shouldShowError ? error : ''}
                />
            )}
            {showAsAnswer && <Typography>{sanitize(value, { USE_PROFILES: {} })}</Typography>}
        </div>
    );
};
