import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        height100: {
            height: '100%',
        },
        title: {
            color: colors.grey,
            textTransform: 'uppercase',
        },
        legend: {
            '& .MuiGrid-item': {
                display: 'flex',
                justifyContent: 'center',
            },
        },
        chart: css({
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }),
        positive: {
            color: colors.peach,
        },
        negative: {
            color: colors.teal,
        },
    };
};
