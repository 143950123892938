import axios from 'axios';
import type { IApiResponse, ISystemInstanceUpsert, ISystemInstanceView } from 'types';
import { api } from '../constants';

export const get = async (): Promise<ISystemInstanceView[]> => {
    return axios.get(`${api.systemInstances}`).then((res) => res.data);
};

export const getBySysId = async (systemInstanceSysId: string): Promise<ISystemInstanceView> => {
    return axios.get(`${api.systemInstances}/${systemInstanceSysId}`).then((res) => res.data);
};

export const create = async (data: ISystemInstanceUpsert): Promise<IApiResponse> => {
    return axios.post(`${api.systemInstances}`, data);
};

export const update = async (
    systemInstanceSysId: string,
    data: ISystemInstanceUpsert,
): Promise<IApiResponse> => {
    return axios.put(`${api.systemInstances}/${systemInstanceSysId}`, data);
};
