import _ from 'lodash';
import { useCallback, useState, useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import { ADVICE_SENTIMENT, FEEDBACK_CHARACTER_TYPE, FEEDBACK_SEEN_STATUS } from 'types';
import type { Feedback } from 'types';
import moment from 'moment';
import { Avatar, Badge, IconButton, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { Mood, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiHeartBroken } from '@mdi/js';
import { EmployeeAvatar } from 'components';
import { useLayout } from 'hooks';
import { api, callService } from 'services';
import { Coins } from '../../Coins';
import { Experience } from '../../Experience';
import { FeedbackShareButton } from '../FeedbackShareButton';
import { RecipientsList } from '../../activityStream';
import { useStyles } from './styles';
import { useStyles as useCommonStyles } from '../common/styles';

const BUTTON_DEBOUNCE = 500;
const EDIT_TIME_MINUTES = 5;

interface Props {
    feedback: Feedback;
    onFeedbackRated: () => void;
}

export const FeedbackListReceivedRow: FC<Props> = ({ feedback, onFeedbackRated }) => {
    const dateTimeRated = moment(feedback.helpfulDateCreated);
    const dateTimeNow = moment();
    const [isChangeRatingEnabled, setIsChangeRatingEnabled] = useState(
        !feedback.helpfulDateCreated ||
            dateTimeRated.isAfter(dateTimeNow.subtract(EDIT_TIME_MINUTES, 'minutes')),
    );
    const { t } = useTranslation();
    const { isXs } = useLayout();
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    let disableRatingTimer;

    useEffect(() => {
        return () => {
            clearTimeout(disableRatingTimer);
        };
    }, []);

    const handleFeedbackClick = useCallback(
        (helpful) =>
            _.debounce(
                async () => {
                    if (feedback.helpful !== helpful) {
                        const { error } = await callService({
                            api: api.updateFeedbackHelpfull,
                            params: [feedback.feedAdviceSysId],
                            body: { helpful },
                        });

                        if (!error) {
                            onFeedbackRated();
                        }
                    }
                },
                BUTTON_DEBOUNCE,
                {
                    leading: true,
                    trailing: false,
                },
            ),
        [feedback, onFeedbackRated],
    );

    if (isChangeRatingEnabled && feedback.helpfulDateCreated) {
        const timeDiff = dateTimeRated.diff(dateTimeNow);

        if (timeDiff) {
            disableRatingTimer = setTimeout(() => setIsChangeRatingEnabled(false), timeDiff);
        }
    }

    const ThumbUpIcon = feedback.helpful ? ThumbUp : ThumbUpOutlined;
    const ThumbDownIcon =
        feedback.helpful != null && !feedback.helpful ? ThumbDown : ThumbDownOutlined;

    return (
        <TableRow>
            {!isXs && (
                <TableCell className={commonClasses.employeeAvatarWrapper} align="left">
                    {feedback.feedbackCharacterType === FEEDBACK_CHARACTER_TYPE.AVATAR && (
                        <EmployeeAvatar
                            sx={commonClasses.characterAvatar}
                            alt={feedback.adviceFromTitle}
                            src={feedback.adviceFromPhotoPath}
                        />
                    )}
                    {feedback.feedbackCharacterType === FEEDBACK_CHARACTER_TYPE.ADVENTURER && (
                        <EmployeeAvatar
                            alt={feedback.adviceFromTitle}
                            sx={commonClasses.employeeAvatar}
                            employeeId={feedback.feedbackToEntities?.[0].feedbackToSysId}
                            src={feedback.adviceFromPhotoPath}
                        />
                    )}
                </TableCell>
            )}
            <TableCell>
                <Typography variant="caption">
                    {moment(feedback.dateCreated).format('DD.MM.YYYY')}
                    {` • ${
                        feedback.competencyTitle ||
                        sanitize(feedback.topic, { USE_PROFILES: {} }) ||
                        t('myFeedback.feedbackListReceivedRow.openTopic.label')
                    }`}
                    {feedback.feedStatusSeen === FEEDBACK_SEEN_STATUS.NOT_SEEN && (
                        <Badge
                            badgeContent={t('myFeedback.feedbackListReceivedRow.badgeNew.label')}
                            color="primary"
                            sx={classes.badge}
                        >
                            {''}
                        </Badge>
                    )}
                </Typography>
                <Typography>
                    {feedback.adviceSentence}
                    {feedback.adviceSentiment === ADVICE_SENTIMENT.POSITIVE ? (
                        <Mood sx={commonClasses.good} />
                    ) : (
                        <Icon path={mdiHeartBroken} className={commonClasses.bad} />
                    )}
                </Typography>
                <Typography variant="caption" component="p">
                    {feedback.adviceFromTitle}
                </Typography>
                {feedback.note && (
                    <Paper elevation={0} className={commonClasses.note}>
                        <Typography>
                            <strong>{t('myFeedback.feedbackNote.startingLabel.label')}</strong>{' '}
                            {sanitize(feedback.note, { USE_PROFILES: {} })}
                        </Typography>
                    </Paper>
                )}
                <div className={classes.actionsWrapper}>
                    <div className={classes.action}>
                        <Typography
                            component="span"
                            variant="caption"
                            sx={{
                                ...classes.helpfulQuestion,
                                ...(feedback.helpful ? commonClasses.teal : {}),
                                ...(feedback.helpful != null && !feedback.helpful
                                    ? commonClasses.orange
                                    : {}),
                            }}
                        >
                            {t('myFeedback.feedbackListReceivedRow.helpfulQuestion.label')}
                        </Typography>
                        <IconButton
                            aria-label={t<string>(
                                'myFeedback.feedbackListReceivedRow.actions.helpful.aria',
                            )}
                            disabled={!isChangeRatingEnabled}
                            onClick={handleFeedbackClick(true)}
                            size="small"
                            sx={{
                                ...classes.helpfulIcon,
                                ...(feedback.helpful != null && !feedback.helpful
                                    ? commonClasses.grey
                                    : {}),
                                ...(feedback.helpful ? commonClasses.teal : {}),
                            }}
                        >
                            <ThumbUpIcon />
                        </IconButton>
                        <IconButton
                            aria-label={t<string>(
                                'myFeedback.feedbackListReceivedRow.actions.notHelpful.aria',
                            )}
                            disabled={!isChangeRatingEnabled}
                            size="small"
                            onClick={handleFeedbackClick(false)}
                            sx={{
                                ...classes.helpfulIcon,
                                ...(feedback.helpful != null && !feedback.helpful
                                    ? commonClasses.grey
                                    : {}),
                                ...(feedback.helpful != null && !feedback.helpful
                                    ? commonClasses.orange
                                    : {}),
                            }}
                        >
                            <ThumbDownIcon />
                        </IconButton>
                        {feedback.helpfulDateCreated && isChangeRatingEnabled && (
                            <Typography variant="caption" component="span" sx={classes.ratedOn}>
                                {t('myFeedback.feedbackListReceivedRow.ratedNow.label', {
                                    minutes: EDIT_TIME_MINUTES,
                                })}
                            </Typography>
                        )}
                        {feedback.helpfulDateCreated && !isChangeRatingEnabled && (
                            <Typography variant="caption" component="span" sx={classes.ratedOn}>
                                {t('myFeedback.feedbackListReceivedRow.ratedOn.label', {
                                    dateTime: dateTimeRated.format('DD.MM.YYYY HH:mm'),
                                })}
                            </Typography>
                        )}
                    </div>
                    <FeedbackShareButton feedback={feedback} onFeedbackShared={onFeedbackRated} />
                </div>
            </TableCell>
            <TableCell className={commonClasses.characterAvatarWrapper} align="right">
                <div className={commonClasses.recipientsWrapper}>
                    <RecipientsList
                        entitiesTo={feedback.feedbackToEntities}
                        className={commonClasses.recipientsList}
                    />
                    <Coins value={feedback.employeeReceivedCoins} displaySign />
                    <Experience value={feedback.employeeReceivedPoints} />
                </div>
            </TableCell>
        </TableRow>
    );
};
