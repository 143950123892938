import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.jobFamilies;

const JobFamilyLoader = Loadable({
    loader: () => import('views/admin/JobFamily'),
    loading: Loading,
    render(loaded, props) {
        const { JobFamily } = loaded;
        return <JobFamily {...props} />;
    },
    delay: general.delay,
});

const JobFamilyEditLoader = Loadable({
    loader: () => import('views/admin/JobFamily/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const jobFamilyRoute = {
    path: `/${mainRoute}`,
    component: JobFamilyLoader,
};

export const jobFamilyCreateRoute = {
    path: `/${mainRoute}/create`,
    component: JobFamilyEditLoader,
};

export const jobFamilyEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: JobFamilyEditLoader,
};
