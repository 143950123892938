import type { FC, MouseEvent } from 'react';
import moment from 'moment';
import { Chip, IconButton, TableCell } from '@mui/material';
import { InformationTooltip } from 'components';
import type { TooltipColor } from 'types';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { FTDeleteAction } from '../FTDeleteAction';
import { useStyles } from './styles';

interface Props {
    column: {
        propName: string;
        title: string;
        type?: string;
        align?: 'right' | 'center' | undefined;
        badgeText?: (value: string, row: any) => string;
        color?: (value: string, row: any) => any;
        tooltip?: (value: string, row: any) => string;
        tooltipColor?: (value: string, row: any) => TooltipColor;
        tooltipText?: (value: string, row: any) => string;
        onClick?: (row: any) => void;
    };
    row: any;
    value: any;
}

export const TCell: FC<Props> = ({ column, row, value }) => {
    const classes = useStyles();
    let valueComponent = value;
    const { badgeText, color, type, tooltip, tooltipColor, tooltipText } = column;

    if (type === 'date') {
        valueComponent = moment(value).format('L');
    }

    if (type === 'dateTime') {
        valueComponent = moment(value).format('L hh:mm:ss');
    }

    if (type === 'percentage') {
        valueComponent = `${value}%`;
    }

    if (type === 'badge') {
        const badgeColor = color?.(value, row) || 'primary';

        valueComponent = (
            <div className={classes.chipWrapper}>
                <Chip label={badgeText?.(value, row) || value} color={badgeColor} />
                <InformationTooltip
                    text={tooltipText?.(value, row)}
                    tooltip={tooltip?.(value, row)}
                    color={tooltipColor?.(value, row)}
                    className={classes.chipTooltip}
                />
            </div>
        );
    }

    if (type === 'delete') {
        return (
            <TableCell align={column.align} sx={classes.actionButton}>
                <FTDeleteAction onConfirm={column.onClick} row={row} />
            </TableCell>
        );
    }

    return <TableCell align={column.align}>{valueComponent}</TableCell>;
};
