import { hexToRgba } from './themeService';
import { frankyTalesTheme } from './frankyTalesTheme';

const { colors } = frankyTalesTheme;

const root = {
    background: hexToRgba(colors.lightBlue),

    '& .MuiTypography-root': {
        textTransform: 'uppercase',
        color: colors.grey,
        fontSize: '0.75rem',
        fontWeight: 600,
        whiteSpace: 'nowrap',
    },
};

export const MuiTableHead = {
    styleOverrides: {
        root,
    },
};
