import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, TableCell, TableRow } from '@mui/material';
import type { TooltipColor } from 'types';
import { TCell } from './TCell';
import { useStyles } from './TRow.styles';

interface Props {
    columns: {
        propName: string;
        title: string;
        type?: string;
        align?: 'right' | 'center' | undefined;
        badgeText?: (value: string, row: any) => string;
        color?: (value: string, row: any) => any;
        tooltip?: (value: string, row: any) => string;
        tooltipColor?: (value: string, row: any) => TooltipColor;
        tooltipText?: (value: string, row: any) => string;
        onClick?: (item: any) => void;
    }[];
    idProp?: string;
    row: any;
    selectedItems?: any[];
    selectItem?: (e: any, id: any) => any;
    selectAllItems?: (e: any) => any;
    handleEdit?: (id: any) => any;
    renderCollapsible?: (row: any) => ReactNode;
}

export const TRow: FC<Props> = ({
    columns,
    idProp = 'id',
    handleEdit,
    row,
    selectedItems,
    selectItem,
    selectAllItems,
    renderCollapsible,
}) => {
    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const classes = useStyles(!!handleEdit);
    const id = row[idProp];
    const { t } = useTranslation();

    const handleOnClick = () => {
        handleEdit?.(id);

        if (renderCollapsible) {
            setIsCollapseOpen(!isCollapseOpen);
        }
    };

    return (
        <>
            <TableRow onClick={handleOnClick} sx={classes.row}>
                {selectAllItems && selectItem && selectedItems && (
                    <TableCell>
                        <input
                            type="checkbox"
                            onClick={(e) => selectItem(e, row[idProp])}
                            checked={selectedItems?.indexOf(id) > -1}
                        />
                    </TableCell>
                )}
                {columns.map((c) => (
                    <TCell
                        key={`${id}-${c.propName}`}
                        column={c}
                        value={row[c.propName]}
                        row={row}
                    />
                ))}
            </TableRow>
            {renderCollapsible && (
                <TableRow>
                    <TableCell colSpan={columns.length} sx={classes.collapsibleCell}>
                        <Collapse in={isCollapseOpen} unmountOnExit>
                            {renderCollapsible(row)}
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};
