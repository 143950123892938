import { useRef, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    FEEDBACK_STEP_TYPE,
    FEEDBACK_TARGET_TYPE,
    KeyResultAssignedTo,
    PLAYER_SEARCH_RESULT_FEATURE_TYPE,
    PlayerSearchResultMode,
    PlayerSearchResultType,
    PROFILE_TAB_TYPE,
} from 'types';
import type { PlayerFeatures, PlayerSearchResult } from 'types';
import { useBoundActions } from 'hooks';
import { setFeedbackPreFillData } from 'store/slices/feedback';
import { setProfilePreFillData } from 'store/slices/profile';
import { setQuestPreFillData } from 'store/slices/quest';
import { api, callService } from 'services';
import { Divider, Fade, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { useStyles } from './styles';
import { PlayerStats } from '../../profile/PlayerStats';

const FEATURE_TRANSLATION_KEYS = {
    [PLAYER_SEARCH_RESULT_FEATURE_TYPE.CREATE_QUEST]:
        'playerSearch.playerSearchResultMenu.actions.createQuest.label',
    [PLAYER_SEARCH_RESULT_FEATURE_TYPE.GIVE_FEEDBACK]:
        'playerSearch.playerSearchResultMenu.actions.giveFeedback.label',
    [PLAYER_SEARCH_RESULT_FEATURE_TYPE.EVALUATE]:
        'playerSearch.playerSearchResultMenu.actions.evaluate.label',
    [PLAYER_SEARCH_RESULT_FEATURE_TYPE.SELF_EVALUATE]:
        'playerSearch.playerSearchResultMenu.actions.selfEvaluate.label',
};

const QUERY_CONTROLLER = {
    [PlayerSearchResultType.PLAYER]: 'employeeController',
    [PlayerSearchResultType.JOB]: 'jobController',
    [PlayerSearchResultType.TEAM]: 'teamController',
};

const QUERY_PARAMS = {
    [PlayerSearchResultType.PLAYER]: 'employeeId',
    [PlayerSearchResultType.JOB]: 'jobId',
    [PlayerSearchResultType.TEAM]: 'teamId',
};

interface Props {
    player: PlayerSearchResult;
    mode: PlayerSearchResultMode;
    type: PlayerSearchResultType;
}

export const PlayerSearchResultMenu: FC<Props> = ({ mode, player, type }) => {
    const [actionsMenuOpen, setActionsMenuOpen] = useState(false);
    const [playerFeatures, setPlayerFeatures] = useState<PlayerFeatures>();
    const actionsMenuButtonRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const { setFeedbackPreFillDataAction, setQuestPreFillDataAction, setProfilePreFillDataAction } =
        useBoundActions({
            setFeedbackPreFillData,
            setQuestPreFillData,
            setProfilePreFillData,
        });

    const handleActionsMenuClose = () => setActionsMenuOpen(false);

    const handleOnMenuButtonClick = async () => {
        setPlayerFeatures(undefined);
        setActionsMenuOpen(true);

        const { error, payload } = await callService({
            api: api[QUERY_CONTROLLER[type]].getFeatures,
            query: { [QUERY_PARAMS[type]]: player.id },
        });

        if (!error && payload) {
            setPlayerFeatures(payload);
        }
    };

    const handleActionMenuItemClick = (feature) => () => {
        if (feature === PLAYER_SEARCH_RESULT_FEATURE_TYPE.CREATE_QUEST) {
            setQuestPreFillDataAction({
                assignedTo: [
                    {
                        group: t('quest.addPlayerStep.form.assignTo.groups.employees.label'),
                        type: KeyResultAssignedTo.EMPLOYEE,
                        name: player.displayName,
                        id: player.id,
                        profilePhoto: player.profilePhoto,
                    },
                ],
            });
            navigate('/quest/new');
        }

        if (feature === PLAYER_SEARCH_RESULT_FEATURE_TYPE.GIVE_FEEDBACK) {
            setFeedbackPreFillDataAction({
                step: FEEDBACK_STEP_TYPE.COMPETENCY,
                profileData: {
                    id: player.id,
                    displayName: player.displayName,
                    type: FEEDBACK_TARGET_TYPE.EMPLOYEE,
                    initials: player.initials,
                    profilePhoto: player.profilePhoto,
                },
            });
            navigate('/feedback/new');
        }

        if (feature === PLAYER_SEARCH_RESULT_FEATURE_TYPE.SELF_EVALUATE) {
            if (!playerFeatures?.perfCycleEmployeeEvaluationId) {
                setProfilePreFillDataAction({ tab: PROFILE_TAB_TYPE.EVALUATION });
                navigate(`/profile/${player.id}`);

                return;
            }

            navigate(
                `/player/assessment/self/${player.id}/${playerFeatures.perfCycleEmployeeEvaluationId}`,
            );
        }

        if (feature === PLAYER_SEARCH_RESULT_FEATURE_TYPE.EVALUATE) {
            if (!playerFeatures?.perfCycleEmployeeEvaluationId) {
                setProfilePreFillDataAction({ tab: PROFILE_TAB_TYPE.EVALUATION });
                navigate(`/profile/${player.id}`);

                return;
            }

            navigate(
                `/player/assessment/${player.id}/${playerFeatures.perfCycleEmployeeEvaluationId}`,
            );
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            onClick={(evt) => {
                evt.stopPropagation();
            }}
            onKeyUp={(evt) => {
                evt.stopPropagation();
            }}
        >
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleOnMenuButtonClick}
                ref={actionsMenuButtonRef}
                size="large"
            >
                <MoreHoriz />
            </IconButton>
            <Menu
                id={`actions-menu-${player.id}`}
                anchorEl={actionsMenuButtonRef.current}
                open={actionsMenuOpen}
                onClose={handleActionsMenuClose}
                TransitionComponent={Fade}
            >
                {player.features?.map((feature) => (
                    <MenuItem
                        key={`actions-menu-${player.id}-${feature}`}
                        onClick={handleActionMenuItemClick(feature)}
                    >
                        {t(FEATURE_TRANSLATION_KEYS[feature])}
                    </MenuItem>
                ))}
                {!playerFeatures && (
                    <div>
                        <Skeleton variant="rectangular" sx={classes.skeleton} />
                        <Skeleton variant="rectangular" sx={classes.skeleton} />
                    </div>
                )}
                {playerFeatures?.features?.map((feature) => (
                    <MenuItem
                        key={`actions-menu-${player.id}-${feature}`}
                        onClick={handleActionMenuItemClick(feature)}
                    >
                        {t(FEATURE_TRANSLATION_KEYS[feature])}
                    </MenuItem>
                ))}
                {mode === PlayerSearchResultMode.TABLE &&
                    type === PlayerSearchResultType.PLAYER && (
                        <div>
                            <Divider sx={classes.divider} />
                            <PlayerStats player={player} />
                        </div>
                    )}
            </Menu>
        </div>
    );
};
