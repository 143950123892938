import { memo, useEffect } from 'react';
import cx from 'classnames';
import { useImage } from 'hooks';
import { Loading } from '../Loading';

interface Props {
    alt?: string;
    className?: string;
    onError?: () => void;
    src: string;
}

export const Image = memo(({ alt, className, onError, src }: Props) => {
    const { cancelServiceCall, error, isLoading, image } = useImage(src);

    useEffect(() => {
        return () => {
            cancelServiceCall();
        };
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        onError?.();
        return null;
    }

    if (!image) {
        return null;
    }

    return <img src={image} alt={alt || src} className={cx(className)} />;
});
