import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        gridContent: {
            display: 'flex',
            textTransform: 'uppercase',
            minHeight: theme.spacing(16),
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
};
