import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        maxWidthXs: css({
            margin: 'auto',
            maxWidth: theme.spacing(50),
        }),
        stepper: {
            backgroundColor: 'transparent',
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(1),

            '& .MuiStepIcon-active': {
                color: colors.blue,
            },

            '& .Mui-disabled': {
                '& svg': {
                    border: `1px solid ${colors.grey}`,
                    borderRadius: theme.spacing(3),
                    color: 'transparent',
                },
                '& .MuiStepIcon-text': {
                    fill: colors.grey,
                },
            },
        },
        stepTitle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        progress: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            height: theme.spacing(1),
            borderRadius: theme.spacing(1 / 2),
        },
        stepTitleContainer: {
            marginBottom: theme.spacing(2),

            '& .MuiGrid-item': {
                height: theme.spacing(5),
            },
        },
        backArrow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        titleTopMargin: {
            marginTop: theme.spacing(4),
        },
    };
};
