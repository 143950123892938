export enum EvaluationBy {
    SELF = 'Employee',
    LEADER = 'Lead',
}

export enum EvaluationStatus {
    DRAFT = 'Draft',
    PUBLISHED = 'Active',
}

export enum EmployeeEvaluationStatus {
    NOT_STARTED = 'NotStarted',
    IN_PROGRESS = 'InProgress',
    FINISHED = 'Finished',
    EXPIRED = 'Expired',
}

export enum PerformanceCycleEmployeeEvaluationFeatures {
    EVALUATE = 'Evaluate',
    SELF_EVALUATE = 'Selfevaluate',
}

export interface PerformanceCycleEmployeeEvaluation {
    performanceCycleEmployeeEvaluationId: string;
    dateFrom: string;
    dateTo: string;
    status: EmployeeEvaluationStatus;
    features: PerformanceCycleEmployeeEvaluationFeatures[];
    title: string;
}
