import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import { PlayerFilter } from '../../playerSearch';
import { useStyles } from './styles';

export const PlayerSearch: FC = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { t } = useTranslation();

    const handleViewMoreClick = () => navigate('/search');

    return (
        <TableContainer component={Paper} sx={classes.tableContainer}>
            <Toolbar sx={classes.header}>
                <Typography variant="h4" component="h2">
                    {t('dashboard.playerSearch.title.label')}
                </Typography>
            </Toolbar>
            <Divider />
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={1}>
                            <PlayerFilter onSearch={() => {}} />
                            <Typography sx={classes.description}>
                                {t('dashboard.playerSearch.description.label')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={1} sx={classes.tableActions}>
                            <Button onClick={handleViewMoreClick}>
                                {t('dashboard.playerSearch.actions.viewMore.label')}
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};
