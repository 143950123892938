const root = {
    '&.biggerPadding .MuiDialogTitle-root': {
        padding: '24px',
    },

    '&.bigPadding .MuiDialogTitle-root': {
        padding: '32px',
    },

    '&.biggerPadding .MuiDialogContent-root': {
        padding: '24px',
    },

    '&.bigPadding .MuiDialogContent-root': {
        padding: '32px',
    },

    '&.biggerPadding .MuiDialogActions-root': {
        padding: '24px',
    },

    '&.bigPadding .MuiDialogActions-root': {
        padding: '32px',
    },
};

export const MuiDialog = {
    styleOverrides: {
        root,
    },
};
