import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        avatar: {
            marginRight: theme.spacing(1),
        },
        avatarWrapper: css({
            display: 'flex',
        }),
    };
};
