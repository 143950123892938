import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        avatarWrapper: css({
            display: 'flex',
            justifyContent: 'center',
        }),
        bellIcon: {
            width: theme.spacing(5),
            height: theme.spacing(5),
            transform: 'rotate(45deg)',
        },
        characterAvatar: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
    };
};
