import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        keyResult: css({
            marginBottom: theme.spacing(4),
        }),
        descriptionWrapper: css({
            display: 'flex',
            alignItems: 'top',
            marginBottom: theme.spacing(6),
        }),
        description: {
            flex: '1 0',
        },
        percentageAdornment: {
            '& .MuiTypography-root': {
                color: colors.blue,
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    };
};
