import type { Scale } from 'types';
import { RULER_ORIENTATION } from 'types';
import { Ruler } from './Ruler';

type ScaleType<T, M, L> = L extends RULER_ORIENTATION.VERTICAL ? Scale<T> : Scale<M>;
type TicksType<T, K, L> = L extends RULER_ORIENTATION.VERTICAL ? T[] : K[];

interface Props<T, M, K, L> {
    numberOfTicks?: number;
    orientation?: RULER_ORIENTATION;
    ticks?: TicksType<T, K, L>;
    xScale: ScaleType<T, M, L>;
    yScale: Scale<K>;
}

export const ChartRuler = <T, M, K, L>({
    numberOfTicks,
    orientation = RULER_ORIENTATION.HORIZONTAL,
    ticks,
    xScale,
    yScale,
}: Props<T, M, K, L>) => {
    if (orientation === RULER_ORIENTATION.HORIZONTAL) {
        return (
            <Ruler
                numberOfTicks={numberOfTicks}
                orientation={orientation}
                ticks={ticks as K[]}
                xScale={yScale}
                yScale={xScale as Scale<T>}
            />
        );
    }

    return (
        <Ruler
            numberOfTicks={numberOfTicks}
            orientation={orientation}
            ticks={ticks as T[]}
            xScale={xScale as Scale<T>}
            yScale={yScale}
        />
    );
};
