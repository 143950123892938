import { useCallback, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import type { ProfileData } from 'types';
import { useBoundActions, useImage } from 'hooks';
import { Loading } from 'components';
import AvatarEdit from 'react-avatar-edit';
import { updateAvatarPhoto } from 'store/slices/user';
import defaultUser from 'assets/img/user_icon.png';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useStyles } from './styles';

const SUPPORTED_MIME_TYPES = ['image/jpeg', 'image/png'];

interface Props {
    open: boolean;
    onClose: () => void;
    profileData?: ProfileData;
    onProfileDataChanged: () => void;
}

export const ChangeProfilePictureModal: FC<Props> = ({
    open,
    onClose,
    profileData,
    onProfileDataChanged,
}) => {
    const { t } = useTranslation();
    const [updatedProfilePhoto, setUpdatedProfilePhoto] = useState('');
    const [dndPhoto, setDndPhoto] = useState('');
    const { updateAvatarPhotoAction } = useBoundActions({
        updateAvatarPhoto,
    });
    const { error, isLoading, image = defaultUser } = useImage(profileData?.profilePhoto || '');

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        if (!file || !SUPPORTED_MIME_TYPES.includes(file.type)) {
            return;
        }

        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
            setDndPhoto(reader.result as string);
        };
        reader.readAsDataURL(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const classes = useStyles(isDragActive);

    const handleAvatarEditClose = () => setUpdatedProfilePhoto('');

    const onAvatarEditCrop = (croppedImage) => setUpdatedProfilePhoto(croppedImage);

    const handleOnClose = () => {
        setDndPhoto('');
        onClose();
    };

    const onUpdateProfilePhotoClick = async () => {
        await updateAvatarPhotoAction(updatedProfilePhoto);
        onClose();
        onProfileDataChanged();
        setDndPhoto('');
    };

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return null;
    }

    return (
        <Dialog open={open} onClose={handleOnClose} className="bigPadding">
            <DialogContent>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className={classes.avatarEdit}>
                        {!dndPhoto && (
                            <AvatarEdit
                                width={400}
                                height={256}
                                onCrop={onAvatarEditCrop}
                                onClose={handleAvatarEditClose}
                                src={image}
                                label={t('profile.actions.updateAvatar.imagePlaceholderLabel')}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                sx={classes.avatarEdit}
                            />
                        )}
                        {dndPhoto && (
                            <AvatarEdit
                                width={400}
                                height={256}
                                onCrop={onAvatarEditCrop}
                                onClose={handleAvatarEditClose}
                                src={dndPhoto}
                                label={t('profile.actions.updateAvatar.imagePlaceholderLabel')}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                sx={classes.avatarEdit}
                            />
                        )}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onUpdateProfilePhotoClick}>
                    {t('profile.actions.updateAvatar.saveChanges')}
                </Button>
                <Button variant="outlined" className="pull-right" onClick={onClose}>
                    {t('profile.actions.updateAvatar.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
