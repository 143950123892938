import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

const CHART_COLORS = [
    colors.tealDarker1,
    colors.blue,
    colors.darkBlue,
    colors.teal,
    colors.tealLighter1,
];

export const useChartColors = (data) =>
    data.map((item, index) => ({
        color: CHART_COLORS[index % CHART_COLORS.length],
        ...item,
    }));
