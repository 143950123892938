import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import type { AssignedToWithType, QuestFilterObject } from 'types';

export type QuestPreFillData = Record<string, any> | undefined;

export type QuestLogFilterPreFillData = {
    timeStamp: string;
    filter: QuestFilterObject;
    assignedToWithType?: AssignedToWithType;
};

export interface QuestState {
    questPreFillData?: QuestPreFillData;
    questLogFilterPreFillData?: QuestLogFilterPreFillData;
}

interface PrefillDataPayload {
    filter: QuestFilterObject;
    assignedToWithType?: AssignedToWithType;
}

export const initialState: QuestState = {
    questPreFillData: undefined,
    questLogFilterPreFillData: undefined,
};

const quest = createSlice({
    initialState,
    name: 'quest',
    reducers: {
        setQuestPreFillData: (state, { payload }: PayloadAction<QuestPreFillData>) => {
            state.questPreFillData = payload;
        },
        setQuestLogFilterPreFillData: (state, { payload }: PayloadAction<PrefillDataPayload>) => {
            state.questLogFilterPreFillData = {
                timeStamp: moment().format(),
                filter: payload.filter,
                assignedToWithType: payload.assignedToWithType,
            };
        },
    },
});

export const {
    actions: { setQuestPreFillData, setQuestLogFilterPreFillData },
    reducer: questReducer,
} = quest;
