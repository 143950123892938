import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import { InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import type { IAutocompleteView } from 'types';

interface Props {
    title: string;
    name: string;
    control: any;
    disabled?: boolean;
    list: IAutocompleteView[];
    defaultValue?: string | number;
    errors: any;
    required?: boolean;
    size?: 'small' | 'medium' | undefined;
}

const FtDropdown: FC<Props> = ({
    title,
    name,
    defaultValue,
    control,
    list,
    disabled = false,
    errors,
    required,
    size = undefined,
}) => (
    <FormControl fullWidth>
        <InputLabel id={`${name}-label`} error={errors[name]}>{`${title} ${
            required && '*'
        }`}</InputLabel>
        <Controller
            control={control}
            name={name}
            rules={{ required: required || false }}
            render={({ field: { value, onChange } }) => (
                <Select
                    disabled={disabled}
                    defaultValue={defaultValue}
                    value={value}
                    size={size}
                    fullWidth
                    onChange={onChange}
                    labelId={`${name}-label`}
                    label={`${title} ${required && '*'}`}
                    error={errors[name]}
                >
                    {list &&
                        list.map((x: IAutocompleteView) => {
                            return (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.displayName}
                                </MenuItem>
                            );
                        })}
                </Select>
            )}
        />
    </FormControl>
);

export default FtDropdown;
