import type { FC } from 'react';
import { Grid } from '@mui/material';

interface Props {
    Left?: any;
    Center: any;
}

export const MainContainer: FC<Props> = ({ Left, Center }) => {
    const centerMd = Left ? 8 : 12;

    return (
        <Grid container spacing={2}>
            {Left && (
                <Grid item xs={12} md={4}>
                    {Left}
                </Grid>
            )}
            <Grid item xs={12} md={centerMd}>
                {Center}
            </Grid>
        </Grid>
    );
};
