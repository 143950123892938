import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBoundActions } from 'hooks';
import { Avatar, Button, Typography } from '@mui/material';
import { setQuestPreFillData } from 'store/slices/quest';
import type { JobProfileHeader } from 'types';
import { KeyResultAssignedTo, PROFILE_TYPE } from 'types';

import { useStyles } from './styles';

const ASSIGNED_TO_TYPE_MAP = {
    [PROFILE_TYPE.JOB]: KeyResultAssignedTo.JOB,
    [PROFILE_TYPE.JOB_FAMILY]: KeyResultAssignedTo.JOB,
    [PROFILE_TYPE.PLAYER]: KeyResultAssignedTo.EMPLOYEE,
    [PROFILE_TYPE.TEAM]: KeyResultAssignedTo.TEAM,
};

interface Props {
    job: JobProfileHeader;
    jobFamily?: { id: string; title: string };
    type: PROFILE_TYPE;
}

export const JobHeaderSection = ({ job, jobFamily, type }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setQuestPreFillDataAction } = useBoundActions({
        setQuestPreFillData,
    });
    const classes = useStyles();

    const { title, noOfEmployees, profilePhoto, id } = job;

    const handleOnCreateQuestClick = () => {
        setQuestPreFillDataAction({
            assignedTo: [
                {
                    group: t('quest.addPlayerStep.form.assignTo.groups.jobs.label'),
                    type: ASSIGNED_TO_TYPE_MAP[type],
                    name: title,
                    id,
                    profilePhoto,
                },
            ],
        });
        navigate('/quest/new');
    };

    const handleOnJobCategoryClick = () => navigate(`/jobFamilyProfile/${jobFamily?.id}`);

    return (
        <div className={classes.wrapper}>
            <div className={classes.avatarWrapper}>
                <Avatar alt={title} src={profilePhoto}>
                    {title[0]}
                </Avatar>
                <div className={classes.dataWrapper}>
                    <Typography component="span">
                        <strong>{t(`jobProfile.jobHeaderSection.title.${type}.label`)}</strong>
                    </Typography>
                    <Typography component="span">
                        <strong>{title}</strong>
                    </Typography>
                    {noOfEmployees != null && (
                        <Typography>
                            {t('jobProfile.jobHeaderSection.members.label', {
                                count: noOfEmployees,
                            })}
                        </Typography>
                    )}
                    {jobFamily && (
                        <Button onClick={handleOnJobCategoryClick}>
                            {t('jobProfile.jobHeaderSection.jobFamily.label', {
                                title: jobFamily.title,
                            })}
                        </Button>
                    )}
                </div>
            </div>
            <Button variant="outlined" onClick={handleOnCreateQuestClick}>
                {t('jobProfile.jobHeaderSection.actions.createQuest.label')}
            </Button>
        </div>
    );
};
