export const hr = {
    translation: {
        profile: {
            actions: {
                giveFeedback: 'Dati povratnu informaciju',
                updateAvatar: {
                    update: 'Ažuriraj',
                    saveChanges: 'Spremi promjene',
                    cancel: 'Otkazati',
                    imagePlaceholderLabel: 'Odaberite datoteku',
                },
            },
            settings: {
                changeLanguage: 'Promijeni jezik',
                revokeConsent: 'Otkazati suglasnost s GDPR',
                changePassword: 'Promijenite zaporku',
                language: {
                    english: { label: 'Engleski' },
                    german: { label: 'Njemački' },
                    slovak: { label: 'Slovački' },
                    czech: { label: 'Češki' },
                    croatian: { label: 'Hrvatski' },
                    slovenian: { label: 'Slovenski' },
                },
            },
            info: {
                level: 'LEVEL',
                experience: 'ISKUSTVO',
                inventory: 'INVENTAR',
            },
        },
    },
};
