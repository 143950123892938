import type { FC, ReactNode } from 'react';
import { useRaisedState } from 'hooks';
import { Card } from '@mui/material';
import { useStyles } from './styles';

const CELL_TEXT_TYPE_RANGE = 'Range';

interface Props {
    onClick?: () => void;
    className?: string;
    children: ReactNode;
}

export const RaisedCard: FC<Props> = ({ className, onClick, children }) => {
    const { raised, handleOnFocus, handleOnBlur } = useRaisedState();
    const classes = useStyles();

    const handleOnClick = () => {
        const cellText = document.getSelection();

        if (cellText?.type !== CELL_TEXT_TYPE_RANGE && onClick) {
            onClick();
        }
    };

    const handleOnKeyUp = (evt) => {
        if (evt.keyCode === 13 && onClick) {
            onClick();
        }
    };

    return (
        <Card
            onClick={handleOnClick}
            onKeyUp={handleOnKeyUp}
            tabIndex={0}
            elevation={raised ? 2 : 0}
            onMouseEnter={handleOnFocus}
            onMouseLeave={handleOnBlur}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            sx={classes.card}
            className={className}
        >
            {children}
        </Card>
    );
};
