export enum REPORT_TYPE {
    COMPANY = 'Company',
    TASK = 'Task',
    GROWTH = 'Growth',
    SKILL = 'Skill',
}

export interface Report {
    id: string;
    name: string;
    type: REPORT_TYPE;
}

export enum ReportFeature {
    CREATE = 'Create',
}
