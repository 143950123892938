import axios from 'axios';
import type {
    IApiResponse,
    IAutocompleteView,
    ISearch,
    ISearchView,
    IPriorityDetailsView,
    IPriorityListView,
    IUpsertPriorityCommand,
    IUpsertPriorityValueCommand,
} from 'types';
import { api } from '../constants';

const controllerApi = api.priorities;

const getAutocomplete = async (): Promise<IAutocompleteView[]> => {
    return axios.get(`${controllerApi}/autocomplete`).then((res) => res.data);
};

const getPriorities = async (queryParams: ISearch): Promise<ISearchView<IPriorityListView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const getBySysId = async (id: string): Promise<IPriorityDetailsView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};

export const create = async (data: IUpsertPriorityCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (id: string, data: IUpsertPriorityCommand): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const deletePriority = async (id: string): Promise<IApiResponse> => {
    return axios.delete(`${controllerApi}/${id}`);
};

const getPrioritiesValuesByPriorityId = async (priorityId) => {
    return axios.get(`${controllerApi}/${priorityId}/priority-values`).then((res) => res.data);
};

export const createValue = async (
    priorityId: string,
    data: IUpsertPriorityValueCommand,
): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/${priorityId}/priority-values`, data);
};

export const updateValue = async (
    priorityId: string,
    id: string,
    data: IUpsertPriorityValueCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${priorityId}/priority-values/${id}`, data);
};

export const deleteValue = async (
    priorityId: string,
    priorityValueId: string,
): Promise<IApiResponse> => {
    return axios.delete(`${controllerApi}/${priorityId}/priority-values/${priorityValueId}`);
};

export const priorityService = {
    getAutocomplete,
    getPriorities,
    getBySysId,
    create,
    update,
    getPrioritiesValuesByPriorityId,
    createValue,
    updateValue,
    deleteValue,
    deletePriority,
};
