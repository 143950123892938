import { useTheme } from '@mui/material';
import { PlayerSearchResultMode } from 'types';

export const useStyles = (mode) => {
    const theme = useTheme();

    return {
        title: {
            marginBottom: theme.spacing(mode === PlayerSearchResultMode.GRID ? 3 : 3.5),
            marginTop: theme.spacing(mode === PlayerSearchResultMode.GRID ? 2 : 2.5),
        },
    };
};
