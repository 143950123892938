import { frankyTalesTheme } from 'theme';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => ({
    backpack: css({
        fill: colors.white,
    }),
});
