import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.revokedConsent;

const RevokedConsentsLoader = Loadable({
    loader: () => import('views/admin/RevokedConsents'),
    loading: Loading,
    render(loaded, props) {
        const { RevokedConsents } = loaded;

        return <RevokedConsents {...props} />;
    },
    delay: general.delay,
});

export const revokedConsentsRoute = {
    path: `/${mainRoute}`,
    component: RevokedConsentsLoader,
};
