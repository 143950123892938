import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

const ARC_COLORS = [
    colors.tealLighter1,
    colors.blueLighter1,
    colors.darkBlueLighter1,
    colors.skyBlue,
    colors.blueDarker,
];

const BOUNCE_SHIFT = 2;
const THEME_SPACING_1 = 8;

export const useStyles = (index, highlighted) => {
    const theme = useTheme();

    return {
        keyResultProgress: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            backgroundColor: ARC_COLORS[index % ARC_COLORS.length],
            borderRadius: theme.spacing(2),
        },
        bounceWrapper: css({
            paddingTop: highlighted ? `${THEME_SPACING_1 - BOUNCE_SHIFT}px` : theme.spacing(1),
            paddingBottom: highlighted ? `${THEME_SPACING_1 + BOUNCE_SHIFT}px` : theme.spacing(1),
        }),
    };
};
