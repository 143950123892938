import type { FC } from 'react';

interface Props {
    label: string;
    text: string;
}

export const FtLabel: FC<Props> = ({ label, text }) => (
    <>
        <div>{label}</div>
        <div>
            <b>{text}</b>
        </div>
    </>
);
