import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.scales;

const ScaleLoader = Loadable({
    loader: () => import('views/admin/Scale'),
    loading: Loading,
    render(loaded, props) {
        const { Scale } = loaded;
        return <Scale {...props} />;
    },
    delay: general.delay,
});

const ScaleEditLoader = Loadable({
    loader: () => import('views/admin/Scale/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const scaleRoute = {
    path: `/${mainRoute}`,
    component: ScaleLoader,
};

export const scaleCreateRoute = {
    path: `/${mainRoute}/create`,
    component: ScaleEditLoader,
};

export const scaleEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: ScaleEditLoader,
};
