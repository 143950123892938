import { hexToRgba, frankyTalesTheme } from 'theme';

import { useTheme } from '@mui/material';

const { buttonBackgroundAlphaHover, colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        nodalHeader: {
            position: 'relative',
            backgroundColor: '#ffffff',
            [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(6),
            },
            [theme.breakpoints.only('md')]: {
                paddingLeft: theme.spacing(5),
                paddingRight: theme.spacing(5),
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
        },
        appName: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        pageTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        closeButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',

            '& .MuiButton-root': {
                color: theme.palette.primary.main,

                '&:hover': {
                    backgroundColor: hexToRgba(
                        theme.palette.primary.main,
                        buttonBackgroundAlphaHover,
                    ),
                },
            },
        },
        nodalHeaderGrid: {
            height: theme.spacing(10),
        },
        modalContent: {
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
            paddingBottom: theme.spacing(8),
            textAlign: 'center',
        },
        modalActions: {
            '& button': {
                backgroundColor: colors.orange,

                '&:hover': {
                    backgroundColor: colors.orangeDarker,
                },
            },
        },
    };
};
