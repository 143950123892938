import type { FC } from 'react';
import { NavLink } from 'react-router-dom';
import LogoSvg from 'assets/img/logo.svg';
import cx from 'classnames';

import { useStyles } from './styles';

interface Props {
    className?: string;
    darkMode?: boolean;
    disableNavigation?: boolean;
    smaller?: boolean;
}

export const Logo: FC<Props> = ({
    className,
    darkMode = false,
    disableNavigation,
    smaller = false,
}) => {
    const classes = useStyles(darkMode, smaller);
    const logo = <div className={cx(className, classes.logo)}>{<LogoSvg />}</div>;

    if (disableNavigation) {
        return logo;
    }

    return <NavLink to="/dashboard">{logo}</NavLink>;
};
