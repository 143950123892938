import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PlayerSearchResultMode, PlayerSearchResultType } from 'types';
import type { PlayerSearchResult } from 'types';
import { CardContent, Divider, Typography } from '@mui/material';
import { PlayerSearchResultMenu } from '../PlayerSearchResultMenu';
import { Avatar } from '../../avatar';
import { PlayerStats } from '../../profile/PlayerStats';
import { RaisedCard } from '../../RaisedCard';
import { useStyles } from './styles';

const PROFILE_URL_TYPES = {
    [PlayerSearchResultType.PLAYER]: 'profile',
    [PlayerSearchResultType.JOB]: 'jobProfile',
    [PlayerSearchResultType.TEAM]: 'teamProfile',
};

interface Props {
    player: PlayerSearchResult;
    mode: PlayerSearchResultMode;
    type: PlayerSearchResultType;
}

export const PlayerSearchCard: FC<Props> = ({ mode, player, type }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles(mode);

    const handleOnCardClick = () => navigate(`/${PROFILE_URL_TYPES[type]}/${player.id}`);

    const handleOnJobClick = (evt) => {
        evt.stopPropagation();
        navigate(`/jobProfile/${player.jobId}`);
    };

    return (
        <RaisedCard onClick={handleOnCardClick}>
            <CardContent sx={classes.descriptionWrapper}>
                <div className={classes.playerDescription}>
                    <Avatar
                        src={player.profilePhoto}
                        alt={player.displayName[0]}
                        sx={classes.avatar}
                    />
                    <div className={classes.nameWrapper}>
                        <Typography>
                            <strong>{player.displayName}</strong>
                        </Typography>
                        {type !== PlayerSearchResultType.PLAYER && (
                            <Typography>
                                {t('playerSearch.playerSearchCard.numberOfPlayers', {
                                    count: player.numberOfEmployees,
                                })}
                            </Typography>
                        )}
                        {type === PlayerSearchResultType.PLAYER && (
                            <Typography onClick={handleOnJobClick} sx={classes.job}>
                                {player.jobName}
                            </Typography>
                        )}
                    </div>
                </div>
                <PlayerSearchResultMenu player={player} mode={mode} type={type} />
            </CardContent>
            {type === PlayerSearchResultType.PLAYER && mode === PlayerSearchResultMode.GRID && (
                <>
                    <Divider />
                    <CardContent>
                        <PlayerStats player={player} />
                    </CardContent>
                </>
            )}
        </RaisedCard>
    );
};
