import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        card: {
            height: '100%',

            '& .MuiCardContent-root': {
                padding: theme.spacing(3),
            },

            cursor: 'pointer',
        },
        expUntilLevel: {
            color: colors.darkBlue,
            fontWeight: theme.typography.fontWeightMedium,
        },
        icon: {
            backgroundColor: colors.lightGreen,
        },
        title: {
            textTransform: 'capitalize',
            color: colors.grey,
            fontSize: '0.75rem',
            fontWeight: 600,
            whiteSpace: 'nowrap',
        },
        levelText: {
            display: 'flex',
            justifyContent: 'inherit',
        },
        currentLevel: css({
            fontWeight: theme.typography.fontWeightBold,
            marginRight: '4px',
        }),
        level: css({
            color: colors.grey,
        }),
        progressBar: {
            borderRadius: 5,
            marginTop: theme.spacing(1),

            '& .MuiLinearProgress-bar': {
                backgroundColor: colors.lightGreen,
            },
        },
    };
};
