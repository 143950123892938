import { useState } from 'react';
import type { FC } from 'react';
import type { Quest, QuestFeature } from 'types';
import { CardContent } from '@mui/material';
import { QuestActionsMenu } from '../QuestActionsMenu';
import { AsignementQuestModal } from '../AsignementQuestModal';
import { QuestListCardProgress } from '../QuestListCardProgress';
import { QuestListCardContent } from './QuestListCardContent';
import { RaisedCard } from '../../RaisedCard';
import { useStyles } from './styles';

interface Props {
    quest: Quest;
    onQuestClick?(questId: string): void;
}

export const QuestListCard: FC<Props> = ({ onQuestClick, quest }) => {
    const [assignmentFeature, setAssignmentFeature] = useState<QuestFeature>();
    const classes = useStyles(quest);

    const handleOnQuestCardClick = () => onQuestClick?.(quest.objectiveId);

    const handleOnModalFeatureCancel = () => setAssignmentFeature(undefined);

    const handleOnModalFeatureSuccess = () => setAssignmentFeature(undefined);

    return (
        <>
            <RaisedCard onClick={handleOnQuestCardClick}>
                <CardContent sx={classes.content}>
                    <QuestListCardContent quest={quest} />
                    <div className={classes.actions}>
                        <QuestListCardProgress
                            quest={quest}
                            className={classes.progressArcWrapper}
                        />
                        <QuestActionsMenu
                            quest={quest}
                            onAssignmentFeatureClick={setAssignmentFeature}
                        />
                    </div>
                </CardContent>
            </RaisedCard>
            {assignmentFeature !== undefined && (
                <AsignementQuestModal
                    action={assignmentFeature}
                    questId={quest.objectiveId}
                    onCancel={handleOnModalFeatureCancel}
                    onSuccess={handleOnModalFeatureSuccess}
                />
            )}
        </>
    );
};
