import { useState } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/extensions
import 'swiper/css';
// eslint-disable-next-line import/extensions
import 'swiper/css/effect-coverflow';
import type { AppReducerState } from 'store';
import type { Avatar } from 'types';
import { Grid, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';
import cx from 'classnames';
import { useStyles } from './styles';
import { FeedbackAvatarCard } from '../FeedbackAvatarCard';
import { FeedbackPlayerCard } from '../FeedbackPlayerCard';
import { SwiperButton } from '../FeedbackCompetencyGrid/SwiperButton';

const NUM_SWIPEABLE_AVATARS_TO_SHOW = 3;

interface Props {
    avatars?: Avatar[];
    onSelectAvatarClick: (...args: any[]) => void;
}

export const FeedbackAvatarGrid: FC<Props> = ({ avatars = [], onSelectAvatarClick }) => {
    const [hoveredAvatar, setHoveredAvatar] = useState('');
    const [slideIndex, setSlideIndex] = useState(0);
    const classes = useStyles();
    const isSmAndLower: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const { currentUser, enablePersonalFeedback } = useSelector(
        ({ configuration, user }: AppReducerState) => ({
            currentUser: user.current,
            enablePersonalFeedback: configuration.enablePersonalFeedback,
        }),
    );
    const { t } = useTranslation();
    const groupedAvatars = avatars.reduce((acc, avatar, index, array) => {
        if (index % NUM_SWIPEABLE_AVATARS_TO_SHOW !== 0) {
            const [last, ...rest] = [...acc].reverse();

            return [...rest.reverse(), [...last, avatar]];
        }

        return [...acc, [avatar]];
    }, [] as Avatar[][]);

    const handleAvatarClick = (avatar?: Avatar) => () => onSelectAvatarClick(avatar);

    const handleAvatarKeyUp = (avatar?: Avatar) => (evt) => {
        if (evt.keyCode === 13) {
            onSelectAvatarClick(avatar);
        }
    };

    const handleAvatarMouseEnter = (avatar: Avatar) => () => setHoveredAvatar(avatar.actSysId);

    const handleAvatarMouseLeave = () => setHoveredAvatar('');

    const handleAvatarFocus = (avatar: Avatar) => () => setHoveredAvatar(avatar.actSysId);

    const handleArrowFocus = () => setHoveredAvatar('');

    const handlePlayerMouseEnter = () => setHoveredAvatar(currentUser.employeeSysId);

    const handleSlide = (left) => {
        if (left) {
            if (slideIndex) {
                setSlideIndex(slideIndex - 1);
            } else {
                setSlideIndex(groupedAvatars.length - 1);
            }

            return;
        }

        if (slideIndex === groupedAvatars.length - 1) {
            setSlideIndex(0);
        } else {
            setSlideIndex(slideIndex + 1);
        }
    };

    if (isSmAndLower) {
        return (
            <Grid container spacing={4} sx={classes.grid}>
                {avatars.map((avatar) => (
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        key={avatar.actSysId}
                        sx={{
                            ...classes.gridItem,
                            ...(hoveredAvatar === avatar.actSysId ? classes.focused : {}),
                        }}
                        onMouseEnter={handleAvatarMouseEnter(avatar)}
                        onFocus={handleAvatarFocus(avatar)}
                        onMouseLeave={handleAvatarMouseLeave}
                    >
                        <FeedbackAvatarCard
                            tabIndex={0}
                            avatar={avatar}
                            onSelectAvatarClick={handleAvatarClick(avatar)}
                            onSelectAvatarKeyUp={handleAvatarKeyUp(avatar)}
                            raised={hoveredAvatar === avatar.actSysId}
                        />
                    </Grid>
                ))}
                {enablePersonalFeedback && (
                    <Grid
                        item
                        md={4}
                        sx={{
                            ...classes.gridItem,
                            ...(hoveredAvatar === currentUser.employeeSysId ? classes.focused : {}),
                        }}
                        onMouseEnter={handlePlayerMouseEnter}
                        onFocus={handlePlayerMouseEnter}
                        onMouseLeave={handleAvatarMouseLeave}
                    >
                        <FeedbackPlayerCard
                            tabIndex={0}
                            onSelectAvatarClick={handleAvatarClick(undefined)}
                            onSelectAvatarKeyUp={handleAvatarKeyUp(undefined)}
                            raised={hoveredAvatar === currentUser.employeeSysId}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    if (!enablePersonalFeedback && avatars.length <= NUM_SWIPEABLE_AVATARS_TO_SHOW + 1) {
        return (
            <Grid container spacing={2} sx={classes.itemWrapper}>
                {avatars.map((avatar) => (
                    <Grid
                        item
                        md={3}
                        key={avatar.actSysId}
                        className={cx(classes.gridItem, {
                            [classes.focused]: hoveredAvatar === avatar.actSysId,
                        })}
                        onMouseEnter={handleAvatarMouseEnter(avatar)}
                        onFocus={handleAvatarFocus(avatar)}
                        onMouseLeave={handleAvatarMouseLeave}
                    >
                        <FeedbackAvatarCard
                            tabIndex={0}
                            avatar={avatar}
                            onSelectAvatarClick={handleAvatarClick(avatar)}
                            onSelectAvatarKeyUp={handleAvatarKeyUp(avatar)}
                            raised={hoveredAvatar === avatar.actSysId}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <div className={classes.spinnerWrapper}>
            <Swiper grabCursor slidesPerView={1} className={classes.slideWrapper} rewind>
                <SwiperButton left onButtonFocused={handleArrowFocus} onSlide={handleSlide} />
                {groupedAvatars.map((avatarGroup, index) => (
                    <SwiperSlide>
                        {/*eslint-disable-next-line react/no-array-index-key*/}
                        <Grid container spacing={2} key={index} sx={classes.itemWrapper}>
                            {avatarGroup.map((avatar) => (
                                <Grid
                                    item
                                    md={4}
                                    key={avatar.actSysId}
                                    sx={{
                                        ...classes.gridItem,
                                        ...(hoveredAvatar === avatar.actSysId
                                            ? classes.focused
                                            : {}),
                                    }}
                                    onMouseEnter={handleAvatarMouseEnter(avatar)}
                                    onFocus={handleAvatarFocus(avatar)}
                                    onMouseLeave={handleAvatarMouseLeave}
                                >
                                    <FeedbackAvatarCard
                                        tabIndex={index === slideIndex ? 0 : undefined}
                                        avatar={avatar}
                                        onSelectAvatarClick={handleAvatarClick(avatar)}
                                        onSelectAvatarKeyUp={handleAvatarKeyUp(avatar)}
                                        raised={hoveredAvatar === avatar.actSysId}
                                    />
                                </Grid>
                            ))}
                            {enablePersonalFeedback && index === groupedAvatars.length - 1 && (
                                <Grid
                                    item
                                    md={4}
                                    sx={{
                                        ...classes.gridItem,
                                        ...(hoveredAvatar === currentUser.employeeSysId
                                            ? classes.focused
                                            : {}),
                                    }}
                                    onMouseEnter={handlePlayerMouseEnter}
                                    onFocus={handlePlayerMouseEnter}
                                    onMouseLeave={handleAvatarMouseLeave}
                                >
                                    <FeedbackPlayerCard
                                        tabIndex={index === slideIndex ? 0 : undefined}
                                        onSelectAvatarClick={handleAvatarClick(undefined)}
                                        onSelectAvatarKeyUp={handleAvatarKeyUp(undefined)}
                                        raised={hoveredAvatar === currentUser.employeeSysId}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </SwiperSlide>
                ))}
                <SwiperButton onButtonFocused={handleArrowFocus} onSlide={handleSlide} />
            </Swiper>
        </div>
    );
};
