import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        employeeAvatarWrapper: {
            verticalAlign: 'baseline',
            width: '1%',
        },
        characterAvatarWrapper: {
            verticalAlign: 'top',
            width: theme.spacing(8),
        },
        characterAvatar: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
        row: {
            verticalAlign: 'top',
        },
        chip: {
            marginRight: theme.spacing(1),
            backgroundColor: colors.blue,
            color: colors.white,
        },
        recipientsWrapper: css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
        }),
        recipientsList: css({
            marginBottom: theme.spacing(1),
        }),
    };
};
