import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiEmailFastOutline } from '@mdi/js';

import { useStyles } from './checkYourInboxStyles';
import { useStyles as useSignUpStyles } from './signUpStyles';
import { useStyles as useCommonStyles } from '../common/styles';

export const CheckYourInbox: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();
    const signUpClasses = useSignUpStyles();
    const commonClasses = useCommonStyles();

    const handleGoToLogin = () => navigate('/login');

    return (
        <div className={signUpClasses.content}>
            <Typography variant="h1" sx={classes.title}>
                {t('user.onboarding.checkYourInbox.title.label')}
            </Typography>
            <br />
            <Typography variant="body1" sx={signUpClasses.grayText}>
                {t('user.onboarding.checkYourInbox.info.label')}
            </Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} sx={commonClasses.actions}>
                    <Typography variant="subtitle2" component="span">
                        <Trans
                            t={t}
                            i18nKey="user.onboarding.checkYourInbox.actions.getInTouch.label"
                        >
                            If something went wrong,
                            <a
                                href="mailto:support@frankytales.com?subject=Trouble signing up"
                                className={signUpClasses.link}
                            >
                                get in touch
                            </a>
                            with us.
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={classes.iconWrapper}>
                    <Icon path={mdiEmailFastOutline} size={5} className={classes.icon} />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={commonClasses.primaryAction}
                        onClick={handleGoToLogin}
                    >
                        {t('user.onboarding.checkYourInbox.actions.backToLogin.label')}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};
