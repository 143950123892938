import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        code: css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: theme.spacing(32),
            width: '100%',
            marginBottom: theme.spacing(4),
        }),
        codeContent: css({
            display: 'flex',
            flexDirection: 'column',

            '& .MuiTypography-root': {
                textAlign: 'center',
            },
        }),
    };
};
