import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        actionsWrapper: css({
            display: 'flex',
            alignItems: 'flex-end',
        }),
        action: css({
            marginRight: theme.spacing(2),
            display: 'flex',
            alignItems: 'end',
        }),
        helpfulIcon: {
            '& svg': {
                height: theme.spacing(2.5),
                width: theme.spacing(2.5),
            },
        },
        helpfulQuestion: {
            marginRight: theme.spacing(1),
        },
        ratedOn: {
            marginLeft: theme.spacing(1),
        },
        badge: {
            '& .MuiBadge-badge': {
                right: 'initial',
                left: theme.spacing(1),
                transform: 'translate(0%, -50%)',
                backgroundColor: colors.blue,
            },
        },
    };
};
