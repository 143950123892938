import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        card: {
            cursor: 'pointer',
            padding: theme.spacing(0.5),
        },
    };
};
