import { useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import { useRedirectToHomeOrGDPR } from 'hooks';
import { AUTHENTICATE_STATUS } from 'types';

import { LoginForm } from './LoginForm';
import { TwoFactorCodeForm } from './TwoFactorCodeForm';
import { ChangePasswordForm } from './ChangePasswordForm';
import { LanguagePicker } from '../LanguagePicker';
import { RequestPasswordReset } from '../RequestPasswordReset';
import { useStyles as useCommonStyles } from '../common/styles';

enum LOGIN_STEP {
    LOGIN = 'LOGIN',
    REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
    ENTER_TWO_FACTOR_CODE = 'ENTER_TWO_FACTOR_CODE',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export const Login: FC = () => {
    const [step, setStep] = useState(LOGIN_STEP.LOGIN);
    const navigate = useNavigate();
    const commonClasses = useCommonStyles();

    useRedirectToHomeOrGDPR();

    const handleForgotPasswordClick = () => setStep(LOGIN_STEP.REQUEST_PASSWORD_RESET);

    const handleBackToLoginClick = () => setStep(LOGIN_STEP.LOGIN);

    const handleSignUpClick = () => navigate('/signUp');

    const handleAuthenticateSuccess = (status?: AUTHENTICATE_STATUS) => {
        if (status === AUTHENTICATE_STATUS.CHANGE_PASSWORD) {
            setStep(LOGIN_STEP.CHANGE_PASSWORD);

            return;
        }

        setStep(LOGIN_STEP.ENTER_TWO_FACTOR_CODE);
    };

    return (
        <div className={commonClasses.container}>
            <LanguagePicker />
            <Card variant="outlined" className="biggerPadding" sx={commonClasses.dialog}>
                <CardContent>
                    {step === LOGIN_STEP.LOGIN && (
                        <LoginForm
                            onAuthenticateSuccess={handleAuthenticateSuccess}
                            onForgotPasswordClick={handleForgotPasswordClick}
                            onSignUpClick={handleSignUpClick}
                        />
                    )}
                    {step === LOGIN_STEP.REQUEST_PASSWORD_RESET && (
                        <RequestPasswordReset onBackToLoginClick={handleBackToLoginClick} />
                    )}
                    {step === LOGIN_STEP.CHANGE_PASSWORD && (
                        <ChangePasswordForm onBackToLoginClick={handleBackToLoginClick} />
                    )}
                    {step === LOGIN_STEP.ENTER_TWO_FACTOR_CODE && (
                        <TwoFactorCodeForm onBackToLoginClick={handleBackToLoginClick} />
                    )}
                </CardContent>
            </Card>
        </div>
    );
};
