import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import { Avatar, Badge, TableCell, TableRow, Typography } from '@mui/material';
import type { Notification } from 'types';
import { useLayout } from 'hooks';
import { api } from 'services';

import { useStyles } from './styles';
import { EventRowMessage } from '../EventRowMessage';
import { RecipientsList } from '../RecipientsList';
import { Coins } from '../../Coins';
import { Experience } from '../../Experience';
import { EmployeeAvatar } from '../../avatar';

interface Props {
    streamItem: Notification;
    showSecondaryPhoto?: boolean;
}

export const ActivityStreamEventRow = ({ showSecondaryPhoto = true, streamItem }: Props) => {
    const { isXs } = useLayout();
    const { t } = useTranslation();
    const classes = useStyles();

    const isAvatarRow = streamItem.primaryPhotoPath?.includes('/avatars');

    return (
        <TableRow key={streamItem.activityStreamId} sx={classes.row}>
            {!isXs && (
                <TableCell sx={classes.employeeAvatarWrapper} align="left">
                    {isAvatarRow && (
                        <Avatar
                            sx={classes.characterAvatar}
                            alt={streamItem.subtitle?.[0]}
                            src={`${api.baseResourceUrl}/${streamItem.primaryPhotoPath}`}
                        >
                            {streamItem.subtitle?.[0]}
                        </Avatar>
                    )}
                    {!isAvatarRow && (
                        <EmployeeAvatar
                            sx={classes.characterAvatar}
                            alt={streamItem.subtitle?.[0]}
                            src={streamItem.primaryPhotoPath || 'no_user_profile_image.png'}
                        />
                    )}
                </TableCell>
            )}
            <TableCell>
                <Typography variant="caption">
                    {moment(streamItem.date).format('DD.MM.YYYY')}
                    {` • ${sanitize(streamItem.title, { USE_PROFILES: {} })}`}
                    {streamItem.isNew && (
                        <Badge
                            badgeContent={t('eventRow.badgeNew.label')}
                            color="primary"
                            sx={classes.badge}
                        >
                            {''}
                        </Badge>
                    )}
                </Typography>
                <EventRowMessage streamItem={streamItem} />
                {streamItem.subtitle && (
                    <Typography variant="caption" component="p">
                        {streamItem.subtitle}
                    </Typography>
                )}
            </TableCell>
            <TableCell sx={classes.characterAvatarWrapper} align="right">
                {showSecondaryPhoto && (
                    <div className={classes.recipientsWrapper}>
                        <RecipientsList
                            entitiesTo={streamItem.entitiesTo}
                            className={classes.recipientsList}
                        />
                        <Coins value={streamItem.coins || 0} displaySign />
                        <Experience value={streamItem.xp} />
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
};
