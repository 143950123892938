import { useState } from 'react';
import type { FC } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import type { Moment } from 'moment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Select,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useStyles } from './styles';

const DATE_FORMAT = 'DD.MM.YYYY';

interface Props {
    selectedDateFrom?: Moment;
    selectedDateTo?: Moment;
    onSelectedDateFromChange: (date: Moment) => void;
    onSelectedDateToChange: (date: Moment) => void;
    onDialogOpenChange: (open: boolean) => void;
    className?: string;
}

export const DateIntervalFilter: FC<Props> = ({
    selectedDateFrom,
    selectedDateTo,
    onSelectedDateFromChange,
    onSelectedDateToChange,
    onDialogOpenChange,
    className,
}) => {
    const [dateDialogOpen, setDateDialogOpen] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const handleDialogOpen = () => {
        setDateDialogOpen(true);
        onDialogOpenChange(true);
    };

    const handleDialogConfirm = () => {
        setDateDialogOpen(false);
        onDialogOpenChange(false);
    };

    const handleDialogClose = () => {
        setDateDialogOpen(false);
        onDialogOpenChange(false);
    };

    return (
        <FormControl size="small" className={cx(className)} fullWidth>
            <Select
                open={false}
                value={{}}
                onOpen={handleDialogOpen}
                renderValue={() => (
                    <span>{`${
                        selectedDateFrom?.isValid() ? selectedDateFrom.format(DATE_FORMAT) : '-'
                    } - ${
                        selectedDateTo?.isValid() ? selectedDateTo?.format(DATE_FORMAT) : '-'
                    }`}</span>
                )}
            />
            <Dialog
                disableEscapeKeyDown
                open={dateDialogOpen}
                onClose={() => {}}
                className={cx(classes.dateIntervalDialog, 'bigPadding')}
            >
                <DialogTitle>{t('analytics.dateIntervalPicker.dialog.title.label')}</DialogTitle>
                <DialogContent>
                    <DatePicker
                        sx={classes.datePicker}
                        slotProps={{
                            textField: {
                                size: 'small',
                                fullWidth: true,
                                'aria-label': t(
                                    'analytics.dateIntervalPicker.dialog.dateFrom.aria',
                                ) as string,
                            },
                        }}
                        format={DATE_FORMAT}
                        onChange={(value) => onSelectedDateFromChange(value as Moment)}
                        value={selectedDateFrom}
                        label={t('analytics.dateIntervalPicker.dialog.dateFrom.label')}
                        disableFuture
                        maxDate={selectedDateTo}
                    />
                    <br />
                    <DatePicker
                        slotProps={{
                            textField: {
                                size: 'small',
                                fullWidth: true,
                                'aria-label': t(
                                    'analytics.dateIntervalPicker.dialog.dateTo.aria',
                                ) as string,
                            },
                        }}
                        format={DATE_FORMAT}
                        onChange={(value) => onSelectedDateToChange(value as Moment)}
                        value={selectedDateTo}
                        label={t('analytics.dateIntervalPicker.dialog.dateTo.label')}
                        disableFuture
                        minDate={selectedDateFrom}
                        sx={classes.dateTo}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogConfirm}
                        variant="contained"
                        disabled={!selectedDateFrom?.isValid() || !selectedDateTo?.isValid()}
                    >
                        {t('analytics.dateIntervalPicker.dialog.actions.confirm.label')}
                    </Button>
                    <Button onClick={handleDialogClose}>
                        {t('analytics.dateIntervalPicker.dialog.actions.close.label')}
                    </Button>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};
