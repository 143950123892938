import type { FC, ReactNode } from 'react';

interface Props {
    active: boolean;
    labeledBy: string;
    tabId: string;
    children: ReactNode;
}

export const TabPanel: FC<Props> = ({ active, children, labeledBy, tabId }) => (
    <div role="tabpanel" hidden={!active} id={tabId} aria-labelledby={labeledBy}>
        {active && children}
    </div>
);
