import type { FC, ReactNode } from 'react';
import { useStyles } from './styles';

interface Props {
    children: ReactNode;
}

export const ValidationError: FC<Props> = ({ children }) => {
    const classes = useStyles();

    return <span className={classes.error}>{children}</span>;
};
