import { useContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    CardActions,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { api, callService } from 'services';
import { CreateQuestContext } from 'contexts';
import { CreateQuestStep } from 'types';
import type { Employee } from 'types';
import type { AppReducerState } from 'store';
import { useStyles } from './styles';

export const QuestSkillStep: FC = () => {
    const [searchedSkills, setSearchedSkills] = useState<Employee[]>([]);
    const [suggestedSkills, setSuggestedSkills] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { saveAsDraft, relatedSkills, setRelatedSkills, setStep } =
        useContext(CreateQuestContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const { employeeSysId } = useSelector(({ user }: AppReducerState) => user.current);

    useEffect(() => {
        (async () => {
            const { error, payload } = await callService({
                api: api.skillsController.getSkillsSuggestions,
                params: { employeeId: employeeSysId },
            });

            if (!error && payload) {
                setSuggestedSkills(payload);
            }
        })();
    }, []);

    const handleSkillsSearch = async (evt) => {
        setIsLoading(true);

        const { error, payload } = await callService({
            api: api.skillsController.getSkills,
            params: { query: evt?.target?.value },
        });

        if (!error && payload) {
            setSearchedSkills(payload);
            setIsLoading(false);
        }
    };

    const handleRelatedSkillsChange = (evt, selectedSkill) => setRelatedSkills(selectedSkill);

    const handleNextStepClick = () => setStep(CreateQuestStep.OBJECTIVE_STEP);

    const handleSaveAsDraftClick = () => saveAsDraft();

    const handleSuggestedSkillSelected = (suggestedSkill) => () => {
        const foundSkill = relatedSkills?.find((skill) => skill === suggestedSkill);

        if (!foundSkill) {
            setRelatedSkills([...relatedSkills!, suggestedSkill]);
        }
    };

    return (
        <Card className="bigPadding">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="quest-skills--input">
                            <Typography>{t('quest.skillStep.form.skill.label')}</Typography>
                        </InputLabel>
                        <Autocomplete
                            id="quest-skill-input"
                            sx={classes.inputChip}
                            value={relatedSkills}
                            multiple
                            freeSolo
                            ChipProps={{ size: 'small' }}
                            onChange={handleRelatedSkillsChange}
                            options={searchedSkills}
                            popupIcon={null}
                            onInputChange={handleSkillsSearch}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: [
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>,
                                                params.InputProps.startAdornment,
                                            ],
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {!!suggestedSkills.length && (
                            <div className={classes.suggestedSkills}>
                                <Typography variant="button">
                                    {t('quest.skillStep.form.suggestedSkills.label')}
                                </Typography>
                                <br />
                                {suggestedSkills.map((suggestedSkill) => (
                                    <Chip
                                        key={suggestedSkill}
                                        label={suggestedSkill}
                                        size="small"
                                        variant="outlined"
                                        sx={classes.suggestedSkill}
                                        onClick={handleSuggestedSkillSelected(suggestedSkill)}
                                    />
                                ))}
                            </div>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardActions>
                <Button variant="outlined" onClick={handleSaveAsDraftClick}>
                    {t('quest.skillStep.actions.saveAsDraft.label')}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleNextStepClick}
                    disabled={!relatedSkills?.length}
                >
                    {t('quest.skillStep.actions.next.label')}
                </Button>
            </CardActions>
        </Card>
    );
};
