import type { FC } from 'react';
import { useState } from 'react';
import cx from 'classnames';
import { Logo } from 'components';
import { SignUpProvider } from 'contexts';

import { BasicInfo } from './BasicInfo';
import { CheckYourInbox } from './CheckYourInbox';
import { SignUpForm } from './SignUpForm';
import { LanguagePicker } from '../LanguagePicker';
import { useStyles as useCommonStyles } from '../common/styles';
import { useStyles } from './signUpStyles';

enum SIGN_UP_STEP {
    BASIC_INFO = 'BASIC_INFO',
    SIGN_UP = 'SIGN_UP',
    CHECK_YOUR_EMAIL = 'CHECK_YOUR_EMAIL',
}

export const SignUp: FC = () => {
    const [step, setStep] = useState(SIGN_UP_STEP.SIGN_UP);
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const handleContinueClick = () => setStep(SIGN_UP_STEP.BASIC_INFO);

    const handleCreateAccountClick = () => setStep(SIGN_UP_STEP.CHECK_YOUR_EMAIL);

    const handleGoToSignUp = () => setStep(SIGN_UP_STEP.SIGN_UP);

    return (
        <SignUpProvider>
            <div className={cx(commonClasses.container, classes.containerAlign)}>
                <div className={classes.contentWrapper}>
                    <Logo disableNavigation className={classes.logo} />
                    <LanguagePicker />
                    {step === SIGN_UP_STEP.SIGN_UP && (
                        <SignUpForm onContinueClick={handleContinueClick} />
                    )}
                    {step === SIGN_UP_STEP.BASIC_INFO && (
                        <BasicInfo
                            onCreateAccountClick={handleCreateAccountClick}
                            onGoBackClick={handleGoToSignUp}
                        />
                    )}
                    {step === SIGN_UP_STEP.CHECK_YOUR_EMAIL && <CheckYourInbox />}
                </div>
            </div>
        </SignUpProvider>
    );
};
