import axios from 'axios';
import type { IAvatarView, IAvatarUpsertCommand, ISearch, IApiResponse, ISearchView } from 'types';
import { api, mainApiUrl } from '../constants';

interface AvatarSearch extends ISearch {
    performanceCycleSysId: string;
}

export const search = async (queryParams: AvatarSearch): Promise<ISearchView<IAvatarView>> => {
    return axios
        .get(`${api.avatars}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const getBySysId = async (id: string): Promise<IAvatarView> => {
    return axios.get(`${api.avatars}/${id}`).then((res) => res.data);
};

export const create = async (data: IAvatarUpsertCommand): Promise<IApiResponse> => {
    return axios.post(`${api.avatars}`, data);
};

export const update = async (id: string, data: IAvatarUpsertCommand): Promise<IApiResponse> => {
    return axios.put(`${api.avatars}/${id}`, data);
};

export const updatePhoto = async ({ id, base64Image }) => {
    return axios.put(`${mainApiUrl}/FeedbackAvatar/photo`, {
        feedbackAvatarSysId: id,
        base64Image,
    });
};

export const updateSmallPhoto = async ({ id, base64Image }) => {
    return axios.put(`${mainApiUrl}/FeedbackAvatar/small-photo`, {
        feedbackAvatarSysId: id,
        base64Image,
    });
};

export const bulkImportValidation = async ({ bulkImportData }) => {
    return axios.put(`${api.avatars}/bulkImportValidate`, {
        importTheData: false,
        BulkImportFeedAvatars: bulkImportData,
    });
};

export const bulkImport = async ({ bulkImportData }) => {
    return axios.put(`${api.avatars}/bulkImportValidate`, {
        importTheData: true,
        BulkImportFeedAvatars: bulkImportData,
    });
};

export const deleteAvatars = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${api.avatars}/delete`, {
        ids,
    });
};
