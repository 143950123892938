import { bindActionCreators } from '@reduxjs/toolkit';
import type { ActionCreatorsMapObject } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

export const useBoundActions = (actions: ActionCreatorsMapObject): ActionCreatorsMapObject => {
    const dispatch = useDispatch();

    return Object.entries(bindActionCreators(actions, dispatch)).reduce(
        (acc, [actionName, action]) => ({
            ...acc,
            [`${actionName}Action`]: action,
        }),
        {},
    );
};
