import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.monthlyActiveUsers;

const MonthlyActiveUsersLoader = Loadable({
    loader: () => import('views/admin/MonthlyActiveUsers'),
    loading: Loading,
    render(loaded, props) {
        const { MonthlyActiveUsers } = loaded;

        return <MonthlyActiveUsers {...props} />;
    },
    delay: general.delay,
});

export const monthlyActiveUsersRoute = {
    path: `/${mainRoute}`,
    component: MonthlyActiveUsersLoader,
};
