import { hexToRgba } from './themeService';
import { frankyTalesTheme } from './frankyTalesTheme';

const { buttonBackgroundAlphaHover, buttonColor } = frankyTalesTheme;

const root = {
    '&:hover': {
        backgroundColor: hexToRgba(buttonColor, buttonBackgroundAlphaHover),
    },

    '&.Mui-checked': {
        color: `${buttonColor} !important`,
    },
};

export const MuiRadio = {
    styleOverrides: {
        root,
    },
};
