import axios from 'axios';
import type { IApiResponse, IExperienceLevelView, IUpsertExperienceLevelCommand } from 'types';
import { api } from '../constants';

const controllerApi = `${api.experienceLevels}`;

export const getExperienceLevels = async (): Promise<IExperienceLevelView[]> => {
    return axios.get(`${controllerApi}`).then((res) => res.data);
};

export const getBySysId = async (experienceLevelSysId: string): Promise<IExperienceLevelView> => {
    return axios.get(`${controllerApi}/${experienceLevelSysId}`).then((res) => res.data);
};

export const create = async (data: IUpsertExperienceLevelCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (id: string, data: IUpsertExperienceLevelCommand) => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const deleteCompetencyLevels = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
