import { useState } from 'react';
import type { FC } from 'react';
import { FEEDBACK_STATUS } from 'types';
import { useSelector } from 'react-redux';
import { sanitize } from 'dompurify';
import type { Avatar as AvatarType, AvatarAdvice, Competency, FeedbackTarget } from 'types';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    RadioGroup,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { AppReducerState } from 'store';
import defaultUser from 'assets/img/user_icon.png';
import { Avatar } from '../../avatar';
import { FeedbackAdviceLabel } from '../FeedbackAdviceLabel';
import { YayNayAdvices } from '../YayNayAdvices';
import { useStyles } from './styles';

export interface SubmitData {
    advice: AvatarAdvice;
    note?: string;
    feedbackStatus: FEEDBACK_STATUS;
    isAnonymous?: boolean;
    topic?: string;
}

interface Props {
    feedbackPreFillData?: Record<string, any>;
    selectedTargets: FeedbackTarget[];
    selectedCompetency?: Competency;
    selectedAvatar?: AvatarType;
    avatarAdvices: AvatarAdvice[];
    onGiveFeedbackClick: ({ advice, note }: SubmitData) => void;
}

export const FeedbackForm: FC<Props> = ({
    feedbackPreFillData,
    selectedTargets,
    selectedCompetency,
    selectedAvatar,
    avatarAdvices,
    onGiveFeedbackClick,
}) => {
    const [note, setNote] = useState(feedbackPreFillData?.note || '');
    const [topic, setTopic] = useState(feedbackPreFillData?.topic || '');
    const [adviceId, setAdviceId] = useState(feedbackPreFillData?.adviceId || '');
    const [adviceError, setAdviceError] = useState('');
    const { currentUser, isAnonymousDefault } = useSelector(
        ({ user, configuration }: AppReducerState) => ({
            currentUser: user.current,
            isAnonymousDefault: configuration.feedbackConfiguration.isAnonymousDefault,
        }),
    );
    const [isAnonymous, setIsAnonymous] = useState(isAnonymousDefault);
    const { t } = useTranslation();
    const classes = useStyles();
    const noteLabel = selectedAvatar
        ? t('feedback.new.feedbackStep.form.privateNote.placeholder')
        : t('feedback.new.feedbackStep.form.note.placeholder');

    if (!selectedTargets.length || !avatarAdvices) {
        return null;
    }

    const handleNoteChange = (evt) => setNote(sanitize(evt.target.value, { USE_PROFILES: {} }));

    const handleTopicChange = (evt) => setTopic(sanitize(evt.target.value, { USE_PROFILES: {} }));

    const handleAdviceChange = (evt) => {
        setAdviceError('');
        setAdviceId(evt.target.value);
    };

    const handleYayNayChange = (advice: AvatarAdvice) => {
        setAdviceError('');
        setAdviceId(advice.id);
    };

    const submitFeedback = (feedbackStatus) => {
        if (adviceId) {
            const advice: AvatarAdvice = avatarAdvices.find(
                ({ id }) => id === adviceId,
            ) as AvatarAdvice;

            onGiveFeedbackClick({
                advice,
                feedbackStatus,
                isAnonymous,
                note,
                topic,
            });

            return;
        }

        setAdviceError(t<string>('feedback.new.feedbackStep.errors.noAdviceSelected'));
    };

    const handleGiveFeedbackClick = () => submitFeedback(FEEDBACK_STATUS.ACTIVE);

    const handleSaveDraftClick = () => submitFeedback(FEEDBACK_STATUS.DRAFT);

    const handleAnonymousSwitch = () => setIsAnonymous(!isAnonymous);

    return (
        <Card className="bigPadding">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {selectedAvatar && (
                            <Avatar
                                sx={{ ...classes.avatar, ...classes[selectedAvatar.title] }}
                                alt={selectedAvatar.title}
                                src={selectedAvatar.photoPath}
                            />
                        )}
                        {!selectedAvatar && !isAnonymous && (
                            <Avatar
                                sx={classes.avatar}
                                alt={`${currentUser.firstName} ${currentUser.lastName}`}
                                src={currentUser.photoPath}
                            />
                        )}
                        {!selectedAvatar && isAnonymous && (
                            <div className={classes.employeeAvatar}>
                                <img
                                    alt={t<string>(
                                        'feedback.new.feedbackStep.anonymousAdventurer.label',
                                    )}
                                    src={defaultUser}
                                    className={classes.employeeAvatarImage}
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h2" sx={classes.avatarTitle}>
                            {selectedAvatar &&
                                t('feedback.new.feedbackStep.avatarTitle.label', {
                                    avatarTitle: selectedAvatar.title.toLowerCase(),
                                })}
                            {!selectedAvatar &&
                                !isAnonymous &&
                                t('feedback.new.feedbackStep.playerTitle.label', {
                                    playerTitle: currentUser.firstName,
                                })}
                            {!selectedAvatar &&
                                isAnonymous &&
                                t('feedback.new.feedbackStep.anonymousTitle.label')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            size="small"
                            error={!!adviceError}
                            fullWidth
                        >
                            {selectedAvatar && (
                                <RadioGroup
                                    aria-label="Advice"
                                    name="Advice"
                                    value={adviceId}
                                    onChange={handleAdviceChange}
                                >
                                    {avatarAdvices.map((avatarAdvice) => (
                                        <FeedbackAdviceLabel
                                            active={avatarAdvice.id === adviceId}
                                            advice={avatarAdvice}
                                            key={avatarAdvice.id}
                                        />
                                    ))}
                                </RadioGroup>
                            )}
                            {!selectedAvatar && (
                                <YayNayAdvices
                                    advices={avatarAdvices}
                                    onAdviceSelected={handleYayNayChange}
                                    selectedAdviceId={adviceId}
                                />
                            )}
                            {adviceError && <FormHelperText error>{adviceError}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    {!selectedCompetency && (
                        <Grid item xs={12}>
                            <TextField
                                minRows={4}
                                maxRows={4}
                                multiline
                                size="small"
                                fullWidth
                                value={topic}
                                label={t('feedback.new.feedbackStep.form.topic.placeholder')}
                                onChange={handleTopicChange}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TextField
                            minRows={4}
                            maxRows={4}
                            multiline
                            size="small"
                            fullWidth
                            value={note}
                            label={noteLabel}
                            onChange={handleNoteChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={classes.valuesWrapper} elevation={0}>
                            <List sx={classes.valuesList}>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={t(
                                            'feedback.new.feedbackStep.form.info.sendFeedbackTo',
                                        )}
                                        sx={classes.listItemLabel}
                                    />
                                    <ListItemText
                                        primary={selectedTargets
                                            .map(({ displayName }) => displayName)
                                            .join(', ')}
                                        sx={classes.listItemValue}
                                    />
                                </ListItem>
                                {selectedCompetency && (
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary={t(
                                                'feedback.new.feedbackStep.form.info.aboutTheTopic',
                                            )}
                                            sx={classes.listItemLabel}
                                        />
                                        <ListItemText
                                            primary={selectedCompetency.descriptionShort}
                                            sx={classes.listItemValue}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </Paper>
                    </Grid>
                    {!selectedAvatar && (
                        <Grid item xs={12} sx={classes.anonymousWrapper}>
                            <div>
                                <Switch
                                    onChange={handleAnonymousSwitch}
                                    checked={isAnonymous}
                                    sx={classes.switch}
                                />
                            </div>
                            <div>
                                <Typography>
                                    <strong>
                                        {t('feedback.new.feedbackStep.form.info.title.label')}
                                    </strong>
                                </Typography>
                                {isAnonymous && (
                                    <Typography>
                                        {t(
                                            'feedback.new.feedbackStep.form.info.feedbackIsAnonymousMessage.label',
                                        )}
                                    </Typography>
                                )}
                                {!isAnonymous && (
                                    <Typography>
                                        {t(
                                            'feedback.new.feedbackStep.form.info.feedbackIsNotAnonymousMessage.label',
                                        )}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
            <Divider />
            <CardActions>
                <Button onClick={handleSaveDraftClick}>
                    {t('feedback.new.feedbackStep.actions.saveAndExit')}
                </Button>
                <Button variant="contained" onClick={handleGiveFeedbackClick}>
                    {t('feedback.new.feedbackStep.actions.giveFeedback')}
                </Button>
            </CardActions>
        </Card>
    );
};
