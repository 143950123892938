import { LANGUAGE } from 'types';
import { store } from 'store';
import { setLanguage } from 'store/slices/user';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './locales/en';
import { de } from './locales/de';
import { sk } from './locales/sk';
import { sl } from './locales/sl';
import { cs } from './locales/cs';
import { hr } from './locales/hr';

const { lng = LANGUAGE.EN } = store.getState().user;

i18n.use(initReactI18next).init({
    resources: {
        en,
        de,
        sk,
        sl,
        cs,
        hr,
    },
    lng,
    debug: true,
    interpolation: {
        escapeValue: false,
    },
});

i18n.on('languageChanged', (language) => store.dispatch(setLanguage(language as LANGUAGE)));

export { i18n };
