import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loading, NodalPage } from 'components';
import { QuestType, SurveyType, UserType } from 'types';
import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { api, callService } from 'services';
import { useStyles } from './styles';
import { EmployeeSection } from '../../EmployeeSection';
import { EvaluationQuestList } from '../EvaluationQuestList';
import { PerformanceEvaluationSurvey } from '../PerformanceEvaluationSurvey';

interface Props {
    closeHandle?(): void;
    evaluationId: string;
    employeeId: string;
}

export const PerformanceEvaluation: FC<Props> = ({ closeHandle, evaluationId, employeeId }) => {
    const [evaluationDetails, setEvaluationDetails] = useState<Record<string, any>>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();

    const getPerformanceEvaluation = async () => {
        const { error, payload } = await callService({
            api: api.employeeController.getEmployeePerformanceReview,
            params: [employeeId, evaluationId],
        });

        if (!error && payload) {
            setEvaluationDetails(payload);
        }
    };

    const handleQuestLogButtonClick = () => navigate('/quest/log');

    const handleCreateQuestButtonClick = () => navigate('/quest/new');

    useEffect(() => {
        getPerformanceEvaluation();
    }, []);

    const employee = evaluationDetails?.userData?.find((user) => user.type === UserType.EMPLOYEE);
    const lead = evaluationDetails?.userData?.find((user) => user.type === UserType.LEAD);
    const employeeSurvey = evaluationDetails?.surveys?.find(
        (survey) => survey.type === SurveyType.EMPLOYEE,
    );
    const leadSurvey = evaluationDetails?.surveys?.find(
        (survey) => survey.type === SurveyType.LEAD,
    );
    const taskQuests = evaluationDetails?.objectives?.filter(
        (objective) => objective.type === QuestType.TASK,
    );
    const growthQuests = evaluationDetails?.objectives?.filter(
        (objective) => objective.type === QuestType.GROWTH,
    );
    const skillQuests = evaluationDetails?.objectives?.filter(
        (objective) => objective.type === QuestType.SKILL,
    );

    return (
        <NodalPage
            title={t<string>('evaluation.performanceEvaluation.pageTitle.label')}
            askWhenLeaving={false}
            closeHandle={closeHandle}
        >
            <Container sx={{ ...classes.marginBottom, ...classes.marginTop }} maxWidth="md">
                {!evaluationDetails && <Loading />}
                {evaluationDetails && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={classes.marginBottom}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <EmployeeSection user={employee} />
                                </Grid>
                                {lead && (
                                    <Grid item xs={6}>
                                        <EmployeeSection user={lead} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={classes.marginBottom}>
                            {!evaluationDetails?.objectives?.length && (
                                <>
                                    <div className={classes.noQuestsTitleWrapper}>
                                        <Typography
                                            variant="h2"
                                            component="h4"
                                            sx={classes.noQuestsTitle}
                                        >
                                            {t('evaluation.evaluationQuestList.title.empty.label')}
                                        </Typography>
                                        <Button onClick={handleQuestLogButtonClick}>
                                            {t(
                                                'evaluation.evaluationQuestList.actions.goToQuestLog.label',
                                            )}
                                        </Button>
                                    </div>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent sx={classes.centered}>
                                                <Typography
                                                    variant="h4"
                                                    sx={classes.emptyQuestsTitle}
                                                >
                                                    {t(
                                                        'evaluation.evaluationQuestList.emptyMessage.label',
                                                    )}
                                                </Typography>
                                                <Button
                                                    onClick={handleCreateQuestButtonClick}
                                                    variant="contained"
                                                >
                                                    {t(
                                                        'evaluation.evaluationQuestList.actions.createQuest.label',
                                                    )}
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>
                            )}
                            {!!evaluationDetails?.objectives?.length && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <EvaluationQuestList
                                            quests={taskQuests}
                                            questType={QuestType.TASK}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EvaluationQuestList
                                            quests={growthQuests}
                                            questType={QuestType.GROWTH}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EvaluationQuestList
                                            quests={skillQuests}
                                            questType={QuestType.SKILL}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} sx={classes.marginBottom}>
                            <Grid container spacing={2}>
                                {employeeSurvey && (
                                    <Grid item xs={12}>
                                        <PerformanceEvaluationSurvey
                                            survey={employeeSurvey}
                                            employee={employee}
                                        />
                                    </Grid>
                                )}
                                {leadSurvey && (
                                    <Grid item xs={12}>
                                        <PerformanceEvaluationSurvey
                                            survey={leadSurvey}
                                            employee={evaluationDetails.evaluatedByLead}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </NodalPage>
    );
};
