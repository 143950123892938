import type { FC } from 'react';
import { TextField } from '@mui/material';

interface Props {
    title: string;
    type?: string;
    defaultValue?: string | number;
    value?: string | number;
    required?: boolean;
    multiline?: boolean;
    onChange?: any;
    size?: 'small' | 'medium' | undefined;
    disabled?: boolean;
}

export const Textbox: FC<Props> = ({
    title,
    type,
    defaultValue,
    value,
    required = false,
    multiline,
    onChange,
    size = undefined,
    disabled = false,
}) => {
    return (
        <TextField
            label={`${title} ${(required && '*') || ''}`}
            fullWidth
            type={type}
            multiline={multiline}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            size={size}
            disabled={disabled}
        />
    );
};
