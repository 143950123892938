import axios from 'axios';
import type {
    IApiResponse,
    ICompanyUpsertCommand,
    ICompanySettingsUpsertCommand,
    ICompanyView,
    ICompanySettingView,
    ICompanySecuritySettingsView,
} from 'types';
import { api, apiUrl } from '../constants';

export const getCompanies = (): Promise<ICompanyView[]> => {
    return axios.get(`${api.companies}/settings`).then((res) => res.data);
};

export const getCompaniesSettings = (): Promise<ICompanySettingView[]> => {
    return axios.get(`${api.companies}/settings`).then((res) => res.data);
};

export const getBySysId = (companySysId: string): Promise<ICompanyView> => {
    return axios.get(`${api.companies}/settings/${companySysId}`).then((res) => res.data);
};

export const create = async (data: ICompanyUpsertCommand): Promise<IApiResponse> => {
    return axios.post(`${api.companies}/settings`, data);
};

export const update = async (
    companySysId: string,
    data: ICompanyUpsertCommand,
): Promise<IApiResponse> => {
    return axios.put(`${api.companies}/settings/${companySysId}`, data);
};

export const updateSettings = async (
    data: ICompanySettingsUpsertCommand,
): Promise<IApiResponse> => {
    return axios.put(`${api.companies}/settings`, data);
};

export const getCompanySecuritySettings = (): Promise<ICompanySecuritySettingsView> =>
    axios.get(`${api.companies}/security-settings`).then((res) => res.data);

export const updateCompanySecuritySettings = (
    data: ICompanySecuritySettingsView,
): Promise<ICompanySecuritySettingsView> => axios.put(`${api.companies}/security-settings`, data);
