import { frankyTalesTheme } from 'theme';
import { useBackground } from 'hooks';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const background = useBackground();
    const theme = useTheme();

    return {
        container: css({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100vh',
            backgroundImage: `url(${background})`,
            backgroundPosition: 'center bottom',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
        }),
        dialog: {
            margin: 'auto',
            maxWidth: 300,
        },
        title: {
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
        primaryAction: {
            width: '100%',
        },
        secondaryAction: {
            // display: 'block',
            // width: '100%',
            textAlign: 'center',
            color: colors.blue,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
        },
        actions: {
            textAlign: 'center',
        },
    };
};
