import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        csvImportContainer: css({
            border: '3px dashed #9A9A9A',
            lineHeight: '100px',
            width: '100%',
            padding: '20px',
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#FAFAFA',
            },
        }),
        progressPoint: css({
            backgroundColor: '#ebebeb',
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
            '&.activated': {
                backgroundColor: '#3399ff',
                color: '#FFFFFF',
            },
        }),
        msgError: css({
            color: colors.orange,
            paddingTop: theme.spacing(2),
        }),
        msgSuccess: css({
            color: colors.teal,
            paddingTop: theme.spacing(2),
        }),
    };
};
