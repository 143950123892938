import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        skeleton: {
            width: theme.spacing(20),
            height: theme.spacing(4),
            marginBottom: theme.spacing(1 / 2),

            '&:last-of-type': {
                marginBottom: theme.spacing(0),
            },
        },
    };
};
