import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { api, callService, getEmailErrors } from 'services';
import { EMAIL_RESET_STATUS } from 'types';
import type { FormValidationError } from 'types';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { EmailInput } from 'components';
import { useStyles } from '../common/styles';

interface Props {
    onBackToLoginClick: () => void;
}

export const RequestPasswordReset: FC<Props> = ({ onBackToLoginClick }) => {
    const [email, setEmail] = useState('');
    const [serviceError, setServiceError] = useState('');
    const [serviceSuccess, setServiceSuccess] = useState(false);
    const [emailErrors, setEmailErrors] = useState<FormValidationError[]>([]);
    const { t } = useTranslation();
    const classes = useStyles();

    const requestPasswordReset = async () => {
        const {
            error,
            payload: { emailStatus },
        } = await callService({
            api: api.userController.requestPasswordReset,
            body: { email },
            useToken: false,
        });

        if (error) {
            setServiceError(t<string>('user.requestPasswordReset.message.error.label'));

            return;
        }

        if (emailStatus === EMAIL_RESET_STATUS.SUCCESS) {
            setServiceError('');
            setServiceSuccess(true);
        } else {
            setServiceError(t<string>('user.requestPasswordReset.message.error.label'));
        }
    };

    const handleFormSubmit = (evt) => {
        evt.preventDefault();
        const validationErrors = getEmailErrors(email, t);

        setEmailErrors(validationErrors);
        setServiceError('');

        if (!validationErrors.length) {
            requestPasswordReset();
        }
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        setEmailErrors([]);
    };

    return (
        <>
            <Typography variant="h2" component="h1" sx={classes.title}>
                {t('user.requestPasswordReset.title.label')}
            </Typography>
            {serviceSuccess && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="success">
                            {t('user.requestPasswordReset.message.success.label')}
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="text"
                            sx={classes.secondaryAction}
                            onClick={onBackToLoginClick}
                        >
                            {t('user.requestPasswordReset.actions.backToLogin.label')}
                        </Button>
                    </Grid>
                </Grid>
            )}
            {!serviceSuccess && (
                <form onSubmit={handleFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="caption" component="p">
                                {t('user.requestPasswordReset.subTitle.label')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <EmailInput
                                email={email}
                                emailError={emailErrors[0]}
                                onEmailChange={handleEmailChange}
                            />
                        </Grid>
                        {serviceError && (
                            <Grid item xs={12}>
                                <Alert severity="error">{serviceError}</Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit" sx={classes.primaryAction}>
                                {t('user.requestPasswordReset.actions.requestReset.label')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={classes.actions}>
                            <Button
                                variant="text"
                                sx={classes.secondaryAction}
                                onClick={onBackToLoginClick}
                            >
                                {t('user.requestPasswordReset.actions.backToLogin.label')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </>
    );
};
