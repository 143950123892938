export const MuiCardActions = {
    styleOverrides: {
        root: {
            justifyContent: 'center',
            padding: '32px',
        },
        spacing: {
            '& > :not(:first-child)': {
                marginLeft: '16px',
            },
        },
    },
};
