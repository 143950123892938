import axios from 'axios';
import { api } from '../constants';

const controllerApi = api.appCodes;

// const getEmployeeStatuses = () => {
//     return axios.get(`${controllerApi}/employee-statuses`);
// };

// const getCompanyStatuses = () => {
//     return axios.get(`${controllerApi}/company-statuses`);
// };

const getLanguages = () => {
    return axios.get(`${controllerApi}/languages`);
};

const getRoles = () => {
    return axios.get(`${controllerApi}/user-roles`);
};

// const getJobTeamRoleStatuses = () => {
//     return axios.get(`${controllerApi}/job-team-role-statuses`);
// };

const getOrgUnitStatuses = () => {
    return axios.get(`${controllerApi}/org-unit-statuses`);
};

const getOrgUnitHierarchyTypes = () => {
    return axios.get(`${controllerApi}/org-unit-hierarchy-types`);
};

// const getStoreItemStatuses = () => {
//     return axios.get(`${controllerApi}/store-item-statuses`);
// };

const getStatuses = () => {
    return axios.get(`${controllerApi}/statuses`);
};

// const getPerformanceCycleStatuses = () => {
//     return axios.get(`${controllerApi}/performance-cycle-status`);
// };

const getSurveyQuestionTypes = async () => {
    return axios.get(`${controllerApi}/survey-question-types`);
};

export const appCodeService = {
    // getPerformanceCycleStatuses,
    getStatuses,
    // getEmployeeStatuses,
    // getCompanyStatuses,
    getLanguages,
    getRoles,
    // getJobTeamRoleStatuses,
    getOrgUnitStatuses,
    getOrgUnitHierarchyTypes,
    // getStoreItemStatuses,
    getSurveyQuestionTypes,
};
