import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { appReducer as reducer } from './reducers/appReducer';
import { loadState, saveState } from './storeService';

const devTools = process.env.NODE_ENV !== 'production';
const logger = createLogger({ collapsed: true });
const preloadedState = loadState();
const middleware = getDefaultMiddleware();

if (devTools) {
    middleware.push(logger);
}

export const store = configureStore({
    reducer,
    preloadedState,
    middleware,
    devTools,
});

store.subscribe(() => saveState(store));
