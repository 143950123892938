import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        queryInput: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3),
        },
        avatar: {
            marginRight: theme.spacing(1),
        },
        avatarWrapper: css({ display: 'flex' }),
        assigneesValueLabel: css({
            marginLeft: theme.spacing(1),
        }),
        filterInputsWrapper: css({
            display: 'flex',
            marginBottom: theme.spacing(3),
        }),
        giveFeedbackButton: css({
            flex: '1 0 auto',
            marginLeft: theme.spacing(2),
        }),
    };
};
