import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useImage } from 'hooks';
import type { AppReducerState } from 'store';
import { useStyles } from './styles';

interface Props {
    onSelectAvatarClick: (...args: any[]) => void;
    onSelectAvatarKeyUp: (...args: any[]) => void;
    raised?: boolean;
    tabIndex?: number;
}

export const FeedbackPlayerCard: FC<Props> = ({
    onSelectAvatarClick = () => {},
    onSelectAvatarKeyUp = () => {},
    raised = false,
    tabIndex,
}) => {
    const { current } = useSelector(({ user }: AppReducerState) => user);
    const { t } = useTranslation();
    const { error, isLoading, image } = useImage(current.photoPath);
    const classes = useStyles();

    return (
        <Card
            tabIndex={tabIndex != null ? tabIndex : undefined}
            onClick={onSelectAvatarClick}
            onKeyUp={onSelectAvatarKeyUp}
            elevation={raised ? 2 : 0}
            sx={classes.card}
        >
            {!error && !isLoading && (
                <CardMedia
                    image={image}
                    title={`${current.firstName} ${current.lastName}`}
                    sx={classes.avatarImage}
                />
            )}
            <CardContent>
                <Typography variant="h3">{t('feedback.feedbackPlayerCard.title.label')}</Typography>
                <Typography sx={classes.contentText}>
                    {t('feedback.feedbackPlayerCard.content.label')}
                </Typography>
            </CardContent>
        </Card>
    );
};
