import { useTheme } from '@mui/material';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        modalContent: {
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
            paddingBottom: theme.spacing(8),
            textAlign: 'center',
        },
        orangeAction: {
            backgroundColor: colors.orange,

            '&:hover': {
                backgroundColor: colors.orangeDarker,
            },
        },
    };
};
