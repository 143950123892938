import type { FC } from 'react';
import cx from 'classnames';
import type { Quest } from 'types';
import { QuestProgressChartArc } from '../QuestProgressChart/QuestProgressChartArc';
import { useStyles } from './styles';

const ARC_RADIUS = 28;
const ARC_WIDTH = 8;
const TRANSITION_DURATION = 200;

interface Props {
    className?: string;
    quest: Quest;
}

export const QuestListCardProgress: FC<Props> = ({ className, quest }) => {
    const classes = useStyles(quest);

    return (
        <svg width={ARC_RADIUS * 2} height={ARC_RADIUS * 2} className={cx(className)}>
            <g transform={`translate(${ARC_RADIUS},${ARC_RADIUS})`}>
                <QuestProgressChartArc
                    value={quest.progressPercent!}
                    index={0}
                    disabled={false}
                    radius={ARC_RADIUS}
                    width={ARC_WIDTH}
                    arcMargin={0}
                    onHover={() => {}}
                    transitionDuration={TRANSITION_DURATION}
                    hideProgress
                    className={classes.progressArc}
                />
                <text dy=".25rem" className={classes.valueText}>
                    {`${quest.progressPercent}%`}
                </text>
            </g>
        </svg>
    );
};
