export const MuiLinearProgress = {
    styleOverrides: {
        root: {
            border: '1px solid #cbcfd5',
        },
        colorPrimary: {
            backgroundColor: 'transparent',
        },
    },
};
