import { useMemo } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { ActivityStreamFilterObject, AssignedToWithType, Notification } from 'types';
import {
    useActivityStreamFilter,
    useBoundActions,
    useLayout,
    useQueriedPaginatedItems,
} from 'hooks';
import {
    Button,
    Card,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import { api } from 'services';
import { setFeedbackPreFillData } from 'store/slices/feedback';
import { ActivityStreamEventRow } from '../ActivityStreamEventRow';
import { InfiniteScroll } from '../../InfiniteScroll';
import { Loading } from '../../Loading';
import { useStyles } from './styles';

const PREVIEW_SIZE = 5;

interface Props {
    assignedToWithType?: AssignedToWithType;
    filter?: ActivityStreamFilterObject;
    giveFeedbackData?: Record<string, any>;
    preview?: boolean;
    showGiveFeedback?: boolean;
    title?: string;
}

export const ActivityStream: FC<Props> = ({
    assignedToWithType,
    filter = useMemo(() => ({} as ActivityStreamFilterObject), []),
    giveFeedbackData,
    preview = false,
    showGiveFeedback = false,
    title,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isXs, isSm } = useLayout();
    const { setActivityStreamFilterPreFillDataAction } = useActivityStreamFilter();
    const classes = useStyles();
    const { setFeedbackPreFillDataAction } = useBoundActions({
        setFeedbackPreFillData,
    });

    const query = useMemo(
        () => ({
            ...(filter || {}),
        }),
        [filter],
    );

    if (typeof filter.assignedTo?.[0] === 'object') {
        query.assignedTo = filter.assignedTo.map((assigned) => assigned.id);
    }

    const { isLoading, items, pagingActive, queryItems } = useQueriedPaginatedItems<Notification>({
        api: api.activityStreamController.getActivityStream,
        query,
    });

    const handleReadMoreClick = () => {
        setActivityStreamFilterPreFillDataAction({ filter, assignedToWithType });
        navigate('/activityStream');
    };

    const handleOnGiveFeedbackClick = () => {
        if (giveFeedbackData) {
            setFeedbackPreFillDataAction(giveFeedbackData);
        }

        navigate('/feedback/new');
    };

    return (
        <>
            {isXs && showGiveFeedback && (
                <Button
                    variant="contained"
                    onClick={handleOnGiveFeedbackClick}
                    size="large"
                    sx={classes.standAloneButton}
                >
                    {t('activityStream.table.title.giveFeedback.label')}
                </Button>
            )}
            <Card>
                <CardContent sx={classes.noPadding}>
                    <TableContainer
                        sx={{
                            ...classes.tableContainer,
                            ...(preview ? classes.maxHeightTableContainer : {}),
                        }}
                    >
                        <Toolbar sx={classes.activityStreamHeader}>
                            <Typography variant="h4" component="h2">
                                {title || t('activityStream.table.title.label')}
                            </Typography>
                            {!isXs && showGiveFeedback && (
                                <Button variant="contained" onClick={handleOnGiveFeedbackClick}>
                                    {t('activityStream.table.title.giveFeedback.label')}
                                </Button>
                            )}
                        </Toolbar>
                        <Divider />
                        <InfiniteScroll active={pagingActive} onEndOfScreen={queryItems}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {!isXs && (
                                            <TableCell align="left">
                                                <Typography>
                                                    {t(
                                                        'activityStream.table.header.character.label',
                                                    )}
                                                </Typography>
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <Typography>
                                                {t('activityStream.table.header.feedback.label')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography>
                                                {t('activityStream.table.header.pointsCoins.label')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items
                                        .slice(0, preview ? PREVIEW_SIZE : items.length)
                                        .map((feedback) => (
                                            <ActivityStreamEventRow
                                                streamItem={feedback}
                                                key={feedback.id}
                                            />
                                        ))}
                                    {isLoading && (
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <Loading
                                                    transitionEnterDuration={0}
                                                    transitionDelayIn={0}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {!items.length && (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <Typography>
                                                    {t('activityStream.table.noItems.label')}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </TableContainer>
                </CardContent>
                {!!items.length && preview && <div className={classes.veil} />}
                {!isXs && !isSm && <Divider />}
                {preview && (
                    <TableFooter sx={classes.tableActions}>
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Button onClick={handleReadMoreClick}>
                                    {t('activityStream.table.footer.actions.readMore.label')}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Card>
        </>
    );
};
