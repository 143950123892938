import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.storeOrders;

const StoreOrderLoader = Loadable({
    loader: () => import('views/admin/StoreOrder'),
    loading: Loading,
    render(loaded, props) {
        const { StoreOrder } = loaded;
        return <StoreOrder {...props} />;
    },
    delay: general.delay,
});

const StoreOrderEditLoader = Loadable({
    loader: () => import('views/admin/StoreOrder/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const storeOrderRoute = {
    path: `/${mainRoute}`,
    component: StoreOrderLoader,
};

export const storeOrderCreateRoute = {
    path: `/${mainRoute}/create`,
    component: StoreOrderEditLoader,
};

export const storeOrderEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: StoreOrderEditLoader,
};
