import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        content: css({
            display: 'flex',
            alignItems: 'center',
        }),
        dates: {
            marginRight: theme.spacing(1),
        },
        description: css({
            flex: 1,
        }),
    };
};
