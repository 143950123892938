import type { FC, ReactNode } from 'react';
import cx from 'classnames';
import { useStyles } from './styles';

interface Props {
    className?: string;
    children: ReactNode;
}

export const PageContent: FC<Props> = ({ children, className }) => {
    const classes = useStyles();

    return (
        <div className={classes.pageWrapper}>
            <div className={cx(classes.pageContent, className)}>{children}</div>
        </div>
    );
};
