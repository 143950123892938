import type { FC } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { api } from 'services';
import type { StoreItem } from 'types';
import { Button, Card, CardActions, CardContent, CardMedia, Typography, Fade } from '@mui/material';
import { useImage } from 'hooks';
import type { AppReducerState } from 'store';
import { Loading } from '../../Loading';
import { Coins } from '../../Coins';
import { useStyles } from './styles';

interface Props {
    disabled?: boolean;
    showConfirmation?: boolean;
    showLoading?: boolean;
    storeItem: StoreItem;
    onBuyClicked?: (storeItem: StoreItem) => void;
    onBuyConfirmationClicked?: (storeItem: StoreItem) => void;
}

export const StoreItemCard: FC<Props> = ({
    disabled,
    storeItem,
    onBuyClicked,
    onBuyConfirmationClicked,
    showConfirmation = false,
    showLoading = false,
}) => {
    const { descriptionShort, descriptionLong, photoPath, price } = storeItem;
    const { t } = useTranslation();
    const classes = useStyles();
    const { coins } = useSelector(({ user }: AppReducerState) => user.current);
    const { cancelServiceCall, image } = useImage(photoPath);

    const handleItemBuy = () => onBuyClicked?.(storeItem);

    const handleItemBuyConfirmation = () => onBuyConfirmationClicked?.(storeItem);

    useEffect(() => {
        return () => {
            cancelServiceCall();
        };
    }, []);

    return (
        <Card sx={{ ...classes.storeItemCard, ...(disabled ? classes.disabledCard : {}) }}>
            <CardMedia image={image} title={descriptionShort} sx={classes.itemImage} />
            <div className={classes.cardContentWrapper}>
                <CardContent sx={classes.content}>
                    <Typography variant="h6">{descriptionShort}</Typography>
                    <Typography>{descriptionLong}</Typography>
                </CardContent>
                <CardActions>
                    <Loading loading={showLoading} />
                    {!showConfirmation && !showLoading && (
                        <Fade in>
                            <div className={classes.actionsButtonWrapper}>
                                <Button
                                    onClick={handleItemBuy}
                                    variant="contained"
                                    disabled={disabled || coins < price}
                                >
                                    {t('store.storeItemCard.actions.buy')}
                                </Button>
                                <div>
                                    <Coins value={price} />
                                </div>
                            </div>
                        </Fade>
                    )}
                    {showConfirmation && !showLoading && (
                        <Fade in>
                            <Button
                                onClick={handleItemBuyConfirmation}
                                variant="contained"
                                disabled={disabled}
                            >
                                {t('store.storeItemCard.actions.confirmPurchase')}
                            </Button>
                        </Fade>
                    )}
                </CardActions>
            </div>
        </Card>
    );
};
