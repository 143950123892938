export enum SurveyType {
    EMPLOYEE = 'Employee',
    LEAD = 'Lead',
}

export enum QuestionType {
    TEXT = 'Text',
    RADIO = 'Radio',
    SCALE_RADIO = 'Scale',
    CHECKBOX = 'Checkbox',
    SELECT = 'Dropdown',
}

export interface QuestionOption {
    id: string;
    descriptionShort: string;
    descriptionLong?: string;
}

export interface Question {
    id: string;
    order: number;
    type: QuestionType;
    questionType: string;
    mandatory: boolean;
    questionText: string;
    options?: QuestionOption[];
    questionAnswer?: string;
}

export interface QuestionGroup {
    order: number;
    title: string;
    questions: Question[];
}

export interface Step {
    order: number;
    title: string;
    questionGroups: QuestionGroup[];
}

export interface Survey {
    type?: SurveyType;
    steps: Step[];
}
