import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, TextField } from '@mui/material';
import { AlertDialog } from '../AlertDialog';
import { useStyles } from './styles';

interface Props {
    addNew?: any;
    setSearchText?: any;
    showDeleteButton?: boolean;
    handleDelete?: any;
    CustomComponent?: any;
}

export const TableNavbar: FC<Props> = ({
    addNew,
    setSearchText,
    CustomComponent,
    showDeleteButton,
    handleDelete,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [openAlertDialog, setOpenAlertDialog] = useState(false);

    const handleDeleteConfirmation = () => {
        setOpenAlertDialog(true);
    };

    return (
        <>
            <AlertDialog
                open={openAlertDialog}
                setOpen={setOpenAlertDialog}
                title={t('Deletion confirmation')}
                contentText={t("You've selected deletion, please confirm to continue?")}
                handleDelete={handleDelete}
            />
            <div className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item sx={classes.textInput}>
                        {showDeleteButton && (
                            <Button onClick={handleDeleteConfirmation}>Delete selected</Button>
                        )}
                        {!showDeleteButton && setSearchText && (
                            <TextField
                                size="small"
                                label={t('Search')}
                                name="searchText"
                                className="form-control"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchText(e.target.value);
                                }}
                                fullWidth
                            />
                        )}
                    </Grid>
                    {CustomComponent && (
                        <Grid item>
                            <CustomComponent />
                        </Grid>
                    )}
                    {addNew && (
                        <Grid item>
                            <Button onClick={addNew}>{t('Add')}</Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </>
    );
};
