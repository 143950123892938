import type { VFC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DonutChart, Loading } from 'components';
import type { AppReducerState } from 'store';
import { api, callService } from 'services';
import type { ProfileTeam } from 'types';

import {
    Card,
    CardContent,
    CardHeader,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { useStyles } from './styles';

interface AnalyticsData {
    graphTitle: string;
    data: {
        totalFeedbackTeam: number;
        totalFeedbackEmployee?: number;
        percentFeedbackTeam?: number;
        percentFeedbackEmployee?: number;
        period: string;
    }[];
}

interface Props {
    teams: ProfileTeam[];
    userId: string;
}

export const FeedbackChartEmployeeVsTeam: VFC<Props> = ({ teams, userId }) => {
    const [data, setData] = useState<AnalyticsData>();
    const [selectedTeam, setSelectedTeam] = useState<ProfileTeam>(teams[0]);
    const { year } = useSelector(({ configuration }: AppReducerState) => configuration);
    const { t } = useTranslation();
    const classes = useStyles();

    const getAnalyticsData = async () => {
        const { error, payload } = await callService({
            api: api.analyticsController.getPlayerAnalytics,
            params: [userId],
            query: {
                teamId: selectedTeam.id,
                graphId: 'E1',
                periodicity: 1,
                dateFrom: moment(`01/01/${year}`).format('YYYY-MM-DD'),
                dateTo: moment(`12/31/${year}`).format('YYYY-MM-DD'),
            },
        });

        if (!error && payload) {
            setData(payload);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, []);

    const handleTeamFilterChange = (evt) =>
        setSelectedTeam(teams.find((team) => team.id === evt.target.value)!);

    if (!selectedTeam.id) {
        return null;
    }

    if (!data) {
        return <Loading />;
    }

    const { percentFeedbackEmployee = 0, percentFeedbackTeam = 0 } = data.data[0];

    const teamFilter =
        teams.length > 1 ? (
            <FormControl size="small">
                <Select
                    value={selectedTeam.id}
                    onChange={handleTeamFilterChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    }}
                >
                    {teams?.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                            {team.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        ) : null;

    return (
        <Card>
            <CardHeader
                title={
                    <Typography>
                        <strong className={classes.graphTitle}>{data.graphTitle}</strong>
                    </Typography>
                }
                action={teamFilter}
            />
            <CardContent sx={classes.cardContent}>
                <DonutChart
                    data={[
                        {
                            label: t(
                                'jobProfile.jobProfileAnalytics.feedbackChartEmployeeVsTeam.totalFeedbackEmployee.label',
                            ),
                            value: percentFeedbackEmployee,
                            valueLabel: `${percentFeedbackEmployee} %`,
                        },
                        {
                            label: t(
                                'jobProfile.jobProfileAnalytics.feedbackChartEmployeeVsTeam.totalFeedbackTeam.label',
                            ),
                            value: percentFeedbackTeam,
                            valueLabel: `${percentFeedbackTeam} %`,
                        },
                    ]}
                />
            </CardContent>
        </Card>
    );
};
