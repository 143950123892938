import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.surveys;

const SurveyLoader = Loadable({
    loader: () => import('views/admin/Survey'),
    loading: Loading,
    render(loaded, props) {
        const { Survey } = loaded;
        return <Survey {...props} />;
    },
    delay: general.delay,
});

const SurveyEditLoader = Loadable({
    loader: () => import('views/admin/Survey/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const surveyRoute = {
    path: `/${mainRoute}`,
    component: SurveyLoader,
};

export const surveyCreateRoute = {
    path: `/${mainRoute}/create`,
    component: SurveyEditLoader,
};

export const surveyEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: SurveyEditLoader,
};
