import { frankyTalesTheme } from './frankyTalesTheme';

const { buttonColor } = frankyTalesTheme;

export const MuiListItemButton = {
    styleOverrides: {
        root: {
            '&.Mui-selected': {
                backgroundColor: 'transparent',
                color: buttonColor,
            },
        },
    },
};
