import { useState, useEffect, useContext } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/extensions
import 'swiper/css';
// eslint-disable-next-line import/extensions
import 'swiper/css/effect-coverflow';
import { Grid } from '@mui/material';
import { CreateQuestContext } from 'contexts';
import { useLayout } from 'hooks';
import type { Competency } from 'types';
import { api, callService } from 'services';
import type { AppReducerState } from 'store';
import { FeedbackCompetencyCard } from '../../feedback/FeedbackCompetencyCard';
import { SwiperButton } from '../../feedback/FeedbackCompetencyGrid/SwiperButton';
import { useStyles } from './styles';

const groupCompetencies = (array: Competency[][], originalArray: Competency[]) => {
    if (originalArray.length) {
        array.push(originalArray.splice(0, 4));
        return groupCompetencies(array, originalArray);
    }

    return array;
};

export const QuestCompetencyStep: FC = () => {
    const [hoveredCompetency, setHoveredCompetency] = useState('');
    const [slideIndex, setSlideIndex] = useState(0);
    const [competencies, setCompetencies] = useState<Competency[]>([]);
    const [groupedCompetencies, setGroupedCompetencies] = useState<Competency[][]>([]);
    const { setRelatedCompetency } = useContext(CreateQuestContext);
    const { isXs, isSm } = useLayout();
    const { empSysId } = useSelector(({ user }: AppReducerState) => user);
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        (async () => {
            const { error, payload } = await callService({
                api: api.getCompetenciesByEmployeeId,
                params: [empSysId],
            });

            if (!error && payload) {
                setCompetencies(payload);
                setGroupedCompetencies(groupCompetencies([], [...payload]));
            }
        })();
    }, []);

    const handleCompetencyClick = (competency: Competency) => () =>
        setRelatedCompetency(competency.compSysId);

    const handleCompetencyKeyUp = (competency: Competency) => (evt) => {
        if (evt.keyCode === 13) {
            setRelatedCompetency(competency.compSysId);
        }
    };

    const handleCompetencyMouseEnter = (competency: Competency) => () =>
        setHoveredCompetency(competency.compSysId);

    const handleCompetencyMouseLeave = () => setHoveredCompetency('');

    const handleCompetencyFocus = (competency: Competency) => () =>
        setHoveredCompetency(competency.compSysId);

    const handleArrowFocus = () => setHoveredCompetency('');

    const handleSlide = (left) => {
        if (left) {
            if (slideIndex) {
                setSlideIndex(slideIndex - 1);
            } else {
                setSlideIndex(groupedCompetencies.length - 1);
            }
        }

        if (slideIndex === groupedCompetencies.length - 1) {
            setSlideIndex(0);
        } else {
            setSlideIndex(slideIndex + 1);
        }
    };

    if (isXs || isSm) {
        return (
            <Grid container spacing={7}>
                {competencies.map((competency) => (
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        key={competency.compSysId}
                        sx={classes.gridItem}
                        onMouseEnter={handleCompetencyMouseEnter(competency)}
                        onFocus={handleCompetencyFocus(competency)}
                        onMouseLeave={handleCompetencyMouseLeave}
                    >
                        <FeedbackCompetencyCard
                            competency={competency}
                            tabIndex={0}
                            onSelectCompetencyClick={handleCompetencyClick(competency)}
                            onSelectCompetencyKeyUp={handleCompetencyKeyUp(competency)}
                            raised={hoveredCompetency === competency.compSysId}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <div className={classes.spinnerWrapper}>
            <Swiper grabCursor slidesPerView={1} className={classes.slideWrapper} rewind>
                <SwiperButton left onButtonFocused={handleArrowFocus} onSlide={handleSlide} />
                {groupedCompetencies.map((competencyGroup, index) => (
                    <SwiperSlide>
                        {/*eslint-disable-next-line react/no-array-index-key*/}
                        <Grid container spacing={2} key={index} sx={classes.itemWrapper}>
                            {competencyGroup.map((competency) => (
                                <Grid
                                    item
                                    md={3}
                                    key={competency.compSysId}
                                    sx={{
                                        ...classes.gridItem,
                                        ...(hoveredCompetency === competency.compSysId
                                            ? classes.focused
                                            : {}),
                                    }}
                                    onMouseEnter={handleCompetencyMouseEnter(competency)}
                                    onFocus={handleCompetencyFocus(competency)}
                                    onMouseLeave={handleCompetencyMouseLeave}
                                >
                                    <FeedbackCompetencyCard
                                        tabIndex={index === slideIndex ? 0 : undefined}
                                        competency={competency}
                                        onSelectCompetencyClick={handleCompetencyClick(competency)}
                                        onSelectCompetencyKeyUp={handleCompetencyKeyUp(competency)}
                                        raised={hoveredCompetency === competency.compSysId}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </SwiperSlide>
                ))}
                <SwiperButton onButtonFocused={handleArrowFocus} onSlide={handleSlide} />
            </Swiper>
        </div>
    );
};
