import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    InputLabel,
    Select,
    Grid,
    TextField,
    MenuItem,
    Checkbox,
    FormControl,
} from '@mui/material';
import type { SystemInstanceUser } from 'types';
import { USER_ROLES } from 'types';

interface Props {
    disabled?: boolean;
    item: SystemInstanceUser;
    onChange: (item: SystemInstanceUser) => void;
}

export const FtUserItem: FC<Props> = ({ disabled = false, item, onChange }) => {
    const [emailError, setEmailError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [rolesError, setRolesError] = useState(false);
    const { t } = useTranslation();

    const handleEmailChange = (evt) => {
        setEmailError(!evt.target.value);
        onChange({ ...item, email: evt.target.value });
    };

    const handleFirstNameChange = (evt) => {
        setFirstNameError(!evt.target.value);
        onChange({ ...item, firstName: evt.target.value });
    };

    const handleLastNameChange = (evt) => {
        setLastNameError(!evt.target.value);
        onChange({ ...item, lastName: evt.target.value });
    };

    const handleRolesChange = (evt) => {
        setRolesError(!evt.target.value);
        onChange({ ...item, roles: evt.target.value });
    };

    const { email, firstName, lastName, order, roles } = item;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <TextField
                    id={`system-instance-user-email-input-${order}`}
                    label={`${'Email'} *`}
                    type="text"
                    fullWidth
                    value={email}
                    size="small"
                    disabled={disabled}
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={
                        emailError
                            ? t('quest.setObjectiveStep.form.validation.requiredMessage.label')
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    id={`system-instance-user-first-name-input-${order}`}
                    label={`${'First Name'} *`}
                    type="text"
                    fullWidth
                    value={firstName}
                    size="small"
                    disabled={disabled}
                    onChange={handleFirstNameChange}
                    error={firstNameError}
                    helperText={
                        firstNameError
                            ? t('quest.setObjectiveStep.form.validation.requiredMessage.label')
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    id={`system-instance-user-last-name-input-${order}`}
                    label={`${'Last Name'} *`}
                    type="text"
                    fullWidth
                    value={lastName}
                    size="small"
                    disabled={disabled}
                    onChange={handleLastNameChange}
                    error={lastNameError}
                    helperText={
                        lastNameError
                            ? t('quest.setObjectiveStep.form.validation.requiredMessage.label')
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                    <InputLabel
                        id={`system-instance-user-roles-label-${order}`}
                        htmlFor={`system-instance-user-roles-input-${order}`}
                    >{`${'Roles'} *`}</InputLabel>
                    <Select
                        id={`system-instance-user-roles-input-${order}`}
                        multiple
                        value={roles}
                        onChange={handleRolesChange}
                        error={rolesError}
                        disabled={disabled}
                        renderValue={(value) => value.join(', ')}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                        }}
                    >
                        {Object.keys(USER_ROLES)
                            .filter((key) => key !== 'SUBSCRIPTION_MANAGER')
                            .map((key) => (
                                <MenuItem key={key} value={USER_ROLES[key]}>
                                    <Checkbox checked={roles.includes(USER_ROLES[key])} readOnly />
                                    {key}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};
