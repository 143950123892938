import { useContext } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    IconButton,
    LinearProgress,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
} from '@mui/material';
import type { Theme } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { CreateQuestContext } from 'contexts';
import { CreateQuestStep, QuestType } from 'types';
import { useStyles } from './styles';

const STEP_TITLES = {
    [CreateQuestStep.TYPE_STEP]: 'quest.createQuestStepper.stepTitle.questType.label',
    [CreateQuestStep.SKILL_STEP]: 'quest.createQuestStepper.stepTitle.skillStep.label',
    [CreateQuestStep.COMPETENCY_STEP]: 'quest.createQuestStepper.stepTitle.competencyStep.label',
    [CreateQuestStep.OBJECTIVE_STEP]: 'quest.createQuestStepper.stepTitle.objectiveStep.label',
    [CreateQuestStep.USER_STEP]: 'quest.createQuestStepper.stepTitle.playerStep.label',
};

const STEP_VALUES = {
    [CreateQuestStep.TYPE_STEP]: 0,
    [CreateQuestStep.OBJECTIVE_STEP]: 33,
    [CreateQuestStep.USER_STEP]: 66,
};

export const CreateQuestStepper: FC = () => {
    const classes = useStyles();
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
    const { t } = useTranslation();
    const { step, setStep, type } = useContext(CreateQuestContext);

    const handleOnStepClick = (goToStep: CreateQuestStep) => () => {
        if (goToStep < step) {
            setStep(goToStep);
        }
    };

    const handleGoBackClick = () => {
        if (step === CreateQuestStep.OBJECTIVE_STEP && type === QuestType.GROWTH) {
            return setStep(CreateQuestStep.COMPETENCY_STEP);
        }

        if (step === CreateQuestStep.OBJECTIVE_STEP && type === QuestType.SKILL) {
            return setStep(CreateQuestStep.SKILL_STEP);
        }

        if (step === CreateQuestStep.USER_STEP) {
            return setStep(CreateQuestStep.OBJECTIVE_STEP);
        }

        if (
            [
                CreateQuestStep.SKILL_STEP,
                CreateQuestStep.COMPETENCY_STEP,
                CreateQuestStep.OBJECTIVE_STEP,
            ].includes(step)
        ) {
            return setStep(CreateQuestStep.TYPE_STEP);
        }

        return undefined;
    };

    const getActiveStep = () => {
        if (step === CreateQuestStep.OBJECTIVE_STEP && type !== QuestType.TASK) {
            return 2;
        }

        if (step === CreateQuestStep.USER_STEP && type !== QuestType.TASK) {
            return 3;
        }

        if (
            [
                CreateQuestStep.SKILL_STEP,
                CreateQuestStep.COMPETENCY_STEP,
                CreateQuestStep.OBJECTIVE_STEP,
            ].includes(step)
        ) {
            return 1;
        }

        return 2;
    };

    return (
        <div className={classes.maxWidthXs}>
            {!isXs && ![CreateQuestStep.TYPE_STEP, CreateQuestStep.SUCCESS_STEP].includes(step) && (
                <Stepper activeStep={getActiveStep()} sx={classes.stepper} alternativeLabel>
                    <Step completed onClick={handleOnStepClick(CreateQuestStep.TYPE_STEP)}>
                        <StepLabel />
                    </Step>
                    {type === QuestType.SKILL && (
                        <Step
                            completed={step > CreateQuestStep.SKILL_STEP}
                            onClick={handleOnStepClick(CreateQuestStep.SKILL_STEP)}
                        >
                            <StepLabel />
                        </Step>
                    )}
                    {type === QuestType.GROWTH && (
                        <Step
                            completed={step > CreateQuestStep.COMPETENCY_STEP}
                            onClick={handleOnStepClick(CreateQuestStep.COMPETENCY_STEP)}
                        >
                            <StepLabel />
                        </Step>
                    )}
                    <Step
                        completed={step > CreateQuestStep.OBJECTIVE_STEP}
                        onClick={handleOnStepClick(CreateQuestStep.OBJECTIVE_STEP)}
                    >
                        <StepLabel />
                    </Step>
                    <Step completed={false}>
                        <StepLabel />
                    </Step>
                </Stepper>
            )}
            {isXs && ![CreateQuestStep.TYPE_STEP, CreateQuestStep.SUCCESS_STEP].includes(step) && (
                <LinearProgress
                    variant="determinate"
                    value={STEP_VALUES[step] || 0}
                    sx={classes.progress}
                />
            )}
            {step !== CreateQuestStep.SUCCESS_STEP && (
                <Grid
                    container
                    sx={{
                        ...classes.stepTitleContainer,
                        ...(step === CreateQuestStep.TYPE_STEP ? classes.titleTopMargin : {}),
                    }}
                >
                    <Grid item xs={1} sx={classes.backArrow}>
                        {step !== CreateQuestStep.TYPE_STEP && (
                            <IconButton onClick={handleGoBackClick} size="large">
                                <ChevronLeft />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={10} sx={classes.stepTitle}>
                        <Typography align="center" component="h2" variant="h2">
                            {t(STEP_TITLES[step] || '')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            )}
        </div>
    );
};
