import axios from 'axios';
import type {
    IApiResponse,
    IPerfCycleEmpEvalView,
    ISearch,
    ISearchView,
    ICreatePerfCycleEmpEvalCommand,
    IUpdatePerfCycleEmpEvalCommand,
} from 'types';
import { api } from '../constants';

const controllerApi = api.performanceCycleEmplEvaluations;

export const getPerfCycEmpEvals = async (
    queryParams: ISearch,
): Promise<ISearchView<IPerfCycleEmpEvalView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const getBySysId = async (perfCycleEmpEvalId: string): Promise<IPerfCycleEmpEvalView> => {
    return axios.get(`${controllerApi}/${perfCycleEmpEvalId}`).then((res) => res.data);
};

export const create = async (data: ICreatePerfCycleEmpEvalCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (
    id: string,
    data: IUpdatePerfCycleEmpEvalCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};
