import type { VFC } from 'react';
import type { Competency } from 'types';
import { Image } from 'components';
import { Card, CardContent, Typography } from '@mui/material';
import { useStyles } from './styles';

interface Props {
    competency: Competency;
}

export const CompanyValueCard: VFC<Props> = ({ competency }) => {
    const classes = useStyles();

    return (
        <Card sx={classes.card}>
            <CardContent sx={classes.content}>
                <div>
                    <Typography variant="h4">{competency.descriptionShort}</Typography>
                    <Typography>{competency.descriptionLong}</Typography>
                </div>
                <div className={classes.competencyImage}>
                    <Image src={competency.photoPath} alt={competency.descriptionShort} />
                </div>
            </CardContent>
        </Card>
    );
};
