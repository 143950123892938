import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { useBoundActions } from 'hooks';
import { getUsernameErrors, getPasswordErrors } from 'services';
import type { AUTHENTICATE_STATUS, FormValidationError } from 'types';
import { Logo, PasswordInput, UsernameInput } from 'components';
import { logIn } from 'store/slices/user';
import { useStyles } from './styles';
import { useStyles as useCommonStyles } from '../common/styles';

const ERROR_STATUS_TO_SHOW = 400;

interface Props {
    onAuthenticateSuccess: (status?: AUTHENTICATE_STATUS) => void;
    onForgotPasswordClick: () => void;
    onSignUpClick: () => void;
}

export const LoginForm: FC<Props> = ({
    onAuthenticateSuccess,
    onForgotPasswordClick,
    onSignUpClick,
}) => {
    const { logInAction } = useBoundActions({ logIn });
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [serviceError, setServiceError] = useState('');
    const [validationErrors, setValidationErrors] = useState<FormValidationError[]>([]);
    const { t } = useTranslation();
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const usernameError = validationErrors.find((err) => err.field === 'username');
    const passwordError = validationErrors.find((err) => err.field === 'password');

    const getToken = async (event) => {
        event.preventDefault();

        const formErrors: FormValidationError[] = [
            ...getUsernameErrors(username, t),
            ...getPasswordErrors(password, t),
        ];

        setValidationErrors(formErrors);

        if (!formErrors.length) {
            const { error, payload } = await logInAction(username, password);

            if (error?.message && error.status === ERROR_STATUS_TO_SHOW) {
                setServiceError(error.message);
            }

            if (error?.message && error.status !== ERROR_STATUS_TO_SHOW) {
                setServiceError(t<string>('user.login.genericError.label'));
            }

            if (!error && payload) {
                const { status } = payload;

                onAuthenticateSuccess(status);
            }
        }
    };

    const handleUsernameChange = (value) => {
        setUsername(value);
        setValidationErrors([]);
        setServiceError('');
    };

    const handlePasswordChange = (value) => {
        setPassword(value);
        setValidationErrors([]);
        setServiceError('');
    };

    return (
        <>
            <Logo className={classes.logo} disableNavigation />
            <form onSubmit={getToken}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <UsernameInput
                            username={username}
                            usernameError={usernameError}
                            onUsernameChange={handleUsernameChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordInput
                            password={password}
                            onPasswordChange={handlePasswordChange}
                            passwordError={passwordError}
                        />
                    </Grid>
                    {serviceError && (
                        <Grid item xs={12}>
                            <Alert severity="error">{serviceError}</Alert>{' '}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" sx={commonClasses.primaryAction}>
                            {t('user.login.actions.login.label')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={commonClasses.actions}>
                        <Button
                            variant="text"
                            sx={commonClasses.secondaryAction}
                            onClick={onForgotPasswordClick}
                        >
                            {t('user.login.actions.forgotPassword.label')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={commonClasses.actions}>
                        <Typography variant="subtitle2" component="span">
                            New to FrankyTales?
                        </Typography>
                        <Button
                            variant="text"
                            sx={commonClasses.secondaryAction}
                            onClick={onSignUpClick}
                        >
                            {t('user.login.actions.signUp.label')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};
