import { memo } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import { PerformanceCycleEmployeeEvaluationFeatures } from 'types';
import type { PerformanceCycleEmployeeEvaluation } from 'types';
import { EvaluationStatusChip } from '../EvaluationStatusChip';
import { useStyles } from './styles';
import { RaisedCard } from '../../RaisedCard';

const DATE_FORMAT = 'DD.MM.YYYY';
const CELL_TEXT_TYPE_RANGE = 'Range';

interface Props {
    employeeId: string;
    evaluation: PerformanceCycleEmployeeEvaluation;
    onEvaluationClick: (performanceCycleEmployeeEvaluationId: string) => void;
}

export const EvaluationCard: FC<Props> = memo(({ employeeId, evaluation, onEvaluationClick }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        dateFrom,
        dateTo,
        features = [],
        status,
        performanceCycleEmployeeEvaluationId,
        title,
    } = evaluation;

    const handleSelfEvaluateSurvey = () =>
        navigate(`/player/assessment/self/${employeeId}/${performanceCycleEmployeeEvaluationId}`);

    const handleEvaluateSurvey = () =>
        navigate(`/player/assessment/${employeeId}/${performanceCycleEmployeeEvaluationId}`);

    const handleOnClick = () => {
        const cellText = document.getSelection();

        if (cellText?.type !== CELL_TEXT_TYPE_RANGE) {
            onEvaluationClick(performanceCycleEmployeeEvaluationId);
        }
    };

    return (
        <RaisedCard onClick={handleOnClick}>
            <CardContent sx={classes.content}>
                <div>
                    <EvaluationStatusChip evaluationStatus={status} />
                    <Typography variant="caption">
                        {`${moment(dateFrom).format(DATE_FORMAT)} - ${moment(dateTo).format(
                            DATE_FORMAT,
                        )}`}
                    </Typography>
                    <Typography>{<strong>{title}</strong>}</Typography>
                </div>
                <div className={classes.actions}>
                    {features.includes(
                        PerformanceCycleEmployeeEvaluationFeatures.SELF_EVALUATE,
                    ) && (
                        <Button onClick={handleSelfEvaluateSurvey} variant="contained">
                            {t('evaluation.evaluationCard.actions.selfEvaluate.label')}
                        </Button>
                    )}
                    {features.includes(PerformanceCycleEmployeeEvaluationFeatures.EVALUATE) && (
                        <Button onClick={handleEvaluateSurvey} variant="contained">
                            {t('evaluation.evaluationCard.actions.evaluate.label')}
                        </Button>
                    )}
                </div>
            </CardContent>
        </RaisedCard>
    );
});
