import type { NotificationDataLineValue } from 'types';

import { Typography } from '@mui/material';

interface Props {
    item: NotificationDataLineValue;
}

export const EventRowMessageItemBold = ({ item }: Props) => (
    <Typography component="span">
        <strong>{item.displayName}</strong>
    </Typography>
);
