import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        container: css({
            display: 'grid',
            gridTemplateColumns: 'auto auto 1fr',
            gap: theme.spacing(1),
        }),
    };
};
