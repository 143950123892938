import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Card, CardContent, Typography } from '@mui/material';
import { Chat } from '@mui/icons-material';
import { useStyles } from './styles';

interface Props {
    className?: string;
    onSelectTopicClick: (...args: any[]) => void;
    onSelectTopicKeyUp: (...args: any[]) => void;
    raised?: boolean;
    tabIndex?: number;
}

export const FeedbackOpenTopicCard: FC<Props> = ({
    className,
    onSelectTopicClick = () => {},
    onSelectTopicKeyUp = () => {},
    raised = false,
    tabIndex,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Card
            tabIndex={tabIndex != null ? tabIndex : undefined}
            className={className}
            sx={classes.card}
            onClick={onSelectTopicClick}
            onKeyUp={onSelectTopicKeyUp}
            elevation={raised ? 2 : 0}
        >
            <CardContent sx={classes.content}>
                <div className={classes.topicImage}>
                    <Avatar>
                        <Chat />
                    </Avatar>
                </div>
                <Typography variant="h6">
                    {t('feedback.feedbackTopicCard.descriptionShort.label')}
                </Typography>
                <Typography>{t('feedback.feedbackTopicCard.descriptionLong.label')}</Typography>
            </CardContent>
        </Card>
    );
};
