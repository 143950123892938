import { useTheme } from '@mui/material';

const CARD_IMAGE_HEIGHT = 200;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        feedbackImage: {
            height: CARD_IMAGE_HEIGHT,
            width: CARD_IMAGE_HEIGHT,
            backgroundSize: 'contain',
            margin: 'auto',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        marginTop: {
            marginTop: theme.spacing(4),
        },
        header: {
            textAlign: 'center',
        },
    };
};
