import { createTheme } from '@mui/material/styles';
import { MuiButton } from './MuiButton';
import { MuiButtonGroup } from './MuiButtonGroup';
import { MuiCard } from './MuiCard';
import { MuiCardActions } from './MuiCardActions';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiContainer } from './MuiContainer';
import { MuiDialog } from './MuiDialog';
import { MuiDialogActions } from './MuiDialogActions';
import { MuiDivider } from './MuiDivider';
import { MuiFormControl } from './MuiFormControl';
import { MuiFormLabel } from './MuiFormLabel';
import { MuiInputAdornment } from './MuiInputAdornment';
import { MuiInputBase } from './MuiInputBase';
import { MuiListItem } from './MuiListItem';
import { MuiListItemButton } from './MuiListItemButton';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiStepConnector } from './MuiStepConnector';
import { MuiStepIcon } from './MuiStepIcon';
import { MuiLinearProgress } from './MuiLinearProgress';
import { MuiRadio } from './MuiRadio';
import { MuiSelect } from './MuiSelect';
import { MuiSlider } from './MuiSlider';
import { MuiTypography } from './MuiTypography';
import { MuiTab } from './MuiTab';
import { MuiTableHead } from './MuiTableHead';
import { MuiTableRow } from './MuiTableRow';
import { frankyTalesTheme } from './frankyTalesTheme';

export { frankyTalesTheme } from './frankyTalesTheme';

const { borderRadius, colors } = frankyTalesTheme;

export { hexToRgba } from './themeService';

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        h1: {
            fontSize: '2rem',
            fontWeight: 'bold',
            color: colors.darkBlue,
        },
        h2: {
            fontSize: '1.375rem',
            fontWeight: 'bold',
            color: colors.darkBlue,
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: colors.darkBlue,
        },
        h4: {
            fontSize: '1.125rem',
            fontWeight: 'bold',
            color: colors.darkBlue,
        },
        body1: {
            fontSize: '1rem',
            color: colors.darkBlue,
        },
        body2: {
            fontSize: '0.875rem',
            color: colors.darkBlue,
        },
        subtitle1: {
            fontSize: '0.875rem',
        },
        subtitle2: {
            fontSize: '0.75rem',
        },
    },
    components: {
        MuiButton,
        MuiButtonGroup,
        MuiCard,
        MuiCardActions,
        MuiContainer,
        MuiCheckbox,
        MuiDialog,
        MuiDialogActions,
        MuiDivider,
        MuiFormControl,
        MuiFormLabel,
        MuiInputAdornment,
        MuiInputBase,
        MuiListItem,
        MuiListItemButton,
        MuiMenuItem,
        MuiSelect,
        MuiStepConnector,
        MuiStepIcon,
        MuiLinearProgress,
        MuiRadio,
        MuiSlider,
        MuiTab,
        MuiTableHead,
        MuiTableRow,
        MuiTypography,
    },
    palette: {
        primary: {
            main: colors.blue,
        },
        text: {
            primary: colors.darkBlue,
        },
    },
    shape: {
        borderRadius,
    },
    shadows: [
        'none',
        '0px 2px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 8px rgba(228,228,228,0.5)',
        '0px 6px 12px 0px rgba(228,228,228,0.5)',
        '0px 8px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
        '0px 4px 12px 0px rgba(228,228,228,0.5)',
    ],
});
