import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import type { ExperienceLevelValue } from 'types';
import { useStyles } from './styles';
import { FtExperienceLevel } from './FtExperienceLevel';

interface Props {
    disabled?: boolean;
    levels: ExperienceLevelValue[];
    onChange: (levels: ExperienceLevelValue[]) => void;
}

export const FtExperienceLevelsList: FC<Props> = ({ disabled, levels, onChange }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleLevelChange = (item: ExperienceLevelValue) =>
        onChange([
            ...levels.slice(0, item.levelId ?? 0),
            item,
            ...levels.slice((item.levelId ?? 0) + 1),
        ]);

    const handleDeleteLevelClick = (index) => () =>
        onChange(
            [...levels.slice(0, index), ...levels.slice(index + 1)].map((keyResult, levelId) => ({
                ...keyResult,
                levelId,
            })),
        );

    const handleAddLevelClick = () => {
        const { pointsTo = -1 } = levels[levels.length - 1] || {};

        onChange([
            ...levels,
            {
                levelId: levels.length,
                descriptionShort: '',
                descriptionLong: '',
                pointsFrom: pointsTo + 1,
                pointsTo: pointsTo + 2,
            },
        ]);
    };

    return (
        <Grid container spacing={2}>
            {levels?.map((level, index) => (
                <Grid item key={level.levelId} xs={12}>
                    <div className={classes.keyResultInputWrapper}>
                        <FtExperienceLevel
                            level={level}
                            levels={levels}
                            onChange={handleLevelChange}
                            disabled={disabled}
                        />
                        <Button
                            onClick={handleDeleteLevelClick(index)}
                            variant="outlined"
                            size="large"
                            sx={classes.keyResultDeleteButton}
                        >
                            <Icon path={mdiTrashCanOutline} size={1} />
                        </Button>
                    </div>
                </Grid>
            ))}
            <Button onClick={handleAddLevelClick} sx={classes.keyResultAddButton}>
                {t('Add value')}
            </Button>
        </Grid>
    );
};
