import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import {
    Autocomplete,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    CircularProgress,
    Divider,
    InputAdornment,
    List,
    ListItem,
    TextField,
    Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { api, callService } from 'services';
import type { FeedbackTarget } from 'types';
import { FEEDBACK_TARGET_TYPE } from 'types';
import { useStyles } from './styles';
import { Avatar } from '../../avatar';

const MIN_LENGTH_FOR_SEARCH = 2;

const FEEDBACK_TO_TYPES_TRANSLATIONS = {
    [FEEDBACK_TARGET_TYPE.EMPLOYEE]: 'feedback.new.userStep.dropdown.groups.adventurers.label',
    [FEEDBACK_TARGET_TYPE.TEAM]: 'feedback.new.userStep.dropdown.groups.teams.label',
    [FEEDBACK_TARGET_TYPE.JOB]: 'feedback.new.userStep.dropdown.groups.jobs.label',
    [FEEDBACK_TARGET_TYPE.QUEST]: 'feedback.new.userStep.dropdown.groups.quests.label',
};

const FEEDBACK_SUGGESTIONS_TYPES_TRANSLATIONS = {
    [FEEDBACK_TARGET_TYPE.EMPLOYEE]: 'feedback.new.userStep.suggestedPlayers.label',
    [FEEDBACK_TARGET_TYPE.TEAM]: 'feedback.new.userStep.suggestedTeams.label',
    [FEEDBACK_TARGET_TYPE.JOB]: 'feedback.new.userStep.suggestedJobs.label',
    [FEEDBACK_TARGET_TYPE.QUEST]: 'feedback.new.userStep.suggestedQuests.label',
};

interface SuggestedTargets {
    [FEEDBACK_TARGET_TYPE.EMPLOYEE]?: FeedbackTarget[];
    [FEEDBACK_TARGET_TYPE.TEAM]?: FeedbackTarget[];
    [FEEDBACK_TARGET_TYPE.JOB]?: FeedbackTarget[];
    [FEEDBACK_TARGET_TYPE.QUEST]?: FeedbackTarget[];
}

interface Props {
    onGoToNextStep: () => void;
    onTargetsSelected: (targets: FeedbackTarget[]) => void;
    selectedTargets: FeedbackTarget[];
}

export const FeedbackTargetSelection: FC<Props> = ({
    onGoToNextStep,
    onTargetsSelected,
    selectedTargets,
}) => {
    const [searchString, setSearchString] = useState('');
    const [suggestedTargets, setSuggestedTargets] = useState<SuggestedTargets>({});
    const [targets, setTargets] = useState<FeedbackTarget[]>([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    const getTargetsWithGroups = (targetsToMap) =>
        targetsToMap
            .map((target) => ({
                ...target,
                group: t(FEEDBACK_TO_TYPES_TRANSLATIONS[target.type]),
            }))
            .filter((target) => {
                const { type } = selectedTargets[0] || {};

                return !type || type === target.type;
            });

    const handleTargetSelected = (evt, target) => {
        if (target) {
            onTargetsSelected(target);
        }
    };

    const handleSuggestedUserSelected = (target) => () => {
        if (!selectedTargets.find(({ id }) => id === target.id)) {
            handleTargetSelected(null, [...selectedTargets, target]);
        }
    };

    const handleOnOpen = () => setOpen(true);

    const handleOnClose = () => setOpen(false);

    const handleUsersSearch = async (evt) => {
        if (evt.target.value.length >= MIN_LENGTH_FOR_SEARCH) {
            const query = sanitize(evt.target.value, { USE_PROFILES: {} });

            setSearchString(query);
            setIsLoading(true);

            const { error, payload } = await callService({
                api: api.employeeController.getSuggestedEmployeeForFeedback,
                query: { query },
            });

            if (!error && payload) {
                setTargets(getTargetsWithGroups(payload));
                setIsLoading(false);
            }
        } else {
            setSearchString('');
        }
    };

    useEffect(() => {
        (async () => {
            const { error, payload } = await callService({
                api: api.employeeController.getSuggestedEmployeeForFeedback,
            });

            if (!error && payload) {
                setSuggestedTargets(
                    payload.reduce((acc, target) => {
                        if (!acc[target.type]) {
                            return {
                                ...acc,
                                [target.type]: [target],
                            };
                        }

                        return { ...acc, [target.type]: [...acc[target.type], target] };
                    }, {}),
                );
            }
        })();
    }, []);

    return (
        <Card sx={classes.card} className="bigPadding">
            <CardContent>
                <Autocomplete
                    sx={classes.inputChip}
                    value={selectedTargets}
                    multiple
                    onChange={handleTargetSelected}
                    renderTags={(val, getTagProps) =>
                        val.map((option, index: number) => (
                            <Chip
                                size="small"
                                avatar={<Avatar alt={option.initials} src={option.profilePhoto} />}
                                label={option.displayName}
                                {...getTagProps({ index })}
                                key={option.id}
                            />
                        ))
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.displayName}
                    groupBy={(option) => option.group || ''}
                    renderOption={(props, option) => (
                        <li className={classes.avatarWrapper} {...props}>
                            <Avatar
                                alt={option.initials}
                                sx={classes.avatar}
                                src={option.profilePhoto}
                            />
                            <Typography>{option.displayName}</Typography>
                        </li>
                    )}
                    options={targets}
                    popupIcon={null}
                    loading={isLoading}
                    onOpen={handleOnOpen}
                    onClose={handleOnClose}
                    open={
                        !!(searchString.length >= MIN_LENGTH_FOR_SEARCH && targets.length && open)
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={
                                !selectedTargets.length
                                    ? t<string>(
                                          'feedback.new.userStep.form.employeeSearch.placeholder',
                                      )
                                    : undefined
                            }
                            size="small"
                            onChange={handleUsersSearch}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isLoading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
                {Object.keys(suggestedTargets).map((targetGroup) => {
                    const { type } = selectedTargets[0] || {};

                    if (type && type !== targetGroup) {
                        return null;
                    }

                    return (
                        <div className={classes.suggestedEmployees} key={targetGroup}>
                            <Typography variant="button">
                                {t(FEEDBACK_SUGGESTIONS_TYPES_TRANSLATIONS[targetGroup])}
                            </Typography>
                            <List>
                                {suggestedTargets[targetGroup].map((target) => (
                                    <ListItem
                                        key={target.id}
                                        button
                                        sx={classes.suggestedEmployee}
                                        onClick={handleSuggestedUserSelected(target)}
                                    >
                                        <Avatar
                                            src={target.profilePhoto}
                                            alt={target.initials}
                                            sx={classes.avatar}
                                        />
                                        <Typography>{target.displayName}</Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    );
                })}
            </CardContent>
            <Divider />
            <CardActions>
                <Button variant="contained" onClick={onGoToNextStep}>
                    {t('feedback.new.userStep.actions.continue.label')}
                </Button>
            </CardActions>
        </Card>
    );
};
