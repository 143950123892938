import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsFilter } from 'hooks';
import { api, callService } from 'services';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useStyles } from './styles';
import { DonutChart } from '../../chart';
import { Loading } from '../../../Loading';

interface AnalyticsData {
    graphTitle: string;
    data: {
        percentFeedbackNegativeTeam: number;
        percentFeedbackPositiveTeam: number;
    }[];
}

export const PositiveNegativeFeedbackTeamVSCompany: FC = () => {
    const [data, setData] = useState<AnalyticsData>();
    const { filter } = useAnalyticsFilter();
    const { t } = useTranslation();
    const classes = useStyles();

    const { dateFrom, dateTo, hierarchies } = filter;

    const getAnalyticsData = async () => {
        const { error, payload } = await callService({
            api: api.analyticsController.getAnalyticsCulture,
            query: {
                graphId: 'T1',
                teams: hierarchies.join(','),
                dateFrom: dateFrom.format('YYYY-MM-DD'),
                dateTo: dateTo.format('YYYY-MM-DD'),
            },
        });

        if (!error && payload) {
            setData(payload);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, [dateFrom, dateTo, hierarchies]);

    if (!data) {
        return <Loading />;
    }

    const { percentFeedbackNegativeTeam = 0, percentFeedbackPositiveTeam = 0 } = data.data[0];

    return (
        <Card sx={classes.card}>
            <CardHeader
                title={
                    <Typography>
                        <strong className={classes.graphTitle}>{data.graphTitle}</strong>
                    </Typography>
                }
            />
            <CardContent sx={classes.cardContent}>
                <DonutChart
                    donut={false}
                    data={[
                        {
                            label: t(
                                'analytics.graphs.positiveNegativeFeedbackTeamVSCompany.feedbackNegativeTeam.label',
                            ),
                            value: percentFeedbackNegativeTeam,
                            valueLabel: `${percentFeedbackNegativeTeam} %`,
                        },
                        {
                            label: t(
                                'analytics.graphs.positiveNegativeFeedbackTeamVSCompany.feedbackPositiveTeam.label',
                            ),
                            value: percentFeedbackPositiveTeam,
                            valueLabel: `${percentFeedbackPositiveTeam} %`,
                        },
                    ]}
                />
            </CardContent>
        </Card>
    );
};
