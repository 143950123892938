import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.jobTeamRoles;

const JobTeamRoleLoader = Loadable({
    loader: () => import('views/admin/JobTeamRole'),
    loading: Loading,
    render(loaded, props) {
        const { JobTeamRole } = loaded;
        return <JobTeamRole {...props} />;
    },
    delay: general.delay,
});

const JobTeamRoleEditLoader = Loadable({
    loader: () => import('views/admin/JobTeamRole/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const jobTeamRoleRoute = {
    path: `/${mainRoute}`,
    component: JobTeamRoleLoader,
};

export const jobTeamRoleCreateRoute = {
    path: `/${mainRoute}/create`,
    component: JobTeamRoleEditLoader,
};

export const jobTeamRoleEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: JobTeamRoleEditLoader,
};
