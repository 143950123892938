import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LANGUAGE } from 'types';
import type {
    AuthenticateData,
    CurrentUser,
    LoginData,
    PolicyInformation,
    ServiceCallResponse,
    USER_ROLES,
} from 'types';
import type { AppThunk } from 'store';
import { api, callService } from 'services';

export interface UserState {
    current: CurrentUser;
    empSysId?: string;
    systemInstanceSysId: string;
    lng: LANGUAGE;
    loggedIn: boolean;
    roles: USER_ROLES[];
    policyAccepted: boolean;
    policyInformation?: PolicyInformation;
}

export const initialState: UserState = {
    current: {
        coins: 0,
        competencyDescShort: '',
        email: '',
        employeeSysId: '',
        feedbackGiven: 0,
        feedbackReceived: 0,
        feedbackReceivedNotSeen: 0,
        firstName: '',
        language: LANGUAGE.EN,
        lastName: '',
        nickName: '',
        photoPath: '',
        points: 0,
        pointsUntilLevelUp: 0,
        expLevelMaxPoints: 0,
        storeTransactions: 0,
        feedbackGivenCompany: 0,
        feedbackGivenCompanyPercent: 0,
        feedbackGivenHelpfulCompanyPercent: 0,
        feedbackGivenHelpfulPercent: 0,
        feedbackGivenPercent: 0,
    },
    empSysId: '',
    policyAccepted: false,
    policyInformation: undefined,
    roles: [],
    systemInstanceSysId: '',
    lng: LANGUAGE.EN,
    loggedIn: false,
};

const user = createSlice({
    initialState,
    name: 'user',
    reducers: {
        setLanguage: (state, { payload }: PayloadAction<LANGUAGE>) => {
            state.lng = payload;
        },
        setLoggedIn: (state, { payload }: PayloadAction<boolean>) => {
            state.loggedIn = payload;
        },
        logOut: (state) => {
            state.current = initialState.current;
            state.empSysId = initialState.empSysId;
            state.policyAccepted = initialState.policyAccepted;
            state.lng = initialState.lng;
            state.roles = [];
            state.loggedIn = false;
        },
        setLogInData: (state, { payload }: PayloadAction<LoginData>) => {
            const { empSysId, policyInformation } = payload;

            state.empSysId = empSysId;
            state.policyInformation = policyInformation;
            state.policyAccepted = policyInformation.policyAccepted;
            state.loggedIn = true;
        },
        setRoles: (state, { payload }: PayloadAction<USER_ROLES[]>) => {
            state.roles = payload;
        },
        setCurrentUserData: (state, { payload }: PayloadAction<CurrentUser>) => {
            state.current = payload;
        },
        addCoinsToProfile: (state, { payload }: PayloadAction<number>) => {
            state.current.coins += payload;
        },
        subCoinsFromProfile: (state, { payload }: PayloadAction<number>) => {
            state.current.coins -= payload;
            state.current.storeTransactions += 1;
        },
        resetUnseenFeedback: (state) => {
            state.current.feedbackReceivedNotSeen = 0;
        },
        setPolicyAccepted: (state, { payload }: PayloadAction<boolean>) => {
            state.policyAccepted = payload;

            if (!payload) {
                state.policyInformation = undefined;
            }
        },
    },
});

export const {
    actions: {
        setLanguage,
        logOut,
        setLoggedIn,
        setCurrentUserData,
        setLogInData,
        setRoles,
        addCoinsToProfile,
        subCoinsFromProfile,
        resetUnseenFeedback,
        setPolicyAccepted,
    },
    reducer: userReducer,
} = user;

export const acceptPolicy =
    (id: string): AppThunk =>
    async (dispatch) => {
        const { error } = await callService({
            api: api.policiesController.updatePolicy,
            body: {
                policySysId: id,
                accepted: true,
            },
        });

        if (!error) {
            dispatch(setPolicyAccepted(true));

            return true;
        }

        return false;
    };

export const rejectPolicy =
    (id: string): AppThunk =>
    async (dispatch) => {
        const { error } = await callService({
            api: api.policiesController.updatePolicy,
            body: {
                policySysId: id,
                accepted: false,
            },
        });

        if (!error) {
            dispatch(setPolicyAccepted(false));

            return true;
        }

        return false;
    };

export const getEmployeeStats = (): AppThunk => async (dispatch) => {
    const currentUserData = await callService({ api: api.getLoggedInEmployeeStats });

    if (!currentUserData.error) {
        dispatch(setCurrentUserData(currentUserData.payload));
    }
};

export const logIn =
    (username: string, password: string) =>
    async (): Promise<ServiceCallResponse<AuthenticateData>> => {
        return callService({
            api: api.authentication.logIn,
            body: { username, password },
            useToken: false,
        });
    };

export const getToken =
    (code: string) =>
    async (dispatch): Promise<ServiceCallResponse<LoginData>> => {
        const response = await callService({
            api: api.authentication.getToken,
            params: [code],
            useToken: false,
        });

        const { error, payload } = response;

        if (!error && payload) {
            const { employeeLoginView } = payload;
            const { empSysId, policyInformation } = employeeLoginView;

            dispatch(
                setLogInData({
                    empSysId,
                    policyInformation,
                }),
            );

            return response;
        }

        return response;
    };

export const logOutUser = (): AppThunk => async (dispatch, getState) => {
    if (!getState().user.loggedIn) {
        return;
    }

    const response = await callService({ api: api.authentication.deleteToken });

    // if (!response.error) {
    dispatch(logOut());
    // }
};

export const updateAvatarPhoto = (avatarPhoto) => async (dispatch, getState) => {
    const { error, payload } = await callService({
        api: api.updateAvatarPhoto,
        body: avatarPhoto,
    });

    // if (!error && payload) {
    //     const { user: userState } = getState();
    //
    //     dispatch(setCurrentUserData({ ...userState.current, photoPath: avatarPhoto }));
    // }
};
