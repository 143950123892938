import { useCallback } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { sanitize } from 'dompurify';
import { ADVICE_SENTIMENT, FEEDBACK_CHARACTER_TYPE, FEEDBACK_STEP_TYPE } from 'types';
import type { Feedback } from 'types';
import moment from 'moment';
import { useBoundActions, useLayout } from 'hooks';
import { setFeedbackPreFillData } from 'store/slices/feedback';
import { Avatar, Button, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { Mood } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiHeartBroken } from '@mdi/js';
import { api, callService } from 'services';
import { EmployeeAvatar } from '../../avatar';
import { RecipientsList } from '../../activityStream';
import { useStyles } from './styles';
import { useStyles as useCommonStyles } from '../common/styles';

interface Props {
    feedback: Feedback;
    onFeedbackDelete: (feedbackId: string) => void;
}

export const FeedbackListDraftRow: FC<Props> = ({ feedback, onFeedbackDelete }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isXs } = useLayout();
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const { setFeedbackPreFillDataAction } = useBoundActions({ setFeedbackPreFillData });

    const handleContinueDraftClick = useCallback(() => {
        setFeedbackPreFillDataAction({
            ...feedback,
            step: FEEDBACK_STEP_TYPE.FEEDBACK,
            employeeId: feedback.feedbackToEntities?.[0].feedbackToSysId,
        });
        navigate('/feedback/new');
    }, [feedback]);

    const handleDeleteFeedbackClick = useCallback(async () => {
        const { error, payload } = await callService({
            api: api.deleteFeedbackAdvice,
            params: [feedback.feedAdviceSysId],
        });

        if (!error) {
            onFeedbackDelete(payload);
        }
    }, [feedback, onFeedbackDelete]);

    return (
        <TableRow>
            {!isXs && (
                <TableCell className={commonClasses.employeeAvatarWrapper} align="left">
                    {feedback.feedbackCharacterType === FEEDBACK_CHARACTER_TYPE.AVATAR && (
                        <EmployeeAvatar
                            sx={commonClasses.characterAvatar}
                            alt={feedback.adviceFromTitle}
                            src={feedback.adviceFromPhotoPath}
                        />
                    )}
                    {feedback.feedbackCharacterType === FEEDBACK_CHARACTER_TYPE.ADVENTURER && (
                        <EmployeeAvatar
                            alt={feedback.adviceFromTitle}
                            sx={commonClasses.characterAvatar}
                            employeeId={feedback.feedbackToEntities?.[0].feedbackToSysId}
                            src={feedback.adviceFromPhotoPath}
                        />
                    )}
                </TableCell>
            )}
            <TableCell>
                <Typography variant="caption">
                    {moment(feedback.dateCreated).format('DD.MM.YYYY')}
                    {` • ${
                        feedback.competencyTitle ||
                        sanitize(feedback.topic, { USE_PROFILES: {} }) ||
                        t('myFeedback.feedbackListDraftRow.openTopic.label')
                    }`}
                </Typography>
                <Typography>
                    {feedback.adviceSentence}
                    {feedback.adviceSentiment === ADVICE_SENTIMENT.POSITIVE ? (
                        <Mood sx={commonClasses.good} />
                    ) : (
                        <Icon path={mdiHeartBroken} className={commonClasses.bad} />
                    )}
                </Typography>
                <Typography variant="caption" component="p">
                    {feedback.adviceFromTitle}
                </Typography>
                <Paper elevation={0} className={commonClasses.note}>
                    <Typography>
                        <strong>{t('myFeedback.feedbackNote.startingLabel.label')}</strong>{' '}
                        {sanitize(feedback.note, { USE_PROFILES: {} }) ||
                            t('myFeedback.feedbackListDraftRow.notePlaceholder')}
                    </Typography>
                </Paper>
                <Button
                    variant="outlined"
                    size="small"
                    sx={classes.actionsMarginRight}
                    onClick={handleContinueDraftClick}
                >
                    {t('myFeedback.feedbackListDraftRow.actions.edit.label')}
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    sx={classes.actionsMarginRight}
                    onClick={handleDeleteFeedbackClick}
                >
                    {t('myFeedback.feedbackListDraftRow.actions.delete.label')}
                </Button>
            </TableCell>
            <TableCell className={commonClasses.characterAvatarWrapper} align="right">
                <div className={commonClasses.recipientsWrapper}>
                    <RecipientsList
                        entitiesTo={feedback.feedbackToEntities}
                        className={commonClasses.recipientsList}
                    />
                </div>
            </TableCell>
        </TableRow>
    );
};
