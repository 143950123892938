import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        actionsWrapper: {
            width: theme.spacing(8),
            verticalAlign: 'top',

            '& img': {
                width: '100%',
            },
        },
        title: {
            marginBottom: theme.spacing(2),
            flex: '1 0 auto',
        },
        titleWrapper: css({
            display: 'flex',
            alignItems: 'baseline',
        }),
        giveFeedbackButton: {
            marginTop: theme.spacing(1),
        },
    };
};
