const root = {
    '& .MuiTypography-root': {
        fontWeight: 600,
        lineHeight: 1.75,
    },
};

export const MuiFormLabel = {
    styleOverrides: {
        root,
    },
};
