/* eslint-disable max-lines */
import Loadable from 'react-loadable';
import { Loading } from 'components';
import type { Route } from 'types';

const delay = 200;

const HomePage = Loadable({
    loader: () => import('views/Dashboard'),
    loading: Loading,
    render(loaded, props) {
        const { Dashboard } = loaded;
        return <Dashboard {...props} />;
    },
    delay,
});

const MarketplacePage = Loadable({
    loader: () => import('views/Marketplace'),
    loading: Loading,
    render(loaded, props) {
        const { Marketplace } = loaded;
        return <Marketplace {...props} />;
    },
    delay,
});

const Feedback = Loadable({
    loader: () => import('views/GiveFeedback'),
    loading: Loading,
    render(loaded, props) {
        const { GiveFeedback } = loaded;
        return <GiveFeedback {...props} />;
    },
    delay,
});

const FeedbackMy = Loadable({
    loader: () => import('views/MyFeedback'),
    loading: Loading,
    render(loaded, props) {
        const { MyFeedback } = loaded;
        return <MyFeedback {...props} />;
    },
    delay,
});

const UserProfile = Loadable({
    loader: () => import('views/Profile'),
    loading: Loading,
    render(loaded, props) {
        const { Profile } = loaded;
        return <Profile {...props} />;
    },
    delay,
});

const JobProfilePage = Loadable({
    loader: () => import('views/JobProfile'),
    loading: Loading,
    render(loaded, props) {
        const { JobProfile } = loaded;
        return <JobProfile {...props} />;
    },
    delay,
});

const JobFamilyProfilePage = Loadable({
    loader: () => import('views/JobFamilyProfile'),
    loading: Loading,
    render(loaded, props) {
        const { JobFamilyProfile } = loaded;
        return <JobFamilyProfile {...props} />;
    },
    delay,
});

const TeamProfilePage = Loadable({
    loader: () => import('views/TeamProfile'),
    loading: Loading,
    render(loaded, props) {
        const { TeamProfile } = loaded;
        return <TeamProfile {...props} />;
    },
    delay,
});

const CreateQuestPage = Loadable({
    loader: () => import('views/CreateQuest'),
    loading: Loading,
    render(loaded, props) {
        const { CreateQuest } = loaded;
        return <CreateQuest {...props} />;
    },
    delay,
});

const EvaluateQuestPage = Loadable({
    loader: () => import('views/EvaluateQuest'),
    loading: Loading,
    render(loaded, props) {
        const { EvaluateQuest } = loaded;
        return <EvaluateQuest {...props} />;
    },
    delay,
});

const EvaluatePlayerPage = Loadable({
    loader: () => import('views/EvaluatePlayer'),
    loading: Loading,
    render(loaded, props) {
        const { EvaluatePlayer } = loaded;
        return <EvaluatePlayer {...props} />;
    },
    delay,
});

const QuestLogPage = Loadable({
    loader: () => import('views/QuestLog'),
    loading: Loading,
    render(loaded, props) {
        const { QuestLog } = loaded;
        return <QuestLog {...props} />;
    },
    delay,
});

const QuestDetailPage = Loadable({
    loader: () => import('views/QuestDetail'),
    loading: Loading,
    render(loaded, props) {
        const { QuestDetail } = loaded;
        return <QuestDetail {...props} />;
    },
    delay,
});

const UpdateQuestProgressPage = Loadable({
    loader: () => import('views/UpdateQuestProgress'),
    loading: Loading,
    render(loaded, props) {
        const { UpdateQuestProgress } = loaded;
        return <UpdateQuestProgress {...props} />;
    },
    delay,
});

const QuestEditPage = Loadable({
    loader: () => import('views/CreateQuest'),
    loading: Loading,
    render(loaded, props) {
        const { CreateQuest } = loaded;
        return <CreateQuest {...props} />;
    },
    delay,
});

const ActivityStreamPage = Loadable({
    loader: () => import('views/ActivityStream'),
    loading: Loading,
    render(loaded, props) {
        const { ActivityStream } = loaded;
        return <ActivityStream {...props} />;
    },
    delay,
});

const AnalyticsBoard = Loadable({
    loader: () => import('views/Analytics'),
    loading: Loading,
    render(loaded, props) {
        const { Analytics } = loaded;
        return <Analytics {...props} />;
    },
    delay,
});

const SearchPage = Loadable({
    loader: () => import('views/Search'),
    loading: Loading,
    render(loaded, props) {
        const { Search } = loaded;
        return <Search {...props} />;
    },
    delay,
});

const ChangePassword = Loadable({
    loader: () => import('views/Profile/ChangePassword'),
    loading: Loading,
    delay,
});

const GenerateAuthenticatorCodePage = Loadable({
    loader: () => import('views/Profile/GenerateAuthenticatorCode'),
    loading: Loading,
    render(loaded, props) {
        const { GenerateAuthenticatorCode } = loaded;
        return <GenerateAuthenticatorCode {...props} />;
    },
    delay,
});

const NotificationsPage = Loadable({
    loader: () => import('views/Notifications'),
    loading: Loading,
    render(loaded, props) {
        const { Notifications } = loaded;
        return <Notifications {...props} />;
    },
    delay,
});

export const AdminPage = Loadable({
    loader: () => import('views/admin/AdminLayout'),
    loading: Loading,
    render(loaded, props) {
        const { AdminLayout } = loaded;
        return <AdminLayout {...props} />;
    },
    delay,
});

export const routes: Route[] = [
    {
        path: '/',
        exact: true,
        name: 'Dashboard',
        component: HomePage,
        header: true,
        useBackgroundImage: true,
    },
    {
        path: '/dashboard',
        exact: true,
        name: 'Dashboard',
        component: HomePage,
        header: true,
        useBackgroundImage: true,
    },
    {
        path: '/feedback/new',
        exact: true,
        name: 'New Feedback',
        component: Feedback,
        header: false,
    },
    {
        path: '/feedback/my',
        exact: true,
        name: 'My Feedback',
        component: FeedbackMy,
        header: false,
    },
    {
        path: '/marketplace',
        exact: true,
        name: 'Marketplace',
        component: MarketplacePage,
        header: false,
    },
    {
        path: '/marketplace/:tab',
        exact: true,
        name: 'Marketplace',
        component: MarketplacePage,
        header: false,
    },
    {
        path: '/activityStream',
        exact: true,
        name: 'Activity Stream',
        component: ActivityStreamPage,
        header: false,
    },
    {
        path: '/profile',
        exact: true,
        name: 'Profile',
        component: UserProfile,
        header: false,
    },
    {
        path: '/profile/:userId',
        name: 'Profile',
        component: UserProfile,
        headerMatch: '/profile',
        header: false,
    },
    {
        path: '/jobProfile/:jobId',
        name: 'JobProfile',
        component: JobProfilePage,
        header: false,
    },
    {
        path: '/jobFamilyProfile/:jobFamilyId',
        name: 'JobFamilyProfile',
        component: JobFamilyProfilePage,
        header: false,
    },
    {
        path: '/teamProfile/:teamId',
        name: 'TeamProfile',
        component: TeamProfilePage,
        header: false,
    },
    {
        path: '/player/assessment/:employeeId/:performanceCycleEmployeeEvaluationId',
        exact: true,
        name: 'Assessment',
        component: EvaluatePlayerPage,
        header: false,
    },
    {
        path: '/player/assessment/:type/:employeeId/:performanceCycleEmployeeEvaluationId',
        exact: true,
        name: 'Self Assessment',
        component: EvaluatePlayerPage,
        header: false,
    },
    {
        path: '/password-change',
        exact: true,
        name: 'Password Change',
        component: ChangePassword,
        header: true,
        useBackgroundImage: true,
    },
    {
        path: '/generate-authenticator-code',
        exact: true,
        name: 'Generate Authenticator Code',
        component: GenerateAuthenticatorCodePage,
        header: true,
        useBackgroundImage: true,
    },
    {
        path: '/quest/new',
        exact: true,
        name: 'Create Quest',
        component: CreateQuestPage,
        // eslint-disable-next-line max-lines
        header: false,
    },
    {
        path: '/quest/evaluate/:questId',
        exact: true,
        name: 'Evaluate Quest',
        component: EvaluateQuestPage,
        header: false,
    },
    {
        path: '/quest/evaluate/:type/:questId',
        exact: true,
        name: 'Self Evaluate Quest',
        component: EvaluateQuestPage,
        header: false,
    },
    {
        path: '/quest/log',
        exact: true,
        name: 'Quest Log',
        component: QuestLogPage,
        header: false,
    },
    {
        path: '/quest/:questId',
        exact: true,
        name: 'Quest Detail',
        component: QuestDetailPage,
        header: false,
    },
    {
        path: '/quest/edit/:questId',
        exact: true,
        name: 'Quest Detail',
        component: QuestEditPage,
        header: false,
    },
    {
        path: '/quest/updateProgress/:questId',
        exact: true,
        name: 'Update Quest Progress',
        component: UpdateQuestProgressPage,
        header: false,
    },
    {
        path: '/analytics',
        exact: true,
        name: 'Analytics',
        component: AnalyticsBoard,
        header: true,
    },
    {
        path: '/search',
        exact: true,
        name: 'Search',
        component: SearchPage,
    },
    {
        path: '/notifications',
        exact: true,
        name: 'Notifications',
        component: NotificationsPage,
    },
    {
        path: '/admin/*',
        name: 'Admin',
        component: AdminPage,
    },
];
