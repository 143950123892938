import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = () => {
    const theme = useTheme();

    return {
        question: css({
            width: '100%',
            display: 'inline-block',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }),
        title: {
            marginBottom: theme.spacing(2),
        },
    };
};
