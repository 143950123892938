import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = () => {
    const theme = useTheme();

    return {
        card: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            cursor: 'pointer',
        },
        competencyImage: css({
            marginBottom: theme.spacing(3),
            textAlign: 'center',

            '& > img': {
                height: '64px',
            },
        }),
        content: {
            padding: theme.spacing(4),
        },
    };
};
