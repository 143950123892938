import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.securitySettings;

const SecuritySettingsLoader = Loadable({
    loader: () => import('views/admin/SecuritySettings'),
    loading: Loading,
    render(loaded, props) {
        const { SecuritySettings } = loaded;

        return <SecuritySettings {...props} />;
    },
    delay: general.delay,
});

export const securitySettingsRoute = {
    path: `/${mainRoute}`,
    component: SecuritySettingsLoader,
};
