import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (enableEdit) => {
    const theme = useTheme();

    return {
        container: css({
            maxWidth: theme.spacing(90),
            margin: 'auto',
        }),
        profileCard: {
            marginTop: theme.spacing(-12),
        },
        cardContent: {
            marginTop: theme.spacing(enableEdit ? 4 : 7),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        contentName: {
            marginBottom: theme.spacing(1),
        },
        imageContainer: css({
            height: theme.spacing(24),
            display: 'flex',
            justifyContent: 'center',
        }),
        imageWrapper: css({
            display: 'flex',
            justifyContent: 'center',
            height: theme.spacing(24),
            width: theme.spacing(24),
            borderRadius: '50%',
            border: '4px solid white',
            backgroundColor: colors.greyLight,
        }),
        profileImage: css({
            width: theme.spacing(24),
            height: theme.spacing(24),
            borderRadius: '50%',
        }),
        imageUpdateButtonContainer: css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: theme.spacing(24),
            height: theme.spacing(24),
            position: 'absolute',
            textAlign: 'center',
            borderRadius: '50%',
            opacity: 0,
            '&:hover': {
                opacity: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
        }),
        playerStats: css({
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }),
        marginBottom: {
            marginBottom: theme.spacing(1),
        },
    };
};
