import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { AnalyticsJob, AnalyticsHierarchy } from 'types';
import { FormControl, Select, MenuItem, Grid, Checkbox } from '@mui/material';
import { api, callService } from 'services';
import { useAnalyticsFilter } from 'hooks';
import { DateIntervalFilter, Loading } from 'components';
import { useStyles } from './styles';

interface Props {
    onFiltersLoaded?: (err?: Error) => void;
}

export const AnalyticsReportsTabFilter: FC<Props> = ({ onFiltersLoaded }) => {
    const { filter, setAnalyticsFilterPreFillDataAction } = useAnalyticsFilter();
    const [jobs, setJobs] = useState<AnalyticsJob[]>();
    const [selectedDateFrom, setSelectedDateFrom] = useState(filter.dateFrom);
    const [selectedDateTo, setSelectedDateTo] = useState(filter.dateTo);
    const [hierarchies, setHierarchies] = useState<AnalyticsHierarchy[]>();
    const [dateDialogOpen, setDateDialogOpen] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        (async () => {
            const jobsCall = callService({ api: api.analyticsController.getAnalyticsJobs });
            const hierarchiesCall = callService({
                api: api.analyticsController.getAnalyticsHierarchies,
            });

            const [jobsCallResponse, hierarchiesResponse] = await Promise.all([
                jobsCall,
                hierarchiesCall,
            ]);

            if (!jobsCallResponse.error && jobsCallResponse.payload) {
                setJobs(jobsCallResponse.payload);
            } else {
                setJobs([]);
            }

            if (!hierarchiesResponse.error && hierarchiesResponse.payload) {
                setHierarchies(hierarchiesResponse.payload);
            } else {
                setHierarchies([]);
            }

            onFiltersLoaded?.(jobsCallResponse.error || hierarchiesResponse.error);
        })();
    }, []);

    useEffect(() => {
        if (
            !dateDialogOpen &&
            (!selectedDateFrom.isSame(filter.dateFrom) || !selectedDateTo.isSame(filter.dateTo))
        ) {
            setAnalyticsFilterPreFillDataAction({
                filter: { ...filter, dateFrom: selectedDateFrom, dateTo: selectedDateTo },
            });
        }
    }, [dateDialogOpen]);

    const handleSelectedDateFromChange = (date) => setSelectedDateFrom(date);

    const handleSelectedDateToChange = (date) => setSelectedDateTo(date);

    const handleJobFilterChange = (evt) =>
        setAnalyticsFilterPreFillDataAction({
            filter: { ...filter, jobs: evt.target.value },
        });

    const handleHierarchyFilterChange = (evt) =>
        setAnalyticsFilterPreFillDataAction({
            filter: { ...filter, hierarchies: evt.target.value },
        });

    const handleDateIntervalDialogOpen = (open: boolean) => setDateDialogOpen(open);

    if (!jobs || !hierarchies) {
        return <Loading />;
    }

    return (
        <Grid container spacing={2} sx={classes.filterWrapper}>
            <Grid item xs={12} md={4}>
                <DateIntervalFilter
                    selectedDateFrom={selectedDateFrom}
                    selectedDateTo={selectedDateTo}
                    onSelectedDateFromChange={handleSelectedDateFromChange}
                    onSelectedDateToChange={handleSelectedDateToChange}
                    onDialogOpenChange={handleDateIntervalDialogOpen}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl size="small" sx={classes.filter} fullWidth>
                    <Select
                        value={filter.jobs}
                        onChange={handleJobFilterChange}
                        multiple
                        displayEmpty
                        renderValue={() =>
                            !filter.jobs.length
                                ? t('analytics.tabs.culture.filter.allJobs.label')
                                : `${t('analytics.tabs.culture.filter.jobs.label', {
                                      count: filter.jobs.length,
                                  })}`
                        }
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                        }}
                    >
                        {jobs.map((job) => (
                            <MenuItem value={job.id} key={job.id}>
                                <Checkbox
                                    checked={filter.jobs.some(
                                        (selectedJob) => selectedJob === job.id,
                                    )}
                                    readOnly
                                />
                                {job.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl size="small" fullWidth>
                    <Select
                        value={filter.hierarchies}
                        onChange={handleHierarchyFilterChange}
                        multiple
                        displayEmpty
                        renderValue={() =>
                            !filter.hierarchies.length
                                ? t('analytics.tabs.culture.filter.allHierarchies.label')
                                : `${t('analytics.tabs.culture.filter.hierarchies.label', {
                                      count: filter.hierarchies.length,
                                  })}`
                        }
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                        }}
                    >
                        {hierarchies.map((hierarchy) => (
                            <MenuItem
                                value={hierarchy.hierarchySysId}
                                key={hierarchy.hierarchySysId}
                            >
                                <Checkbox
                                    checked={filter.hierarchies.some(
                                        (selectedHierarchy) =>
                                            selectedHierarchy === hierarchy.hierarchySysId,
                                    )}
                                    readOnly
                                />
                                {hierarchy.hierarchyTitle}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};
