import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';
import { QuestStatus } from 'types';

const { colors } = frankyTalesTheme;

const QUEST_STATUS_COLORS = {
    [QuestStatus.DRAFT]: colors.grey,
    [QuestStatus.ACTIVE]: colors.blueDarker,
    [QuestStatus.EVALUATED]: colors.teal,
    [QuestStatus.COMPLETED]: colors.teal,
};

export const useStyles = (quest) => {
    const theme = useTheme();

    return {
        progressArc: css({
            '& [class*=valueArcPath]': {
                fill: QUEST_STATUS_COLORS[quest.status],
            },
        }),
        valueText: css({
            // ...theme.typography.body1,
            fontSize: '0.75rem',
            fontWeight: theme.typography.fontWeightMedium,
            textAnchor: 'middle',
        }),
    };
};
