import type { FC } from 'react';
import type { QuestType } from 'types';
import { Avatar } from '@mui/material';
import { QuestTypeIcon } from '../QuestTypeIcon';
import { useStyles } from './styles';

interface Props {
    className?: string;
    questType: QuestType;
    transparentBackground?: boolean;
}

export const QuestDetailQuestTypeIcon: FC<Props> = ({
    className,
    questType,
    transparentBackground = false,
}) => {
    const classes = useStyles(transparentBackground);

    return (
        <Avatar variant="rounded" className={className} sx={classes.typeIconAvatar}>
            <QuestTypeIcon questType={questType} className={classes.typeIcon} />
        </Avatar>
    );
};
