import { hexToRgba } from './themeService';

const colors = {
    orange: '#e86734',
    orangeDarker: '#ce663d',
    backpackOrange: '#ffbb4e',
    blue: '#4e6cff', // change to purple
    blueLighter1: hexToRgba('#4e6cff', 0.1),
    skyBlue: '#a5b5ff',
    lightSkyBlue: '#e8ecff',
    blueDarker: '#253eb7',
    darkBlue: '#212362',
    darkBlueLighter1: hexToRgba('#212362', 0.1),
    grey: '#8a96a0',
    greyLight: '#cbcbcb',
    greyLighter1: hexToRgba('#8a96a0', 0.1),
    greyLighter2: hexToRgba('#8a96a0', 0.2),
    lightBlue: '#f6f9fc',
    teal: '#57c9c1',
    tealDarker1: '#31b2a9',
    tealLighter1: hexToRgba('#31b2a9', 0.2),
    lightGreen: '#2fc593',
    lightTeal: '#a9e4df',
    peach: '#f2a586',
    lightPeach: '#fee4bc',
    pinkRed: '#e4186a',
    white: '#ffffff',
    logoLightBlue: '#C9D1F8',
};

export const frankyTalesTheme = {
    colors,
    menuWidth: 265,
    borderRadius: 8,
    buttonColor: colors.blue,
    buttonColorHover: colors.blueDarker,
    buttonBackgroundAlphaHover: 0.04,
    buttonTextColor: colors.white,
};
