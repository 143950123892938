import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerSearchResultType } from 'types';
import type { PlayerSearchResultMode, PlayerSearchResult } from 'types';
import { api, callService } from 'services';
import { Loading } from 'components';
import { PlayerSearchResults } from '../PlayerSearchResults';

interface MyTeam {
    peers: PlayerSearchResult[];
    leads: PlayerSearchResult[];
    subordinates: PlayerSearchResult[];
}

interface Props {
    mode: PlayerSearchResultMode;
}

export const PlayerSearchYourTeam: FC<Props> = ({ mode }) => {
    const [myTeam, setPlayers] = useState<MyTeam>();
    const { t } = useTranslation();

    const getPlayers = async () => {
        const { error, payload } = await callService({
            api: api.employeeController.myTeam,
        });

        if (!error && payload) {
            setPlayers(payload);
        }
    };

    useEffect(() => {
        getPlayers();
    }, []);

    if (!myTeam) {
        return <Loading />;
    }

    return (
        <>
            <PlayerSearchResults
                players={myTeam.peers}
                mode={mode}
                type={PlayerSearchResultType.PLAYER}
                title={t('playerSearch.resultType.yourTeam.label')}
            />
            <PlayerSearchResults
                players={myTeam.leads}
                mode={mode}
                type={PlayerSearchResultType.PLAYER}
                title={t('playerSearch.resultType.yourLead.label')}
            />
            <PlayerSearchResults
                players={myTeam.subordinates}
                mode={mode}
                type={PlayerSearchResultType.PLAYER}
                title={t('playerSearch.resultType.yourSubordinates.label')}
            />
        </>
    );
};
