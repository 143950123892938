import type { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import type { FormValidationError } from 'types';
import { FormControl, FormHelperText, InputAdornment, OutlinedInput } from '@mui/material';
import { PersonOutlined } from '@mui/icons-material';

interface Props {
    onUsernameChange?: (...args: any[]) => void;
    username: string;
    usernameError?: FormValidationError;
}

export const UsernameInput: FC<Props> = ({
    onUsernameChange = () => {},
    username,
    usernameError,
}) => {
    const { t } = useTranslation();

    const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) =>
        onUsernameChange(sanitize(event.target.value, { USE_PROFILES: {} }));

    return (
        <FormControl fullWidth size="small" error={!!usernameError}>
            <OutlinedInput
                type="text"
                placeholder={t<string>('form.username.placeholder')}
                value={username}
                autoComplete="email"
                onChange={handleUsernameChange}
                startAdornment={
                    <InputAdornment position="start">
                        <PersonOutlined />
                    </InputAdornment>
                }
            />
            {usernameError && <FormHelperText>{usernameError.message}</FormHelperText>}
        </FormControl>
    );
};
