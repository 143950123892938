import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Card,
    CardActions,
    CardHeader,
    CardMedia,
    Divider,
    Typography,
} from '@mui/material';
import { useGoBackFromNodal } from 'hooks';
import { useStyles } from './styles';

const MIN = 1;
const MAX = 21;

export const QuestUpdateSuccessStep: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const goBackFromNodal = useGoBackFromNodal();
    const random = Math.floor(Math.random() * (MAX - MIN + 1)) + MIN;
    const image = `/gifs/positive_${`000${random}`.slice(-3)}.gif`;

    return (
        <Card sx={classes.marginTop}>
            <CardHeader
                title={
                    <Typography variant="h2">
                        {t('updateQuestProgress.successStep.title.label')}
                    </Typography>
                }
                sx={classes.header}
            />
            <CardMedia
                image={image}
                title={t('feedback.new.successStep.title.label')}
                sx={classes.feedbackImage}
            />
            <Divider />
            <CardActions>
                <Button variant="contained" onClick={goBackFromNodal}>
                    {t('updateQuestProgress.actions.done.label')}
                </Button>
            </CardActions>
        </Card>
    );
};
