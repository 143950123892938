import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import type { FC } from 'react';
import type { Quest } from 'types';
import { usePagination, useQuestLogFilter } from 'hooks';
import { InfiniteScroll, Loading } from 'components';
import { api } from 'services';
import { Grid } from '@mui/material';
import { QuestListCard } from '../QuestListCard';

interface Props {
    onQuestClick(questId: string): void;
}

const SEARCH_DEBOUNCE = 500;

export const QuestList: FC<Props> = ({ onQuestClick }) => {
    const { filter } = useQuestLogFilter();
    const query = {
        ...filter,
    };

    if (typeof filter.assignedTo?.[0] === 'object') {
        query.assignedTo = filter.assignedTo.map((assigned) => assigned.id);
    }

    const {
        isLoading,
        callService,
        cancelServiceCall,
        items,
        currentPage,
        nextPageNumber,
        resetPaging,
        STARTING_PAGE,
    } = usePagination<Quest>({
        api: api.objectiveController.getObjectives,
        query,
    });

    const resetSearch = useCallback(
        _.debounce(() => {
            resetPaging();
        }, SEARCH_DEBOUNCE),
        [resetPaging],
    );

    useEffect(() => {
        if (filter && currentPage !== STARTING_PAGE) {
            resetSearch();
        }
    }, [filter]);

    useEffect(() => {
        if (currentPage === STARTING_PAGE) {
            callService();
        }
    }, [currentPage]);

    useEffect(() => {
        return () => cancelServiceCall();
    }, []);

    return (
        <InfiniteScroll
            active={!isLoading && currentPage < nextPageNumber}
            onEndOfScreen={callService}
        >
            <Grid container spacing={2}>
                {items.map((quest) => (
                    <Grid item key={quest.objectiveId} xs={12}>
                        <QuestListCard quest={quest} onQuestClick={onQuestClick} />
                    </Grid>
                ))}
                {isLoading && (
                    <Grid item xs={12}>
                        <Loading transitionEnterDuration={0} transitionDelayIn={0} />
                    </Grid>
                )}
            </Grid>
        </InfiniteScroll>
    );
};
