import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    Loading,
    NodalPage,
    QuestDetailActions,
    QuestDetailAssignees,
    QuestDetailHeader,
    QuestDetailKeyResults,
    QuestDetailParentQuest,
    QuestDetailSurvey,
} from 'components';
import { Card, CardActions, Container, Divider, Grid } from '@mui/material';
import { api, callService } from 'services';
import { QuestDetailContext } from 'contexts';
import type { Quest } from 'types';
import { useStyles } from './styles';

interface Props {
    closeHandle?(): void;
    questId?: string;
}

export const QuestDetail: FC<Props> = ({ closeHandle, questId: questIdProp }) => {
    const [questDetails, setQuestDetails] = useState<Quest>();
    const [highlightedKeyResult, setHighlightedKeyResult] = useState<number | undefined>();
    const classes = useStyles();
    const { t } = useTranslation();
    const { questId = questIdProp } = useParams();

    const getQuestDetails = async () => {
        const { error, payload } = await callService({
            api: api.objectiveController.getObjective,
            params: [questId],
        });

        if (!error && payload) {
            setQuestDetails(payload);
        }
    };

    useEffect(() => {
        getQuestDetails();
    }, []);

    const context = useMemo(
        () => ({
            highlightedKeyResult,
            setHighlightedKeyResult,
        }),
        [highlightedKeyResult, setHighlightedKeyResult],
    );

    return (
        <NodalPage
            title={t<string>('quest.detail.pageTitle.label')}
            askWhenLeaving={false}
            closeHandle={closeHandle}
        >
            <Container sx={classes.container} maxWidth="sm">
                {!questDetails && <Loading />}
                {questDetails && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card className="bigPadding">
                                <QuestDetailContext.Provider value={context}>
                                    <QuestDetailHeader quest={questDetails} />
                                    <Divider />
                                    <QuestDetailKeyResults quest={questDetails} />
                                    <Divider />
                                    <QuestDetailAssignees quest={questDetails} />
                                    <QuestDetailParentQuest quest={questDetails} />
                                    {!!questDetails.features?.length && (
                                        <>
                                            <Divider />
                                            <CardActions>
                                                <QuestDetailActions
                                                    questDetails={questDetails}
                                                    getQuestDetails={getQuestDetails}
                                                />
                                            </CardActions>
                                        </>
                                    )}
                                </QuestDetailContext.Provider>
                            </Card>
                        </Grid>
                        {questDetails.surveyEmployee && (
                            <Grid item xs={12}>
                                <QuestDetailSurvey survey={questDetails.surveyEmployee} />
                            </Grid>
                        )}
                        {questDetails.surveyLeader && (
                            <Grid item xs={12}>
                                <QuestDetailSurvey survey={questDetails.surveyLeader} />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Container>
        </NodalPage>
    );
};
