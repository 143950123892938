import { useTheme } from '@mui/material';
import { frankyTalesTheme } from 'theme';
import { EmployeeEvaluationStatus } from 'types';

const { colors } = frankyTalesTheme;

const EVALUATION_STATUS_COLORS = {
    [EmployeeEvaluationStatus.NOT_STARTED]: colors.grey,
    [EmployeeEvaluationStatus.IN_PROGRESS]: colors.teal,
    [EmployeeEvaluationStatus.FINISHED]: colors.teal,
    [EmployeeEvaluationStatus.EXPIRED]: colors.orange,
};

export const useStyles = (evaluationStatus) => {
    const theme = useTheme();

    return {
        evaluationStatus: {
            borderColor: EVALUATION_STATUS_COLORS[evaluationStatus],
            color: EVALUATION_STATUS_COLORS[evaluationStatus],
            marginRight: theme.spacing(1),
        },
    };
};
