import type { VFC } from 'react';
import { Grid } from '@mui/material';
import type { ProfileTeam } from 'types';
import { TeamAndRoleCard } from './TeamAndRoleCard';

interface Props {
    teams: ProfileTeam[];
}

export const TeamsAndRoles: VFC<Props> = ({ teams }) => {
    if (!teams.length) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            {teams.map((team) => (
                <Grid item key={team.id} xs={12}>
                    <TeamAndRoleCard team={team} />
                </Grid>
            ))}
        </Grid>
    );
};
