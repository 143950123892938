import type { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import type { ProfileTeam } from 'types';
import { useStyles } from './styles';
import { RaisedCard } from '../../RaisedCard';

interface Props {
    team: ProfileTeam;
}

export const TeamAndRoleCard: VFC<Props> = ({ team }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();

    const handleOnTeamCardClick = (evt?) => {
        evt?.stopPropagation();
        navigate(`/teamProfile/${team.id}`);
    };

    return (
        <RaisedCard onClick={handleOnTeamCardClick}>
            <CardContent sx={classes.content}>
                <div className={classes.iconWrapper}>
                    <Avatar alt={team.name} sx={classes.avatar}>
                        {team.name[0]}
                    </Avatar>
                </div>
                <div className={classes.detail}>
                    <Typography>
                        <strong>{team.name}</strong>
                    </Typography>
                    <Typography variant="caption">{team.teamRoleName}</Typography>
                </div>
                <div>
                    <Button variant="text" onClick={handleOnTeamCardClick}>
                        {t('profile.teamsAndRoles.teamAndRoleCard.actions.goToTeam.label')}
                    </Button>
                </div>
            </CardContent>
        </RaisedCard>
    );
};
