import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        year: {
            marginBottom: theme.spacing(2),
        },
        modal: {
            overflowY: 'scroll',
            '& > div:first-child': {
                backgroundColor: `${colors.lightBlue} !important`,
            },
        },
        wrapper: css({
            marginTop: theme.spacing(4),
            position: 'relative',
            minHeight: theme.spacing(5),
        }),
    };
};
