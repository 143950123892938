import { hexToRgba } from './themeService';
import { frankyTalesTheme } from './frankyTalesTheme';

const { colors } = frankyTalesTheme;

const root = {
    minWidth: '140px !important',
    color: colors.darkBlue,

    '&.MuiTab-textColorPrimary.Mui-disabled': {
        color: hexToRgba(colors.grey, 0.5),
    },
};

const wrapper = {
    fontSize: '0.875rem',
};

export const MuiTab = {
    styleOverrides: {
        root,
        wrapper,
    },
};
