import { useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import type { FormValidationError } from 'types';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material';

interface Props {
    onPasswordChange?: (value: string) => void;
    password: string;
    passwordError?: FormValidationError;
    placeholder?: string;
    hideAdornment?: boolean;
    label?: string;
}

export const PasswordInput: FC<Props> = ({
    onPasswordChange = () => {},
    password,
    passwordError,
    placeholder = i18next.t<string>('form.password.placeholder'),
    hideAdornment = false,
    label,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();

    const handleShowPasswordClick = () => setShowPassword(!showPassword);

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) =>
        onPasswordChange(sanitize(event.target.value, { USE_PROFILES: {} }));
    const startAdornment = hideAdornment ? null : (
        <InputAdornment position="start">
            <LockOutlined />
        </InputAdornment>
    );
    const endAdornment = (
        <InputAdornment position="end" className="greyInputAdornment">
            <IconButton
                aria-label={t<string>('form.password.endAdornment.aria')}
                onClick={handleShowPasswordClick}
                edge="end"
                size="small"
            >
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );
    const placeholderText = label ? undefined : placeholder;

    return (
        <TextField
            type={showPassword ? 'text' : 'password'}
            value={password}
            placeholder={placeholderText}
            label={label}
            autoComplete="current-password"
            onChange={handlePasswordChange}
            InputProps={{
                startAdornment,
                endAdornment,
            }}
            error={!!passwordError}
            helperText={passwordError?.message}
            fullWidth
            size="small"
            variant="outlined"
        />
    );
};
