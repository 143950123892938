import type { ComponentType, FC } from 'react';

import type { USER_ROLES } from './User';

export enum LANGUAGE {
    EN = 'en',
    DE = 'de',
    SK = 'sk',
    SL = 'sl',
    CS = 'cs',
    HR = 'hr',
}

export interface Api {
    call: string;
    method: string;
    url: string;
}

export interface CommonError {
    errorMessage: string;
}

export interface ApiError extends Error {
    status?: number;
}

export interface FormValidationError {
    field: string;
    message: string;
}

export interface ServiceCallResponse<T> {
    error?: ApiError;
    payload: T;
}

export interface Policy {
    id: string;
    title: string;
    version: string;
    policyText: string;
}

export interface PolicyInformation {
    activePolicySysId: string;
    policyAccepted: boolean;
}

export interface LoginData {
    empSysId: string;
    policyInformation: PolicyInformation;
}

export enum AUTHENTICATE_STATUS {
    OK = 'OK',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export interface AuthenticateData {
    status?: AUTHENTICATE_STATUS;
}

export interface SideMenuItem {
    labelKey: string;
    url?: string;
    component?: FC<any>;
    roles?: USER_ROLES[];
}

export enum LEADERBOARD_TYPES {
    POINTS = 'TopPoints',
    HELPFUL = 'TopHelpful',
}

export interface ModalDangerContent {
    title: string;
    message: string;
}

export enum EMAIL_RESET_STATUS {
    SUCCESS = 'Success',
    ERROR = 'Error',
}

export interface Token {
    exp: 0;
    roles: USER_ROLES[];
    companySysId: string;
}

export interface Route {
    path: string;
    exact?: boolean;
    name?: string;
    component: ComponentType;
    header?: boolean;
    useBackgroundImage?: boolean;
    headerMatch?: string;
}
