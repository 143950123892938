import type { ADVICE_SENTIMENT } from './Avatar';

export enum FEEDBACK_SEEN_STATUS {
    NOT_SEEN = 'NotSeen',
    SEEN = 'Seen',
}

export enum FEEDBACK_STATUS {
    DRAFT = 'Draft',
    ACTIVE = 'Active',
    ARCHIVED = 'Archived',
}

export enum FEEDBACK_TYPE {
    ARCHIVED = 'Archived',
    DRAFT = 'Draft',
    RECEIVED = 'Received',
    SENT = 'Sent',
}

export enum FEEDBACK_STEP_TYPE {
    TARGET = 0,
    COMPETENCY = 1,
    AVATAR = 2,
    FEEDBACK = 3,
    SUCCESS = 4,
}

export enum FEEDBACK_RELATION_TYPE {
    CUSTOMER_2_SERVICE = 'Customer2Service',
    SERVICE_2_CUSTOMER = 'Service2Customer',
    MANAGER_2_PEER = 'Manager2Peer',
    PEER_2_MANAGER = 'Peer2Manager',
    PEER_2_PEER = 'Peer2Peer',
}

export interface FeedbackToEntity {
    feedbackToSysId?: string;
    entityId?: string;
    feedbackToType: FEEDBACK_TARGET_TYPE;
    initials: string;
    name: string;
    photoPath: string;
    helpful: boolean;
}

export interface Feedback {
    adviceFromEmployeeId: string;
    adviceFromEmployeeName: string;
    adviceId: string;
    adviceSentence: string;
    adviceSentiment: ADVICE_SENTIMENT;
    adviceToEmployeeSysId: string;
    adviceToEmployeeName: string;
    adviceToEmployeeAvatarPhoto: string;
    avatarHelpfulHow: string;
    avatarId: string;
    avatarPhotoPath: string;
    avatarTitle: string;
    competencyId: string;
    competencyTitle: string;
    dateCreated: string;
    dateShared: string;
    feedAdviceSysId: string;
    feedStatusId: FEEDBACK_STATUS;
    feedStatusSeen?: FEEDBACK_SEEN_STATUS;
    helpful: boolean;
    note?: string;
    topic?: string;
    relationCode: FEEDBACK_RELATION_TYPE;
    subject: string;
    helpfulDateCreated?: string;
    employeeReceivedCoins?: number;
    employeeReceivedPoints?: number;
    feedbackToEntities: FeedbackToEntity[];
    feedbackCharacterType: FEEDBACK_CHARACTER_TYPE;
    feedbackTopicType: FEEDBACK_TOPIC_TYPE;
    adviceFromTitle: string;
    adviceFromPhotoPath: string;
    features: string[];
}

export enum FEEDBACK_TOPIC_TYPE {
    COMPETENCY = 'Competency',
    OPEN_TOPIC = 'OpenTopic',
}

export enum FEEDBACK_CHARACTER_TYPE {
    AVATAR = 'Avatar',
    ADVENTURER = 'Adventurer',
}

export enum FEEDBACK_TARGET_TYPE {
    EMPLOYEE = 'Employee',
    TEAM = 'Team',
    JOB = 'Job',
    QUEST = 'Objective',
}

export interface FeedbackTarget {
    displayName: string;
    id: string;
    initials: string;
    profilePhoto?: string;
    type: FEEDBACK_TARGET_TYPE;
    group?: string;
}

export enum FEEDBACK_SHARE_TYPE {
    SHARE = 'Share',
    UN_SHARE = 'Unshare',
}
