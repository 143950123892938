/* eslint-disable no-restricted-globals */
export const handleAntiClickJack = () => {
    if (top && self !== top && self.location.hostname !== 'localhost') {
        top.location = self.location;

        throw new Error('Tried to load in iframe');
    }

    const antiClickJack = document.getElementById('antiClickJack');

    antiClickJack!.parentNode!.removeChild(antiClickJack!);
};
