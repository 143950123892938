export const MuiStepConnector = {
    styleOverrides: {
        root: {
            top: '16px',
        },
        alternativeLabel: {
            top: '16px',
        },
        line: {
            borderColor: '#87a2b5',
        },
    },
};
