import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import { useQuestLogFilter } from 'hooks';
import { QuestType } from 'types';
import type { Quest } from 'types';
import { EvaluationQuestCard } from '../EvaluationQuestCard';
import { useStyles } from './styles';

interface Props {
    questType: QuestType;
    quests: Quest[];
}

const QUEST_TYPE_TRANSLATION_KEYS = {
    [QuestType.SKILL]: 'evaluation.evaluationQuestList.title.skill.label',
    [QuestType.GROWTH]: 'evaluation.evaluationQuestList.title.growth.label',
    [QuestType.TASK]: 'evaluation.evaluationQuestList.title.task.label',
};

const NUM_QUESTS_TO_SHOW = 3;

export const EvaluationQuestList: FC<Props> = ({ quests, questType }) => {
    const { setQuestLogFilterPreFillDataAction } = useQuestLogFilter();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();

    const handleButtonClick = () => {
        setQuestLogFilterPreFillDataAction({ filter: { type: [questType] } });
        navigate('/quest/log');
    };

    if (!quests.length) {
        return null;
    }

    return (
        <>
            <div className={classes.titleWrapper}>
                <Typography variant="h2" component="h4" sx={classes.title}>
                    {t(QUEST_TYPE_TRANSLATION_KEYS[questType])}
                </Typography>
                {quests.length > NUM_QUESTS_TO_SHOW && (
                    <Button onClick={handleButtonClick}>
                        {t('evaluation.evaluationQuestList.actions.goToQuestLog.label')}
                    </Button>
                )}
            </div>
            <Grid container spacing={2}>
                {quests.slice(0, NUM_QUESTS_TO_SHOW).map((quest) => (
                    <Grid item key={quest.objectiveId} xs={12}>
                        <EvaluationQuestCard quest={quest} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
