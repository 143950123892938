import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { AppReducerState } from 'store';

export const useGoBackFromNodal = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const nodalBackPage = useSelector(({ appState }: AppReducerState) => appState.nodalBackPage);

    return () => {
        navigate(-1);
        // if (location.key === 'default') {
        //     navigate(nodalBackPage);
        // } else {
        //     navigate('/dashboard');
        // }
    };
};
