import { useEffect, useMemo } from 'react';
import { api } from 'services';
import { useService } from './useService';
import { usePreviousValue } from './usePreviousValue';

export const useImage = (id: string) => {
    const previousId = usePreviousValue(id);
    const { data, error, isLoading, cancelServiceCall, callService } = useService<Blob>({
        api: api.storagesController.getImage,
        params: [id],
        fetchOnMount: false,
    });

    const image = useMemo(() => (data ? URL.createObjectURL(data) : undefined), [data]);

    useEffect(() => {
        return () => {
            if (isLoading) {
                cancelServiceCall();
            }

            if (image) {
                URL.revokeObjectURL(image);
            }
        };
    }, []);

    useEffect(() => {
        if (previousId !== id) {
            if (isLoading) {
                cancelServiceCall();
            }

            try {
                callService();
            } catch (err) {
                console.log(err);
            }
        }
    }, [id]);

    return {
        cancelServiceCall,
        error,
        isLoading,
        image,
    };
};
