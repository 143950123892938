import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { sanitize } from 'dompurify';
import { ADVICE_SENTIMENT, FEEDBACK_CHARACTER_TYPE } from 'types';
import type { Feedback } from 'types';
import moment from 'moment';
import { Avatar, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { Mood } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiHeartBroken } from '@mdi/js';
import { api } from 'services';
import { useLayout } from 'hooks';
import { EmployeeAvatar } from '../../avatar';
import { Coins } from '../../Coins';
import { Experience } from '../../Experience';
import { RecipientsList } from '../../activityStream';
import { useStyles } from '../common/styles';

const HELPFUL_DISPLAY_THRESHOLD = 2;

interface Props {
    feedback: Feedback;
}

export const FeedbackListSentRow: FC<Props> = ({ feedback }) => {
    const { isXs } = useLayout();
    const { t } = useTranslation();
    const commonClasses = useStyles();

    const { helpful } = feedback.feedbackToEntities[0] || feedback;

    return (
        <TableRow>
            {!isXs && (
                <TableCell className={commonClasses.employeeAvatarWrapper} align="left">
                    {feedback.feedbackCharacterType === FEEDBACK_CHARACTER_TYPE.AVATAR && (
                        <EmployeeAvatar
                            sx={commonClasses.characterAvatar}
                            alt={feedback.adviceFromTitle}
                            src={feedback.adviceFromPhotoPath}
                        />
                    )}
                    {feedback.feedbackCharacterType === FEEDBACK_CHARACTER_TYPE.ADVENTURER && (
                        <EmployeeAvatar
                            alt={feedback.adviceFromTitle}
                            sx={commonClasses.characterAvatar}
                            employeeId={feedback.feedbackToEntities?.[0].feedbackToSysId}
                            src={feedback.adviceFromPhotoPath}
                        />
                    )}
                </TableCell>
            )}
            <TableCell>
                <Typography variant="caption">
                    {moment(feedback.dateCreated).format('DD.MM.YYYY')}
                    {` • ${
                        feedback.competencyTitle ||
                        sanitize(feedback.topic, { USE_PROFILES: {} }) ||
                        t('myFeedback.feedbackListSentRow.openTopic.label')
                    }`}
                </Typography>
                <Typography>
                    {feedback.adviceSentence}
                    {feedback.adviceSentiment === ADVICE_SENTIMENT.POSITIVE ? (
                        <Mood sx={commonClasses.good} />
                    ) : (
                        <Icon path={mdiHeartBroken} className={commonClasses.bad} />
                    )}
                </Typography>
                <Typography variant="caption" component="p">
                    {feedback.adviceFromTitle}
                </Typography>
                {feedback.note && (
                    <Paper elevation={0} className={commonClasses.note}>
                        <Typography>
                            <strong>{t('myFeedback.feedbackNote.startingLabel.label')}</strong>{' '}
                            {sanitize(feedback.note, { USE_PROFILES: {} })}
                        </Typography>
                    </Paper>
                )}
                {helpful != null &&
                    feedback.feedbackToEntities.length < HELPFUL_DISPLAY_THRESHOLD && (
                        <Typography variant="caption">
                            {t('myFeedback.feedbackListSentRow.helpfulSentence')}
                            <span
                                className={cx({
                                    [commonClasses.orange]: !helpful,
                                    [commonClasses.teal]: helpful,
                                })}
                            >
                                {helpful && t('myFeedback.feedbackListSentRow.helpful')}
                                {!helpful && t('myFeedback.feedbackListSentRow.notHelpful')}
                            </span>
                        </Typography>
                    )}
            </TableCell>
            <TableCell className={commonClasses.characterAvatarWrapper} align="right">
                <div className={commonClasses.recipientsWrapper}>
                    <RecipientsList
                        entitiesTo={feedback.feedbackToEntities}
                        className={commonClasses.recipientsList}
                    />
                    <Coins value={feedback.employeeReceivedCoins} displaySign />
                    <Experience value={feedback.employeeReceivedPoints} />
                </div>
            </TableCell>
        </TableRow>
    );
};
