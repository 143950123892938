import { css } from '@emotion/css';

export const useStyles = (): any => ({
    avatar: {
        cursor: 'pointer',
    },
    image: css({
        height: 'inherit',
        width: 'inherit',
    }),
});
