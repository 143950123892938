import React from 'react';
import ReactDOM from 'react-dom/client';
import { handleAntiClickJack } from 'utils/FrameBust';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

try {
    handleAntiClickJack();

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
} catch (e) {
    console.log('Failed to load');
}
