import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type SearchFilterPreFillData = Record<string, any> | undefined;

export interface QuestState {
    searchFilterPreFillData?: SearchFilterPreFillData;
}

const initialState: QuestState = {
    searchFilterPreFillData: undefined,
};

const search = createSlice({
    initialState,
    name: 'search',
    reducers: {
        setSearchFilterPreFillData: (
            state,
            { payload }: PayloadAction<SearchFilterPreFillData>,
        ) => {
            state.searchFilterPreFillData = payload;
        },
    },
});

export const {
    actions: { setSearchFilterPreFillData },
    reducer: searchReducer,
} = search;
