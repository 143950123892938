import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        valueText: css({
            // ...theme.typography.body1,
            textAnchor: 'middle',
            fill: theme.palette.text.primary,
        }),
    };
};
