import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

interface BulkImportStep2Props {
    ImportedDataTable: any;
    handleImport(): any;
    data: any;
    prevStep(): any;
}

export const BulkImportStep2: FC<BulkImportStep2Props> = ({
    ImportedDataTable,
    data,
    prevStep,
    handleImport,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        if (data)
            setHasErrors(
                data.some((x) => x.error.length > 0 || (!Array.isArray(x.error) && x.error)),
            );
    }, [data]);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ImportedDataTable data={data} />
                </div>
            </div>
            {!hasErrors && <div className={classes.msgSuccess}>{data.length} records loaded.</div>}
            {hasErrors && (
                <div className={classes.msgError}>
                    There are some errors, please correct the data and repeat the import.
                </div>
            )}
            <div className="row mt-3">
                <div className="col-6">
                    <button className="btn btn-outline-secondary" onClick={prevStep}>
                        Back
                    </button>
                </div>
                <div className="col-6 text-right">
                    <button className="btn btn-primary" disabled={hasErrors} onClick={handleImport}>
                        Import the data
                    </button>
                </div>
            </div>
        </>
    );
};
