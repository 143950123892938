import { frankyTalesTheme } from 'theme';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => ({
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    gridItemIcon: css({
        justifyContent: 'center',
        textAlign: 'center',
    }),
    iconPoints: {
        backgroundColor: colors.lightGreen,
    },
    iconGameLevel: {
        backgroundColor: colors.orange,
    },
    iconWallet: {
        backgroundColor: colors.backpackOrange,
    },
});
