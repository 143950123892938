import type { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import type { PlayerSearchResult } from 'types';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { People, ShowChart } from '@mui/icons-material';
import { Backpack } from '../Backpack';
import { Coins } from '../../Coins';
import { useStyles } from './styles';

interface Props {
    className?: string;
    onBackpackClick?: () => void;
    player: PlayerSearchResult;
}

export const PlayerStats: VFC<Props> = ({ className, onBackpackClick, player }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container spacing={1} className={cx(className)}>
            <Grid item xs={4} sx={classes.gridItem}>
                <Avatar sx={classes.iconGameLevel}>
                    <People />
                </Avatar>
                <Box justifyContent="center" p={1}>
                    <Typography variant="subtitle2">{t('profile.info.level')}</Typography>
                </Box>
                <Typography variant="h4">{player.experienceLevel}</Typography>
            </Grid>
            <Grid item xs={4} sx={classes.gridItem}>
                <div className={classes.gridItemIcon}>
                    <Avatar sx={classes.iconPoints}>
                        <ShowChart />
                    </Avatar>
                </div>
                <Box justifyContent="center" p={1}>
                    <Typography variant="subtitle2">{t('profile.info.experience')}</Typography>
                </Box>
                <Typography variant="h4">{player.points}</Typography>
            </Grid>
            <Grid item xs={4} sx={classes.gridItem}>
                <div className={classes.gridItemIcon}>
                    <Avatar sx={classes.iconWallet} onClick={onBackpackClick}>
                        <Backpack />
                    </Avatar>
                </div>
                <Box justifyContent="center" p={1}>
                    <Typography variant="subtitle2">{t('profile.info.inventory')}</Typography>
                </Box>
                <Coins value={player.coins} variant="h4" />
            </Grid>
        </Grid>
    );
};
