import type { Scale } from 'types';
import { RULER_ORIENTATION } from 'types';
import { useStyles } from './styles';
import { getAxisTicks } from '../Axis';

interface Props<T, K> {
    numberOfTicks?: number;
    orientation?: RULER_ORIENTATION;
    ticks?: T[];
    xScale: Scale<T>;
    yScale: Scale<K>;
}

export const Ruler = <T, K>({
    numberOfTicks,
    orientation = RULER_ORIENTATION.HORIZONTAL,
    ticks,
    xScale,
    yScale,
}: Props<T, K>) => {
    const tickValues = getAxisTicks(xScale, numberOfTicks, ticks);
    const xRange = xScale.range();
    const yRange = yScale.range();
    const [[xMin, xMax], [yMin, yMax]] =
        orientation === RULER_ORIENTATION.HORIZONTAL ? [yRange, xRange] : [xRange, yRange];
    const classes = useStyles();

    if (!tickValues.length) {
        return null;
    }

    return (
        <g>
            {tickValues.map((tick) => {
                const scaledCoordinate = xScale(tick);
                const [x1, x2, y1, y2] =
                    orientation === RULER_ORIENTATION.HORIZONTAL
                        ? [xMin, xMax, scaledCoordinate, scaledCoordinate]
                        : [scaledCoordinate, scaledCoordinate, yMin, yMax];

                return [
                    <line
                        key={String(tick)}
                        x1={Number(x1)}
                        y1={Number(y1)}
                        x2={Number(x2)}
                        y2={Number(y2)}
                        className={classes.rulerLine}
                    />,
                ];
            })}
        </g>
    );
};
