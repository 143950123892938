import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.systemInstances;

const SystemInstanceLoader = Loadable({
    loader: () => import('views/admin/SystemInstance'),
    loading: Loading,
    render(loaded, props) {
        const { SystemInstance } = loaded;
        return <SystemInstance {...props} />;
    },
    delay: general.delay,
});

const SystemInstanceEditLoader = Loadable({
    loader: () => import('views/admin/SystemInstance/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const systemInstanceRoute = {
    path: `/${mainRoute}`,
    exact: true,
    name: 'System Instances',
    component: SystemInstanceLoader,
    details: {
        authenticationRequired: true,
        systemInstanceRequired: false,
        companyRequired: false,
    },
};

export const systemInstanceCreateRoute = {
    path: `/${mainRoute}/create`,
    exact: true,
    name: 'Create',
    component: SystemInstanceEditLoader,
    details: {
        authenticationRequired: true,
        systemInstanceRequired: false,
        companyRequired: false,
    },
};

export const systemInstanceEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    exact: true,
    name: 'Edit',
    component: SystemInstanceEditLoader,
    details: {
        authenticationRequired: true,
        systemInstanceRequired: false,
        companyRequired: false,
    },
};
