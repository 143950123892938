import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@mui/material';
import type { ExperienceLevelValue } from 'types';

interface Props {
    disabled?: boolean;
    level: ExperienceLevelValue;
    levels: ExperienceLevelValue[];
    onChange: (item: ExperienceLevelValue) => void;
}

export const FtExperienceLevel: FC<Props> = ({ disabled = false, level, levels, onChange }) => {
    const [descriptionShortError, setDescriptionShortError] = useState(false);
    const [descriptionLongError, setDescriptionLongError] = useState(false);
    const [pointsFromError, setPointsFromError] = useState('');
    const [pointsToError, setPointsToError] = useState('');
    const { t } = useTranslation();
    const { descriptionShort, descriptionLong, pointsFrom, pointsTo } = level;

    const handleDescriptionShortChange = (evt) => {
        setDescriptionShortError(!evt.target.value);
        onChange({ ...level, descriptionShort: evt.target.value });
    };

    const handleDescriptionLongChange = (evt) => {
        setDescriptionLongError(!evt.target.value);
        onChange({ ...level, descriptionLong: evt.target.value });
    };

    const validatePoints = (from, to) => {
        let pointFromError = '';
        let pointToError = '';
        const { pointsTo: previousTo } = levels[level.levelId - 1] || {};
        const { pointsFrom: nextFrom } = levels[level.levelId + 1] || {};

        if (from == null) {
            pointFromError = t('quest.setObjectiveStep.form.validation.requiredMessage.label');
        }

        if (from >= to) {
            pointFromError = t('Value needs to be lower than "points to" value');
        }

        if (previousTo != null && from <= previousTo) {
            pointFromError = t('Value needs to be higher than the previous "points to" value');
        }

        if (to == null) {
            pointToError = t('quest.setObjectiveStep.form.validation.requiredMessage.label');
        }

        if (to <= from) {
            pointToError = t('Value needs to be higher than the "points from" value');
        }

        if (nextFrom != null && to >= nextFrom) {
            pointToError = t('Value needs to be lower than the next "points from" value');
        }

        setPointsFromError(pointFromError);
        setPointsToError(pointToError);
    };

    const handlePointsFromChange = (evt) => {
        const { value } = evt.target;
        const numValue = Number(value);

        validatePoints(numValue, pointsTo);
        onChange({ ...level, pointsFrom: numValue });
    };

    const handlePointsToChange = (evt) => {
        const { value } = evt.target;
        const numValue = Number(value);

        validatePoints(pointsFrom, numValue);
        onChange({ ...level, pointsTo: numValue });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <TextField
                    label={`${'Description Short'} *`}
                    type="text"
                    fullWidth
                    value={descriptionShort}
                    size="small"
                    disabled={disabled}
                    onChange={handleDescriptionShortChange}
                    error={descriptionShortError}
                    helperText={
                        descriptionShortError
                            ? t('quest.setObjectiveStep.form.validation.requiredMessage.label')
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    label={`${'Description Long'} *`}
                    type="text"
                    fullWidth
                    value={descriptionLong}
                    size="small"
                    disabled={disabled}
                    onChange={handleDescriptionLongChange}
                    error={descriptionLongError}
                    helperText={
                        descriptionLongError
                            ? t('quest.setObjectiveStep.form.validation.requiredMessage.label')
                            : ''
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    label={`${'Points From'} *`}
                    type="number"
                    fullWidth
                    value={pointsFrom}
                    size="small"
                    disabled={disabled}
                    onChange={handlePointsFromChange}
                    error={!!pointsFromError}
                    helperText={pointsFromError}
                    inputProps={{ min: 0 }}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    label={`${'Points To'} *`}
                    type="number"
                    fullWidth
                    value={pointsTo}
                    size="small"
                    disabled={disabled}
                    onChange={handlePointsToChange}
                    error={!!pointsToError}
                    helperText={pointsToError}
                    inputProps={{ min: 1 }}
                />
            </Grid>
        </Grid>
    );
};
