import { css } from '@emotion/css';

const CARD_IMAGE_HEIGHT = 150;

export const useStyles = (): any => ({
    itemImage: css({
        height: `${CARD_IMAGE_HEIGHT}px`,
        minHeight: `${CARD_IMAGE_HEIGHT}px`,
        maxWidth: '100%',
    }),
    dateColumn: {
        width: '9%',
    },
    priceColumn: {
        width: '12%',
    },
    arrowColumn: {
        width: '5%',
    },
    collapsibleCell: {
        padding: 0,
        width: '100%',
    },
    imageCell: {
        width: '30%',
    },
    detailCell: {
        width: '70%',
        verticalAlign: 'top',
    },
});
