import type { FC } from 'react';
import { QuestionType } from 'types';
import type { Question as QuestionT } from 'types';
import { TextQuestion } from '../TextQuestion';
import { RadioQuestion } from '../RadioQuestion';
import { SelectQuestion } from '../SelectQuestion';

const QUESTION_INPUTS = {
    [QuestionType.TEXT]: TextQuestion,
    [QuestionType.RADIO]: RadioQuestion,
    [QuestionType.SCALE_RADIO]: RadioQuestion,
    [QuestionType.SELECT]: SelectQuestion,
};

interface Props {
    className?: string;
    question: QuestionT;
    showAsAnswer?: boolean;
    globalValidation?: boolean;
}

export const Question: FC<Props> = ({ question, ...rest }) => {
    const { type } = question;
    const Component = QUESTION_INPUTS[type];

    return <Component question={question} {...rest} />;
};
