import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { UserType } from 'types';
import type { EvaluationUser } from 'types';

import { useStyles } from './styles';
import { EmployeeAvatar } from '../avatar';

interface Props {
    user: EvaluationUser;
}

export const EmployeeSection: FC<Props> = ({ user }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();

    const { employeeId, type, displayName, jobTitle, profilePhoto } = user;

    const typeTranslation =
        type === UserType.EMPLOYEE
            ? t('employeeSection.type.employee.label')
            : t('employeeSection.type.leadManager.label');

    const handleSectionClick = () => {
        if (employeeId) {
            navigate(`/profile/${employeeId}`);
        }
    };
    const handleSectionKeyUp = () => (evt) => {
        if (evt.keyCode === 13) {
            navigate(`/profile/${employeeId}`);
        }
    };

    return (
        <div
            className={classes.wrapper}
            onClick={handleSectionClick}
            role="link"
            tabIndex={0}
            onKeyUp={handleSectionKeyUp}
        >
            <EmployeeAvatar alt={displayName} src={profilePhoto} />
            <div className={classes.dataWrapper}>
                <Typography component="span">{typeTranslation}</Typography>
                <Typography component="span">
                    <strong>{displayName}</strong>
                </Typography>
                <Typography component="span">{jobTitle}</Typography>
            </div>
        </div>
    );
};
