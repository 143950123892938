import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        typeName: {
            fontWeight: theme.typography.fontWeightBold,
        },
        questTypeCard: {
            width: theme.spacing(25),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            margin: 'auto',
        },
        gridItem: {
            display: 'flex',
            transition: 'margin 70ms, padding 70ms',

            paddingTop: `${theme.spacing(4)} !important`,
            paddingBottom: `${theme.spacing(4)} !important`,

            '&:hover &:focus': {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(6),
            },
        },
        focused: {
            paddingTop: `${theme.spacing(2)} !important`,
            paddingBottom: `${theme.spacing(6)} !important`,
        },
        questTypePicker: {
            marginTop: theme.spacing(-2),
        },
    };
};
