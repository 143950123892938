import type { FC } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Event } from '@mui/icons-material';
import type { Quest } from 'types';
import { useStyles } from './styles';

const DATE_FORMAT = 'DD.MM.YYYY';
const MAX_PERCENTAGE = 100;

interface Props {
    quest: Quest;
}

export const QuestDuration: FC<Props> = ({ quest }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { dateFrom, dateTo } = quest;
    const momentDateFrom = moment(dateFrom);
    const momentDateTo = moment(dateTo);
    const nowMinusFrom = moment().valueOf() - momentDateFrom.valueOf();
    const percentage = Math.round(
        (nowMinusFrom / (momentDateTo.valueOf() - momentDateFrom.valueOf() || nowMinusFrom)) * 100,
    );

    return (
        <div className={classes.datesWrapper}>
            <Event sx={classes.dateIcon} />
            <Typography sx={classes.dates}>
                {`${momentDateFrom.format(DATE_FORMAT)} - ${momentDateTo.format(DATE_FORMAT)}`}
            </Typography>
            <Typography variant="caption">
                {t('quest.questDetailKeyResults.timeSpent.label', {
                    percentage: percentage > MAX_PERCENTAGE ? MAX_PERCENTAGE : percentage,
                })}
            </Typography>
        </div>
    );
};
