import { useContext } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { CreateQuestContext } from 'contexts';
import type { AppReducerState } from 'store';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import { useStyles } from './styles';

interface Props {
    showError: boolean;
}

export const KeyResults: FC<Props> = ({ showError }) => {
    const { keyResults, setKeyResults } = useContext(CreateQuestContext);
    const {
        enabled,
        minNumber = 0,
        maxNumber = 0,
    } = useSelector(({ configuration }: AppReducerState) => configuration.keyResults);
    const { t } = useTranslation();
    const classes = useStyles();

    const handleKeyResultChange = (keyResult) => (evt) =>
        setKeyResults([
            ...keyResults.slice(0, keyResult.order),
            { ...keyResult, descriptionShort: evt.target.value },
            ...keyResults.slice(keyResult.order + 1),
        ]);

    const handleDeleteKeyResultClick = (index) => () =>
        setKeyResults(
            [...keyResults.slice(0, index), ...keyResults.slice(index + 1)].map(
                (keyResult, order) => ({ ...keyResult, order }),
            ),
        );

    const handleAddKeyResultClick = () =>
        setKeyResults([
            ...keyResults,
            { order: keyResults.length, descriptionShort: '', descriptionLong: '' },
        ]);

    if (!enabled) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            {keyResults.map((keyResult, index) => (
                <Grid item key={keyResult.order} xs={12}>
                    <InputLabel htmlFor={`quest-key-result-input-${keyResult.order}`}>
                        <Typography>{t('quest.keyResults.form.keyResult.label')}</Typography>
                    </InputLabel>
                    <div className={classes.keyResultInputWrapper}>
                        <TextField
                            multiline
                            id={`quest-key-result-input-${keyResult.order}`}
                            type="text"
                            value={keyResult.descriptionShort}
                            size="small"
                            fullWidth
                            onChange={handleKeyResultChange(keyResult)}
                            maxRows={5}
                            error={showError && !keyResult.descriptionShort}
                            helperText={
                                showError
                                    ? t(
                                          'quest.setObjectiveStep.form.validation.requiredMessage.label',
                                      )
                                    : ''
                            }
                        />
                        {keyResults.length > minNumber && (
                            <Button
                                onClick={handleDeleteKeyResultClick(index)}
                                variant="outlined"
                                size="large"
                                sx={classes.keyResultDeleteButton}
                            >
                                <Icon path={mdiTrashCanOutline} size={1} />
                            </Button>
                        )}
                    </div>
                </Grid>
            ))}
            {keyResults.length < maxNumber && (
                <Button onClick={handleAddKeyResultClick} sx={classes.keyResultAddButton}>
                    {t('quest.keyResults.actions.addKeyResult.label')}
                </Button>
            )}
        </Grid>
    );
};
