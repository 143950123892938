import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { ChartLegend, Loading } from 'components';
import { useAnalyticsFilter, useChartColors } from 'hooks';
import { api, callService } from 'services';
import type { AppReducerState } from 'store';
import type { ChartTuple } from 'types';
import { PROFILE_TYPE } from 'types';
import { RadarChart } from '../../analytics/chart/RadarChart';
import { useStyles } from './styles';

const API_CONTROLLER_PATH = {
    [PROFILE_TYPE.JOB]: api.analyticsController.getJobAnalytics,
    [PROFILE_TYPE.JOB_FAMILY]: api.analyticsController.getJobFamilyAnalytics,
    [PROFILE_TYPE.PLAYER]: api.analyticsController.getPlayerAnalytics,
    [PROFILE_TYPE.TEAM]: api.analyticsController.getTeamAnalytics,
};

const GRAPH_ID = {
    [PROFILE_TYPE.JOB]: 'J3',
    [PROFILE_TYPE.JOB_FAMILY]: 'J3',
    [PROFILE_TYPE.PLAYER]: 'E3',
    [PROFILE_TYPE.TEAM]: 'T2',
};

interface AnalyticsData {
    graphTitle: string;
    data: {
        avgRateCompany: number;
        avgRateJob?: number;
        avgRateTeam?: number;
        avgRateEmployee?: number;
        competencySysId: string;
    }[];
}

interface Props {
    analyticsPrefillId?: string;
    id: string;
    type: PROFILE_TYPE;
}

export const JobProfileStatistics = ({ analyticsPrefillId, id, type }: Props) => {
    const { setAnalyticsFilterPreFillDataAction } = useAnalyticsFilter();
    const [data, setData] = useState<AnalyticsData>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();
    const { allCompetencies, year } = useSelector(
        ({ competencies, configuration }: AppReducerState) => ({
            allCompetencies: competencies.allCompetencies,
            year: configuration.year,
        }),
    );

    const getAnalyticsData = async () => {
        const { error, payload } = await callService({
            api: API_CONTROLLER_PATH[type],
            params: [id],
            query: {
                graphId: GRAPH_ID[type],
                periodicity: 1,
                dateFrom: moment(`01/01/${year}`).format('YYYY-MM-DD'),
                dateTo: moment(`12/31/${year}`).format('YYYY-MM-DD'),
            },
        });

        if (!error && payload) {
            setData(payload);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, []);

    if (!data) {
        return <Loading />;
    }

    const coloredData = useChartColors(
        data.data.reduce(
            (acc, item) => {
                const competencyName =
                    allCompetencies.find(
                        (competency) => competency.compSysId === item.competencySysId,
                    )?.descriptionShort || (Math.random() + 1).toString(36).substring(7);

                return [
                    {
                        data: [
                            ...acc[0].data,
                            {
                                key: competencyName,
                                value: (item.avgRateTeam ||
                                    item.avgRateJob ||
                                    item.avgRateEmployee)!,
                            },
                        ],
                        legend: t(
                            `jobProfile.jobProfileAnalytics.legend.averageRatingJob.${type}.label`,
                        ),
                    },
                    {
                        data: [...acc[1].data, { key: competencyName, value: item.avgRateCompany }],
                        legend: t(
                            'jobProfile.jobProfileAnalytics.legend.averageRatingCompany.label',
                        ),
                    },
                ];
            },
            [
                { data: [] as ChartTuple<string>[], legend: '' },
                { data: [] as ChartTuple<string>[], legend: '' },
            ],
        ),
    );

    const handleGoToAnalyticsButtonClick = () => {
        setAnalyticsFilterPreFillDataAction({
            filter: {
                [type === PROFILE_TYPE.TEAM ? 'hierarchies' : 'jobs']: [analyticsPrefillId || id],
            },
        });

        navigate('/analytics');
    };

    return (
        <>
            <div className={classes.titleWrapper}>
                <Typography variant="h2" component="h4" sx={classes.title}>
                    {t(`jobProfile.jobProfileAnalytics.title.${type}.label`)}
                </Typography>
                <Button onClick={handleGoToAnalyticsButtonClick}>
                    {t('jobProfile.jobProfileAnalytics.actions.goToAnalytics.label')}
                </Button>
            </div>
            <Card>
                <CardHeader
                    title={
                        <Typography>
                            <strong className={classes.graphTitle}>{data.graphTitle}</strong>
                        </Typography>
                    }
                />
                <CardContent sx={classes.cardContent}>
                    <RadarChart radius={120} data={coloredData} />
                    <ChartLegend
                        data={coloredData.map((item) => ({
                            color: item.color,
                            label: item.legend,
                        }))}
                        className={classes.legend}
                    />
                </CardContent>
            </Card>
        </>
    );
};
