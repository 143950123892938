import type { NotificationDataLineValue } from 'types';

import { useStyles } from './styles';
import { Coins } from '../../Coins';

interface Props {
    item: NotificationDataLineValue;
}

export const EventRowMessageItemCoins = ({ item }: Props) => {
    const classes = useStyles();

    return <Coins value={Number(item.coins)} className={classes.coins} />;
};
