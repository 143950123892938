import axios from 'axios';
import type {
    IApiResponse,
    IObjectiveView,
    IQuestSettingsView,
    IUpsertCompanyObjectiveCommand,
} from 'types';
import { api } from '../constants';

const controllerApi = api.objectives;
const questSettingsControllerApi = api.questSettings;

const getObjectives = async (perfCycleSysId: string): Promise<IObjectiveView[]> => {
    return axios
        .get(`${controllerApi}?performanceCycleId=${perfCycleSysId}`)
        .then((res) => res.data);
};

const getObjectiveById = async (objectiveSysId: string): Promise<IObjectiveView> => {
    return axios.get(`${controllerApi}/${objectiveSysId}`).then((res) => res.data);
};

const createCompanyObjective = async (
    data: IUpsertCompanyObjectiveCommand,
): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

const updateCompanyObjective = async (
    id: string,
    data: IUpsertCompanyObjectiveCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

const getQuestsSettings = async (performanceCycleId: string): Promise<IQuestSettingsView[]> =>
    axios
        .get(`${questSettingsControllerApi}?perfCycleSysId=${performanceCycleId}`)
        .then((res) => res.data);

const getQuestSettings = async (objectiveSettingId: string): Promise<IQuestSettingsView> =>
    axios.get(`${questSettingsControllerApi}/${objectiveSettingId}`).then((res) => res.data);

const updateQuestSettings = async (objectiveSettingId: string): Promise<IQuestSettingsView> =>
    axios.get(`${questSettingsControllerApi}/${objectiveSettingId}`).then((res) => res.data);

export const objectiveService = {
    getObjectives,
    getObjectiveById,
    getQuestsSettings,
    getQuestSettings,
    createCompanyObjective,
    updateCompanyObjective,
};
