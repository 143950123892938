import type { FC } from 'react';
import { TextField } from '@mui/material';

interface Props {
    defaultValue?: number;
    disabled?: boolean;
    errors: any;
    name: string;
    readOnly?: boolean;
    register: any;
    required?: boolean;
    title: string;
}

export const FtInputNumber: FC<Props> = ({
    defaultValue,
    disabled = false,
    errors,
    name,
    readOnly = false,
    register,
    required = false,
    title,
}) => (
    <TextField
        fullWidth
        label={`${title} ${(required && '*') || ''}`}
        defaultValue={defaultValue}
        {...register(name, {
            required,
        })}
        type="number"
        error={errors && errors[name]}
        InputProps={{
            readOnly,
            disabled,
        }}
    />
);
