import { useTheme } from '@mui/material';
import { PlayerSearchResultMode } from 'types';
import { css } from '@emotion/css';

export const useStyles = (mode) => {
    const theme = useTheme();

    return {
        playerDescription: css({
            display: 'flex',
            alignItems: mode === PlayerSearchResultMode.GRID ? 'center' : undefined,
            flexDirection: mode === PlayerSearchResultMode.GRID ? 'column' : undefined,
        }),
        descriptionWrapper: {
            position: 'relative',

            '& .MuiButtonBase-root': {
                position: 'absolute',
                top: theme.spacing(mode === PlayerSearchResultMode.GRID ? 1 : 2),
                right: theme.spacing(1),
            },

            paddingBottom: `${theme.spacing(2)} !important`,
        },
        job: {
            cursor: 'pointer',
        },
        nameWrapper: css({
            marginLeft: theme.spacing(mode === PlayerSearchResultMode.GRID ? 0 : 2),
            display: 'flex',
            alignItems: mode === PlayerSearchResultMode.GRID ? 'center' : 'flex-start',
            flexDirection: 'column',
        }),
        avatar: {
            cursor: 'pointer',
            height: theme.spacing(mode === PlayerSearchResultMode.GRID ? 12 : 6),
            width: theme.spacing(mode === PlayerSearchResultMode.GRID ? 12 : 6),
        },
    };
};
