import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CardContent, Typography } from '@mui/material';
import type { Quest } from 'types';
import { KeyResultAssignedTo } from 'types';
import { useStyles } from './styles';
import { Avatar } from '../../avatar';

interface Props {
    quest: Quest;
}

export const QuestDetailAssignees: FC<Props> = ({ quest }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { assignedTo } = quest;
    const classes = useStyles();

    const handleOnAssigneeClick = (assignee) => () => {
        const profileRoot = assignee.type === KeyResultAssignedTo.TEAM ? 'teamProfile' : 'profile';

        if (assignee.id) {
            navigate(`/${profileRoot}/${assignee.id}`);
        }
    };

    const handleOnAssigneeKeyUp = (assignee) => (evt) => {
        const profileRoot = assignee.type === KeyResultAssignedTo.TEAM ? 'teamProfile' : 'profile';

        if (evt.keyCode === 13 && assignee.id) {
            navigate(`/${profileRoot}/${assignee.id}`);
        }
    };

    if (!assignedTo.length) {
        return null;
    }

    return (
        <CardContent>
            <Typography variant="h4" sx={classes.title}>
                {t('quest.detail.assigned.label')}
            </Typography>
            {assignedTo.map((assignee) => (
                <div
                    tabIndex={0}
                    className={classes.assignee}
                    onClick={handleOnAssigneeClick(assignee)}
                    onKeyUp={handleOnAssigneeKeyUp(assignee)}
                    role="link"
                >
                    <Avatar src={assignee.profilePhoto} alt={assignee.name[0]} />
                    <Typography>{assignee.name}</Typography>
                </div>
            ))}
        </CardContent>
    );
};
