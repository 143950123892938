import type { VFC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ProfileData } from 'types';
import {
    FEEDBACK_STEP_TYPE,
    FEEDBACK_TARGET_TYPE,
    PROFILE_DETAIL_TAB_TYPE,
    PROFILE_TYPE,
} from 'types';
import {
    JobProfileStatistics,
    TabPanel,
    ValuablePlayerTopics,
    ValuablePlayerTopicsSelector,
} from 'components';
import { Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
// import { Achievements } from '../Achievements';
import { ProfileQuestList } from '../ProfileQuestList';
import { TeamsAndRoles } from '../TeamsAndRoles';
import { FeedbackChartEmployeeVsJobFamily } from '../FeedbackChartEmployeeVsJobFamily';
import { FeedbackChartEmployeeVsTeam } from '../FeedbackChartEmployeeVsTeam';
import { ActivityStream } from '../../activityStream/ActivityStream';
import { useStyles } from './styles';

interface Props {
    enableEdit: boolean;
    profileData: ProfileData;
}

export const ProfileDetailTabs: VFC<Props> = ({ enableEdit, profileData }) => {
    const [selectedTab, setSelectedTab] = useState(PROFILE_DETAIL_TAB_TYPE.GENERAL);
    const { t } = useTranslation();
    const classes = useStyles();

    const streamFilter = useMemo(
        () => ({
            eventTypes: [],
            query: '',
            assignedTo: [profileData.employeeSysId],
        }),
        [profileData.employeeSysId],
    );

    const streamAssignedToWithType = useMemo(
        () => ({
            id: profileData.employeeSysId,
            type: PROFILE_TYPE.PLAYER,
        }),
        [profileData.employeeSysId],
    );

    const handleTabChange = (evt, newValue) => setSelectedTab(newValue);

    return (
        <div className={classes.wrapper}>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
                sx={classes.tabs}
            >
                <Tab
                    label={t('profile.profileDetailTabs.general.label')}
                    id="tab-general"
                    aria-controls="tab-panel-general"
                />
                {/*<Tab*/}
                {/*    label={t('profile.profileDetailTabs.achievements.label')}*/}
                {/*    id="tab-achievements"*/}
                {/*    aria-controls="tab-panel-achievements"*/}
                {/*/>*/}
                <Tab
                    label={t('profile.profileDetailTabs.quests.label')}
                    id="tab-quests"
                    aria-controls="tab-panel-quests"
                />
                <Tab
                    label={t('profile.profileDetailTabs.skills.label')}
                    id="tab-skills"
                    aria-controls="tab-panel-skills"
                />
                <Tab
                    label={t('profile.profileDetailTabs.analytics.label')}
                    id="tab-analytics"
                    aria-controls="tab-panel-analytics"
                />
            </Tabs>
            <Divider sx={classes.divider} />
            <TabPanel
                active={selectedTab === PROFILE_DETAIL_TAB_TYPE.GENERAL}
                tabId="tab-panel-general"
                labeledBy="tab-general"
            >
                <Typography variant="h2" component="h2" sx={classes.title}>
                    {t('profile.profileDetailTabs.teamsAndRoles.title.label')}
                </Typography>
                <TeamsAndRoles teams={profileData.teams} />
                <Typography variant="h2" component="h2" sx={classes.title}>
                    {t('profile.profileDetailTabs.activity.title.label')}
                </Typography>
                <ActivityStream
                    preview
                    showGiveFeedback={!enableEdit}
                    filter={streamFilter}
                    assignedToWithType={streamAssignedToWithType}
                    title={t<string>('profile.profileDetailTabs.yourStream.label')}
                    giveFeedbackData={{
                        step: FEEDBACK_STEP_TYPE.COMPETENCY,
                        profileData: {
                            id: profileData.employeeSysId,
                            displayName: `${profileData.firstName} ${profileData.lastName}`,
                            type: FEEDBACK_TARGET_TYPE.EMPLOYEE,
                            initials: profileData.initials,
                            profilePhoto: profileData.profilePhoto,
                        },
                    }}
                />
            </TabPanel>
            {/*<TabPanel*/}
            {/*    active={selectedTab === PROFILE_DETAIL_TAB_TYPE.ACHIEVEMENTS}*/}
            {/*    tabId="tab-panel-achievements"*/}
            {/*    labeledBy="tab-achievements"*/}
            {/*>*/}
            {/*    <Achievements />*/}
            {/*</TabPanel>*/}
            <TabPanel
                active={selectedTab === PROFILE_DETAIL_TAB_TYPE.QUESTS}
                tabId="tab-panel-quests"
                labeledBy="tab-quests"
            >
                <ProfileQuestList profile={profileData} />
            </TabPanel>
            <TabPanel
                active={selectedTab === PROFILE_DETAIL_TAB_TYPE.SKILLS}
                tabId="tab-panel-skills"
                labeledBy="tab-skills"
            >
                {!enableEdit && (
                    <ValuablePlayerTopics
                        employeeSysId={profileData.employeeSysId}
                        profileData={profileData}
                    />
                )}
                {enableEdit && (
                    <ValuablePlayerTopicsSelector employeeSysId={profileData.employeeSysId} />
                )}
            </TabPanel>
            <TabPanel
                active={selectedTab === PROFILE_DETAIL_TAB_TYPE.ANALYTICS}
                tabId="tab-panel-analytics"
                labeledBy="tab-analytics"
            >
                <Grid container spacing={2}>
                    {enableEdit && (
                        <Grid item xs={12}>
                            <JobProfileStatistics
                                analyticsPrefillId={profileData.job?.id}
                                id={profileData.employeeSysId}
                                type={PROFILE_TYPE.PLAYER}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="h2" component="h4" sx={classes.title}>
                            {t('jobProfile.jobProfileAnalytics.title.feedback.label')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FeedbackChartEmployeeVsJobFamily
                                    jobFamilyId={profileData.job?.jobFamilyId}
                                    userId={profileData.employeeSysId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FeedbackChartEmployeeVsTeam
                                    teams={profileData.teams}
                                    userId={profileData.employeeSysId}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    );
};
