import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import { useBoundActions } from 'hooks';
import { api, callService } from 'services';
import { getToken } from 'store/slices/user';
import { Logo } from 'components';
import { useStyles } from './styles';
import { useStyles as useCommonStyles } from '../common/styles';

const ERROR_STATUS_TO_SHOW = 400;

interface Props {
    onBackToLoginClick: () => void;
}

export const TwoFactorCodeForm: FC<Props> = ({ onBackToLoginClick }) => {
    const { getTokenAction } = useBoundActions({ getToken });
    const [code, setCode] = useState('');
    const [serviceError, setServiceError] = useState('');
    const [codeError, setCodeError] = useState('');
    const { t } = useTranslation();
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!code) {
            setCodeError(t('user.twoFactorCodeForm.message.codeError.label') as string);

            return;
        }

        const { error } = await getTokenAction(code);

        if (error?.message && error.status === ERROR_STATUS_TO_SHOW) {
            setServiceError(error.message);
        }

        if (error?.message && error.status !== ERROR_STATUS_TO_SHOW) {
            setServiceError(t<string>('user.twoFactorCodeForm.message.genericError.label'));
        }
    };

    const handleReSendCode = () => {
        callService({
            api: api.authentication.generateCode,
            useToken: false,
        });
    };

    const handleCodeChange = (evt) => {
        setCode(evt.target.value);
        setCodeError('');
        setServiceError('');
    };

    return (
        <>
            <Logo className={classes.logo} disableNavigation />
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>{t('user.twoFactorCodeForm.subTitle.label')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            fullWidth
                            value={code}
                            size="small"
                            onChange={handleCodeChange}
                            error={!!codeError}
                            helperText={codeError}
                            placeholder={t('user.twoFactorCodeForm.code.label') as string}
                        />
                    </Grid>
                    {serviceError && (
                        <Grid item xs={12}>
                            <Alert severity="error">{serviceError}</Alert>{' '}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" sx={commonClasses.primaryAction}>
                            {t('user.twoFactorCodeForm.actions.login.label')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={commonClasses.actions}>
                        <Button
                            variant="text"
                            sx={commonClasses.secondaryAction}
                            onClick={handleReSendCode}
                        >
                            {t('user.twoFactorCodeForm.actions.sendCode.label')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={commonClasses.actions}>
                        <Button
                            variant="text"
                            sx={commonClasses.secondaryAction}
                            onClick={onBackToLoginClick}
                        >
                            {t('user.twoFactorCodeForm.actions.backToLogin.label')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};
