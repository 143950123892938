import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { STORE_TAB_TYPES } from 'types';
import type { AppReducerState } from 'store';
import { useNavigate } from 'react-router-dom';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { Backpack, Coins } from 'components';
import { useStyles } from './styles';

export const CardWallet: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const { coins, storeTransactions } = useSelector(({ user }: AppReducerState) => user.current);

    const handleCardClick = () => navigate(`/marketplace/${STORE_TAB_TYPES.HISTORY}`);

    return (
        <Card sx={classes.card} onClick={handleCardClick}>
            <CardContent>
                <Grid container>
                    <Grid item xs={9}>
                        <div>
                            <Typography sx={classes.title}>
                                {t('home.card.inventory.title')}
                            </Typography>
                            <div className={classes.displayFlex}>
                                <Coins value={coins} variant="h6" />
                            </div>
                        </div>
                        <Typography variant="caption" sx={classes.purchasedItems}>
                            <strong className={classes.storeTransactions}>
                                {storeTransactions}
                            </strong>{' '}
                            {t('home.card.inventory.itemRedeemed', { count: storeTransactions })}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Avatar sx={classes.icon}>
                                    <Backpack />
                                </Avatar>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
