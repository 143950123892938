import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { KeyResultAssignedTo, QuestFeature } from 'types';
import { api, callService } from 'services';
import type { AppReducerState } from 'store';

const ASSIGN_ACTION = 'Assign';

interface Props {
    questId: string;
    action: QuestFeature;
    onCancel(): void;
    onSuccess(): void;
}

export const AsignementQuestModal: FC<Props> = ({ questId, onCancel, onSuccess, action }) => {
    const { t } = useTranslation();
    const { employeeSysId } = useSelector(({ user }: AppReducerState) => user.current);

    const titleKey =
        action === QuestFeature.JOIN
            ? 'quest.abandonQuestModal.title.join.label'
            : 'quest.abandonQuestModal.title.abandon.label';
    const actionKey =
        action === QuestFeature.JOIN
            ? 'quest.abandonQuestModal.actions.join.label'
            : 'quest.abandonQuestModal.actions.abandon.label';

    const handleOnCancelClick = () => onCancel();

    const handleOnAbandonClick = async () => {
        const actionCall = action === QuestFeature.JOIN ? ASSIGN_ACTION : action;

        const { error, payload } = await callService({
            api: api.objectiveController.assignObjective,
            params: [questId],
            body: { action: actionCall, type: KeyResultAssignedTo.EMPLOYEE, id: employeeSysId },
        });

        if (!error && payload) {
            onSuccess();
        }
    };

    return (
        <Dialog open className="bigPadding">
            <DialogTitle>
                <Typography variant="h4" component="h2">
                    {t(titleKey)}
                </Typography>
            </DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={handleOnCancelClick}>
                    {t('quest.abandonQuestModal.actions.cancel.label')}
                </Button>
                <Button variant="contained" onClick={handleOnAbandonClick}>
                    {t(actionKey)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
