import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    Typography,
} from '@mui/material';
import type { Job } from 'types';
import { ExpandMore } from '@mui/icons-material';
import { useStyles } from './styles';

interface Props {
    job: Job;
}

export const JobPositionCard = ({ job }: Props) => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();

    const handleExpandClick = () => setExpanded(!expanded);

    const expandButtonLabel = t(
        `jobFamilyProfile.jobs.actions.${expanded ? 'collapse' : 'expand'}.label`,
    );

    const subHeader =
        job.noOfEmployees != null
            ? t('jobProfile.jobHeaderSection.members.label', {
                  count: job.noOfEmployees,
              })
            : '';

    const handleJobClick = () => navigate(`/jobProfile/${job.id}`);

    return (
        <Card>
            <CardHeader
                action={
                    job.description && (
                        <Button
                            variant="text"
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label={expandButtonLabel}
                            endIcon={
                                <ExpandMore
                                    sx={{
                                        ...classes.expand,
                                        ...(expanded ? classes.expandOpen : {}),
                                    }}
                                />
                            }
                        >
                            {expandButtonLabel}
                        </Button>
                    )
                }
                title={job.title}
                avatar={
                    <Avatar alt={job.title} src={job.profilePhoto} onClick={handleJobClick}>
                        {job.title[0]}
                    </Avatar>
                }
                subheader={subHeader}
                titleTypographyProps={{
                    variant: 'body1',
                    component: 'strong',
                    sx: classes.bold,
                    onClick: handleJobClick,
                }}
            />
            {job.description && (
                <Collapse in={expanded}>
                    <Divider />
                    <CardContent>
                        <Typography>
                            {job.description}asdasdasd asdasd asdasd asdas aaaas asdasdasd asdasd
                            asd asdasd asdasdasd asdasd asdasd asdas aaaas asdasdasd asdasd asd
                            asdasd asdasdasd asdasd asdasd asdas aaaas asdasdasd asdasd asd asdasd
                        </Typography>
                    </CardContent>
                </Collapse>
            )}
        </Card>
    );
};
