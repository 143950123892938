/* eslint-disable max-lines */
import { useContext, useState, useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    Grid,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    InputLabel,
    Select,
    TextField,
    Typography,
    MenuItem,
    Card,
    CardContent,
    CardActions,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CreateQuestContext } from 'contexts';
import { KeyResults } from 'components';
import type { AppReducerState } from 'store';
import { CreateQuestStep, QuestStatus } from 'types';

const DATE_FORMAT = 'DD.MM.YYYY';

export const SetObjectiveStep: FC = () => {
    const {
        descriptionShort,
        descriptionLong,
        setDescriptionLong,
        setDescriptionShort,
        dateFrom,
        dateTo,
        keyResults,
        priorityId,
        parentQuestId,
        objectiveId,
        status,
        saveAsDraft,
        setPriorityId,
        setParentQuestId,
        setDateFrom,
        setDateTo,
        setStep,
        type,
    } = useContext(CreateQuestContext);
    const [errors, setErrors] = useState<any>({});
    const { t } = useTranslation();
    const { companyObjectives, questPriorities, parentQuestsEnabled, questTypesEnabled } =
        useSelector(({ configuration }: AppReducerState) => configuration);

    const { minDateFrom, maxDateTo } =
        questTypesEnabled.find((questType) => questType.type === type) || {};

    const getErrors = () => {
        const validationErrors = {} as any;
        const requiredMessage = t('quest.setObjectiveStep.form.validation.requiredMessage.label');
        const dateRangeMessage = t('quest.setObjectiveStep.form.validation.dateRangeMessage.label');
        const invalidDateMessage = t('quest.setObjectiveStep.form.validation.invalidDate.label');

        if (!descriptionShort) {
            validationErrors.descriptionShort = requiredMessage;
        }

        if (!descriptionLong) {
            validationErrors.descriptionLong = requiredMessage;
        }

        if (!dateFrom) {
            validationErrors.dateFrom = requiredMessage;
        }

        if (!dateTo) {
            validationErrors.dateTo = requiredMessage;
        }

        if (dateFrom?.isAfter(dateTo)) {
            validationErrors.dateFrom = dateRangeMessage;
            validationErrors.dateTo = dateRangeMessage;
        }

        if (minDateFrom && !dateFrom?.isSameOrAfter(minDateFrom)) {
            validationErrors.dateFrom = t(
                'quest.setObjectiveStep.form.validation.dateAfterMinMessage.label',
            );
        }

        if (maxDateTo && !dateTo?.isSameOrBefore(maxDateTo)) {
            validationErrors.dateTo = t(
                'quest.setObjectiveStep.form.validation.dateBeforeMaxMessage.label',
            );
        }

        if (!dateFrom?.isValid()) {
            validationErrors.dateFrom = invalidDateMessage;
        }

        if (!dateTo?.isValid()) {
            validationErrors.dateTo = invalidDateMessage;
        }

        if (!priorityId) {
            validationErrors.priorityId = requiredMessage;
        }

        if (keyResults.some((keyResult) => !keyResult.descriptionShort)) {
            validationErrors.keyResults = requiredMessage;
        }

        return validationErrors;
    };

    useEffect(() => {
        setErrors({});
    }, [descriptionShort, descriptionLong, keyResults, dateFrom, dateTo, priorityId]);

    const handleDescriptionLongChange = (evt) => setDescriptionLong(evt.target.value);

    const handleDescriptionShortChange = (evt) => setDescriptionShort(evt.target.value);

    const handleDateFromChange = (date) => setDateFrom(date);

    const handleDateToChange = (date) => setDateTo(date);

    const handlePriorityChange = (evt) => setPriorityId(evt.target.value);

    const handleParentQuestChange = (evt) => setParentQuestId(evt.target.value);

    const handleNextStepClick = () => {
        const validationErrors = getErrors();

        if (Object.keys(validationErrors).length) {
            setErrors(validationErrors);
        } else {
            setStep(CreateQuestStep.USER_STEP);
        }
    };

    const handleSaveAsDraftClick = () => {
        const validationErrors = getErrors();

        if (Object.keys(validationErrors).length) {
            setErrors(validationErrors);
        } else {
            saveAsDraft();
        }
    };

    const momentDateFrom = moment(dateFrom);
    const momentDateTo = moment(dateTo);
    const momentMinDateFrom = moment(minDateFrom);
    const momentMaxDateTo = moment(maxDateTo);

    let minDateMaxConstraint;
    let maxDateMinConstraint;

    if (dateTo || maxDateTo) {
        minDateMaxConstraint =
            momentMaxDateTo.diff(momentDateTo) > 0 ? momentDateTo : momentMaxDateTo;
    }

    if (dateFrom || minDateFrom) {
        maxDateMinConstraint =
            momentMinDateFrom.diff(momentDateFrom) > 0 ? momentMinDateFrom : momentDateFrom;
    }

    return (
        <Card className="bigPadding">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="quest-objective-input">
                            <Typography>
                                {t('quest.setObjectiveStep.form.objective.label')}
                            </Typography>
                        </InputLabel>
                        <TextField
                            id="quest-objective-input"
                            type="text"
                            fullWidth
                            value={descriptionShort || ''}
                            size="small"
                            onChange={handleDescriptionShortChange}
                            error={!!errors.descriptionShort}
                            helperText={errors.descriptionShort}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="quest-description-input">
                            <Typography>
                                {t('quest.setObjectiveStep.form.description.label')}
                            </Typography>
                        </InputLabel>
                        <TextField
                            id="quest-description-input"
                            minRows={3}
                            maxRows={3}
                            multiline
                            size="small"
                            fullWidth
                            value={descriptionLong}
                            onChange={handleDescriptionLongChange}
                            error={!!errors.descriptionLong}
                            helperText={errors.descriptionLong}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <KeyResults showError={errors.keyResults} />
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="quest-date-from-input">
                                    <Typography>
                                        {t('quest.setObjectiveStep.form.dateFrom.label')}
                                    </Typography>
                                </InputLabel>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            id: 'quest-date-from-input',
                                            size: 'small',
                                            fullWidth: true,
                                            error: !!errors.dateFrom,
                                            'aria-label': t(
                                                'quest.setObjectiveStep.form.dateFrom.aria',
                                            ) as string,
                                        },
                                    }}
                                    format={DATE_FORMAT}
                                    onChange={handleDateFromChange}
                                    value={dateFrom}
                                    minDate={moment(minDateFrom)}
                                    maxDate={minDateMaxConstraint}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="quest-date-to-input">
                                    <Typography>
                                        {t('quest.setObjectiveStep.form.dateTo.label')}
                                    </Typography>
                                </InputLabel>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            id: 'quest-date-to-input',
                                            size: 'small',
                                            fullWidth: true,
                                            error: !!errors.dateTo,
                                            'aria-label': t(
                                                'quest.setObjectiveStep.form.dateTo.aria',
                                            ) as string,
                                        },
                                    }}
                                    format={DATE_FORMAT}
                                    onChange={handleDateToChange}
                                    value={dateTo}
                                    // minDate={moment(dateFrom) || undefined}
                                    minDate={maxDateMinConstraint}
                                    maxDate={moment(maxDateTo)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth size="small" error={!!errors.priorityId}>
                            <FormLabel
                                id="quest-priority-input-label"
                                htmlFor="quest-priority-input"
                            >
                                <Typography>
                                    {t('quest.setObjectiveStep.form.priority.label')}
                                </Typography>
                            </FormLabel>
                            <Select
                                labelId="quest-priority-input-label"
                                id="quest-priority-input"
                                value={priorityId}
                                onChange={handlePriorityChange}
                                fullWidth
                            >
                                {questPriorities.map(({ id, name }) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.priorityId && (
                                <FormHelperText variant="outlined">
                                    {errors.priorityId}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    {parentQuestsEnabled && (
                        <Grid item xs={12}>
                            <FormControl fullWidth size="small">
                                <FormLabel
                                    id="quest-parent-quest-input-label"
                                    htmlFor="quest-parent-quest-input"
                                >
                                    <Typography>
                                        {t('quest.setObjectiveStep.form.parentQuest.label')}
                                    </Typography>
                                </FormLabel>
                                <Select
                                    labelId="quest-parent-quest-input-label"
                                    id="quest-parent-quest-input"
                                    value={parentQuestId}
                                    onChange={handleParentQuestChange}
                                    fullWidth
                                >
                                    {companyObjectives?.map((quest) => (
                                        <MenuItem key={quest.objectiveId} value={quest.objectiveId}>
                                            {quest.descriptionShort}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
            <Divider />
            <CardActions>
                {(!objectiveId || status === QuestStatus.DRAFT) && (
                    <Button variant="outlined" onClick={handleSaveAsDraftClick}>
                        {t('quest.setObjectiveStep.actions.saveAsDraft.label')}
                    </Button>
                )}
                <Button variant="contained" onClick={handleNextStepClick}>
                    {t('quest.setObjectiveStep.actions.next.label')}
                </Button>
            </CardActions>
        </Card>
    );
};
