import cx from 'classnames';
import type { AvatarAdvice } from 'types';
import { FormControlLabel, Radio } from '@mui/material';
import { Mood } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiHeartBroken } from '@mdi/js';
import { ADVICE_SENTIMENT } from 'types';
import { useStyles } from './styles';

interface Props {
    active: boolean;
    advice: AvatarAdvice;
}

export const FeedbackAdviceLabel = ({ active, advice }: Props) => {
    const classes = useStyles();

    const sentiment =
        advice.sentiment === ADVICE_SENTIMENT.POSITIVE ? (
            <Mood className={cx(classes.icon, classes.good)} />
        ) : (
            <Icon path={mdiHeartBroken} className={cx(classes.icon, classes.bad)} />
        );

    return (
        <FormControlLabel
            sx={classes.radioLabel}
            value={advice.id}
            control={<Radio />}
            label={
                <>
                    {advice.advice} {active && sentiment}
                </>
            }
        />
    );
};
