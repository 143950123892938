import { hexToRgba } from './themeService';
import { frankyTalesTheme } from './frankyTalesTheme';

const { colors } = frankyTalesTheme;

const root = {
    backgroundColor: hexToRgba(colors.grey, 0.15),
};

export const MuiDivider = {
    styleOverrides: {
        root,
    },
};
