import { useState } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/extensions
import 'swiper/css';
// eslint-disable-next-line import/extensions
import 'swiper/css/effect-coverflow';
import type { Competency } from 'types';
import type { AppReducerState } from 'store';
import { Grid, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';
import { FeedbackCompetencyCard } from '../FeedbackCompetencyCard';
import { SwiperButton } from './SwiperButton';
import { FeedbackOpenTopicCard } from '../FeedbackOpenTopicCard';
import { useStyles } from './styles';

interface Props {
    onSelectCompetencyClick: (...args: any[]) => void;
}

const groupCompetencies = (array: Competency[][], originalArray: Competency[]) => {
    if (originalArray.length) {
        array.push(originalArray.splice(0, 4));
        return groupCompetencies(array, originalArray);
    }

    return array;
};

export const FeedbackCompetencyGrid: FC<Props> = ({ onSelectCompetencyClick = () => {} }) => {
    const [hoveredCompetency, setHoveredCompetency] = useState('');
    const [slideIndex, setSlideIndex] = useState(0);
    const [topicHovered, setTopicHovered] = useState(false);
    const isSmAndLower: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const { allCompetencies, enableOpenTopic } = useSelector(
        ({ competencies, configuration }: AppReducerState) => ({
            allCompetencies: competencies.allCompetencies,
            enableOpenTopic: configuration.feedbackConfiguration.enableOpenTopic,
        }),
    );
    const { t } = useTranslation();
    const classes = useStyles();
    const groupedCompetencies = groupCompetencies([], [...allCompetencies]);

    const handleCompetencyClick = (competency?: Competency) => () =>
        onSelectCompetencyClick(competency);

    const handleCompetencyKeyUp = (competency?: Competency) => (evt) => {
        if (evt.keyCode === 13) {
            onSelectCompetencyClick(competency);
        }
    };

    const handleCompetencyMouseEnter = (competency: Competency) => () =>
        setHoveredCompetency(competency.compSysId);

    const handleCompetencyMouseLeave = () => setHoveredCompetency('');

    const handleCompetencyFocus = (competency: Competency) => () =>
        setHoveredCompetency(competency.compSysId);

    const handleTopicMouseEnter = () => setTopicHovered(true);

    const handleTopicFocus = () => setTopicHovered(true);

    const handleTopicMouseLeave = () => setTopicHovered(false);

    const handleArrowFocus = () => setHoveredCompetency('');

    const handleSlide = (left) => {
        if (left) {
            if (slideIndex) {
                setSlideIndex(slideIndex - 1);
            } else {
                setSlideIndex(groupedCompetencies.length - (enableOpenTopic ? 0 : 1));
            }

            return;
        }

        if (slideIndex === groupedCompetencies.length - (enableOpenTopic ? 0 : 1)) {
            setSlideIndex(0);
        } else {
            setSlideIndex(slideIndex + 1);
        }
    };

    if (isSmAndLower) {
        return (
            <Grid container spacing={4} sx={classes.grid}>
                {allCompetencies.map((competency) => (
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        key={competency.compSysId}
                        sx={classes.gridItem}
                        onMouseEnter={handleCompetencyMouseEnter(competency)}
                        onFocus={handleCompetencyFocus(competency)}
                        onMouseLeave={handleCompetencyMouseLeave}
                    >
                        <FeedbackCompetencyCard
                            competency={competency}
                            tabIndex={0}
                            onSelectCompetencyClick={handleCompetencyClick(competency)}
                            onSelectCompetencyKeyUp={handleCompetencyKeyUp(competency)}
                            raised={hoveredCompetency === competency.compSysId}
                        />
                    </Grid>
                ))}
                {enableOpenTopic && (
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        sx={classes.gridItem}
                        onMouseEnter={handleTopicMouseEnter}
                        onFocus={handleTopicFocus}
                        onMouseLeave={handleTopicMouseLeave}
                    >
                        <FeedbackOpenTopicCard
                            tabIndex={groupedCompetencies.length === slideIndex ? 0 : undefined}
                            onSelectTopicClick={handleCompetencyClick(undefined)}
                            onSelectTopicKeyUp={handleCompetencyKeyUp(undefined)}
                            raised={topicHovered}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    const openTopicCard = enableOpenTopic ? (
        <Grid
            item
            md={3}
            sx={{ ...classes.gridItem, ...(topicHovered ? classes.focused : {}) }}
            onMouseEnter={handleTopicMouseEnter}
            onFocus={handleTopicFocus}
            onMouseLeave={handleTopicMouseLeave}
        >
            <FeedbackOpenTopicCard
                tabIndex={groupedCompetencies.length === slideIndex ? 0 : undefined}
                onSelectTopicClick={handleCompetencyClick(undefined)}
                onSelectTopicKeyUp={handleCompetencyKeyUp(undefined)}
                raised={topicHovered}
            />
        </Grid>
    ) : null;

    return (
        <div className={classes.spinnerWrapper}>
            <Swiper grabCursor slidesPerView={1} className={classes.slideWrapper} rewind>
                <SwiperButton left onButtonFocused={handleArrowFocus} onSlide={handleSlide} />
                {groupedCompetencies.map((competencyGroup, index) => (
                    <SwiperSlide>
                        {/*eslint-disable-next-line react/no-array-index-key*/}
                        <Grid container spacing={2} key={index} sx={classes.itemWrapper}>
                            {competencyGroup.map((competency) => (
                                <Grid
                                    item
                                    md={3}
                                    key={competency.compSysId}
                                    sx={{
                                        ...classes.gridItem,
                                        ...(hoveredCompetency === competency.compSysId
                                            ? classes.focused
                                            : {}),
                                    }}
                                    onMouseEnter={handleCompetencyMouseEnter(competency)}
                                    onFocus={handleCompetencyFocus(competency)}
                                    onMouseLeave={handleCompetencyMouseLeave}
                                >
                                    <FeedbackCompetencyCard
                                        tabIndex={index === slideIndex ? 0 : undefined}
                                        competency={competency}
                                        onSelectCompetencyClick={handleCompetencyClick(competency)}
                                        onSelectCompetencyKeyUp={handleCompetencyKeyUp(competency)}
                                        raised={hoveredCompetency === competency.compSysId}
                                    />
                                </Grid>
                            ))}
                            {competencyGroup.length < 4 && openTopicCard}
                        </Grid>
                    </SwiperSlide>
                ))}
                {groupedCompetencies[groupedCompetencies.length - 1].length === 4 && (
                    <SwiperSlide>
                        <Grid
                            container
                            spacing={2}
                            key={groupedCompetencies.length}
                            sx={classes.itemWrapper}
                        >
                            {openTopicCard}
                        </Grid>
                    </SwiperSlide>
                )}
                <SwiperButton onButtonFocused={handleArrowFocus} onSlide={handleSlide} />
            </Swiper>
        </div>
    );
};
