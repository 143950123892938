import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';
import { REPORT_TYPE } from 'types';

const { colors } = frankyTalesTheme;

const REPORT_TYPE_COLORS = {
    [REPORT_TYPE.TASK]: colors.blueDarker,
    [REPORT_TYPE.GROWTH]: colors.pinkRed,
    [REPORT_TYPE.SKILL]: colors.lightGreen,
    [REPORT_TYPE.COMPANY]: colors.orange,
};

export const useStyles = (reportType): any => {
    const theme = useTheme();

    return {
        content: {
            display: 'flex',
            paddingBottom: `${theme.spacing(2)} !important`,
            justifyContent: 'space-between',
        },
        nameAndIcon: css({
            display: 'flex',
            alignItems: 'center',
        }),
        typeIconWrapper: css({
            marginRight: theme.spacing(2),
        }),
        typeIconAvatar: {
            borderRadius: theme.spacing(2),
            width: theme.spacing(7),
            height: theme.spacing(7),
            background: colors.white,
            border: `1px solid ${colors.greyLighter2} !important`,
        },
        typeIcon: css({
            width: theme.spacing(3),
            height: theme.spacing(3),
            color: REPORT_TYPE_COLORS[reportType],
        }),
        actions: css({
            display: 'flex',
            alignItems: 'center',
            minWidth: theme.spacing(7),
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(0),
        }),
    };
};
