import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        filters: {
            marginBottom: theme.spacing(3),
        },
        filterWrapper: css({
            display: 'flex',
        }),
        filter: {
            marginRight: theme.spacing(2),
        },
        queryInput: {
            marginBottom: theme.spacing(3),
        },
        avatar: {
            marginRight: theme.spacing(1),
        },
        avatarWrapper: css({ display: 'flex' }),
        assigneesValueLabel: css({
            marginLeft: theme.spacing(1),
        }),
        createQuestButton: css({
            flex: '1 0 auto',
            marginLeft: theme.spacing(2),
        }),
    };
};
