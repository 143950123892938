import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    return {
        error: css({
            color: colors.orange,
        }),
    };
};
