import { useState } from 'react';
import type { FC } from 'react';
// import { Modal } from '@mui/material';

import { AnalyticsReportsTabFilter } from './AnalyticsReportsTabFilter';
import { ReportsList } from './ReportsList';
import { ReportDetail } from './ReportDetail';
import { useStyles } from './styles';

export const AnalyticsReportsTab: FC = () => {
    const [filtersLoaded, setFiltersLoaded] = useState<boolean>();
    // const [reportDetailId, setReportDetailId] = useState('');
    // const classes = useStyles();

    // const handleOnReportClick = (reportId) => setReportDetailId(reportId);
    const handleOnReportClick = () => {};

    const handleFiltersLoaded = (err) => setFiltersLoaded(!err);

    // const handleReportDetailClose = () => setReportDetailId('');

    return (
        <>
            <AnalyticsReportsTabFilter onFiltersLoaded={handleFiltersLoaded} />
            {filtersLoaded && <ReportsList onReportClick={handleOnReportClick} />}
            {/*<Modal open={!!reportDetailId} sx={classes.modal}>*/}
            {/*    <ReportDetail reportId={reportDetailId} closeHandle={handleReportDetailClose} />*/}
            {/*</Modal>*/}
        </>
    );
};
