export enum PlayerSearchResultType {
    PLAYER = 'PLAYER',
    JOB = 'JOB',
    TEAM = 'TEAM',
}

export enum PlayerSearchResultMode {
    GRID = 'GRID',
    TABLE = 'TABLE',
}

export enum PLAYER_SEARCH_RESULT_FEATURE_TYPE {
    CREATE_QUEST = 'CreateQuest',
    GIVE_FEEDBACK = 'Feedback',
    SELF_EVALUATE = 'Selfevaluate',
    EVALUATE = 'Evaluate',
}

export interface PlayerFeatures {
    features: PLAYER_SEARCH_RESULT_FEATURE_TYPE[];
    perfCycleEmployeeEvaluationId?: string;
}

export interface PlayerSearchResult {
    id: string;
    displayName: string;
    profilePhoto?: string;
    jobName?: string;
    jobId?: string;
    experienceLevel?: number;
    coins?: number;
    points?: number;
    numberOfEmployees?: number;
    features?: PLAYER_SEARCH_RESULT_FEATURE_TYPE[];
    initials?: string;
}
