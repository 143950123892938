import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        storeMenuItem: css({
            display: 'flex',
            border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: 100,
            overflow: 'hidden',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: 'fit-content',
        }),
        coins: css({
            marginRight: theme.spacing(1),
        }),
        listItem: {
            width: 'fit-content',
            paddingTop: '2px',
            paddingBottom: '2px',
            // paddingLeft: theme.spacing(2),
            // paddingRight: theme.spacing(2),
        },
        listItemText: {
            display: 'block',
            flex: 'initial',
        },
    };
};
