import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ProfilePrefillData } from 'types';

export interface ProfileState {
    preFillData: ProfilePrefillData | undefined;
}

export const initialState: ProfileState = {
    preFillData: undefined,
};

const profile = createSlice({
    initialState,
    name: 'user',
    reducers: {
        setProfilePreFillData: (state, { payload }: PayloadAction<ProfilePrefillData>) => {
            state.preFillData = payload;
        },
    },
});

export const {
    actions: { setProfilePreFillData },
    reducer: profileReducer,
} = profile;
