import axios from 'axios';
import type {
    ISearch,
    IApiResponse,
    ISearchView,
    IEmployeeUpsertCommand,
    IEmployeeSearchModel,
    IAutocompleteView,
    IEmployeeView,
} from 'types';
import { api } from '../constants';

export const getEmployees = async ({
    pageIndex,
    pageSize,
    searchText,
}: ISearch): Promise<ISearchView<IEmployeeSearchModel>> => {
    return axios
        .get(
            `${api.employees}?pageIndex=${pageIndex}&pageSize=${pageSize}&searchText=${searchText}`,
        )
        .then((res) => res.data);
};

export const getUserAutocomplete = async (searchText?: string): Promise<IAutocompleteView[]> => {
    return axios
        .get(`${api.employees}/UserAutocomplete?searchText=${searchText}`)
        .then((res) => res.data);
};

export const getEmployeeAutocomplete = async (
    searchText?: string,
): Promise<IAutocompleteView[]> => {
    return axios
        .get(`${api.employees}/EmployeeAutocomplete?searchText=${searchText}`)
        .then((res) => res.data);
};

export const getByEmployeeId = async (employeeId: string): Promise<IEmployeeView> => {
    return axios.get(`${api.employees}/${employeeId}`).then((res) => res.data);
};

export const update = async (
    employeeSysId: string,
    data: IEmployeeUpsertCommand,
): Promise<IApiResponse> => {
    return axios.put(`${api.employees}/${employeeSysId}`, data);
};

export const create = async (data: IEmployeeUpsertCommand): Promise<IApiResponse> => {
    return axios.post(`${api.employees}`, data);
};

export const bulkImportValidation = async ({ bulkImportData }) => {
    return axios.put(`${api.employees}/bulkImportValidate`, {
        bulkImportEmployees: bulkImportData,
    });
};

export const bulkImport = async ({ bulkImportData }) => {
    return axios.put(`${api.employees}/bulkImportValidate`, {
        importTheData: true,
        bulkImportEmployees: bulkImportData,
    });
};

export const deleteEmployees = async ({ ids }) => {
    return axios.post(`${api.employees}/delete`, {
        ids,
    });
};
