import type { QuestionGroup } from './Survey';
import type { EvaluationBy } from './Evaluation';
import type { PROFILE_TYPE } from './Profile';

export enum QuestType {
    COMPANY = 'Company',
    TASK = 'Task',
    GROWTH = 'Growth',
    SKILL = 'Skill',
}

export interface QuestTypeConfigItem {
    id: QuestType;
    key: string;
}

export enum QuestStatus {
    DRAFT = 'Draft',
    ACTIVE = 'InProgress',
    EVALUATED = 'Evaluated',
    COMPLETED = 'Completed',
    ABANDONED = 'Abandon',
    ARCHIVED = 'Archive',
}

export enum KeyResultStatus {
    ACTIVE = 'Active',
    FINALIZED = 'Finalized',
}

export enum KeyResultAssignedTo {
    EMPLOYEE = 'Employee',
    TEAM = 'Team',
    JOB = 'Job',
}

export interface KeyResult {
    id?: string;
    order: number;
    descriptionShort: string;
    descriptionLong: string;
    status?: KeyResultStatus;
    assignedTo?: KeyResultAssignedTo;
    progressPercent?: number;
}

export enum CreateQuestStep {
    TYPE_STEP = 0,
    SKILL_STEP = 1,
    COMPETENCY_STEP = 2,
    OBJECTIVE_STEP = 3,
    USER_STEP = 4,
    SUCCESS_STEP = 5,
}

export enum UpdateQuestStep {
    UPDATE_STEP = 0,
    SUCCESS_STEP = 1,
}

export enum EvaluationType {
    GRADE = 'Scale',
    PERCENTAGE = 'Percentage',
}

export interface QuestPriorityConfig {
    id: string;
    name: string;
    rewardXp?: number;
    rewardCoins?: number;
}

export interface QuestAssignee {
    group?: string;
    type: KeyResultAssignedTo;
    name: string;
    id: string;
    profilePhoto?: string;
}

export enum QuestFeature {
    JOIN = 'Join',
    ABANDON = 'Abandon',
    UPDATE_QUEST = 'UpdateQuest',
    UPDATE_PROGRESS = 'UpdateProgress',
    EVALUATE = 'Evaluate',
    SELF_EVALUATE = 'Selfevaluate',
    ARCHIVE = 'Archive',
    REOPEN = 'Reopen',
}

export interface QuestSurvey {
    type: EvaluationBy;
    questionGroups: QuestionGroup[];
}

export interface Quest {
    objectiveId: string;
    type: QuestType;
    title?: string;
    descriptionShort: string;
    descriptionLong: string;
    priorityId: string;
    dateFrom: string;
    dateTo: string;
    parentQuestId?: string;
    confidential: boolean;
    relatedCompetency?: string;
    relatedSkills?: string[];
    assignedTo: QuestAssignee[];
    keyResults?: KeyResult[];
    features?: QuestFeature[];
    xp?: number;
    coins?: number;
    status: QuestStatus;
    statusDescription?: string;
    surveyEmployee?: QuestSurvey;
    surveyLeader?: QuestSurvey;
    progressPercent?: number;
}

export interface AssignedToWithType {
    id: string;
    type: PROFILE_TYPE;
}

export interface QuestFilterObject {
    type: QuestType[];
    status: QuestStatus[];
    assignedTo: QuestAssignee[] | string[];
    query: string;
}

export interface EvaluationQuestData {
    dateFrom: string;
    dateTo: string;
    title: string;
    type: QuestType;
}
