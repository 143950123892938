import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Notification } from 'types';
import { useQueriedPaginatedItems } from 'hooks';
import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import { api } from 'services';
import { InfiniteScroll } from '../../InfiniteScroll';
import { Loading } from '../../Loading';
import { EventRow } from '../../activityStream/EventRow';
import { useStyles } from './styles';

interface Props {
    query?: string;
}

export const NotificationsTable = ({ query = '' }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isLoading, items, pagingActive, queryItems } = useQueriedPaginatedItems<Notification>({
        api: api.notificationsController.getNotifications,
        query: useMemo(() => ({ query }), [query]),
    });

    return (
        <TableContainer component={Paper} sx={classes.tableContainer}>
            <Toolbar>
                <Typography variant="h4" component="h2">
                    {t('notificationsTable.title.label')}
                </Typography>
            </Toolbar>
            <Divider />
            <InfiniteScroll active={pagingActive} onEndOfScreen={queryItems}>
                <Table>
                    <TableBody>
                        {items.map((notification) => (
                            <EventRow
                                streamItem={notification}
                                key={notification.activityStreamId}
                                showSecondaryPhoto={false}
                            />
                        ))}
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Loading transitionEnterDuration={0} transitionDelayIn={0} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </InfiniteScroll>
        </TableContainer>
    );
};
