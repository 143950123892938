import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        title: {
            marginBottom: theme.spacing(1),
        },
        assignee: css({
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),

            '& .MuiAvatar-root': {
                marginRight: theme.spacing(1),
            },
            cursor: 'pointer',
        }),
    };
};
