import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = () => {
    const theme = useTheme();

    return {
        card: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            cursor: 'pointer',
        },
        topicImage: css({
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(3),

            '& > .MuiAvatar-root': {
                height: '64px',
                width: '64px',
            },
        }),
        content: {
            padding: theme.spacing(4),
        },
    };
};
