import { useContext, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { CreateQuestContext } from 'contexts';
import type { AppReducerState } from 'store';
import { QuestType } from 'types';
import { useStyles } from './styles';
import { QuestTypeIcon } from '../QuestTypeIcon';

export const QuestTypePicker: FC = () => {
    const [hoveredType, setHoveredType] = useState<QuestType>();
    const { setType } = useContext(CreateQuestContext);
    const { questTypesEnabled } = useSelector(
        ({ configuration }: AppReducerState) => configuration,
    );
    const { t } = useTranslation();
    const classes = useStyles();

    const questTypes = [
        { id: QuestType.TASK, key: t('quest.questType.task.label') },
        {
            id: QuestType.GROWTH,
            key: t('quest.questType.growth.label'),
        },
        { id: QuestType.SKILL, key: t('quest.questType.skill.label') },
    ];

    const handleOnQuestTypeClick = (id) => () => setType(id);

    const handleOnQuestTypeKeyUp = (questType: QuestType) => (evt) => {
        if (evt.keyCode === 13) {
            setType(questType);
        }
    };

    const handleOnQuestTypeMouseEnter = (questType: QuestType) => () => setHoveredType(questType);

    const handleOnQuestTypeMouseLeave = () => setHoveredType(undefined);

    const handleOnQuestTypeFocus = (questType: QuestType) => () => setHoveredType(questType);

    if (questTypesEnabled.length === 1) {
        setType(questTypes[0].id);
    }

    const questTypeList = questTypes.filter(({ id }) =>
        questTypesEnabled.find(({ type }) => type === id),
    );

    return (
        <Grid container spacing={4} sx={classes.questTypePicker}>
            {questTypeList.map(({ id, key }) => {
                return (
                    <Grid
                        item
                        xs={12}
                        sm={(12 / questTypeList.length) as any}
                        key={id}
                        sx={{
                            ...classes.gridItem,
                            ...(hoveredType === id ? classes.focused : {}),
                        }}
                    >
                        <Card
                            sx={classes.questTypeCard}
                            tabIndex={0}
                            onClick={handleOnQuestTypeClick(id)}
                            onKeyUp={handleOnQuestTypeKeyUp(id)}
                            onMouseEnter={handleOnQuestTypeMouseEnter(id)}
                            onFocus={handleOnQuestTypeFocus(id)}
                            onBlur={handleOnQuestTypeMouseLeave}
                            onMouseLeave={handleOnQuestTypeMouseLeave}
                            raised={hoveredType === id}
                        >
                            <CardContent sx={classes.cardContent}>
                                <QuestTypeIcon questType={id} />
                                <Typography sx={classes.typeName}>{key}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};
