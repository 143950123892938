import axios from 'axios';
import type {
    IApiResponse,
    IScaleView,
    ISearch,
    ISearchView,
    IUpsertScaleCommand,
    IUpsertScaleValueCommand,
} from 'types';
import { api } from '../constants';

const controllerApi = api.scales;

export const getAllActive = async (): Promise<IScaleView[]> => {
    return axios.get(`${controllerApi}`).then((res) => res.data);
};

const searchAsync = async (queryParams: ISearch): Promise<ISearchView<IScaleView>> => {
    return axios
        .get(`${controllerApi}/search`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const getBySysId = async (id: string): Promise<IScaleView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};

export const create = async ({ name, status, triggerCarryOverQuestion }) => {
    return axios.post(`${controllerApi}`, {
        name,
        status,
        triggerCarryOverQuestion,
    });
};

export const update = async (id: string, data: IUpsertScaleCommand): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const deleteScale = async (scaleSysId: string): Promise<IApiResponse> => {
    return axios.delete(`${controllerApi}/${scaleSysId}`);
};

export const createValue = async (
    scaleSysId: string,
    data: IUpsertScaleValueCommand,
): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/${scaleSysId}/scale-values`, data);
};

export const updateValue = async (
    scaleSysId: string,
    id: string,
    data: IUpsertScaleValueCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${scaleSysId}/scale-values/${id}`, data);
};

export const deleteValue = async (
    scaleSysId: string,
    scaleValueSysId: string,
): Promise<IApiResponse> => {
    return axios.delete(`${controllerApi}/${scaleSysId}/scale-values/${scaleValueSysId}`);
};

export const scaleService = {
    getAllActive,
    searchAsync,
    getBySysId,
    create,
    update,
    deleteScale,
    createValue,
    updateValue,
    deleteValue,
};
