import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.companies;
const mainSettingsRoute = paths.companySettings;

const CompanyLoader = Loadable({
    loader: () => import('views/admin/Company'),
    loading: Loading,
    render(loaded, props) {
        const { Company } = loaded;
        return <Company {...props} />;
    },
    delay: general.delay,
});

const CompanyEditLoader = Loadable({
    loader: () => import('views/admin/Company/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

const CompanySettingsLoader = Loadable({
    loader: () => import('views/admin/CompanySettings'),
    loading: Loading,
    render(loaded, props) {
        const { CompanySettings } = loaded;
        return <CompanySettings {...props} />;
    },
    delay: general.delay,
});

export const companyRoute = {
    path: `/${mainRoute}`,
    exact: true,
    name: 'Company',
    component: CompanyLoader,
    details: {
        authenticationRequired: true,
        systemInstanceRequired: true,
        companyRequired: false,
    },
};

export const companyEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    exact: true,
    name: 'Edit Company',
    component: CompanyEditLoader,
    details: {
        authenticationRequired: true,
        systemInstanceRequired: true,
        companyRequired: false,
    },
};

export const companyCreateRoute = {
    path: `/${mainRoute}/create`,
    exact: true,
    name: 'Create Company',
    component: CompanyEditLoader,
    details: {
        authenticationRequired: true,
        systemInstanceRequired: true,
        companyRequired: false,
    },
};

export const companySettingsRoute = {
    path: `/${mainSettingsRoute}`,
    exact: false,
    name: 'CompanySettings',
    component: CompanySettingsLoader,
    details: {
        authenticationRequired: true,
        systemInstanceRequired: true,
        companyRequired: false,
    },
};
