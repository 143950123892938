import type { FC } from 'react';
import cx from 'classnames';
import Icon from '@mdi/react';
import { mdiAccountHeartOutline, mdiFeather, mdiSwordCross, mdiStarSettingsOutline } from '@mdi/js';
import { QuestType } from 'types';
import { useStyles } from './styles';

const QUEST_TYPE_ICON = {
    [QuestType.TASK]: mdiSwordCross,
    [QuestType.GROWTH]: mdiAccountHeartOutline,
    [QuestType.SKILL]: mdiFeather,
    [QuestType.COMPANY]: mdiStarSettingsOutline,
};

interface Props {
    className?: string;
    questType: QuestType;
}

export const QuestTypeIcon: FC<Props> = ({ className, questType }) => {
    const classes = useStyles(questType);

    return <Icon path={QUEST_TYPE_ICON[questType]} className={cx(className, classes.icon)} />;
};
