import type { FC } from 'react';
import type { Quest } from 'types';
import moment from 'moment';
import { sanitize } from 'dompurify';
import { Typography } from '@mui/material';
import { VisibilityOff } from '@mui/icons-material';
import { QuestStatusChip } from '../QuestStatusChip';
import { QuestDetailQuestTypeIcon } from '../QuestDetailQuestTypeIcon';
import { useStyles } from './styles';

const DATE_FORMAT = 'DD.MM.YYYY';

interface Props {
    quest: Quest;
}

export const QuestListCardContent: FC<Props> = ({ quest }) => {
    const classes = useStyles(quest);

    return (
        <div className={classes.flex}>
            <div className={classes.typeIconWrapper}>
                <QuestDetailQuestTypeIcon questType={quest.type} />
            </div>
            <div>
                <div>
                    <QuestStatusChip questStatus={quest.status} />
                    <Typography variant="caption" sx={classes.dates}>
                        {`${moment(quest.dateFrom).format(DATE_FORMAT)} - ${moment(
                            quest.dateTo,
                        ).format(DATE_FORMAT)}`}
                    </Typography>
                </div>
                <div className={classes.questName}>
                    {quest.confidential && <VisibilityOff sx={classes.visibilityIcon} />}
                    <Typography component="span">
                        <strong>{sanitize(quest.descriptionShort, { USE_PROFILES: {} })}</strong>
                    </Typography>
                </div>
            </div>
        </div>
    );
};
