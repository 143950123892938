import { useState } from 'react';
import type { FC } from 'react';
import { Grid } from '@mui/material';
import { AnalyticsQuestsTabFilter } from './AnalyticsQuestsTabFilter';
import { QuestsInType } from './QuestsInType';
import { GrowthQuestBehaviors } from './GrowthQuestBehaviors';
import { QuestsInStatus } from './QuestsInStatus';
import { useStyles } from './styles';

export const AnalyticsQuestsTab: FC = () => {
    const [filtersLoaded, setFiltersLoaded] = useState<boolean>();
    const classes = useStyles();

    const handleFiltersLoaded = (err) => setFiltersLoaded(!err);

    return (
        <>
            <AnalyticsQuestsTabFilter onFiltersLoaded={handleFiltersLoaded} />
            {filtersLoaded && (
                <Grid container spacing={2} sx={classes.graphsWrapper}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <QuestsInType />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <QuestsInStatus />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <GrowthQuestBehaviors />
                    </Grid>
                </Grid>
            )}
        </>
    );
};
