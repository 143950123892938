import { frankyTalesTheme } from 'theme';
import { QuestStatus } from 'types';

const { colors } = frankyTalesTheme;

const QUEST_STATUS_COLORS = {
    [QuestStatus.DRAFT]: colors.grey,
    [QuestStatus.ACTIVE]: colors.blueDarker,
    [QuestStatus.EVALUATED]: colors.teal,
    [QuestStatus.COMPLETED]: colors.teal,
    [QuestStatus.ABANDONED]: colors.orange,
    [QuestStatus.ARCHIVED]: colors.orange,
};

export const useStyles = (questStatus) => ({
    questStatus: {
        borderColor: QUEST_STATUS_COLORS[questStatus],
        color: QUEST_STATUS_COLORS[questStatus],
    },
});
