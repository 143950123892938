import { memo } from 'react';
import type { FC } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface Props {
    years: number[];
    filter: Record<string, any>;
    onFilterChange: (filter: Record<string, any>) => void;
}

export const EvaluationsFilter: FC<Props> = memo(({ filter, onFilterChange, years }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleYearChange = (evt) => onFilterChange({ year: evt.target.value });

    return (
        <div className={classes.select}>
            <FormControl size="small" fullWidth>
                <Select
                    value={filter.year}
                    onChange={handleYearChange}
                    displayEmpty
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    }}
                >
                    <MenuItem key={'noYear'} value={''}>
                        {t('evaluation.evaluationsFilter.allYears.label')}
                    </MenuItem>
                    {years.map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
});
