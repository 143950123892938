import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import type { Moment } from 'moment';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useStyles } from './styles.mui';
import { maxDate as maxDateConstant } from '../../../constants';

const DATE_FORMAT = 'DD.MM.YYYY';

interface Props {
    label: string;
    name: string;
    onChange: (date: Moment | null) => void;
    disabled?: boolean;
    enableInfinite?: boolean;
    minDate?: Moment;
    maxDate?: Moment;
    required?: boolean;
    control?: any;
    errors?: any;
}

const FtDatePicker: FC<Props> = ({
    label,
    name,
    onChange,
    disabled = false,
    enableInfinite = false,
    minDate = moment({ year: 2020, month: 0, day: 1 }),
    maxDate = maxDateConstant,
    control,
    required,
    errors,
}) => {
    const { t } = useTranslation();

    const classes = useStyles();
    const [infinite, setInfinite] = useState<boolean>(false);

    const handleInfiniteClick = (e) => {
        setInfinite(e.target.checked);
        onChange(maxDate);
    };

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                alignItems: 'center',
                justifyContent: 'stretch',
            }}
        >
            {enableInfinite && (
                <div className={classes.divCheckbox}>
                    <input
                        disabled={disabled}
                        type="checkbox"
                        onChange={handleInfiniteClick}
                        title={t('Infinite relation') as string}
                    />
                </div>
            )}
            <div>
                <Controller
                    name={name}
                    control={control}
                    rules={{ required: required || false }}
                    render={({ field }) => (
                        <DatePicker
                            label={`${label} ${(required && '*') || ''}`}
                            format={DATE_FORMAT}
                            {...field}
                            // mask="__.__.____"
                            value={moment(field.value)}
                            onChange={onChange}
                            minDate={minDate}
                            maxDate={maxDate}
                            disabled={disabled || infinite}
                            slotProps={{
                                textField: {
                                    error: errors[name],
                                },
                            }}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default FtDatePicker;
