import axios from 'axios';
import type { IApiResponse, IRewardSystemView, IUpdateRewardSystemCommand } from 'types';
import { api } from '../constants';

export const getByPerfCycleIdId = async (id): Promise<IRewardSystemView[]> => {
    return axios.get(`${api.rewardSystems}/${id}`).then((res) => res.data);
};

export const getByRewardSystemSysId = async (id: string): Promise<IRewardSystemView[]> => {
    return axios.get(`${api.rewardSystems}/${id}`).then((res) => res.data);
};

export const update = async (
    id: string,
    data: IUpdateRewardSystemCommand,
): Promise<IApiResponse> => {
    return axios.put(`${api.rewardSystems}/${id}`, data);
};
