import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type FeedbackPreFillData = Record<string, any> | undefined;

export interface FeedbackState {
    feedbackPreFillData?: FeedbackPreFillData;
}

const initialState: FeedbackState = {
    feedbackPreFillData: undefined,
};

const feedback = createSlice({
    initialState,
    name: 'feedback',
    reducers: {
        setFeedbackPreFillData: (state, { payload }: PayloadAction<FeedbackPreFillData>) => {
            state.feedbackPreFillData = payload;
        },
    },
});

export const {
    actions: { setFeedbackPreFillData },
    reducer: feedbackReducer,
} = feedback;
