import { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    Avatar as MuiAvatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import type { FeedbackToEntity } from 'types';
import { FEEDBACK_TARGET_TYPE } from 'types';

import { useStyles } from './styles';
import { EmployeeAvatar } from '../../avatar';

const MAX_AVATARS = 3;
const FEEDBACK_TO_URLS = {
    [FEEDBACK_TARGET_TYPE.EMPLOYEE]: '/profile',
    [FEEDBACK_TARGET_TYPE.JOB]: '/jobProfile',
    [FEEDBACK_TARGET_TYPE.TEAM]: '/teamProfile',
};

interface Props {
    className?: string;
    entitiesTo?: FeedbackToEntity[];
}

export const RecipientsList = ({ className, entitiesTo }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const handleRecipientsClick = () => {
        setIsModalOpen(true);
    };

    const noop = () => {};

    const handleOnModalClose = () => setIsModalOpen(false);

    if (!entitiesTo) {
        return null;
    }

    if (entitiesTo.length === 1) {
        return (
            <EmployeeAvatar
                alt={entitiesTo[0].initials}
                sx={classes.employeeAvatar}
                employeeId={entitiesTo[0].entityId || entitiesTo[0].feedbackToSysId}
                src={entitiesTo[0].photoPath}
                profileUrl={FEEDBACK_TO_URLS[entitiesTo[0].feedbackToType]}
            />
        );
    }

    return (
        <div className={className}>
            <div
                className={classes.avatarGroup}
                onClick={handleRecipientsClick}
                role="button"
                tabIndex={-1}
                onKeyUp={noop}
            >
                {entitiesTo.slice(0, MAX_AVATARS).map((entityTo) => (
                    <EmployeeAvatar
                        alt={entityTo.initials}
                        src={entityTo.photoPath}
                        preventClick={false}
                    />
                ))}
                {entitiesTo.length > MAX_AVATARS && (
                    <MuiAvatar className="dialogButton">+</MuiAvatar>
                )}
            </div>
            <Dialog
                open={isModalOpen}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="bigPadding"
            >
                <DialogTitle sx={classes.dialogTitle}>
                    <IconButton
                        aria-label="close"
                        onClick={handleOnModalClose}
                        size="small"
                        sx={classes.dialogCloseButton}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={classes.dialogContent}>
                    <Typography component="span" variant="h4">
                        {t('recipientsList.modal.title.label')}
                    </Typography>
                    <Typography sx={classes.dialogDescription}>
                        {t('recipientsList.modal.description.label')}
                    </Typography>
                    {entitiesTo.map(
                        ({
                            entityId,
                            feedbackToSysId,
                            feedbackToType,
                            helpful,
                            initials,
                            name,
                            photoPath,
                        }) => (
                            <div className={classes.employeeWrapper}>
                                <EmployeeAvatar
                                    alt={initials}
                                    employeeId={entityId || feedbackToSysId}
                                    src={photoPath}
                                    profileUrl={FEEDBACK_TO_URLS[feedbackToType]}
                                />
                                <div className={classes.helpfulWrapper}>
                                    <Typography>{name}</Typography>
                                    {helpful != null && (
                                        <Typography variant="caption">
                                            {t('myFeedback.feedbackListSentRow.helpfulSentence')}
                                            <span
                                                className={cx({
                                                    [classes.orange]: !helpful,
                                                    [classes.teal]: helpful,
                                                })}
                                            >
                                                {!helpful &&
                                                    t('myFeedback.feedbackListSentRow.notHelpful')}
                                                {helpful &&
                                                    t('myFeedback.feedbackListSentRow.helpful')}
                                            </span>
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        ),
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="contained" onClick={handleOnModalClose}>
                        {t('recipientsList.modal.actions.close.label')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
