import { useCallback } from 'react';
import type { FC } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import type { Question } from 'types';
import { useStyles } from '../common/question.styles';

interface Props {
    className?: string;
    question: Question;
    showAsAnswer?: boolean;
    globalValidation?: boolean;
}

export const RadioQuestion: FC<Props> = ({
    className,
    question,
    showAsAnswer = false,
    globalValidation = false,
}) => {
    const { id, mandatory, questionText, options } = question;
    const classes = useStyles();
    const { t } = useTranslation();
    const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
        type: 'text',
        name: id,
        validate: (text) => {
            return mandatory && !text
                ? t<string>('form.textQuestion.validation.valueMissing.label')
                : undefined;
        },
    });
    const shouldShowError = (touched || globalValidation) && !!error;

    const onQuestionChange = useCallback((evt) => setValue(evt.target.value), [setValue]);

    const onQuestionBlur = useCallback(() => setTouched(true), [setTouched]);

    return (
        <div className={cx(classes.question, className)}>
            <Typography sx={classes.title}>
                <strong>{questionText}</strong>
            </Typography>
            {!showAsAnswer && (
                <FormControl error={shouldShowError} size="small" fullWidth>
                    <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        value={value}
                        onChange={onQuestionChange}
                        onBlur={onQuestionBlur}
                    >
                        {options?.map((option) => (
                            <FormControlLabel
                                key={`${question.id}${option.id}`}
                                value={option.id}
                                control={<Radio />}
                                label={option.descriptionShort}
                            />
                        ))}
                    </RadioGroup>
                    <FormHelperText>{shouldShowError ? error : ''}</FormHelperText>
                </FormControl>
            )}
            {showAsAnswer && (
                <Typography>
                    {options?.find((option) => option.id === value)?.descriptionShort}
                </Typography>
            )}
        </div>
    );
};
