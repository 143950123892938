import type { FC } from 'react';
import { useState } from 'react';
import cx from 'classnames';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { Close, InfoOutlined } from '@mui/icons-material';
import type { SvgIconComponent } from '@mui/icons-material';
import type { TypographyVariant } from '@mui/material';
import { TooltipColor } from 'types';
import { useStyles } from './styles';

interface Props {
    className?: string;
    color?: TooltipColor;
    icon?: SvgIconComponent;
    text?: string;
    textVariant?: TypographyVariant;
    title?: string;
    tooltip?: string;
}

const ENTER_TOUCH_DELAY = 0;
const LEAVE_TOUCH_DELAY = 2500;

export const InformationTooltip: FC<Props> = ({
    className,
    color = TooltipColor.DEFAULT,
    icon = InfoOutlined,
    text,
    textVariant = 'body1',
    title,
    tooltip,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const classes = useStyles();
    const IconComponent = icon;

    const handleClick = (evt) => evt.stopPropagation();

    const handleModalOpen = () => setModalOpen(true);

    const handleModalClose = () => setModalOpen(false);

    if (!tooltip && !text && !title) {
        return null;
    }

    if (!tooltip && text) {
        return (
            <div className={cx(classes.wrapper, color)}>
                {text && <Typography>{text}</Typography>}
            </div>
        );
    }

    if (title) {
        return (
            <div className={cx(classes.wrapper, color, className)}>
                {text && <Typography variant={textVariant}>{text}</Typography>}
                <IconComponent fontSize="small" onClick={handleModalOpen} />
                <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="biggerPadding"
                >
                    <DialogTitle id="modal-title" sx={classes.dialogTitle}>
                        {title && <Typography variant="h4">{title}</Typography>}
                        <IconButton
                            aria-label="close"
                            onClick={handleModalClose}
                            sx={classes.closeButton}
                            size="large"
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="modal-messsage">
                            {tooltip && <Typography>{tooltip}</Typography>}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    return (
        <Tooltip
            title={tooltip}
            enterTouchDelay={ENTER_TOUCH_DELAY}
            leaveTouchDelay={LEAVE_TOUCH_DELAY}
            placement="top"
            onClick={handleClick}
            className={className}
        >
            <div className={cx(classes.wrapper, color)}>
                {text && <Typography>{text}</Typography>}
                <IconComponent fontSize="small" />
            </div>
        </Tooltip>
    );
};
