import type { VFC } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Grid, Modal, Typography } from '@mui/material';
import { useBoundActions, useService, useQuestLogFilter } from 'hooks';
import type { ProfileData, Quest } from 'types';
import { KeyResultAssignedTo, PROFILE_TYPE } from 'types';
import { api } from 'services';
import { setQuestPreFillData } from 'store/slices/quest';
import { QuestDetail } from 'views/QuestDetail';

import { useStyles } from './styles';
import { QuestListCard } from '../../quest';
import { Loading } from '../../Loading';

interface Props {
    profile: ProfileData;
}

const NUM_QUESTS_TO_SHOW = 3;

export const ProfileQuestList: VFC<Props> = ({ profile }) => {
    const { setQuestLogFilterPreFillDataAction } = useQuestLogFilter();
    const [questDetailId, setQuestDetailId] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setQuestPreFillDataAction } = useBoundActions({
        setQuestPreFillData,
    });
    const { data, error, isLoading } = useService<{ items: Quest[] }>({
        api: api.objectiveController.getObjectives,
        query: { assignedTo: [profile.employeeSysId], pageSize: NUM_QUESTS_TO_SHOW + 1, page: 0 },
    });
    const classes = useStyles();

    const quests = data?.items || [];

    const handleQuestLogButtonClick = () => {
        setQuestLogFilterPreFillDataAction({
            assignedToWithType: {
                id: profile.employeeSysId,
                type: PROFILE_TYPE.PLAYER,
            },
        });
        navigate('/quest/log');
    };

    const handleCreateQuestButtonClick = () => {
        setQuestPreFillDataAction({
            assignedTo: [
                {
                    group: t('quest.addPlayerStep.form.assignTo.groups.jobs.label'),
                    type: KeyResultAssignedTo.EMPLOYEE,
                    name: `${profile.firstName} ${profile.lastName}`,
                    id: profile.employeeSysId,
                    profilePhoto: profile.profilePhoto,
                },
            ],
        });
        navigate('/quest/new');
    };

    const handleOnQuestClick = (questId) => setQuestDetailId(questId);

    const handleQuestDetailClose = () => setQuestDetailId('');

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return null;
    }

    return (
        <>
            <div className={classes.titleWrapper}>
                <Typography variant="h2" component="h4" sx={classes.title}>
                    {t('jobProfile.jobProfileQuestList.title.label')}
                </Typography>
                {quests.length > NUM_QUESTS_TO_SHOW && (
                    <Button onClick={handleQuestLogButtonClick}>
                        {t('jobProfile.jobProfileQuestList.actions.goToQuestLog.label')}
                    </Button>
                )}
            </div>
            <Grid container spacing={2}>
                {!quests.length && (
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={classes.emptyContent}>
                                <Typography variant="h4" sx={classes.emptyQuestsTitle}>
                                    {t('jobProfile.jobProfileQuestList.emptyMessage.label')}
                                </Typography>
                                <Button onClick={handleCreateQuestButtonClick} variant="contained">
                                    {t('jobProfile.jobProfileQuestList.actions.createQuest.label')}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {!!quests.length &&
                    quests.slice(0, NUM_QUESTS_TO_SHOW).map((quest) => (
                        <Grid item key={quest.objectiveId} xs={12}>
                            <QuestListCard quest={quest} onQuestClick={handleOnQuestClick} />
                        </Grid>
                    ))}
            </Grid>
            <Modal open={!!questDetailId} sx={classes.modal}>
                <QuestDetail questId={questDetailId} closeHandle={handleQuestDetailClose} />
            </Modal>
        </>
    );
};
