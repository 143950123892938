import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.employeeTeams;

const EmployeeTeamLoader = Loadable({
    loader: () => import('views/admin/EmployeeTeam'),
    loading: Loading,
    render(loaded, props) {
        const { EmployeeTeam } = loaded;
        return <EmployeeTeam {...props} />;
    },
    delay: general.delay,
});

const EmployeeTeamEditLoader = Loadable({
    loader: () => import('views/admin/EmployeeTeam/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const employeeTeamRoute = {
    path: `/${mainRoute}`,
    component: EmployeeTeamLoader,
};

export const employeeTeamCreateRoute = {
    path: `/${mainRoute}/create`,
    component: EmployeeTeamEditLoader,
};

export const employeeTeamEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: EmployeeTeamEditLoader,
};
