import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        contentWrapper: {
            paddingTop: `${theme.spacing(0)} !important`,

            '& .section': {
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
            },
            '& .bullet': {
                marginLeft: theme.spacing(2),
            },
            '& .MuiTypography-body1': {
                ...theme.typography.body1,
            },
            '& MuiTypography-h3': {
                ...theme.typography.h3,
            },
        },
        gdprDialog: {
            '& .MuiDialogTitle-root': {
                paddingBottom: `${theme.spacing(0)} !important`,
            },
        },
    };
};
