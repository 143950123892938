import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        keyResultInputWrapper: css({
            display: 'flex',
            alignItems: 'flex-start',
        }),
        keyResultAddButton: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
        keyResultDeleteButton: {
            marginLeft: theme.spacing(2),
        },
        marginBottom: {
            marginBottom: theme.spacing(1),
        },
    };
};
