import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        description: {
            marginTop: theme.spacing(1),
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        tableActions: {
            textAlign: 'center',
        },
        tableContainer: {
            overflowY: 'hidden',
        },
    };
};
