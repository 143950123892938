import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@mui/material';
import { LANGUAGE } from 'types';
import type { AppReducerState } from 'store';

import { useStyles } from './styles';

const LANGUAGES = [
    { lng: LANGUAGE.EN, label: 'profile.settings.language.english.label' },
    { lng: LANGUAGE.DE, label: 'profile.settings.language.german.label' },
    { lng: LANGUAGE.SK, label: 'profile.settings.language.slovak.label' },
    { lng: LANGUAGE.SL, label: 'profile.settings.language.slovenian.label' },
    { lng: LANGUAGE.CS, label: 'profile.settings.language.czech.label' },
    { lng: LANGUAGE.HR, label: 'profile.settings.language.croatian.label' },
];

export const LanguagePicker: FC = () => {
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const [languagesMenuOpen, setLanguagesMenuOpen] = useState(false);
    const { lng: language } = useSelector(({ user }: AppReducerState) => user);
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const handleLanguagesMenuClick = () => setLanguagesMenuOpen(true);

    const handleLanguagesMenuClose = () => setLanguagesMenuOpen(false);

    const handleChangeLanguage = (lang) => () => {
        handleLanguagesMenuClose();
        i18n.changeLanguage(lang);
    };

    return (
        <>
            <Button
                variant="contained"
                sx={classes.languagePicker}
                onClick={handleLanguagesMenuClick}
                ref={menuButtonRef}
                aria-label={language}
                aria-controls="language-menu"
                aria-haspopup="true"
            >
                {language}
            </Button>
            <Menu
                id="language-menu"
                anchorEl={menuButtonRef.current}
                keepMounted
                onClose={handleLanguagesMenuClose}
                open={languagesMenuOpen}
            >
                {LANGUAGES.map(({ lng, label }) => (
                    <MenuItem onClick={handleChangeLanguage(lng)} key={lng}>
                        {t(label)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
