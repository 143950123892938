import { useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';

export const useLayout = () => {
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'), { noSsr: true });
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'), { noSsr: true });
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'), { noSsr: true });
    const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.only('lg'), { noSsr: true });
    const isXl = useMediaQuery('@media (min-width:1500px)', { noSsr: true });
    const isXxl = useMediaQuery('@media (min-width:1921px)', { noSsr: true });
    let menuWidth: number | string = 296;
    let width: string | number = 1140;
    let headerHeight = 352;
    let menuTopOffset = 0;
    let permanentMenu = true;
    let sidePadding = 32;
    let menuPadding = 296;

    if (isLg) {
        menuPadding = 256;
        menuWidth = 256;
        width = 960;
        sidePadding = 0;
    }

    if (isXl) {
        menuWidth = 296;
        menuPadding = 296;
        width = 1140;
        sidePadding = 0;
    }

    if (isXxl) {
        // menuTopOffset = 352;
        menuPadding = 0;
        menuTopOffset = 100;
        // sidePadding = 0;
    }

    if (isMd) {
        menuWidth = 296;
        menuPadding = 0;
        permanentMenu = false;
        headerHeight = 192;
        width = 896;
    }

    if (isSm) {
        menuWidth = '70%';
        permanentMenu = false;
        headerHeight = 192;
        width = 540;
        menuPadding = 0;
        sidePadding = 30;
    }

    if (isXs) {
        menuWidth = '100%';
        permanentMenu = false;
        headerHeight = 192;
        width = '100%';
        menuPadding = 0;
        sidePadding = 16;
    }

    return {
        headerHeight,
        isXs,
        isSm,
        isMd,
        isLg,
        isXl,
        isXxl,
        menuPadding,
        menuTopOffset,
        menuWidth,
        permanentMenu,
        sidePadding,
        width,
    };
};
