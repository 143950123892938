import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        title: {
            marginBottom: theme.spacing(1),
        },
        quest: {
            cursor: 'pointer',
        },
        questName: css({
            marginTop: theme.spacing(1),
            display: 'flex',
        }),
        dates: {
            marginLeft: theme.spacing(1),
        },
        typeIconWrapper: css({
            marginRight: theme.spacing(2),
        }),
        visibilityIcon: {
            marginRight: theme.spacing(1),
            color: colors.blue,
        },
        flex: css({
            border: `1px solid ${colors.greyLight}`,
            padding: theme.spacing(2),
            borderRadius: theme.spacing(2),
            display: 'flex',
        }),
    };
};
