import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.employeeJobs;

const EmployeeJobLoader = Loadable({
    loader: () => import('views/admin/EmployeeJob'),
    loading: Loading,
    render(loaded, props) {
        const { EmployeeJob } = loaded;
        return <EmployeeJob {...props} />;
    },
    delay: general.delay,
});

const EmployeeJobEditLoader = Loadable({
    loader: () => import('views/admin/EmployeeJob/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const employeeJobRoute = {
    path: `/${mainRoute}`,
    component: EmployeeJobLoader,
};

export const employeeJobCreateRoute = {
    path: `/${mainRoute}/create`,
    component: EmployeeJobEditLoader,
};

export const employeeJobEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: EmployeeJobEditLoader,
};
