import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        languageOption: {
            paddingLeft: theme.spacing(5),
        },
    };
};
