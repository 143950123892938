import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        bad: css({
            color: colors.orange,
        }),
        good: css({
            color: colors.lightGreen,
        }),
        icon: css({
            transform: 'translate(2px, -1px)',
            height: '1.5rem',
            width: '1.5rem',
            position: 'absolute',
        }),
        radioLabel: {
            alignItems: 'flex-start',
            paddingBottom: theme.spacing(1),

            '& .MuiButtonBase-root': {
                top: theme.spacing(-1),

                '&.Mui-checked + span': {
                    textShadow: '-0.04ex 0 currentColor, 0.04ex 0 currentColor',
                },
            },
        },
    };
};
