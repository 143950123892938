import { frankyTalesTheme } from './frankyTalesTheme';

const { colors } = frankyTalesTheme;

const root = {
    height: '32px',
    width: '32px',

    '&.Mui-completed': {
        fill: colors.darkBlue,
    },
};

export const MuiStepIcon = {
    styleOverrides: {
        root,
    },
};
