import { lineRadial, curveCardinalClosed } from 'd3';
import type { ScaleLinear } from 'd3';
import type { ChartTuple } from 'types';
import { useStyles } from './RadarChartArea.styles';

const RADIUS = 4;

interface Props {
    index: number;
    lineData: { color: string; data: ChartTuple<string>[] };
    scale: ScaleLinear<number, number>;
    sliceAngle: number;
}

export const RadarChartArea = ({ index, lineData, scale, sliceAngle }: Props) => {
    const classes = useStyles(lineData.color);

    const radarLine = lineRadial<ChartTuple<string>>()
        .curve(curveCardinalClosed)
        .radius((d) => scale(d.value))
        .angle((d, i) => i * sliceAngle);

    return (
        <g>
            <path
                d={radarLine(lineData.data) || ''}
                className={classes.valueLine}
                clipPath="url(#personal-plan-graph-cut-off-around)"
                data-cy="personal-plan-graph-line"
            />
            {lineData.data.map((value, idx) => (
                <circle
                    cx={scale(value.value) * Math.cos(sliceAngle * idx - Math.PI / 2)}
                    cy={scale(value.value) * Math.sin(sliceAngle * idx - Math.PI / 2)}
                    r={RADIUS}
                    className={classes.valueCircle}
                    key={`${index}${value.key}`}
                />
            ))}
        </g>
    );
};
