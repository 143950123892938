import type { ChangeEvent, FC } from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmailInput } from 'components';
import { SignUpContext } from 'contexts';
import { sanitize } from 'dompurify';
import { getEmailErrors } from 'services';
import { Alert, Button, Grid, TextField, Typography } from '@mui/material';

import { useStyles as useCommonStyles } from '../common/styles';
import { useStyles } from './signUpStyles';

interface Props {
    onContinueClick: () => void;
}

export const SignUpForm: FC<Props> = ({ onContinueClick }) => {
    const { firstName, setFirstName, lastName, setLastName, email, setEmail } =
        useContext(SignUpContext);
    const [serviceError, setServiceError] = useState('');
    const [validationErrors, setValidationErrors] = useState<any>({});
    const navigate = useNavigate();
    const { t } = useTranslation();
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const getErrors = () => {
        const errors = {} as any;
        const requiredMessage = t('quest.setObjectiveStep.form.validation.requiredMessage.label');
        const emailErrors = getEmailErrors(email, t);

        if (emailErrors.length) {
            errors.email = emailErrors[0].message;
        }

        if (!firstName) {
            errors.firstName = requiredMessage;
        }

        if (!lastName) {
            errors.lastName = requiredMessage;
        }

        return errors;
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();

        const errors = getErrors();

        if (Object.keys(errors).length) {
            setValidationErrors(errors);
        } else {
            onContinueClick();
        }
    };

    const handleGoToLogin = () => navigate('/login');

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = sanitize(event.target.value, { USE_PROFILES: {} });
        setFirstName(value);
        setValidationErrors([]);
        setServiceError('');
    };

    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = sanitize(event.target.value, { USE_PROFILES: {} });
        setLastName(value);
        setValidationErrors([]);
        setServiceError('');
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        setValidationErrors([]);
        setServiceError('');
    };

    const emailError = validationErrors.email
        ? { message: validationErrors.email, field: 'email' }
        : undefined;

    return (
        <div className={classes.content}>
            <Typography variant="h1" sx={classes.title}>
                {t('user.onboarding.signUpForm.title.label')}
            </Typography>
            <Typography variant="body1" sx={classes.grayText}>
                {t('user.onboarding.signUpForm.info.label')}
            </Typography>
            <br />
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={t<string>('user.onboarding.signUpForm.firstName.placeholder')}
                            value={firstName}
                            onChange={handleFirstNameChange}
                            size="small"
                            error={!!validationErrors.firstName}
                            helperText={validationErrors.firstName}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t<string>('user.onboarding.signUpForm.lastName.placeholder')}
                            value={lastName}
                            onChange={handleLastNameChange}
                            size="small"
                            error={!!validationErrors.lastName}
                            helperText={validationErrors.lastName}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EmailInput
                            email={email}
                            emailError={emailError}
                            onEmailChange={handleEmailChange}
                            hideAdornment
                            useLabel
                        />
                    </Grid>
                    {serviceError && (
                        <Grid item xs={12}>
                            <Alert severity="error">{serviceError}</Alert>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" sx={commonClasses.primaryAction}>
                            {t('user.onboarding.signUpForm.actions.continue.label')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={commonClasses.actions}>
                        <Typography variant="subtitle2" component="span" sx={classes.darkBlueText}>
                            {t('user.onboarding.signUpForm.alreadyGotAccount.label')}
                        </Typography>
                        <Button
                            variant="text"
                            sx={commonClasses.secondaryAction}
                            onClick={handleGoToLogin}
                        >
                            {t('user.onboarding.signUpForm.actions.backToLogin.label')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
