import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { EmployeeEvaluationStatus } from 'types';
import { useStyles } from './styles';

const STATUS_TRANSLATION_KEYS = {
    [EmployeeEvaluationStatus.NOT_STARTED]: 'evaluation.evaluationStatus.notStarted.label',
    [EmployeeEvaluationStatus.IN_PROGRESS]: 'evaluation.evaluationStatus.inProgress.label',
    [EmployeeEvaluationStatus.FINISHED]: 'evaluation.evaluationStatus.finished.label',
    [EmployeeEvaluationStatus.EXPIRED]: 'evaluation.evaluationStatus.expired.label',
};

interface Props {
    evaluationStatus: EmployeeEvaluationStatus;
}

export const EvaluationStatusChip: FC<Props> = ({ evaluationStatus }) => {
    const classes = useStyles(evaluationStatus);
    const { t } = useTranslation();

    return (
        <Chip
            label={t(STATUS_TRANSLATION_KEYS[evaluationStatus])}
            size="small"
            variant="outlined"
            sx={classes.evaluationStatus}
        />
    );
};
