import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import type { AppReducerState } from 'store';
import { useBoundActions } from 'hooks';
import { setAnalyticsFilterPreFillData } from 'store/slices/analytics';

// const shouldLoadSavedFilter = (date) => date && moment(date).isAfter(moment().subtract(2, 'hours'));

export const selectAnalyticsFilter = createSelector(
    (state: AppReducerState) => state.user,
    (state: AppReducerState) => state.analytics.analyticsPreFillData,
    (employee, analyticsPreFillData) => {
        // const shouldLoad = shouldLoadSavedFilter(questLogFilterPreFillData?.timeStamp);

        return {
            filter: {
                dateFrom: moment().startOf('year'),
                dateTo: moment(),
                hierarchies: [],
                jobs: [],
                questTypes: [],
                ...(analyticsPreFillData?.filter || {}),
            },
        };
    },
);

export const useAnalyticsFilter = () => {
    const { filter } = useSelector(selectAnalyticsFilter);

    const { setAnalyticsFilterPreFillDataAction } = useBoundActions({
        setAnalyticsFilterPreFillData,
    });

    return {
        filter,
        setAnalyticsFilterPreFillDataAction,
    };
};
