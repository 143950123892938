import { css } from '@emotion/css';
import { frankyTalesTheme, hexToRgba } from 'theme';
import { useTheme } from '@mui/material';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        profileMenuItem: css({
            border: `1px solid ${hexToRgba(colors.grey, 0.3)}`,
            borderRadius: `${theme.shape.borderRadius}px`,
            overflow: 'hidden',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        }),
        avatarBox: css({
            width: '100%',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
        }),
        name: {
            marginLeft: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium,
        },
    };
};
