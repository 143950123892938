import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        characterAvatar: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
        employeeAvatarWrapper: {
            verticalAlign: 'baseline',
            width: theme.spacing(10),
        },
        characterAvatarWrapper: {
            width: theme.spacing(11),
            verticalAlign: 'top',
        },
        orange: {
            color: colors.orange,

            '&.Mui-disabled': {
                color: colors.orange,
            },
        },
        teal: {
            color: colors.lightGreen,

            '&.Mui-disabled': {
                color: colors.lightGreen,
            },
        },
        grey: {
            color: colors.grey,
        },
        note: {
            whiteSpace: 'pre-wrap',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            backgroundColor: colors.greyLighter2,
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        },
        good: {
            color: colors.lightGreen,
            transform: 'translate(0px, 4px)',
            height: '1.25rem',
            width: '1.25rem',
        },
        bad: css({
            color: colors.orange,
            transform: 'translate(0px, 4px)',
            height: '1.25rem',
            width: '1.25rem',
        }),
        recipientsWrapper: css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
        }),
        recipientsList: css({
            marginBottom: theme.spacing(1),
        }),
    };
};
