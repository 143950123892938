const root = {
    '&.biggerPadding .MuiCardContent-root': {
        padding: '24px',
    },

    '&.bigPadding .MuiCardContent-root': {
        padding: '32px',
    },
};

export const MuiCard = {
    styleOverrides: {
        root,
    },
};
