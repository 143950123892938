import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        employeeAvatarWrapper: {
            verticalAlign: 'baseline',
            width: '1%',
        },
        characterAvatarWrapper: {
            verticalAlign: 'top',
            width: theme.spacing(11),
        },
        characterAvatar: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
        row: {
            verticalAlign: 'top',
        },
        badge: {
            '& .MuiBadge-badge': {
                right: 'initial',
                left: theme.spacing(1),
                transform: 'translate(0%, -50%)',
                backgroundColor: colors.blue,
            },
        },
        recipientsWrapper: css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
        }),
        recipientsList: css({
            marginBottom: theme.spacing(1),
        }),
    };
};
