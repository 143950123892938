import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { sanitize } from 'dompurify';
import {
    Autocomplete,
    CircularProgress,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import { KeyResultAssignedTo } from 'types';
import type { QuestAssignee } from 'types';
import { api, callService } from 'services';
import { Avatar } from '../../avatar';
import { useStyles } from './styles';

const MIN_LENGTH_FOR_SEARCH = 2;

const ASSIGNED_TO_TYPES = {
    employees: {
        groupKey: 'quest.addPlayerStep.form.assignTo.groups.employees.label',
        type: KeyResultAssignedTo.EMPLOYEE,
        profileUrl: 'profile',
    },
    teams: {
        groupKey: 'quest.addPlayerStep.form.assignTo.groups.teams.label',
        type: KeyResultAssignedTo.TEAM,
        profileUrl: 'teamProfile',
    },
    jobs: {
        groupKey: 'quest.addPlayerStep.form.assignTo.groups.jobs.label',
        type: KeyResultAssignedTo.JOB,
        profileUrl: 'jobProfile',
    },
};

interface Props {
    searchFilter?: string;
    onSearch: (searchFilter?: string) => void;
    className?: string;
}

export const PlayerFilter: FC<Props> = ({ searchFilter, onSearch, ...rest }) => {
    const { t } = useTranslation();
    const [users, setUsers] = useState<QuestAssignee[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const classes = useStyles();

    const handleOnAssigneesChange = (evt, option) => {
        if (option == null) {
            onSearch('');
            return;
        }

        if (typeof option === 'string') {
            onSearch(sanitize(option, { USE_PROFILES: {} }));
            return;
        }

        const assignedToType = Object.values(ASSIGNED_TO_TYPES).find(
            (assignedToTypeObj) => assignedToTypeObj.type === option.type,
        );

        if (assignedToType) {
            navigate(`/${assignedToType.profileUrl}/${option.id}`);
        }
    };

    const getInputItem = (assigneeItem, key) => ({
        group: t(ASSIGNED_TO_TYPES[key].groupKey),
        type: ASSIGNED_TO_TYPES[key].type,
        name: assigneeItem.title || assigneeItem.displayName,
        id:
            assigneeItem.employeeSysId ||
            assigneeItem.employeeId ||
            assigneeItem.teamId ||
            assigneeItem.jobId,
        profilePhoto: assigneeItem.profilePhoto,
    });

    const parseSearchedUsers = (payload) =>
        Object.keys(payload).reduce(
            (acc, key) => [
                ...acc,
                ...payload[key].map((searchedUser) => getInputItem(searchedUser, key)),
            ],
            [] as any,
        );

    const handleUsersSearch = async (evt) => {
        setUsers([]);

        if (evt.target.value?.length >= MIN_LENGTH_FOR_SEARCH) {
            setIsLoading(true);

            const { error, payload } = await callService({
                api: api.employeeController.autoSuggest,
                query: { query: evt?.target?.value },
            });

            if (!error && payload) {
                setUsers(parseSearchedUsers(payload));
                setIsLoading(false);
            }
        }
    };

    return (
        <Autocomplete
            {...rest}
            freeSolo
            onChange={handleOnAssigneesChange}
            getOptionLabel={(option: QuestAssignee | string) =>
                typeof option === 'string' ? option : option.name
            }
            groupBy={(option) => option.group || ''}
            renderOption={(props, option) => (
                <li className={classes.avatarWrapper} {...props}>
                    <Avatar src={option.profilePhoto} alt={option.name[0]} sx={classes.avatar} />
                    <Typography>{option.name}</Typography>
                </li>
            )}
            options={users}
            popupIcon={null}
            onInputChange={handleUsersSearch}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    className="searchInput"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};
