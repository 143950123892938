import { Typography } from '@mui/material';
import type { Notification } from 'types';

import { EventRowMessageItem } from '../EventRowMessageItem';

interface Props {
    streamItem: Notification;
}

const REG_EXP = /(#\w+)/g;

export const EventRowMessage = ({ streamItem }: Props) => {
    if (!streamItem.lines) {
        return null;
    }

    return (
        <Typography>
            {streamItem.lines?.map((line) => (
                <>
                    {line
                        .split(REG_EXP)
                        .map((lineItem) =>
                            lineItem[0] === '#' ? (
                                <EventRowMessageItem
                                    item={streamItem.data[lineItem.slice(1)]}
                                    key={streamItem.data[lineItem.slice(1)]?.displayName}
                                />
                            ) : (
                                lineItem
                            ),
                        )}
                    <br />
                </>
            ))}
        </Typography>
    );
};
