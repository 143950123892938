import { frankyTalesTheme, hexToRgba } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        dataWrapper: css({
            marginLeft: theme.spacing(2),
            fontWeight: theme.typography.fontWeightMedium,
            display: 'flex',
            flexDirection: 'column',
        }),
        wrapper: css({
            border: `1px solid ${hexToRgba(colors.grey, 0.3)}`,
            borderRadius: `${theme.shape.borderRadius}px`,
            overflow: 'hidden',
            padding: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
        }),
    };
};
