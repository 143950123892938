import axios from 'axios';
import type {
    ISearchView,
    IUpsertTeamCommand,
    ITeamView,
    IAutocompleteView,
    ISearch,
    IApiResponse,
} from 'types';
import { api } from '../constants';

const controllerApi = api.teams;

export const search = async (queryParams: ISearch): Promise<ISearchView<ITeamView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const autocomplete = async (searchText): Promise<IAutocompleteView[]> => {
    return axios.get(`${controllerApi}/Autocomplete?searchText=${searchText}`);
};

export const getBySysId = async (id: string): Promise<ITeamView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};

export const create = async (data: IUpsertTeamCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (
    teamSysId: string,
    data: IUpsertTeamCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${teamSysId}`, data);
};

export const deleteTeams = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
