import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';
import { QuestStatus } from 'types';
import { useLayout } from 'hooks';

const { colors } = frankyTalesTheme;

const QUEST_STATUS_COLORS = {
    [QuestStatus.DRAFT]: colors.grey,
    [QuestStatus.ACTIVE]: colors.blueDarker,
    [QuestStatus.EVALUATED]: colors.teal,
    [QuestStatus.COMPLETED]: colors.teal,
};

export const useStyles = (quest) => {
    const { isXs } = useLayout();

    const theme = useTheme();

    return {
        content: {
            display: 'flex',
            paddingBottom: `${theme.spacing(2)} !important`,
            justifyContent: 'space-between',
        },
        actions: css({
            display: 'flex',
            alignItems: 'center',
            minWidth: theme.spacing(isXs ? 7 : 15),
            flexDirection: isXs ? 'column-reverse' : undefined,
            justifyContent: isXs ? 'flex-end' : undefined,
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(isXs ? -1.5 : 0),
        }),
        questName: css({
            marginTop: theme.spacing(1),
            display: 'flex',
        }),
        dates: {
            marginLeft: theme.spacing(1),
        },
        typeIconWrapper: css({
            marginRight: theme.spacing(2),
        }),
        visibilityIcon: {
            marginRight: theme.spacing(1),
            color: colors.blue,
        },
        progressArcWrapper: css({
            marginRight: theme.spacing(isXs ? 0 : 2),
        }),
        flex: css({
            display: 'flex',
        }),
    };
};
