import { frankyTalesTheme } from 'theme';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const DEFAULT_HEIGHT = 24;

export const useStyles = (height = DEFAULT_HEIGHT) => {
    const scale = height / DEFAULT_HEIGHT;

    return {
        coins: css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: colors.darkBlue,

            '& svg': {
                transformOrigin: 'left',
                transform: `scale(${scale})`,
            },
        }),
    };
};
