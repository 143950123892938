import { initialState as initialAppState } from './slices/appState';
import { initialState as initialUserState } from './slices/user';
import { initialState as initialQuestState } from './slices/quest';

export const saveState = (appStore) => {
    const { user, quest } = appStore.getState();
    const userToSave = { ...user };

    delete userToSave.current;
    delete userToSave.loggedIn;

    const stateToSave = {
        user: userToSave,
        quest: { questLogFilterPreFillData: quest.questLogFilterPreFillData },
    };

    try {
        localStorage.setItem('storeState', JSON.stringify(stateToSave));
    } catch {
        // ignore write errors
    }
};

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('storeState');

        if (serializedState === null) {
            return undefined;
        }

        return {
            appState: { ...initialAppState },
            user: { ...initialUserState, ...JSON.parse(serializedState).user },
            quest: { ...initialQuestState, ...JSON.parse(serializedState).quest },
        };
    } catch (err) {
        return undefined;
    }
};
