import { hexToRgba } from './themeService';
import { frankyTalesTheme } from './frankyTalesTheme';

const { buttonBackgroundAlphaHover, buttonColor, buttonColorHover, buttonTextColor } =
    frankyTalesTheme;

const contained = {
    color: buttonTextColor,
    backgroundColor: buttonColor,

    '&:hover': {
        color: buttonTextColor,
        backgroundColor: buttonColorHover,
    },
};

const outlined = {
    color: buttonColor,
    borderColor: buttonColor,

    '&:hover': {
        color: buttonColorHover,
        borderColor: buttonColorHover,
        backgroundColor: hexToRgba(buttonColor, buttonBackgroundAlphaHover),
    },
};

const root = {
    color: buttonColor,
    textTransform: 'initial' as const,
    fontSize: '1rem',
};

const text = {
    color: buttonColor,

    '&:hover': {
        color: buttonColorHover,
        backgroundColor: hexToRgba(buttonColor, buttonBackgroundAlphaHover),
    },
};

export const MuiButton = {
    styleOverrides: {
        contained,
        containedPrimary: contained,
        containedSecondary: contained,
        outlined,
        outlinedPrimary: outlined,
        outlinedSecondary: outlined,
        root,
        text,
        textPrimary: text,
        textSecondary: text,
    },
};
