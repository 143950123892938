import { useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import { PlayerSearchResultType } from 'types';
import type { PlayerSearchResultMode, PlayerSearchResult } from 'types';
import { api } from 'services';
import { usePagination } from 'hooks';
import { Loading } from 'components';
import { Button } from '@mui/material';
import { useStyles } from './styles';
import { PlayerSearchResults } from '../PlayerSearchResults';

interface Props {
    mode: PlayerSearchResultMode;
    searchFilter?: string;
}

export const PlayerSearchJobs: FC<Props> = ({ mode, searchFilter }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        isLoading,
        callService,
        cancelServiceCall,
        items,
        currentPage,
        nextPageNumber,
        resetPaging,
        STARTING_PAGE,
    } = usePagination<PlayerSearchResult>({
        api: api.employeeController.searchJobs,
        query: { query: searchFilter },
        pageSize: 6,
    });

    useEffect(() => {
        if (currentPage !== STARTING_PAGE) {
            resetPaging();
        }
    }, [searchFilter]);

    useEffect(() => {
        if (currentPage === STARTING_PAGE) {
            callService();
        }
    }, [currentPage]);

    useEffect(() => {
        return () => cancelServiceCall();
    }, []);

    if (items && !items.length) {
        return null;
    }

    return (
        <>
            <PlayerSearchResults
                players={items}
                mode={mode}
                type={PlayerSearchResultType.JOB}
                title={t('playerSearch.resultType.jobs.label', {
                    searchFilter: sanitize(searchFilter, { USE_PROFILES: {} }),
                })}
            />
            <div className={classes.loadMoreButton}>
                {!isLoading && currentPage < nextPageNumber && (
                    <Button onClick={callService} variant="text">
                        {t('playerSearch.playerSearchJobs.actions.loadMore.label')}
                    </Button>
                )}
            </div>
            {isLoading && <Loading transitionEnterDuration={0} transitionDelayIn={0} />}
        </>
    );
};
