import { useEffect } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { Quest } from 'types';
import { KeyResultAssignedTo, PROFILE_TYPE, QuestStatus } from 'types';
import { useBoundActions, useLayout, useQuestLogFilter, useService } from 'hooks';
import { Loading } from 'components';
import { api } from 'services';
import {
    Button,
    Card,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import type { AppReducerState } from 'store';
import { setQuestPreFillData } from 'store/slices/quest';
import { QuestListCardContent } from '../../quest';
import { useStyles } from './styles';

const NUM_QUESTS_TO_SHOW = 3;

export const MyQuests: FC = () => {
    const { empSysId, current } = useSelector(({ user }: AppReducerState) => user);
    const { cancelServiceCall, data, error, isLoading } = useService<{ items: Quest[] }>({
        api: api.objectiveController.getObjectives,
        query: {
            assignedTo: [empSysId],
            status: [
                QuestStatus.DRAFT,
                QuestStatus.ACTIVE,
                QuestStatus.EVALUATED,
                QuestStatus.COMPLETED,
            ],
            pageSize: NUM_QUESTS_TO_SHOW,
            page: 0,
        },
    });
    const { isXs, isSm } = useLayout();
    const navigate = useNavigate();
    const { setQuestLogFilterPreFillDataAction } = useQuestLogFilter();
    const classes = useStyles();
    const { t } = useTranslation();
    const { setQuestPreFillDataAction } = useBoundActions({
        setQuestPreFillData,
    });

    useEffect(() => {
        return () => cancelServiceCall();
    }, []);

    const handleViewMoreClick = () => {
        setQuestLogFilterPreFillDataAction({
            assignedToWithType: {
                id: empSysId,
                type: PROFILE_TYPE.PLAYER,
            },
        });
        navigate('/quest/log');
    };

    const handleOnCreateQuestClick = () => {
        setQuestPreFillDataAction({
            assignedTo: [
                {
                    group: t('quest.addPlayerStep.form.assignTo.groups.employees.label'),
                    type: KeyResultAssignedTo.EMPLOYEE,
                    name: `${current.firstName} ${current.lastName}`,
                    id: empSysId,
                    profilePhoto: current.photoPath,
                },
            ],
        });
        navigate('/quest/new');
    };

    const handleOnQuestClick = (objectiveId) => () => navigate(`/quest/${objectiveId}`);

    return (
        <Card>
            <CardContent sx={classes.noPadding}>
                <TableContainer sx={classes.tableContainer}>
                    <Toolbar sx={classes.header}>
                        <Typography variant="h4" component="h2">
                            {t('dashboard.myQuests.title.label')}
                        </Typography>
                        {!isXs && (
                            <Button variant="contained" onClick={handleOnCreateQuestClick}>
                                {t('dashboard.myQuests.actions.createQuest.label')}
                            </Button>
                        )}
                    </Toolbar>
                    <Divider />
                    <Table>
                        <TableBody>
                            {data?.items?.map((quest) => (
                                <TableRow
                                    sx={classes.quest}
                                    onClick={handleOnQuestClick(quest.objectiveId)}
                                >
                                    <TableCell>
                                        <QuestListCardContent quest={quest} />{' '}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {isLoading && (
                                <TableRow>
                                    <TableCell colSpan={1}>
                                        <Loading
                                            transitionEnterDuration={0}
                                            transitionDelayIn={0}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!data?.items?.length && !error && !isLoading && (
                                <TableRow>
                                    <TableCell colSpan={1} align="center">
                                        <Typography>
                                            {t('dashboard.myQuests.noQuests.label')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {error && (
                                <TableRow>
                                    <TableCell colSpan={1} align="center">
                                        <Typography>
                                            {t('dashboard.myQuests.error.label')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            {!!data?.items?.length && <div className={classes.veil} />}
            {!isXs && !isSm && <Divider />}
            <TableFooter sx={classes.tableActions}>
                <TableRow>
                    <TableCell colSpan={1}>
                        <Button onClick={handleViewMoreClick}>
                            {t('dashboard.myQuests.actions.viewMore.label')}
                        </Button>
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Card>
    );
};
