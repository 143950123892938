import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import type { SxProps } from '@mui/system';
import { Avatar } from '../Avatar';

interface Props {
    alt?: string;
    sx?: SxProps;
    employeeId?: string;
    src?: string;
    profileUrl?: string;
    preventClick?: boolean;
}

export const EmployeeAvatar: FC<Props> = ({
    alt,
    sx,
    employeeId,
    preventClick = true,
    profileUrl,
    src = 'no_user_profile_image.png',
}) => {
    const navigate = useNavigate();

    const handleAvatarClick = (evt) => {
        if (preventClick) {
            evt.stopPropagation();
        }

        if (profileUrl) {
            navigate(`${profileUrl}/${employeeId}`);
            return;
        }

        if (employeeId) {
            navigate(`/profile/${employeeId}`);
        }
    };

    return <Avatar alt={alt} sx={sx} onClick={handleAvatarClick} src={src} />;
};
