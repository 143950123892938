import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { useLayout } from 'hooks';

export const useStyles = (): any => {
    const { isXs } = useLayout();

    const theme = useTheme();

    return {
        content: {
            flexDirection: isXs ? 'column' : 'row',
            display: 'flex',
            justifyContent: isXs ? undefined : 'space-between',
            paddingBottom: `${theme.spacing(2)} !important`,
        },
        actions: css({
            marginTop: isXs ? theme.spacing(1) : 0,
            display: 'flex',
            alignItems: 'center',
            minWidth: theme.spacing(14),
        }),
    };
};
