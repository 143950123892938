import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        action: css({
            marginRight: theme.spacing(2),
            display: 'flex',
            alignItems: 'end',
        }),
        icon: {
            '& svg': {
                height: theme.spacing(2.5),
                width: theme.spacing(2.5),
            },
        },
        activityStream: {
            marginRight: theme.spacing(0.5),
        },
        sharedOn: {
            marginLeft: theme.spacing(0.5),
        },
        modalContent: {
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
            paddingBottom: theme.spacing(8),
            textAlign: 'center',
        },
        good: {
            color: colors.lightGreen,
            transform: 'translate(0px, 4px)',
            height: '0.75em',
            width: '0.75em',
        },
        caption: {
            lineHeight: '0.75em',
        },
        teal: {
            color: colors.lightGreen,

            '&.Mui-disabled': {
                color: colors.lightGreen,
            },
        },
        grey: {
            color: colors.grey,
        },
    };
};
