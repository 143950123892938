import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FEEDBACK_STEP_TYPE, FEEDBACK_TARGET_TYPE, TooltipColor } from 'types';
import type { EmployeeCompetency, ProfileData } from 'types';
import { useBoundActions } from 'hooks';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { api, callService } from 'services';
import { Image } from 'components';
import { setFeedbackPreFillData } from 'store/slices/feedback';
import { useStyles } from './styles';
import { Loading } from '../../Loading';
import { InformationTooltip } from '../../InformationTooltip';

interface Props {
    employeeSysId: string;
    profileData: ProfileData;
}

export const ValuablePlayerTopics: FC<Props> = ({ employeeSysId, profileData }) => {
    const [competencies, setCompetencies] = useState<EmployeeCompetency[]>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const classes = useStyles();

    const { setFeedbackPreFillDataAction } = useBoundActions({
        setFeedbackPreFillData,
    });

    const fetchCompetencies = async () => {
        const { error, payload } = await callService({
            api: api.getEmployeeCompetency,
            params: [employeeSysId],
        });

        if (!error && payload) {
            setCompetencies(payload);
        }
    };

    const handleGiveCompetencyFeedback = (competencyId) => () => {
        setFeedbackPreFillDataAction({
            step: FEEDBACK_STEP_TYPE.AVATAR,
            profileData: {
                id: employeeSysId,
                displayName: `${profileData.firstName} ${profileData.lastName}`,
                type: FEEDBACK_TARGET_TYPE.EMPLOYEE,
                initials: profileData.initials,
                profilePhoto: profileData.profilePhoto,
            },
            competencyId,
        });
        navigate('/feedback/new');
    };

    useEffect(() => {
        fetchCompetencies();
    }, []);

    if (!competencies) {
        return <Loading />;
    }

    if (!competencies.length) {
        return null;
    }

    return (
        <>
            <div className={classes.titleWrapper}>
                <Typography variant="h2" component="h4" sx={classes.title}>
                    {t('profile.valuablePlayerTopics.header.title')}
                </Typography>
                <InformationTooltip
                    color={TooltipColor.BLUE}
                    tooltip={t<string>('profile.valuablePlayerTopics.header.information.tooltip')}
                    text={t<string>('profile.valuablePlayerTopics.header.information.title')}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {competencies.map((competency) => (
                            <TableRow key={competency.employeeCompetencySysId}>
                                <TableCell>
                                    <Typography variant="h4">
                                        {competency.competencyDescriptionShort}
                                    </Typography>
                                    <Typography>{competency.competencyDescriptionLong}</Typography>
                                    <Button
                                        onClick={handleGiveCompetencyFeedback(
                                            competency.competencySysId,
                                        )}
                                        variant="outlined"
                                        size="small"
                                        sx={classes.giveFeedbackButton}
                                    >
                                        {t(
                                            'profile.valuablePlayerTopics.competency.actions.giveFeedback.title',
                                        )}
                                    </Button>
                                </TableCell>
                                <TableCell sx={classes.actionsWrapper}>
                                    <Image
                                        src={competency.competencyPhotoPath}
                                        alt={competency.competencyDescriptionShort}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
