import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        addNewContainer: css({
            marginTop: theme.spacing(1),
            display: 'flex',
            justifyContent: 'right',
            color: '#31C110',
            cursor: 'pointer',
            fontSize: '0.9rem',
            '&:hover': {
                textDecoration: 'underline',
            },
        }),
        mandatory: {
            padding: theme.spacing(1),
            color: '#515151',
            backgroundColor: '#F1F1F1',
        },
        pagination: {
            float: 'right',
        },
        chipWrapper: css({
            display: 'flex',
        }),
        chipTooltip: css({
            marginLeft: theme.spacing(1),
        }),
        actionButton: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
    };
};
