import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { EmployeeAvatar } from 'components';
import type { EvaluationUser } from 'types';

import { useStyles } from './styles';

interface Props {
    className?: string;
    employee?: EvaluationUser;
}

export const EmployeeEvaluationHeaderSection: FC<Props> = ({ className, employee }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    if (!employee) {
        return null;
    }

    return (
        <div className={cx(classes.wrapper, className)}>
            <EmployeeAvatar
                alt={employee.displayName}
                employeeId={employee.employeeId}
                src={employee.profilePhoto}
            />
            <div className={classes.dataWrapper}>
                <Typography component="span">
                    <strong>
                        {t('evaluation.employeeEvaluationHeaderSection.employee.label')}
                    </strong>
                </Typography>
                <Typography component="span">
                    <strong>{employee.displayName}</strong>
                </Typography>
                <Typography component="span">{employee.jobTitle}</Typography>
            </div>
        </div>
    );
};
