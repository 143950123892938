import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (id) => {
    const employee = id
        ? {
              color: colors.blue,
              cursor: 'pointer',

              '&:hover': {
                  color: colors.blueDarker,
              },
          }
        : {};

    return {
        employee,
    };
};
