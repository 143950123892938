import _ from 'lodash';
import { useRef, useEffect } from 'react';
import type { FC, ReactNode } from 'react';

const THROTTLE_WAIT = 100;

interface Props {
    active: boolean;
    onEndOfScreen: (...args: any[]) => void;
    children: ReactNode;
}

export const InfiniteScroll: FC<Props> = ({ active = true, children, onEndOfScreen }) => {
    const sentinelRef = useRef<HTMLTableRowElement>(null);
    const activeRef = useRef(active);
    const onEndOfScreenRef = useRef(onEndOfScreen);

    const handleScrollOrResize = _.throttle(
        () => {
            const { current: sentinel } = sentinelRef;
            const { current: isActive } = activeRef;
            const { current: handleEndOfScreen } = onEndOfScreenRef;

            if (!isActive || !sentinel) {
                return;
            }

            const { top: sentinelPosition } = sentinel.getBoundingClientRect();

            if (sentinelPosition - window.innerHeight <= 0) {
                handleEndOfScreen();
            }
        },
        THROTTLE_WAIT,
        {
            leading: true,
            trailing: true,
        },
    );

    useEffect(() => {
        window.addEventListener('scroll', handleScrollOrResize);
        window.addEventListener('resize', handleScrollOrResize);
        window.addEventListener('touchmove', handleScrollOrResize);

        return () => {
            window.removeEventListener('scroll', handleScrollOrResize);
            window.removeEventListener('resize', handleScrollOrResize);
            window.removeEventListener('touchmove', handleScrollOrResize);
        };
    }, []);

    useEffect(() => {
        activeRef.current = active;
        onEndOfScreenRef.current = onEndOfScreen;
    }, [active, onEndOfScreen]);

    if (!children) {
        return null;
    }

    return (
        <>
            {children}
            <span ref={sentinelRef} aria-hidden />
        </>
    );
};
