import axios from 'axios';
import type { IApiResponse, ISearch, ISearchView, IJobView, IUpsertJobCommand } from 'types';
import { api } from '../constants';

const controllerApi = `${api.jobs}`;

export const getJobs = async (queryParams: ISearch): Promise<ISearchView<IJobView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const autocomplete = async (searchText: string) => {
    return axios.get(`${controllerApi}/Autocomplete?searchText=${searchText}`);
};

export const getBySysId = async (jobSysId: string): Promise<IJobView> => {
    return axios.get(`${controllerApi}/${jobSysId}`).then((res) => res.data);
};

export const create = async (data: IUpsertJobCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async ({
    id,
    jobId,
    title,
    initials,
    statusSysId,
}): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}`, {
        id,
        jobId,
        title,
        initials,
        statusSysId,
    });
};

export const bulkImportValidation = async ({ bulkImportData }) => {
    return axios.put(`${controllerApi}/bulkImportValidate`, {
        importTheData: false,
        bulkImportJobs: bulkImportData,
    });
};

export const bulkImport = async ({ bulkImportData }) => {
    return axios.put(`${controllerApi}/Job/bulkImportValidate`, {
        importTheData: true,
        bulkImportJobs: bulkImportData,
    });
};

export const deleteJobs = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
