import { useTheme } from '@mui/material';
import { frankyTalesTheme, hexToRgba } from 'theme';

const { colors } = frankyTalesTheme;

const SNACKBAR_COLORS = {
    success: colors.teal,
    error: colors.orange,
    info: colors.white,
};

export const useStyles = (variant) => {
    const theme = useTheme();

    return {
        snackBar: {
            '& .MuiAlert-icon': {
                color: variant === 'info' ? colors.blueDarker : SNACKBAR_COLORS[variant],
            },

            '& .MuiAlert-root': {
                backgroundColor:
                    variant === 'info'
                        ? SNACKBAR_COLORS[variant]
                        : hexToRgba(SNACKBAR_COLORS[variant], 0.15),
            },

            '& .MuiTypography-root': {
                paddingRight: theme.spacing(2),
            },

            '& .MuiButton-text': {
                color: colors.blueDarker,
            },

            '&.MuiSnackbar-anchorOriginTopRight': {
                top: theme.spacing(12),
            },
        },
        button: {
            marginTop: theme.spacing(1),
            float: 'right',
        },
    };
};
