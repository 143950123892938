import { useTheme } from '@mui/material';
import { hexToRgba } from 'theme';
import { css } from '@emotion/css';

export const useStyles = (legendItem) => {
    const theme = useTheme();

    return {
        icon: {
            fill: hexToRgba(legendItem.color, legendItem.selected ? 0.5 : 1),
        },
        chartLegendItem: css({
            display: 'inline-flex',
            marginRight: theme.spacing(2),
            whiteSpace: 'nowrap',

            '&:last-child': {
                marginRight: theme.spacing(0),
            },
        }),
    };
};
