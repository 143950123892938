import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

interface Props {
    open: boolean;
    onClose: () => void;
    revokeGdprConsent: () => void;
}

export const GdprRevokeConfirmationDialog: FC<Props> = ({ open, onClose, revokeGdprConsent }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="bigPadding"
        >
            <DialogTitle id="alert-dialog-title">{t('profile.revokeGdpr.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('profile.revokeGdpr.description')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" className="pull-left mr-4">
                    {t('profile.revokeGdpr.actions.goBack')}
                </Button>
                <Button onClick={revokeGdprConsent} variant="contained">
                    {t('profile.revokeGdpr.actions.continue')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
