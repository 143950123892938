import { frankyTalesTheme } from './frankyTalesTheme';

const { colors } = frankyTalesTheme;

const icon = {
    color: colors.blue,
};

export const MuiSelect = {
    styleOverrides: {
        icon,
    },
};
