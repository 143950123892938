import type { VFC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DonutChart, Loading } from 'components';
import { api, callService } from 'services';
import type { AppReducerState } from 'store';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useStyles } from './styles';

interface AnalyticsData {
    graphTitle: string;
    data: {
        totalFeedbackJobFamily: number;
        totalFeedbackEmployee?: number;
        percentFeedbackJobFamily?: number;
        percentFeedbackEmployee?: number;
        period: string;
    }[];
}

interface Props {
    jobFamilyId?: string;
    userId: string;
}

export const FeedbackChartEmployeeVsJobFamily: VFC<Props> = ({ jobFamilyId, userId }) => {
    const [data, setData] = useState<AnalyticsData>();
    const { t } = useTranslation();
    const { year } = useSelector(({ configuration }: AppReducerState) => configuration);
    const classes = useStyles();

    const getAnalyticsData = async () => {
        const { error, payload } = await callService({
            api: api.analyticsController.getPlayerAnalytics,
            params: [userId],
            query: {
                jobFamilyId,
                graphId: 'E2',
                periodicity: 1,
                dateFrom: moment(`01/01/${year}`).format('YYYY-MM-DD'),
                dateTo: moment(`12/31/${year}`).format('YYYY-MM-DD'),
            },
        });

        if (!error && payload) {
            setData(payload);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, []);

    if (!jobFamilyId) {
        return null;
    }

    if (!data) {
        return <Loading />;
    }

    const { percentFeedbackEmployee = 0, percentFeedbackJobFamily = 0 } = data.data[0];

    return (
        <Card>
            <CardHeader
                title={
                    <Typography>
                        <strong className={classes.graphTitle}>{data.graphTitle}</strong>
                    </Typography>
                }
            />
            <CardContent sx={classes.cardContent}>
                <DonutChart
                    data={[
                        {
                            label: t(
                                'jobProfile.jobProfileAnalytics.feedbackChartEmployeeVsJobFamily.totalFeedbackEmployee.label',
                            ),
                            value: percentFeedbackEmployee,
                            valueLabel: `${percentFeedbackEmployee} %`,
                        },
                        {
                            label: t(
                                'jobProfile.jobProfileAnalytics.feedbackChartEmployeeVsJobFamily.totalFeedbackJobFamily.label',
                            ),
                            value: percentFeedbackJobFamily,
                            valueLabel: `${percentFeedbackJobFamily} %`,
                        },
                    ]}
                />
            </CardContent>
        </Card>
    );
};
