import axios from 'axios';
import type {
    IApiResponse,
    ISearch,
    ISearchView,
    IJobFamilyView,
    IUpsertJobFamilyCommand,
} from 'types';
import { api } from '../constants';

const controllerApi = `${api.jobFamilies}`;

export const getJobFamilies = async (
    queryParams: ISearch,
): Promise<ISearchView<IJobFamilyView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const autocomplete = async (searchText: string) => {
    return axios.get(`${controllerApi}/Autocomplete?searchText=${searchText}`);
};

export const getBySysId = async (jobFamilySysId: string): Promise<IJobFamilyView> => {
    return axios.get(`${controllerApi}/${jobFamilySysId}`).then((res) => res.data);
};

export const create = async (data: IUpsertJobFamilyCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (id: string, data: IUpsertJobFamilyCommand): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const deleteJobFamilies = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
