import { useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeAvatar, Question } from 'components';
import { SurveyType } from 'types';
import type { EvaluationUser, Survey } from 'types';
import { Button, Card, CardContent, CardHeader, Collapse, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Formik } from 'formik';
import { useStyles } from './styles';

interface Props {
    employee: EvaluationUser;
    survey: Survey;
}

export const PerformanceEvaluationSurvey: FC<Props> = ({ employee, survey }) => {
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const handleExpandClick = () => setExpanded(!expanded);

    const renderQuestions = (surveyStep) => {
        return surveyStep?.questionGroups
            .sort((first, second) => first.order - second.order)
            .map((questionGroup) => (
                <div key={`${surveyStep.order}${questionGroup.order}`}>
                    <Typography variant="h3" sx={classes.questionGroupTitle}>
                        {questionGroup.title}
                    </Typography>
                    {questionGroup.questions
                        .sort((first, second) => first.order - second.order)
                        .map((question) => (
                            <Question
                                question={question}
                                key={question.id}
                                className={classes.question}
                                showAsAnswer
                            />
                        ))}
                </div>
            ));
    };

    const initialValues = survey.steps.reduce(
        (acc, surveyStep) => ({
            ...acc,
            ...surveyStep.questionGroups.reduce(
                (groupAcc, group) => ({
                    ...groupAcc,
                    ...group.questions.reduce(
                        (questionsAcc, question) => ({
                            ...questionsAcc,
                            [question.id]: question.questionAnswer || '',
                        }),
                        {},
                    ),
                }),
                {},
            ),
        }),
        {},
    );

    const expandButtonLabel = t(
        `evaluation.performanceEvaluationSurvey.actions.${expanded ? 'collapse' : 'expand'}.label`,
    );
    const avatar = employee ? (
        <EmployeeAvatar
            alt={employee.displayName}
            employeeId={employee.employeeId}
            src={employee.profilePhoto}
        />
    ) : null;

    return (
        <>
            <Typography component="h4" variant="h2" sx={classes.title}>
                {survey.type === SurveyType.EMPLOYEE &&
                    t('evaluation.performanceEvaluationSurvey.title.employee.label')}
                {survey.type === SurveyType.LEAD &&
                    t('evaluation.performanceEvaluationSurvey.title.lead.label')}
            </Typography>
            <Card>
                <CardHeader
                    avatar={avatar}
                    action={
                        <Button
                            variant="text"
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label={expandButtonLabel}
                            endIcon={
                                <ExpandMore
                                    sx={{
                                        ...classes.expand,
                                        ...(expanded ? classes.expandOpen : {}),
                                    }}
                                />
                            }
                        >
                            {expandButtonLabel}
                        </Button>
                    }
                    title={employee.displayName}
                    subheader={employee.jobTitle}
                    titleTypographyProps={{ variant: 'h4', component: 'h4' }}
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Formik initialValues={initialValues} onSubmit={() => {}}>
                            <>
                                {survey.steps
                                    .sort((first, second) => first.order - second.order)
                                    .map((surveyStep) => renderQuestions(surveyStep))}
                            </>
                        </Formik>
                    </CardContent>
                </Collapse>
            </Card>
        </>
    );
};
