import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        datesWrapper: css({
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        }),
        dates: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        dateIcon: {
            color: colors.blueDarker,
        },
    };
};
