import jwtDecode from 'jwt-decode';
import type { Api, ServiceCallResponse, ApiError, Token } from 'types';
import { LANGUAGE } from 'types';
import { store } from 'store';
import { logOutUser, setLoggedIn, setRoles } from 'store/slices/user';
import axios from 'axios';
import { api as apiMap, calls } from './api';

let token = '';
let refreshPromise;
let refreshPromiseRunning;

export const DEFAULT_CONTENT_TYPE = 'application/json';

// axios.defaults.withCredentials = true;

export const generateUrl = (params, url = '') => {
    if (params === null || params === undefined) {
        return url;
    }

    const paramsCount = url.split('{param').length - 1;

    let urlExit = url;
    for (let i = 0; i < paramsCount; ) {
        urlExit = urlExit.replace(`{param${i}}`, params[i]);
        i += 1;
    }

    return urlExit;
};

export const generateUrlWithKeys = (query, url = '') => {
    if (query === null || query === undefined) {
        return url;
    }

    const keys = Object.keys(query)
        .filter((key) => query[key] !== undefined)
        .map((key) => `${key}=${query[key]}`)
        .join('&');

    return `${url}${keys.length ? `?${keys}` : ''}`;
};

export const getApiUrlWithKeys = (call, params) => {
    const webApi = calls.find((elem) => elem.call === call);

    const apiUrl = { ...webApi, url: generateUrlWithKeys(params, webApi?.url) };

    return apiUrl;
};

export const getApiUrl = (call, params) => {
    const webApi = calls.find((elem) => elem.call === call);

    const apiUrl = { ...webApi, url: generateUrl(params, webApi?.url) };

    return apiUrl;
};

export const getApi = (api, params, query) => {
    const call = calls.find((item) => item.call === api);
    let url = call?.url || '';

    if (params) {
        url = generateUrl(params, url);
    }

    if (query) {
        url = generateUrlWithKeys(query, url);
    }

    return {
        ...call,
        url,
    };
};

export const isArray = (arg: unknown) => Object.prototype.toString.call(arg) === '[object Array]';

export const callService = async ({
    api,
    body,
    params = [],
    query,
    headers,
    useToken = true,
    signal,
}: {
    api: string;
    body?: Record<string, any> | string;
    headers?: Record<string, any>;
    params?: any[] | Record<string, any>;
    query?: any[] | Record<string, any>;
    useToken?: boolean;
    signal?: AbortSignal;
}): Promise<ServiceCallResponse<any>> => {
    const getApiFunction = isArray(params) ? getApiUrl : getApiUrlWithKeys;
    const { method, url } = (
        query ? getApi(api, params, query) : getApiFunction(api, params)
    ) as Api;

    if (!method || !url) {
        const err = new Error('Invalid call');

        return { error: err, payload: {} };
    }

    const headerObject: Record<string, string> = {
        'content-type': DEFAULT_CONTENT_TYPE,
        ...headers,
    };

    if (useToken) {
        const decoded = token ? jwtDecode<Token>(token) : { exp: 0 };
        const dateNow = Date.now().valueOf() / 1000;

        if (!token || decoded.exp < dateNow) {
            if (!refreshPromiseRunning) {
                refreshPromiseRunning = true;
                refreshPromise = new Promise((resolve, reject) => {
                    const { method: refreshMethod, url: refreshUrl } = getApiUrl(
                        apiMap.authentication.refreshToken,
                        [],
                    ) as Api;

                    try {
                        fetch(refreshUrl, {
                            method: refreshMethod,
                            credentials: 'include',
                        }).then((refreshResponse) => {
                            if (!refreshResponse.ok) {
                                refreshPromiseRunning = false;
                                reject(new Error('Authentication error'));
                                return;
                            }

                            try {
                                refreshResponse.json().then((payload) => {
                                    token = payload.token;
                                    delete payload.token;

                                    axios.interceptors.request.clear();
                                    axios.interceptors.request.use((config: any) => {
                                        const { headers: axiosHeaders } = config;

                                        axiosHeaders.Authorization = `Bearer ${token}`;

                                        const { lng = LANGUAGE.EN } = store.getState().user;

                                        axiosHeaders.language = lng;

                                        // eslint-disable-next-line no-underscore-dangle,prefer-destructuring
                                        axiosHeaders.__tenant__ =
                                            window.location.hostname.split('.')[0];

                                        return config;
                                    });

                                    const { companySysId, roles } = jwtDecode<Token>(token);
                                    store.dispatch(setRoles(roles));
                                    // store.dispatch(setCompanySysId(companySysId));
                                    store.dispatch(setLoggedIn(true));
                                    refreshPromiseRunning = false;
                                    resolve(true);
                                });
                            } catch (error) {
                                refreshPromiseRunning = false;
                                reject(new Error('Authentication error'));
                            }
                        });
                    } catch (err) {
                        store.dispatch(logOutUser());
                        token = '';
                        axios.interceptors.request.clear();
                    }
                }).catch(() => {
                    store.dispatch(logOutUser());
                    token = '';
                    axios.interceptors.request.clear();
                });
            }

            await refreshPromise;
        }

        headerObject.Authorization = `Bearer ${token}`;
    }

    const credentials = [
        apiMap.authentication.logIn,
        apiMap.authentication.getToken,
        apiMap.authentication.generateCode,
        apiMap.authentication.refreshToken,
        apiMap.authentication.deleteToken,
        apiMap.authentication.changePassword,
    ].includes(api)
        ? 'include'
        : undefined;

    const response = await fetch(url, {
        method,
        headers: new Headers(headerObject),
        body: JSON.stringify(body),
        signal,
        credentials,
    });

    if (!response.ok) {
        try {
            const { error, message }: { error?: string; message?: string } = await response.json();
            const err: ApiError = new Error(error || message);
            err.status = response.status;

            return { error: err, payload: {} };
        } catch (error: any) {
            error.status = response.status;

            return { error, payload: {} };
        }
    }

    try {
        if (response.headers.get('Content-Type')?.match(/^text\/plain/)) {
            return { payload: await response.text() };
        }

        if (response.headers.get('Content-Type')?.match(/spreadsheetml/)) {
            return { payload: await response };
        }

        if (response.url.includes('/api/storages/')) {
            return { payload: await response.blob() };
        }

        const responseValue = await response.text();
        const payload = responseValue ? await JSON.parse(responseValue) : {};

        if (api === apiMap.authentication.getToken) {
            token = payload.token;
            delete payload.token;

            const { companySysId, roles } = jwtDecode<Token>(token);
            store.dispatch(setRoles(roles));
            // store.dispatch(setCompanySysId(companySysId));

            axios.interceptors.request.clear();
            axios.interceptors.request.use((config: any) => {
                const { headers: axiosHeaders } = config;

                axiosHeaders.Authorization = `Bearer ${token}`;

                const { lng = LANGUAGE.EN } = store.getState().user;

                axiosHeaders.language = lng;

                // eslint-disable-next-line no-underscore-dangle,prefer-destructuring
                axiosHeaders.__tenant__ = window.location.hostname.split('.')[0];

                return config;
            });
        }

        if (api === apiMap.authentication.deleteToken) {
            token = '';
            axios.interceptors.request.clear();
        }

        return { payload };
    } catch (error: any) {
        error.status = response.status;

        if (api === apiMap.authentication.getToken) {
            store.dispatch(logOutUser());
            token = '';
            axios.interceptors.request.clear();

            return { error: new Error('Authentication error'), payload: {} };
        }

        return { error, payload: {} };
    }
};
