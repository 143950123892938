import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsFilter } from 'hooks';
import { api, callService } from 'services';
import { QuestStatus } from 'types';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useStyles } from './styles';
import { DonutChart } from '../../chart';
import { Loading } from '../../../Loading';

const STATUS_TRANSLATION_KEYS = {
    [QuestStatus.DRAFT]: 'quest.questStatus.draft.label',
    [QuestStatus.ACTIVE]: 'quest.questStatus.active.label',
    [QuestStatus.EVALUATED]: 'quest.questStatus.evaluated.label',
    [QuestStatus.COMPLETED]: 'quest.questStatus.completed.label',
    [QuestStatus.ABANDONED]: 'quest.questStatus.abandoned.label',
    [QuestStatus.ARCHIVED]: 'quest.questStatus.archived.label',
};

interface AnalyticsData {
    graphTitle: string;
    data: {
        status: QuestStatus;
        totalQuestsStatus: number;
    }[];
}

export const QuestsInStatus: FC = () => {
    const [data, setData] = useState<AnalyticsData>();
    const { filter } = useAnalyticsFilter();
    const { t } = useTranslation();
    const classes = useStyles();

    const { dateFrom, dateTo, jobs, hierarchies, questTypes } = filter;

    const getAnalyticsData = async () => {
        const { error, payload } = await callService({
            api: api.analyticsController.getAnalyticsQuests,
            query: {
                graphId: 'Q3',
                jobs: jobs.join(','),
                teams: hierarchies.join(','),
                questTypes: questTypes.join(','),
                dateFrom: dateFrom.format('YYYY-MM-DD'),
                dateTo: dateTo.format('YYYY-MM-DD'),
            },
        });

        if (!error && payload) {
            setData(payload);
        }
    };

    useEffect(() => {
        getAnalyticsData();
    }, [dateFrom, dateTo, jobs, hierarchies, questTypes]);

    if (!data) {
        return <Loading />;
    }

    return (
        <Card sx={classes.card}>
            <CardHeader
                title={
                    <Typography>
                        <strong className={classes.graphTitle}>{data.graphTitle}</strong>
                    </Typography>
                }
            />
            <CardContent sx={classes.cardContent}>
                <DonutChart
                    donut={false}
                    data={data.data.map((dataRow) => ({
                        label: t(STATUS_TRANSLATION_KEYS[dataRow.status]),
                        value: dataRow.totalQuestsStatus,
                        valueLabel: `${dataRow.totalQuestsStatus}`,
                    }))}
                />
            </CardContent>
        </Card>
    );
};
