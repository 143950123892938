import { frankyTalesTheme } from './frankyTalesTheme';
import { hexToRgba } from './themeService';

const { colors } = frankyTalesTheme;

const root = {
    boxShadow: `2px 2px 10px 0px ${hexToRgba(colors.grey, 0.25)}`,
    background: colors.white,
    borderRadius: '8px',

    '&:hover': {
        boxShadow: `2px 2px 10px 0px ${hexToRgba(colors.grey, 0.4)}`,
    },

    '& fieldset': {
        borderColor: hexToRgba(colors.grey, 0.05),
    },

    '&:hover fieldset': {
        borderColor: `${hexToRgba(colors.blue, 0.5)} !important`,
    },

    '&.Mui-focused fieldset': {
        borderColor: `${colors.blue} !important`,
    },

    '&.search': {
        borderRadius: '8px',
    },
};

export const MuiInputBase = {
    styleOverrides: {
        root,
    },
};
