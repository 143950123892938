import { css } from '@emotion/css';

export const useStyles = (): any => ({
    loader: css({
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
});
