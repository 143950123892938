import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.perfCycleEmplEvals;

const PerfCycleEmpEvalLoader = Loadable({
    loader: () => import('views/admin/PerfCycleEmpEval'),
    loading: Loading,
    render(loaded, props) {
        const { PerfCycleEmpEval } = loaded;
        return <PerfCycleEmpEval {...props} />;
    },
    delay: general.delay,
});

const PerfCycleEmpEvalEditLoader = Loadable({
    loader: () => import('views/admin/PerfCycleEmpEval/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const perfCycleEmplEvalRoute = {
    path: `/${mainRoute}`,
    component: PerfCycleEmpEvalLoader,
};

export const perfCycleEmplEvalCreateRoute = {
    path: `/${mainRoute}/create`,
    component: PerfCycleEmpEvalEditLoader,
};

export const perfCycleEmplEvalEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: PerfCycleEmpEvalEditLoader,
};
