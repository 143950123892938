import { useTheme } from '@mui/material';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        breadcrumbs: {
            '& li': {
                padding: `${theme.spacing(1.5)} ${theme.spacing(0)}`,
            },
        },
    };
};
