import type { Route } from 'types';

import { homeRoute } from './loadables/home';
import { notFoundRoute } from './loadables/notFound';
import {
    systemInstanceRoute,
    systemInstanceCreateRoute,
    systemInstanceEditRoute,
} from './loadables/systemInstance';
import {
    companyRoute,
    companyCreateRoute,
    companyEditRoute,
    companySettingsRoute,
} from './loadables/company';
import {
    feedbackAvatarRoute,
    feedbackAvatarEditRoute,
    feedbackAvatarCreateRoute,
    feedbackAvatarBulkImportRoute,
} from './loadables/feedbackAvatar';
import { userRoute, userEditRoute, userCreateRoute, userBulkImportRoute } from './loadables/user';
import { managerRoute, managerCreateRoute, managerEditRoute } from './loadables/employeeManager';
import { userRoleRoute, userRoleCreateRoute } from './loadables/userRole';
import {
    experienceLevelRoute,
    experienceLevelCreateRoute,
    experienceLevelEditRoute,
} from './loadables/experienceLevel';
import {
    competencyRoute,
    competencyCreateRoute,
    competencyEditRoute,
    competencyBulkImportRoute,
} from './loadables/competency';
import {
    avatarAdviceRoute,
    avatarAdviceCreateRoute,
    avatarAdviceEditRoute,
    avatarAdviceBulkImportRoute,
} from './loadables/avatarAdvice';
import { jobRoute, jobCreateRoute, jobEditRoute, jobBulkImportRoute } from './loadables/job';
import { rewardSystemRoute } from './loadables/rewardSystem';
import { storeItemRoute, storeItemCreateRoute, storeItemEditRoute } from './loadables/storeItem';
import { teamRoute, teamCreateRoute, teamEditRoute } from './loadables/team';
import {
    jobTeamRoleRoute,
    jobTeamRoleCreateRoute,
    jobTeamRoleEditRoute,
} from './loadables/jobTeamRole';
import {
    employeeTeamRoute,
    employeeTeamCreateRoute,
    employeeTeamEditRoute,
} from './loadables/employeeTeam';
import {
    employeeJobRoute,
    employeeJobCreateRoute,
    employeeJobEditRoute,
} from './loadables/employeeJob';
import {
    storeOrderRoute,
    storeOrderCreateRoute,
    storeOrderEditRoute,
} from './loadables/storeOrder';
import { perfCycleRoute, perfCycleCreateRoute, perfCycleEditRoute } from './loadables/perfCycle';
import {
    perfCycleEmplEvalRoute,
    perfCycleEmplEvalCreateRoute,
    perfCycleEmplEvalEditRoute,
} from './loadables/perfCycleEmplEval';
import { priorityRoute, priorityCreateRoute, priorityEditRoute } from './loadables/priority';
import { surveyRoute, surveyCreateRoute, surveyEditRoute } from './loadables/survey';
import {
    companyQuestRoute,
    companyQuestCreateRoute,
    companyQuestEditRoute,
} from './loadables/companyQuest';
import { jobFamilyRoute, jobFamilyCreateRoute, jobFamilyEditRoute } from './loadables/jobFamily';
import { scaleRoute, scaleCreateRoute, scaleEditRoute } from './loadables/scale';
import { importsRoute, importDetailRoute, zipImportRoute } from './loadables/userImport';
import { revokedConsentsRoute } from './loadables/revokeConsent';
import { securitySettingsRoute } from './loadables/securitySettings';
import {
    questSettingsRoute,
    questSettingsCreateRoute,
    questSettingsEditRoute,
} from './loadables/questSettings';
import { monthlyActiveUsersRoute } from './loadables/mothlyActiveUsers';

export const adminRoutes: Route[] = [
    homeRoute,
    notFoundRoute,
    systemInstanceRoute,
    systemInstanceCreateRoute,
    systemInstanceEditRoute,
    companyRoute,
    companyCreateRoute,
    companyEditRoute,
    companySettingsRoute,
    feedbackAvatarRoute,
    feedbackAvatarEditRoute,
    feedbackAvatarCreateRoute,
    feedbackAvatarBulkImportRoute,
    userRoute,
    userEditRoute,
    userCreateRoute,
    userBulkImportRoute,
    managerRoute,
    managerCreateRoute,
    managerEditRoute,
    userRoleRoute,
    userRoleCreateRoute,
    experienceLevelRoute,
    experienceLevelCreateRoute,
    experienceLevelEditRoute,
    competencyRoute,
    competencyCreateRoute,
    competencyEditRoute,
    competencyBulkImportRoute,
    avatarAdviceRoute,
    avatarAdviceCreateRoute,
    avatarAdviceEditRoute,
    avatarAdviceBulkImportRoute,
    jobRoute,
    jobCreateRoute,
    jobEditRoute,
    jobBulkImportRoute,
    rewardSystemRoute,
    storeItemRoute,
    storeItemCreateRoute,
    storeItemEditRoute,
    teamRoute,
    teamCreateRoute,
    teamEditRoute,
    jobTeamRoleRoute,
    jobTeamRoleCreateRoute,
    jobTeamRoleEditRoute,
    employeeTeamRoute,
    employeeTeamCreateRoute,
    employeeTeamEditRoute,
    employeeJobRoute,
    employeeJobCreateRoute,
    employeeJobEditRoute,
    storeOrderRoute,
    storeOrderCreateRoute,
    storeOrderEditRoute,
    perfCycleRoute,
    perfCycleCreateRoute,
    perfCycleEditRoute,
    perfCycleEmplEvalRoute,
    perfCycleEmplEvalCreateRoute,
    perfCycleEmplEvalEditRoute,
    priorityRoute,
    priorityCreateRoute,
    priorityEditRoute,
    surveyRoute,
    surveyCreateRoute,
    surveyEditRoute,
    companyQuestRoute,
    companyQuestCreateRoute,
    companyQuestEditRoute,
    jobFamilyRoute,
    jobFamilyCreateRoute,
    jobFamilyEditRoute,
    scaleRoute,
    scaleCreateRoute,
    scaleEditRoute,
    importsRoute,
    importDetailRoute,
    zipImportRoute,
    revokedConsentsRoute,
    securitySettingsRoute,
    questSettingsRoute,
    questSettingsCreateRoute,
    questSettingsEditRoute,
    monthlyActiveUsersRoute,
];
