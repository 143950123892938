import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, CardHeader, Chip, Collapse, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useLayout } from 'hooks';
import type { JobProfileHeader } from 'types';
import { useStyles } from './styles';

interface Props {
    job: JobProfileHeader;
    skills: string[];
}

export const JobProfileSkillsList = ({ job, skills }: Props) => {
    const [expanded, setExpanded] = useState(false);
    const { isXs, isSm } = useLayout();
    const { t } = useTranslation();
    const classes = useStyles();

    const handleExpandClick = () => setExpanded(!expanded);

    const expandButtonLabel = t(
        `jobProfile.jobProfileSkillsList.actions.${expanded ? 'collapse' : 'expand'}.label`,
    );

    return (
        <>
            <Typography variant="h2" component="h2" gutterBottom>
                {t('jobProfile.jobProfileSkillsList.title.label')}
            </Typography>
            <Card>
                <CardHeader
                    action={
                        <Button
                            variant="text"
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label={expandButtonLabel}
                            endIcon={
                                <ExpandMore
                                    sx={{
                                        ...classes.expand,
                                        ...(expanded ? classes.expandOpen : {}),
                                    }}
                                />
                            }
                        >
                            {!(isXs || isSm) && expandButtonLabel}
                        </Button>
                    }
                    title={
                        t('jobProfile.jobProfileSkillsList.headerTitle.label', {
                            job: job.title,
                        }) as string
                    }
                    titleTypographyProps={{
                        variant: 'body1',
                        component: 'h3',
                        sx: classes.headerTitle,
                    }}
                />
                <CardContent>
                    <Collapse in={expanded} collapsedSize={32}>
                        {skills.map((skill) => (
                            <Chip
                                label={skill}
                                size="small"
                                variant="outlined"
                                sx={classes.relatedSkill}
                                key={skill}
                            />
                        ))}
                    </Collapse>
                </CardContent>
            </Card>
        </>
    );
};
