import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { SideMenuItem as SideMenuItemType } from 'types';
import type { AppReducerState } from 'store';
import { ListItem, Typography } from '@mui/material';
import { useStyles } from './styles';
import { ProfileDetailMenu } from '../../profile/ProfileDetailMenu';
import { Avatar } from '../../avatar';

interface Props {
    menuItem: SideMenuItemType;
    onMenuItemClick?: (...args: any[]) => void;
}

export const ProfileMenuItem: FC<Props> = ({ menuItem, onMenuItemClick }) => {
    const location = useLocation();
    const classes = useStyles();
    const { labelKey = '', url } = menuItem;
    const { photoPath, firstName, lastName } = useSelector(
        ({ user }: AppReducerState) => user.current,
    );

    return (
        <div className={classes.profileMenuItem}>
            <ListItem
                key={labelKey}
                button
                onClick={onMenuItemClick}
                disabled={!url}
                selected={location.pathname === url}
            >
                <div className={classes.avatarBox}>
                    <Avatar alt={`${firstName[0]}${lastName[0]}`} src={photoPath} />
                    <Typography
                        component="span"
                        sx={classes.name}
                    >{`${firstName} ${lastName}`}</Typography>
                </div>
                <ProfileDetailMenu sideMenuMode />
            </ListItem>
        </div>
    );
};
