import { useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';
import type { FC } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { useStyles } from './questProgressChartArc.styles';

const END_ANGLE = (3 / 2) * Math.PI;
const TEXT_MARGIN = -10;
const TRANSITION_DURATION = 500;

interface Props {
    arcMargin: number;
    className?: string;
    disabled: boolean;
    hideProgress?: boolean;
    index: number;
    onHover: (idx: number | undefined) => void;
    radius: number;
    transitionDuration?: number;
    value: number;
    width: number;
}

export const QuestProgressChartArc: FC<Props> = ({
    arcMargin,
    className,
    disabled,
    hideProgress,
    index,
    onHover,
    radius,
    transitionDuration = TRANSITION_DURATION,
    value,
    width,
}) => {
    const arcRef = useRef<SVGGElement>(null);
    const backgroundArcRef = useRef<SVGPathElement>(null);
    const valueArcRef = useRef<SVGPathElement>(null);
    const classes = useStyles(index, disabled);
    const { t } = useTranslation();
    const circleValue = useMemo(
        () =>
            d3
                .arc()
                .startAngle(0)
                .innerRadius(radius)
                .outerRadius(radius - width)
                .cornerRadius(width / 2) as any,
        [radius, width],
    );
    const circleBackground = useMemo(
        () =>
            d3
                .arc()
                .startAngle(0)
                .endAngle(END_ANGLE)
                .innerRadius(radius)
                .outerRadius(radius - width)
                .cornerRadius(width / 2) as any,
        [radius, width],
    );

    const interpolate = d3.interpolate(0, END_ANGLE * (value / 100));

    const arcTween = () => (val) => circleValue({ endAngle: interpolate(val) });

    useEffect(() => {
        d3.select(valueArcRef.current)
            .transition()
            .duration(transitionDuration)
            .attrTween('d', arcTween);
    }, [value, transitionDuration]);

    const handleMouseEnter = () => onHover(index);

    const handleMouseLeave = () => onHover(undefined);

    return (
        <g
            ref={arcRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={cx(className)}
        >
            <path
                ref={backgroundArcRef}
                className={classes.backgroundArcPath}
                d={circleBackground()}
            />
            <path ref={valueArcRef} className={classes.valueArcPath} d={circleValue} />
            {!hideProgress && (
                <text x={TEXT_MARGIN} y={-radius + width - arcMargin} className={classes.valueText}>
                    {t('quest.questProgressChartArc.completed.label', { percentage: `${value}%` })}
                </text>
            )}
        </g>
    );
};
