import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
        },
        dataWrapper: css({
            marginLeft: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
        }),
    };
};
