import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general } from '../../constants';

const HomePage = Loadable({
    loader: () => import('views/admin/Home'),
    loading: Loading,
    render(loaded, props) {
        const { Home } = loaded;
        return <Home {...props} />;
    },
    delay: general.delay,
});

export const homeRoute = {
    path: '/',
    exact: true,
    name: 'Home',
    component: HomePage,
    details: {
        authenticationRequired: true,
        systemInstanceRequired: false,
        companyRequired: false,
    },
};
