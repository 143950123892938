export enum STORE_TAB_TYPES {
    REWARDS = 0,
    HISTORY = 1,
}

export interface StoreItem {
    sTranSysId: string;
    storeItemSysId: string;
    descriptionShort: string;
    descriptionLong: string;
    photoPath: string;
    price: number;
}

export interface StoreTransaction {
    sTransSysId: string;
    storeItemSysIs: string;
    descriptionShort: string;
    descriptionLong: string;
    photoPath: string;
    purchasedByEmployeeId: string;
    pricePaid: number;
    dateCreated: string;
}
