import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    IconButton,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';

import { useStyles } from './styles';

interface Props<T = any> {
    onConfirm?: (row: T) => void;
    row: T;
    text?: string;
    title?: string;
}

export const FTDeleteAction = <T,>({ onConfirm, row, text, title }: Props<T>) => {
    const [showDialog, setShowDialog] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const handleConfirm = () => {
        setShowDialog(false);
        onConfirm?.(row);
    };

    const handleOnClick = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        setShowDialog(true);
    };

    const handleCloseDialog = () => setShowDialog(false);

    const handleDialogClick = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
    };

    return (
        <>
            <IconButton
                onClick={handleOnClick}
                aria-label={t<string>('delete')}
                sx={classes.deleteButton}
                size="large"
            >
                <Icon path={mdiTrashCanOutline} size={1} />
            </IconButton>
            <Dialog
                open={showDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="bigPadding"
                onClick={handleDialogClick}
            >
                <Card>
                    <CardHeader
                        action={
                            <IconButton
                                aria-label="settings"
                                onClick={handleCloseDialog}
                                size="large"
                            >
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent sx={classes.modalContent}>
                        <Typography variant="h4" component="h2">
                            Delete row
                        </Typography>
                        <Typography>Are you sure you want to delete the selected row?</Typography>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Button variant="outlined" onClick={handleCloseDialog}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleConfirm}
                            sx={classes.orangeAction}
                        >
                            Delete
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
        </>
    );
};
