/* eslint-disable max-lines */
import type { FC } from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Alert,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { SignUpContext } from 'contexts';
import { COMPANY_SIZE, TEAM_SIZE, ONBOARDING_ROLE } from 'types';
import type { FormValidationError } from 'types';
import { Loading, PasswordInput } from 'components';
import { getPasswordErrors, getPasswordConfirmationErrors, signUpService } from 'services';

import { useStyles as useCommonStyles } from '../common/styles';
import { useStyles } from './signUpStyles';

interface Props {
    onCreateAccountClick: () => void;
    onGoBackClick: () => void;
}

const ROLE_TRANSLATION_KEYS = {
    [ONBOARDING_ROLE.BUSINESS_OWNER]: 'user.onboarding.basicInfo.role.businessOwner.label',
    [ONBOARDING_ROLE.TEAM_LEADER]: 'user.onboarding.basicInfo.role.teamLeader.label',
    [ONBOARDING_ROLE.TEAM_MEMBER]: 'user.onboarding.basicInfo.role.teamMember.label',
};

const COMPANY_SIZE_TRANSLATION_KEYS = {
    [COMPANY_SIZE.TWENTY]: 'user.onboarding.basicInfo.companySize.twenty.label',
    [COMPANY_SIZE.FIFTY]: 'user.onboarding.basicInfo.companySize.fifty.label',
    [COMPANY_SIZE.HUNDRED]: 'user.onboarding.basicInfo.companySize.hundred.label',
    [COMPANY_SIZE.TWO_HUNDRED_FIFTY]: 'user.onboarding.basicInfo.companySize.twoHundredFifty.label',
    [COMPANY_SIZE.FIFE_HUNDRED]: 'user.onboarding.basicInfo.companySize.fifeHundred.label',
    [COMPANY_SIZE.ONE_THOUSAND_FIVE_HUNDRED]:
        'user.onboarding.basicInfo.companySize.oneThousandFiveHundred.label',
    [COMPANY_SIZE.ONE_THOUSAND_FIVE_HUNDRED_PLUS]:
        'user.onboarding.basicInfo.companySize.oneThousandFiveHundredPlus.label',
};

const TEAM_SIZE_TRANSLATION_KEYS = {
    [TEAM_SIZE.FIVE]: 'user.onboarding.basicInfo.teamSize.five.label',
    [TEAM_SIZE.TEN]: 'user.onboarding.basicInfo.teamSize.ten.label',
    [TEAM_SIZE.FIFTEEN]: 'user.onboarding.basicInfo.teamSize.fifteen.label',
    [TEAM_SIZE.TWENTY_FIVE]: 'user.onboarding.basicInfo.teamSize.twentyFive.label',
    [TEAM_SIZE.FIFTY]: 'user.onboarding.basicInfo.teamSize.fifty.label',
    [TEAM_SIZE.FIFTY_PLUS]: 'user.onboarding.basicInfo.teamSize.fiftyPlus.label',
};

export const BasicInfo: FC<Props> = ({ onCreateAccountClick, onGoBackClick }) => {
    const {
        firstName,
        lastName,
        email,
        role,
        companySize,
        password,
        passwordConfirmation,
        setCompanySize,
        setRole,
        setPassword,
        setPasswordConfirmation,
        setTeamSize,
        teamSize,
    } = useContext(SignUpContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [roleError, setRoleError] = useState<FormValidationError>();
    const [teamSizeError, setTeamSizeError] = useState<FormValidationError>();
    const [companySizeError, setCompanySizeError] = useState<FormValidationError>();
    const [passwordErrors, setPasswordErrors] = useState<FormValidationError[]>([]);
    const [passwordConfirmationErrors, setPasswordConfirmationErrors] = useState<
        FormValidationError[]
    >([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const handleSignUp = (evt) => {
        evt.preventDefault();

        let roleValidationError;
        let teamSizeValidationError;
        let companySizeValidationError;
        const passwordValidationErrors = getPasswordErrors(password, t);
        const passwordConfirmationValidationError = getPasswordConfirmationErrors(
            password,
            passwordConfirmation,
            t,
        );

        setPasswordErrors(passwordValidationErrors);
        setPasswordConfirmationErrors(passwordConfirmationValidationError);

        if (!role) {
            roleValidationError = { field: 'role', message: 'Please pick a role' };
            setRoleError(roleValidationError);
        }

        if (!teamSize) {
            teamSizeValidationError = {
                field: 'teamSize',
                message: "Please pick your team's size",
            };
            setTeamSizeError(teamSizeValidationError);
        }

        if (!companySize) {
            companySizeValidationError = {
                field: 'companySize',
                message: 'Please pick the approximate size of your company',
            };
            setCompanySizeError(companySizeValidationError);
        }

        if (
            roleValidationError ||
            teamSizeValidationError ||
            companySizeValidationError ||
            passwordValidationErrors.length ||
            passwordConfirmationValidationError.length
        ) {
            return;
        }

        setIsLoading(true);
        signUpService
            .signUp({
                firstName,
                lastName,
                password,
                email,
                questionnaire: {
                    currentRole: role,
                    teamSize,
                    companySize,
                },
            })
            .then(() => {
                onCreateAccountClick();
            })
            .catch((err) => {
                setError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleGoBack = () => onGoBackClick();

    const handleRoleChange = (evt) => {
        setRole(evt.target.value);
        setRoleError(undefined);
    };

    const handleCompanySizeChange = (evt) => {
        setCompanySize(evt.target.value);
        setCompanySizeError(undefined);
    };

    const handleTeamSizeChange = (evt) => {
        setTeamSize(evt.target.value);
        setTeamSizeError(undefined);
    };

    const handlePasswordChange = (value) => {
        setPassword(value);
        setPasswordErrors([]);
    };

    const handlePasswordConfirmationChange = (value) => {
        setPasswordConfirmation(value);
        setPasswordConfirmationErrors([]);
    };

    return (
        <div className={classes.content}>
            {isLoading && <Loading />}
            {!isLoading && (
                <>
                    <Typography variant="h1" sx={classes.title}>
                        {t('user.onboarding.basicInfo.title.label', { name: firstName })}
                    </Typography>
                    <Typography variant="body1" sx={classes.grayText}>
                        {t('user.onboarding.basicInfo.info.label')}
                    </Typography>
                    <br />
                    <form onSubmit={handleSignUp}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl size="small" fullWidth error={!!roleError}>
                                    <InputLabel htmlFor="user-onboarding-basicInfo-role">
                                        {t<string>('user.onboarding.basicInfo.role.label')}
                                    </InputLabel>
                                    <Select
                                        id="user-onboarding-basicInfo-role"
                                        value={role}
                                        fullWidth
                                        label={t<string>('user.onboarding.basicInfo.role.label')}
                                        onChange={handleRoleChange}
                                    >
                                        {Object.keys(ROLE_TRANSLATION_KEYS).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                {t(ROLE_TRANSLATION_KEYS[key])}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {roleError && (
                                        <FormHelperText>{roleError.message}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl size="small" fullWidth error={!!teamSizeError}>
                                    <InputLabel htmlFor="user-onboarding-basicInfo-role">
                                        {t<string>('user.onboarding.basicInfo.teamSize.label')}
                                    </InputLabel>
                                    <Select
                                        value={teamSize}
                                        label={t<string>(
                                            'user.onboarding.basicInfo.teamSize.label',
                                        )}
                                        onChange={handleTeamSizeChange}
                                    >
                                        {Object.keys(TEAM_SIZE_TRANSLATION_KEYS).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                {t(TEAM_SIZE_TRANSLATION_KEYS[key])}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {teamSizeError && (
                                        <FormHelperText>{teamSizeError.message}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl size="small" fullWidth error={!!companySizeError}>
                                    <InputLabel htmlFor="user-onboarding-basicInfo-role">
                                        {t<string>('user.onboarding.basicInfo.companySize.label')}
                                    </InputLabel>
                                    <Select
                                        value={companySize}
                                        onChange={handleCompanySizeChange}
                                        label={t<string>(
                                            'user.onboarding.basicInfo.companySize.label',
                                        )}
                                    >
                                        {Object.keys(COMPANY_SIZE_TRANSLATION_KEYS).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                {t(COMPANY_SIZE_TRANSLATION_KEYS[key])}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {companySizeError && (
                                        <FormHelperText>{companySizeError.message}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordInput
                                    password={password}
                                    onPasswordChange={handlePasswordChange}
                                    passwordError={passwordErrors[0]}
                                    label={t<string>(
                                        'user.onboarding.basicInfo.password.placeholder',
                                    )}
                                    hideAdornment
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordInput
                                    password={passwordConfirmation}
                                    onPasswordChange={handlePasswordConfirmationChange}
                                    passwordError={passwordConfirmationErrors[0]}
                                    label={t<string>(
                                        'user.onboarding.basicInfo.passwordConfirmation.placeholder',
                                    )}
                                    hideAdornment
                                />
                            </Grid>
                            {error && (
                                <Grid item xs={12}>
                                    <Alert severity="error">{error}</Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography component="span">
                                    {t('user.onboarding.basicInfo.nextStepInfo.label')}
                                </Typography>
                                <Typography component="span">
                                    <strong>
                                        {t('user.onboarding.basicInfo.nextStepInfoBold.label')}
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={commonClasses.primaryAction}
                                >
                                    {t('user.onboarding.basicInfo.actions.createAccount.label')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sx={commonClasses.actions}>
                                <Button
                                    variant="text"
                                    sx={commonClasses.secondaryAction}
                                    onClick={handleGoBack}
                                >
                                    {t('user.onboarding.basicInfo.actions.back.label')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </>
            )}
        </div>
    );
};
