import axios from 'axios';
import { api } from '../constants';
import type { MonthlyActiveUsers } from '../types';

const controllerApi = `${api.reports}`;

export const getUserPolicies = async ({
    pageIndex,
    pageSize,
    policySysId,
    isUserAcceptedPolicy,
    searchText,
}) => {
    return axios
        .get(
            `${controllerApi}/user-policies?pageIndex=${pageIndex}&pageSize=${pageSize}&isUserAcceptedPolicy=${isUserAcceptedPolicy}&searchText=${searchText}&policySysId=${policySysId}`,
        )
        .then((res) => res.data);
};

export const deleteEmployees = async ({ ids }) =>
    axios.post(`${api.employees}/delete`, {
        ids,
    });

export const getMonthlyActiveUsers = () =>
    axios.get<MonthlyActiveUsers[]>(`${controllerApi}/active-users`);
