import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { sanitize } from 'dompurify';
import { CardContent, Divider, Typography } from '@mui/material';
import type { Quest } from 'types';
import type { AppReducerState } from 'store';
import { VisibilityOff } from '@mui/icons-material';
import { QuestDetailQuestTypeIcon } from '../QuestDetailQuestTypeIcon';
import { QuestStatusChip } from '../QuestStatusChip';
import { useStyles } from './styles';

const DATE_FORMAT = 'DD.MM.YYYY';

interface Props {
    quest: Quest;
}

export const QuestDetailParentQuest: FC<Props> = ({ quest }) => {
    const { t } = useTranslation();
    const { parentQuestId } = quest;
    const navigate = useNavigate();
    const classes = useStyles();

    const { companyObjectives } = useSelector(
        ({ configuration }: AppReducerState) => configuration,
    );
    const parentQuest = companyObjectives.find(
        (objective) => objective.objectiveId === parentQuestId,
    );

    if (!parentQuest) {
        return null;
    }

    const handleOnQuestClick = () => navigate(`/quest/${parentQuest.objectiveId}`);

    return (
        <>
            <Divider />
            <CardContent sx={classes.quest} onClick={handleOnQuestClick}>
                <Typography variant="h4" sx={classes.title}>
                    {t('quest.detail.parentQuest.label')}
                </Typography>
                <div className={classes.flex}>
                    <div className={classes.typeIconWrapper}>
                        <QuestDetailQuestTypeIcon questType={parentQuest.type} />
                    </div>
                    <div>
                        <div>
                            <QuestStatusChip questStatus={parentQuest.status} />
                            <Typography variant="caption" sx={classes.dates}>
                                {`${moment(parentQuest.dateFrom).format(DATE_FORMAT)} - ${moment(
                                    parentQuest.dateTo,
                                ).format(DATE_FORMAT)}`}
                            </Typography>
                        </div>
                        <div className={classes.questName}>
                            {parentQuest.confidential && (
                                <VisibilityOff sx={classes.visibilityIcon} />
                            )}
                            <Typography component="span">
                                <strong>
                                    {sanitize(parentQuest.descriptionShort, { USE_PROFILES: {} })}
                                </strong>
                            </Typography>
                        </div>
                    </div>
                </div>
            </CardContent>
        </>
    );
};
