export const de = {
    translation: {
        profile: {
            actions: {
                giveFeedback: 'Feedback geben',
                updateAvatar: {
                    update: 'Aktualisieren',
                    saveChanges: 'Speichern',
                    cancel: 'Abbrechen',
                    imagePlaceholderLabel: 'Wählen Sie eine Datei',
                },
            },
            settings: {
                changeLanguage: 'Sprache ändern',
                revokeConsent: 'Zustimmung mit GDPR widerrufen',
                changePassword: 'Passwort ändern',
                language: {
                    english: { label: 'Englisch' },
                    german: { label: 'Deutsch' },
                    slovak: { label: 'Slowakisch' },
                    czech: { label: 'Tschechisch' },
                    croatian: { label: 'Kroatisch' },
                    slovenian: { label: 'Slowenisch' },
                },
            },
            info: {
                level: 'LEVEL',
                experience: 'ERFAHRUNG',
                inventory: 'INVENTAR',
            },
        },
    },
};
