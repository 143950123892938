import { createContext } from 'react';
import type { Moment } from 'moment';
import { CreateQuestStep } from 'types';
import type { QuestAssignee, QuestType, QuestStatus, KeyResult } from 'types';

export interface CreateQuestContextType {
    assignedTo: QuestAssignee[];
    relatedCompetency?: string;
    confidential: boolean;
    descriptionLong?: string;
    dateTo?: Moment | null;
    keyResults: KeyResult[];
    descriptionShort?: string;
    loading: boolean;
    priorityId?: string;
    parentQuestId?: string;
    relatedSkills?: string[];
    dateFrom?: Moment | null;
    objectiveId?: string;
    step: CreateQuestStep;
    status?: QuestStatus;
    type?: QuestType;
    createNewQuest(): void;
    preFillData(preFillData: any): void;
    publishQuest(status?: QuestStatus): void;
    saveAsDraft(): void;
    setAssignedTo(assignees: QuestAssignee[]): void;
    setRelatedCompetency(relatedCompetency: string): void;
    setConfidential(confidential: boolean): void;
    setDescriptionLong(descriptionLong: string): void;
    setDateTo(dateTo: Moment): void;
    setKeyResults(keyResults: KeyResult[]): void;
    setDescriptionShort(descriptionShort: string): void;
    setPriorityId(priorityId: string): void;
    setParentQuestId(questId: string): void;
    setType(type?: QuestType): void;
    setRelatedSkills(relatedSkills: string[]): void;
    setDateFrom(dateFrom: Moment): void;
    setStep(step: CreateQuestStep): void;
    snackBarOpen: boolean;
    snackBarError: string;
    setSnackBarOpen(open: boolean): void;
    setSnackBarError(error: string): void;
}

export const CreateQuestContext = createContext<CreateQuestContextType>({
    assignedTo: [],
    relatedCompetency: undefined,
    confidential: false,
    descriptionLong: undefined,
    dateTo: undefined,
    keyResults: [],
    descriptionShort: undefined,
    loading: false,
    priorityId: undefined,
    parentQuestId: undefined,
    relatedSkills: undefined,
    dateFrom: undefined,
    objectiveId: '',
    step: CreateQuestStep.TYPE_STEP,
    status: undefined,
    type: undefined,
    createNewQuest: () => {},
    preFillData: () => {},
    publishQuest: () => {},
    saveAsDraft: () => {},
    setAssignedTo: () => {},
    setRelatedCompetency: () => {},
    setConfidential: () => {},
    setDescriptionLong: () => {},
    setDateTo: () => {},
    setKeyResults: () => {},
    setDescriptionShort: () => {},
    setPriorityId: () => {},
    setParentQuestId: () => {},
    setType: () => {},
    setRelatedSkills: () => {},
    setDateFrom: () => {},
    setStep: () => {},
    snackBarOpen: false,
    snackBarError: '',
    setSnackBarOpen: () => {},
    setSnackBarError: () => {},
});
