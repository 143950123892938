import { useState } from 'react';

export const useRaisedState = () => {
    const [raised, setRaised] = useState(false);

    const handleOnFocus = () => setRaised(true);

    const handleOnBlur = () => setRaised(false);

    return { handleOnFocus, handleOnBlur, raised };
};
