import type { FC } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { sanitize } from 'dompurify';
import type { QuestSurvey } from 'types';
import { useStyles } from './styles';

interface Props {
    survey: QuestSurvey;
}

export const QuestDetailSurvey: FC<Props> = ({ survey }) => {
    const classes = useStyles();

    return (
        <Card>
            <CardContent>
                {survey.questionGroups
                    .sort((first, second) => first.order - second.order)
                    .map((questionGroup) => (
                        <div key={`${survey.type}${questionGroup.order}`}>
                            <Typography variant="h3" sx={classes.questionGroupTitle}>
                                {questionGroup.title}
                            </Typography>
                            {questionGroup.questions
                                .sort((first, second) => first.order - second.order)
                                .map((question) => (
                                    <div className={classes.question}>
                                        <Typography>
                                            <strong>{question.questionText}</strong>
                                        </Typography>
                                        <Typography>
                                            {sanitize(question.questionAnswer, {
                                                USE_PROFILES: {},
                                            })}
                                        </Typography>
                                    </div>
                                ))}
                        </div>
                    ))}
            </CardContent>
        </Card>
    );
};
