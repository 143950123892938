import type { FC } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import type { Quest, JobProfileQuest } from 'types';
import { QuestStatusChip } from '../../quest/QuestStatusChip';
import { useStyles } from './styles';

const DATE_FORMAT = 'DD.MM.YYYY';

interface Props {
    quest: Quest | JobProfileQuest;
}

export const EvaluationQuestCard: FC<Props> = ({ quest }) => {
    const classes = useStyles();
    const {
        dateFrom,
        dateTo,
        title,
        descriptionLong,
        descriptionShort,
        status,
        statusDescription,
    } = quest;

    return (
        <Card>
            <CardContent>
                <div className={classes.content}>
                    <div className={classes.description}>
                        <Typography variant="caption" sx={classes.dates}>
                            {`${moment(dateFrom).format(DATE_FORMAT)} - ${moment(dateTo).format(
                                DATE_FORMAT,
                            )}`}
                        </Typography>
                        <QuestStatusChip questStatus={status} />
                        <Typography>{<strong>{title || descriptionShort}</strong>}</Typography>
                        <Typography>{descriptionLong}</Typography>
                    </div>
                    <Typography>{statusDescription}</Typography>
                </div>
            </CardContent>
        </Card>
    );
};
