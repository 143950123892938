import { combineReducers } from '@reduxjs/toolkit';
import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { activityStreamReducer } from '../slices/activityStream';
import { analyticsReducer } from '../slices/analytics';
import { appStateReducer } from '../slices/appState';
import { competenciesReducer } from '../slices/competencies';
import { configurationReducer } from '../slices/configuration';
import { feedbackReducer } from '../slices/feedback';
import { layoutReducer } from '../slices/layout';
import { questReducer } from '../slices/quest';
import { searchReducer } from '../slices/search';
import { userReducer } from '../slices/user';
import { profileReducer } from '../slices/profile';

export const appReducer = combineReducers({
    activityStream: activityStreamReducer,
    analytics: analyticsReducer,
    appState: appStateReducer,
    competencies: competenciesReducer,
    configuration: configurationReducer,
    feedback: feedbackReducer,
    layout: layoutReducer,
    quest: questReducer,
    search: searchReducer,
    user: userReducer,
    profile: profileReducer,
});

export type AppReducerState = ReturnType<typeof appReducer>;

export type AppThunk<T = Promise<any>> = ThunkAction<T, AppReducerState, unknown, Action<string>>;
