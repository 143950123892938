import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (transparentBackground) => {
    const theme = useTheme();

    return {
        typeIconAvatar: {
            borderRadius: theme.spacing(2),
            width: theme.spacing(7),
            height: theme.spacing(7),
            background: transparentBackground ? 'transparent' : colors.white,
            border: `1px solid ${colors.greyLighter2} !important`,
        },
        typeIcon: css({
            width: `${theme.spacing(3)} !important`,
            height: `${theme.spacing(3)} !important`,
        }),
    };
};
