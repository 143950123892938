import { createSlice } from '@reduxjs/toolkit';

export interface LayoutState {
    menuOpen: boolean;
    nodalOpen: boolean;
}

const initialState: LayoutState = {
    menuOpen: true,
    nodalOpen: false,
};

const layout = createSlice({
    initialState,
    name: 'layout',
    reducers: {
        openMenu: (state) => {
            state.menuOpen = true;
        },
        closeMenu: (state) => {
            state.menuOpen = false;
        },
        toggleNodal: (state) => {
            state.nodalOpen = !state.nodalOpen;
        },
    },
});

export const {
    actions: { openMenu, closeMenu, toggleNodal },
    reducer: layoutReducer,
} = layout;
