import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        avatar: {
            marginRight: theme.spacing(1),
        },
        avatarWrapper: css({
            display: 'flex',
        }),
        card: {
            minHeight: theme.spacing(30),
        },
        inputChip: {
            '& .MuiChip-root': {
                marginTop: 0,
                marginBottom: 0,
                backgroundColor: colors.blue,
                color: colors.white,

                '& .MuiSvgIcon-root': {
                    color: colors.white,
                },

                '& .MuiAvatar-root': {
                    // width: '18px',
                    // height: '18px',
                    // fontSize: 0.625rem;
                    // margin-left: 4px;
                    // margin-right: -4px;
                },
            },
        },
        suggestedEmployees: css({
            marginTop: theme.spacing(2),

            '& > .MuiTypography-root': {
                color: colors.grey,
            },
        }),
        suggestedEmployee: {
            // paddingRight: 0,
            width: `calc(100% + ${theme.spacing(4)})`,
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
        },
    };
};
