import axios from 'axios';
import { api } from '../constants';

const controllerApi = `${api.import}`;

export const bulkImport = async (data) => axios.post(`${controllerApi}`, data);

export const getImports = async ({ pageIndex, pageSize }) => {
    return axios
        .get(`${controllerApi}?pageIndex=${pageIndex}&pageSize=${pageSize}`)
        .then((res) => res.data);
};

export const getImportDetails = async ({
    importId,
    pageIndex,
    pageSize,
    searchText,
    status,
    type,
}) => {
    return axios
        .get(
            `${controllerApi}/${importId}/?pageIndex=${pageIndex}&pageSize=${pageSize}&searchText=${searchText}&status=${status}&type=${type}`,
        )
        .then((res) => res.data);
};
