import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        headerContent: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        descriptionShort: {
            textAlign: 'center',
        },
        descriptionLong: {
            marginBottom: theme.spacing(1),
            textAlign: 'center',
        },
        confidential: css({
            display: 'flex',
        }),
        confidentialIcon: {
            marginRight: theme.spacing(1),
            color: colors.blue,
        },
        confidentialDialogTitleWrapper: {
            padding: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
        },
        confidentialDialogTitle: {
            textAlign: 'center',
            flex: '1 0 auto',
        },
        questProgressChart: css({
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(3),
        }),
        questTypeIcon: css({
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            width: `${theme.spacing(10)} !important`,
            height: `${theme.spacing(10)} !important`,

            '& [class*=typeIcon]': {
                width: `${theme.spacing(9 / 2)} !important`,
                height: `${theme.spacing(9 / 2)} !important`,
            },
        }),
        rewardsWrapper: css({
            position: 'absolute',
            right: theme.spacing(0),
            top: theme.spacing(0),
            marginTop: theme.spacing(4),
            marginRight: theme.spacing(4),
        }),
    };
};
