import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const CARD_IMAGE_HEIGHT = 300;

export const useStyles = (): any => {
    const theme = useTheme();
    return {
        content: {
            padding: theme.spacing(4),
            paddingTop: theme.spacing(2),
        },
        itemImage: {
            height: `${CARD_IMAGE_HEIGHT}px`,
            minHeight: `${CARD_IMAGE_HEIGHT}px`,
        },
        storeItemCard: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        disabledCard: {
            opacity: 0.35,
        },
        cardContentWrapper: css({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
        }),
        actionsButtonWrapper: css({
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            '& > div': {
                display: 'flex',
                alignItems: 'center',
            },
        }),
    };
};
