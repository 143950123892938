import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        divCheckbox: css({
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(2),
        }),
    };
};
