import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import type { ActivityStreamFilterObject } from 'types';
import type { AppReducerState } from 'store';
import { useBoundActions } from 'hooks';
import { setActivityStreamFilterPreFillData } from 'store/slices/activityStream';

export const selectActivityStreamFilter = createSelector(
    (state: AppReducerState) => state.user,
    (state: AppReducerState) => state.activityStream.activityStreamFilterPreFillData,
    (employee, activityStreamFilterPreFillData) => {
        const prefilledAssignedToId = activityStreamFilterPreFillData?.assignedToWithType?.id;

        return {
            filter: {
                eventTypes: [],
                assignedTo: prefilledAssignedToId ? [prefilledAssignedToId] : [],
                query: '',
                ...(activityStreamFilterPreFillData?.filter || {}),
            } as ActivityStreamFilterObject,
            assignedToWithType: activityStreamFilterPreFillData?.assignedToWithType,
        };
    },
);

export const useActivityStreamFilter = () => {
    const { filter, assignedToWithType } = useSelector(selectActivityStreamFilter);

    const { setActivityStreamFilterPreFillDataAction } = useBoundActions({
        setActivityStreamFilterPreFillData,
    });

    return {
        assignedToWithType,
        filter,
        setActivityStreamFilterPreFillDataAction,
    };
};
