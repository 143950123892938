import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        title: {
            marginBottom: theme.spacing(2),
            flex: '1 0 auto',
        },
        titleWrapper: css({
            display: 'flex',
            alignItems: 'baseline',
        }),
        emptyContent: {
            textAlign: 'center',
        },
        emptyQuestsTitle: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    };
};
