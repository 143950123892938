import type { FormValidationError } from 'types';

const EMAIL_PATTERN =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const USERNAME_PATTERN = /^.{4,50}$/;

// const EMAIL_PATTERN =
//     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getEmailErrors = (email: string, t): FormValidationError[] => {
    const emailErrors: FormValidationError[] = [];

    if (!email) {
        emailErrors.push({ field: 'email', message: t('userService.email.error.empty.label') });
    }

    if (!EMAIL_PATTERN.test(String(email).toLowerCase())) {
        emailErrors.push({ field: 'email', message: t('userService.email.error.invalid.label') });
    }

    return emailErrors;
};

export const getUsernameErrors = (username: string, t): FormValidationError[] => {
    const usernameErrors: FormValidationError[] = [];

    if (!username) {
        usernameErrors.push({
            field: 'username',
            message: t('userService.username.error.empty.label'),
        });
    }

    if (!USERNAME_PATTERN.test(String(username).toLowerCase())) {
        usernameErrors.push({
            field: 'username',
            message: t('userService.username.error.invalid.label'),
        });
    }

    return usernameErrors;
};

export const getPasswordErrors = (password, t): FormValidationError[] => {
    const passwordErrors: FormValidationError[] = [];

    if (!password) {
        passwordErrors.push({
            field: 'password',
            message: t('userService.password.error.empty.label'),
        });
    }

    if (password.length < 5) {
        passwordErrors.push({
            field: 'password',
            message: t('userService.password.error.min.label'),
        });
    }

    if (password.length > 12) {
        passwordErrors.push({
            field: 'password',
            message: t('userService.password.error.max.label'),
        });
    }

    return passwordErrors;
};

export const getPasswordConfirmationErrors = (password, confirmation, t) => {
    const passwordErrors: FormValidationError[] = getPasswordErrors(confirmation, t);

    if (password !== confirmation) {
        passwordErrors.push({
            field: 'password',
            message: t('userService.password.error.match.label'),
        });
    }

    return passwordErrors;
};
