import { useEffect } from 'react';
import type { FC } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Alert, Container, Snackbar } from '@mui/material';
import { loadAppData } from 'store/slices/appState';
import { useBoundActions } from 'hooks';
import { Loading, SideMenu, BottomMenu } from 'components';
import type { AppReducerState } from 'store';
import { routes } from 'config';
import { useStyles } from './styles';

export const DefaultLayout: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const classes = useStyles();
    const { isLoading, configurationFailedToLoad, errors, nodalOpen, loggedIn } = useSelector(
        ({ appState, configuration, layout, user }: AppReducerState) => ({
            ...user,
            ...layout,
            ...appState,
            configurationFailedToLoad: configuration.failedToLoad,
        }),
    );
    const { loadAppDataAction } = useBoundActions({ loadAppData });
    const maxWidth = nodalOpen ? false : 'xl';
    const { useBackgroundImage } =
        routes.find(
            ({ path, headerMatch }) =>
                path === location.pathname ||
                location.pathname.indexOf(headerMatch as string) !== -1,
        ) || {};

    useEffect(() => {
        if (!loggedIn) {
            navigate('/login');
        }
    }, [loggedIn]);

    useEffect(() => {
        loadAppDataAction();
    }, []);

    if (isLoading) {
        return <Loading className={classes.appLoading} />;
    }

    if (!isLoading && errors.length) {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open
            >
                <Alert severity="error">{errors[0].errorMessage}</Alert>
            </Snackbar>
        );
    }

    if (!loggedIn) {
        return null;
    }

    // if (configurationFailedToLoad) {
    //     return (
    //         <div
    //             className={cx({
    //                 [classes.background]: useBackgroundImage,
    //             })}
    //         >
    //             <Container maxWidth={maxWidth} sx={classes.appWrapper} disableGutters>
    //                 <Routes>
    //                     {routes.map(
    //                         ({ path, component: Page }) =>
    //                             Page && <Route key={path} path={path} Component={Page} />,
    //                     )}
    //                 </Routes>
    //             </Container>
    //         </div>
    //     );
    // }

    return (
        <div
            className={cx({
                [classes.background]: useBackgroundImage,
            })}
        >
            <Container maxWidth={maxWidth} sx={classes.appWrapper} disableGutters>
                {!nodalOpen && <SideMenu alignWithContent={useBackgroundImage} />}
                <Routes>
                    {routes.map(
                        ({ path, component: Page }) =>
                            Page && <Route key={path} path={path} Component={Page} />,
                    )}
                </Routes>
                {!nodalOpen && <BottomMenu />}
            </Container>
        </div>
    );
};
