import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        profileActions: css({
            display: 'flex',
            justifyContent: 'center',
            width: '100%',

            '& .MuiButton-root': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
            },
        }),
    };
};
