import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { MenuBook, Search } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiChatOutline, mdiHomeVariantOutline } from '@mdi/js';
import { useLayout } from 'hooks';
import { useStyles } from './styles';

const MENU_URLS = {
    0: '/dashboard',
    1: '/feedback/my',
    2: '/quest/log',
    3: '/search',
};

export const BottomMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isXs, isSm, isMd } = useLayout();
    const { t } = useTranslation();
    const classes = useStyles();

    const onNavigationChange = (evt, value) => navigate(MENU_URLS[value]);

    if (!(isXs || isSm || isMd)) {
        return null;
    }

    const activeMenu = Object.values(MENU_URLS).indexOf(location.pathname);

    return (
        <BottomNavigation
            value={activeMenu}
            onChange={onNavigationChange}
            showLabels
            sx={classes.bottomMenu}
        >
            <BottomNavigationAction
                label={t('bottomMenu.home.label')}
                icon={<Icon path={mdiHomeVariantOutline} className={classes.icon} />}
            />
            <BottomNavigationAction
                label={t<string>('bottomMenu.feedback.label')}
                icon={<Icon path={mdiChatOutline} className={classes.icon} />}
            />
            <BottomNavigationAction label={t('bottomMenu.questLog.label')} icon={<MenuBook />} />
            <BottomNavigationAction label={t('bottomMenu.search.label')} icon={<Search />} />
        </BottomNavigation>
    );
};
