import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        dialogTitle: {
            textAlign: 'center',
            paddingLeft: `${theme.spacing(7)} !important`,
            paddingRight: `${theme.spacing(7)} !important`,
        },
        wrapper: css({
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            // width: 'fit-content',

            '&.ORANGE': {
                color: colors.orange,

                '& .MuiTypography-root': {
                    color: colors.orange,
                },
            },

            '&.BLUE': {
                color: colors.blue,

                '& .MuiTypography-root': {
                    color: colors.blue,
                },
            },

            '&.DEFAULT': {},
        }),
    };
};
