import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.jobs;

const JobLoader = Loadable({
    loader: () => import('views/admin/Job'),
    loading: Loading,
    render(loaded, props) {
        const { Job } = loaded;
        return <Job {...props} />;
    },
    delay: general.delay,
});

const JobEditLoader = Loadable({
    loader: () => import('views/admin/Job/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

const JobBulkImportLoader = Loadable({
    loader: () => import('views/admin/JobBulkImport'),
    loading: Loading,
    render(loaded, props) {
        const { JobBulkImport } = loaded;
        return <JobBulkImport {...props} />;
    },
    delay: general.delay,
});

export const jobRoute = {
    path: `/${mainRoute}`,
    component: JobLoader,
};

export const jobCreateRoute = {
    path: `/${mainRoute}/create`,
    component: JobEditLoader,
};

export const jobEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: JobEditLoader,
};

export const jobBulkImportRoute = {
    path: `/${mainRoute}/bulkImport`,
    component: JobBulkImportLoader,
};
