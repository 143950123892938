import axios from 'axios';
import type {
    ISearch,
    IRelationEmployeeTeamView,
    IUpsertRelationEmployeeTeamCommand,
    ISearchView,
    IApiResponse,
} from 'types';
import { api } from '../constants';

const controllerApi = api.relationEmployeeTeams;

export const search = async (
    queryParams: ISearch,
): Promise<ISearchView<IRelationEmployeeTeamView>> => {
    return axios
        .get(`${controllerApi}`, {
            params: queryParams,
        })
        .then((res) => res.data);
};

export const getBySysId = async (id: string): Promise<IRelationEmployeeTeamView> => {
    return axios.get(`${controllerApi}/${id}`).then((res) => res.data);
};

export const create = async (data: IUpsertRelationEmployeeTeamCommand): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}`, data);
};

export const update = async (
    id: string,
    data: IUpsertRelationEmployeeTeamCommand,
): Promise<IApiResponse> => {
    return axios.put(`${controllerApi}/${id}`, data);
};

export const deleteEmployeeTeams = async ({ ids }): Promise<IApiResponse> => {
    return axios.post(`${controllerApi}/delete`, {
        ids,
    });
};
