import { css } from '@emotion/css';
import { frankyTalesTheme } from 'theme';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => ({
    experience: css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& span span': {
            color: colors.grey,
        },
    }),
});
