import { useNavigate } from 'react-router-dom';
import { sanitize } from 'dompurify';
import { Typography } from '@mui/material';
import type { NotificationDataLineValue } from 'types';
import { NOTIFICATION_DATA_LINE_TYPE } from 'types';

import { useStyles } from './styles';

const PROFILE_URLS = {
    [NOTIFICATION_DATA_LINE_TYPE.EMPLOYEE]: 'profile',
    [NOTIFICATION_DATA_LINE_TYPE.JOB]: 'jobProfile',
    [NOTIFICATION_DATA_LINE_TYPE.JOB_FAMILY]: 'jobFamilyProfile',
    [NOTIFICATION_DATA_LINE_TYPE.TEAM]: 'teamProfile',
    [NOTIFICATION_DATA_LINE_TYPE.QUEST]: 'quest',
};

interface Props {
    item: NotificationDataLineValue;
}

export const EventRowMessageItemEmployee = ({ item }: Props) => {
    const navigate = useNavigate();
    const classes = useStyles(item.id);

    const handleOnEmployeeClick = () => {
        if (item.id) {
            navigate(`/${PROFILE_URLS[item.type]}/${item.id}`);
        }
    };

    return (
        <Typography component="span" sx={classes.employee} onClick={handleOnEmployeeClick}>
            {sanitize(item.displayName, { USE_PROFILES: {} })}
        </Typography>
    );
};
