import type { FC, MouseEvent } from 'react';
import { useCallback, useState } from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import type { SxProps } from '@mui/system';
import { useStyles } from './styles';
import { Image } from '../../Image';

interface Props {
    alt?: string;
    className?: string;
    sx?: SxProps;
    onClick?: (evt: MouseEvent) => void;
    src?: string;
}

export const Avatar: FC<Props> = ({ alt, className, onClick, src, sx = {} }) => {
    const [error, setError] = useState(false);
    const classes = useStyles();

    const handleImageError = useCallback(() => setError(true), []);

    return (
        <>
            {src && !error && (
                <MuiAvatar
                    alt={alt}
                    sx={{ ...classes.avatar, ...sx }}
                    className={className}
                    onClick={onClick}
                >
                    <Image
                        src={src}
                        alt={alt}
                        className={classes.image}
                        onError={handleImageError}
                    />
                </MuiAvatar>
            )}
            {(!src || error) && (
                <MuiAvatar
                    alt={alt}
                    sx={{ ...classes.avatar, ...sx }}
                    className={className}
                    onClick={onClick}
                >
                    {alt}
                </MuiAvatar>
            )}
        </>
    );
};
