import { useTheme } from '@mui/material';
import { useLayout } from 'hooks';
import { css } from '@emotion/css';

const getLogoTopMargin = (isMdAndLower, isXxl) => {
    if (isXxl) {
        return 1.5;
    }

    if (isMdAndLower) {
        return 2;
    }

    return 6;
};

const getLogoBottomMargin = (isMdAndLower, isXxl) => {
    if (isXxl) {
        return 0;
    }

    if (isMdAndLower) {
        return 0;
    }

    return 2;
};

export const useStyles = (alignWithContent) => {
    const theme = useTheme();
    const { isXs, isSm, isMd, isXxl, menuWidth, menuTopOffset } = useLayout();

    const isMdAndLower = isXs || isSm || isMd;

    const logoTopMargin = getLogoTopMargin(isMdAndLower, isXxl);
    const logoBottomMargin = getLogoBottomMargin(isMdAndLower, isXxl);
    const logoLeftPadding = isMdAndLower ? undefined : theme.spacing(2);

    return {
        drawer: {
            width: theme.spacing(0),
            '& .MuiList-root': {
                marginLeft: theme.spacing(3),
                marginRight: theme.spacing(3),
            },
            '& .MuiPaper-root': {
                width: menuWidth,
            },
        },
        drawerOpen: {
            width: menuWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            '& .MuiPaper-root': {
                width: menuWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
        drawerClosed: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            [theme.breakpoints.up('md')]: {
                width: 0,
            },
            '& .MuiPaper-root': {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                [theme.breakpoints.up('md')]: {
                    width: 0,
                },
            },
        },
        toolbar: css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: theme.spacing(logoTopMargin),
            marginBottom: theme.spacing(logoBottomMargin),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            paddingLeft: logoLeftPadding,
        }),
        wideScreenPaper: {
            '& .MuiPaper-root': {
                height: '786px',
                float: 'left',
                borderRadius: `${theme.shape.borderRadius}px`,
                marginTop: `${alignWithContent ? 101 : menuTopOffset}px`,
                paddingBottom: `${menuTopOffset}px`,
                position: 'relative',
            },
        },
    };
};
