import { frankyTalesTheme } from 'theme';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';

const { colors } = frankyTalesTheme;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        typeName: {
            fontWeight: theme.typography.fontWeightBold,
        },
        advice: css({
            border: `2px solid transparent`,
        }),
        selectedAdvice: css({ borderColor: colors.blue }),
        bad: css({
            height: '3em',
            width: '3em',
            color: colors.orange,
        }),
        good: {
            height: '2em',
            width: '2em',
            color: colors.lightGreen,
        },
    };
};
