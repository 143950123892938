import { useRef, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { QuestFeature } from 'types';
import type { Quest } from 'types';
import { api, callService } from 'services';
import { Fade, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { useStyles } from './styles';

const FEATURE_TRANSLATION_KEYS = {
    [QuestFeature.JOIN]: 'quest.questListCard.actions.join.label',
    [QuestFeature.ABANDON]: 'quest.questListCard.actions.abandon.label',
    [QuestFeature.UPDATE_QUEST]: 'quest.questListCard.actions.updateQuest.label',
    [QuestFeature.UPDATE_PROGRESS]: 'quest.questListCard.actions.updateProgress.label',
    [QuestFeature.EVALUATE]: 'quest.questListCard.actions.evaluate.label',
    [QuestFeature.SELF_EVALUATE]: 'quest.questListCard.actions.selfEvaluate.label',
    [QuestFeature.ARCHIVE]: 'quest.questListCard.actions.archive.label',
};

interface Features {
    features: QuestFeature[];
}

interface Props {
    onAssignmentFeatureClick: (feature: QuestFeature) => void;
    quest: Quest;
}

export const QuestActionsMenu: FC<Props> = ({ onAssignmentFeatureClick, quest }) => {
    const [actionsMenuOpen, setActionsMenuOpen] = useState(false);
    const [questFeatures, setQuestFeatures] = useState<Features>();
    const actionsMenuButtonRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();

    const handleActionsMenuClose = () => setActionsMenuOpen(false);

    const handleOnMenuButtonClick = async () => {
        setQuestFeatures(undefined);
        setActionsMenuOpen(true);

        const { error, payload } = await callService({
            api: api.objectiveController.getFeatures,
            params: [quest.objectiveId],
        });

        if (!error && payload) {
            setQuestFeatures(payload);
        }
    };

    const handleActionMenuItemClick = (feature) => () => {
        setActionsMenuOpen(false);

        if (feature === QuestFeature.UPDATE_QUEST) {
            navigate(`/quest/edit/${quest.objectiveId}`);
        }

        if (feature === QuestFeature.ABANDON) {
            onAssignmentFeatureClick(QuestFeature.ABANDON);
        }

        if (feature === QuestFeature.JOIN) {
            onAssignmentFeatureClick(QuestFeature.JOIN);
        }

        if (feature === QuestFeature.SELF_EVALUATE) {
            navigate(`/quest/evaluate/self/${quest.objectiveId}`);
        }

        if (feature === QuestFeature.EVALUATE) {
            navigate(`/quest/evaluate/${quest.objectiveId}`);
        }

        if (feature === QuestFeature.UPDATE_PROGRESS) {
            navigate(`/quest/updateProgress/${quest.objectiveId}`);
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            onClick={(evt) => {
                evt.stopPropagation();
            }}
            onKeyUp={(evt) => {
                evt.stopPropagation();
            }}
        >
            <IconButton
                aria-label={t<string>('quest.questListCard.menu.aria')}
                aria-controls={`actions-menu-${quest.objectiveId}`}
                aria-haspopup="true"
                onClick={handleOnMenuButtonClick}
                ref={actionsMenuButtonRef}
                size="large"
            >
                <MoreHoriz />
            </IconButton>
            <Menu
                id={`actions-menu-${quest.objectiveId}`}
                anchorEl={actionsMenuButtonRef.current}
                open={actionsMenuOpen}
                onClose={handleActionsMenuClose}
                TransitionComponent={Fade}
            >
                {!questFeatures && (
                    <>
                        <Skeleton variant="rectangular" sx={classes.skeleton} />
                        <Skeleton variant="rectangular" sx={classes.skeleton} />
                    </>
                )}
                {questFeatures?.features?.map((feature) => (
                    <MenuItem
                        key={`actions-menu-${quest.objectiveId}-${feature}`}
                        onClick={handleActionMenuItemClick(feature)}
                    >
                        {t(FEATURE_TRANSLATION_KEYS[feature])}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
