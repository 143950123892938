import { useTheme } from '@mui/material';

const CARD_IMAGE_HEIGHT = 300;

export const useStyles = (): any => {
    const theme = useTheme();

    return {
        avatarImage: {
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: `${CARD_IMAGE_HEIGHT}px`,
            minHeight: `${CARD_IMAGE_HEIGHT}px`,
        },
        card: {
            cursor: 'pointer',
            width: '100%',
        },
        contentText: {
            marginTop: theme.spacing(1 / 2),
        },
    };
};
