import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { SideMenuItem as SideMenuItemType } from 'types';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { Coins } from '../../Coins';
import { useStyles } from './styles';

interface Props {
    menuItem: SideMenuItemType;
    onMenuItemClick?: (...args: any[]) => void;
}

export const StoreMenuItem: FC<Props> = ({ menuItem, onMenuItemClick }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const classes = useStyles();
    const { labelKey = '', url } = menuItem;

    return (
        <div className={classes.storeMenuItem}>
            <ListItem
                sx={classes.listItem}
                key={labelKey}
                button
                onClick={onMenuItemClick}
                disabled={!url}
                selected={location.pathname === url}
            >
                <Coins className={classes.coins} />
                <ListItemText
                    sx={classes.listItemText}
                    primary={<Typography variant="button">{t(labelKey)}</Typography>}
                />
            </ListItem>
        </div>
    );
};
