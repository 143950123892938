import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { Report } from 'types';
import { REPORT_TYPE } from 'types';
import { usePagination, useQuestLogFilter } from 'hooks';
import { InfiniteScroll, Loading } from 'components';
import { api } from 'services';
import { Grid } from '@mui/material';
import { ReportsListCard } from '../ReportsListCard';

interface Props {
    onReportClick(reportId: string): void;
}

const SEARCH_DEBOUNCE = 500;

export const ReportsList: FC<Props> = ({ onReportClick }) => {
    const { filter } = useQuestLogFilter();
    const { t } = useTranslation();
    const query = {
        ...filter,
    };

    if (typeof filter.assignedTo?.[0] === 'object') {
        query.assignedTo = filter.assignedTo.map((assigned) => assigned.id);
    }

    const items = [
        {
            id: 'quest-progress',
            name: t('report.reportName.questProgress.label'),
            type: REPORT_TYPE.TASK,
        },
        {
            id: 'quest-evaluations',
            name: t('report.reportName.questEvaluations.label'),
            type: REPORT_TYPE.TASK,
        },
        {
            id: 'people-progress',
            name: t('report.reportName.peopleProgress.label'),
            type: REPORT_TYPE.GROWTH,
        },
    ];

    // const {
    //     isLoading,
    //     callService,
    //     cancelServiceCall,
    //     // items,
    //     currentPage,
    //     nextPageNumber,
    //     resetPaging,
    //     STARTING_PAGE,
    // } = usePagination<Report>({
    //     api: api.objectiveController.getObjectives,
    //     // api: api.objectiveController.getReports,
    //     query,
    // });

    // const resetSearch = useCallback(
    //     _.debounce(() => {
    //         resetPaging();
    //     }, SEARCH_DEBOUNCE),
    //     [resetPaging],
    // );
    //
    // useEffect(() => {
    //     if (filter && currentPage !== STARTING_PAGE) {
    //         resetSearch();
    //     }
    // }, [filter]);

    // useEffect(() => {
    //     if (currentPage === STARTING_PAGE) {
    //         callService();
    //     }
    // }, [currentPage]);
    //
    // useEffect(() => {
    //     return () => cancelServiceCall();
    // }, []);

    return (
        // <InfiniteScroll
        //     active={!isLoading && currentPage < nextPageNumber}
        //     onEndOfScreen={callService}
        // >
        <Grid container spacing={2}>
            {items.map((report) => (
                <Grid item key={report.id} xs={12}>
                    <ReportsListCard report={report} onReportClick={onReportClick} />
                </Grid>
            ))}
            {/*{isLoading && (*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Loading transitionEnterDuration={0} transitionDelayIn={0} />*/}
            {/*    </Grid>*/}
            {/*)}*/}
        </Grid>
        // </InfiniteScroll>
    );
};
