import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { useLayout } from 'hooks';

export const useStyles = (): any => {
    const { isXs, isSm } = useLayout();

    let maxHeightTableContainer = {};

    if (!isXs && !isSm) {
        maxHeightTableContainer = { height: '626px', borderRadius: 0 };
    }

    const theme = useTheme();

    return {
        activityStreamHeader: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        tableActions: {
            justifyContent: 'center',
            display: 'flex',
            width: '100%',

            '& .MuiTableRow-root': {
                width: '100%',
            },

            '& .MuiTableCell-root': {
                display: 'flex',
                justifyContent: 'center',
            },
        },
        standAloneButton: {
            width: '100%',
            marginBottom: theme.spacing(4),
        },
        tableContainer: {
            overflowY: 'hidden',
        },
        veil: css({
            marginTop: '-128px',
            height: theme.spacing(16),
            position: 'relative',
            background:
                'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 60%, #ffffff)',
        }),
        noPadding: {
            padding: 0,
        },
        maxHeightTableContainer,
    };
};
