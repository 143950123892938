import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import { InputLabel } from '@mui/material';

import { FtUsersList } from './FtUsersList';

interface Props {
    title: string;
    name: string;
    control: any;
    disabled?: boolean;
    errors: any;
    required?: boolean;
}

export const FtUsers: FC<Props> = ({
    title,
    name,
    control,
    disabled = false,
    errors,
    required = false,
}) => (
    <>
        <InputLabel id={`${name}-label`} error={errors[name]}>{`${title} ${
            required && '*'
        }`}</InputLabel>
        <Controller
            control={control}
            name={name}
            rules={{ required }}
            render={({ field: { value, onChange } }) => (
                <FtUsersList items={value} onChange={onChange} disabled={disabled} />
            )}
        />
    </>
);
