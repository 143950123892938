import Loadable from 'react-loadable';
import { Loading } from 'components';
import { general, paths } from '../../constants';

const mainRoute = paths.teams;

const TeamLoader = Loadable({
    loader: () => import('views/admin/Team'),
    loading: Loading,
    render(loaded, props) {
        const { Team } = loaded;
        return <Team {...props} />;
    },
    delay: general.delay,
});

const TeamEditLoader = Loadable({
    loader: () => import('views/admin/Team/Edit'),
    loading: Loading,
    render(loaded, props) {
        const { Edit } = loaded;
        return <Edit {...props} />;
    },
    delay: general.delay,
});

export const teamRoute = {
    path: `/${mainRoute}`,
    component: TeamLoader,
};

export const teamCreateRoute = {
    path: `/${mainRoute}/create`,
    component: TeamEditLoader,
};

export const teamEditRoute = {
    path: `/${mainRoute}/edit/:id`,
    component: TeamEditLoader,
};
