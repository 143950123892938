import { useState } from 'react';
import type { FC } from 'react';
import moment from 'moment';
import type { StoreTransaction } from 'types';
import { api } from 'services';
import { TableCell, TableRow, Typography, IconButton, Collapse } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Coins } from '../../Coins';
import { useStyles } from './styles';

interface Props {
    storeTransaction: StoreTransaction;
}

const DATE_FORMAT = 'DD.MM.YYYY';

export const PurchasedItem: FC<Props> = ({ storeTransaction }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleRowClick = () => setOpen(!open);

    return (
        <>
            <TableRow onClick={handleRowClick}>
                <TableCell component="th" scope="row">
                    {storeTransaction.descriptionShort}
                </TableCell>
                <TableCell align="right" sx={classes.priceColumn}>
                    <Coins value={storeTransaction.pricePaid} />
                </TableCell>
                <TableCell align="right" sx={classes.dateColumn}>
                    {moment(storeTransaction.dateCreated).format(DATE_FORMAT)}
                </TableCell>
                <TableCell sx={classes.arrowColumn}>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={classes.collapsibleCell} colSpan={4}>
                    <Collapse in={open} unmountOnExit>
                        <TableRow>
                            <TableCell sx={classes.imageCell}>
                                <img
                                    src={`${api.baseResourceUrl}/${storeTransaction.photoPath}`}
                                    alt={storeTransaction.descriptionShort}
                                    className={classes.itemImage}
                                />
                            </TableCell>
                            <TableCell sx={classes.detailCell}>
                                <Typography variant="h3" component="h3">
                                    {storeTransaction.descriptionShort}
                                </Typography>
                                <Typography>{storeTransaction.descriptionLong}</Typography>
                            </TableCell>
                        </TableRow>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
