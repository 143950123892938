import { createContext } from 'react';

export interface QuestDetailContextType {
    highlightedKeyResult: number | undefined;
    setHighlightedKeyResult: (index: number | undefined) => void;
}

export const QuestDetailContext = createContext<QuestDetailContextType>({
    highlightedKeyResult: undefined,
    setHighlightedKeyResult: () => {},
});
