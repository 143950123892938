import type { FC } from 'react';
import { useSwiper } from 'swiper/react';
import cx from 'classnames';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface Props {
    left?: boolean;
    onButtonFocused?: () => void;
    onSlide?: (left?: boolean) => void;
}

export const SwiperButton: FC<Props> = ({ left, onButtonFocused, onSlide }) => {
    const swiper = useSwiper();
    const { t } = useTranslation();
    const classes = useStyles();

    const handleOnFocus = () => onButtonFocused?.();

    const handleOnClick = () => {
        if (left) {
            swiper.slidePrev();
            onSlide?.(true);

            return;
        }

        swiper.slideNext();
        onSlide?.();
    };

    const ariaLabel = left
        ? t<string>('feedback.new.competencyStep.actions.goLeft')
        : t<string>('feedback.new.competencyStep.actions.goRight');
    const className = left ? 'left' : 'right';
    const icon = left ? <ChevronLeft /> : <ChevronRight />;

    return (
        <IconButton
            aria-label={ariaLabel}
            className={cx('MuiPaper-elevation1', className)}
            sx={classes.arrowButton}
            onClick={handleOnClick}
            onFocus={handleOnFocus}
            size="large"
        >
            {icon}
        </IconButton>
    );
};
